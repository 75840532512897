import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import ComponentInstructionalCoachTopcard from "./../../components/topcard/index-instructionalcoach";
import ComponentDashboardTile from "./../../components/dashboard-tile/index";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getProfileData } from "../../actions/UserActions/ProfileActions";
import _ from "lodash";
import {
  COMPREHENSIVE_PROGRAM_MEMBER,
  CONTENT_ADMIN,
  CONTENT_AUTHOR,
  DISTRICT_FACILITATOR,
  GPLC_PROGRAM_MEMBER,
  GPLC_PROGRAM_MEMBER_NAME,
  MICRO_PROGRAM_MEMBER,
  KIDVISION_PROGRAM_MEMBER,
  MICRO_PROGRAM_MEMBER_NAME,
  SCHOOL_FACILITATOR,
  SYSTEM_ADMIN,
  TKL_ACCOUNT_MANAGER,
  TKL_FACILITATOR,
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
} from "../../constants.js/RoleNames";
import ComponentPaidUserTopcard from "../topcard/index-paiduser";
import ComponentAccountManagerTopcard from "../topcard/index-accountmanager";
import ComponentCourseFacilitatorTopcard from "../topcard/index-coursefacilitator";
import { store } from "../../App";
import { types } from "../../reducers/types";
import {
  updateTilePosition,
  getTilePositionData,
} from "../../actions/DashboardActions/InstructionalCoachActions";
function ContentAdmin({ props, userData, getProfileData }) {
  const dispatch = useDispatch();

  const { positions } = useSelector((state) => state.dashboards);
  const loggedInUserRoles = useSelector((state) => state?.users?.userData?.roles);

  useEffect(() => {
    // if (_.isEmpty(props?.userRoles)) {
    //   // dispatch(getRoles());
    // }
    sessionStorage.removeItem("isReadOnly");

    sessionStorage.removeItem("courseSlug");

    sessionStorage.removeItem("mode");

    if (sessionStorage.getItem("role")) sessionStorage.removeItem("role");
  }, []);
  const [list, setList] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getProfileData(null, true); //tilePositions;
    // dispatch(getTilePositionData());
  }, []);

  const getTopCardBasedOnRole = (roles) => {
    if (roles?.find((currentRole) => currentRole === SYSTEM_ADMIN)) {
      return (
        <ComponentInstructionalCoachTopcard
          userData={userData}
          title={"System Admin"}
          user={"SYSTEM ADMIN"}
          noOfActivePaidUsers={userData?.systemAdminStats?.noOfActivePaidUsers}
          noOfCommunityBasedUsers={
            userData?.systemAdminStats?.noOfCommunityBasedUsers
          }
          noOfNewUsers={userData?.systemAdminStats?.noOfNewUsers}
          numberOfCoursesCompleted={
            userData?.systemAdminStats?.numberOfCoursesCompleted
          }
        />
      );
    } else if (roles?.find((currentRole) => currentRole === CONTENT_ADMIN)) {
      return (
        <ComponentInstructionalCoachTopcard
          userData={userData}
          user={"CONTENT ADMIN"}
          courseReviewAndFeedback={
            userData?.contentAdminData?.courseReviewAndFeedback
          }
          certificatesEarnedPercentage={
            userData?.contentAdminData?.certificatesEarnedPercentage
          }
          activeTeachersAssigned={
            userData?.contentAdminData?.activeTeachersAssigned
          }
          totalActiveCourses={userData?.contentAdminData?.totalActiveCourses}
        />
      );
    } else if (roles?.find((currentRole) => currentRole === CONTENT_AUTHOR)) {
      return (
        <ComponentInstructionalCoachTopcard
          userData={userData}
          user={"CONTENT AUTHOR"}
          certificatesEarnedPercentage={
            userData?.contentAuthorData?.certificatesEarnedPercentage
          }
          activeTeachersRegistered={
            userData?.contentAuthorData?.activeTeachersRegistered
          }
          totalNoOfParentCoursesApproved={
            userData?.contentAuthorData?.totalNoOfParentCoursesApproved
          }
        />
      );
    } else if (roles?.find((currentRole) => currentRole === TKL_FACILITATOR)) {
      return (
        <ComponentInstructionalCoachTopcard
          userData={userData}
          user={"TKL FACILITATOR"}
          coursesAssigned={userData?.facilitatorData?.coursesAssigned}
          certificatesEarned={
            userData?.facilitatorData?.certificatesEarnedPercentage
          }
          teachersWithEndDateIn30Days={
            userData?.facilitatorData?.teachersWithEndDateIn30Days
          }
          activeTeachersAssigned={
            userData?.facilitatorData?.activeTeachersAssigned
          }
        />
      );
    } else if (
      roles?.find((currentRole) => currentRole === TKL_ACCOUNT_MANAGER)
    ) {
      return (
        <ComponentAccountManagerTopcard
          userData={userData}
          title={"Account Manager"}
          user={"Account Manager"}
          numberOfAccounts={userData?.accountManagerData?.numberOfAccounts}
          totalActiveCourses={userData?.accountManagerData?.totalActiveCourses}
          certificatesEarned={userData?.accountManagerData?.certificatesEarned}
          percentageOfCoursesCompleted={
            userData?.accountManagerData?.percentageOfCoursesCompleted
          }
        />
      );
    } else if (roles?.find((currentRole) => currentRole === DISTRICT_ADMIN)) {
      return (
        <ComponentAccountManagerTopcard
          userData={userData}
          title={"District Admin"}
          user={"Account Manager"}
          numberOfAccounts={userData?.accountManagerData?.numberOfAccounts}
          totalActiveCourses={userData?.accountManagerData?.totalActiveCourses}
          certificatesEarned={userData?.accountManagerData?.certificatesEarned}
          percentageOfCoursesCompleted={
            userData?.accountManagerData?.percentageOfCoursesCompleted
          }
        />
      );
    } else if (roles?.find((currentRole) => currentRole === SCHOOL_ADMIN)) {
      return (
        <ComponentAccountManagerTopcard
          userData={userData}
          title={"School Admin"}
          user={"Account Manager"}
          numberOfAccounts={userData?.accountManagerData?.numberOfAccounts}
          totalActiveCourses={userData?.accountManagerData?.totalActiveCourses}
          certificatesEarned={userData?.accountManagerData?.certificatesEarned}
          percentageOfCoursesCompleted={
            userData?.accountManagerData?.percentageOfCoursesCompleted
          }
        />
      );
    } else if (
      roles?.find((currentRole) => currentRole === DISTRICT_FACILITATOR)
    ) {
      return (
        // ComponentCourseFacilitatorTopcard
        <ComponentInstructionalCoachTopcard
          userData={userData}
          role={"districtFacilitator"}
          user={"DISTRICT FACILITATOR"}
          // user={"Account Manager"}
          // numberOfAccounts={userData?.accountManagerData?.numberOfAccounts}
          // totalActiveCourses={userData?.accountManagerData?.totalActiveCourses}
          // certificatesEarned={userData?.accountManagerData?.certificatesEarned}
          // percentageOfCoursesCompleted={
          //   userData?.accountManagerData?.percentageOfCoursesCompleted
          // }

          coursesAssigned={userData?.districtFacilitatorStats?.coursesAssigned}
          certificatesEarned={
            userData?.districtFacilitatorStats?.certificatesEarnedPercentage
          }
          teachersWithEndDateIn30Days={
            userData?.districtFacilitatorStats?.teachersWithEndDateIn30Days
          }
          activeTeachersAssigned={
            userData?.districtFacilitatorStats?.activeTeachersAssigned
          }
        />
      );
    } else if (
      roles?.find((currentRole) => currentRole === SCHOOL_FACILITATOR)
    ) {
      return (
        // ComponentCourseFacilitatorTopcard
        <ComponentInstructionalCoachTopcard
          userData={userData}
          role={"schoolFacilitator"}
          // user={"Account Manager"}

          user={"SCHOOL FACILITATOR"}
          // numberOfAccounts={userData?.accountManagerData?.numberOfAccounts}
          // totalActiveCourses={userData?.accountManagerData?.totalActiveCourses}
          // certificatesEarned={userData?.accountManagerData?.certificatesEarned}
          // percentageOfCoursesCompleted={
          //   userData?.accountManagerData?.percentageOfCoursesCompleted
          // }

          coursesAssigned={userData?.schoolFacilitatorStats?.coursesAssigned}
          certificatesEarned={
            userData?.schoolFacilitatorStats?.certificatesEarnedPercentage
          }
          teachersWithEndDateIn30Days={
            userData?.schoolFacilitatorStats?.teachersWithEndDateIn30Days
          }
          activeTeachersAssigned={
            userData?.schoolFacilitatorStats?.activeTeachersAssigned
          }
        />
      );
    } else if (
      roles?.find((currentRole) => currentRole === COMPREHENSIVE_PROGRAM_MEMBER)
    ) {
      return <ComponentPaidUserTopcard userData={userData} />;
    } else if (
      roles?.find((currentRole) => currentRole === GPLC_PROGRAM_MEMBER)
    ) {
      return <ComponentPaidUserTopcard userData={userData} />;
    } else if (
      roles?.find((currentRole) => currentRole === KIDVISION_PROGRAM_MEMBER)
    ) {
      return <ComponentPaidUserTopcard userData={userData} />;
    } else if (
      roles?.find((currentRole) => currentRole === MICRO_PROGRAM_MEMBER)
    ) {
      return <ComponentPaidUserTopcard userData={userData} />;
    }else {
      return [];
    }
  };

  useEffect(() => {
    // console.log("......positions useEffect", positions);
    if (positions != null) {
      if (positions?.length > 0) {
        setList(getTilesForPositions(positions));
      } else {
        // console.log("No positions");
        setList(getTilesBasedOnRoles(userData?.roles));
      }
    }
  }, [userData, JSON.stringify(positions)]);

  const onChangePosition = (evt) => {
    let prevArr = [].concat(positions);
    if (evt.oldIndex < evt.newIndex) {
      for (let tileIndex = evt.newIndex; tileIndex > evt.oldIndex; ) {
        prevArr[tileIndex] = {
          ...prevArr[tileIndex],
          index: --tileIndex,
        };
      }
      prevArr[evt.oldIndex] = {
        ...prevArr[evt.oldIndex],
        index: evt.newIndex,
      };
    } else {
      for (let tileIndex = evt.newIndex; tileIndex < evt.oldIndex; ) {
        prevArr[tileIndex] = {
          ...prevArr[tileIndex],
          index: ++tileIndex,
        };
      }
      prevArr[evt.oldIndex] = {
        ...prevArr[evt.oldIndex],
        index: evt.newIndex,
      };
    }
    prevArr.sort((a, b) => (a.index > b.index ? 1 : -1));
    setList(getTilesForPositions(prevArr));
    dispatch(updateTilePosition(prevArr));
    // console.log("updating positions, onChangePosition", prevArr);
    store.dispatch({
      type: types.RESUABLE_GET_ACTION,
      payload: prevArr,
      label: "positions",
    });
  };

  const changeTitleNames = (title) => {
    switch (title) {
      // case "My Assigned Courses":
      //   return "My GPLC Assigned Instructional Topics";
      case "Reflect On It Tasks Feedback & Grading":
        return "Reflect On It Tasks: Feedback & Grading";
      case "Practice It Tasks Feedback & Grading":
        return "Practice It Tasks: Feedback & Grading";
      case "Master It Tasks Feedback & Grading":
        return "Master It Tasks: Feedback & Grading";
      default:
        return title;
    }
  };
  const getTilesForPositions = (positionsArr) => {
    let tileAsPerPositions = [];
    let counter = 0;
    positionsArr?.forEach((item, index) => {
      if(item?.tileData?.title === "My Participant Guides"){
        tileAsPerPositions.push(
          <ComponentDashboardTile
            type="q"
            title={changeTitleNames(item?.tileData?.title) || ""}
            role={item?.tileData?.role || ""}
            activityType={item?.tileData?.activityType || ""}
            isTranscripts={item?.tileData?.isTranscripts || false}
            userData={userData}
            Program={item?.tileData?.Program || ""}
            key={item?.tileData?.key || ""}
          />
        );
      }else{
      tileAsPerPositions.push(
        <ComponentDashboardTile
          type={item?.tileData?.type || ""}
          title={changeTitleNames(item?.tileData?.title) || ""}
          role={item?.tileData?.role || ""}
          activityType={item?.tileData?.activityType || ""}
          isTranscripts={item?.tileData?.isTranscripts || false}
          userData={userData}
          Program={item?.tileData?.Program || ""}
          key={item?.tileData?.key || ""}
        />
      )};
      if(loggedInUserRoles?.find((currentRole) => [SYSTEM_ADMIN, CONTENT_ADMIN, CONTENT_AUTHOR, TKL_ACCOUNT_MANAGER, TKL_FACILITATOR].includes(currentRole))){
        positionsArr?.map((item1) => {
          if (item1?.tileData?.title === "Active Accounts KIDVISION") {
            counter++;
          }
        })
      };
    });
    if(loggedInUserRoles?.find((currentRole) => [SYSTEM_ADMIN, CONTENT_ADMIN, CONTENT_AUTHOR, TKL_ACCOUNT_MANAGER, TKL_FACILITATOR].includes(currentRole))){
      if(counter === 0){
        tileAsPerPositions.splice(4, 0, 
          <ComponentDashboardTile
            type="Active Accounts KIDVISION"
            title="Active Accounts KIDVISION"
            Program={"KIDVISION"}
            role={"systemAdmin"}
          />)
      }
    }
    return tileAsPerPositions;
  };
  const getTilesBasedOnRoles = (roles) => {
    let tileAsPerRoles = [];
    if (roles?.find((currentRole) => currentRole === CONTENT_ADMIN)) {
      tileAsPerRoles.push(
        <ComponentDashboardTile
          type={"courseDevelopmentStatus"}
          title={"Course Development Status"}
          role="contentDeveloper"
          userData={userData}
        />,
        <ComponentDashboardTile
          type="manageCohorts"
          role={"systemAdmin"}
          // title={"Manage Cohorts"}

          title={"Managing Cohorts"}
        />
      );
    }
    if (roles?.find((currentRole) => currentRole === CONTENT_AUTHOR)) {
      if (!roles?.find((currentRole) => currentRole === CONTENT_ADMIN)) {
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type={"authorcourses"}
            title={"Course Development Status"}
            role="contentAuthor"
          />
        );
      }
    }

    if (
      roles?.find((currentRole) => {
        return currentRole === TKL_FACILITATOR;
      })
    ) {
      tileAsPerRoles.push(
        <ComponentDashboardTile
          type={"m"}
          role="instructionalCoach"
          title={"My Assigned Courses"}
        />,

        <ComponentDashboardTile
          type={"p"}
          title={"Reflect On It Tasks: Feedback & Grading"}
          activityType={"reflect-on-it"}
        />,
        <ComponentDashboardTile
          type={"p"}
          title={"Practice It Tasks: Feedback & Grading"}
          activityType={"practice-it"}
        />,
        <ComponentDashboardTile
          type={"p"}
          title={"Master It Tasks: Feedback & Grading"}
          activityType={"master-it"}
        />,
        <ComponentDashboardTile
          type={"ReviewPage"}
          // title={"Monitoring Progress"}
          title={"Monitoring Participant Progress"}
          activityType={"aup"}
        />,
        <ComponentDashboardTile
          type={"ReviewPage"}
          // title={"User Certification & Evaluation"}

          title={"Participant Certification & Evaluation"}
          activityType={"uce"}
        />
      );
    }
    if (
      roles?.find((currentRole) =>
        [
          COMPREHENSIVE_PROGRAM_MEMBER,
          GPLC_PROGRAM_MEMBER,
          MICRO_PROGRAM_MEMBER,
          KIDVISION_PROGRAM_MEMBER,
        ].includes(currentRole)
      )
    ) {
      if (
        roles?.find((currentRole) =>
          [
            DISTRICT_ADMIN,
            DISTRICT_FACILITATOR
          ].includes(currentRole)
        )
      ){
      }else{
        tileAsPerRoles.push(<ComponentDashboardTile type={"a"} />);
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type={"b"}
            title={"My TKL Transcript"}
            isTranscripts={true}
          />
        );
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type={"q"}
            title={"My Participant Guides"}
            isTranscripts={false}
          />
        );
      }
    }

    if (roles?.find((currentRole) => currentRole === DISTRICT_ADMIN)) {
      if (!roles?.find((currentRole) => currentRole === SYSTEM_ADMIN)) {
        if (roles?.find((currentRole) => [MICRO_PROGRAM_MEMBER].includes(currentRole))){
          tileAsPerRoles.push(
            <ComponentDashboardTile
              type="Active Accounts MICRO"
              title="Active Accounts MICRO"
              Program={"MICRO"}
              role={"accountManager"}
              key={"MICRO"}
            />,
            )
        }else if(roles?.find((currentRole) => [KIDVISION_PROGRAM_MEMBER].includes(currentRole))){
          tileAsPerRoles.push(
            <ComponentDashboardTile
              type="Active Accounts KIDVISION"
              title="Active Accounts KIDVISION"
              Program={"KIDVISION"}
              role={"systemAdmin"}
            />,
          )
        }
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type={"p"}
            title={"Practice It Tasks: Feedback & Grading"}
            activityType={"practice-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Reflect On It Tasks: Feedback & Grading"}
            activityType={"reflect-on-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Master It Tasks: Feedback & Grading"}
            activityType={"master-it"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"Monitoring Progress"}
            title={"Monitoring Participant Progress"}
            activityType={"aup"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"User Certification & Evaluation"}
            title={"Participant Certification & Evaluation"}
            activityType={"uce"}
          />,
        );
      }
    }

    if (
      roles?.find((currentRole) => [TKL_ACCOUNT_MANAGER].includes(currentRole))
    ) {
      if (!roles?.find((currentRole) => currentRole === SYSTEM_ADMIN)) {
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type="Active Accounts MICRO"
            title="Active Accounts MICRO"
            Program={"MICRO"}
            role={"accountManager"}
            key={"MICRO"}
          />,
          <ComponentDashboardTile
            type="Active Accounts KIDVISION"
            title="Active Accounts KIDVISION"
            Program={"KIDVISION"}
            role={"systemAdmin"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Reflect On It Tasks: Feedback & Grading"}
            activityType={"reflect-on-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Practice It Tasks: Feedback & Grading"}
            activityType={"practice-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Master It Tasks: Feedback & Grading"}
            activityType={"master-it"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"Monitoring Progress"}
            title={"Monitoring Participant Progress"}
            activityType={"aup"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"User Certification & Evaluation"}
            title={"Participant Certification & Evaluation"}
            activityType={"uce"}
          />
        );
      }
    }
    if (roles?.find((currentRole) => currentRole === DISTRICT_FACILITATOR)) {
        (
          loggedInUserRoles.includes(MICRO_PROGRAM_MEMBER) &&
          tileAsPerRoles.push(
          <ComponentDashboardTile
            type="Active Accounts MICRO"
            title="Active Accounts MICRO"
            Program={"MICRO"}
            role={"accountManager"}
            key={"MICRO"}
          />)
        ),
        (
          loggedInUserRoles.includes(KIDVISION_PROGRAM_MEMBER) &&
          tileAsPerRoles.push(
          <ComponentDashboardTile
            type="Active Accounts KIDVISION"
            title="Active Accounts KIDVISION"
            Program={"KIDVISION"}
            role={"systemAdmin"}
          />)
        ),
          tileAsPerRoles.push
            (
              <ComponentDashboardTile
                type={"p"}
                title={"Reflect On It Tasks: Feedback & Grading"}
                activityType={"reflect-on-it"}
              />,
              <ComponentDashboardTile
                type={"p"}
                title={"Practice It Tasks: Feedback & Grading"}
                activityType={"practice-it"}
              />,
              <ComponentDashboardTile
                type={"p"}
                title={"Master It Tasks: Feedback & Grading"}
                activityType={"master-it"}
              />,
              <ComponentDashboardTile
                type={"ReviewPage"}
                // title={"Monitoring Progress"}
                title={"Monitoring Participant Progress"}
                activityType={"aup"}
              />,
              <ComponentDashboardTile
                type={"ReviewPage"}
                // title={"User Certification & Evaluation"}
                title={"Participant Certification & Evaluation"}
                activityType={"uce"}
              />,
            );

      // if (!roles?.find((currentRole) => currentRole === SYSTEM_ADMIN)) {
      //   tileAsPerRoles.push(
      //     <ComponentDashboardTile
      //       type="Active Accounts GPLC"
      //       Program={"GPLC"}
      //       role={"districtFacilitator"}
      //       key={"GPLC"}
      //     />,
      //     <ComponentDashboardTile
      //       type="Active Accounts MICRO"
      //       Program={"MICRO"}
      //       role={"districtFacilitator"}
      //       key={"MICRO"}
      //     />
      //   );
      // }
    }
    if (roles?.find((currentRole) => currentRole === SCHOOL_FACILITATOR)) {
      if (roles?.find((currentRole) => {
        if (currentRole === MICRO_PROGRAM_MEMBER) {
          tileAsPerRoles.push(
          <ComponentDashboardTile
            type="Active Accounts MICRO"
            title="Active Accounts MICRO"
            Program={"MICRO"}
            role={currentRole}
            key={"MICRO"}
          />,
          );
        }
      })) {
      } else if (roles?.find((currentRole) => currentRole === KIDVISION_PROGRAM_MEMBER)) {
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type="Active Accounts KIDVISION"
            title="Active Accounts KIDVISION"
            Program={"KIDVISION"}
            role={KIDVISION_PROGRAM_MEMBER}
          />,
        );
      }
      

      if (!roles?.find((currentRole) => currentRole === SYSTEM_ADMIN)) {
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type={"p"}
            title={"Reflect On It Tasks Feedback & Grading"}
            activityType={"reflect-on-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Practice It Tasks Feedback & Grading"}
            activityType={"practice-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Master It Tasks Feedback & Grading"}
            activityType={"master-it"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"Monitoring Progress"}
            title={"Monitoring Participant Progress"}
            activityType={"aup"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"Monitoring Progress"}
            title={"Monitoring Participant Progress"}
            activityType={"aup"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"User Certification & Evaluation"}
            title={"Participant Certification & Evaluation"}
            activityType={"uce"}
          />,
        );
      }
    }
    if (roles?.find((currentRole) => currentRole === SCHOOL_ADMIN)) {
      if (!roles?.find((currentRole) => currentRole === SYSTEM_ADMIN)) {
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type={"p"}
            title={"Reflect On It Tasks: Feedback & Grading"}
            activityType={"reflect-on-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Practice It Tasks: Feedback & Grading"}
            activityType={"practice-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Master It Tasks: Feedback & Grading"}
            activityType={"master-it"}
          />,
          (
            loggedInUserRoles.includes(MICRO_PROGRAM_MEMBER) &&
            <ComponentDashboardTile
              type="Active Accounts MICRO"
              title="Active Accounts MICRO"
              Program={"MICRO"}
              role={"accountManager"}
              key={"MICRO"}
            />
          ),
          (
            loggedInUserRoles.includes(KIDVISION_PROGRAM_MEMBER) &&
            <ComponentDashboardTile
              type="Active Accounts KIDVISION"
              title="Active Accounts KIDVISION"
              Program={"KIDVISION"}
              role={"systemAdmin"}
            />
          ),
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"Monitoring Progress"}
            title={"Monitoring Participant Progress"}
            activityType={"aup"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"User Certification & Evaluation"}
            title={"Participant Certification & Evaluation"}
            activityType={"uce"}
          />
        );
      }
    }
    if (roles?.find((currentRole) => currentRole === SYSTEM_ADMIN)) {
      if (!roles?.find((currentRole) => currentRole === CONTENT_ADMIN)) {
        // ["TKL MEMBER (FREE USER)", "SYSTEM ADMIN"]
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type={"courseDevelopmentStatus"}
            title={"Course Development Status"} // title={"My Assigned Courses"}
            role="contentDeveloper"
            userData={userData}
          />,
          <ComponentDashboardTile
            type="manageCohorts"
            role={"systemAdmin"}
            // title={"Manage Cohorts"}

            title={"Managing Cohorts"}
          />,
          <ComponentDashboardTile
            type="Active Accounts GPLC"
            title="Active Accounts GPLC"
            Program={"GPLC"}
            role={"systemAdmin"}
          />,
          <ComponentDashboardTile
            type="Active Accounts MICRO"
            title="Active Accounts MICRO"
            Program={"MICRO"}
            role={"systemAdmin"}
          />,
          <ComponentDashboardTile
            type="Active Accounts KIDVISION"
            title="Active Accounts KIDVISION"
            Program={"KIDVISION"}
            role={"systemAdmin"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Reflect On It Tasks: Feedback & Grading"}
            activityType={"reflect-on-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Practice It Tasks: Feedback & Grading"}
            activityType={"practice-it"}
          />,
          <ComponentDashboardTile
            type={"p"}
            title={"Master It Tasks: Feedback & Grading"}
            activityType={"master-it"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"Monitoring Progress"}
            title={"Monitoring Participant Progress"}
            activityType={"aup"}
          />,
          <ComponentDashboardTile
            type={"ReviewPage"}
            // title={"User Certification & Evaluation"}
            title={"Participant Certification & Evaluation"}
            activityType={"uce"}
          />
          // <ComponentDashboardTile type={"resources"} title={"MY RESOURCES"} />
        );
      }
      // tileAsPerRoles.push(
      // <ComponentDashboardTile
      //   type="Active Accounts GPLC"
      //   Program={"GPLC"}
      //   role={"systemAdmin"}
      // />,
      // <ComponentDashboardTile
      //   type="Active Accounts MICRO"
      //   Program={"MICRO"}
      //   role={"systemAdmin"}
      // />
      // );
    }

    {
      ((loggedInUserRoles.includes(CONTENT_ADMIN)) ||
        (loggedInUserRoles.includes(TKL_FACILITATOR)) ||
        (loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER)) ||
        (loggedInUserRoles.includes(DISTRICT_FACILITATOR) && !loggedInUserRoles.includes(MICRO_PROGRAM_MEMBER)) ||
        (loggedInUserRoles.includes(DISTRICT_ADMIN) && !loggedInUserRoles.includes(MICRO_PROGRAM_MEMBER)) ||
        (loggedInUserRoles.includes(SCHOOL_ADMIN) && !loggedInUserRoles.includes(MICRO_PROGRAM_MEMBER)) ||
        (loggedInUserRoles.includes(SYSTEM_ADMIN)) ) &&
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type={"Active Accounts KIDVISION"}
            title={changeTitleNames("Active Accounts KIDVISION")}
            role={"systemAdmin"}
            activityType={""}
            isTranscripts={false}
            userData={userData}
            Program={"KIDVISION"}
            key={""}
          />
        );
    }

    {
      ((loggedInUserRoles.includes(CONTENT_ADMIN)) ||
        (loggedInUserRoles.includes(TKL_FACILITATOR)) ||
        (loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER)) ||
        (loggedInUserRoles.includes(DISTRICT_FACILITATOR) && !loggedInUserRoles.includes(KIDVISION_PROGRAM_MEMBER)) ||
        (loggedInUserRoles.includes(DISTRICT_ADMIN) && !loggedInUserRoles.includes(KIDVISION_PROGRAM_MEMBER)) ||
        (loggedInUserRoles.includes(SCHOOL_ADMIN) && !loggedInUserRoles.includes(KIDVISION_PROGRAM_MEMBER)) ||
        (loggedInUserRoles.includes(SYSTEM_ADMIN)) ) &&
        tileAsPerRoles.push(
          <ComponentDashboardTile
            type={"Active Accounts MICRO"}
            title={changeTitleNames("Active Accounts MICRO")}
            // role={"systemAdmin"}
            activityType={""}
            // isTranscripts={false}
            // userData={userData}
            Program={"MICRO"}
            key={"MICRO"}
          />
        );
    }

    tileAsPerRoles.push(
      <ComponentDashboardTile
        type={"colleages"}
        title={"MY PROFESSIONAL COLLEAGUES"}
      />
    );
    tileAsPerRoles.push(
      <ComponentDashboardTile type={"resources"} title={"MY RESOURCES"} />
    );

    //remove duplicate tiles
    tileAsPerRoles = tileAsPerRoles.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t?.props?.title === item?.props?.title)
    );

    let newArr = [];
    tileAsPerRoles.forEach((item, index) => {
      newArr.push({ tileData: item?.props, index: index });
    });
    store.dispatch({
      type: types.RESUABLE_GET_ACTION,
      payload: newArr,
      label: "positions",
    });
    return tileAsPerRoles;
  };

  // useEffect(() => {
  //   console.log(".....positions", positions);
  // }, [positions]);

  return (
    <>
      <main className="pg-main pb-3 pb-md-5">
        <div className="px-2 px-md-4 profile-banner-styles">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                {getTopCardBasedOnRole(userData?.roles)}
              </div>
            </div>
            <div className="row">
              <div className="col p-0">
                <section
                  className="dashboard-container dashobard-move_styles"
                  style={{
                    position: "relative",
                    zIndex: "3000",
                  }}
                >
                  <ReactSortable
                    list={list?.map((x) => ({ ...x, chosen: true }))} //}//list}
                    setList={setList}
                    onEnd={onChangePosition}
                    handle=".dash-t-move"
                    ghostClass="sortable-ghost" // Class name for the drop placeholder
                    chosenClass="sortable-chosen" // Class name for the chosen item
                    dragClass="sortable-drag" // Class name for the dragging item
                    dragoverBubble={false}
                    //   onStart={addClass}
                  >
                    {/* className="col-xxl-6 col-xl-12 mb-3" */}
                    {list.map((item, index) => (
                      <div
                        className="col-xl-6 mb-3"
                        key={`ic_sortable_${index}`}
                      >
                        {item}
                      </div>
                    ))}
                  </ReactSortable>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userData: state.users.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfileData: (otheruserSlug, tilePosition) =>
      dispatch(getProfileData(otheruserSlug, tilePosition)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContentAdmin));
