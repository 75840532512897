import axios from "axios";
import { URL } from "../../api/tklEnvironments";
import { types } from "../../reducers/types";
import { sortBasedonSortOrder } from "./UserAction";
export const getTags = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // !userData?.searchInput
    if (true) {
      dispatch(
        sortBasedonSortOrder(
          "tag",
          1,
          "tag",
          userData?.sortFieldType?.slug || "name",
          userData?.sortOrder === "Descending" ? "DESC" : "ASC" || "ASC",
          "",
          "",
          false,
          page
        )
      );
    } else {
      axios
        .get(`${URL}/tag?page=${page}`)
        .then(function (response) {
          if (response.data) {
            dispatch({ type: types.IS_LOADING, payload: false });
            dispatch({ type: types.IS_RELOAD, payload: false });
            dispatch({
              type: types.GET_ALL_TAGS,
              payload: response.data,
            });
          }
        })
        .catch(function (error) {
          dispatch({ type: types.IS_LOADING, payload: false });
          console.error("..error", error);
        });
    }
  };
};

export const updateTags = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/update-tag`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        let errorMessage = error.response?.data?.errors?.name;
        console.error("..error", errorMessage);
        if (
          errorMessage &&
          errorMessage[0] === "The name has already been taken."
        ) {
          dispatch({
            type: types.API_ERROR,
            payload: "The name has already been taken.",
          });
        }
      });
  };
};

export const addTag = (payload) => {
  return (dispatch) => {
    axios
      .post(`${URL}/add-tag`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        let errorMessage = error.response?.data?.errors?.name;
        console.error("..error", errorMessage);
        if (
          errorMessage &&
          errorMessage[0] === "The name has already been taken."
        ) {
          dispatch({
            type: types.API_ERROR,
            payload: "The name has already been taken.",
          });
        }
      });
  };
};
