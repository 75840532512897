import axios from "axios";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import { types } from "../../reducers/types";
import { showFirstValidationErrorFromAPI } from "../../components/utility/ApiErrorHandler";
import { sortBasedonSortOrder } from "./UserAction";

export const getMarketLocation = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });

    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // !userData?.searchInput
    if (true) {
      dispatch(
        sortBasedonSortOrder(
          "get-market-location",
          1,
          "marketLocationAndOrganisation",
          userData?.sortFieldType?.slug || "name",
          userData?.sortOrder === "Descending" ? "DESC" : "ASC" || "ASC",
          "",
          "",
          true,
          page
        )
      );
    } else {
      axios
        .get(`${URL_COHORT}/get-market-location?page=${page}`)
        .then(function (response) {
          if (response.data) {
            dispatch({ type: types.IS_LOADING, payload: false });
            dispatch({ type: types.IS_RELOAD, payload: false });
            dispatch({
              type: types.GET_ALL_MARKET_LOCATION,
              payload: response.data,
            });
          }
        })
        .catch(function (error) {
          dispatch({ type: types.IS_LOADING, payload: false });
          console.error("..error", error);
        });
    }
  };
};

export const updateMarketLocation = (payload) => {
  return async (dispatch) => {
    await axios
      .put(`${URL_COHORT}/update-market-location`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
        }
        return Promise.resolve();
      })
      .catch(function (error) {
        let errorMessage = error.response?.data?.errors?.name;
        if (
          errorMessage &&
          errorMessage[0] === "The name has already been taken."
        ) {
          dispatch({
            type: types.API_ERROR,
            payload: "The name has already been taken.",
          });
        } else {
          if (Array.isArray(errorMessage)) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
        }
        return Promise.reject();
      });
  };
};

export const addMarketLocation = (payload) => {
  return async (dispatch) => {
    await axios
      .post(`${URL_COHORT}/add-market-location`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
          return Promise.resolve();
        }
      })
      .catch(function (error) {
        let errorMessage = error.response?.data?.errors?.name;
        if (
          errorMessage &&
          errorMessage[0] === "The name has already been taken."
        ) {
          dispatch({
            type: types.API_ERROR,
            payload: "The name has already been taken.",
          });
        } else {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject();
      });
  };
};

export const getSecondaryCertificateList = () => {
  return async (dispatch) => {
    await axios
      .get(`${URL_COHORT}/get-secondary-certificate-list`)
      .then((response) => {
        if (response.data) {
          let dataAfterFormatted = [];
          let responseData = response?.data?.response;
          Object.keys(responseData).forEach((listItem) => {
            let data = {
              name: responseData[listItem],
              slug: listItem,
            };
            dataAfterFormatted.push(data);
          });
          let defaultData = {
            name: "No Certificate",
            slug: null,
          };
          dataAfterFormatted.push(defaultData);
          dispatch({
            type: types.ADD_DATA_TO_STATE,
            payload: dataAfterFormatted,
            label: "secondaryCertificates",
          });
          dispatch({
            type: types.ADD_DATA_TO_STATE,
            payload: responseData,
            label: "secondaryCertificatesMap",
          });
        }
      })
      .catch((error) => {
        let message = showFirstValidationErrorFromAPI(
          error.response?.data?.errors
        );
        dispatch({
          type: types.API_ERROR,
          payload: message,
        });
      });
  };
};
