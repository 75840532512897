import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL_COHORT, URL } from "../../api/tklEnvironments";
import {
  showFirstValidationErrorFromAPI,
  hideLoader,
  convertUTCDateToLocalDate,
  convertLocalDateToUTCDate,
} from "../../components/utility/ReUsableFunctions.tsx";

export const cohortsFormData = (itemIndex, label, payload) => {
  return (dispatch) => {
    dispatch({
      type: types.COHORTS_FORM_DATA,
      itemIndex: itemIndex,
      label: label,
      payload: payload,
    });
  };
};

export const updateDataForCohorts = (payload) => {
  return (dispatch) => {
    // console.log("....payload", payload);
    dispatch({
      type: types.UPDATE_COHORT_DATA,
      payload: payload,
    });
  };
};

export const manageCohortData = (
  programType,
  isPaginated,
  pageNumber,
  returnDataPromise,
  hideLoaderCohort,
  searchValue,
  courseStatus
) => {
  return async (dispatch) => {
    !hideLoaderCohort && dispatch({ type: types.IS_RELOAD, payload: true });
    let responseData = {};
    let withSearch_value = searchValue
      ? `manage-course-cohort?programSlug=${programType}&isPaginated=1&page=${pageNumber}&courseName=${searchValue}
      ${courseStatus ? `&status=${courseStatus}` : ""}
        `
      : // &status=${courseStatus || "APPROVED"}

        `manage-course-cohort?programSlug=${programType}&isPaginated=1&page=${pageNumber}${
          courseStatus ? `&status=${courseStatus}` : ""
        }`;
    let URL_ITEM = isPaginated
      ? withSearch_value
      : `manage-course-cohort?programSlug=${programType}&isPaginated=0&status=${
          courseStatus || "APPROVED"
        }`;
    await axios
      .get(`${URL_COHORT}/${URL_ITEM}`)
      .then(function (response) {
        //responseData = {};

        responseData = response?.data;
        let responseDataArrData = responseData?.data;
        responseDataArrData.forEach((item, index) => {
          let totalMarkets = 0;
          item?.cohort.forEach((cohortItem) => {
            if (cohortItem?.program?.slug === programType) {
              ++totalMarkets;
            }
          });
          responseDataArrData[index]["totalmarkets"] = totalMarkets;
        });
        responseData["data"] = responseDataArrData;
        //console.log("......responseDataArrData", responseDataArrData);
        dispatch({
          type: types.MANAGE_COHORT_DATA,
          payload: response?.data,
        });
        dispatch({ type: types.IS_RELOAD, payload: false });
        // console.log(".....responseData", searchValue, responseData);
      })
      .catch(function (error) {
        !hideLoaderCohort &&
          dispatch({ type: types.IS_RELOAD, payload: false });
      });
    if (returnDataPromise) {
      return Promise.resolve(responseData);
    }
  };
};

export const emptyManageCohortData = () => {
  return (dispatch) => {
    dispatch({
      type: types.MANAGE_COHORT_DATA,
      payload: [],
    });
  };
};

export const copyCourse = (payload, history) => {
  return (dispatch) => {
    axios
      .put(`${URL}/copy-course`, payload)
      .then(function (response) {
        //console.log("response : copyCourse", response);
        if (response?.status === 200) {
          let newCourseSlug = response?.data?.response?.copiedCourseSlug;
          sessionStorage.setItem("mode", "edit");
          sessionStorage.setItem("courseSlug", newCourseSlug);
          // history.push(`/${newCourseSlug}/CoursePath`);
          history.push(`/create-course`);
        }
      })
      .catch(function (error) {});
  };
};

export const addFieldsForComprehensive = (itemIndex) => {
  return (dispatch) => {
    let comprehensiveFields = {
      anotherField: "abcd",
    };
    dispatch({
      type: types.ADD_COHORT_FIELDS,
      itemIndex: 0,
      payload: comprehensiveFields,
      assignedProgram: "Guided PLC Program",
    });
  };
};

export const getCountries = () => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-countries`)
      .then(function (response) {
        let countries = [];
        response.data.forEach((country, index) => {
          countries.push(country);
        });
        dispatch({
          type: types.GET_COHORT_DATA,
          payload: countries,
          label: "countries",
        });
      })
      .catch(function (error) {
        console.log("...error");
      });
  };
};

export const getStates = (countrySlug) => {
  return (dispatch) => {
    if (countrySlug) {
      axios
        .get(`${URL_COHORT}/get-states/${countrySlug}`)
        .then(function (response) {
          let states = [];
          response?.data &&
            response.data.forEach((state, index) => {
              state["isChecked"] = false;
              states.push(state);
            });
          dispatch({
            type: types.GET_COHORT_DATA,
            payload: states,
            label: "states",
          });
        })
        .catch(function (error) {
          console.log("...error");
        });
    }
  };
};

export const cohortsStateFormData = (itemIndex, label, payload, checked) => {
  return (dispatch) => {
    dispatch({
      type: types.STATES_FORM_DATA,
      itemIndex: itemIndex,
      label: label,
      payload: payload,
      checked: checked,
    });
  };
};

export const getCohortTypes = () => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-cohort-type`)
      .then(function (response) {
        let cohortType = [];
        response.data.forEach((type, index) => {
          let cohortTypeNameandSlug = {};
          cohortTypeNameandSlug["name"] = type["cohortTypeName"];
          cohortTypeNameandSlug["slug"] = type["cohortTypeSlug"];
          cohortType.push(cohortTypeNameandSlug);
        });
        dispatch({
          type: types.GET_COHORT_DATA,
          payload: cohortType,
          label: "cohortType",
        });
      })
      .catch(function (error) {
        console.log("...error");
      });
  };
};
export const getCohortPurpose = (programType) => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-cohort-purpose`)
      .then(function (response) {
        let cohortPurpose = [];
        response.data.forEach((item, index) => {
          let cohortPurposeNameandSlug = {};
          if (item?.cohortPurposeSlug === "subscription-based-pd") {
            if (programType === "gplc") {
              cohortPurposeNameandSlug["name"] = item["cohortPurposeName"];
              cohortPurposeNameandSlug["slug"] = item["cohortPurposeSlug"];
              cohortPurpose.push(cohortPurposeNameandSlug);
            }
          } else {
            cohortPurposeNameandSlug["name"] = item["cohortPurposeName"];
            cohortPurposeNameandSlug["slug"] = item["cohortPurposeSlug"];
            cohortPurpose.push(cohortPurposeNameandSlug);
          }
        });
        // cohortPurpose.sort(function (a, b) {
        //   return a?.name.toLowerCase() < b?.name.toLowerCase() ? -1 : 1;
        // });

        dispatch({
          type: types.GET_COHORT_DATA,
          payload: cohortPurpose,
          label: "cohortPurpose",
        });
      })
      .catch(function (error) {
        console.log("...error");
      });
  };
};

export const getMarketLocation = () => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-market-location?isPaginated=0`)
      .then(function (response) {
        let cohortcomprehensiveMarkets = [];
        // console.log(".....marketLcoation", response.data);
        // response.data.forEach((item, index) => {
        //   cohortcomprehensiveMarkets.push(item);
        // });
        dispatch({
          type: types.GET_COHORT_DATA,
          payload: response.data,
          label: "SelectMarketLocationComprehensive",
        });
      })
      .catch(function (error) {
        console.log("...error");
      });
  };
};

export const updateCohort = (payload) => {
  return (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });

    axios
      .put(`${URL_COHORT}/update-cohort`, payload)
      .then(function (response) {
        dispatch({
          type: types.UPDATE_COHORT,
          payload: true,
        });

        hideLoader(initialTime);
      })
      .catch(function (error) {
        console.log("...error");
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });

          hideLoader(initialTime);
        }
      });
  };
};

export const getFacilitators = () => {
  return (dispatch) => {
    axios
      // .get(`${URL}/author`)
      .get(
        `${URL}/get-all-users?isPaginated=0&permissions[]=TKL FACILITATOR&sortBy=firstName&orderBy=ASC&searchKey=status&searchExactValue=active`
      )
      .then(function (response) {
        let cohortFacilitators = [];
        response.data &&
          response.data.response &&
          response.data.response?.forEach((item, index) => {
            let cohortPurposeNameandSlug = {};
            cohortPurposeNameandSlug["name"] =
              item["firstName"] + " " + item["lastName"];
            cohortPurposeNameandSlug["slug"] = item["slug"];
            cohortFacilitators.push(cohortPurposeNameandSlug);
          });
        dispatch({
          type: types.GET_COHORT_DATA,
          payload: cohortFacilitators,
          label: "AssignFacilitatorComprehensive",
        });
      })
      .catch(function (error) {
        console.log("...error");
      });
  };
};

export const getAccountOrganization = () => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-organisation`)
      .then(function (response) {
        let cohortAccounts = [];
        response.data.forEach((item, index) => {
          // let cohortPurposeNameandSlug = {};
          // cohortPurposeNameandSlug["name"] = item["cohortPurposeName"];
          // cohortPurposeNameandSlug["slug"] = item["cohortPurposeSlug"];
          cohortAccounts.push(item);
        });
        dispatch({
          type: types.GET_COHORT_DATA,
          payload: cohortAccounts,
          label: "accountOrganizationGuidedPlc",
        });
      })
      .catch(function (error) {
        console.log("...error");
      });
  };
};

export const getWelcomeLetter = () => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-welcome-letter-list`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.WELCOME_LETTER,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        console.log("...error");
      });
  };
};

export const getInformationSheets = () => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-information-letter-list`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.INFORMATION_SHEETS,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        console.log("...error");
      });
  };
};

export const saveCohortPublishData = (index, courseSlug) => {
  return (dispatch) => {
    let initialTime = performance.now();

    dispatch({
      type: types.SHOW_LOADER,
    });
    let cohortPublishData = {};
    let cohortPublishStoreData =
      store && store.getState().cohorts.application.cohorts[index];
    cohortPublishData["courseSlug"] = courseSlug;

    cohortPublishData["courseUrlLocation"] =
      cohortPublishStoreData.courseUrlLocation;
    cohortPublishData["surveyUrl"] = cohortPublishStoreData.surveyUrl;

    cohortPublishData["programSlug"] =
      cohortPublishStoreData.assignedProgram.slug;
    if (
      cohortPublishStoreData.assignedProgram.name ===
      "Comprehensive Course Program"
    ) {
      cohortPublishData["countrySlug"] =
        cohortPublishStoreData.CountryComprehensive.slug;
      cohortPublishData["marketLocationAndOrganisationsSlug"] =
        cohortPublishStoreData.SelectMarketLocationComprehensive.slug;
      cohortPublishData["facilitatorSlug"] =
        cohortPublishStoreData.AssignFacilitatorComprehensive.slug;
      // cohortPublishStoreData.AssignFacilitatorComprehensive.slug;
      cohortPublishData["cohortPurposeSlug"] =
        cohortPublishStoreData.CohortPurposeComprehensive.slug;
      cohortPublishData["cohortTypeSlug"] =
        cohortPublishStoreData.CohortTypeComprehensive.slug;
      cohortPublishData["isFeature"] = cohortPublishStoreData?.isFeaturedCourse;
      // cohortPublishData["description"] =
      //   cohortPublishStoreData?.catalogueDescription;

      switch (cohortPublishStoreData.CohortTypeComprehensive.name) {
        case "Rolling":
          cohortPublishData["termDays"] =
            cohortPublishStoreData.termDaysComprehensiveRolling;
          cohortPublishData["externalUrl"] =
            cohortPublishStoreData.externalUrlComprehensiveRolling;
          cohortPublishData["status"] =
            cohortPublishStoreData.StatusComprehensiveRolling.slug.toUpperCase();
          break;
        case "Seasonal":
          cohortPublishData["registrationStart"] =
            cohortPublishStoreData.RegistrationStartDateComprehensiveSeasonal;
          cohortPublishData["registrationEnd"] =
            cohortPublishStoreData.RegistrationEndDateComprehensiveSeasonal;
          cohortPublishData["termOpenDate"] =
            cohortPublishStoreData.TermOpenDateComprehensiveSeasonal;
          cohortPublishData["termCloseDate"] =
            cohortPublishStoreData.TermCloseDateComprehensiveSeasonal;
          cohortPublishData["status"] =
            cohortPublishStoreData.StatusComprehensiveSeasonal.slug.toUpperCase();
          break;
        case "Subscription":
          cohortPublishData["registrationStart"] =
            cohortPublishStoreData.RegistrationStartDateComprehensiveSeasonal;
          cohortPublishData["registrationEnd"] =
            cohortPublishStoreData.RegistrationEndDateComprehensiveSeasonal;
          cohortPublishData["termOpenDate"] =
            cohortPublishStoreData.TermOpenDateComprehensiveSeasonal;
          cohortPublishData["termCloseDate"] =
            cohortPublishStoreData.TermCloseDateComprehensiveSeasonal;
          cohortPublishData["status"] =
            cohortPublishStoreData.StatusComprehensiveSeasonal.slug.toUpperCase();
          break;
        default:
          break;
      }
    } else if (
      cohortPublishStoreData.assignedProgram.name === "Guided PLC Program" ||
      cohortPublishStoreData.assignedProgram.name ===
        "EvaluWise® Partnership Program" ||
      cohortPublishStoreData.assignedProgram.name === "Micro Course Program"
      || cohortPublishStoreData.assignedProgram.name === "KidVision Course Program"
    ) {
      cohortPublishData["countrySlug"] =
        cohortPublishStoreData.CountryGuidedPlc.slug;
      cohortPublishData["accountId"] =
        cohortPublishStoreData.accountOrganizationGuidedPlc.slug;
      if (cohortPublishStoreData.assignFacilitatorGuidedPlc.slug) {
        cohortPublishData["facilitatorSlug"] =
          cohortPublishStoreData.assignFacilitatorGuidedPlc.slug;
      }
      cohortPublishData["accountManagerSlug"] =
        cohortPublishStoreData.assignTklAccountManagerGuidedPlc.slug;
      cohortPublishData["status"] =
        cohortPublishStoreData.StatusGuidedPlc.slug.toUpperCase();
      // cohortPublishData["description"] = "desc";
      cohortPublishData["cohortTypeSlug"] =
        cohortPublishStoreData.CohortTypeGPLCMicro.slug;
      cohortPublishData["cohortPurposeSlug"] =
        cohortPublishStoreData.CohortPurpose.slug;
      cohortPublishData["isFeature"] = cohortPublishStoreData?.isFeaturedCourse;

      switch (cohortPublishStoreData.CohortTypeGPLCMicro.name) {
        case "Rolling":
          cohortPublishData["termDays"] =
            cohortPublishStoreData.termDaysComprehensiveRolling;
          cohortPublishData["externalUrl"] =
            cohortPublishStoreData.externalUrlComprehensiveRolling;
          cohortPublishData["status"] =
            cohortPublishStoreData.StatusGuidedPlc.slug.toUpperCase();
          break;
        case "Seasonal":
          cohortPublishData["registrationStart"] = convertLocalDateToUTCDate(
            cohortPublishStoreData.RegistrationStartDateComprehensiveSeasonal
          );
          cohortPublishData["registrationEnd"] = convertLocalDateToUTCDate(
            cohortPublishStoreData.RegistrationEndDateComprehensiveSeasonal
          );
          cohortPublishData["termOpenDate"] = convertLocalDateToUTCDate(
            cohortPublishStoreData.TermOpenDateComprehensiveSeasonal
          );
          cohortPublishData["termCloseDate"] = convertLocalDateToUTCDate(
            cohortPublishStoreData.TermCloseDateComprehensiveSeasonal
          );
          cohortPublishData["status"] =
            cohortPublishStoreData.StatusGuidedPlc.slug.toUpperCase();
          break;
        case "Subscription":
          cohortPublishData["registrationStart"] = convertLocalDateToUTCDate(
            cohortPublishStoreData.RegistrationStartDateComprehensiveSeasonal
          );
          cohortPublishData["registrationEnd"] = convertLocalDateToUTCDate(
            cohortPublishStoreData.RegistrationEndDateComprehensiveSeasonal
          );
          cohortPublishData["termOpenDate"] = convertLocalDateToUTCDate(
            cohortPublishStoreData.TermOpenDateComprehensiveSeasonal
          );
          cohortPublishData["termCloseDate"] = convertLocalDateToUTCDate(
            cohortPublishStoreData.TermCloseDateComprehensiveSeasonal
          );
          cohortPublishData["status"] =
            cohortPublishStoreData.StatusGuidedPlc.slug.toUpperCase();
          break;
        default:
          break;
      }
    }
    let statesSlug = [];
    cohortPublishStoreData.StateProvidence.forEach((states, index) => {
      statesSlug.push(states.slug);
    });
    cohortPublishData["stateSlug"] = statesSlug;
    //console.log(".....cohortPublishStoreData", cohortPublishStoreData);
    cohortPublishData["laUsdCourseCode"] =
      cohortPublishStoreData?.laUsdCourseCode;

    cohortPublishData["price"] = +cohortPublishStoreData.Price;
    cohortPublishData["gradCredits"] = +cohortPublishStoreData.gradCredits;
    cohortPublishData["clockHours"] = +cohortPublishStoreData.clockHours;
    cohortPublishData["salaryCredits"] = +cohortPublishStoreData.salaryPoints;
    cohortPublishData["welcomeLetter"] =
      cohortPublishStoreData.WelcomeLetter.slug;
    cohortPublishData["informationSheet"] =
      cohortPublishStoreData.InformationSheet.slug;
    if ((localStorage.getItem("microSelected")) || (cohortPublishStoreData.assignedProgram.name === "Micro Course Program") || cohortPublishStoreData.assignedProgram.name === "KidVision Course Program") { 
      cohortPublishData["termOpenDate"] = localStorage.getItem("startDate");
      cohortPublishData["termCloseDate"] = localStorage.getItem("endDate");
      cohortPublishData["registrationStart"] = localStorage.getItem("startDate");
      cohortPublishData["registrationEnd"] = localStorage.getItem("endDate");
    }
    axios
      .post(`${URL_COHORT}/create-cohort`, cohortPublishData)
      .then(function (response) {
        if (response.data) {
          hideLoader(initialTime);
          localStorage.removeItem("startDate");
          localStorage.removeItem("endDate");
          localStorage.removeItem("MicroSelected");
          let cohortCreatedSlug = {
            name: cohortPublishStoreData.assignedProgram.name,
            slug: cohortPublishStoreData.assignedProgram.slug,
          };

          sessionStorage.setItem(
            "cohortCreatedSlug",
            JSON.stringify(cohortCreatedSlug)
          );
          if (response.data.response["message"] === "Cohort Created") {
            dispatch({
              type: types.COHORT_CONTENT_PUBLISH_TEXT,
              payload: true,
            });
            dispatch({
              type: types.MANAGE_COHORT_DATA,
              payload: [],
            });
            dispatch({ type: types.INITIAL_COHORT_DATA });
          }
        }
        // alert("..then");
        dispatch({
          type: types.API_SUCCESS,
          payload: "Cohort Created",
        });
        dispatch({
          type: types.HIDE_LOADER,
        });
      })
      .catch(function (error) {
        dispatch({
          type: types.HIDE_LOADER,
        });
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject(false);
      });
    return Promise.resolve(true);
  };
};

export const cohortFielsAdd = (cohortCourseSlug, programeName, programSlug) => {
  return (dispatch) => {
    let fieldsArray = [];
    if (programeName === "Comprehensive Course Program") {
      fieldsArray[0] = {
        courseUrlLocation: "",
        surveyUrl: "",
        courseSlug: cohortCourseSlug,
        assignedProgram: { name: programeName, slug: programSlug },

        CountryComprehensive: { name: "", slug: "" },
        SelectMarketLocationComprehensive: { name: "", slug: "" },
        AssignFacilitatorComprehensive: { name: "", slug: "" },
        CohortPurposeComprehensive: { name: "", slug: "" },
        CohortTypeComprehensive: { name: "", slug: "" },

        isFeaturedCourse: false,
        // catalogueDescription: "",

        RegistrationStartDateComprehensiveSeasonal: "",
        RegistrationEndDateComprehensiveSeasonal: "",
        TermOpenDateComprehensiveSeasonal: "",
        TermCloseDateComprehensiveSeasonal: "",
        StatusComprehensiveSeasonal: { name: "", slug: "" },

        termDaysComprehensiveRolling: "",
        externalUrlComprehensiveRolling: "",
        StatusComprehensiveRolling: { name: "", slug: "" },

        StateProvidence: [],
        laUsdCourseCode: "",
        Price: "",
        gradCredits: "",
        clockHours: "",
        salaryPoints: "",
        WelcomeLetter: { name: "", slug: "" },
        InformationSheet: { name: "", slug: "" },
      };
    } else {
      fieldsArray[0] = {
        courseUrlLocation: "",
        surveyUrl: "",
        courseSlug: cohortCourseSlug,
        assignedProgram: { name: programeName, slug: programSlug },
        CohortTypeGPLCMicro: { name: "", slug: "" },
        CohortPurpose: { name: "", slug: "" },

        CountryGuidedPlc: { name: "", slug: "" },
        accountOrganizationGuidedPlc: {
          name: "",
          slug: "",
          state: {},
          country: {},
        },
        assignFacilitatorGuidedPlc: { name: "", slug: "" },
        assignTklAccountManagerGuidedPlc: { name: "", slug: "" },
        StatusGuidedPlc: { name: "", slug: "" },

        isFeaturedCourse: false,

        StateProvidence: [],

        laUsdCourseCode: "",
        Price: "0",
        gradCredits: "",
        clockHours: "",
        salaryPoints: "",
        WelcomeLetter: { name: "", slug: "" },
        InformationSheet: { name: "", slug: "" },
      };
    }
    dispatch({ type: types.COHORTS_FIELDS_ADD, payload: fieldsArray });
  };
};

const fieldsDataSplitting = (mapData, newData, objectField) => {
  for (let SeasonalFieldName of Array.from(mapData)) {
    if (!newData[SeasonalFieldName]) {
      if (SeasonalFieldName === objectField) {
        newData[SeasonalFieldName] = {};
        newData[SeasonalFieldName]["name"] = "";
        newData[SeasonalFieldName]["slug"] = "";
      } else {
        newData[SeasonalFieldName] = "";
      }
    }
  }
};
const comprehensiveFieldData = (
  cohortFieldLabel,
  removeFieldData,
  newData,
  addFieldData,
  isSeasonal
) => {
  Object.keys(cohortFieldLabel).forEach((fieldName) => {
    if (!removeFieldData.has(fieldName)) {
      newData[fieldName] = cohortFieldLabel?.[fieldName];
    }
  });
  fieldsDataSplitting(
    addFieldData,
    newData,
    isSeasonal ? "StatusComprehensiveSeasonal" : "StatusComprehensiveRolling"
  ); //add/remove fields already if present in the state
};
export const removeFields = (payload) => {
  return (dispatch) => {
    let applicationCohortLabelData =
      store && store.getState().cohorts.application.cohorts;
    let fieldData = [];
    let newData = {};
    let cohortFieldLabel = applicationCohortLabelData[0];
    let setMapForFieldComprehensiveSeasonal = new Set([
      "RegistrationStartDateComprehensiveSeasonal",
      "RegistrationEndDateComprehensiveSeasonal",
      "TermOpenDateComprehensiveSeasonal",
      "TermCloseDateComprehensiveSeasonal",
      "StatusComprehensiveSeasonal",
    ]);
    let setMapForFieldComprehensiveRolling = new Set([
      "termDaysComprehensiveRolling",
      "externalUrlComprehensiveRolling",
      "StatusComprehensiveRolling",
    ]);
    if (payload === "Seasonal" || payload === "Subscription") {
      comprehensiveFieldData(
        cohortFieldLabel,
        setMapForFieldComprehensiveRolling,
        newData,
        setMapForFieldComprehensiveSeasonal,
        "Seasonal"
      );
    } else if (payload === "Rolling") {
      comprehensiveFieldData(
        cohortFieldLabel,
        setMapForFieldComprehensiveSeasonal,
        newData,
        setMapForFieldComprehensiveRolling,
        "Rolling"
      );
    }
    fieldData.push(newData);
    dispatch({
      type: types.COHORTS_FIELDS_REMOVE,
      payload: payload,
      applicationFields: fieldData,
    });
  };
};
//   Object.keys(cohortFieldLabel).forEach((fieldName) => {
//     if (!setMapForFieldComprehensiveSeasonal.has(fieldName)) {
//       newData[fieldName] = cohortFieldLabel?.[fieldName];
//     }
//   });
//   for (let SeasonalFieldName of Array.from(
//     setMapForFieldComprehensiveRolling
//   )) {
//     if (SeasonalFieldName === "StatusComprehensiveRolling") {
//       newData[SeasonalFieldName] = {};
//       newData[SeasonalFieldName]["name"] = "";
//       newData[SeasonalFieldName]["slug"] = "";
//     } else {
//       newData[SeasonalFieldName] = "";
//     }
//   }

export const deleteCohort = (cohortId, history, getManageDataPayload) => {
  // console.log("......getManageDataPayload", getManageDataPayload);
  const {
    programSlug,
    isPaginated,
    pageNumber,
    returnDataPromise,
    hideLoaderCohort,
    filterValue,
  } = getManageDataPayload;
  return async (dispatch) => {
    dispatch({ type: types.IS_RELOAD, payload: true });
    await axios
      .delete(`${URL_COHORT}/delete-cohort/${cohortId}`)
      .then(function (resposne) {
        dispatch({ type: types.IS_RELOAD, payload: false });
        dispatch(
          manageCohortData(
            programSlug,
            isPaginated,
            pageNumber,
            returnDataPromise,
            hideLoaderCohort,
            filterValue
          )
        );
        // history.go(0);
      })
      .catch((error) => {
        alert("Not Deleted, Please try again");
        dispatch({ type: types.IS_RELOAD, payload: false });
      });
  };
};

export const updateInactiveCourseStatus = (
  url,
  programType,
  pageNumber,
  searchValue,
  courseStatus
) => {
  return (dispatch) => {
    axios
      .put(`${URL_COHORT}/${url}`)
      .then((response) => {
        if (response?.data) {
          dispatch(
            manageCohortData(
              programType,
              1,
              pageNumber,
              false,
              false,
              searchValue,
              courseStatus
            )
          );
          // return Promise.resolve();
        }
      })
      .catch((error) => {
        console.log("....error", error);

        // return Promise.reject();
      });
  };
};
