import React, { Component } from "react";
import { Carousel } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import { isMobile } from "react-device-detect";

export default class ComponentCustomScrollbar extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = { top: 0 };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.renderView = this.renderView.bind(this);
    this.renderThumbHorizontal = this.renderThumbHorizontal.bind(this);
    this.renderThumbVertical = this.renderThumbVertical.bind(this);
    this.renderTrackVertical = this.renderTrackVertical.bind(this);
    this.renderTrackHorizontal = this.renderTrackHorizontal.bind(this);
  }

  handleUpdate(values) {
    const { top } = values;
    this.setState({ top });
  }

  renderView({ style, ...props }) {
    // const { top } = this.state;
    const viewStyle = {
      // paddingRight: `1rem`,
      // backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(top * 255)}, ${Math.round(255)})`,
      // color: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
    };
    return (
      <div
        className="custom-scroll-box"
        style={{ ...style, ...viewStyle }}
        {...props}
      />
    );
  }

  renderThumbHorizontal({ style, ...props }) {
    // const { top } = this.state;
    const thumbStyle = {
      // backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
      backgroundColor: `#000`,
      width: `0.5rem`,
      borderRadius: `0.25rem`,
      display: "none",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  renderThumbVertical({ style, ...props }) {
    // const { top } = this.state;
    const thumbStyle = {
      // backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
      backgroundColor: `#000`,
      width: `0.5rem`,
      borderRadius: `0.25rem`,
    };
    // return (
    //   <div
    //     style={{ ...style, ...thumbStyle }}
    //     {...props} />
    // );
    return <div className="thumb-vertical" {...props} />;
  }
  renderTrackVertical({ style, ...props }) {
    // const { top } = this.state;
    const thumbStyle = {
      // backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
      backgroundColor: `#000`,
      borderRadius: `0.25rem`,
    };
    // return (
    //   <div
    //     style={{ ...style, ...thumbStyle }}
    //     {...props} />
    // );
    if (isMobile) {
      return <div className="track-vertical d-none d-xl-block" {...props} />;
    } else {
      return <div className="track-vertical  d-xl-block" {...props} />;
    }
  }
  renderTrackHorizontal({ style, ...props }) {
    // const { top } = this.state;
    const thumbStyle = {
      // backgroundColor: `rgb(${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))}, ${Math.round(255 - (top * 255))})`
      backgroundColor: `#000`,
      borderRadius: `0.25rem`,
      display: "none",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  }

  render() {
    return (
      <Scrollbars
        renderTrackHorizontal={this.renderTrackHorizontal}
        renderThumbHorizontal={this.renderThumbHorizontal}
        renderTrackVertical={this.renderTrackVertical}
        renderThumbVertical={this.renderThumbVertical}
        renderView={this.renderView}
        onUpdate={this.handleUpdate}
        {...this.props}
      />
    );
  }
}
