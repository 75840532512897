export class DiscountCode {
  constructor(
    code,
    discountType,
    discount,
    startDate,
    startTime,
    endDate,
    endTime,
    location,
    courses
  ) {
    this.code = code || "";
    this.discountType = discountType || "";
    this.discount = discount || "";
    this.startDate = startDate || "";
    this.startTime = startTime || "";
    this.endDate = endDate || "";
    this.endTime = endTime || "";
    this.location = location || [""];
    this.courses = courses || [""];
  }
  public code: "";
  public discountType: "";
  public discount: "";
  public startDate: "";
  public startTime: "";
  public endDate: "";
  public endTime: "";
  public location: [""];
  public courses: [""];
}

export class Bundles {
  constructor(
    title,
    description,
    price,
    bundleSize,
    visibility,
    location,
    courses
  ) {
    this.title = title || "";
    this.description = description || "";
    this.price = price || "";
    this.bundleSize = bundleSize || "";
    this.visibility = visibility || "";
    this.location = location || [""];
    this.courses = courses || [""];
  }
  public title: "";
  public description: "";
  public price: "";
  public bundleSize: "";
  public visibility: "";
  public location: [""];
  public courses: [""];
}

export class BundleCodes {
  constructor(code, bundle, bundleSize, redemptionCount, active) {
    this.code = code || "";
    this.bundle = bundle || "";
    this.bundleSize = bundleSize || "";
    this.redemptionCount = redemptionCount || "";
    this.active = active || "";
  }
  public code: "";
  public bundle: "";
  public bundleSize: "";
  public redemptionCount: "";
  public active: "";
}
