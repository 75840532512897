import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  addResource,
  updateResource,
} from "../../../actions/CommunityActions/CommunityAction";
import { URL } from "../../../api/tklEnvironments";
import { types } from "../../../reducers/types";
import { TKLModal } from "../../../shared-components/model/TKLModal";
import ComponentAvatar from "../../CourseCreation/modals/ComponentAvatar";
import _ from "lodash";

function AddResource(props) {
  const { handleCloseGroup, currentResourse, editMode } = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(props?.showGroup);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [bannerSlug, setBannerSlug] = useState("");
  const [avatarSlug, setAvatarSlug] = useState("");
  const defaultValues = !editMode ? { isRecommended: true } : {};
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: defaultValues });
  console.log("errors.redirectionUrl", errors);
  console.log("defaultValues", editMode, defaultValues);

  useEffect(() => {
    console.log("currentResourse : ", currentResourse, editMode);
    if (editMode && currentResourse) {
      setValue("redirectionUrl", currentResourse?.redirectionUrl);
      setValue("resourceName", currentResourse?.title);
      setValue("resourceType", currentResourse?.type);
      setValue("isRecommended", currentResourse?.isRecommended);
    }
  }, [currentResourse]);

  const { apiSuccessMessage, apiErrorMessage } = useSelector(
    (state) => state.coursepath
  );

  const userData = useSelector((state) => state.users.userData);

  const clickbtn = (event) => {
    document.getElementById(event).click();
  };
  const onSubmit = (data) => {
    if (editMode) {
      dispatch(
        updateResource({
          title: data?.resourceName,
          type: data?.resourceType,
          redirectionUrl: data?.redirectionUrl,
          resourceId: currentResourse?.communityResourceId,
          isRecommended: data?.isRecommended == true ? 1 : 0,
        })
      );
    } else {
      console.log(data?.isRecommended);
      dispatch(
        addResource({
          title: data?.resourceName,
          type: data?.resourceType,
          redirectionUrl: data?.redirectionUrl,
          isRecommended: data?.isRecommended == true ? 1 : 0,
        })
      );
    }

    props.handleCloseGroup();
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="create-group">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs="12" md="12" xl="12" className="col-xxl-12">
                <div className="pg-content">
                  <Container fluid className="px-3 p-xxl-4">
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label>
                            <span className="font-weight-500">
                              Resource Title
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Resource Title"
                            className="rounded-pill"
                            {...register("resourceName", { required: true })}
                          />
                          {errors.resourceName && (
                            <span className="text-danger p-2">
                              This field is required
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-3 rounded-pill-textbox"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>
                            <span className="font-weight-500">
                              Resourse Type
                            </span>
                          </Form.Label>

                          <Form.Control
                            as="select"
                            className="rounded-pill"
                            aria-label="Select Type"
                            {...register("resourceType", {
                              required: true,
                              message: "Resource Type is Required",
                            })}
                          >
                            <option value="">
                              --Please Select Resource Type--
                            </option>{" "}
                            <option value="image">Image</option>
                            <option value="document">Document</option>
                            <option value="video">Video</option>
                            <option value="blog">Blog</option>
                            <option value="newsItem">News</option>
                            <option value="course">Course</option>
                          </Form.Control>

                          {errors.resourceType && (
                            <span className="text-danger p-2">
                              This field is required
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-3 rounded-pill-textbox"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Label>
                            <span className="font-weight-500">
                              Resourse URL
                            </span>
                          </Form.Label>
                          <Form.Control
                            className="mb-3 rounded-pill-textbox"
                            rows={3}
                            {...register("redirectionUrl", {
                              required: true,
                              pattern: {
                                value:
                                  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                message: "Invalid URL address",
                              },
                            })}
                          />

                          {errors.redirectionUrl && (
                            <span className="text-danger p-2">
                              {errors?.redirectionUrl?.message?.length > 0
                                ? errors?.redirectionUrl?.message
                                : "This field is required"}
                            </span>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-3 "
                          controlId="exampleForm.ControlInput1"
                        >
                          <div
                            style={{ maxWidth: "200px" }}
                            className="d-flex align-items-center justify-content-space-between"
                          >
                            <div className="custom-checkbox_addResource">
                              {" "}
                              <input
                                type="checkbox"
                                // checked={getFieldState("isRecommended")}
                                {...register("isRecommended", {
                                  // required: true,
                                })}
                              />{" "}
                            </div>
                            <Form.Label>
                              <span className="font-weight-500">
                                Recommended
                              </span>
                            </Form.Label>
                          </div>
                          {errors.isRecommended && (
                            <span className="text-danger p-2">
                              This field is required
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="12" className="d-flex">
                        <div style={{ marginLeft: "auto" }}>
                          <Button
                            className="btn btn-primary rounded-pill btn-width m-2"
                            size="sm"
                            style={{
                              backgroundColor: "#40403f",
                              border: "none",
                            }}
                            onClick={() => props.handleCloseGroup()}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            className="btn btn-primary rounded-pill btn-width m-2"
                            size="sm"
                          >
                            {editMode ? "Update" : "Create"}
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </form>

      {apiSuccessMessage && (
        <TKLModal
          showDialog={true}
          msgType={"warning"}
          message={apiSuccessMessage}
          type="success"
        />
      )}
      {apiErrorMessage && (
        <TKLModal
          showDialog={true}
          msgType={"warning"}
          message={apiErrorMessage}
          type="error"
        />
      )}
    </div>
  );
}

export default AddResource;
