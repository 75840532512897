import { listFolders } from "actions/FileStorageActions/FileStorageAction";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import { useSelector, useDispatch } from "react-redux";
import { types } from "reducers/types";

function FileStorageBreadCrumb() {
  const dispatch = useDispatch();
  const { folders, currentFolderPath } = useSelector(
    (state) => state.filestorage
  );
  return (
    <Breadcrumb>
      {currentFolderPath.map((item, index) => {
        return (
          <Breadcrumb.Item
            href=""
            onClick={() => {
              if (index + 1 != currentFolderPath.length) {
                dispatch(listFolders(item?.slug));
                dispatch({
                  type: types.UPDATE_CURRENT_FOLDER_PATH,
                  payload: currentFolderPath.slice(0, index + 1),
                });
              }
            }}
            active={index + 1 == currentFolderPath.length}
          >
            {item?.name}
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
}

export default FileStorageBreadCrumb;
