import React, { useState, useMemo, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ContextUser from "./../../../contexts/user-context";
import { getProgramDetails } from "../../../actions/CreateCourse/CreatecourseActions";
import { manageCohortData } from "../../../actions/CohortsActions/cohortactions";
import InfiniteScroll from "react-infinite-scroll-component";
import { Spinner } from "react-bootstrap";
const ManageCohort = ({ role, title }) => {
  const { history } = useContext(ContextUser);

  const { districts, schools, cohortList, cohortDatagrouped } = useSelector(
    (state) => state.dashboards
  );

  const { isReload } = useSelector((state) => state.masterdata);

  const { allPrograms } = useSelector((state) => state.coursehome);
  const courseCohortData = useSelector(
    (state) => state.cohorts.manageCohortData
  );
  const dispatch = useDispatch();

  const [programs, setPrograms] = useState(allPrograms);
  const [cohortData, setCohortData] = useState("");
  const [programSelected, setProgramSelected] = useState("");
  const [programNameSelected, setProgramNameSelected] = useState("");
  const [courseNamefilter, setcourseNamefilter] = useState("");

  const pageCount = useRef(1);
  const manageCohortTileSession = JSON.parse(
    sessionStorage.getItem("manageCohortTileData")
  );
  const [lastPage, setLastPage] = useState(false);
  useEffect(() => {
    dispatch(getProgramDetails());
    if (manageCohortTileSession) {
      dispatch(manageCohortData(manageCohortTileSession?.ProgramSlug, false));
      setProgramSelected(manageCohortTileSession?.ProgramSlug);
      setProgramNameSelected(manageCohortTileSession?.ProgramName);
    }
  }, []);

  useEffect(() => {
    setPrograms(allPrograms);
  }, [allPrograms]);

  useEffect(() => {
    setCohortData(courseCohortData?.data);
  }, [JSON.stringify(courseCohortData?.data)]);

  const optionSelectHandler = (event, hideLoader) => {
    let programCohortData = [];
    let slug = event?.target?.value; //event.target[event.target?.selectedIndex]?.dataset?.slug;
    // if (!programSelected) {

    let element = document.getElementById("cohort_search_id");
    element.value = "";

    let optionsSlug = document.getElementById("course_status_dropdown")?.value;
    // }
    //  console.log(".....slug", slug, optionsSlug);
    setProgramSelected(slug);
    setProgramNameSelected(event?.target[event?.target?.selectedIndex]?.text);
    slug &&
      dispatch(
        manageCohortData(
          slug,
          false,
          null,
          false,
          hideLoader && true,
          "",
          optionsSlug || ""
        )
      );
    let storage = {
      ProgramSlug: slug,
      ProgramName: event?.target[event?.target?.selectedIndex]?.text,
    };
    sessionStorage.setItem("manageCohortTileData", JSON.stringify(storage));
    if (!slug) {
      setCohortData(courseCohortData?.data);
    } else {
      courseCohortData?.data &&
        Array.isArray(courseCohortData?.data) &&
        courseCohortData?.data.forEach((item) => {
          item?.program.forEach((programData) => {
            if (programData?.slug === slug) {
              programCohortData.push(item);
            }
          });
        });
      setCohortData(programCohortData);
    }
  };

  const filterCourseNames = (event) => {
    let value = event.target?.value;
    let programSearchCohortData = [];
    if (value) {
      courseCohortData?.data &&
        courseCohortData?.data.forEach((item) => {
          if (item?.courseName.toLowerCase().includes(value.toLowerCase())) {
            programSearchCohortData.push(item);
          }
        });

      setCohortData(programSearchCohortData);
      // setcourseNamefilter(event.target.value);
    } else {
      if (programSelected === "") {
        setCohortData(courseCohortData?.data);
      } else {
        let event = {
          target: { value: programSelected },
        };
        optionSelectHandler(event, true);
      }
    }
  };

  const nextApICall = () => {
    pageCount.current += 1;
    dispatch(manageCohortData(programSelected, false, pageCount.current));
  };

  useEffect(() => {
    // const { current_page, last_page } = courseCohortData?.meta;
    if (
      courseCohortData?.meta?.current_page === courseCohortData?.meta?.last_page
    )
      setLastPage(true);
  }, [courseCohortData?.meta?.current_page]);
  const renderCourseAndCohorts = () => {
    return (
      <>
        <Col xs="12" className="d-flex">
          {isReload ? (
            <div className="tkl-spinner-master-data tkl-dashboard-tile-spinner">
              <Spinner animation="border" variant="primary" size="lr" />
            </div>
          ) : (
            <ComponentCustomScrollbar
              className="scroll-comp"
              style={{ height: "100%" }}
            >
              {cohortData && cohortData.length ? (
                // <InfiniteScroll
                //   dataLength={cohortData?.length}
                //   next={nextApICall}
                //   hasMore={lastPage}
                //   loader={<h4>Loading...</h4>}
                // >
                <>
                  {cohortData &&
                    cohortData.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="dash-row-type-2 container rounded mb-2 active_accounts_style">
                            <div className="row w-100">
                              <div className="t-row-name col-12 position-relative p-2 d-flex flex-column justify-content-center">
                                <p
                                  className="font-semi-bold-TKL"
                                  style={{
                                    marginBottom: "0.3em",
                                    fontSize: "18px",
                                  }}
                                >
                                  {item?.courseName}
                                </p>
                                <p
                                  className="TKL_number_highlight"
                                  style={{
                                    marginBottom: "0.3em",
                                    fontSize: "16px",
                                  }}
                                >
                                  {item?.cohort?.length && item?.cohort.length}{" "}
                                  Cohorts Available
                                  <span
                                    style={{
                                      float: "right",
                                    }}
                                  >
                                    <span style={{ marginRight: "0.8em" }}>
                                      <ComponentCtaBtn
                                        onClick={() => {
                                          // history.push(
                                          //   "/manageCohorts/" +
                                          //     programNameSelected
                                          // );
                                          // history.go(0);

                                          window.open(
                                            `/manageCohorts/ +
                                            ${programNameSelected}
                                       `
                                          );
                                          // window.scrollTo(0, 0);
                                        }}
                                        className="btn btn-outline btn-rounded btn-blue-dark m-2 rounded-pill"
                                        data={{
                                          tag: "button",
                                          type: "button",
                                          size: "sm",
                                          ctastyle: "info",
                                          text: "Manage Cohort",
                                          title: "Manage Cohort",
                                          ctaicon: "chevron_right",
                                        }}
                                      />
                                    </span>
                                    {item?.program.length && (
                                      <ComponentCtaBtn
                                        onClick={() => {
                                          // history.push(
                                          //   `${item?.courseSlug}/${programSelected}/cohorts`
                                          // );
                                          // history.go(0);
                                          sessionStorage.setItem(
                                            "programSelected",
                                            programNameSelected
                                          );
                                          window.open(
                                            `${item?.courseSlug}/${programNameSelected}/cohorts`
                                          );
                                          // window.scrollTo(0, 0);
                                        }}
                                        className="btn btn-outline btn-rounded m-2 rounded-pill"
                                        data={{
                                          tag: "button",
                                          type: "button",
                                          size: "sm",
                                          ctastyle: "primary",
                                          text: "Create Cohort",
                                          title: "Create Cohort",
                                          ctaicon: "add",
                                        }}
                                      />
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  <p className="item-not-selected">No Courses Available</p>
                </>
              )}
              <br />
            </ComponentCustomScrollbar>
          )}
        </Col>
      </>
    );
  };

  const handleActiveCourses = (e) => {
    let slug = e.target[e.target.selectedIndex]?.dataset.slug;
    // console.log("slug", slug);
    let searchVal = document.getElementById("cohort_search_id")?.value || "";
    dispatch(
      manageCohortData(
        programSelected,
        false,
        null,
        false,
        true,
        searchVal,
        slug
      )
    );
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2
            className="h4 text-uppercase m-0"
            style={{ display: "inline-block" }}
          >
            <strong>{title}</strong>
          </h2>
        </div>
      </div>
      <Row style={{ marginTop: "1.2em", marginLeft: "1em" }}>
        <Col xs="12" md="6">
          <div>
            <h5 className="font-semi-bold-TKL">Program</h5>
          </div>
          <select
            className="form-control rounded-pill"
            onChange={optionSelectHandler}
            id={"Program"}
            value={manageCohortTileSession?.ProgramSlug}
          >
            <option value="">Select Program</option>
            {programs &&
              programs.map((program) => {
                return (
                  <option
                    value={program?.slug}
                    key={program?.slug}
                    // selected={
                    //   program.slug === manageCohortTileSession?.ProgramSlug
                    // }
                  >
                    {program?.name}
                  </option>
                );
              })}
          </select>
        </Col>
        <Col xs="12" md="6">
          <div style={{ marginBottom: "2em" }}></div>
          <select
            className="form-control rounded-pill"
            onChange={handleActiveCourses}
            // disabled
            id={"course_status_dropdown"}
          >
            <option value="APPROVED" data-slug="APPROVED">
              Active Course
            </option>
            <option value="INACTIVE" data-slug="INACTIVE">
              Inactive Course
            </option>
            <option value="ARCHIVED" data-slug="ARCHIVED">
              Archived Course
            </option>
          </select>
        </Col>
      </Row>
      <Row style={{ marginTop: "1.2em", marginLeft: "1em" }}>
        <Col>
          <input
            onChange={filterCourseNames}
            type="text"
            className="form-control border-rounded  rounded-pill"
            aria-label="Default"
            aria-describedby="inputGroup-sizing-default"
            placeholder={"Enter Course Name"}
            id={"cohort_search_id"}
            // value={courseNamefilter}
          />
        </Col>
      </Row>
      <Row
        className="flex-grow-1"
        style={{ marginTop: "1.2em", marginLeft: "1em" }}
      >
        {programSelected ? (
          <>{renderCourseAndCohorts()}</>
        ) : (
          <>
            <p className="item-not-selected">Please select Program</p>
          </>
        )}
      </Row>
    </article>
  );
};
export default withRouter(ManageCohort);
