import React from "react";
import ComponentNotificationBulb from "./../../../shared-components/notification-bulb/index";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import moment from "moment";

const tilejson = require("./../../../data/dashboard/tile-n.json");
const compArr = (tilejson || []).filter(
  (item) => item.type === "comprehensive"
);
const gplcArr = (tilejson || []).filter((item) => item.type === "gplc");
const microArr = (tilejson || []).filter((item) => item.type === "micro");

export default function TileN() {
  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "comprehensive") {
      thisArr = (compArr || [])[0].data || [];
      char = "comp";
    }
    if (str === "gplc") {
      thisArr = (gplcArr || [])[0].data || [];
      char = "gplc";
    }
    if (str === "micro") {
      thisArr = (microArr || [])[0].data || [];
      char = "micro";
    }
    return thisArr.map((item, index) => (
      <React.Fragment key={`t_n_${char}_${index}`}>
        <div className="dash-row-type-2 container rounded mb-2">
          <div className="row">
            <div className="t-row-name col-12 col-md-6 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
              <div className="t-avtr-wrap position-absolute">
                <a href={item.link}>
                  <ComponentAvatar
                    type="course"
                    data={{
                      img: item.img || {},
                    }}
                  />
                </a>
              </div>
              <a href={item.link} className="t-row-name-link">
                {item.name}
              </a>
              <small className="text-muted">
                {/* {dateformat(new Date(item.createddate), "P")} */}
                {moment(item?.createddate).format("MM-DD-YYYY")}
              </small>
              <div>
                <small>
                  <i className="material-icons text-primary">place</i>
                </small>
                <small className="text-black-50">{item.location}</small>
              </div>
            </div>
            <div className="col-6 col-md-3 p-2 d-flex flex-column justify-content-center align-items-end">
              <a
                href={item.activeuserslink}
                className="d-inline-block text-center"
              >
                <strong>
                  {item.activeusers === 1
                    ? "1 User"
                    : item.activeusers + " Users"}
                </strong>
                <br />
                Active
              </a>
            </div>
            <div className="col-6 col-md-3 p-2 d-flex flex-column justify-content-center align-items-end">
              <ComponentCtaBtn
                notifications={item.notifications}
                data={{
                  tag: "a",
                  href: "#",
                  ctastyle: "primary",
                  text: "Review",
                  title: "Review",
                  size: "sm",
                }}
              />
            </div>
          </div>
        </div>
        <hr />
      </React.Fragment>
    ));
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-flex flex-row position-relative">
        <div className="dash-t-h-left flex-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>Discussion Forum Notifications</strong>
          </h2>
        </div>
        <div className="dash-t-h-right py-2 px-3 flex-grow-1 d-flex justify-content-md-end align-items-md-center">
          <div
            className="ml-md-2 d-inline-block"
            style={{ width: "1.5rem", height: "1.5rem" }}
          >
            <ComponentNotificationBulb count={"123"} />
          </div>
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container id="mapl_tabs" defaultActiveKey="comprehensive">
          <Row>
            <Col xs="12" className="mb-3">
              <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="comprehensive"
                  >
                    <strong>Comprehensive</strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="comprehensive">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("comprehensive")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}
