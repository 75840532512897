import React from "react";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Row, Col, Form, InputGroup } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import moment from "moment";

const tilejson = require("./../../../data/dashboard/tile-k.json");
const arr1 = (tilejson || []).filter((item) => item.type === "resources");

export default function TileK() {
  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "resources") {
      thisArr = (arr1 || [])[0].data || [];
      char = "resources";
    }

    return thisArr.map((item, index) => (
      <React.Fragment key={`t_k_${char}_${index}`}>
        <div className="dash-row-type-2 container rounded mb-2">
          <div className="row">
            <div className="t-row-name col-12 col-md-9 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
              <div className="t-avtr-wrap position-absolute">
                <a href={item.link}>
                  {item.type === "course" ? (
                    <ComponentAvatar
                      type="course"
                      data={{
                        img: item.img || {},
                      }}
                    />
                  ) : (
                    <ComponentAvatar
                      type="resource"
                      data={{
                        type: item.type,
                      }}
                    />
                  )}
                </a>
              </div>
              <small>
                <strong>{item.type}</strong>
              </small>
              <a href={item.link} className="t-row-name-link">
                {item.name}
              </a>
              <small className="text-muted">
                {moment(item?.createddate).format("MM-DD-YYYY")}
              </small>
            </div>
            <div className="col-12 col-md-3 p-2 d-flex flex-column justify-content-start align-items-center">
              <ComponentCtaBtn
                data={{
                  tag: "a",
                  href: "#",
                  ctastyle: "primary",
                  text: "Share",
                  title: "Share",
                  size: "sm",
                  primaryicon: "share",
                }}
              />
            </div>
          </div>
        </div>
        <hr />
      </React.Fragment>
    ));
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>Professional Development Resources</strong>
          </h2>
        </div>
      </div>
      <div className="dash-t-search p-2 px-md-3 d-flex flex-column">
        <Form>
          <Row>
            <Col xs="12">
              <InputGroup size="sm">
                <Form.Control
                  type="search"
                  placeholder="Search for Members / Groups"
                  aria-label="Search for Members / Groups"
                  aria-describedby="basic-addon2"
                  className="rounded-pill"
                />
                <InputGroup.Append>
                  <ComponentCtaBtn
                    data={{
                      tag: "button",
                      type: "submit",
                      ctastyle: "secondary",
                      text: "",
                      title: "Search",
                      size: "sm",
                      primaryicon: "search",
                    }}
                  />
                </InputGroup.Append>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <ComponentCustomScrollbar
          className="scroll-comp"
          style={{ height: "100%" }}
        >
          {renderData("resources")}
          <br />
        </ComponentCustomScrollbar>
      </div>
    </article>
  );
}
