import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import "../createCourse.scss";
import React, { useEffect, useState } from "react";

const defaultImg =
  "https://media-exp1.licdn.com/dms/image/C4E0BAQEpU5cJci7GvA/company-logo_200_200/0/1567603719788?e=2159024400&v=beta&t=xg2OyEeORko6uBc1CEvuI4xP7dvNq8U_IU7Dmf4hywg";
const ComponentAvtar = (props) => {
  const { avtrImg, avtrImgSlug } = props;

  const onAvtrFileChange = (event) => {
    props.onAvtrFileChange(event, "output-avtr");
  };
  // useEffect(() => {
  //   console.log('.....avtrImg', avtrImg)
  // })
  const clickavtrbtn = () => {
    props.clickbtn("Avtr-trigger");
  };
  React.useEffect(() => {
    if (props.isRound && props.isCreateProfile && !avtrImgSlug) {
      let element = document.getElementsByClassName("CreateProfileAvtr")[0];
      element?.classList.add("showInitials");
    } else {
      let element = document.getElementsByClassName("CreateProfileAvtr")[0];
      if (element?.classList.contains("showInitials")) {
        element?.classList.remove("showInitials");
        element.innerHTML = "";
      }
    }
  }, [props.initials, avtrImgSlug]);
  return (
    <div className={`default-img-width ${props.type}`} id="avtr-container">
      <div
        className={`c-avtr rounded-xl m-2 ${
          props.isRound && "isRound-container"
        }`}
        style={{ height: (props.isCreateCourse || props.isRound) && "20px" }}
      >
        <div className="c-avtr-img rounded-xl isRound-div">
          <div
            className={`c-avtr-img rounded-xl isRound-img ${
              props.isRound && props.isCreateProfile && "CreateProfileAvtr"
            }`}
            style={{
              backgroundImage: `url(${avtrImg})`,
            }}
            id="output-avtr"
          ></div>
        </div>
        {props.show && (
          <input
            accept="image/*"
            id="Avtr-trigger"
            type="file"
            style={{ visibility: "hidden" }}
            onChange={(event, id) => onAvtrFileChange(event, id)}
          ></input>
        )}
        {props.show && (
          <div
            className="position-absolute text-center rounded-circle border "
            style={{
              bottom: "-0.2rem",
              left: "50%",
              marginLeft: "-1rem",
              zIndex: "4",
            }}
          >
            <button
              type="button"
              className=" cta-comp btn rounded-pill btn-light icon-l no-text"
              title="Icon Image - Recommended Size 200*200"
              //150*150
              onClick={() => clickavtrbtn()}
            >
              <i className="_pi material-icons">camera_alt</i>
            </button>
          </div>
        )}
      </div>
      {/* <ComponentAvatar type="course" iseditable="true" /> */}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ComponentAvtar));
