import { types } from "./types";
const initialState = {
  facilitatorCourses: [],
  programData:[],
  //GPLC Fields
  districts: [],
  schools: [],
  cohortList: [],
  cohortDatagrouped: [],
  //MICRO Fields
  districtsMicro: [],
  schoolsMicro: [],
  cohortListMicro: [],
  cohortDatagroupedMicro: [],
  completedCourses: [],
  facilitatorFeedbacksCourses: {},
  monitoringCourses: [],
  positions: null,
  allDiscussionForums: [],
  forumReplies: [],
  isLoading: false,
  courseNames: {},
  microCatalogDescriptionData: [],
  catalogDescriptionData: [],
};
export const dashboards = (state = initialState, action: any) => {
  switch (action.type) {
    case types.MY_COURSES_FACILITATOR:
      return {
        ...state,
        facilitatorCourses: action.payload,
      };
    case types.RESUABLE_GET_ACTION: //reusable_get_action
      return {
        ...state,
        [action.label]: action.payload,
      };
    case types.USEABLE_GET_ACTION: //usable_get_action
      return {
        ...state,
        [action.label]: action.payload,
      };
    case types.SET_DASHBOARD_COURSE_NAMES: //reusable_get_action
      return {
        ...state,
        courseNames: { ...state.courseNames, ...action.payload },
      };
    case types.GET_PROGRAM_DATA:
      return {
        ...state,
        programData: action.payload,
      };
    case types.GET_MICRO_CATALOG_DESCRIPTION_DATA:
      return {
        ...state,
        microCatalogDescriptionData: action.payload,
      };
    case types.GET_DESCRIPTION_DATA:
      return {
        ...state,
        catalogDescriptionData: action.payload,
      };

    default:
      return state;
  }
};
