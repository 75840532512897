import React, { useState } from "react";
import ComponentCtaBtn from "./../cta-btn/index";
import { FormControl } from "react-bootstrap";
import { BlockPicker } from "react-color";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
export const CustomDropdownToggle = React.forwardRef(
  ({ children, onClick, config }, ref) => {
    const thisConfig = config || {};
    const variant = thisConfig.variant || "secondary";
    const size = thisConfig.size || "sm";
    const isoutline = thisConfig.isoutline || "false";
    const ddicon = thisConfig.icon || "arrow_drop_down";
    return (
      <button
        type="button"
        ref={ref}
        className={`btn position-relative pr-5 btn${
          ["true", true].includes(isoutline) ? "-outline" : ""
        }-${variant} ${
          size === "sm" ? "btn-sm" : ""
        } rounded-pill btn-block text-left`}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        <div className="position-absolute top-0 right-0 h-100 p-2 d-flex justify-content-center align-items-center">
          <i className="material-icons">{ddicon}</i>
        </div>
      </button>
      // <a
      //   href=""
      //   ref={ref}
      //   onClick={(e) => {
      //     e.preventDefault();
      //     onClick(e);
      //   }}
      // >
      //   {children}
      //   &#x25bc;
      // </a>
    );
  }
);

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
export const CustomDropdownMenu = React.forwardRef(
  (
    { children, style, className, "aria-labelledby": labeledBy, config },
    ref
  ) => {
    const [value, setValue] = useState("");
    const thisConfig = config || {};
    const variant = thisConfig.variant || "light";
    const size = thisConfig.size || "sm";
    const isoutline = thisConfig.isoutline || "false";

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        {/* <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        /> */}
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value)
          )}
        </ul>
      </div>
    );
  }
);

export const ColorDropdown = function (props) {
  const { className, defaultColor, onChange, disabled } = props;
  const popover = {
    position: "absolute",
    zIndex: "2",
  };
  const cover = {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  };
  const handleChange = (color) => {
    onChange(color.hex.toUpperCase());
  };
  const [displayPicker, setDisplayPicker] = useState(false);
  return (
    <div>
      <button
        type="button"
        disabled={disabled}
        className={`btn position-relative pr-5 btn-outline-secondary rounded-pill text-left`}
        onClick={() => {
          setDisplayPicker(!displayPicker);
        }}
        style={{ minWidth: "170px" }}
      >
        <div
          className="position-relative rounded-circle mr-2 d-inline-block border"
          style={{
            height: "1.5rem",
            width: "1.5rem",
            verticalAlign: "top",
            top: "-0.025rem",
            backgroundColor: defaultColor.length > 0 ? defaultColor : "#ffffff",
          }}
        ></div>
        {defaultColor.length > 0 ? defaultColor : "No Color Selected"}
        <div className="position-absolute top-0 right-0 h-100 p-2 d-flex justify-content-center align-items-center">
          <i className="material-icons">arrow_drop_down</i>
        </div>
      </button>
      {displayPicker ? (
        <div style={popover} className="pt-1">
          <div
            style={cover}
            onClick={() => {
              setDisplayPicker(false);
            }}
          />
          <BlockPicker
            className={className}
            color={defaultColor.length > 0 ? defaultColor : "#ffffff"}
            colors={[
              "#f44336",
              "#e91e63",
              "#9c27b0",
              "#673ab7",
              "#3f51b5",
              "#2196f3",
              "#03a9f4",
              "#00bcd4",
              "#009688",
              "#4caf50",
              "#8bc34a",
              "#cddc39",
              "#ffeb3b",
              "#ffc107",
              "#ff9800",
              "#ff5722",
              "#795548",
              "#3AB09E",
              "#000000",
              "#ffffff",
            ]}
            onChange={handleChange}
          />
        </div>
      ) : null}
    </div>
  );
};
