import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Row,
  InputGroup,
  FormControl,
  Modal,
  Pagination,
  Spinner,
} from "react-bootstrap";
import { store } from "../../../App";
import _ from "lodash";
import { types } from "../../../reducers/types";
import { AgGridReact } from "ag-grid-react";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import MasterDataActionBottons from "../../MasterData/MasterDataActionBottons";
import { getMembers, getRoles, getSchoolsByAccountId } from "../../../actions/MasterDataActions/AccountAction";


import { useDispatch, useSelector } from "react-redux";
import { withRouter, useParams } from "react-router-dom";

import TKLModal from "../../../shared-components/model/TKLModal";
import Tooltip from 'react-tooltip';
import UserData from "../../TKLAdmin/Organisation/UserData";
import {
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
  SYSTEM_ADMIN,
  TKL_ACCOUNT_MANAGER,
  TKL_FACILITATOR,
} from "../../../../src/constants.js/RoleNames";

function UpdateMicroCohortMembers(props) {
    const[showMemeberAdd, setShowMemeberAdd] = useState(true);
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);
    let { accountId, schoolId } = useParams();
    let dispatch = useDispatch();
    const { members, schoolsByAccountID} = useSelector((state) => state.tkladmin);
    const { apiSuccessMessage, apiErrorMessage } = useSelector(
        (state) => state.coursepath
      );
    const [rowData, setRowData] = useState();
    const [pageType, setPageType] = useState("");
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const loggedInUserRoles = useSelector((state) => state?.users?.userData?.roles);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.api.sizeColumnsToFit();
        params.api.hideOverlay();
      };

      useEffect(() => {
        let tableData = [];
        members?.data?.forEach((school, i) => { 
          tableData.push({
            serial: (members?.current_page - 1) * members?.per_page + i + 1,
            firstName: school?.firstName,
            middleName: school?.middleName,
            lastName: school?.lastName,
            schoolName: school?.schools?.[0]?.schoolName,
            email: school?.email,
            role: school?.roles,
            status: school.lastAccessedAt ? school?.status : "PENDING",
            type: "accountSchoolData",
            slug: school?.slug,
          });
        });
        let microFiltedData= [];
        let kidvisionFiltedData= [];
        tableData?.map((ele)=> {
          ele?.role?.map((item) => {
            if(item === "MICRO COURSE PROGRAM MEMBER (PAID USER)"){
              microFiltedData.push(ele);
            }else if(item === "KIDVISION PROGRAM MEMBER (PAID USER)"){
              kidvisionFiltedData.push(ele);
            }
          })
        })
        if(pageType === "micro"){
          setRowData(microFiltedData);
        }else if(pageType === "kidVision"){
          setRowData(kidvisionFiltedData);
        }else{
          setRowData(tableData);
        }   
      }, [members]); 
    
      useEffect(() => {
        let schoolParams = "schoolsIds[]=" + schoolId + "&";
        dispatch(getMembers(schoolParams, 1));
        dispatch(getRoles());
        dispatch(getSchoolsByAccountId(accountId));   
      },[apiSuccessMessage]);

      useEffect(() => {
        if(schoolsByAccountID[0]?.numberOfUsers == schoolsByAccountID[0]?.seatsFilled)
        {
          setShowMemeberAdd(false);
        } else {
          setShowMemeberAdd(true);
        }
      },[schoolsByAccountID]);

      useEffect(() => {
        if((window.location.pathname).includes("KidVision")){
          setPageType("kidVision");
          store.dispatch({
            type: types.ACCOUNT_TYPE,
            payload: 4,
          });
        }else if((window.location.pathname).includes("Micro")){
          setPageType("micro");
          store.dispatch({
            type: types.ACCOUNT_TYPE,
            payload: 2,
          });
        }
      },[])

  return(
  <div className="tkl-admin-add card-box bg-alice-blue p-3">
 
<Container
          className="card-box rounded-box bg-white p-3"
          style={{ width: "90%" }}
        >
     <Container className="pt-2">
          <Row>
            <Col className="justify-content-center">
              <Container
                className="justify-content-center"
                style={{ textAlign: "center" }}
              >
                <h4 className="font-weight-600">Update Members</h4>
              </Container>
            </Col>
          </Row>
          {
            ((loggedInUserRoles.includes(SYSTEM_ADMIN)) || (loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER))  || (loggedInUserRoles.includes(TKL_FACILITATOR))) ?
              <Row>
                <Col>
                  <Button
                    className="btn-outline btn-blue  m-2 pl-3 pr-3 rounded-pill "
                    onClick={() => {
                      if((window.location.pathname).includes("KidVision")){
                        setPageType("kidVision");
                        store.dispatch({
                          type: types.ACCOUNT_TYPE,
                          payload: 4,
                        });
                      }else{
                        setPageType("micro");
                        store.dispatch({
                          type: types.ACCOUNT_TYPE,
                          payload: 2,
                        });
                      }
                      setShow(true);
                    }}
                    disabled = {showMemeberAdd ? false : true}
                    data-tip= {!showMemeberAdd ? "seat is already filled" : ""}
                  >
                    Add Member
                  </Button>
                  {!showMemeberAdd &&
                    <Tooltip place="top" type="error" effect="solid" />
                  }
                </Col>
                <Col></Col>
                <Col></Col>
                <Col></Col>
              </Row>
              :
              ""
          }
        </Container>


        <Container>
            <div
              className="agGridWidth"
              style={{
                height:
                  rowData?.length === 0
                    ? 130 + rowData?.length * 40 + "px"
                    : rowData?.length < 5
                    ? 100 + rowData?.length * 40 + "px"
                    : 50 + rowData?.length * 40 + "px",
              }}
            >
              <div
                id="myGrid"
                style={{
                  height: "100%",
                  width: "99%",
                }}
                className="ag-theme-alpine root"
              >
                <AgGridReact
                  rowHeight={rowData?.length === 0 ? 50 : 50}
                  editType={"fullRow"}
                  frameworkComponents={{
                    userAction: MasterDataActionBottons,
                  }}
                  defaultColDef={{
                    editable: true,
                    sortable: true,
                    flex: 1,
                    minWidth: 40,
                  }}
                  suppressClickEdit={true}
                  onGridReady={onGridReady}
                  rowData={rowData}
                >
                  <AgGridColumn
                    pinned={"left"}
                    editable={false}
                    field="serial"
                    headerName="Entries"
                    minWidth={150}
                    cellClass={"center-align"}
                    headerClass={"center-header"}
                  />
                  <AgGridColumn
                    field="firstName"
                    editable={false}
                    minWidth={150}
                  />
                  <AgGridColumn
                    field="middleName"
                    editable={false}
                    minWidth={150}
                  />
                  <AgGridColumn
                    field="lastName"
                    editable={false}
                    minWidth={150}
                  />
                  <AgGridColumn
                    field="schoolName"
                    editable={false}
                    minWidth={150}
                  />
                  <AgGridColumn 
                  field="email" 
                  editable={false} 
                  minWidth={300}
                   />

                  <AgGridColumn
                    field="role"
                    tooltipField="role"
                    editable={false}
                    minWidth={300}
                  />
                  <AgGridColumn
                    field="status"
                    editable={false}
                    minWidth={150}
                  />

                  <AgGridColumn
                    pinned={"right"}
                    editable={false}
                    field="Edit"
                    cellRenderer="userAction"
                    minWidth={120}
                  />
                </AgGridReact>
              </div>
            </div>
          </Container>

</Container>          

<div>
          {
            <Modal show={show} onHide={handleClose} className="master-data">
              <Modal.Header closeButton>
                <Modal.Title>
                  <span className="font-weight-bold">Create Member</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <UserData
                  handleClose={handleClose}
                  isDistrict={false}
                  isMemberCreate={true}
                />
              </Modal.Body>
            </Modal>
          }
        </div>

        {apiSuccessMessage && (
          <TKLModal
            showDialog={true}
            msgType={"success"}
            message={apiSuccessMessage}
          />
        )}

  </div>);
}

export default withRouter(UpdateMicroCohortMembers);
