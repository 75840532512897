import React, { useState } from 'react';
import PanelDragger from './../default/panel-dragger';
import { Form, Col } from 'react-bootstrap';
import CustomizeCTAs from './../default/customize-ctas';

const ControlSelectDropdown = React.memo(function ControlSelectDropdown(props) {
  const { type, item, mode, pathindex, initSticky } = props;
  const [width, setWidth] = useState(item.width || "full");
  const content = item.content || {};
  const generateOptions = () => {
    let options = content.response || [];
    let optionsArr = [];
    if ([true, 'true'].includes(content.hasselectone)) {
      optionsArr.push(<option value="" key={`option_${mode}_${pathindex}_${0}`}>Select One</option>);
    }
    options.forEach((item, index) => {
      optionsArr.push(<option value={item.value} key={`option_${mode}_${pathindex}_${(index + 1)}`}>{item.label}</option>);
    });
    return optionsArr;
  };
  const generateDefaultValue = () => {
    let options = content.response || [];
    let defaultVal = '';
    for (let i=0; i < options.length; i++) {
      if ([true, 'true'].includes(options[i].isselected)) {
        defaultVal = options[i].value;
        break;
      }
    }
    return defaultVal;
  }
  switch (type) {
    case 'panel-dragger':
      return (<PanelDragger item={item} />);
    case 'path-dragger':
      return (<Col xs="12" xl={width === 'full' ? '12' : '6'} className="mb-2 px-1">
        <div className={`d-flex h-100 flex-column p-2 border border-dotted rounded-lg`}>
          {mode === 'edit' ? <>
            <CustomizeCTAs widthtoggle={() => {
              setWidth(width === "full" ? "half" : "full");
              if (initSticky && window && document) {
                setTimeout(() => {
                  initSticky();
                }, 1000);
              }
            }} />
          </> : <></>}
          <Form.Group controlId={`select_${mode}_${pathindex}`} className="m-0">
            <Form.Label><strong>{item.name}</strong></Form.Label>
            <Form.Control as="select" size="sm" className="rounded-pill">
              <option>Select One</option>
              <option>Option 1</option>
              <option>Option 2</option>
            </Form.Control>
          </Form.Group>
        </div>
      </Col>);
    case 'path-review':
      return (<Col xs="12" xl={width === 'full' ? '12' : '6'} className="mb-2 px-1">
        <div className={`d-flex h-100 flex-column p-2`}>
          <Form.Group controlId={`select_${mode}_${pathindex}`}>
            <Form.Label><strong>{content.title || ''}</strong></Form.Label>
            <Form.Control as="select" disabled size="sm" className="rounded-pill" defaultValue=''>
              {generateOptions()}
            </Form.Control>
          </Form.Group>
        </div>
      </Col>);
    case 'path-learn':
      return (<Col xs="12" xl={width === 'full' ? '12' : '6'} className="mb-2 px-1">
        <div className={`d-flex h-100 flex-column p-2`}>
          <Form.Group controlId={`select_${mode}_${pathindex}`}>
            <Form.Label><strong>{content.title || ''}</strong></Form.Label>
            <Form.Control as="select" size="sm" className="rounded-pill" defaultValue=''>
              {generateOptions()}
            </Form.Control>
          </Form.Group>
        </div>
      </Col>);
    case 'path-grade':
      return (<Col xs="12" xl={width === 'full' ? '12' : '6'} className="mb-2 px-1">
        <div className={`d-flex h-100 flex-column p-2`}>
          <Form.Group controlId={`select_${mode}_${pathindex}`}>
            <Form.Label><strong>{content.title || ''}</strong></Form.Label>
            <Form.Control as="select" size="sm" className="rounded-pill" disabled defaultValue={generateDefaultValue()}>
              {generateOptions()}
            </Form.Control>
          </Form.Group>
        </div>
      </Col>);
    default:
      return (<></>);
  };
});

export default ControlSelectDropdown;
