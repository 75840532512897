import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL_COHORT, URL } from "../../api/tklEnvironments";
import {
  hideLoader,
  showFirstValidationErrorFromAPI,
} from "components/utility/ReUsableFunctions";

export const createFolder = ({ name, userSlug, parentFolderSlug }) => {
  let currentFolderSlug =
    store && store.getState().filestorage.currentFolderPath;
  currentFolderSlug = currentFolderSlug[currentFolderSlug.length - 1].slug;
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let payload = {
      folderName: name,
      userSlug: userSlug,
      parentFolderSlug: parentFolderSlug,
    };
    axios
      .post(`${URL_COHORT}/tkldrive/create`, (payload = payload))
      .then(function (response) {
        hideLoader(initialTime);
        if (currentFolderSlug) {
          dispatch(listFolders(currentFolderSlug));
        } else {
          dispatch(listFolders());
        }
      })
      .catch(function (error) {
        if (currentFolderSlug) {
          dispatch(listFolders(currentFolderSlug));
        } else {
          dispatch(listFolders());
        }
        hideLoader(initialTime);
        console.error("..error", error);
      });
  };
};

export const listFolders = (folderSlug) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_LOADER });
    let baseFolderSlug = "";
    if (folderSlug) {
      baseFolderSlug = "?baseFolderSlug=" + folderSlug;
    }
    axios
      .get(`${URL_COHORT}/tkldrive/list${baseFolderSlug}`)
      .then(function (response) {
        // console.log(
        //   "/tkldrive/list response",
        //   response,
        //   response?.data?.data[0]?.fileContents
        // );
        if (baseFolderSlug == "") {
          dispatch({
            type: types.UPADTE_FOLDERS,
            payload: response?.data?.data,
          });
          dispatch({
            type: types.UPDATE_CURRENT_FOLDER_PATH,
            payload: [{ name: "/", slug: null }],
          });
          dispatch({
            type: types.UPDATE_FILECONTENTS,
            payload: [],
          });
        } else {
          dispatch({
            type: types.UPADTE_FOLDERS,
            payload: response?.data?.data[0]?.subFolders || [],
          });
          dispatch({
            type: types.UPDATE_FILECONTENTS,
            payload: response?.data?.data[0]?.fileContents || [],
          });
        }
        dispatch({ type: types.HIDE_MODAL_LOADER });
      })
      .catch(function (error) {
        dispatch({ type: types.HIDE_MODAL_LOADER });
        console.error("..error", error);
      });
  };
};

export const mapFileUpload = ({ folderSlug, fileName, fileUploadLocation }) => {
  return async (dispatch) => {
    let payload = {
      folderSlug: folderSlug,
      fileName: fileName,
      fileUploadLocation: fileUploadLocation,
    };
    axios
      .put(`${URL_COHORT}/map-file`, payload)
      .then(function (response) {
        // console.log("/map-file response", response);

        dispatch({
          type: types.UPDATE_UPLOADED_FILE_SLUG,
          payload: response?.data?.mediaSlug,
        });

        Promise.resolve(true);
      })
      .catch(function (error) {
        console.error("..error", error);
        Promise.reject(error);
      });
  };
};

export const deleteFolder = ({ folderSlug }) => {
  let currentFolderSlug =
    store && store.getState().filestorage.currentFolderPath;
  currentFolderSlug = currentFolderSlug[currentFolderSlug.length - 1].slug;
  return async (dispatch) => {
    axios
      .delete(`${URL_COHORT}/tkldrive/delete?folderSlug=${folderSlug}`)
      .then(function (response) {
        // console.log(
        //   `tkldrive/delete?folderSlug=${folderSlug} response`,
        //   response
        // );
        dispatch({
          type: types.API_SUCCESS,
          payload: response?.data?.response,
        });
        if (currentFolderSlug) {
          dispatch(listFolders(currentFolderSlug));
        } else {
          dispatch(listFolders());
        }
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          dispatch({
            type: types.API_ERROR,
            payload: "Failed to Delete Folder",
          });
        }
        if (currentFolderSlug) listFolders(currentFolderSlug);
        else listFolders();
      });
  };
};

export const renameFolder = ({ oldFolderSlug, newFolderName }) => {
  let currentFolderSlug =
    store && store.getState().filestorage.currentFolderPath;
  currentFolderSlug = currentFolderSlug[currentFolderSlug.length - 1].slug;
  // console.log({ oldFolderSlug: oldFolderSlug, newFolderName: newFolderName });
  let payload = { oldFolderSlug: oldFolderSlug, newFolderName: newFolderName };
  return async (dispatch) => {
    axios
      .put(`${URL_COHORT}/tkldrive/rename`, payload)
      .then(function (response) {
        // console.log(`tkldrive/rename response`, response);
        dispatch({
          type: types.API_SUCCESS,
          payload: response?.data?.response,
        });

        if (currentFolderSlug) {
          dispatch(listFolders(currentFolderSlug));
        } else {
          dispatch(listFolders());
        }
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          dispatch({
            type: types.API_ERROR,
            payload: "Failed to Rename Folder",
          });
        }
        if (currentFolderSlug) listFolders(currentFolderSlug);
        else listFolders();
      });
  };
};

export const getAwsToken = () => {
  return async (dispatch) => {
    axios
      .get(`${URL_COHORT}/token`)
      .then(function (response) {
        // console.log("/token response", response, {
        //   accessKeyId: response?.data?.tokenData?.key,
        //   secretAccessKey: response?.data?.tokenData?.secret,
        // });
        dispatch({
          type: types.UPDATE_AWS_CREDENTIALS,
          payload: {
            accessKeyId: response?.data?.tokenData?.key,
            secretAccessKey: response?.data?.tokenData?.secret,
            token: response?.data.tokenData?.token,
          },
        });
        Promise.resolve(true);
      })
      .catch(function (error) {
        console.error("..error", error);
        Promise.reject(error);
      });
  };
};
