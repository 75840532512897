import React, { useState, useEffect } from "react";
import ComponentResponsiveImg from "./../responsive-img/index";
import ComponentCtaBtn from "./../cta-btn/index";
// import { _TrianglifyImg } from './../../shared/services/constants';
import { _GeopatternImg } from "./../../shared/services/constants";
import "./_index.scss";

const ComponentPageBanner = React.memo(function ComponentPageBanner(props) {
  const data = (props || {}).data || {};
  const { bannerImage } = props;
  const iseditable = (props || {}).iseditable || "";

  const [bannerUrl, setBannerUrl] = useState("/Blank.gif");
  const [isBannerGenerated, setIsBannerGenerated] = useState(false);

  const defaultImageURL = `${
    process.env.PUBLIC_URL + "/Banner_defaut_image.png"
  }`;

  const imageCheck = () => {
    let imageFlag = true;
    if (data?.img?.xs) {
      if (data?.img?.xs?.includes("undefined")) {
        imageFlag = false;
      }
    } else if (data?.img) {
      if (data?.img?.includes("undefined")) {
        imageFlag = false;
      }
    } else {
      imageFlag = false;
    }
    return imageFlag;
  };

  const hasImages = imageCheck();

  useEffect(() => {
    if (!hasImages && !isBannerGenerated) {
      setBannerUrl(defaultImageURL);
      setIsBannerGenerated(true);
    }
  }, [isBannerGenerated]);

  return (
    <>
      <div className="pg-ban-comp">
        {data.type === "image" && hasImages ? (
          <div
            className="pg-ban-bg"
            style={{
              height: data.isCatalogue && "calc(100px - -100px)",
            }}
          >
            <ComponentResponsiveImg imgdata={data.img} />
          </div>
        ) : (
          <></>
        )}
        {data.type === "image" && !hasImages ? (
          <div
            className="pg-ban-bg"
            style={{
              backgroundImage: `url(${bannerImage ? bannerImage : bannerUrl})`,
              backgroundSize: "100% 100%",
            }}
          ></div>
        ) : (
          <></>
        )}
        {/* <div className="pg-ban-bg">
        {data.type === 'image' && hasImages ? <ComponentResponsiveImg imgdata={data.img} /> : <></>}
        {data.type === 'image' && !hasImages ?
          <img src={bannerUrl} />
          : <></>}
      </div> */}
        <div className="pg-ban-buf">
          {iseditable ? (
            <div
              className="position-absolute text-center rounded-circle border"
              style={{
                bottom: "0.5rem",
                left: "50%",
                marginLeft: "-0.9rem",
                zIndex: "4",
              }}
            >
              <ComponentCtaBtn
                data={{
                  tag: "button",
                  type: "button",
                  ctastyle: "light",
                  text: "",
                  title: "Update Banner Image",
                  primaryicon: "camera_alt",
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
});

export default ComponentPageBanner;
