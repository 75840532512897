import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { authors, domains } from "../../reducers/data";
import { URL } from "../../api/tklEnvironments";

export const showReview = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.SHOW_REVIEW_COMMENTS, payload });
  };
};

export const addStep = (payload, scrollID) => {
  return (dispatch) => {
    axios
      .post(`${URL}/create-activity`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: scrollID },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        //console.error("..error", error);
      });
  };
};

export const addIntroStep = (payload, scrollID) => {
  return (dispatch) => {
    axios
      .post(`${URL}/add-step-introduction`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: scrollID },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const putIntroStep = (payload, scrollID, courseSlug, props) => {
  return (dispatch) => {
    axios
      .put(`${URL}/update-step-introduction`, payload)
      .then(function (response) {
        let responseData = response?.data?.response;
        if (courseSlug) {
          let updatedActivity = responseData?.introductionStep?.slug;
          props?.history.push(
            "/introductionActivity/" +
              courseSlug +
              "/CoursePath/" +
              updatedActivity +
              "/1/activity-path/edit"
          );
        }

        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: scrollID },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const updateStep = (payload, scrollID, courseSlug, props) => {
  return (dispatch) => {
    axios
      .put(`${URL}/update-activity`, payload)
      .then(function (response) {
        let responseData = response?.data?.response;
        if (responseData.message === "Activity updated") {
          if (courseSlug) {
            let updatedActivity = responseData?.activity?.slug;
            props?.history.push(
              "/fragmentsActivity/" +
                courseSlug +
                "/CoursePath/" +
                updatedActivity +
                "/activity-path/edit"
            );
          }
          dispatch({
            type: types.ADD_MODULE,
            payload: { isLoading: true, scrollID: scrollID },
          });
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const updateSectionCount = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.UPDATE_SECTION_COUNT, payload });
  };
};

export const updateModuleCount = (payload) => {
  ////console.log("updateModuleCount payload", payload);
  return (dispatch) => {
    dispatch({ type: types.UPDATE_MODULE_COUNT, payload });
  };
};

export const addModulePost = (payload) => {
  return (dispatch) => {
    axios
      .post(`${URL}/create-module`, payload)
      .then(function (response) {
        ////console.log("module...response", response);
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "", successMessage: "Success" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
      });
  };
};

export const addIntroSectionPost = (payload) => {
  return (dispatch) => {
    dispatch({
      type: types.ADD_MODULE,
      payload: { isLoading: false, scrollID: "" },
    });
    axios
      .post(`${URL}/add-introduction-section`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const updateIntoSectionPost = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/update-introduction-section`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const updateModulePost = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/update-module`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
      });
  };
};

export const getStepTypes = () => {
  return (dispatch) => {
    let payload = [];

    axios
      .get(`${URL}/get-step-type`)
      .then(function (response) {
        if (response.data) {
          payload = response.data?.data;
        }

        dispatch({ type: types.GET_STEP_TYPES, payload });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const deleteActivity = (activityslug) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-activity/${activityslug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_STEP, isLoading: true });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const deleteIntroActivity = (activityslug) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-step-introduction/${activityslug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_STEP, isLoading: true });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const deleteCourse = (courseSlug) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-course/${courseSlug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_COURSE, isLoading: true });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const deleteModule = (moduleSlug) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-module/${moduleSlug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_MODULE, isLoading: true });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const copyItem = (item) => {
  return (dispatch) => {
    dispatch({ type: types.COPY_ITEM, item });
  };
};

export const pasteActivity = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/copy-activity`, payload)
      .then(function (response) {
        ////console.log("...response", response);
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const pasteIntroStep = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/copy-intro-step`, payload)
      .then(function (response) {
        //console.log("...response", response);
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const sendToReview = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/submit-for-review`, payload)
      .then(function (response) {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Sent  for Review Successfully",
          pathName: "/",
        });
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const sendFeedBack = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/send-feedback`, payload)
      .then(function (response) {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Feedback Sent Successfully",
          pathName: "/",
        });
        sessionStorage.removeItem("feedbackSentForMinOne");
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const denyCourse = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/deny-course`, payload)
      .then(function (response) {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Course Denied Successfully",
          pathName: "/",
        });
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const submitForapprove = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/submit-for-approve`, payload)
      .then(function (response) {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Approved the course successfully",
          pathName: "/",
        });
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const pasteModule = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/copy-module`, payload)
      .then(function (response) {
        ////console.log("...response", response);
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};
export const pasteSection = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/copy-section`, payload)
      .then(function (response) {
        ////console.log("...response", response);
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const deleteSection = (sectionSlug) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-section/${sectionSlug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_SECTION, isLoading: true });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const deleteSectionIntro = (sectionSlug) => {
  return (dispatch) => {
    dispatch({ type: types.DELETE_SECTION_INTRO, isLoading: false });
    axios
      .delete(`${URL}/delete-introduction-section/${sectionSlug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_SECTION_INTRO, isLoading: true });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updateStepPosition = (slug, newPosition) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-section/`)
      .then(function (response) {
        dispatch({ type: types.DELETE_SECTION, isLoading: true });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

// dispatch(updateStepPosition(slug, newPosition)),
// export const updateStepPosition
export const getmodules = (slug) => {
  let stepTypes = [];
  return (dispatch) => {
    if (slug) {
      dispatch({ type: types.SHOW_LOADER });
      axios
        .get(`${URL}/get-modules/${slug}`)
        .then(function (response) {
          axios
            .get(`${URL}/get-step-type`)
            .then(function (responseStep) {
              if (responseStep.data) {
                stepTypes = responseStep.data?.data;
                let coursePathData = [];
                if (response.data) {
                  let responseData = response.data?.response;

                  let introSection = responseData?.introductionSection?.[0];

                  if (introSection) {
                    let introSecObj = {
                      type: "subheader",
                      name: introSection?.name,
                      title: "Introduction: " + introSection?.name,
                      desc: introSection?.description,
                      courseSlug: slug,
                      slug: introSection?.slug,
                      activities: getListOfIntoActivities(introSection),
                      isIntro: true,
                      reviewComments: introSection?.reviewComments,
                      // id: "subheader_" + 1 + "." + 1,
                      id: "Introsubheader_" + 1 + "." + 1,
                    };

                    dispatch({ type: types.GET_INTRO_SECTION, introSecObj });
                  }

                  responseData?.modules?.forEach((object, index) => {
                    let moduleObj = {
                      type: "header",
                      id: "header_" + (index + 1),
                      name: object.name,
                      title: "Module " + (index + 1) + ":" + " " + object.name,
                      courseObjective: object.courseObjectiveIndex,
                      moduleObjective: object.objective,
                      link: "#",
                      desc: object.description,
                      module_index: index + 1,
                      sectionCount: object.sectionWithActivities.length,
                      moduleSlug: object.slug,
                      courseSlug: slug,
                      reviewComments: object.reviewComments,
                    };
                    coursePathData.push(moduleObj);
                    getSectionData(
                      object.sectionWithActivities,
                      coursePathData,
                      moduleObj,
                      stepTypes
                    );
                  });

                  dispatch({ type: types.GET_MODULE, coursePathData });

                  dispatch({ type: types.HIDE_LOADER });
                }
              }
            })
            .catch(function (error) {
              if (error?.response?.data?.errors?.name) {
                dispatch({
                  type: types.API_ERROR,
                  payload: error?.response?.data?.errors?.name?.[0],
                });
              }
              console.error("..error", error);
            });
        })
        .catch(function (error) {
          console.error("..error", error);

          dispatch({ type: types.HIDE_LOADER });
        });
    }
  };
};

const getSectionData = (
  sectionObject,
  coursePathData,
  moduleObj,
  stepTypes
) => {
  // console.log("......sectionObject", sectionObject);
  if (sectionObject?.length > 0) {
    (sectionObject || []).forEach((section, secIndex) => {
      let sectionObj = {
        type: "subheader",
        headerid: "header_" + moduleObj.module_index,
        id: "subheader_" + moduleObj.module_index + "." + (secIndex + 1),
        name: section.name,
        title:
          "Section " +
          moduleObj.module_index +
          "." +
          (secIndex + 1) +
          ":" +
          " " +
          section.name,
        courseObjective: section.courseObjectiveIndex,
        moduleObj: section.moduleObjectiveIndex,
        link: "#",
        desc: section.description,
        sectionIndex: secIndex + 1,
        activities: getListOfActivities(
          section,
          moduleObj.module_index,
          secIndex,
          stepTypes
        ),
        slug: section.slug,
        moduleSlug: section.moduleSlug,
        reviewComments: section.reviewComments,
      };

      ////console.log("object : Module", section, sectionObj);
      coursePathData.push(sectionObj);
    });
  } else {
    let sectionObj = {
      type: "subheader",
      name: "",
      headerid: "header_" + moduleObj.module_index,
      title: "",
      courseObjective: [],
      moduleObj: [],
      desc: "section.description",
      sectionIndex: 0,
      moduleSlug: moduleObj.moduleSlug,
    };
    //coursePathData.push(sectionObj);
  }
};

export const getListOfActivities = (section, modIndex, secIntex, stepTypes) => {
  //console.log("stepTypes : ", stepTypes);
  let listOfActivities = [];
  // console.log("......section", section);
  if (section.activities.length > 0) {
    (section.activities || []).forEach((step, stepIndex) => {
      let stepType = (stepTypes || []).find(
        (obj) => obj.slug === step.stepTypeSlug
      );
      let activity = {
        name: step.name,
        title:
          "Step " +
          modIndex +
          "." +
          (secIntex + 1) +
          "." +
          (stepIndex + 1) +
          ":" +
          " " +
          step.name,
        link: "#",
        id: "activity_1_" + (stepIndex + 1),
        headerid: section.headerid,
        sectionid: section.id,
        activity: {
          title: stepType?.title,
          slug: step?.stepTypeSlug,
          icon: stepType?.icon,
        },
        index: step.index,
        points: step.points,
        fragmentCount: step.fragmentCount,
        type: "activity",
        iscovered: true,
        moduleObjective: step.moduleObjectiveIndex,
        slug: step.slug,
        sectionSlug: section.slug,
        reviewComments: step.reviewComments,
      };
      listOfActivities.push(activity);
    });
  } else {
    let activity = {
      name: "",
      title: "",
      link: "#",
      id: "activity_1_" + 1,
      headerid: section.headerid,
      sectionid: section.id,
      activity: {},
      index: 0,
      points: 0,
      type: "activity",
      iscovered: true,
      moduleObjective: [],
      slug: "step.slug",
      sectionSlug: section.slug,
    };
    //listOfActivities.push(activity);
  }

  ////console.log("getSteps slug", section, modIndex, secIntex, listOfActivities);
  return listOfActivities;
};

export const getListOfIntoActivities = (section) => {
  let listOfActivities = [];

  (section?.introductionStep || []).forEach((step, stepIndex) => {
    let activity = {
      name: step.name,
      // title: "Step " + 1 + "." + (stepIndex + 1) + " : " + step.name,
      title: "Intro Step " + (stepIndex + 1) + ": " + step.name,
      link: "#",
      id: "activity_1_" + (stepIndex + 1),
      activity: {
        title: step?.stepType?.title,
        slug: step?.stepType?.slug,
        icon: step?.stepType?.icon,
      },
      fragmentCount: step.fragmentCount,
      index: step.index,
      points: step.points,
      type: "activity",
      iscovered: true,
      isIntroStep: true,
      slug: step.slug,
      sectionSlug: section.slug,
    };
    listOfActivities.push(activity);
  });
  return listOfActivities;
};

export const getSteps = (courseDetails) => {
  let sectionObjs = [...courseDetails];
  return (dispatch) => {
    (courseDetails || []).forEach((item, itemIndex) => {
      if (item.type === "subheader") {
        let listOfActivities = [];
        axios
          .get(`${URL}/get-activities/${item.slug}`)
          .then(function (response) {
            if (response.data) {
              (response.data || []).forEach((step, stepIndex) => {
                let activity = {
                  title: step.name,
                  link: "#",
                  id: "activity_1_" + (stepIndex + 1),
                  headerid: item.headerid,
                  sectionid: item.id,
                  activity: {
                    title: step.name,
                    id: step.name,
                    icon: step.stepTypeSlug,
                  },
                  points: 0,
                  type: "activity",
                  iscovered: true,
                  moduleObjective: step.moduleObjectiveIndex,
                };
                listOfActivities.push(activity);
              });
            }
          })
          .catch(function (error) {
            console.error("..error", error);
          });
        sectionObjs[itemIndex].activities = listOfActivities;
      }
    });
  };
};

export const addSectionPost = (payload, secCount) => {
  return (dispatch) => {
    ////console.log("addSectionPost : ", payload);
    axios
      .post(`${URL}/create-section`, payload)
      .then(function (response) {
        const res = response.data.response;
        ////console.log("ADD_MODULE", response, res.sectionSlug);
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "subheader_" + secCount },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
      });
  };
};

export const updateSectionPost = (payload, scrollID) => {
  return (dispatch) => {
    ////console.log("addSectionPost : ", payload);

    axios
      .put(`${URL}/update-section`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: scrollID },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export function getCourseDetails(slug) {
  return function (dispatch) {
    return axios
      .get(`${URL}/get-course/${slug}`)
      .then(function (response) {
        ////console.log("response : ", response.data, response.data.courseName);
        let appData = {};
        let applicationdata = response.data;
        // console.log("applicationdata : ", applicationdata);
        appData["courseName"] = applicationdata.courseName;
        appData["courseObjective"] = applicationdata.courseObjective;
        appData["courseDescription"] = applicationdata.courseDescription;
        appData["authors"] = applicationdata?.authors;
        appData["programsSelected"] = [];
        appData["gradeLevel"] = [];
        appData["contentArea"] = [];
        appData["tags"] = [];
        appData["frameworkDomains"] = [];
        appData["frameworkSubDomains"] = [];
        appData["slug"] = slug;
        appData["avatarImage"] = applicationdata.avatarImage;
        appData["bannerImage"] = applicationdata.bannerImage;
        appData["status"] = applicationdata.status;
        appData["createdBy"] = applicationdata?.createdBy;

        dispatch({ type: types.GET_COURSE_DETAILS, payload: appData });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
}
