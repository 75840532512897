import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MultiSelectDropdown from "../../Cohorts/CohortFields/MultiSelectDropdown";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  updateUserRoleStatus,
} from "../../../actions/MasterDataActions/UserAction";
import { useForm } from "react-hook-form";
import { getRoles } from "../../../actions/MasterDataActions/AccountAction";
import { TKLModal } from "../../../shared-components/model/TKLModal";
import { RiArrowLeftSFill } from "react-icons/ri";
import { TKLstaffRoles, userRoles } from "../../utility/ReUsableFunctions";
function UserFormMasterData(props) {
  const dispatch = useDispatch();
  const { updateData, isCreate, handleClose, current_page_number_active } =
    props;
  const [roleObject, setRoleObject] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userStatus, setUserStatus] = useState(
    updateData.status ? updateData.status : "INACTIVE"
  );

  const [allRoles, setAllRoles] = useState([]);
  const { apiSuccessMessage, apiErrorMessage } = useSelector(
    (state) => state.coursepath
  );
  const { roles } = useSelector((state) => state.tkladmin);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    // console.log("...roles", roles);
    setAllRoles([]);
    roles[0] &&
      roles[0]?.forEach((role) => {
        let newData = {};
        if (isCreate) {
          if (Object.values(TKLstaffRoles).indexOf(role) > -1) {
            newData = {
              name: role,
              slug: role, //role?.replace(" ", "-")?.toLowerCase(),
            };
            setAllRoles((data) => [...data, newData]);
          }
        } else {
          if (role !== userRoles?.TKL_MEMBER_FREE_USER) {
            newData = {
              name: role,
              slug: role, //role?.replace(" ", "-")?.toLowerCase(),
            };
            setAllRoles((data) => [...data, newData]);
          }
        }
      });
  }, [JSON.stringify(roles[0])]);

  useEffect(() => {
    let selectedRoles = [];
    allRoles &&
      updateData?.roles &&
      updateData?.roles?.forEach((role) => {
        if (allRoles.find((findrole) => findrole.name === role)) {
          selectedRoles.push({ name: role, slug: role, isChecked: true });
        }
      });
    if (
      selectedRoles?.findIndex(
        (roleData) => roleData?.slug === userRoles?.TKL_MEMBER_FREE_USER
      ) < 0
    ) {
      selectedRoles.push({
        name: userRoles?.TKL_MEMBER_FREE_USER,
        slug: userRoles?.TKL_MEMBER_FREE_USER,
        isChecked: true,
      });
    }
    setRoleObject(selectedRoles);
  }, [JSON.stringify(updateData?.roles), JSON.stringify(allRoles)]);

  const stateHandler = (event, checked, status, selectall) => {
    if (status !== "selectAll") {
      let newData = {};
      newData["name"] = event.target.value;
      newData["slug"] = event.target.id;
      checked ? (newData["isChecked"] = true) : (newData["isChecked"] = false);

      let updatedRoleObject = [...roleObject];

      let index = _.findIndex(updatedRoleObject, { name: newData.name });

      if (index === -1) {
        updatedRoleObject.push(newData);
      } else {
        updatedRoleObject.splice(index, 1);
      }

      setRoleObject(updatedRoleObject);
    } else {
      checked ? setRoleObject(roles) : setRoleObject([]);
    }
  };

  const changeStatus = (e) => {
    setIsSubmitted(true);
    setUserStatus(e.target.value);
  };

  const onSubmit = (e) => {
    setIsSubmitted(true);
    if (isCreate) {
      if (roleObject?.length > 0) {
        dispatch(
          addUser(
            {
              firstName: e.firstName,
              middleName: e.middleName,
              lastName: e.lastName,
              email: e.email,
              status: userStatus,
              roles: roleObject.map((obj) => obj.name),
              slug: updateData.slug,
            },
            true
          )
        )
          .then(() => {
            handleClose();
          })
          .catch(() => {
            console.log("....");
          });
      }
    } else {
      if (roleObject.length > 0) {
        dispatch(
          updateUserRoleStatus(
            {
              slug: updateData.slug,
              roles: roleObject.map((obj) => obj.name),
              email: e?.email,
              status: userStatus,
            },
            current_page_number_active,
            true
          )
        )
          .then(() => {
            handleClose();
          })
          .catch(() => {
            console.log("....");
          });
      }
    }
    setIsSubmitted(true);
  };

  // useEffect(() => {
  //   //  console.log(".....roleObject", roleObject);
  // }, [roleObject]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div> */}
        <Form.Group as={Row} controlId="firstName">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            First Name <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9">
            <Form.Control
              readOnly={!isCreate}
              type="text"
              size="sm"
              defaultValue={updateData.firstName ? updateData.firstName : ""}
              placeholder="Enter First Name"
              className="rounded-pill"
              {...register("firstName", { required: true })}
            />
            {errors.firstName && (
              <p className="show-txt-error p-1 m-2">First Name Name required</p>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="middleName">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            Middle Name
          </Form.Label>
          <Col sm="9">
            <Form.Control
              size="sm"
              readOnly={!isCreate}
              type="text"
              placeholder="Enter Middle Name"
              className="rounded-pill"
              defaultValue={updateData.middleName ? updateData.middleName : ""}
              {...register("middleName")}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="lastName">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            Last Name <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9">
            <Form.Control
              readOnly={!isCreate}
              type="text"
              size="sm"
              placeholder="Enter Last Name"
              className="rounded-pill"
              defaultValue={updateData.lastName ? updateData.lastName : ""}
              {...register("lastName", { required: isCreate ? true : false })}
            />
            {errors.lastName && (
              <p className="show-txt-error p-1 m-2">Last Name required</p>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="email">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            Email <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9">
            <Form.Control
              // readOnly={!isCreate}
              type="email"
              size="sm"
              placeholder="Enter Email"
              className="rounded-pill"
              defaultValue={updateData.email ? updateData.email : ""}
              {...register("email", { required: isCreate ? true : false })}
            />
            {isCreate && errors.email && (
              <p className="show-txt-error p-1 m-2">Email is required</p>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="email">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            Role<span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9">
            <div cassName="p-relative">
              <div className="multiple-select">
                <MultiSelectDropdown
                  width={"100%"}
                  options={allRoles}
                  // options={roleObject}
                  id={"roles"}
                  data={roleObject}
                  statesboolean={true}
                  onSelectFieldChange={stateHandler}
                  showSelectAll={true}
                />
              </div>
            </div>
            {roleObject.length === 0 && isSubmitted && (
              <p className="show-txt-error p-1 m-2">Please select Role</p>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="status">
          <Form.Label column sm="3" className="font-semi-bold-TKL pb-6">
            Status
          </Form.Label>
          <Col sm="9" className="select-container">
            <span className="select-arrow-icon material-icons">
              expand_more
            </span>
            <Form.Control
              onChange={changeStatus}
              as="select"
              size="sm"
              className="rounded-pill select-styles"
              defaultValue={
                updateData?.status ? updateData?.status : "INACTIVE"
              }
            >
              <option name="INACTIVE">INACTIVE</option>
              <option name="ACTIVE">ACTIVE</option>
            </Form.Control>
          </Col>
          {/* </Form.Group> */}
          {/* </div> */}
          <Row className="mt-3 mr-2 ml-auto mr-3s">
            <Button
              className="m-2 btn btn-outline btn-secondary rounded-pill"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button type="submit" className="m-2 btn btn-primary rounded-pill">
              {isCreate ? "Create" : "Update"}
            </Button>
          </Row>
        </Form.Group>
      </form>
      {apiErrorMessage && (
        <TKLModal
          showDialog={true}
          msgType={"warning"}
          message={apiErrorMessage}
          type="error"
        />
      )}
    </Container>
  );
}

export default UserFormMasterData;
