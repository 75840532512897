import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import FrameworkDomainForm from "./FrameworkDomain/FrameworkDomainForm";
import FrameworkSubDomainForm from "./FrameworkDomain/FrameworkSubDomain/FrameworkSubDomainForm";
import GradeLevelForm from "./GradeLevel/GradeLevelForm";
import TagsForm from "./Tags/TagsForm";
import UserForm from "./User/UserForm";
import UserFormMasterData from "./User/UserFormMasterData";
import ContentAreaForm from "./ContentArea/ContentAreaForm";
import MarketLocationForm from "./MarketLocation/MarketLocationForm";
import PositionTitleForm from "./PositionTitles/PositionTitleForm";
import CatalogueForm from "./CatalogueDescription/CatalogueForm";
import { types } from "../../reducers/types";
import { useDispatch, useSelector } from "react-redux";

function MasterDataActionBottons(props) {
  const dispatch = useDispatch();
  const handleCloseNew = () => {
    // return async (dispatch) => {
    if(localStorage.getItem("radioSelected")){
      dispatch({ type: types.IS_RELOAD, payload: true });
      setShow(false)
    }
  };

  const handleClose = () => {
      setShow(false)
    }

  let { data } = props;
  const [updateData, setUpdateData] = useState({});
  const [show, setShow] = useState(false);
  const handleShow = (data) => {
    setUpdateData(data);
    setShow(true);
  };
  return (
    <div>
      <div>
        <button
          title="Edit User"
          className="ml-2 icon-font btn rounded-pill cta-comp btn-outline-default btn-sm icon-l no-text"
          onClick={() => {
            handleShow(data);
          }}
        >
          <span class="material-icons">edit</span>
        </button>

        {/* <button
          title="Delete User"
          className="text-danger ml-2 icon-font btn rounded-pill cta-comp btn-outline-default btn-sm icon-l no-text"
          onClick={() => {}}
        >
          <span class="material-icons">delete_outline</span>
        </button> */}
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        enforceFocus={false}
        className="master-data"
      >
        <Modal.Header closeButton>
          <Modal.Title className="font-semi-bold-TKL">
            {data.type === "userData" && "Update User"}
            {data.type === "frameworkdomain" &&
              "Update Danielson Framework Domain"}
            {data.type === "frameworkSubDomain" &&
              "Update Danielson Framework Component"}
            {data.type === "tags" && "Update Tag"}
            {data.type === "gradelevel" && "Update Grade Level"}
            {data.type === "contentArea" && "Update Instructional Focus Area"}
            {data.type === "accountDistrictData" && "Update User"}
            {data.type === "accountSchoolData" && "Update User"}
            {data.type === "marketLocation" && "Update Market Location"}
            {data.type === "positionTitles" && "Update Position Title"}
            {data.type === "catalogueDescription" &&
              "Update Catalog Description"}
            {data.type1 === "AccountCatalogueDescription" && "Update Catalog Description"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {data.type === "userData" && (
            <UserFormMasterData
              updateData={updateData}
              handleClose={handleClose}
              isCreate={false}
            />
          )}

          {data.type === "accountSchoolData" && (
            <UserForm
              updateData={updateData}
              handleClose={handleClose}
              isCreate={false}
              type={"accountSchoolData"}
            />
          )}

          {data.type === "accountDistrictData" && (
            <UserForm
              updateData={updateData}
              handleClose={handleClose}
              isCreate={false}
              type={"accountDistrictData"}
            />
          )}

          {data.type === "frameworkdomain" && (
            <FrameworkDomainForm
              updateData={updateData}
              handleClose={handleClose}
              isCreate={false}
            />
          )}

          {data.type === "frameworkSubDomain" && (
            <FrameworkSubDomainForm
              isCreate={false}
              fdomain={data.fdomain}
              updateData={updateData}
              setUpdateData={setUpdateData}
              handleClose={handleClose}
            />
          )}

          {data.type === "tags" && (
            <TagsForm
              isCreate={false}
              updateData={updateData}
              setUpdateData={setUpdateData}
              handleClose={handleClose}
            />
          )}

          {data.type === "gradelevel" && (
            <GradeLevelForm
              isCreate={false}
              updateData={updateData}
              setUpdateData={setUpdateData}
              handleClose={handleClose}
            />
          )}

          {data.type === "contentArea" && (
            <ContentAreaForm
              isCreate={false}
              updateData={updateData}
              setUpdateData={setUpdateData}
              handleClose={handleClose}
            />
          )}
          {data.type === "marketLocation" && (
            <MarketLocationForm
              isCreate={false}
              updateData={updateData}
              setUpdateData={setUpdateData}
              handleClose={handleClose}
            />
          )}

          {data.type === "positionTitles" && (
            <PositionTitleForm
              isCreate={false}
              updateData={updateData}
              setUpdateData={setUpdateData}
              handleClose={handleClose}
            />
          )}

          {data.type === "catalogueDescription" && (
            <CatalogueForm
              isCreate={false}
              updateData={updateData}
              setUpdateData={setUpdateData}
              handleClose={handleClose}
            />
          )}

          {data.type1 === "AccountCatalogueDescription" && (
            <CatalogueForm
              isCreate={false}
              updateData={updateData}
              setUpdateData={setUpdateData}
              handleClose={handleCloseNew}
              type="accountDescriptionUpdate"
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MasterDataActionBottons;
