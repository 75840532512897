export const types = {
  HOMELOGIN: "HOMELOGIN",
  INITIAL_STATE: "INITIAL_STATE",

  AUTHORS_TAGS_DATA: "AUTHORS_TAGS_DATA",
  IS_LOADING: "IS_LOADING",
  IS_RELOAD: "IS_RELOAD",
  IS_RELOAD_TKL: "IS_RELOAD_TKL",
  IS_ACCOUNT_CREATION: "IS_ACCOUNT_CREATION",
  GENERATE_PASSWORD: "GENERATE_PASSWORD",

  CREATE_COURSE_FORM_DATA: "CREATE_COURSE_FORM_DATA",

  CREATE_COURSE_FORM_DATA_OBJECTIVE: "CREATE_COURSE_FORM_DATA_OBJECTIVE",
  DELETE_COURSE: "DELETE_COURSE",
  GET_PROGRAM_DETAILS: "GET_PROGRAM_DETAILS",
  CREATE_COURSE_FORM_PROGRAMS_ADD: "CREATE_COURSE_FORM_DATA_PROGRAMS_ADD",
  CREATE_COURSE_FORM_PROGRAMS_DELETE: "CREATE_COURSE_FORM_DATA_PROGRAMS_DELETE",

  GET_ALL_AUTHORS: "GET_ALL_AUTHORS",
  ADD_AUTHORS: "ADD_AUTHORS",
  AVATAR_IMAGE_UPLOAD_ADD: "AVATAR_IMAGE_UPLOAD_ADD",
  BANNER_IMAGE_UPLOAD_ADD: "BANNER_IMAGE_UPLOAD_ADD",
  DELETE_AUTHORS: "DELETE_AUTHORS",

  GET_ALL_GRADELEVELS: "GET_ALL_GRADELEVELS",
  ADD_GRADELEVEL: "ADD_GRADELEVEL",
  DELETE_GRADELEVEL: "DELETE_GRADELEVEL",

  GET_ALL_CONTENTAREA: "GET_ALL_CONTENTAREA",
  ADD_CONTENTAREA: "ADD_CONTENTAREA",
  DELETE_CONTENTAREA: "DELETE_CONTENTAREA",

  GET_ALL_TAGS: "GET_ALL_TAGS",
  ADD_TAGS: "ADD_TAGS",
  DELETE_TAGS: "DELETE_TAGS",

  VALIDATION_CHECK: "VALIDATION_CHECK",

  GET_ALL_FRAMEWORKS: "GET_ALL_FRAMEWORKS",
  ADD_FRAMEWORKS: "ADD_FRAMEWORKS",
  DELETE_FRAMEWORKS: "DELETE_FRAMEWORKS",

  ADD_FRAMEWORKS_SUB_DOMAIN: "ADD_FRAMEWORKS_SUB_DOMAIN",
  DELETE_FRAMEWORKS_SUB_DOMAIN: "DELETE_FRAMEWORKS_SUB_DOMAIN",
  DELETE_FRAMEWORKS_SUB_DOMAIN_NULL: "DELETE_FRAMEWORKS_SUB_DOMAIN_NULL",

  ADD_DATA_TO_SUBDOMAIN: "ADD_DATA_TO_SUBDOMAIN",
  REMOVE_DATA_FROM_SUBDOMAIN: "REMOVE_DATA_FROM_SUBDOMAIN",

  CHECK_SUBDOMAIN_VALUE: "CHECK_SUBDOMAIN_VALUE",
  COPY_ITEM: "COPY_ITEM",
  CHECKED_STATUS_CHANGE: "CHECKED_STATUS_CHANGE",

  GET_DATA_BY_SLUG: "GET_DATA_BY_SLUG",
  GET_COURSE_DETAILS: "GET_COURSE_DETAILS",
  GET_COHORT_COURSE_DETAILS: "GET_COHORT_COURSE_DETAILS",
  NAVIGATE_TO_COURSE_PATH_EDIT: "NAVIGATE_TO_COURSE_PATH_EDIT",

  GET_DATA_FOR_SAVE_DRAFT: "GET_DATA_FOR_SAVE_DRAFT",

  GO_BACK_TO_CREATE_COURSE: "GO_BACK_TO_CREATE_COURSE",

  GET_COURSE_PATH_DATA: "GET_COURSE_PATH_DATA",
  ADD_MODULE: "ADD_MODULE",
  ADD_MODULE_POST: "ADD_MODULE_POST",
  ADD_SECTION: "ADD_SECTION",
  ADD_SECTION_POST: "ADD_SECTION_POST",
  ADD_STEP: "ADD_STEP",
  UPDATE_STEP: "UPDATE_STEP",
  LOAD_COURSE_PATH_DATA: "LOAD_COURSE_PATH_DATA",
  UPDATE_SECTION_COUNT: "UPDATE_SECTION_COUNT",
  UPDATE_MODULE_COUNT: "UPDATE_MODULE_COUNT",
  UPDATE_SECTION: "UPDATE_SECTION",
  UPDATE_MODULE: "UPDATE_MODULE",
  GET_MODULE: "GET_MODULE",
  API_ERROR: "API_ERROR",
  LEANER_IS_CONTINUE: "LEANER_IS_CONTINUE",
  SELECTED_OBJECTIVES: "SELECTED_OBJECTIVES",
  EMPTY_COURSEPATH_DATA: "EMPTY_COURSEPATH_DATA",
  API_SUCCESS: "API_SUCCESS",
  GET_INTRO_SECTION: "GET_INTRO_SECTION",
  GET_SECTIONS: "GET_SECTIONS",
  GET_SECTION: "GET_SECTION",
  GET_STEP_TYPES: "GET_STEP_TYPES",
  SET_SCROLL: "SET_SCROLL",
  ACTIVITY_SETTINGS_CHECKBOXES: "ACTIVITY_SETTINGS_CHECKBOXES",
  GET_DRAFT_DATA: "GET_DRAFT_DATA",
  VISITED_TO_COURSE_PATH: "VISITED_TO_COURSE_PATH",
  ADD_ACTIVITY_HEADER_DATA: "ADD_ACTIVITY_HEADER_DATA",
  ENABLE_FROM_ACTIVITY: "ENABLE_FROM_ACTIVITY",
  DELETE_COMPONENT_DOMAIN_FROM_DOMAIN: "DELETE_COMPONENT_DOMAIN_FROM_DOMAIN",
  ADD_ACTIVITY_INDEXES: "ADD_ACTIVITY_INDEXES",
  RADIO_GROUP_FORM_DATA: "RADIO_GROUP_FORM_DATA",
  ADD_RADIO_OPTIONS: "ADD_RADIO_OPTIONS",
  ADD_NEW_RADIO_GROUP: "ADD_NEW_RADIO_GROUP",
  ADD_NEW_RICHTEXT_GROUP: "ADD_NEW_RICHTEXT_GROUP",
  ADD_COMMENTS: "ADD_COMMENTS",
  ADD_QUESTION_RADIO_GROUP: "ADD_QUESTION_RADIO_GROUP",
  ADD_DESCRIPTION_RADIO_GROUP: "ADD_DESCRIPTION_RADIO_GROUP",
  ADD_CORRECT_ANSWER_RADIO_GROUP: "ADD_CORRECT_ANSWER_RADIO_GROUP",
  ADD_INCORRECT_ANSWER_RADIO_GROUP: "ADD_INCORRECT_ANSWER_RADIO_GROUP",

  DELETE_RADIOGROUP_OPTION: "DELETE_RADIOGROUP_OPTION",
  CHECKBOX_FORM_DATA: "CHECKBOX_FORM_DATA",
  FRAGMENTS_SUBMIT_BUTTON_CLICKED: "FRAGMENTS_SUBMIT_BUTTON_CLICKED",
  FRAGMENTS_SHOW_COMMENTS: "FRAGMENTS_SHOW_COMMENTS",
  FRAGMENTS_RESET_BUTTON_CLICKED: "FRAGMENTS_RESET_BUTTON_CLICKED",
  DELETE_SELECTED_FRAGMENT: "DELETE_SELECTED_FRAGMENT",
  GET_SELECTED_FRAGMENTS: "GET_SELECTED_FRAGMENTS",
  DELETE_SELECTED_FRAGMENT_RADIO: "DELETE_SELECTED_FRAGMENT_RADIO",
  UPDATE_CONTINUE_WITH_COURSE_PATH: "UPDATE_CONTINUE_WITH_COURSE_PATH",
  UPDATE_FRAGMENT_POSITION: "UPDATE_FRAGMENT_POSITION",
  ADD_QUESTION_BOX: "ADD_QUESTION_BOX",
  SHOW_REVIEW_COMMENTS: "SHOW_REVIEW_COMMENTS",
  QUESTIONBOX_FORM_DATA: "QUESTIONBOX_FORM_DATA",
  SHOW_COMMENT_INDEX: "SHOW_COMMENT_INDEX",
  CHANGE_MODE: "CHANGE_MODE",
  CHANGE_USER_MODE: "CHANGE_USER_MODE",
  FRAMENT_UPDATE: "FRAMENT_UPDATE",

  ADD_QUESTION_INPUT: "ADD_QUESTION_INPUT",

  DEELTE_QUESTION_INPUT: "DEELTE_QUESTION_INPUT",

  ADD_SUBMITTED_ANSWERS: "ADD_SUBMITTED_ANSWERS",

  ADD_INPUT_BUTTON: "ADD_INPUT_BUTTON",
  INPUT_BUTTON_FORM_DATA: "INPUT_BUTTON_FORM_DATA",

  ADD_DATA_TO_SUBDOMAIN_FROM_GET_DATA_BY_SLUG:
    "ADD_DATA_TO_SUBDOMAIN_FROM_GET_DATA_BY_SLUG",

  ADD_DISCUSSION_FORM: "ADD_DISCUSSION_FORM",

  CLONE_FRAGMENT: "CLONE_FRAGMENT",

  DELETE_STEP: "DELETE_STEP",
  DELETE_MODULE: "DELETE_MODULE",
  DELETE_SECTION: "DELETE_SECTION",
  DELETE_SECTION_INTRO: "DELETE_SECTION_INTRO",
  ACTIVE_COHORTS: "ACTIVE_COHORTS",

  ADD_SELECTBOX_CHOICE: "ADD_SELECTBOX_CHOICE",
  ADD_INPUT_ELEMENT: "ADD_INPUT_ELEMENT",

  SELECT_BOX_OPTIONS_HANDLER: "SELECT_BOX_OPTIONS_HANDLER",

  SELECT_BOX_CORRECT_ANSWER_INDEX: "SELECT_BOX_CORRECT_ANSWER_INDEX",
  SELECT_BOX_OPTION_SELECTED: "SELECT_BOX_OPTION_SELECTED",

  CHECK_COURSE_NAME: "CHECK_COURSE_NAME",

  ADD_INITIAL_COMMENTS_TO_DISCUSSION_FORUM:
    "ADD_INITIAL_COMMENTS_TO_DISCUSSION_FORUM",

  TOTAL_COMMENTS_FOR_FORUM: "TOTAL_COMMENTS_FOR_FORUM",

  ALL_COMMENTS_DATA_FOR_FRAGMENT: "ALL_COMMENTS_DATA_FOR_FRAGMENT",

  COMMENT_SLUG_UPDATE: "COMMENT_SLUG_UPDATE",
  ADD_FILES_TO_FILEUPLOAD_FRAGMENT: "ADD_FILES_TO_FILEUPLOAD_FRAGMENT",
  DELETE_UPLOADED_FILE: "DELETE_UPLOADED_FILE",

  COHORTS_FORM_DATA: "COHORTS_FORM_DATA",
  ADD_COHORT_FIELDS: "ADD_COHORT_FIELDS",
  GET_COHORT_DATA: "GET_COHORT_DATA",
  STATES_FORM_DATA: "STATES_FORM_DATA",
  MANAGE_COHORT_DATA: "MANAGE_COHORT_DATA",
  UPDATE_COHORT_DATA: "UPDATE_COHORT_DATA",
  UPDATE_COHORT: "UPDATE_COHORT",
  SHOW_LOADER: "SHOW_LOADER",
  HIDE_LOADER: "HIDE_LOADER",
  GET_COHORT_CONTENT_DATA: "GET_COHORT_CONTENT_DATA",
  ACTIVE_USER_PROGRES: "ACTIVE_USER_PROGRESS",
  USER_POINTS: "USER_POINTS",
  GET_ACTIVITY_REVIEW_DATA: "GET_ACTIVITY_REVIEW_DATA",
  FILEUPLOAD_FILES_ADD: "FILEUPLOAD_FILES_ADD",
  TOTAL_QUESTION_FRAGMENTS: "TOTAL_QUESTION_FRAGMENTS",
  IMAGE_SLIDER_IMAGE_UPLOAD: "IMAGE_SLIDER_IMAGE_UPLOAD",
  ACTIVITY_COMPLETED_CLICKED: "ACTIVITY_COMPLETED_CLICKED",
  ADD_MODULES_DATA_FRAGMENTS: "ADD_MODULES_DATA_FRAGMENTS",
  COHORT_CONTENT_PUBLISH_TEXT: "COHORT_CONTENT_PUBLISH_TEXT",
  SAVE_SETTINGS_ONSAVE_TEXT: "SAVE_SETTINGS_ONSAVE_TEXT",
  INITIAL_ACTIVITY: "INITIAL_ACTIVITY",
  LAST_ACTIVITY: "LAST_ACTIVITY",
  NEXT_ACTIVITY_LABEL: "NEXT_ACTIVITY_LABEL",
  CURRENT_ACTIVITY_INDEX: "CURRENT_ACTIVITY_INDEX",
  TOTAL_SECTION_LENGTH: "TOTAL_SECTION_LENGTH",
  INTRODUCTION_ACTIVITY_DATA: "INTRODUCTION_ACTIVITY_DATA",
  INITIAL_COHORT_DATA: "INITIAL_COHORT_DATA",
  COHORT_STATUS_ACTIVITY_PAGE: "COHORT_STATUS_ACTIVITY_PAGE",
  TOTAL_POINTS_OBTAINED: "TOTAL_POINTS_OBTAINED",
  QUIZ_COMPLETED_COHORT: "QUIZ_COMPLETED_COHORT",
  INSTRUCTOR_FEEDBACK_READONLY: "INSTRUCTOR_FEEDBACK_READONLY",
  FILE_UPLOAD_DOWNLOADABLE: "FILE_UPLOAD_DOWNLOADABLE",
  COHORTS_FIELDS_ADD: "COHORTS_FIELDS_ADD",
  COHORTS_FIELDS_REMOVE: "COHORTS_FIELDS_REMOVE",
  //registration - Types
  GET_ALL_USERS: "GET_ALL_USERS",
  REGISTRATION_APPLICATION_INITIAL_DATA:
    "REGISTRATION_APPLICATION_INITIAL_DATA",
  REGISTRATION_FORM_DATA: "REGISTRATION_FORM_DATA",
  EXTERNAL_USER: "EXTERNAL_USER",
  ASSIGN_TOKEN: "ASSIGN_TOKEN",
  POSITION_TITLES: "POSITION_TITLES",
  MULTISELECT_FORM_DATA: "MULTISELECT_FORM_DATA",
  SINGLE_SELECTION_DRPDOWN: "SINGLE_SELECTION_DRPDOWN",
  GET_PROFILE_DATA: "GET_PROFILE_DATA",
  GET_OTHER_PROFILE_DATA: "GET_OTHER_PROFILE_DATA",
  ASSIGN_USER_ROLE: "ASSIGN_USER_ROLE",
  USER_CONTENT_DEVELOPER_COURSES: "USER_CONTENT_DEVELOPER_COURSES",
  GET_PROGRAM_DATA: "GET_PROGRAM_DATA",
  GET_MICRO_CATALOG_DESCRIPTION_DATA: "GET_MICRO_CATALOG_DESCRIPTION_DATA",
  GET_DESCRIPTION_DATA: "GET_DESCRIPTION_DATA",
  MY_COURSES_FACILITATOR: "MY_COURSES_FACILITATOR",
  ADD_ACCOUNT: "ADD_ACCOUNT",
  GET_ACCOUNT: "GET_ACCOUNT",

  GET_ACCCOUNT_MANAGERS: "GET_ACCCOUNT_MANAGERS",
  GET_SCHOOLS_BY_ID: "GET_SCHOOLS_BY_ID",
  GET_UNASSIGNED_MEMBERS: "GET_UNASSIGNED_MEMBERS",
  GET_MICRO_COUSRSE_DETAILS: "GET_MICRO_COUSRSE_DETAILS",
  GET_ROLES: "GET_ROLES",
  ACCOUNT_TYPE: "ACCOUNT_TYPE",
  LIST_OF_ACCOUNTS: "LIST_OF_ACCOUNTS",
  LIST_OF_MEMBERS: "LIST_OF_MEMBERS",
  LIST_OF_DISTRICT_ADMINS: "LIST_OF_DISTRICT_ADMINS",
  IS_LOADING_TKLADMIN: "IS_LOADING_TKLADMIN",
  GET_ALL_SUBDOMAINS: "GET_ALL_SUBDOMAINS",
  GET_CART_DATA: "GET_CART_DATA",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  COURSE_CATALOG: "COURSE_CATALOG",
  FEATURE_COURSE_CATALOG: "FEATURE_COURSE_CATALOG",
  CART_RELOAD: "CART_RELOAD",
  CATALOG_OPTIONAL_VALUES: "CATALOG_OPTIONAL_VALUES",
  GET_DISCUSSION_FAQ: "GET_DISCUSSION_FAQ",
  GET_ALL_LOCATIONS_DISCUOUNTCODE: "GET_ALL_LOCATIONS_DISCUOUNTCODE",
  ADD_LOCATION_TO_DISCUOUNTCODE: "ADD_LOCATION_TO_DISCUOUNTCODE",
  ADD_NEW_DISCOUNT_CODE: "ADD_NEW_DISCOUNT_CODE",
  REMOVE_LOCATION_FROM_DISCOUNT_CODE: "REMOVE_LOCATION_FROM_DISCOUNT_CODE",
  CODE_INPUT_SELECTION: "CODE_INPUT_SELECTION",
  ADD_LOCATION_TO_BUNDLE: "ADD_LOCATION_TO_BUNDLE",
  REMOVE_LOCATION_FROM_BUNDLE: "REMOVE_LOCATION_FROM_BUNDLE",
  CODE_INPUT_SELECTION_BUNDLE: "CODE_INPUT_SELECTION_BUNDLE",
  GET_DISCOUNT_CODE_DATA: "GET_DISCOUNT_CODE",
  GET_ALL_COURSES_DISCUOUNTCODE: "GET_ALL_COURSES_DISCUOUNTCODE",
  GET_ALL_MARKET_LOCATION: "GET_ALL_MARKET_LOCATION",
  INITIAL_DATA_DISCOUNT_CODE: "INITIAL_DATA_DISCOUNT_CODE",
  INITIAL_DATA_BUNDLE: "INITIAL_DATA_BUNDLE",
  INITIAL_DATA_BUNDLE_CODES: "INITIAL_DATA_BUNDLE_CODES",
  GET_BUNDLES_DATA: "GET_BUNDLES_DATA",
  RESUABLE_GET_ACTION: "RESUABLE_GET_ACTION",
  USEABLE_GET_ACTION: "USEABLE_GET_ACTION",
  LIST_OF_COHORT_MEMBERS: "LIST_OF_COHORT_MEMBERS",
  SELECTED_COHORT_GROUP: "SELECTED_COHORT_GROUP",
  GET_ASSIGNED_COURSES: "GET_ASSIGNED_COURSES",
  RESUABLE_GET_DATAINPUT_BY_LABEL_CART_REDUCER:
    "RESUABLE_GET_DATAINPUT_BY_LABEL_CART_REDUCER",
  RESUABLE_GET_DATAINPUT_FOR_ONE_LEVEL_CART_REDUCER:
    "RESUABLE_GET_DATAINPUT_FOR_ONE_LEVEL_CART_REDUCER",
  WELCOME_LETTER: "WELCOME_LETTER",
  RESUABLE_INNER_LABEL_VALUE_CHANGE: "RESUABLE_INNER_LABEL_VALUE_CHANGE",
  GET_ALL_USERS_MASTER_DATA: "GET_ALL_USERS_MASTER_DATA",
  INFORMATION_SHEETS: "INFORMATION_SHEETS",
  PAYMENT_INTENT: "PAYMENT_INTENT",
  CONFIRM_ORDER: "CONFIRM_ORDER",
  ADD_DATA_TO_STATE: "ADD_DATA_TO_STATE",
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  APPEND_NOTIFICATIONS: "APPEND_NOTIFICATIONS",
  NOTIFICATION_RELOAD: "NOTIFICATION_RELOAD",
  ADD_POSITION_TITLES_MASTER_DATA: "ADD_POSITION_TITLES_MASTER_DATA",
  GET_POSITION_TITLES_MASTER_DATA: "GET_POSITION_TITLES_MASTER_DATA",
  ADD_POST: "ADD_POST",
  GET_POSTS: "GET_POSTS",
  COMMUNITY_RELOAD: "COMMUNITY_RELOAD",
  BOOKMARKED_POST_RELOAD: "BOOKMARKED_POST_RELOAD",
  FORUM_RELOAD: "FORUM_RELOAD",
  GET_FORUMS: "GET_FORUMS",
  GET_RESOURCE: "GET_RESOURCE",
  RESOURCE_RELOAD: "RESOURCE_RELOAD",
  BANNER_RELOAD: "BANNER_RELOAD",
  // FOLLOWING_USERS: "FOLLOWING_USERS",
  // FOLLOWED_USERS: "FOLLOWED_USERS",
  // PROFESSIONAL_COLLEAGUES: "PROFESSIONAL_COLLEAGUES",

  GROUP_INVITE_USER_LIST: "GROUP_INVITE_USER_LIST",
  MY_CONNECTIONS: "MY_CONNECTIONS",
  ADD_MORE_CONTACTS: "ADD_MORE_CONTACTS",
  MEMBER_INVITATIONS: "MEMBER_INVITATIONS",
  PENDING_INVITATIONS: "PENDING_INVITAIONS",
  PROFESSIONAL_COLLEAGUES_RELOAD: "PROFESSIONAL_COLLEAGUES_RELOAD",
  COLLEAGUES: "COLLEAGUES",
  GET_BANNERS: "GET_BANNERS",
  GET_TRENDING_COURSES: "GET_TRENDING_COURSES",
  USER_CERTIFICATION_DATA: "USER_CERTIFICATION_DATA",
  RESUABLE_COMMUNITIY_REDUCER: "RESUABLE_COMMUNITIY_REDUCER",
  CREATE_POLL_ADD_OPTION: "CREATE_POLL_ADD_OPTION",
  CREATE_POLL_DELETE_OPTION: "CREATE_POLL_DELETE_OPTION",
  CREATE_POLL_EDIT_OPTION: "CREATE_POLL_EDIT_OPTION",
  CREATE_POLL_LABEL_CHANGE: "CREATE_POLL_LABEL_CHANGE",
  CREATE_POLL_OPTIONS_HANDLER: "CREATE_POLL_OPTIONS_HANDLER",
  CREATE_COURSE_RESUABLE_FIELD_VALUE: "CREATE_COURSE_RESUABLE_FIELD_VALUE",
  GET_PATH_RIGHT_UPLOAD_STATUS: "GET_PATH_RIGHT_UPLOAD_STATUS",
  GET_PATH_RIGHT_DATA: "GET_PATH_RIGHT_DATA",

  //Groups

  LIST_OF_GROUPS: "LIST_OF_GROUPS",
  LIST_OF_INVITED_GROUPS: "LIST_OF_INVITED_GROUPS",
  LIST_OF_POSTS_BY_GROUP: "LIST_OF_POSTS_BY_GROUP",
  IS_GROUP_RELOAD: "IS_GROUP_RELOAD",

  //Comments

  LIST_OF_COMMENTS_FOR_POST: "LIST_OF_COMMENTS_FOR_POST",

  //ALL Discussion Forums
  GET_ALL_DISCUSSION_FORUMS: "GET_ALL_DISCUSSION_FORUMS",

  //REPORTS
  GET_CONTENT_DEVELOPER_SUMMARY_REPORT_TOTALS:
    "GET_CONTENT_DEVELOPER_SUMMARY_REPORT_TOTALS",
  GET_COURSE_REGISTRATIONS_FOR_CONTENT_DEVELOPER:
    "GET_COURSE_REGISTRATIONS_FOR_CONTENT_DEVELOPER",
  COMPANY_REGISTRATIONS_SUMMARY_REPORTS:
    "COMPANY_REGISTRATIONS_SUMMARY_REPORTS",
  COMPANY_REGISTRATIONS_PARENT_COURSE: "COMPANY_REGISTRATIONS_PARENT_COURSE",
  COMPANY_REGISTRATIONS_INDIVIDUAL_COURSES:
    "COMPANY_REGISTRATIONS_INDIVIDUAL_COURSES",
  KID_VISION_SUMMARY_REPORTS:
    "KID_VISION_SUMMARY_REPORTS",
  KID_VISION_PARENT_COURSE: "KID_VISION_PARENT_COURSE",
  KID_VISION_INDIVIDUAL_COURSES:
    "KID_VISION_INDIVIDUAL_COURSES",
  MICRO_COURSE_SUMMARY_REPORTS:
    "MICRO_COURSE_SUMMARY_REPORTS",
  MICRO_COURSE_PARENT_COURSE: "MICRO_COURSE_PARENT_COURSE",
  MICRO_COURSE_INDIVIDUAL_COURSES:
    "MICRO_COURSE_INDIVIDUAL_COURSES",
  COURSE_FACILITATOR_SUMMARY_REPORTS: "COURSE_FACILITATOR_SUMMARY_REPORTS",
  COURSE_FACILITATOR_INDIVIDUAL_COURSES:
    "COURSE_FACILITATOR_INDIVIDUAL_COURSES",
  SET_DASHBOARD_COURSE_NAMES: "SET_DASHBOARD_COURSE_NAMES",
  CF_PAYMENTS: "CF_PAYMENTS",

  //course review page
  GET_COURSES_INSTRUCTOR_REVIEW: "GET_COURSES_INSTRUCTOR_REVIEW",

  //Fragments
  UPDATE_POINTS_EARNED: "UPDATE_POINTS_EARNED",

  //filestorage
  UPADTE_FOLDERS: "UPADTE_FOLDERS",

  CREATE_COMMON_FOLDER: "CREATE_COMMON_FOLDER",

  DELETE_COMMON_FOLDER: "DELETE_COMMON_FOLDER",

  RENAME_COMMON_FOLDER: "DELETE_COMMON_FOLDER",

  CREATE_USER_FOLDER: "CREATE_USER_FOLDER",

  DELETE_USER_FOLDER: "DELETE_USER_FOLDER",

  RENAME_USER_FOLDER: "RENAME_USER_FOLDER",

  CREATE_USER_SUB_FOLDER: "CREATE_USER_SUB_FOLDER",

  DELETE_USER_SUB_FOLDER: "DELETE_USER_SUB_FOLDER",

  RENAME_USER_SUB_FOLDER: "RENAME_USER_SUB_FOLDER",

  UPDATE_CURRENT_FOLDER_PATH: "UPDATE_CURRENT_FOLDER_PATH",

  UPDATE_FILECONTENTS: "UPDATE_FILECONTENTS",

  UPDATE_AWS_CREDENTIALS: "UPDATE_AWS_CREDENTIALS",

  UPDATE_UPLOADED_FILE_SLUG: "UPDATE_UPLOADED_FILE_SLUG",

  PROVIDE_FEEDBACK_IN_PROGRESS: "PROVIDE_FEEDBACK_IN_PROGRESS",
  SEND_EMAIL_IN_PROGRESS: "SEND_EMAIL_IN_PROGRESS",

  ADD_INSTRUCTOR_FEEDBACK_IN_PROGRESS: "ADD_INSTRUCTOR_FEEDBACK_IN_PROGRESS",
  SHOW_MODAL_LOADER: "SHOW_MODAL_LOADER",
  HIDE_MODAL_LOADER: "HIDE_MODAL_LOADER",
};
