import { Tab, Row, Col, Nav, Form } from "react-bootstrap";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ContextOffcanvas from "./../../../contexts/offcanvas-context";
import React, { useState, useContext } from "react";

const tilejson = require("./../../../data/dashboard/tile-h.json");
const arr1 = (tilejson || []).filter((item) => item.type === "cohorts");
const arr2 = (tilejson || []).filter((item) => item.type === "members");

export default function TileH(props) {
  const toggleOffcanvas = useContext(ContextOffcanvas);
  const role = props.role || "";
  const [tab, setTab] = useState("cohorts");
  const openOC = () => {
    if (toggleOffcanvas) {
      toggleOffcanvas(true, "OffcanvasCreateCohort", "Create New Cohort");
    }
  };
  React.useEffect(() => {
    sessionStorage.removeItem("showNext");
  }, []);
  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "cohorts") {
      thisArr = (arr1 || [])[0].data || [];
      char = "cohorts";
    }
    if (str === "members") {
      thisArr = (arr2 || [])[0].data || [];
      char = "members";
    }

    return thisArr.map((item, index) => (
      <React.Fragment key={`t_h_${char}_${index}`}>
        <div className="dash-row-type-2 container rounded mb-2">
          <div className="row w-100">
            <div className="t-row-name col-12 col-md-9 col-xl-8 col-xxl-9 position-relative p-2 d-flex flex-column justify-content-center">
              <a href={item.link} className="t-row-name-link">
                {item.name}
              </a>
              {str === "cohorts" ? (
                <small className="text-muted">
                  <strong>{item.assignments} Course Assignments</strong>
                </small>
              ) : (
                <></>
              )}
            </div>
            <div className="col-12 col-md-3 col-xl-4 col-xxl-3 p-2 d-flex flex-column justify-content-center align-items-end">
              {str === "cohorts" ? (
                <ComponentCtaBtn
                  data={{
                    tag: "a",
                    href: "#",
                    ctastyle: "primary",
                    text: "Assign Course",
                    title: "Assign Course",
                    size: "sm",
                    primaryicon: "done",
                  }}
                />
              ) : (
                <></>
              )}
              {str === "members" ? <></> : <></>}
            </div>
          </div>
        </div>
        <hr />
      </React.Fragment>
    ));
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>Active GPLC Accounts</strong>
          </h2>
          <ComponentCtaBtn
            className="d-md-none"
            data={{
              tag: "a",
              href: "#",
              ctastyle: "info",
              text: "Create Cohort",
              title: "Create Cohort",
              size: "sm",
              ctaicon: "add",
            }}
          />
        </div>
        <div className="dash-t-h-right py-2 px-3 flex-md-grow-1 d-none d-md-flex justify-content-end align-items-center">
          <ComponentCtaBtn
            onClick={openOC}
            data={{
              tag: "button",
              type: "button",
              ctastyle: "info",
              text: "Create Cohort",
              title: "Create Cohort",
              size: "sm",
              ctaicon: "add",
            }}
          />
        </div>
      </div>
      <div className="dash-t-form py-2 px-3">
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="tilehdistrict" className="m-0">
              <Form.Label>
                <strong>District</strong>
              </Form.Label>
              <Form.Control as="select" size="sm" className="rounded-pill">
                <option>Disctict / Account</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="tilehdischool" className="m-0">
              <Form.Label>
                <strong>School</strong>
              </Form.Label>
              <Form.Control as="select" size="sm" className="rounded-pill">
                <option>Location / School</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container id="mapl_tabs" defaultActiveKey="cohorts">
          <Row>
            <Col xs="12" className="mb-3">
              <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="cohorts"
                    onClick={() => setTab("cohorts")}
                  >
                    <strong>Cohorts</strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="members"
                    onClick={() => setTab("members")}
                  >
                    <strong>Members</strong>
                  </Nav.Link>
                </Nav.Item>
                {role === "accountmanager" && tab === "cohorts" ? (
                  <ComponentCtaBtn
                    className="position-absolute right-0 top-0 mr-2"
                    data={{
                      tag: "a",
                      href: "#",
                      ctastyle: "info",
                      text: "Manage Cohorts",
                      title: "Manage Cohorts",
                      size: "sm",
                      isoutline: "true",
                      primaryicon: "account_tree",
                    }}
                  />
                ) : (
                  <></>
                )}
                {role === "accountmanager" && tab === "members" ? (
                  <ComponentCtaBtn
                    className="position-absolute right-0 top-0 mr-2"
                    data={{
                      tag: "a",
                      href: "#",
                      ctastyle: "info",
                      text: "Manage Members",
                      title: "Manage Members",
                      size: "sm",
                      isoutline: "true",
                      primaryicon: "group",
                    }}
                  />
                ) : (
                  <></>
                )}
              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="cohorts">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("cohorts")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="members">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("members")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}
