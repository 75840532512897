import { createFolder } from "actions/FileStorageActions/FileStorageAction";

import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";

function CreateNewFolder({ parentFolderSlug }) {
  const [showCreateNewFolderPopup, setShowCreateNewFolderPopup] =
    useState(false);
  const dispatch = useDispatch();
  const [folderName, setFolderName] = useState("");
  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
  };
  return (
    <div>
      <div className="d-flex flex-column justify-content-center align-items-center p-1 m-2">
        <span
          class="material-icons FileStorageRoot__toolbar__folder__icon"
          title="Create New Folder"
          onClick={() => setShowCreateNewFolderPopup(true)}
        >
          create_new_folder
        </span>
        <label>Create New Folder</label>
        <Modal
          className="modal-styles"
          show={showCreateNewFolderPopup}
          onHide={() => {
            setShowCreateNewFolderPopup(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Create New Folder"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              placeholder="Enter New Folder Name"
              className="m-2 p-2"
              value={folderName}
              onChange={handleFolderNameChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-outline btn-secondary rounded-pill "
              title={"Cancel"}
              onClick={() => {
                setShowCreateNewFolderPopup(false);
              }}
            >
              {"Cancel"}
            </Button>

            <Button
              className=" btn-outline rounded-pill btn"
              title={"Ok"}
              onClick={() => {
                if (folderName && parentFolderSlug) {
                  dispatch(
                    createFolder({
                      name: folderName,
                      parentFolderSlug: parentFolderSlug,
                    })
                  );
                }
                setShowCreateNewFolderPopup(false);
              }}
            >
              {"Ok"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default CreateNewFolder;
