import React from "react";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Tab, Row, Col, Nav, Form } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";

const tilejson = require("./../../../data/dashboard/tile-i.json");
const arr1 = (tilejson || []).filter((item) => item.type === "gplc");
const arr2 = (tilejson || []).filter((item) => item.type === "micro");

export default function TileI() {
  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "gplc") {
      thisArr = (arr1 || [])[0].data || [];
      char = "gplc";
    }
    if (str === "micro") {
      thisArr = (arr2 || [])[0].data || [];
      char = "micro";
    }

    return thisArr.map((item, index) => (
      <React.Fragment key={`t_i_${char}_${index}`}>
        <div className="dash-row-type-2 container rounded mb-2">
          <div className="row">
            {str === "gplc" ? (
              <div className="t-row-name col-12 col-md-6 position-relative p-2 d-flex flex-column justify-content-center">
                <a href={item.link} className="t-row-name-link">
                  {item.name}
                </a>
                <a
                  href={item.courselink}
                  className="d-block position-relative pl-5 text-dark mt-2"
                  style={{ minHeight: "2rem", lineHeight: "1.2" }}
                >
                  <div
                    className="position-absolute top-0 left-0"
                    style={{ width: "2rem", height: "2rem" }}
                  >
                    <ComponentAvatar
                      type="course"
                      data={{
                        img: item.courseimg || {},
                      }}
                    />
                  </div>
                  <small>{item.coursename}</small>
                </a>
              </div>
            ) : (
              <></>
            )}
            {str === "micro" ? (
              <div className="t-row-name col-12 col-md-6 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
                <div className="t-avtr-wrap position-absolute">
                  <a href={item.courselink}>
                    <ComponentAvatar
                      type="course"
                      data={{
                        img: item.courseimg || {},
                      }}
                    />
                  </a>
                </div>
                <a href={item.courselink} className="t-row-name-link">
                  {item.coursename}
                </a>
              </div>
            ) : (
              <></>
            )}
            <div className="col-6 col-md-3 p-2 d-flex flex-column justify-content-center align-items-end">
              <a
                href={item.activeuserslink}
                className="d-inline-block text-center"
              >
                <strong>
                  {item.ActiveUsers === 1
                    ? "1 User"
                    : item.ActiveUsers + " Users"}
                </strong>
                <br />
                Active
              </a>
            </div>
            <div className="col-6 col-md-3 p-2 d-flex flex-column justify-content-center align-items-end">
              <a
                href={item.completeduserslink}
                className="d-inline-block text-center text-dark"
              >
                <strong>
                  {item.usersCompletedCourse === 1
                    ? "1 User"
                    : item.usersCompletedCourse + " Users"}
                </strong>
                <br />
                Completed Course
              </a>
            </div>
          </div>
        </div>
        <hr />
      </React.Fragment>
    ));
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>Active Course Enrollment</strong>
          </h2>
        </div>
      </div>
      <div className="dash-t-form py-2 px-3">
        <Form>
          <Form.Row>
            <Form.Group as={Col} controlId="tilehdistrict" className="m-0">
              <Form.Label>
                <strong>District</strong>
              </Form.Label>
              <Form.Control as="select" size="sm" className="rounded-pill">
                <option>Disctict / Account</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} controlId="tilehdischool" className="m-0">
              <Form.Label>
                <strong>School</strong>
              </Form.Label>
              <Form.Control as="select" size="sm" className="rounded-pill">
                <option>Location / School</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container id="mapl_tabs" defaultActiveKey="gplc">
          <Row>
            <Col xs="12" className="mb-3">
              <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="gplc"
                  >
                    <strong>GPLC</strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="micro"
                  >
                    <strong>Micro Courses</strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="gplc">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("gplc")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="micro">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("micro")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}
