import moment from "moment";
import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Notification.scss";
import parse from "html-react-parser";

function Notification(props) {
  const cursorStyle = (item) => {
    let bool = !!item?.data?.feedbackData || !!item?.data?.cohortDiscussionData;
    // console.log("....bool", bool);
    return bool;
  };
  const { nData, setDropDownShow, dropDownShow, showClickHere } = props;
  return (
    <div
      className={`${
        cursorStyle(props?.nData) ? "curser-pointer" : "cursor-pointer-hidden"
      }`}
    >
      <Container
        fluid
        className={
          props?.nData?.read_at?.length > 0
            ? `read_notification`
            : "unread_notification"
        }
      >
        <Row className="font-weight-500 html_parse_p_stlyes">
          {parse(nData?.data.message)}
        </Row>
        <div style={{ fontSize: "13px", paddingTop: "10px" }}>
          {cursorStyle(props?.nData) ? parse("<b>Click Here</b>") : ""}
        </div>
        <Row className="data_color">
          <Col> {moment.utc(nData?.created_at).local().fromNow()} </Col>
          <Col></Col>
          <Col></Col>
          <Col>
            {props?.nData?.read_at?.length > 0 && (
              <div>
                <span>
                  Read {moment.utc(props?.nData?.read_at).local().fromNow()}{" "}
                </span>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Notification;
