import React, { useContext } from "react";
import ComponentNotificationBulb from "../../shared-components/notification-bulb/index";
import ComponentAvatar from "../../shared-components/avatar/index";
import ContextOffcanvas from "../../contexts/offcanvas-context";
import "./_index.scss";
import {
  Button,
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from "react-bootstrap";
import ComponentCtaBtn from "../../shared-components/cta-btn";
import "../layouts.scss";
import ContextUser from "./../../contexts/user-context";
import { withRouter } from "react-router-dom";

const ComponentSiteheaderTKL = React.memo(function ComponentSiteheaderTKL(
  props
) {
  const toggleOffcanvas = useContext(ContextOffcanvas);
  const toggleSiteNav = props.toggleSiteNav;
  const { history } = useContext(ContextUser);

  const openSiteNav = () => {
    if (toggleSiteNav) {
      toggleSiteNav(true);
    }
  };
  const openUserNav = () => {
    if (toggleOffcanvas) {
      toggleOffcanvas(true, "OffcanvasUserAccount", "");
    }
  };
  const redirectToPage = (event, page) => {
    props.history.push("/loginPage");
    props.history.go(0);
  };
  const redirectToLink = (link) => {
    window.open(link);
  };
  return (
    <>
      {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="box">
          <a className="navbar-brand mx-auto" href="#">
            <img
              src="https://www.teachnkidslearn.com/wp-content/uploads/2019/08/TKL_Logo_Colour.png"
              alt="Logo"
            />
          </a>
        </div>
        <ul className="nav navbar-nav ml-auto">
          <button
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100"
              height="33.469"
              viewBox="0 0 89.998 33.469"
            >
              <g
                id="Group_2387"
                data-name="Group 2387"
                transform="translate(-1703.002 -45.999)"
              >
                <text
                  id="LOGIN"
                  transform="translate(1747 68)"
                  fill="#151515"
                  font-size="16"
                  font-family="Karla-Bold, Karla"
                  font-weight="700"
                >
                  <tspan x="0" y="0">
                    LOGIN
                  </tspan>
                </text>
                <g
                  id="Group_2384"
                  data-name="Group 2384"
                  transform="translate(8)"
                >
                  <g
                    id="Group_2383"
                    data-name="Group 2383"
                    transform="translate(-120)"
                  >
                    <g
                      id="noun_User_2419009"
                      transform="translate(1793.808 24.807)"
                    >
                      <g
                        id="Group_1794"
                        data-name="Group 1794"
                        transform="translate(15.5 15.5)"
                      >
                        <g
                          id="Group_1793"
                          data-name="Group 1793"
                          transform="translate(5.693 5.693)"
                        >
                          <path
                            id="Path_1005"
                            data-name="Path 1005"
                            d="M39.161,22.427A16.734,16.734,0,1,0,22.427,39.161,16.753,16.753,0,0,0,39.161,22.427ZM22.427,37.4a14.873,14.873,0,0,1-7.815-2.206,7.926,7.926,0,0,1,15.629,0A14.874,14.874,0,0,1,22.427,37.4ZM16.261,20.665a6.165,6.165,0,1,1,6.165,6.165A6.172,6.172,0,0,1,16.261,20.665ZM31.807,34.088a9.7,9.7,0,0,0-5.565-6.477,7.927,7.927,0,1,0-7.629,0,9.7,9.7,0,0,0-5.565,6.477,14.973,14.973,0,1,1,18.759,0Z"
                            transform="translate(-5.693 -5.693)"
                            fill="#151515"
                          ></path>
                        </g>
                      </g>
                    </g>
                    <g
                      id="Path_1011"
                      data-name="Path 1011"
                      transform="translate(1824 52)"
                      fill="#fff"
                    >
                      <path
                        d="M 7.69970703125 16.185546875 C 3.702047109603882 16.185546875 0.44970703125 12.93320655822754 0.44970703125 8.935546875 C 0.44970703125 4.937886714935303 3.702047109603882 1.685546875 7.69970703125 1.685546875 C 11.69736671447754 1.685546875 14.94970703125 4.937886714935303 14.94970703125 8.935546875 C 14.94970703125 12.93320655822754 11.69736671447754 16.185546875 7.69970703125 16.185546875 Z"
                        stroke="none"
                      ></path>
                      <path
                        d="M 7.69970703125 2.435546875 C 4.115596771240234 2.435546875 1.19970703125 5.351436614990234 1.19970703125 8.935546875 C 1.19970703125 12.51965713500977 4.115596771240234 15.435546875 7.69970703125 15.435546875 C 11.28381729125977 15.435546875 14.19970703125 12.51965713500977 14.19970703125 8.935546875 C 14.19970703125 5.351436614990234 11.28381729125977 2.435546875 7.69970703125 2.435546875 M 7.69970703125 0.935546875 C 12.11798667907715 0.935546875 15.69970703125 4.517267227172852 15.69970703125 8.935546875 C 15.69970703125 13.35382652282715 12.11798667907715 16.935546875 7.69970703125 16.935546875 C 3.281427383422852 16.935546875 -0.30029296875 13.35382652282715 -0.30029296875 8.935546875 C -0.30029296875 4.517267227172852 3.281427383422852 0.935546875 7.69970703125 0.935546875 Z"
                        stroke="none"
                        fill="#df1c45"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </button>
        </ul>
        <div className="collapse navbar-collapse" id="navbarNavDropdown">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item active">
              <a className="nav-link" href="#">
                Home <span className="sr-only">(current)</span>
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Features
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Pricing
              </a>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="http://example.com"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Dropdown link
              </a>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <a className="dropdown-item" href="#">
                  Action
                </a>
                <a className="dropdown-item" href="#">
                  Another action
                </a>
                <a className="dropdown-item" href="#">
                  Something else here
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav> */}
      {
        <Navbar
          // fixed="top"
          bg="light"
          expand="lg"
          className="tkl-header border-bottom"
          style={{ zIndex: "101" }}
        >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Brand href="#home">
            <div className="box flex cart-style">
              <a
                href="/external-catalog"
                className="header__logo navbar-brand mx-auto"
              >
                <img
                  src="https://www.teachnkidslearn.com/wp-content/uploads/2019/08/TKL_Logo_Colour.png"
                  alt="Logo"
                />
              </a>
            </div>
          </Navbar.Brand>
          {/*ml-auto nav navbar-nav login-section*/}
          <Nav className="nav navbar-nav login-section">
            <div className="header-buttons  d-md-none d-xl-block">
              <Button
                className="p-1 m-1 btn-black no-border-radius capital-case header-button-padding"
                onClick={() =>
                  history.push("https://www.teachnkidslearn.com/book-a-demo")
                }
              >
                Book a Demo
              </Button>

              <Button
                className="p-1 m-1 btn-tkl no-border-radius capital-case header-button-padding"
                onClick={() =>
                  history.push("https://www.teachnkidslearn.com/subscribe/")
                }
                style={{ padding: "0.9rem 0.7rem !important" }}
              >
                <strong>Subscribe</strong>
              </Button>
            </div>
            {/* <div className="header-buttons-login"> */}
            <button
              className="p-1 m-1 mr-3 login-block curser-pointer"
              style={{ background: "#fff", border: "none" }}
              onClick={(event) => redirectToPage(event, "/loginPage")}
            >
              <img
                src="https://tklportal.edk-12.com/assets/img/userProIcon.png"
                alt="User Profile"
              />
              <span
                className="p-1 m-1 mr-0 ml-1 curser-pointer"
                // onClick={(event) => redirectToPage(event, "/loginPage")}
              >
                <strong>LOGIN</strong>
              </span>
            </button>
            {/* </div> */}
          </Nav>

          <Navbar.Collapse
            id="basic-navbar-nav new-id"
            // style={{ zIndex: "101" }}
          >
            <Nav className="mr-auto alignment-styles">
              <Nav.Link
                href="https://teachingknowledgeloop.com/external-catalog"
                className="tkl-header-a tkl_header_links"
                block
              >
                ONLINE COURSES
              </Nav.Link>
              <NavDropdown
                className="mr-auto tkl-header-a d-flex flex-wrap dropdown-programs tkl_header_links"
                title="PROGRAMS"
                id="basic-nav-dropdown-programs"
              >
                <div className="dropdown_items_programs d-flex">
                  <NavDropdown.Item
                    href="https://www.teachnkidslearn.com/comprehensive-courses/"
                    className="dropdown-item-tkl"
                    // target="_blank"
                    // rel="noreferrer"
                  >
                    <span style={{ paddingLeft: "1rem" }} className="div1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25.756"
                        height="62.762"
                        viewBox="0 0 83.756 182.762"
                      >
                        {" "}
                        <g
                          id="Group_2360"
                          data-name="Group 2360"
                          transform="translate(0 0)"
                        >
                          {" "}
                          <path
                            id="Path_1013"
                            data-name="Path 1013"
                            d="M4.873,3.167S50.426-2.129,71.111,18.8s17.3,68.881,17.3,68.881H33.693s-15.052-.205-22.257-6.749S4.873,61.509,4.873,61.509Z"
                            transform="translate(-4.858 -2.569)"
                            fill="#b9defa"
                          ></path>{" "}
                          <path
                            id="Path_1016"
                            data-name="Path 1016"
                            d="M41.534,0H83.068V41.534A41.534,41.534,0,1,1,41.534,0Z"
                            transform="translate(0.052 99.693)"
                            fill="#41739b"
                          ></path>{" "}
                        </g>{" "}
                      </svg>
                    </span>
                    <span
                      className="p-2"
                      // onClick={redirectToLink(
                      //   "https://www.teachnkidslearn.com/comprehensive-courses/"
                      // )}
                    >
                      Comprehensive Courses
                    </span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://www.teachnkidslearn.com/micro-courses/"
                    className="dropdown-item-tkl"
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25.756"
                        height="62.762"
                        viewBox="0 0 83.756 182.762"
                      >
                        {" "}
                        <g
                          id="Group_2350"
                          data-name="Group 2350"
                          transform="translate(0 0)"
                        >
                          {" "}
                          <path
                            id="Path_1014"
                            data-name="Path 1014"
                            d="M4.873,3.167S50.426-2.129,71.111,18.8s17.3,68.881,17.3,68.881H33.693s-15.052-.205-22.257-6.749S4.873,61.509,4.873,61.509Z"
                            transform="translate(-4.858 -2.569)"
                            fill="#94ded6"
                          ></path>{" "}
                          <path
                            id="Path_1017"
                            data-name="Path 1017"
                            d="M41.534,0H83.068V41.534A41.534,41.534,0,1,1,41.534,0Z"
                            transform="translate(0.052 99.693)"
                            fill="#1dbaad"
                          ></path>{" "}
                        </g>{" "}
                      </svg>
                    </span>

                    <span className="p-2"> Micro Courses</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://www.teachnkidslearn.com/guided-plcs/"
                    className="dropdown-item-tkl"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.756"
                      height="62.762"
                      viewBox="0 0 83.756 182.762"
                    >
                      {" "}
                      <g
                        id="Group_2361"
                        data-name="Group 2361"
                        transform="translate(0 0)"
                      >
                        {" "}
                        <path
                          id="Path_1018"
                          data-name="Path 1018"
                          d="M4.873,3.167S50.426-2.129,71.111,18.8s17.3,68.881,17.3,68.881H33.693s-15.052-.205-22.257-6.749S4.873,61.509,4.873,61.509Z"
                          transform="translate(-4.858 -2.569)"
                          fill="#fe8a80"
                        ></path>{" "}
                        <path
                          id="Path_1019"
                          data-name="Path 1019"
                          d="M41.534,0H83.068V41.534A41.534,41.534,0,1,1,41.534,0Z"
                          transform="translate(0.052 99.693)"
                          fill="#dd1d45"
                        ></path>{" "}
                      </g>{" "}
                    </svg>

                    <span className="p-2">Guided PLC</span>
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://www.teachnkidslearn.com/evaluwise-partner-program/"
                    className="d-flex dropdown-item-tkl"
                  >
                    <table style={{ display: "contents" }}>
                      <tr>
                        <td rowspan="2">
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25.756"
                              height="62.762"
                              viewBox="0 0 83.756 182.762"
                            >
                              {" "}
                              <g
                                id="Group_2362"
                                data-name="Group 2362"
                                transform="translate(0 0)"
                              >
                                {" "}
                                <path
                                  id="Path_1012"
                                  data-name="Path 1012"
                                  d="M4.873,3.167S50.426-2.129,71.111,18.8s17.3,68.881,17.3,68.881H33.693s-15.052-.205-22.257-6.749S4.873,61.509,4.873,61.509Z"
                                  transform="translate(-4.858 -2.569)"
                                  fill="#ffd584"
                                ></path>{" "}
                                <path
                                  id="Path_1015"
                                  data-name="Path 1015"
                                  d="M41.534,0H83.068V41.534A41.534,41.534,0,1,1,41.534,0Z"
                                  transform="translate(0.052 99.693)"
                                  fill="#ffba33"
                                ></path>{" "}
                              </g>{" "}
                            </svg>
                          </span>
                        </td>
                        <td>
                          <span className="p-2">EvaluWise Partner Program</span>
                        </td>
                      </tr>
                      {/* <tr>
                        <td>
                          <span>Program</span>
                        </td>
                      </tr> */}
                    </table>
                  </NavDropdown.Item>
                </div>
              </NavDropdown>
              <Nav.Link
                href="https://www.teachnkidslearn.com/blog/"
                className="tkl-header-a tkl_header_links"
              >
                BLOG
              </Nav.Link>
              <NavDropdown
                title="ABOUT"
                className="tkl-header-a tkl_header_links"
                id="basic-nav-dropdown-about"
              >
                <NavDropdown.Item
                  href="https://www.teachnkidslearn.com/about-tkl/"
                  className="tkl-about-a tkl_header_links"
                >
                  ABOUT TKL
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.teachnkidslearn.com/about-tkl/faqs/"
                  className="tkl-about-a tkl_header_links"
                >
                  FREQUENTLY ASKED QUESTIONS
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="https://www.teachnkidslearn.com/our-team/"
                  className="tkl-about-a tkl_header_links"
                >
                  TEAM
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      }
    </>
  );
});

export default withRouter(ComponentSiteheaderTKL);
