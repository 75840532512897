import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  showLoader,
  hideLoader,
} from "../../actions/CreateCourse/CreatecourseActions";

const LogoLoader = React.memo(function ComponentPageLoader(props) {
  const [animation, setAnimation] = useState("");
  useEffect(() => {
    setAnimation("active");
    return () => {
      setAnimation("");
    };
  });
  return (
    <div
      className={`page-loader-comp ${animation}`}
      style={{ zIndex: "10000" }}
    >
      {/* style={{ width: "4rem", height: "4rem" }} */}
      <div className="d-inline-block" style={{ height: "100vh" }}>
        <div className="tkl-spinner-logoLoader">
          <svg
            version="1.2"
            baseProfile="tiny"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            x="10px"
            y="10px"
            viewBox="0 0 202 202"
          >
            <path
              fill="#1DBAAD"
              id="green"
              d="M92,92H22C9.9,92,0,82.1,0,70L0,0l24,0c37.4,0,68,30.6,68,68V92z"
            />
            <path
              fill="#FCB127"
              id="yellow"
              d="M180,92h-70V68c0-37.6,30.4-68,68-68h24v70C202,82.2,192.2,92,180,92z"
            />
            <path
              fill="#DF1C45"
              id="red"
              d="M47,202h-2c-24.8,0-45-20.3-45-45v-2c0-24.9,20.1-45,45-45h47v47C92,181.9,71.9,202,47,202z"
            />
            <path
              fill="#3F749C"
              id="blue"
              d="M157,202h-2c-24.8,0-45-20.3-45-45v-47h47c24.9,0,45,20.1,45,45v2C202,181.9,181.9,202,157,202z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
});

export const mapStateToProps = (state) => {
  return {
    isLoading: state.coursehome.isLoading,
  };
};
export const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(LogoLoader);
