import {
  deleteFolder,
  listFolders,
  renameFolder,
} from "actions/FileStorageActions/FileStorageAction";
import {
  CONTENT_ADMIN,
  CONTENT_AUTHOR,
  SYSTEM_ADMIN,
  TKL_FACILITATOR,
} from "constants.js/RoleNames";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { types } from "reducers/types";

function Folder({ item }) {
  const dispatch = useDispatch();
  const { folders, currentFolderPath } = useSelector(
    (state) => state.filestorage
  );
  const userData = useSelector((state) => state.users.userData);

  const [showRenameFolderPopup, setShowRenameFolderPopup] = useState(false);

  const [show, setShow] = useState(true);

  useEffect(() => {
    if (
      userData?.roles?.find((role) => {
        return role === TKL_FACILITATOR || role === CONTENT_AUTHOR;
      }) &&
      !userData?.roles?.find((role) => {
        return role === SYSTEM_ADMIN || role === CONTENT_ADMIN;
      })
    ) {
      // if the user is TKL_FACILITATOR OR CONTENT_AUTHOR  and user is not a SYSTEM_ADMIN OR CONTENT_ADMIN,
      //  we want to restrict access to some folders

      if (
        item.slug == userData.userSlug ||
        currentFolderPath.findIndex((x) => x.slug == userData.userSlug) ||
        item.name.toLowerCase() == "common folder".toLowerCase() ||
        currentFolderPath.findIndex(
          (x) => x.name.toLowerCase() == "common folder".toLowerCase()
        )
      ) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  }, []);

  const handleOpenFolder = () => {
    dispatch(listFolders(item.slug));
    dispatch({
      type: types.UPDATE_CURRENT_FOLDER_PATH,
      payload: [
        ...currentFolderPath,
        {
          name: item.name,
          slug: item.slug,
        },
      ],
    });
    dispatch({
      type: types.UPADTE_FOLDERS,
      payload: item.subFolders,
    });
  };

  const handleRenameFolder = (oldFolderSlug, newFolderName) => {
    console.log(oldFolderSlug, newFolderName);
    dispatch(
      renameFolder({
        oldFolderSlug: oldFolderSlug,
        newFolderName: newFolderName,
      })
    );
  };

  const handleDeleteFolder = (folderSlug) => {
    dispatch(deleteFolder({ folderSlug: folderSlug }));
  };

  const RenameFolderPopup = ({ item }) => {
    const [folderName, setFolderName] = useState("");
    const handleFolderNameChange = (e) => {
      setFolderName(e.target.value);
    };
    return (
      <div>
        <Modal
          className="modal-styles"
          show={showRenameFolderPopup}
          onHide={() => {
            setShowRenameFolderPopup(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{"Rename Folder"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              placeholder="Enter New Folder Name"
              className="m-2 p-2"
              value={folderName}
              onChange={handleFolderNameChange}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-outline btn-secondary rounded-pill "
              title={"Cancel"}
              onClick={() => {
                setShowRenameFolderPopup(false);
              }}
            >
              {"Cancel"}
            </Button>

            <Button
              className=" btn-outline rounded-pill btn"
              title={"Ok"}
              onClick={() => {
                if (folderName) {
                  handleRenameFolder(item.slug, folderName);
                }
                setShowRenameFolderPopup(false);
              }}
            >
              {"Ok"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  return (
    <>
      {show && (
        <div className="d-flex flex-column justify-content-center align-items-center FileStorageRoot__Activity__folder__container">
          <span
            className="material-icons FileStorageRoot__Activity__folder__icon"
            title={item.name}
            onDoubleClick={handleOpenFolder}
          >
            folder
          </span>
          <label
            className="FileStorageRoot__Activity__folder__container__label"
            title={item.name}
          >
            {item.name}
          </label>

          <DropdownButton
            className="fileStorage__folder__dropdown"
            title=""
            size="sm"
            variant="primary"
            direction="top"
            style={{ backgroundColor: "lavender" }}
          >
            <Dropdown.Item onClick={handleOpenFolder}>Open</Dropdown.Item>

            <Dropdown.Item
              onClick={() => {
                setShowRenameFolderPopup(true);
              }}
            >
              Rename
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item
              onClick={() => {
                handleDeleteFolder(item.slug);
              }}
            >
              Delete
            </Dropdown.Item>
          </DropdownButton>

          <RenameFolderPopup item={item}></RenameFolderPopup>
          {/* <span
      class="material-icons FileStorageRoot__Activity__folder__more__vert"
      onClick={() => {}}
    >
      more_vert
    </span> */}
        </div>
      )}
    </>
  );
}

export default Folder;
