import React, { useEffect, useRef, useMemo, useState } from "react";
import ComponentCtaBtn from "../../../shared-components/cta-btn/index";
import { Tabs, Tab, Row, Col, Nav, Container, Button } from "react-bootstrap";
import ComponentCustomScrollbar from "../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "../../../shared-components/avatar/index";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getFacilitatorCourses } from "../../../actions/DashboardActions/InstructionalCoachActions";
import { BannerImageHandlingForAllDevices } from "../../utility/ReUsableFunctions";
import { getallDraftData } from "../../../actions/CreateCourse/CreatecourseActions";
import moment from "moment";

function ApprovedCoursesTitle(props) {
  const [show, setShow] = useState(false);
  const [currentCourse, setCurrentCourse] = useState("");
  const dispatch = useDispatch();
  const facilitatorCourses = useSelector(
    (state) => state.dashboards.facilitatorCourses
  );

  const draftData = useSelector((state) => state.coursehome.draftData);

  useEffect(() => {
    if (props.role === "instructionalCoach") {
      dispatch(getFacilitatorCourses());
    } else if (
      props.role === "contentDeveloper" ||
      props.role === "contentAuthor"
    ) {
      dispatch(getallDraftData());
    }
  }, []);

  const handleShow = (slug) => {
    setShow(true);
    setCurrentCourse(slug);
  };

  const compArr = useRef([]);
  const gplcArr = useRef([]);
  const microArr = useRef([]);

  const navigateToCoursePath = (event, slug) => {
    sessionStorage.setItem("courseSlug", slug);
    sessionStorage.setItem("mode", "review");
    props.history.push(`/${slug}/CoursePath/`);
  };

  useMemo(() => {
    if (props.role === "instructionalCoach") {
      compArr.current = (facilitatorCourses || []).filter(
        (item) =>
          item?.program?.slug === "comprehensive-course-program" &&
          item.status === "APPROVED"
      );
      gplcArr.current = (facilitatorCourses || []).filter(
        (item) =>
          item?.program?.slug === "guided-plc-program" &&
          item.status === "APPROVED"
      );
      microArr.current = (facilitatorCourses || []).filter(
        (item) =>
          item?.program?.slug === "micro-course-program" &&
          item.status === "APPROVED"
      );
    } else if (
      props.role === "contentDeveloper" ||
      props.role === "contentAuthor"
    ) {
      compArr.current = (draftData || []).filter((item) =>
        item.programsAvailable.some(
          (e) =>
            e.slug === "comprehensive-course-program" &&
            item.status === "APPROVED"
        )
      );
      gplcArr.current = (draftData || []).filter(
        (item) =>
          item.programsAvailable.some((e) => e.slug === "guided-plc-program") &&
          item.status === "APPROVED"
      );
      microArr.current = (draftData || []).filter(
        (item) =>
          item.programsAvailable.some(
            (e) => e.slug === "micro-course-program"
          ) && item.status === "APPROVED"
      );
    }
  }, [draftData]);

  function getStatus(item) {
    if (item.status === "DRAFT" || item.status === "UNPUBLISHED") {
      return "DRAFT";
    } else if (
      item.status === "UNDER REVIEW" ||
      item.status === "FEEDBACK SUBMITTED"
    ) {
      return "REVIEW AND FEEDBACK";
    } else if (item.status === "APPROVED") {
      return item.status;
    }
  }

  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "comprehensive") {
      thisArr = compArr?.current || []; // //(compArr || [])[0]?.data || [];
      char = "comp";
    }
    if (str === "gplc") {
      thisArr = gplcArr?.current || []; //(gplcArr || [])[0].data || [];
      char = "gplc";
    }
    if (str === "micro") {
      thisArr = microArr?.current || []; //(microArr || [])[0].data || [];
      char = "micro";
    }

    return thisArr.map((item, index) => (
      <React.Fragment key={`t_m_${char}_${index}`}>
        <div className="dash-row-type-2 container rounded mb-2">
          <div className="row">
            <div className="t-row-name col-12 col-md-6 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
              <div className="t-avtr-wrap position-absolute">
                <a href="#">
                  <ComponentAvatar
                    type="course"
                    data={{
                      img: BannerImageHandlingForAllDevices(
                        item?.avatarImage?.url,
                        "course",
                        item?.avatarImage?.slug
                      ),
                    }}
                  />
                </a>
              </div>
              <div>
                <Link to={"#"} className="t-row-name-link">
                  {item?.name}
                </Link>
                <div>
                  <small className="text-muted" style={{ marginTop: "5px" }}>
                    {moment(item?.updatedAt).format("MM-DD-YYYY")}
                  </small>
                </div>
                <div>
                  <div style={{ marginTop: "5px" }}>
                    <small className="font-weight-600">
                      Status : {getStatus(item)}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3 p-2 d-flex flex-column justify-content-center align-items-end"></div>
            <div className="col-6 col-md-3 p-2 d-flex flex-column justify-content-center align-items-end">
              {item.status === "UNDER REVIEW" ||
              item.status === "FEEDBACK SUBMITTED" ||
              item.status === "APPROVED" ? (
                <>
                  {props.role === "contentAuthor" ? (
                    <Container fluid>
                      <Row className="p-1">
                        <Button
                          onClick={() => {
                            sessionStorage.setItem("courseSlug", item.slug);
                            sessionStorage.setItem("mode", "edit");
                            props.history.push(`/${item.slug}/CoursePath/`);
                          }}
                          className="btn btn-outline btn-rounded btn-primary m-2 btn-sm rounded-pill btn-width"
                        >
                          View
                        </Button>
                      </Row>
                    </Container>
                  ) : (
                    <Container fluid>
                      <Row className="p-1">
                        <Button
                          className="btn btn-outline btn-rounded btn-primary ml-2 btn-sm rounded-pill btn-width"
                          disabled={item?.status !== "APPROVED"}
                          onClick={(event) => {
                            props.history.push(
                              `\/${item?.slug}\/${sessionStorage.getItem(
                                "programSelected"
                              )}/cohorts`
                            );
                          }}
                        >
                          Publish
                        </Button>
                      </Row>
                    </Container>
                  )}
                </>
              ) : (
                <Container fluid>
                  <Row className="p-1">
                    <Button
                      onClick={() => {
                        sessionStorage.setItem("courseSlug", item.slug);
                        sessionStorage.setItem("mode", "review");
                        props.history.push(`/${item.slug}/CoursePath/`);
                      }}
                      className="btn btn-outline btn-rounded btn-primary m-2 btn-sm rounded-pill"
                    >
                      Review
                    </Button>
                  </Row>
                  <Row className="p-1">
                    <Button
                      onClick={() => handleShow(item.slug)}
                      className="btn btn-outline btn-danger rounded-pill btn-sm ml-2"
                    >
                      Delete
                    </Button>
                  </Row>
                </Container>
              )}
            </div>
          </div>
        </div>
        <hr />
      </React.Fragment>
    ));
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 m-0 text-uppercase">
            <strong>{props?.title}</strong>
          </h2>
        </div>
        <div className="dash-t-h-right py-2 px-3 flex-md-grow-1 d-none d-md-flex justify-content-md-end align-items-md-center"></div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container
          id="mapl_tabs"
          defaultActiveKey={
            props.role === "districtFacilitator" ||
            props.role === "schoolFacilitator"
              ? "gplc"
              : "comprehensive"
          }
        >
          <Row>
            <Col xs="12" className="mb-3">
              {/* <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="comprehensive"
                  >
                    <strong>Comprehensive</strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav> */}
              <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  {!(
                    props.role === "districtFacilitator" ||
                    props.role === "schoolFacilitator"
                  ) && (
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="comprehensive"
                    >
                      <strong>Comprehensive</strong>
                    </Nav.Link>
                  )}
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="gplc"
                  >
                    <strong>GPLC</strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="micro"
                  >
                    <strong>Micro Courses</strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="comprehensive">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("comprehensive")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="gplc">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("gplc")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="micro">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("micro")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}

export default withRouter(ApprovedCoursesTitle);
