import React, { useState, useEffect, useMemo } from "react";
import ComponentCtaBtn from "./../cta-btn/index";
import ComponentResponsiveImg from "./../responsive-img/index";
// import { _TrianglifyImg } from './../../shared/services/constants';
import { _GeopatternImg } from "./../../shared/services/constants";
import "./_index.scss";

const ComponentAvatar = React.memo(function ComponentAvatar(props) {
  const type = (props || {}).type || "";
  const data = (props || {}).data || {};
  const iseditable = (props || {}).iseditable || "";
  const imgdata = data.img || {};
  const { avatarImage } = props;

  const first = (data.firstname || "").charAt(0).toUpperCase();
  const last = (data.lastname || "").charAt(0).toUpperCase();
  const iconType = data.type || "";

  const rTypes = [
    {
      type: "video",
      icon: "videocam",
    },
    {
      type: "article",
      icon: "article",
    },
    {
      type: "blog",
      icon: "rss_feed",
    },
    {
      type: "newsItem",
      icon: "newspaper",
    },

    {
      type: "document",
      icon: "insert_drive_file",
    },
    {
      type: "image",
      icon: "perm_media",
    },
    {
      type: "forum",
      icon: "forum",
    },
    {
      type: "image",
      icon: "image",
    },
    { type: "text", icon: "text" },
  ];

  const imageCheck = () => {
    let imageFlag = true;
    if (data?.img?.xs) {
      if (data?.img?.xs?.includes("undefined")) {
        imageFlag = false;
      }
    } else if (data?.img) {
      if (
        (data?.img || "").length > 0 &&
        (data?.img || "").includes("undefined")
      ) {
        imageFlag = false;
      }
    } else {
      imageFlag = false;
    }
    return imageFlag;
  };

  const hasImages = imageCheck();
  const defaultImageURL = `${process.env.PUBLIC_URL + "/images.png"}`;

  const rIcon = (str) => {
    const arr = rTypes.filter((item) => item.type === str);
    if (arr.length > 0) {
      switch (arr[0].icon) {
        case "videocam":
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="28"
              viewBox="0 0 24 24"
              width="28"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M17 10.5V7c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1v-3.5l4 4v-11l-4 4z" />
            </svg>
          );
        case "menu_book":
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g />
                <g>
                  <path d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                  <g>
                    <path d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
                    <path d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
                    <path d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
                  </g>
                </g>
              </g>
            </svg>
          );
        case "rss_feed":
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <circle cx="6.18" cy="17.82" r="2.18" />
              <path d="M4 4.44v2.83c7.03 0 12.73 5.7 12.73 12.73h2.83c0-8.59-6.97-15.56-15.56-15.56zm0 5.66v2.83c3.9 0 7.07 3.17 7.07 7.07h2.83c0-5.47-4.43-9.9-9.9-9.9z" />
            </svg>
          );
        case "insert_drive_file":
          return (
            <svg
              aria-hidden="true"
              role="img"
              width="42"
              height="42"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 32 32"
            >
              <path
                d="M25.7 9.3l-7-7c-.2-.2-.4-.3-.7-.3H8c-1.1 0-2 .9-2 2v24c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V10c0-.3-.1-.5-.3-.7zM18 4.4l5.6 5.6H18V4.4zM24 28H8V4h8v6c0 1.1.9 2 2 2h6v16z"
                fill="#18998e"
              />
              <path d="M10 22h12v2H10z" fill="#18998e" />
              <path d="M10 16h12v2H10z" fill="#18998e" />
            </svg>
          );
        case "perm_media":
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M2 6H0v5h.01L0 20c0 1.1.9 2 2 2h18v-2H2V6zm20-2h-8l-2-2H6c-1.1 0-1.99.9-1.99 2L4 16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zM7 15l4.5-6 3.5 4.51 2.5-3.01L21 15H7z" />
            </svg>
          );
        case "forum":
          return (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 0 24 24"
              width="24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M21 6h-2v9H6v2c0 .55.45 1 1 1h11l4 4V7c0-.55-.45-1-1-1zm-4 6V3c0-.55-.45-1-1-1H3c-.55 0-1 .45-1 1v14l4-4h10c.55 0 1-.45 1-1z" />
            </svg>
          );
        case "newspaper":
          return (
            <span
              class="material-icons"
              style={{ transform: "rotate(-45deg)" }}
            >
              newspaper
            </span>
          );
        case "text":
          return (
            <span
              class="material-icons"
              style={{ color: "#18998e", transform: "rotate(45deg)" }}
            >
              text_snippet
            </span>
          );
        default:
        // code block
      }
    } else {
      return <></>;
    }
  };
  // console error from here
  const [isAvatarGenerated, setIsAvatarGenerated] = useState(false); 
  const [avatarUrl, setAvatarUrl] = useState(defaultImageURL); 

  // useEffect(() => {
  //   if (!isAvatarGenerated && !hasImages) {
  //     setAvatarUrl(defaultImageURL);
  //     setIsAvatarGenerated(true);
  //   }
  // }, [isAvatarGenerated]);
  

  if (type === "user") {
    return (
      <>
        <div className="u-avtr rounded-circle">
          <div className="u-avtr-cred rounded-circle"></div>
          {imgdata.xxl ||
          imgdata.xxl2 ||
          imgdata.xl ||
          imgdata.xl2 ||
          imgdata.lg ||
          imgdata.lg2 ||
          imgdata.sm ||
          imgdata.sm2 ||
          imgdata.xs ||
          imgdata.xs2 ? (
            <div className="u-avtr-img rounded-circle">
              <ComponentResponsiveImg imgdata={data.img} />
            </div>
          ) : (
            <></>
          )}
          {iseditable === "true" ? (
            <>
              <div
                className="position-absolute text-center rounded-circle border"
                style={{
                  bottom: "-0.5rem",
                  left: "48.5%",
                  marginLeft: "-1.1rem",
                  zIndex: "4",
                }}
              >
                <ComponentCtaBtn
                  data={{
                    tag: "button",
                    type: "button",
                    ctastyle: "secondary",
                    text: "",
                    title: "Update Profile Image",
                    primaryicon: "camera_alt",
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
  if (type === "course") {
    return (
      <>
        <div className="c-avtr rounded-xl">
          {imgdata.xxl ||
          imgdata.xxl2 ||
          imgdata.xl ||
          imgdata.xl2 ||
          imgdata.lg ||
          imgdata.lg2 ||
          imgdata.sm ||
          imgdata.sm2 ||
          imgdata.xs ||
          imgdata.xs2 ? (
            <div className="c-avtr-img rounded-xl">
              <ComponentResponsiveImg imgdata={data.img} />
            </div>
          ) : (
            <div
              className="c-avtr-img rounded-xl"
              style={{
                backgroundImage: `url(${
                  avatarImage ? avatarImage : avatarUrl
                })`,
              }}
            ></div>
          )}
          {iseditable ? (
            <>
              <div
                className="position-absolute text-center rounded-circle border"
                style={{
                  bottom: "-1rem",
                  left: "50%",
                  marginLeft: "-1.1rem",
                  zIndex: "4",
                }}
              >
                <ComponentCtaBtn
                  data={{
                    tag: "button",
                    type: "button",
                    ctastyle: "secondary",
                    text: "",
                    title: "Update Course Avatar",
                    primaryicon: "camera_alt",
                  }}
                />
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
  if (type === "resource") {
    return (
      <>
        <div className="r-avtr rounded-circle">
          <div className="r-avtr-icn rounded-circle">{rIcon(iconType)}</div>
        </div>
      </>
    );
  }

  if (type === "article") {
    return (
      <>
        <div className="r-avtr rounded-circle">
          <div className="r-avtr-icn rounded-circle">{rIcon(iconType)}</div>
        </div>
      </>
    );
  }

  if (type === "image") {
    return (
      <>
        <div className="r-avtr rounded-circle">
          <div className="r-avtr-icn rounded-circle">
            <svg
              aria-hidden="true"
              role="img"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 1024 1024"
            >
              <path
                d="M553.1 509.1l-77.8 99.2l-41.1-52.4a8 8 0 0 0-12.6 0l-99.8 127.2a7.98 7.98 0 0 0 6.3 12.9H696c6.7 0 10.4-7.7 6.3-12.9l-136.5-174a8.1 8.1 0 0 0-12.7 0zM360 442a40 40 0 1 0 80 0a40 40 0 1 0-80 0zm494.6-153.4L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0 0 42 42h216v494z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </>
    );
  }
  if (type === "icon") {
    return (
      <>
        <div className="i-avtr rounded-circle">
          <div className="i-avtr-icn rounded-circle">
            {/* <span className={`display-3 m-0 mdi mdi-${icon}`}></span> */}
            {props.children}
          </div>
        </div>
      </>
    );
  }
  return <></>;
});

export default ComponentAvatar;
