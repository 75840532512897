import React, { useRef, useMemo, useEffect, useState, useContext } from "react";
import ComponentNotificationBulb from "./../../../shared-components/notification-bulb/index";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import { connect, useSelector, useDispatch } from "react-redux";
import { getFacilitatorMonitoringData } from "../../../actions/DashboardActions/InstructionalCoachActions";
import { BannerImageHandlingForAllDevices, userRoles } from "../../utility/ReUsableFunctions";
import ContextUser from "../../../contexts/user-context";
import {
  COMPREHENSIVE_PROGRAM_MEMBER,
  COMPREHENSIVE_PROGRAM_MEMBER_NAME,
  CONTENT_ADMIN,
  CONTENT_AUTHOR,
  DISTRICT_FACILITATOR,
  GPLC_PROGRAM_MEMBER,
  GPLC_PROGRAM_MEMBER_NAME,
  MICRO_PROGRAM_MEMBER,
  KIDVISION_PROGRAM_MEMBER,
  MICRO_PROGRAM_MEMBER_NAME,
  SCHOOL_FACILITATOR,
  SYSTEM_ADMIN,
  TKL_ACCOUNT_MANAGER,
  TKL_FACILITATOR,
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
} from "../../../../src/constants.js/RoleNames";

const ReviewPage = ({ title, activityType }) => {
  const dispatch = useDispatch();

  const { aupCourses, uceCourses } = useSelector((state) => state.dashboards);
  const { history } = useContext(ContextUser);
  const [selectedNav, setSelectedNav] = useState("Comprehensive Course Program");
  const [programSlug, setProgramSlug] = useState(
    "Comprehensive Course Program"
  );
  const [defaultKey, setDefaultKey] = useState();
  const loggedInUserRoles = useSelector((state) => state?.users?.userData?.roles);
  const defaultLinkMicro=useRef();
  const defaultLinkComprehensive=useRef();
  const defaultLinkKidvision=useRef();
  const defaultLinkGplc=useRef();

  useEffect(() => {
    if(loggedInUserRoles.includes(userRoles?.KIDVISION_PROGRAM_MEMBER)){
      setDefaultKey("kidvision");
    }else if(loggedInUserRoles.includes(userRoles?.GPLC_PROGRAM_MEMBER_PAID_USER)){
      setDefaultKey("gplc");
    }else if (loggedInUserRoles.includes(userRoles?.MICRO_COURSE_PROGRAM_MEMBER_PAID_USER)){
      setDefaultKey("micro");
    }else if(loggedInUserRoles.includes(userRoles?.COMPREHENSIVE_PROGRAM_MEMBER_PAID_USER) && loggedInUserRoles.includes(!userRoles?.SYSTEM_ADMIN)){
      setDefaultKey("comprehensive");
    }
}, []);
useEffect(()=>{
  if(defaultKey === "micro"){
    setTimeout(()=>{
      defaultLinkMicro?.current?.click();
    },3000)
  }
  if(defaultKey === "gplc"){
    setTimeout(()=>{
      defaultLinkGplc?.current?.click();
    },3000)
  }
  if(defaultKey === "kidvision"){
    setTimeout(()=>{
      defaultLinkKidvision?.current?.click();
    },3000)
  }
  if(defaultKey === "comprehensive"){
    setTimeout(()=>{
      defaultLinkComprehensive?.current?.click();
    },3000)
  }
},[defaultKey])

  useEffect(() => {
    (async()=>{
      await dispatch(
      getFacilitatorMonitoringData( 
        activityType,
        selectedNav,
        activityType == "aup" ? 0 : 1
      )
    )})()
  }, [selectedNav]);
  useEffect(() => {
    (async () => { await dispatch(
      getFacilitatorMonitoringData(
        activityType,
        programSlug,
        activityType == "aup" ? 0 : 1
      )
    )})()
  }, []);
  const compArr = useRef([]);
  const gplcArr = useRef([]);
  const microArr = useRef([]);
  const kidVisionArr = useRef([]);

  useMemo(() => {
    compArr.current = (
      (activityType == "aup" ? aupCourses : uceCourses) || []
    ).filter((item) => item?.programName === "Comprehensive Course Program");
    gplcArr.current = (
      (activityType == "aup" ? aupCourses : uceCourses) || []
    ).filter((item) => item?.programName === "Guided PLC Course Program");
    microArr.current = (
      (activityType == "aup" ? aupCourses : uceCourses) || []
    ).filter((item) => item?.programName === "Micro Course Program");
    kidVisionArr.current = (
      (activityType == "aup" ? aupCourses : uceCourses) || []
    ).filter((item) => item?.programName === "KidVision Program");
  }, [JSON.stringify(aupCourses), JSON.stringify(uceCourses)]);

  const renderData = (str) => {
    let thisArr = {};
    let char = "";
    if (str === "comprehensive") {
      thisArr = compArr?.current || {};
      char = "comp";
    }
    if (str === "gplc") {
      thisArr = gplcArr?.current || {};
      char = "gplc";
    }
    if (str === "micro") {
      thisArr = microArr?.current || {};
      char = "micro";
    }

    if (str === "kidvision") {
      thisArr = kidVisionArr?.current || [];
      char = "kidvision";
    }

    return thisArr?.length === 0 ? (
      <p className="item-not-selected">No Courses Available</p>
    ) : (
      (thisArr || []).map((item, index) => {
        return (
          <React.Fragment key={`t_p_${char}_${index}`}>
            <div className="dash-row-type-2 container rounded mb-2">
              <div className="row">
                <div className="t-row-name col-12 col-md-12 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
                  <div className="t-avtr-wrap position-absolute">
                    <a href={item?.link}>
                      <ComponentAvatar
                        type="course"
                        data={{
                          img: BannerImageHandlingForAllDevices(
                            item.avatarMedia?.url,
                            "user",
                            item?.avatarMedia?.slug
                          ),
                        }}
                      />
                    </a>
                  </div>
                  <a href={item?.link} className="t-row-name-link">
                    {item?.name}
                  </a>
                  <small className="text-muted">
                    {/* {dateformat(new Date(item.createddate), "P")} */}
                  </small>
                </div>
                <div
                  className="my_assigned_courses_style"
                  style={{ justifyContent: "space-between" }}
                >
                  <div
                    className="col-12 col-md-8 d-flex flex-column"
                    style={{ alignItems: "flex-start" }}
                  >
                    <div>
                      <small>
                        <i className="material-icons text-primary">place</i>
                      </small>
                      <small className="text-black-50">
                        {item?.programName === "Comprehensive Course Program"
                          ? item?.marketLocation?.name
                          : item?.account?.districtName}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-md-2 p-2 d-flex flex-column justify-content-center align-items-end">
                    <ComponentCtaBtn
                      notifications={item?.count || 0}
                      onClick={() => {
                        sessionStorage.setItem("buttonClicked",true);
                        sessionStorage.setItem("districtName", item?.account?.districtName );
                        sessionStorage.setItem("schoolName", item?.schoolName )       ;
                        sessionStorage.setItem("MIRAccountId", item?.account?.accountId );
                        sessionStorage.setItem("MIRSchoolId", item?.schoolId )         ;
                        sessionStorage.setItem("courseSlug", item?.slug);
                        sessionStorage.setItem("courseName", item?.name);
                        sessionStorage.setItem(
                          "marketLocationSlug",
                          item?.marketLocation?.slug
                        );
                        sessionStorage.setItem(
                          "cohortCourseID",
                          item?.cohortDisplayId
                        );
                        sessionStorage.setItem("activityType", activityType);
                        str === "comprehensive"
                        ? history.push("/course-review")
                        : str === "gplc"
                        ? history.push("/course-review-gplc")
                        : str === "micro"
                        ? history.push(`/course-review-micro`) 
                        : history.push(`/course-review-kidvision`)
                      history.go(0);
                      }}
                      data={{
                        tag: "button",
                        // href: "#",
                        ctastyle: "primary",
                        text: "View",
                        title: "View",
                        size: "sm",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
          </React.Fragment>
        );
      })
    );
  };

  const getNotificationsCount = () => {
    if (programSlug === "comprehensive") {
      let total = 0;
      compArr?.current &&
      Object.keys(compArr?.current).forEach((item) => {
        total += compArr?.current[item]?.numberOfActivitiesToBeReviewed || 0;
      });
      return total;
    } else if (programSlug === "gplc") {
      let total = 0;
      gplcArr?.current &&
      Object.keys(gplcArr?.current).forEach((item) => {
        total += gplcArr?.current[item]?.numberOfActivitiesToBeReviewed || 0;
      });
      return total;
    } else if (programSlug === "micro") {
      let total = 0;
      microArr?.current &&
      Object.keys(microArr?.current).forEach((item) => {
        total += microArr?.current[item]?.numberOfActivitiesToBeReviewed || 0;
      });
      return total;
    } else{
      let total = 0;
      kidVisionArr?.current &&
      Object.keys(kidVisionArr?.current).forEach((item) => {
        total += kidVisionArr?.current[item]?.numberOfActivitiesToBeReviewed || 0;
      });
      return total;
    }
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-flex flex-row position-relative">
        <div className="dash-t-h-left flex-grow-1 p-3 pl-md-8">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>{title}</strong>
          </h2>
        </div>
        {/* dash-t-h-right */}
        <div className="py-2 px-3 flex-grow-1 d-flex justify-content-md-end align-items-md-center">
          <div
            className="ml-md-2 d-inline-block"
            style={{ width: "1.5rem", height: "1.5rem" }}
          >
            <ComponentNotificationBulb />
            {/* count={getNotificationsCount()} */}
          </div>
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
      <Tab.Container id="mapl_tabs" defaultActiveKey="comprehensive">
          <Row>
            <Col xs="12" className="mb-3">
              <Nav variant="pills" className="custom-tabs-link flex-row">
                {
                  ((loggedInUserRoles.includes(CONTENT_ADMIN)) ||
                    (loggedInUserRoles.includes(CONTENT_AUTHOR)) ||
                    (loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER)) ||
                    (loggedInUserRoles.includes(SYSTEM_ADMIN)) ||
                    (loggedInUserRoles.includes(TKL_FACILITATOR))) &&
                  <>
                    <Nav.Item>
                      <Nav.Link
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="comprehensive"
                        onClick={() => {
                          setProgramSlug("comprehensive");
                          setSelectedNav("Comprehensive Course Program");
                        }}
                      >
                        <strong>Comprehensive</strong>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="gplc"
                        onClick={() => {
                          setProgramSlug("gplc");
                          setSelectedNav("Guided PLC Program");
                        }}
                      >
                        <strong>GPLC</strong>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="micro"
                        onClick={() => {
                          setProgramSlug("micro");
                          setSelectedNav("Micro Course Program");
                        }}
                      >
                        <strong>Micro Courses</strong>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="kidvision"
                        onClick={() => {
                          setProgramSlug("kidvision");
                          setSelectedNav("KidVision Program");
                        }}
                      >
                        <strong>KidVision</strong>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                }
                {
                  (loggedInUserRoles.includes(KIDVISION_PROGRAM_MEMBER)) &&
                  <Nav.Item>
                    <Nav.Link
                      ref={defaultLinkKidvision}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="kidvision"
                      onClick={() => {
                        setProgramSlug("kidvision");
                        setSelectedNav("KidVision Program");
                      }}
                    >
                      <strong>KidVision</strong>
                    </Nav.Link>
                  </Nav.Item>
                }
                {
                  (loggedInUserRoles.includes(MICRO_PROGRAM_MEMBER)) &&
                  <Nav.Item>
                    <Nav.Link
                      ref={defaultLinkMicro}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="micro"
                      onClick={() => {
                        setProgramSlug("micro");
                        setSelectedNav("Micro Course Program");
                      }}
                    >
                      <strong>Micro Courses</strong>
                    </Nav.Link>
                  </Nav.Item>
                }
                {
                  ((loggedInUserRoles.includes(GPLC_PROGRAM_MEMBER)) || (loggedInUserRoles.includes(GPLC_PROGRAM_MEMBER_NAME))) &&
                  <Nav.Item>
                    <Nav.Link
                      ref={defaultLinkGplc}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="gplc"
                      onClick={() => {
                        setProgramSlug("gplc");
                        setSelectedNav("Guided PLC Program");
                      }}
                    >
                      <strong>GPLC</strong>
                    </Nav.Link>
                  </Nav.Item>
                }
                {
                  ((loggedInUserRoles.includes(COMPREHENSIVE_PROGRAM_MEMBER_NAME)) || (loggedInUserRoles.includes(COMPREHENSIVE_PROGRAM_MEMBER))) &&
                  <Nav.Item>
                    <Nav.Link
                      ref={defaultLinkComprehensive}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="comprehensive"
                      onClick={() => {
                        setProgramSlug("comprehensive");
                        setSelectedNav("Comprehensive Course Program");
                      }}
                    >
                      <strong>Comprehensive</strong>
                    </Nav.Link>
                  </Nav.Item>
                }

              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="comprehensive">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("comprehensive")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                
                <Tab.Pane eventKey="gplc">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("gplc")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="micro">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("micro")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="kidvision">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("kidvision")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>

              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
};

export default React.memo(ReviewPage);
