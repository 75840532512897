import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../cohorts.scss";
const InputField = (props) => {
  const inputChange = (event) => {
    props.onFieldChange(event);
  };
  return (
    <>
      <div className="numberBar-scrolling course_id course_items_flex">
        <label
          style={{ width: `${props.width}` }}
          className="form-label"
          htmlFor="Course_ID"
        >
          <strong>{props.label}</strong>
        </label>
        <div className="input_field input_field_text">
          {props.isText ? (
            <input
              className="form-control form-control-sm rounded-pill"
              type="text"
              disabled={props.disabled}
              value={props.value}
              placeholder={props.placeholder}
              id={props.id}
              onChange={inputChange}
            />
          ) : props.isCurrency ? (
            // className="dollar"
            <div>
              <input
                type="number"
                pattern="[0-9]"
                max="1000"
                min="0"
                // style={{ paddingLeft: "1.8em" }}
                className="form-control form-control-sm rounded-pill" //currency 
                disabled={props.disabled}
                value={props.value}
                placeholder={props.placeholder}
                onChange={inputChange}
                id={props.id}
              />
            </div>
          ) : (
            <input
              type="number"
              pattern="[0-9]"
              max="1000"
              min="0"
              className="form-control form-control-sm rounded-pill"
              disabled={props.disabled}
              value={props.value}
              placeholder={props.placeholder}
              onChange={inputChange}
              id={props.id}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InputField));
