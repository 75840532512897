import React, { useRef, useMemo, useEffect, useState, useContext } from "react";
import ComponentNotificationBulb from "./../../../shared-components/notification-bulb/index";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Tabs, Tab, Row, Col, Nav, Spinner } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import { connect, useSelector, useDispatch } from "react-redux";
import { getActivityReviewTasks } from "../../../actions/DashboardActions/InstructionalCoachActions";
import {
  BannerImageHandlingForAllDevices,
  convertToISODate,
  getInDateFormat,
} from "../../utility/ReUsableFunctions";
import ContextUser from "../../../contexts/user-context";
import moment from "moment";
import Dropdown from "react-dropdown";
import "../../MasterData/masterData.scss";
import {
  COMPREHENSIVE_PROGRAM_MEMBER,
  COMPREHENSIVE_PROGRAM_MEMBER_NAME,
  CONTENT_ADMIN,
  CONTENT_AUTHOR,
  DISTRICT_FACILITATOR,
  GPLC_PROGRAM_MEMBER,
  GPLC_PROGRAM_MEMBER_NAME,
  MICRO_PROGRAM_MEMBER,
  KIDVISION_PROGRAM_MEMBER,
  MICRO_PROGRAM_MEMBER_NAME,
  SCHOOL_FACILITATOR,
  SYSTEM_ADMIN,
  TKL_ACCOUNT_MANAGER,
  TKL_FACILITATOR,
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
} from "../../../../src/constants.js/RoleNames";

export default function TileP({ title, activityType }) {
  const dispatch = useDispatch();

  const loggedInUserRoles = useSelector((state) => state?.users?.userData?.roles);

  const { facilitatorFeedbacksCourses, isLoading, courseNames } = useSelector(
    (state) => state.dashboards
  );
  const { history } = useContext(ContextUser);

  const [programSlug, setProgramSlug] = useState("comprehensive");

  const [sortBy, setSortBy] = useState("Recent Activity");
  useEffect(() => {
    sessionStorage.removeItem("buttonClicked");
  }, []);

  const [show, setShow] = useState(false);

  const compArr = useRef([]);
  const gplcArr = useRef([]);
  const microArr = useRef([]);
  const kidVisionArr = useRef([]);

  const navLinkComprehensive = useRef();
  const navLinkMicro = useRef();
  useEffect(() => {
    if (programSlug === "comprehensive") {
      dispatch(
        getActivityReviewTasks(
          programSlug,
          activityType,
          true,
          getSortByAttribute(sortBy)
        )
      ).then((responseData) => {
        compArr.current = responseData;
      });
    } else if (programSlug === "gplc") {
      dispatch(
        getActivityReviewTasks(
          programSlug,
          activityType,
          true,
          getSortByAttribute(sortBy)
        )
      ).then((responseData) => {
        gplcArr.current = responseData;
      });
    } else if (programSlug === "micro"){
      dispatch(
        getActivityReviewTasks(
          programSlug,
          activityType,
          true,
          getSortByAttribute(sortBy)
        )
      ).then((responseData) => {
        microArr.current = responseData;
      });
    } else if (programSlug === "kidvision"){
      dispatch(
        getActivityReviewTasks(
          programSlug,
          activityType,
          true,
          getSortByAttribute(sortBy)
        )
      ).then((responseData) => {
        kidVisionArr.current = responseData;
      });
    }
  }, [programSlug, sortBy]);

  useEffect(() => {
    if (programSlug == "comprehensive") {
      setTimeout(() => {
        navLinkMicro?.current?.click();
        setTimeout(() => {
          navLinkComprehensive?.current?.click();
        }, 200);
        setTimeout(() => {
          setShow(true);
        }, 500);
      }, 2000);
    } else {
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, [activityType]);

  const getActivityNameAsPerReviewPage = (activityType) => {
    if (activityType === "reflect-on-it") {
      return "rot";
    } else if (activityType === "master-it") {
      return "mt";
    }
    return "pt";
  };
  const renderData = (str) => {
    let thisArr = {};
    let char = "";
    if (str === "comprehensive") {
      thisArr = compArr?.current || {};
      char = "comp";
    }
    if (str === "gplc") {
      thisArr = gplcArr?.current || {};
      char = "gplc";
    }
    if (str === "micro") {
      thisArr = microArr?.current || {};
      char = "micro";
    }
    if (str === "kidVision") {
      thisArr = kidVisionArr?.current || {};
      char = "kidVision";
    }
    // console.log("thisarg", thisArr, courseNames[activityType]);

    return Object.keys(thisArr)?.length === 0 ? (
      <p className="item-not-selected">No Courses Available</p>
    ) : (
      courseNames[activityType]?.map((item, index) => (
        <React.Fragment key={`t_p_${char}_${index}`}>
          <div className="dash-row-type-2 container rounded mb-2">
            <div className="row">
              <div className="t-row-name col-12 col-md-12 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
                <div className="t-avtr-wrap position-absolute">
                  <a href={thisArr?.item?.link}>
                    <ComponentAvatar
                      type="course"
                      data={{
                        img: BannerImageHandlingForAllDevices(
                          thisArr[item]?.mediaData?.url,
                          "user",
                          thisArr[item]?.mediaData?.avatarSlug
                        ),
                      }}
                    />
                  </a>
                </div>
                <div>
                  <a href={thisArr?.item?.link} className="t-row-name-link">
                    {thisArr[item]?.courseName}
                  </a>
                </div>
              </div>
              <div
                className="my_assigned_courses_style"
                style={{ justifyContent: "space-between" }}
              >
                <div
                  className="col-12 col-md-8 d-flex flex-column"
                  style={{ alignItems: "flex-start" }}
                >
                  <small className="text-muted">
                    {thisArr[item]?.displayDate &&
                      moment(thisArr[item]?.displayDate).format("MM-DD-YYYY")}
                  </small>
                  <div>
                    <small>
                      <i className="material-icons text-primary">place</i>
                    </small>
                    <small className="text-black-50">
                      {thisArr[item]?.marketLocation}
                    </small>
                  </div>
                </div>
                <div className="col-12 col-md-2 p-2 d-flex flex-column justify-content-center align-items-end">
                  <ComponentCtaBtn
                    notifications={
                      thisArr[item]?.numberOfActivitiesToBeReviewed
                    }
                    onClick={() => {
                      sessionStorage.setItem("buttonClicked",true);
                      sessionStorage.setItem(
                        "courseSlug",
                        thisArr[item]?.courseSlug
                      );
                      sessionStorage.setItem(
                        "courseName",
                        thisArr[item]?.courseName
                      );
                      sessionStorage.setItem(
                        "districtName",
                        thisArr[item]?.districtName
                      );
                      sessionStorage.setItem(
                        "schoolName",
                        thisArr[item]?.schoolName
                      );
                      sessionStorage.setItem(
                        "marketLocationSlug",
                        thisArr[item]?.marketLocationSlug
                      );
                      sessionStorage.setItem(
                        "cohortCourseID",
                        thisArr[item]?.cohortDisplayId
                      );
                      sessionStorage.setItem(
                        "activityType",
                        getActivityNameAsPerReviewPage(activityType)
                      );

                      sessionStorage.setItem(
                        "MIRAccountId",
                        thisArr[item]?.accountId
                      );

                      sessionStorage.setItem(
                        "MIRSchoolId",
                        thisArr[item]?.schoolId
                      );
                       console.log("thisArr[item]", thisArr[item]);   
                      sessionStorage.removeItem("userSlugSession");
                      sessionStorage.removeItem("userNameSession");

                      str === "comprehensive"
                        ? history.push("/course-review")
                        : str === "gplc"
                        ? history.push("/course-review-gplc")
                        : str === "micro"
                        ? history.push(`/course-review-micro`) 
                        : history.push(`/course-review-kidvision`)
                      history.go(0);
                    }}
                    data={{
                      tag: "button",
                      // href: "#",
                      ctastyle: "primary",
                      text: "Review",
                      title: "Review",
                      size: "sm",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
        </React.Fragment>
      ))
    );
  };

  const getNotificationsCount = () => {
    if (programSlug === "comprehensive") {
      let total = 0;
      compArr?.current &&
        Object.keys(compArr?.current).forEach((item) => {
          total += compArr?.current[item]?.numberOfActivitiesToBeReviewed || 0;
        });
      return total;
    } else if (programSlug === "gplc") {
      let total = 0;
      gplcArr?.current &&
      Object.keys(gplcArr?.current).forEach((item) => {
        total += gplcArr?.current[item]?.numberOfActivitiesToBeReviewed || 0;
      });
      return total;
    } else if (programSlug === "micro"){
      let total = 0;
      microArr?.current &&
      Object.keys(microArr?.current).forEach((item) => {
        total += microArr?.current[item]?.numberOfActivitiesToBeReviewed || 0;
      });
      return total;
    } else if (programSlug === "kidvision"){
      let total = 0;
      kidVisionArr?.current &&
      Object.keys(kidVisionArr?.current).forEach((item) => {
        total += kidVisionArr?.current[item]?.numberOfActivitiesToBeReviewed || 0;
      });
      return total;
    }
  };

  const getSortByAttribute = (val) => {
    if (val === "Recent Activity") {
      return "recentActivity";
    } else if (val === "Market Location") {
      return "marketLocation";
    } else if (val === "Course Name") {
      return "courseName";
    } else if (val === "Total Notifications") {
      return "total";
    }
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-flex flex-row position-relative">
        <div className="dash-t-h-left flex-grow-1 p-3 pl-md-8">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>{title}</strong>
          </h2>
        </div>
        {/* dash-t-h-right */}
        <div className="py-2 px-3 flex-grow-1 d-flex justify-content-md-end align-items-md-center">
          <div
            className="ml-md-2 d-inline-block"
            style={{ width: "1.5rem", height: "1.5rem" }}
          >
            <ComponentNotificationBulb count={getNotificationsCount()} />
          </div>
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container id="mapl_tabs" defaultActiveKey="comprehensive">
          <Row>
            <Col xs="12" className="mb-3">
              <Nav
                variant="pills"
                className="custom-tabs-link flex-row tile_p_nav"
              >
                <div className="tile_p_nav_group">
                {
                  ((loggedInUserRoles.includes(CONTENT_ADMIN)) ||
                  (loggedInUserRoles.includes(CONTENT_AUTHOR)) ||
                  (loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER)) ||
                  (loggedInUserRoles.includes(SYSTEM_ADMIN)) ||
                  (loggedInUserRoles.includes(TKL_FACILITATOR))) &&
                  <>
                  <Nav.Item>
                    <Nav.Link
                      ref={navLinkComprehensive}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="comprehensive"
                      onClick={() => {
                        setProgramSlug("comprehensive");
                      }}
                    >
                      <strong>Comprehensive</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="gplc"
                      onClick={() => {
                        setProgramSlug("gplc");
                      }}
                    >
                      <strong>GPLC</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      ref={navLinkMicro}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="micro"
                      onClick={() => {
                        setProgramSlug("micro");
                      }}
                    >
                      <strong>Micro Courses</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      // ref={navLinkMicro}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="kidvision"
                      onClick={() => {
                        setProgramSlug("kidvision");
                      }}
                    >
                      <strong>KidVision</strong>
                    </Nav.Link>
                  </Nav.Item>
                  </>
                }
                {  
                  (loggedInUserRoles.includes(KIDVISION_PROGRAM_MEMBER)) &&
                  <Nav.Item>
                  <Nav.Link
                    ref={navLinkMicro}
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="kidvision"
                    onClick={() => {
                      setProgramSlug("kidvision");
                    }}
                  >
                    <strong>KidVision</strong>
                  </Nav.Link>
                </Nav.Item>
                }
                {  
                  (loggedInUserRoles.includes(MICRO_PROGRAM_MEMBER)) &&
                  <Nav.Item>
                    <Nav.Link
                      ref={navLinkMicro}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="micro"
                      onClick={() => {
                        setProgramSlug("micro");
                      }}
                    >
                      <strong>Micro Courses</strong>
                    </Nav.Link>
                  </Nav.Item>
                }
                {  
                  ((loggedInUserRoles.includes(GPLC_PROGRAM_MEMBER)) || (loggedInUserRoles.includes(GPLC_PROGRAM_MEMBER_NAME))) &&
                  <Nav.Item>
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="gplc"
                      onClick={() => {
                        setProgramSlug("gplc");
                      }}
                    >
                      <strong>GPLC</strong>
                    </Nav.Link>
                  </Nav.Item>
                }
                {  
                  ((loggedInUserRoles.includes(COMPREHENSIVE_PROGRAM_MEMBER_NAME)) || (loggedInUserRoles.includes(COMPREHENSIVE_PROGRAM_MEMBER))) &&
                  <Nav.Item>
                    <Nav.Link
                      ref={navLinkComprehensive}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="comprehensive"
                      onClick={() => {
                        setProgramSlug("comprehensive");
                      }}
                    >
                      <strong>Comprehensive</strong>
                    </Nav.Link>
                  </Nav.Item>
                }
                  
                </div>
                <div className={"tile_p_controls"}>
                  <Dropdown
                    options={[
                      "Recent Activity",
                      "Market Location",
                      "Course Name",
                      "Total Notifications",
                    ]}
                    placeholder={"Recent Activity"}
                    className="sort-by-dropdown-styles_facilitator tile_p_sortBy"
                    onChange={(e) => {
                      console.log(e.value);
                      setSortBy(e.value);
                    }}
                    //    menuPlacement="auto"
                    arrowClosed={
                      <span className="material-icons">arrow_drop_down</span>
                    }
                    arrowOpen={
                      <span className="material-icons">arrow_drop_up</span>
                    }
                  />
                </div>
              </Nav>
            </Col>
          </Row>

          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="comprehensive">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {!show || isLoading ? (
                      <div className="tkl-community-spinner tkl-spinner-master-data">
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="lr"
                        />
                      </div>
                    ) : (
                      renderData("comprehensive")
                    )}

                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="gplc">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {!show || isLoading ? (
                      <div className="tkl-community-spinner tkl-spinner-master-data">
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="lr"
                        />
                      </div>
                    ) : (
                      renderData("gplc")
                    )}

                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="micro">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {!show || isLoading ? (
                      <div className="tkl-community-spinner tkl-spinner-master-data">
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="lr"
                        />
                      </div>
                    ) : (
                      renderData("micro")
                    )}

                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>

                <Tab.Pane eventKey="kidvision">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {!show || isLoading ? (
                      <div className="tkl-community-spinner tkl-spinner-master-data">
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="lr"
                        />
                      </div>
                    ) : (
                      renderData("kidVision")
                    )}

                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}
