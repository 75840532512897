import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ComponentCtaBtn from "../../shared-components/cta-btn";
import "./_index.scss";

const ComponentSitefooterTKL = React.memo(function ComponentSitefooterTKL() {
  return (
    <>
      <section
        className="content-section cta-block tkl-footer"
        style={{
          backgroundImage:
            "url(https://www.teachnkidslearn.com/wp-content/uploads/2019/08/Group-1929@2x.png)",
        }}
      >
        <div className="container center">
          <h2 className="cta-block__title color-white">Get Started</h2>
          <h5 className="color-white">
            Ready to learn how TKL’s scaffolded approach to learning can
            transform your educational practice?
          </h5>
          <a href="https://www.teachnkidslearn.com/book-a-demo/">
            <Button
              style={{ borderRadius: "0px" }}
              className="btn btn-black pl-3 pr-3 m-5"
            >
              <strong>BOOK A DEMO</strong>
            </Button>
          </a>
        </div>
      </section>
      <section className="sitefooter-comp color-white py-3 py-md-5 tkl-footer bg-black">
        <footer class="footer">
          <Container fluid className="padding-lr">
            <Row>
              <Col
                xs={0}
                md={6}
                lg={3}
                class="pure-u-1 pure-u-md-1-2 pure-u-lg-1-4 footer__contact"
              >
                <h5 class="footer__title">CONTACT</h5>

                <p>
                  333 Southeast 2nd Avenue, 20th Floor Suite, Miami, FL 33131
                </p>

                <p>Phone: 1-855-498-4400</p>

                <p>Email: pd@teachnkidslearn.com</p>

                <p>
                  Hours: 8:30 am – 5:30 pm, Monday – Friday (limited support
                  evenings, weekends and holidays){" "}
                </p>
                <div className="tkl_social_footer">
                  <ul className="footer__social">
                    <li>
                      <a
                        href="https://www.facebook.com/TeachnKidsLearn/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          width="2em"
                          height="2em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M288 192v-38.1c0-17.2 3.8-25.9 30.5-25.9H352V64h-55.9c-68.5 0-91.1 31.4-91.1 85.3V192h-45v64h45v192h83V256h56.4l7.6-64h-64z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/TeachNKidsLearn/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          width="2em"
                          height="2em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path
                              d="M23.643 4.937c-.835.37-1.732.62-2.675.733a4.67 4.67 0 0 0 2.048-2.578a9.3 9.3 0 0 1-2.958 1.13a4.66 4.66 0 0 0-7.938 4.25a13.229 13.229 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342A4.66 4.66 0 0 0 3.96 9.824a4.647 4.647 0 0 1-2.11-.583v.06a4.66 4.66 0 0 0 3.737 4.568a4.692 4.692 0 0 1-2.104.08a4.661 4.661 0 0 0 4.352 3.234a9.348 9.348 0 0 1-5.786 1.995a9.5 9.5 0 0 1-1.112-.065a13.175 13.175 0 0 0 7.14 2.093c8.57 0 13.255-7.098 13.255-13.254c0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003z"
                              fill="white"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/teach-n'-kids-learn-inc-tkl-/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          width="2em"
                          height="2em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <g fill="none">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1 2.838A1.838 1.838 0 0 1 2.838 1H21.16A1.837 1.837 0 0 1 23 2.838V21.16A1.838 1.838 0 0 1 21.161 23H2.838A1.838 1.838 0 0 1 1 21.161V2.838zm8.708 6.55h2.979v1.496c.43-.86 1.53-1.634 3.183-1.634c3.169 0 3.92 1.713 3.92 4.856v5.822h-3.207v-5.106c0-1.79-.43-2.8-1.522-2.8c-1.515 0-2.145 1.089-2.145 2.8v5.106H9.708V9.388zm-5.5 10.403h3.208V9.25H4.208v10.54zM7.875 5.812a2.063 2.063 0 1 1-4.125 0a2.063 2.063 0 0 1 4.125 0z"
                              fill="white"
                            />
                          </g>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/TeachnKidsLearn"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          width="2.5em"
                          height="2.5em"
                          preserveAspectRatio="xMidYMid meet"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M9.935 14.628v-5.62l5.403 2.82l-5.403 2.8zM21.8 8.035s-.195-1.379-.795-1.986c-.76-.796-1.613-.8-2.004-.847C16.203 5 12.004 5 12.004 5h-.008s-4.198 0-6.997.202c-.391.047-1.243.05-2.004.847c-.6.607-.795 1.986-.795 1.986S2 9.653 2 11.272v1.517c0 1.618.2 3.237.2 3.237s.195 1.378.795 1.985c.76.797 1.76.771 2.205.855c1.6.153 6.8.2 6.8.2s4.203-.006 7.001-.208c.391-.047 1.244-.05 2.004-.847c.6-.607.795-1.985.795-1.985s.2-1.619.2-3.237v-1.517c0-1.619-.2-3.237-.2-3.237z"
                            fill="white"
                            fill-rule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>

              <Col
                xs={0}
                md={6}
                lg={3}
                className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-4 footer__navigation"
              >
                <h5 className="footer__title">NAVIGATION</h5>
                <div className="menu-footer-menu-container">
                  <ul id="menu-footer-menu" className="menu">
                    <li
                      id="menu-item-51"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-51"
                    >
                      <a href="https://teachingknowledgeloop.com/external-catalog">
                        Online Courses
                      </a>
                    </li>
                    <li
                      id="menu-item-253"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-253"
                    >
                      <a href="https://www.teachnkidslearn.com/comprehensive-courses/">
                        Comprehensive Courses
                      </a>
                    </li>
                    <li
                      id="menu-item-254"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-254"
                    >
                      <a href="https://www.teachnkidslearn.com/micro-courses/">
                        Micro Courses Program
                      </a>
                    </li>
                    <li
                      id="menu-item-255"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-255"
                    >
                      <a href="https://www.teachnkidslearn.com/guided-plcs/">
                        Guided PLCs Program
                      </a>
                    </li>
                    <li
                      id="menu-item-256"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-256"
                    >
                      <a href="https://www.teachnkidslearn.com/evaluwise-partner-program/">
                        EvaluWise Partner Program
                      </a>
                    </li>
                    <li
                      id="menu-item-52"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-52"
                    >
                      <a href="https://www.teachnkidslearn.com/about-tkl/">
                        About TKL
                      </a>
                    </li>
                    <li
                      id="menu-item-53"
                      className="demo-intent-foot menu-item menu-item-type-post_type menu-item-object-page menu-item-53"
                    >
                      <a href="https://www.teachnkidslearn.com/book-a-demo/">
                        Book a Demo
                      </a>
                    </li>
                    <li
                      id="menu-item-8107"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-8107"
                    >
                      <a href="https://www.teachnkidslearn.com/general-inquiries/">
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>{" "}
              </Col>

              <Col
                xs={12}
                md={6}
                lg={3}
                className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-4 footer__resources"
              >
                <h5 className="footer__title">RESOURCES</h5>
                <div className="menu-footer-resources-menu-container">
                  <ul id="menu-footer-resources-menu" className="menu">
                    <li
                      id="menu-item-55"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-55"
                    >
                      <a href="https://www.teachnkidslearn.com/blog/">Blog</a>
                    </li>
                    <li
                      id="menu-item-56"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-56"
                    >
                      <a href="https://www.teachnkidslearn.com/about-tkl/faqs/">
                        FAQs
                      </a>
                    </li>
                    <li
                      id="menu-item-8366"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-8366"
                    >
                      <a href="https://www.teachnkidslearn.com/courses_category/comprehensive-courses/">
                        Course Library
                      </a>
                    </li>
                    <li
                      id="menu-item-250"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-250"
                    >
                      <a href="https://www.teachnkidslearn.com/state-alignment/">
                        State Alignment
                      </a>
                    </li>
                  </ul>
                </div>{" "}
              </Col>

              <Col
                xs={12}
                md={6}
                lg={3}
                className="pure-u-1 pure-u-md-1-2 pure-u-lg-1-4 footer__joinMail"
              >
                <h5 className="footer__title">JOIN OUR MAILING LIST</h5>
                <a href="https://www.teachnkidslearn.com/subscribe/">
                  <ComponentCtaBtn
                    className="pl-3 pr-3 pb-2 btn-tkl"
                    data={{
                      tag: "button",
                      type: "button",
                      ctastyle: "tkl",
                      text: "SUBSCRIBE",
                      title: "SUBSCRIBE",
                    }}
                  />
                </a>
              </Col>
            </Row>
          </Container>

          <Container fluid className="padding-lr footer-border-top pt-2">
            <Row
              style={{
                borderTop: "1px solid rgba(241,241,241,.22)",
                paddingTop: "1rem",
              }}
            >
              <Col>
                <a
                  href="https://www.teachnkidslearn.com/privacy-policy/"
                  className="privacy_policy"
                >
                  Privacy Policy
                </a>
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Copyright © 2021 Teach &amp;
                  Kids Learn (TKL). All rights reserved.
                </span>
              </Col>
            </Row>
          </Container>
        </footer>
      </section>
    </>
  );
});

export default ComponentSitefooterTKL;
