import axios from "axios";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import {
  errorMsgForCSVUpload,
  prepareErrorMsg,
  hideLoader,
} from "../../components/utility/ReUsableFunctions";
import { types } from "../../reducers/types";
import { showFirstValidationErrorFromAPI } from "../../components/utility/ApiErrorHandler";
export const addAcount = (payload, history) => {
  return (dispatch) => {
    axios
      .post(`${URL}/add-account`, payload)
      .then(function (response) {
        // console.log("response : addAcount", response);

        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Account Created Successfully",
          });
          history.push("/tklAdmin/account/" + response?.data?.accountId);
          sessionStorage.setItem("showNext", true);
        }
      })
      .catch(function (error) {
        if (error.response) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
      });
  };
};

export const assignCohortGroup = (payload) => {
  return (dispatch) => {
    axios
      .post(`${URL_COHORT}/assign-cohort-group`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Assigned courses to cohort group",
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
      });
  };
};

export const deleteMemberFromCohort = (cohortSlug, memberSlug) => {
  return (dispatch) => {
    axios
      .delete(
        `${URL_COHORT}/remove-user-from-cohort?cohortSlug=${cohortSlug}&users[]=${memberSlug}`
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({ type: types.IS_RELOAD_TKL, payload: true });

          dispatch({
            type: types.API_SUCCESS,
            payload: "Member removed SuccessFully",
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
      });
  };
};

export const addUserToCohort = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL_COHORT}/add-user-to-cohort`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({ type: types.IS_RELOAD_TKL, payload: true });
          dispatch({
            type: types.API_SUCCESS,
            payload: "Successfully Added Users to Cohort",
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: types.API_ERROR,
            payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const getCohortMembers = (accountID, schoolId) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    let initialTime = performance.now();
    //dispatch({ type: types.SHOW_LOADER });

    axios
      .get(`${URL_COHORT}/get-cohort-list/${accountID}/${schoolId}`)
      .then(function (response) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        dispatch({ type: types.IS_RELOAD_TKL, payload: false });
        if (response?.data) {
          dispatch({
            type: types.LIST_OF_COHORT_MEMBERS,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        dispatch({ type: types.IS_RELOAD_TKL, payload: false });

        if (error.response) {
          dispatch({
            type: types.API_ERROR,
            payload: "Error while Getting Cohort Members",
            // payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const removeCohortFromTopic = (cohortGroupSlug, cohortDisplayId) => {
  return (dispatch) => {
    axios
      .delete(
        `${URL_COHORT}/remove-cohort-from-cohort-group?cohortGroupSlug=${cohortGroupSlug}&cohortDisplayId=${cohortDisplayId}`
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Cohort removed from topic Successfully",
          });

          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: types.API_ERROR,
            payload: "Error while  removing Cohort from topic",
            // payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const deleteAcount = (payload, history) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-account/${payload}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Account Deleted Successfully",
          });

          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({
            type: types.API_ERROR,
            payload: "Error while Delete Account",
            // payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const getAllAccount = (accountType, pageNo, isPaginated, perPage = 15) => {
  // accountType = programId
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    let initialTime = performance.now();
    //dispatch({ type: types.SHOW_LOADER });
    if (accountType === 1 || accountType === 2 || accountType === 3 || accountType === 4 ) {
      axios
        .get(
          // ApiCall for gpcl here api call going when on click on tkl admin
          `${URL}/get-all-accounts/${
          // accountType === "gplc" ? 1 : 0
          accountType
          }?isPaginated=${isPaginated}&page=${pageNo}&perPage=${perPage}`
        )
        .then(function (response) {
          //hideLoader(initialTime);
          dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });

          if (response?.data) {
            dispatch({
              type: types.LIST_OF_ACCOUNTS,
              payload: response?.data?.response,
            });
          }
        })
        .catch(function (error) {
          dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
          if (error.response) {
            dispatch({
              type: types.API_ERROR,
              payload: "Error while Getting  Accounts",
              // payload: prepareErrorMsg(error.response),
            });
          }
        });
    }
  };
};

export const getUnAssigedUsersForSchool = (schoolID) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .get(`${URL_COHORT}/get-unassigned-school-users/${schoolID}`)
      .then(function (response) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        if (response.data) {
          dispatch({
            type: types.GET_UNASSIGNED_MEMBERS,
            payload: response?.data,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        if (error.response) {
          prepareErrorMsg(error.response);
        }
      });
  };
};

export const getMemberMicroCourse = (payload) => {
  return (dispatch) => {  
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .get(`${URL}/get-member-by-micro-course/`, {
        params: payload,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      .then(function (response) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        if (response.data) {
          dispatch({
            type: types.GET_MICRO_COUSRSE_DETAILS,
            payload: response?.data,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        if (error.response) {
          prepareErrorMsg(error.response);
        }
      });
  };
};

export const uploadCSV = (payload, accountId) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .post(`${URL}/upload-csv`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(function (response) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        if (response.data) {
          // console.log(" response.data ", response.data);

          if (response?.data?.response?.error) {
            dispatch({
              type: types.API_SUCCESS,
              payload: errorMsgForCSVUpload(response?.data?.response),
            });
          } else {
            dispatch({
              type: types.API_SUCCESS,
              payload: response?.data?.response?.message,
            });
            accountId &&
              dispatch(getSchoolsByAccountId(accountId, false, true));
          }

          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        } else {
          dispatch({
            type: types.API_ERROR,
            payload: "Error while Uploading Users",
            // payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const createCohortGroup = (payload) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .post(`${URL_COHORT}/add-cohort-account`, payload)
      .then(function (response) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });

        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Cohort Created Successfully",
          });

          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });

          dispatch({
            type: types.API_ERROR,
            payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const addUserToSchool = (payload, accountId) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .post(`${URL}/add-user-to-school`, payload) 
      .then(function (response) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "User Added Successfully",
          });

          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: true,
          });
          accountId && dispatch(getSchoolsByAccountId(accountId, false, true));
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
          let errorMessage = error?.response?.data;
          // console.log("....errorMessage", errorMessage);
          if (errorMessage?.errors?.email[0] === "The email has already been taken.") {
            dispatch({
              type: types.API_ERROR,
              payload: "The email has already been taken",
            });
          } else {
            dispatch({
              type: types.API_ERROR,
              payload: "Error while Adding Users Manually",
              // payload: prepareErrorMsg(error.response),
            });
          }
        }
      });
  };
};

export const getDistrictAdmin = (schoolids, selectedRole, pageNo) => {
  return (dispatch) => {
    let role = "";
    if (selectedRole === "") {
      role = "roles[]=DISTRICT FACILITATOR&roles[]=DISTRICT ADMIN&";
    } else {
      role = "roles[]=" + selectedRole + "&";
    }
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .get(
        `${URL}/get-district-level-users?${schoolids}${role}isPaginated=1&page=${pageNo}`
      )
      .then(function (response) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        dispatch({
          type: types.IS_RELOAD_TKL,
          payload: false,
        });

        if (response.data) {
          dispatch({
            type: types.LIST_OF_DISTRICT_ADMINS,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        console.error("errors", error);
      });
  };
};

export const searchBasedOnFieldTypeAndValueForDistrictAdmin = (
  URLPoint,
  isPaginated,
  searchType,
  searchField,
  searchValue,
  schoolIds,
  selectedRole
) => {
  let role = "";
  if (selectedRole === "") {
    role = "roles[]=DISTRICT FACILITATOR&roles[]=DISTRICT ADMIN&";
  } else {
    role = "roles[]=" + selectedRole + "&";
  }
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .get(
        `${URL}/${URLPoint}?${schoolIds}?isPaginated=${isPaginated}${role}&searchType=${searchType}&searchKey=${searchField}&searchValue=${searchValue}`
      )
      .then((response) => {
        if (response.data) {
          dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: false,
          });
          dispatch({
            type: types.LIST_OF_DISTRICT_ADMINS,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
      });
  };
};

export const getMembers = (schoolids, pageNo) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });

    axios
      .get(`${URL}/get-school-users?${schoolids}isPaginated=1&page=${pageNo}`)
      .then(function (response) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        dispatch({
          type: types.IS_RELOAD_TKL,
          payload: false,
        });

        if (response.data) {
          dispatch({
            type: types.LIST_OF_MEMBERS,
            payload: response.data,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        console.error("errors", error);
      });
  };
};

export const searchBasedOnFieldTypeAndValueForUser = (
  URLPoint,
  isPaginated,
  searchType,
  searchField,
  searchValue,
  schoolIds
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .get(
        `${URL}/${URLPoint}?${schoolIds}?isPaginated=${isPaginated}&searchType=${searchType}&searchKey=${searchField}&searchValue=${searchValue}`
      )
      .then((response) => {
        if (response.data) {
          dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: false,
          });
          dispatch({
            type: types.LIST_OF_MEMBERS,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
      });
  };
};
export const sortBasedonSortOrderForUser = (
  URLPoint,
  isPaginated,
  searchType,
  sortBy,
  sortOrder,
  searchFieldType,
  searchInput,
  schoolIds
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    let apiUrl = "";
    if (searchInput) {
      apiUrl = `${URL}/${URLPoint}?${schoolIds}isPaginated=${isPaginated}&searchType=${searchType}&searchKey=${searchFieldType}&searchValue=${searchInput}&sortBy=${sortBy}&orderBy=${sortOrder}`;
    } else {
      apiUrl = `${URL}/${URLPoint}?${schoolIds}isPaginated=${isPaginated}&searchType=${searchType}&sortBy=${sortBy}&orderBy=${sortOrder}`;
    }
    axios
      .get(apiUrl)
      .then((response) => {
        if (response.data) {
          dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: false,
          });
          dispatch({
            type: types.LIST_OF_MEMBERS,
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
      });
  };
};

export const updateAccount = (payload, history) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .put(`${URL}/update-account`, payload)
      .then(function (response) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });

        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Account Updated Successfully",
          });
          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: true,
          });
          history.push("/tklAdmin/account/" + response?.data?.accountId);
          sessionStorage.setItem("showNext", true);
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
          dispatch({
            type: types.API_ERROR,
            payload: "Error while Updating Account",
            // payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const getAccount = (accountId) => {
  return (dispatch) => {
    axios
      .get(`${URL}/get-account/${accountId}`)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.GET_ACCOUNT, payload: response.data });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getAccountManagers = () => {
  return (dispatch) => {
    axios
      .get(`${URL}/get-account-managers`)
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_ACCCOUNT_MANAGERS,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getSchoolsByAccountId = (
  accountId,
  isTKLManagerForDashboards,
  isGPLC,
  isSklAdmOrFacilitator,
) => {
  return (dispatch) => {
    axios
      .get(`${URL}${isSklAdmOrFacilitator ? "/get-schools-by-role/" : "/get-schools/"}${accountId}`)
      .then(function (response) {
        if (response.data) {
          if (isTKLManagerForDashboards) {
            if (isGPLC) {
              dispatch({
                type: types.RESUABLE_GET_ACTION,
                payload: response?.data?.response,
                label: "schools",
              });
            } else {
              dispatch({
                type: types.RESUABLE_GET_ACTION,
                payload: response?.data?.response,
                label: "schoolsMicro",
              });
            }
          } else {
            dispatch({
              type: types.GET_SCHOOLS_BY_ID,
              payload: response?.data?.response,
            });
          }
        }
      })
      .catch(function (error) {
        if (error.response) {
          //prepareErrorMsg(error.response);
        }
      });
  };
};

export const updateSchools = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/update-schools`, payload)
      .then(function (response) {
        //console.log(response);

        if (response.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Updated Schools Successfully",
          });
          sessionStorage.setItem("showNext", true);
        }
      })
      .catch(function (error) {
        if (error.response) {
          // prepareErrorMsg(error.response);
        }
      });
  };
};

export const downloadCSV = (school, accountName) => {
  return (dispatch) => {
    axios
      .get(`${URL}/download-csv/${school?.id}`)
      .then(function (response) {
        if (response.data) {
          let headerLine = response?.data?.headers?.["content-disposition"];
          let headerLine1 = response?.headers?.["Content-Disposition"];

          //console.log("headerLine2 ", response, headerLine, headerLine1);

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let fileName =
            accountName.replace(" ", "-") +
            "_" +
            school?.name.replace(" ", "-") +
            ".csv";

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          // console.log("response.data", response.data);
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const searchBasedOnFieldTypeAndValueForAccount = ( 
  URLPoint,
  isPaginated,
  searchType,
  searchField,
  searchValue,
  accountType,
  perPage = 15
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .get(
        `${URL}/${URLPoint}/${
          accountType
        }?isPaginated=${isPaginated}&perPage=${perPage}&searchType=${"account"}&searchKey=${searchField}&searchValue=${searchValue}`
      )
      .then((response) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });

        if (response?.data) {
          dispatch({
            type: types.LIST_OF_ACCOUNTS,
            payload: response?.data?.response,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
      });
  };
};

export const sortBasedonSortOrderForAccount = (
  URLPoint,
  isPaginated,
  searchType,
  sortBy,
  sortOrder,
  searchFieldType,
  searchInput,
  accountType,
  perPage = 15
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    let apiUrl = "";
    if (searchInput) {
      apiUrl = `${URL}/${URLPoint}/${accountType}?isPaginated=${isPaginated}&perPage=${perPage}&searchType=${"account"}&searchKey=${searchFieldType}&searchValue=${searchInput}&sortBy=${sortBy}&orderBy=${sortOrder}`;
    } else {
      apiUrl = `${URL}/${URLPoint}/${accountType}?isPaginated=${isPaginated}&perPage=${perPage}&searchType=${"account"}&sortBy=${sortBy}&orderBy=${sortOrder}`;
    }
    axios
      .get(apiUrl)
      .then((response) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });

        if (response?.data) {
          dispatch({
            type: types.LIST_OF_ACCOUNTS,
            payload: response?.data?.response,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
      });
  };
};

export const getRoles = () => {
  return (dispatch) => {
    axios
      .get(`${URL}/get-roles`)
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_ROLES,
            payload: response?.data,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getPathRightUploadStatusData = (page) => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-path-right-upload-status?page=${page}`)
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GET_PATH_RIGHT_UPLOAD_STATUS,
            payload: response?.data,
          });
        }
      })
      .catch(function (error) {
      });
  };
};

export const getPathRightData = (searchField,sortValue,searchValue,sortOrder,page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    let initialTime = performance.now();
    //dispatch({ type: types.SHOW_LOADER });
    let apiUrl = `${URL_COHORT}/get-path-right-data?page=${page}`;
    if(sortValue && sortOrder)
    {
      apiUrl = apiUrl+`&sortBy=${sortValue}&orderBy=${sortOrder}`
    }
    if(searchField && searchValue)
    {
      apiUrl = apiUrl+`&searchValue=${searchValue}&searchBy=${searchField}`
    }
    axios
      .get(apiUrl)
      .then(function (response) {
        //hideLoader(initialTime);
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });

        if (response?.data) {
          dispatch({
            type: types.GET_PATH_RIGHT_DATA,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
        if (error.response) {
          dispatch({
            type: types.API_ERROR,
            payload: "Error while Getting  Accounts",
            // payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const getPathRightSearchData = (
  searchField,
  searchValue,
  page
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    axios
      .get(
        `${URL_COHORT}/get-path-right-data?searchBy=${searchField}&searchValue=${searchValue}&page=${page}`
      )
      .then((response) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });

        if (response?.data) {
          dispatch({
            type: types.GET_PATH_RIGHT_DATA,
            payload: response?.data?.response,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
      });
  };
};

export const getPathRightSortData = (
  searchField,
  sortValue,
  searchValue,
  sortOrder,
  page
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING_TKLADMIN, payload: true });
    let apiUrl = "";
    if(searchValue)
    {
        apiUrl =`${URL_COHORT}/get-path-right-data?sortBy=${sortValue}&orderBy=${sortOrder}&searchValue=${searchValue}&searchBy=${searchField}`
    }
    else{
        apiUrl = `${URL_COHORT}/get-path-right-data?sortBy=${sortValue}&orderBy=${sortOrder}`
    }
    axios
      .get(apiUrl)
      .then((response) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });

        if (response?.data) {
          dispatch({
            type: types.GET_PATH_RIGHT_DATA,
            payload: response?.data?.response,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: types.IS_LOADING_TKLADMIN, payload: false });
      });
  };
};
