import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStepTypes } from "../../../actions/CoursePath/coursePathAction";
import { getCohortData } from "../../../actions/CohortsActions/coursePathForCohortAction";
import { getActiveUsersPoints } from "../../../actions/Intructor/IntructorActions";
import ComponentProgressBar from "../../../shared-components/progress-bar";
import "./CoursePathReport.scss";

import { getProfileData } from "../../../actions/UserActions/ProfileActions";
import ComponentAvatar from "../../../shared-components/avatar";
import {
  BannerImageHandlingForAllDevices,
  ConfirmationPopup,
  getFullName,
  InfoPopup,
} from "../../utility/ReUsableFunctions";
import { Button, Card } from "react-bootstrap";

import LogoLoader from "../../../shared-components/page-loader/logoLoader";

import { resetActivityByLearner } from "../../../actions/ActivitySettingActions/cohortLearnerActions";
function CoursePathReport() {
  const { cohortId, userSlug, courseSlug } = useParams();
  const dispatch = useDispatch();

  const { stepTypes } = useSelector((state) => state.coursepath);
  const { courseCohortData } = useSelector((state) => state.cohorts);
  const { userPoints } = useSelector((state) => state.cohorts);
  const { otherUserData } = useSelector((state) => state.users);
  const { isLoading } = useSelector((state) => state.dashboards);

  const [ready, setReady] = useState(false);

  const [resetConfirmDialog, setResetConfirmDialog] = useState({
    show: false,
    isIntro: false,
    stepSlug: false,
  });

  useEffect(() => {
    dispatch(getStepTypes());
    dispatch(getProfileData(userSlug, false, false));
  }, []);

  useEffect(() => {
    if (!ready) dispatch(getCohortData(cohortId, stepTypes, userSlug));
  }, [stepTypes]);

  useEffect(() => {
    console.log("courseCohortData", courseCohortData);
    dispatch(getActiveUsersPoints(userSlug, courseSlug));
    setReady(true);
  }, [courseCohortData]);

  const resetActivity = (introId, activitySlug) => {
    setReady(false);
    console.log(activitySlug, userSlug);
    if (activitySlug && userSlug) {
      dispatch(resetActivityByLearner(introId, activitySlug, userSlug));
    }

    dispatch(getStepTypes());
    dispatch(getProfileData(userSlug, false, false));
  };

  const Modules = () => {
    return (
      <div className="width__90">
        {courseCohortData?.cohortCourseWithCohortModules?.map(
          (cohortModule, i) => {
            return (
              <Card eventKey={i} className="p-3 mb-5 bg__module" >
                <Card.Title className="primary__color">
                  <strong> Module: {i + 1 + " " + cohortModule.name}</strong>
                </Card.Title>
                {cohortModule?.CohortModuleWithCohortSections?.map(
                  (cohortSection, j) => {
                    return (
                      <Card.Body>
                        <Card className="p-2 no-border">
                          <Card.Title className="primary__color">
                            <strong>
                              {" "}
                              Section: {i + 1}.
                              {j + 1 + " " + cohortSection.name}
                            </strong>
                          </Card.Title>
                          {cohortSection?.sectionWithActivities?.map(
                            (sectionWithActivity, k) => {
                              return (
                                <Card.Body>
                                  <Card className="p-2 ">
                                    <Card.Title className="primary__color">
                                      {" "}
                                      <a
                                        href={`/cohortActivity/${cohortId}/${
                                          i + 1
                                        }/${j + 1}/${k + 1}/${
                                          sectionWithActivity.slug
                                        }/cohortInstructor/true/${userSlug}/true/${courseSlug}`}
                                      >
                                        <strong>
                                          Step: {i + 1}.{j + 1}.
                                          {k +
                                            1 +
                                            " " +
                                            sectionWithActivity.name}
                                        </strong>
                                      </a>
                                    </Card.Title>
                                    <Card.Body>
                                      <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-6  font__bold p-1">
                                          {sectionWithActivity?.stepType?.slug
                                            .split("-")
                                            .join(" ")
                                            .toUpperCase()}
                                          ! :{" "}
                                          <span className="stepData__color">
                                            {sectionWithActivity?.points || "0"}
                                            {" POINTS AVAILABLE"}
                                          </span>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-6  font__bold p-1">
                                          <span style={{ color: "#9f1a20" }}>
                                            EARNED POINTS:
                                          </span>{" "}
                                          {sectionWithActivity?.userActivity
                                            ?.cohortUserActivity
                                            ?.userEarnedPoints || "0"}{" "}
                                          <span>
                                            ({sectionWithActivity?.grading})
                                          </span>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-6  font__bold p-1">
                                          COMPLETED:{" "}
                                          <span className="stepData__color">
                                            {sectionWithActivity?.userActivity
                                              ?.cohortUserActivity
                                              ?.stepCompletedAt || (
                                              <span style={{ color: "orange" }}>
                                                IN PROGRESS
                                              </span>
                                            )}
                                          </span>
                                        </div>
                                        <div className="col-lg-1 col-md-1 col-sm-6  font__bold p-1">
                                          <Button
                                            onClick={() => {
                                              setResetConfirmDialog({
                                                show: true,
                                                isIntro: false,
                                                stepSlug:
                                                  sectionWithActivity.slug,
                                              });

                                              // resetActivity(
                                              //   false,
                                              //   sectionWithActivity.slug
                                              // );
                                            }}
                                            title="Reset"
                                            className="btn-outline rounded-pill pr-3 pl-3"
                                            style={{
                                              backgroundColor: "#9f1a20",
                                            }}
                                          >
                                            Reset
                                          </Button>
                                        </div>
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Card.Body>
                              );
                            }
                          )}
                        </Card>
                      </Card.Body>
                    );
                  }
                )}
              </Card>
            );
          }
        )}
      </div>
    );
  };

  const Intro = () => {
    return (
      <div className="width__90">
        {courseCohortData?.cohortCourseWithCohortIntroductionSection?.map(
          (introSection, i) => {
            return (
              <Card className="p-3 mb-5 mt-5 bg__module">
                <Card.Title className="primary__color">
                  <strong>
                    {" "}
                    Introduction: {i + 1 + " " + introSection.name}
                  </strong>
                </Card.Title>
                {introSection?.cohortIntroductionSectionWithCohortIntroductionSteps?.map(
                  (introStep, j) => {
                    return (
                      <Card.Body>
                        <Card className="p-2 ">
                          <Card.Title className="primary__color">
                            <a
                              href={`/cohortIntroduction/cohortActivity/${cohortId}/introduction/${
                                i + 1
                              }/${j + 1}/${
                                introStep.slug
                              }/cohortInstructor/true/${userSlug}/true/${courseSlug}`}
                            >
                              <strong>
                                {" "}
                                Step {i + 1}.{j + 1}
                                {" " + introStep.name}
                              </strong>
                            </a>
                          </Card.Title>
                          <Card.Body>
                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-sm-6  font__bold p-1">
                                {introStep?.stepType?.slug
                                  .split("-")
                                  .join(" ")
                                  .toUpperCase()}
                                ! :{" "}
                                <span className="stepData__color">
                                  {introStep?.points || "0"}{" "}
                                  {"AVAILABLE POINTS"}
                                </span>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-6  font__bold p-1">
                                <span style={{ color: "#9f1a20" }}>
                                  EARNED POINTS:
                                </span>{" "}
                                {introStep?.userIntroductionActivity
                                  ?.cohortUserIntroductionActivity
                                  ?.userEarnedPoints || "0"}{" "}
                                <span>({introStep?.grading})</span>
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-6  font__bold p-1">
                                COMPLETED:{" "}
                                <span className="stepData__color">
                                  {introStep?.userIntroductionActivity
                                    ?.cohortUserIntroductionActivity
                                    ?.stepCompletedAt || (
                                    <span style={{ color: "orange" }}>
                                      IN PROGRESS
                                    </span>
                                  )}
                                </span>
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-6  font__bold p-1">
                                <Button
                                  onClick={() => {
                                    setResetConfirmDialog({
                                      show: true,
                                      stepSlug: introStep.slug,
                                      isIntro: true,
                                    });
                                    // resetActivity(true, introStep.slug);
                                  }}
                                  title="Reset"
                                  className="btn-outline rounded-pill pr-3 pl-3"
                                  style={{ backgroundColor: "#9f1a20" }}
                                >
                                  Reset
                                </Button>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </Card.Body>
                    );
                  }
                )}
              </Card>
            );
          }
        )}
      </div>
    );
  };

  return (
    <div className="">
      {/* user profile and progress */}
      <div className="row  bg__page fluid-container p-2">
        <div className="col-lg-6 col-md-6 col-sm-12">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-6 col-sm-6 avatar">
              <ComponentAvatar
                type={"user"}
                data={{
                  firstname:
                    otherUserData?.avatarImg?.slug === "" &&
                    otherUserData?.firstName,
                  lastname:
                    otherUserData?.avatarImg?.slug === "" &&
                    otherUserData?.lastName,

                  img: BannerImageHandlingForAllDevices(
                    otherUserData?.avatarImg?.url,
                    "user",
                    otherUserData?.avatarImg?.slug
                  ),
                }}
              />
            </div>
            <strong className="col-lg-6 col-md-6 col-sm-6 full__name">
              {" "}
              {getFullName(
                otherUserData?.firstName,
                otherUserData?.middleName,
                otherUserData?.lastName
              )}
            </strong>
          </div>

          <div className="course__progress">
            <span className="row ">Step Progress Bar</span>
            <div
              style={{ marginTop: "0.5em", maxWidth: "400px", width: "70%" }}
            >
              <ComponentProgressBar
                type={"linear"}
                data={{
                  theme:
                    userPoints?.userDashboard?.scores?.userProgress >= 100
                      ? "primary"
                      : "warning",
                  progress: userPoints?.userDashboard?.scores?.userProgress,
                }}
              />
              <strong>{userPoints?.userDashboard?.scores.userProgress}%</strong>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 text-center">
          <div className="row align-items-center place-items-center">
            <div className="col-lg-6 col-md-6 col-sm-6  text-center">
              <div className="flex__column__center">
                <div className="circle">
                  <ComponentProgressBar
                    type={"circular"}
                    data={{
                      theme:
                        (userPoints?.userScore / userPoints?.maxScore) * 100 >=
                        85
                          ? "primary"
                          : "warning",
                      value: {
                        current: userPoints.userScorePercentage,
                        total: 100,
                      },
                      showPercent: "true",
                    }}
                  />
                </div>
                <span className="font-semi-bold-TKL text-center">
                  User Score{" "}
                  <strong>
                    {userPoints.userScore}/{userPoints.maxScore}
                  </strong>
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6  text-center">
              <div className="flex__column__center">
                <div className="circle">
                  <ComponentProgressBar
                    type={"circular"}
                    data={{
                      theme:
                        userPoints?.userDashboard?.daysLeft <= 14
                          ? "danger"
                          : "blue",
                      value: {
                        current:
                          userPoints?.userDashboard?.totalDays -
                          userPoints?.userDashboard?.daysLeft,
                        total: userPoints?.userDashboard?.totalDays,
                      },
                      showDirectText: true,
                      showValue:
                        userPoints?.userDashboard?.daysLeft >= 0
                          ? userPoints?.userDashboard?.daysLeft
                          : 0,
                      showPercent: "false",
                    }}
                  />
                </div>
                <span className="font-semi-bold-TKL">Days Left</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* cohort data */}
      <div className="row fluid-container justify-content-center bg__bot">
        <Intro />
        <Modules />
      </div>
      {(!ready || isLoading) && <LogoLoader />}

      {resetConfirmDialog.show && (
        <ConfirmationPopup
          title="Alert"
          body="Reset Step?"
          primaryBtn="Ok"
          secondaryBtn="Cancel"
          handleClose={() => {
            setResetConfirmDialog({
              show: false,
              stepSlug: false,
              isIntro: false,
            });
          }}
          handleSubmit={() => {
            if (resetConfirmDialog.stepSlug)
              resetActivity(
                resetConfirmDialog.isIntro,
                resetConfirmDialog.stepSlug
              );
            setResetConfirmDialog({
              show: false,
              stepSlug: false,
              isIntro: false,
            });
          }}
          show={resetConfirmDialog.show}
        />
      )}
    </div>
  );
}

export default CoursePathReport;
