import React, { useEffect, useState } from "react";
import { Button, Container, Modal, Row, Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import "./FileStorageRoot.scss";
import UploadFileToS3 from "./UploadFileToS3";
import { getAuthorDetails } from "actions/CreateCourse/CreatecourseActions";
import SelectContentDeveloper from "./SelectContentDeveloper";
import {
  createFolder,
  listFolders,
} from "actions/FileStorageActions/FileStorageAction";
import FileStorageBreadCrumb from "./FileStorageBreadCrumb";
import Folder from "./Folder";
import { debounce } from "components/utility/ReUsableFunctions";
import File from "./File";
import TKLModal from "shared-components/model/TKLModal";
import {
  CONTENT_ADMIN,
  SYSTEM_ADMIN,
  TKL_FACILITATOR,
  CONTENT_AUTHOR,
} from "constants.js/RoleNames";
import CreateNewFolder from "./CreateNewFolder";

function FileStorageRoot() {
  const [eventKey, setEventKey] = useState("");
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.users.userData);

  const { apiSuccessMessage, apiErrorMessage } = useSelector(
    (state) => state.coursepath
  );

  const { folders, currentFolderPath, fileContents } = useSelector(
    (state) => state.filestorage
  );
  const { modalLoader } = useSelector((state) => state.tkladmin);

  const [showSelectContentDeveloper, setShowSelectContentDeveloper] =
    useState(false);

  useEffect(() => {
    console.log("SHOW", folders);
    if (!folders.length) {
      dispatch(listFolders());
      dispatch(getAuthorDetails());
    }
  }, []);

  const createCommonFolder = debounce(() => {
    dispatch(
      createFolder({
        name: "Common Folder",
        // parentFolderSlug: "common-folder",
      })
    );
  }, 800);

  return (
    <>
      <Container
        fluid
        style={{
          height: "100%",
        }}
        className="bg-alice-blue container_padding p-5 resize-for-small-devices"
      >
        <Container className="mb-3   rounded-box card-box bg-white">
          <Row className="p-2 FileStorageRoot__toolbar">
            {userData?.roles?.find((role) => {
              return role === SYSTEM_ADMIN || role === CONTENT_ADMIN;
            }) &&
              !currentFolderPath[currentFolderPath.length - 1].slug && (
                <div className="d-flex flex-column justify-content-center align-items-center p-1 m-2">
                  <span
                    class="material-icons FileStorageRoot__toolbar__folder__icon"
                    title="Create Common Folder"
                    onClick={createCommonFolder}
                  >
                    create_new_folder
                  </span>
                  <label>Create Common Folder</label>
                </div>
              )}

            {userData?.roles?.find((role) => {
              return role === SYSTEM_ADMIN || role === CONTENT_ADMIN;
            }) &&
              !currentFolderPath[currentFolderPath.length - 1].slug && (
                <div className="d-flex flex-column justify-content-center align-items-center p-1 m-2">
                  <span
                    class="material-icons FileStorageRoot__toolbar__folder__icon"
                    onClick={() => {
                      setShowSelectContentDeveloper(true);
                    }}
                  >
                    create_new_folder
                  </span>

                  <SelectContentDeveloper
                    handleClose={() => {
                      setShowSelectContentDeveloper(false);
                    }}
                    handleSubmit={() => {
                      setShowSelectContentDeveloper(false);
                    }}
                    show={showSelectContentDeveloper}
                  />

                  <label>Create Folder For User</label>
                </div>
              )}

            {currentFolderPath[currentFolderPath.length - 1].slug &&
              userData?.roles?.find((role) => {
                return (
                  role === SYSTEM_ADMIN ||
                  role === CONTENT_ADMIN ||
                  role === TKL_FACILITATOR ||
                  role === CONTENT_AUTHOR
                );
              }) && (
                <CreateNewFolder
                  parentFolderSlug={
                    currentFolderPath[currentFolderPath.length - 1].slug
                  }
                />
              )}

            {currentFolderPath[currentFolderPath.length - 1].name != "/" && (
              <UploadFileToS3 />
            )}
          </Row>
          <FileStorageBreadCrumb currentFolderPath={currentFolderPath} />

          <Row
            className={`${
              modalLoader
                ? "FileStorageRoot__Activity__container__center"
                : "FileStorageRoot__Activity__container"
            }`}
          >
            {modalLoader ? (
              <Spinner animation="border" variant="primary" size="lr" />
            ) : (
              <div>
                <div className="FileStorageRoot__Activity__container__content">
                  {folders.map((item, index) => {
                    return <>{item && <Folder item={item} />}</>;
                  })}
                  {fileContents?.map((item, index) => {
                    return <>{item && <File item={item} />}</>;
                  })}
                </div>
                {/* <div className="FileStorageRoot__Activity__File__root">
                  {fileContents?.map((item, index) => {
                    return <>{item && <File item={item} />}</>;
                  })}
                </div> */}
              </div>
            )}
          </Row>
        </Container>

        {apiErrorMessage && (
          <TKLModal
            showDialog={true}
            msgType={"warning"}
            message={apiErrorMessage}
            type="error"
          />
        )}

        {apiSuccessMessage && (
          <TKLModal
            showDialog={true}
            msgType={"success"}
            message={apiSuccessMessage}
            // redirectToPath={props.redirectToPath}
          />
        )}
      </Container>
    </>
  );
}

export default FileStorageRoot;
