import React, { useState, useEffect, useRef } from "react";
import {
  Col,
  Row,
  Container,
  Nav,
  ListGroup,
  Button,
  Form,
  InputGroup,
  FormControl,
  Pagination,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import {
  getCountries,
  getStates,
} from "../../../actions/CohortsActions/cohortactions";
import {
  getFollowedUsers,
  getFollowingUsers,
  getProfessionlColleagues,
  followUnfollowUser,
  blockUser,
  groupInvitation,
  getMemberInvitations,
  getMyConnections,
  getAddMoreContacts,
  getPendingInvitations,
  getGroupInviteUserList,
} from "../../../actions/CommunityActions/CommunityAction";
import { URL } from "../../../api/tklEnvironments";
import ComponentAvatar from "../../../shared-components/avatar";
import { TKLModal } from "../../../shared-components/model/TKLModal";
import ComponentPageBanner from "../../../shared-components/page-banner";
import { BiSearch } from "react-icons/bi";
import "./ProfessionalColleagues.scss";
import { withRouter, useParams } from "react-router-dom";
import { BannerImageHandlingForAllDevices } from "../../utility/ReUsableFunctions";
import ComponentCtaBtn from "shared-components/cta-btn";

function ListOfColleages(props) {
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const iconStyles = { marginTop: "0.25em" };
  const [eventKey, setEventKey] = useState("myConnections");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [searchName, setSearchName] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [isFollow, setisFollow] = useState(true);
  const [listOfInvites, setListOfInvites] = useState([]);

  const [active, setActive] = useState(1);
  const [items, setItems] = useState([]);

  const RefprofessionalCOlleaguesWrapper = useRef(null);
  const { allCountries, allStates } = useSelector((state) => state.cohorts);

  const userData = useSelector((state) => state.users.userData);
  const {
    groupInviteUserList,
    addMoreContacts,
    memberInvitations,
    pendingInvitations,
    isColleaguesReload,
  } = useSelector((state) => state.community);
  const { apiSuccessMessage, apiErrorMessage } = useSelector(
    (state) => state.coursepath
  );
  const sendInvitationToJoinGroup = () => {};

  const postedby = {
    name: "Srikanth" + " " + "Kanakaboina",
    firstname: "Srikanth",
    lastname: "Kanakaboina",
    designation: "Teacher",
    date: new Date(),
    district: "Florida",
    state: "Florida",
    country: "United States",
  };
  //Initial API CALL

  React.useEffect(() => {
    dispatch(getCountries());
  }, []);

  React.useEffect(() => {
    dispatch(getStates(selectedCountry));
  }, [selectedCountry]);

  useEffect(() => {
    if (eventKey) {
      setActive(1);
      handleAPIRequest(searchName, 1);
    }
  }, [eventKey]);

  useEffect(() => {
    getPaginationData(eventKey, null);
  }, [active]);

  const getPaginationData = (eventKeyParam, lastPage, currentPage) => {
    let paginationItems = [];
    console.log(getLastPage(eventKeyParam));
    for (
      let number = 1;
      number <= (lastPage || getLastPage(eventKeyParam));
      number++
    ) {
      paginationItems.push(
        <Pagination.Item
          key={number}
          active={number === (currentPage || active)}
          onClick={() => {
            setActive(number);
            handleAPIRequest(searchName, number);
          }}
        >
          {number}
        </Pagination.Item>
      );
    }
    setItems(paginationItems);
  };

  // React.useEffect(() => {
  //   if (selectedState) {
  //     setActive(1);
  //     handleAPIRequest(searchName, 1);
  //   }
  // }, [JSON.stringify(selectedState)]);

  // React.useEffect(() => {
  //   if (selectedCountry) {
  //     setActive(1);
  //     handleAPIRequest(searchName, 1);
  //   }
  // }, [JSON.stringify(selectedCountry)]);

  const searchClickHandler = () => {
    setActive(1);
    handleAPIRequest(searchName, 1);
  };

  React.useEffect(() => {
    if (isColleaguesReload) {
      handleAPIRequest(searchName, active);
    }
  }, [isColleaguesReload]);

  // React.useEffect(() => {
  //   dispatch(getMemberInvitations());
  // }, []);

  const getLastPage = (eventKeyParam) => {
    if (eventKeyParam === "myConnections") {
      return groupInviteUserList?.meta?.last_page;
    } else if (eventKeyParam === "addMoreContacts") {
      return addMoreContacts?.meta?.last_page;
    } else if (eventKeyParam === "memberInvitations") {
      return memberInvitations?.meta?.last_page;
    } else if (eventKeyParam === "pendingInvitations") {
      return pendingInvitations?.meta?.last_page;
    }
  };

  const handleAPIRequest = async (name, pageNumber) => {
    let lastPage = null;
    // ["colleagues", "blockedUsers"].includes(eventKey)
    setActive(pageNumber || active);
    //console.log("....handlerequest 2a", pageNumber, active);
    if (eventKey === "myConnections") {
      lastPage = await dispatch(
        getGroupInviteUserList(name, groupId, pageNumber)
      );
    } else if (eventKey === "addMoreContacts") {
      lastPage = await dispatch(getAddMoreContacts(name, pageNumber));
    } else if (eventKey === "memberInvitations") {
      lastPage = await dispatch(getMemberInvitations(name, pageNumber));
    } else if (eventKey === "pendingInvitations") {
      lastPage = await dispatch(getPendingInvitations(name, pageNumber));
    }
    setTimeout(() => {
      getPaginationData(eventKey, lastPage, pageNumber);
    }, 0);
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const searchInputHandler = (e) => {
    setSearchName(e.target.value);
  };
  useEffect(() => {
    if (searchName == "") {
      searchClickHandler();
    }
  }, [searchName]);

  const handleCheckboxChange = (event) => {
    let tempSelectedListOfGroups = [...listOfInvites];
    const target = event?.target;
    const checked = target?.checked;
    const name = target?.name;
    if (checked) {
      if (!tempSelectedListOfGroups.includes(name)) {
        tempSelectedListOfGroups.push(name);
      }
    } else {
      if (tempSelectedListOfGroups.includes(name)) {
        tempSelectedListOfGroups = tempSelectedListOfGroups.filter(
          (object) => name != object
        );
      }
    }
    //console.log("tempSelectedListOfGroups : ", tempSelectedListOfGroups);
    setListOfInvites(tempSelectedListOfGroups);
  };

  const getListItem = () => {
    let data = [];
    console.log("eventKey", eventKey);
    if (eventKey === "myConnections") {
      data = groupInviteUserList?.data;
    } else if (eventKey === "addMoreContacts") {
      data = addMoreContacts?.data;
    } else if (eventKey === "memberInvitations") {
      data = memberInvitations?.data;
    } else if (eventKey === "pendingInvitations") {
      data = pendingInvitations?.data;
    }
    if (!data?.length) {
      //we are making the message Text centered
      if (
        RefprofessionalCOlleaguesWrapper &&
        RefprofessionalCOlleaguesWrapper.current
      ) {
        RefprofessionalCOlleaguesWrapper.current.style.display = "flex";
        RefprofessionalCOlleaguesWrapper.current.style.justifyContent =
          "center";
      }
      return (
        <>
          {eventKey === "myConnections" && (
            <Row
              style={{}}
              className="d-flex flex-row align-items-center justify-content-center"
            >
              <div className="p-2">
                <strong>Currently, you do not have any connections. </strong>
                <br />
                <strong>
                  Make some connections now by clicking on the “Add More
                  Contacts” tab in{" "}
                  <a href="/colleagues">My Professional Colleagues</a>.
                </strong>
              </div>
            </Row>
          )}
        </>
      );
    } else {
      if (
        RefprofessionalCOlleaguesWrapper &&
        RefprofessionalCOlleaguesWrapper.current
      ) {
        RefprofessionalCOlleaguesWrapper.current.style.display = "grid";
        RefprofessionalCOlleaguesWrapper.current.style.justifyContent =
          "center";
      }

      return (
        <>
          {" "}
          {data?.map((colleage) => {
            return (
              <div
                className="professional_colleague_tile"
                key={colleage?.userSlug}
              >
                <div className="media professional_colleague_tile_details">
                  <div
                    className="d-inline-block mr-3"
                    style={{ width: "5rem" }}
                  >
                    <ComponentAvatar
                      type="user"
                      data={{
                        firstname: postedby.firstname,
                        lastname: postedby.lastname,
                        img: colleage?.avatarImage?.slug
                          ? {
                              md: `${URL}/stream-media/${colleage?.avatarImage?.slug}`,
                              md2: `${URL}/stream-media/${colleage?.avatarImage?.slug}`,
                              xs: `${URL}/stream-media/${colleage?.avatarImage?.slug}`,
                              xs2: `${URL}/stream-media/${colleage?.avatarImage?.slug}`,
                            }
                          : BannerImageHandlingForAllDevices(
                              null,
                              "user",
                              null
                            ),
                      }}
                    />
                  </div>

                  <div className="d-flex flex-column  position-relative text-primary font-weight-600 align-items-start">
                    {eventKey === "memberInvitations" && (
                      <p
                        style={{
                          color: "black",
                          fontSize: "14px",
                          paddingBottom: "0px",
                          marginBottom: "0px",
                        }}
                      >
                        Invitation to Connect
                      </p>
                    )}
                    <span
                      // to={`/OtherProfile/${colleage?.userSlug}`}
                      // target="_blank"
                      // rel="noreferrer"
                      className="professional_colleague_tile_title"
                    >
                      {colleage.firstName} {colleage.lastName}
                    </span>

                    {/* {postedby.designation ? (
                  <>
                    <small className="text-black-50">
                      <strong>
                        {colleage?.positions?.map((p) => p?.title).join(",")}
                      </strong>
                      <strong>
                        {colleage?.positions?.map((p) => p?.title).join(",")
                          ? ", "
                          : " "}
                      </strong>
                      <strong>{colleage?.userDetails?.district}</strong>
                      <strong>
                        {colleage?.userDetails?.district ? ", " : " "}
                      </strong>
                      <strong>{colleage?.userDetails?.state?.name}</strong>
                      <strong>
                        {colleage?.userDetails?.state?.name ? ", " : " "}
                      </strong>
                      <strong>{colleage?.userDetails?.country?.name}</strong>
                    </small>
                  </>
                ) : (
                  <></>
                )} */}
                    <a
                      href={`/OtherProfile/${colleage?.userSlug}`}
                      target="_blank"
                      rel="noreferrer"
                      className="mt-2 mb-2"
                      style={{
                        color: "royalblue",
                        fontSize: "14px",
                      }}
                    >
                      View Profile
                    </a>
                  </div>
                </div>
                {/* above is the avatar, position and location */}
                <div className="d-flex flex-wrap align-items-center justify-content-center w-100">
                  {eventKey == "myConnections" && (
                    <div className="d-flex flex-wrap align-items-center justify-content-center w-100">
                      {!colleage?.isGroupMember ? (
                        <Button
                          className="m-2 pl-2 pr-2 add_colleague_btn"
                          size="sm"
                          onClick={() => {
                            dispatch(
                              groupInvitation(
                                {
                                  groupSlug: groupId,
                                  userSlugs: [colleage?.userSlug],
                                },
                                true
                              )
                            );
                            // props?.handleCloseGroup();
                          }}
                          title="Add Colleague to Group"
                        >
                          {"Add"}
                        </Button>
                      ) : (
                        <Button
                          className=" m-2 pl-2 pr-2 remove_colleague_btn"
                          size="sm"
                          onClick={() => {
                            dispatch(
                              groupInvitation(
                                {
                                  groupSlug: groupId,
                                  userSlugs: [colleage?.userSlug],
                                },
                                false
                              )
                            );
                            // props?.handleCloseGroup();
                          }}
                          title="Remove Colleague from Group"
                        >
                          {"Remove"}
                        </Button>
                      )}

                      {/* <Form.Check
                        type="checkbox"
                        className="custom-checkbox_addResource"
                        label=""
                        name={colleage?.userSlug}
                        value={colleage?.userSlug}
                        checked={listOfInvites.includes(colleage?.userSlug)}
                        onChange={(e) => handleCheckboxChange(e)}
                      /> */}
                    </div>
                  )}
                  {eventKey == "addMoreContacts" && (
                    <Button
                      className="btn m-2 pl-2 pr-2  send_invitation_btn"
                      size="sm"
                      onClick={() => {
                        dispatch(sendConnectionRequest(colleage?.userSlug));
                      }}
                    >
                      {"Send Invitation"}
                    </Button>
                  )}
                  {eventKey == "memberInvitations" && (
                    <div>
                      {" "}
                      <Button
                        className="btn m-2 pl-2 pr-2  accept_invitation_btn"
                        size="sm"
                        onClick={() => {
                          dispatch(acceptConnectionRequest(colleage?.userSlug));
                        }}
                      >
                        {"Accept"}
                      </Button>
                      <Button
                        className="btn m-2 pl-2 pr-2  decline_invitation_btn"
                        size="sm"
                        onClick={() => {
                          dispatch(
                            declineConnectionRequest(colleage?.userSlug)
                          );
                        }}
                      >
                        {"Decline"}
                      </Button>
                    </div>
                  )}{" "}
                  {eventKey == "pendingInvitations" && (
                    <Button
                      className="btn m-2 pl-2 pr-2  pending_invitations_btn "
                      disabled
                      size="sm"
                    >
                      {"Pending"}
                    </Button>
                  )}
                </div>
              </div>
            );
          })}
        </>
      );
    }
  };
  return (
    <div className="bg-alice-blue">
      <div className="profile-creation-page-styles_course_path px-2 px-md-4 ml-md-5 pt-4">
        {/* <Container fluid className="">
          <Row>
            <Col xs="12">
              <ComponentPageBanner
                iseditable={false}
                data={{
                  type: "image",
                }}
                bannerImage={
                  "https://www.detroitlabs.com/wp-content/uploads/2018/02/alfons-morales-YLSwjSy7stw-unsplash.jpg"
                }
              />
            </Col>
          </Row>
        </Container> */}
        <div
          className="card-box bg-white"
          style={{ position: "relative", zIndex: "5", borderRadius: "1rem" }}
        >
          <Container fluid className="pt-5 pb-2 py-2 px-2">
            {/* <Row className="justify-content-center">
              <Col xs="12">
                <div className="font-weight-600 d-flex justify-content-space-between">
                  <h2>My Professional Colleagues</h2>
                  <Button
                    size="sm"
                    className="btn btn-primary mr-2 mb-2"
                    onClick={sendInvitePopUp}
                  >
                    Send Invite
                  </Button>
                </div>
              </Col>
            </Row> */}
            <Row className="justify-content-center py-2 bg-light">
              <Col xs="12">
                <Nav
                  variant="pills"
                  defaultActiveKey="myConnections"
                  onSelect={(selectedKey) => {
                    setEventKey(selectedKey);
                    setSearchName("");
                  }}
                >
                  {/* <Nav.Item>
                    <Nav.Link
                      eventKey="myConnections"
                      style={{ cursor: "default" }}
                    >
                      My Connections
                    </Nav.Link>
                  </Nav.Item> */}
                  {/* <Nav.Item>
                    <Nav.Link eventKey="addMoreContacts">
                      Add More Contacts
                    </Nav.Link>
                  </Nav.Item> */}

                  {/* <Nav.Item className="memberInvitationTabHeader">
                    <Nav.Link eventKey="memberInvitations">
                      Member Invitations
                      <p className="memberInvitationTabNotification">
                        {memberInvitations?.meta?.total || 0}
                      </p>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey="pendingInvitations">
                      Pending Invitations
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
                <h2 className="text-center">Group Members</h2>
                <ComponentCtaBtn
                  onClick={() => {
                    window.open("/community", "_self");
                  }}
                  className="m-1 m-md-0 mr-md-2 pr-4 btn-outline btn-green"
                  data={{
                    tag: "button",
                    type: "button",
                    ctastyle: "green",
                    text: "Return",
                    title: "Return to Community Page",
                    primaryicon: "chevron_left",
                  }}
                />
                {/* <Button
                  className={`rounded-pill m-2 pl-2 pr-2  float-right ${
                    listOfInvites.length
                      ? "send_invitation_btn btn"
                      : "btn-secondary"
                  }`}
                  size="sm"
                  disabled={!listOfInvites.length}
                  onClick={() => {
                    console.log(listOfInvites);
                    dispatch(
                      groupInvitation({
                        groupSlug: groupId,
                        userSlugs: listOfInvites,
                      })
                    );
                    // props?.handleCloseGroup();
                  }}
                  title="Send Invitation to join Group"
                >
                  {listOfInvites.length
                    ? `Send Invitation to ${listOfInvites.length} Colleagues`
                    : "Select Colleagues to Invite"}
                </Button> */}
              </Col>
            </Row>
            <Row className="justify-content-center pt-3 px-5 bg-wjite text-primary font-weight-500">
              <Col>Search by Last Name or First Name</Col>
              {/* <Col>Search By Country</Col>
                <Col>Search By State</Col> */}
            </Row>
            <Row className="justify-content-center pt-2 px-5 bg-wjite text-primary font-weight-500">
              <Col>
                {" "}
                <InputGroup className="mb-3">
                  <FormControl
                    onChange={searchInputHandler}
                    onKeyUp={(e) => {
                      if (e.key === "Enter") {
                        searchClickHandler();
                      }
                    }}
                    placeholder="Search by Last Name or First Name "
                    aria-describedby="basic-addon2"
                    className="border-left"
                    value={searchName}
                  />
                  <Button
                    as={InputGroup.Append}
                    className="border-right"
                    onClick={() => searchClickHandler()}
                  >
                    <BiSearch style={iconStyles} />
                  </Button>
                </InputGroup>
              </Col>
              {/* <Col>
                  <Form.Control
                    as="select"
                    className="rounded-pill"
                    onChange={(e) => handleCountryChange(e)}
                  >
                    <option value="">All Countries</option>
                    {allCountries.map((value) => (
                      <option
                        name={value.name}
                        value={value.slug}
                        key={value?.slug}
                      >
                        {value.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
                <Col>
                  <Form.Control
                    as="select"
                    className="rounded-pill"
                    onChange={(e) => handleStateChange(e)}
                    disabled={selectedCountry == ""}
                  >
                    <option value="">All States</option>
                    {allStates.map((value) => (
                      <option name={value.name} value={value.slug}>
                        {value.name}
                      </option>
                    ))}
                  </Form.Control>
                </Col> */}
            </Row>
          </Container>
          <hr />

          <Container className="py-3">
            <>
              <div
                className="professional_colleagues_list_wrapper"
                ref={RefprofessionalCOlleaguesWrapper}
              >
                {getListItem()}
              </div>
              <br />
              {eventKey == "addMoreContacts" && (
                <div className="m-2">
                  <article>
                    <header>
                      <strong>How to add contacts?</strong>
                    </header>
                    <section>
                      <ul>
                        <li>
                          Use search feature to search for your colleagues by
                          name.
                        </li>
                        <li>
                          <strong>Note: </strong>Automated potential connections
                          are based on the state that you have identified in
                          your profile. Please update your profile with the
                          state information to view potential connections.
                        </li>
                      </ul>
                    </section>
                  </article>
                </div>
              )}
            </>
          </Container>
        </div>

        <Row className="justify-content-center content-center-master-data">
          {
            <Container className="p-2 ml-5 mr-5 m-2 pagination_items width-auto">
              <div>
                <Pagination>{items}</Pagination>
              </div>
            </Container>
          }
        </Row>
      </div>

      {apiSuccessMessage && (
        <TKLModal
          showDialog={true}
          msgType={"success"}
          message={apiSuccessMessage}
          primaryBtnColor={"#0088CC"}
        />
      )}

      {apiErrorMessage && (
        <TKLModal
          showDialog={true}
          msgType={"warning"}
          message={apiErrorMessage}
          type="error"
        />
      )}
    </div>
  );
}

export default ListOfColleages;
