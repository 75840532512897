import React, { useEffect, useRef, useMemo, useState } from "react";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Tabs, Tab, Row, Col, Nav, Spinner } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import {
  getCompletedCourseData,
  downloadCertificate,
  printTranscripts,
} from "../../../actions/DashboardActions/PaidUserActions";
import { useSelector, useDispatch } from "react-redux";
import { BannerImageHandlingForAllDevices, userRoles } from "../../utility/ReUsableFunctions";
import moment from "moment";
import "../../../components/MasterData/masterData.scss";
import Dropdown from "react-dropdown";
import {
  COMPREHENSIVE_PROGRAM_MEMBER,
  COMPREHENSIVE_PROGRAM_MEMBER_NAME,
  CONTENT_ADMIN,
  CONTENT_AUTHOR,
  DISTRICT_FACILITATOR,
  GPLC_PROGRAM_MEMBER,
  GPLC_PROGRAM_MEMBER_NAME,
  MICRO_PROGRAM_MEMBER,
  KIDVISION_PROGRAM_MEMBER,
  MICRO_PROGRAM_MEMBER_NAME,
  SCHOOL_FACILITATOR,
  SYSTEM_ADMIN,
  TKL_ACCOUNT_MANAGER,
  TKL_FACILITATOR,
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
} from "../../../../src/constants.js/RoleNames";

export default function TileB({ title, isTranscripts }) {
  const userData = useSelector((state) => state.users.userData);
  const dispatch = useDispatch();
  const { completedCourses } = useSelector((state) => state.dashboards);
  const [sortBy, setSortBy] = useState("Recent Activity");
  const compArr = useRef([]);
  const gplcArr = useRef([]);
  const microArr = useRef([]);
  const kidVisionArr = useRef([]);
  const comprehensiveRef = useRef();
  const [loggeduserRole, setUserRole] = useState(""); 
  const [selectedNav, setSelectedNav] = useState();
  const { isLoading } = useSelector((state) => state.dashboards);
  const [defaultKey, setDefaultKey] = useState();
  useMemo(() => {
    compArr.current = (completedCourses || []).filter(
      (item) => item?.program?.slug === "Comprehensive Course Program"
    );
    gplcArr.current = (completedCourses || []).filter(
      (item) => item?.program?.slug === "Guided Plc Program"
    );
    microArr.current = (completedCourses || []).filter(
      (item) => item?.program?.slug === "Micro Course Program"
    );
    kidVisionArr.current = (completedCourses || []).filter(
      (item) => item?.program?.slug === "KidVision Program"
    );
  }, [completedCourses]);
  
  useEffect(()=>{
    setDefaultKey(selectedNav);
  },[selectedNav])
  useEffect(() => {
      isOnlyNormalUser(userData?.roles);
      if(!defaultKey){
        setTimeout(()=>{
        comprehensiveRef?.current?.click();
      },300)
      }
  }, []);
  useEffect(()=>{
    setSelectedNav(defaultKey)
  },[defaultKey])
  useEffect(() => {
    dispatch(
      getCompletedCourseData(selectedNav, getSortByAttribute(sortBy))
    );
  }, [sortBy, selectedNav]);

  const isOnlyNormalUser = (roles) => {
    if(roles?.length === 2){
      if(roles.includes(userRoles?.KIDVISION_PROGRAM_MEMBER)){
        setUserRole("kidvision");
        setDefaultKey("kidvision");
      }else if(roles.includes(userRoles?.GPLC_PROGRAM_MEMBER_PAID_USER)){
        setUserRole("gplc");
        setDefaultKey("gplc");
      }else if (roles.includes(userRoles?.MICRO_COURSE_PROGRAM_MEMBER_PAID_USER)){
        setUserRole("micro");
        setDefaultKey("micro");
      }else if(roles.includes(userRoles?.COMPREHENSIVE_PROGRAM_MEMBER_PAID_USER) && roles.includes(!userRoles?.SYSTEM_ADMIN)){
        setUserRole("comprehensive");
        setDefaultKey("comprehensive");
      }
    }
  }
  const loggedInUserRoles = useSelector((state) => state?.users?.userData?.roles);

  const navLinkKidvision = useRef();
  const navLinkGplc = useRef();
  const navLinkMicro = useRef();
  const navLinkComprehensive = useRef();
  
  useEffect(() => {
    if (loggedInUserRoles.includes(userRoles?.COMPREHENSIVE_PROGRAM_MEMBER_PAID_USER)) {
      setTimeout(() => {
          navLinkComprehensive?.current?.click()
      }, 2000)
    } else if (loggedInUserRoles.includes(userRoles?.GPLC_PROGRAM_MEMBER_PAID_USER)) {
      setTimeout(() => {
        navLinkGplc?.current?.click()
      }, 2000)
    } else if (loggedInUserRoles.includes(userRoles?.MICRO_COURSE_PROGRAM_MEMBER_PAID_USER)) {
      setTimeout(() => {
        navLinkMicro?.current?.click()
      }, 2000)
    }else if (loggedInUserRoles.includes(userRoles?.KIDVISION_PROGRAM_MEMBER)) {
      setTimeout(() => {
        navLinkKidvision?.current?.click()
      }, 2000)}
  }, [loggedInUserRoles]);

  const getSortByAttribute = (val) => {
    if (val === "Recent Activity") {
      return "completedAt";
    } else if (val === "First Name") {
      return "firstName";
    } else if (val === "Last Name") {
      return "lastName";
    } else if (val === "Course Name") {
      return "courseName";
    }
  };

  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "comprehensive") {
      thisArr = compArr?.current || []; //[0].data || [];
      char = "comp";
    }
    if (str === "gplc") {
      thisArr = gplcArr?.current || []; //[0].data || [];
      char = "gplc";
    }
    if (str === "micro") {
      thisArr = microArr?.current || []; //[0].data || [];
      char = "micro";
    }
    if (str === "kidvision") {
      thisArr = kidVisionArr?.current || []; //[0].data || [];
      char = "kidvision";
    }
    return thisArr?.length === 0 ? (
      <p className="item-not-selected">
        No {isTranscripts ? "Transcripts" : "Participant Guides"} Available
      </p>
    ) : (
      thisArr.map((item, index) => (
        <React.Fragment key={`t_b_${char}_${index}`}>
          <div className="dash-row-type-2 container rounded mb-2">
            <div className="row justify-content-space-between">
              {/* ${
                  isTranscripts ? "col-md-8" : "col-md-8"
                } */}
              <div
                className={`t-row-name col-12 position-relative pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center`}
              >
                <div className="t-avtr-wrap position-absolute">
                  <a href={item.link}>
                    <ComponentAvatar
                      type="course"
                      data={{
                        img: BannerImageHandlingForAllDevices(
                          item?.media,
                          "course",
                          item?.media
                        ),
                      }}
                    />
                  </a>
                </div>
                <div>
                  <span href={"#"} className="t-row-name-link text-primary">
                    {item?.courseName}
                  </span>
                </div>
              </div>
              {isTranscripts && (
                <div className="my_assigned_courses_style ml-2">
                  <small className="text-muted">
                    <b>Completed :</b>{" "}
                    {moment(item?.completedAt).format("MM-DD-YYYY")}
                  </small>
                </div>
              )}
              <div className="my_assigned_courses_style">
                <small
                  className={`text-muted col-12 ${
                    isTranscripts ? "col-md-3 pt-2" : "col-md-4"
                  }`}
                >
                  <b>Status:</b>{" "}
                  <span
                    className="font-semi-bold-TKL"
                    style={{ fontSize: "14px" }}
                  >
                    {item?.resultStatus}
                  </span>
                </small>

                {isTranscripts ? (
                  <>
                    {item?.secondaryCertificateAvailable ? (
                      <div className="col-12 col-md-5 pt-2 d-flex flex-column justify-content-start align-items-center">
                        {/* <small
                          className="text-black-50"
                          style={{ marginLeft: "0.6em" }}
                        >
                          <b style={{ fontSize: "14px" }}>
                            Secondary Certificate
                          </b>
                        </small> */}
                        <ComponentCtaBtn
                          data={{
                            tag: "button",
                            // href: "#",
                            ctastyle: "info",
                            text: "Secondary Certificate",
                            title: "Secondary Certificate",
                            size: "sm",
                            isoutline: "true",
                            primaryicon: "get_app",
                          }}
                          onClick={() => {
                            dispatch(
                              downloadCertificate(
                                item?.userSlug,
                                item?.cohortDisplayId,
                                0
                              )
                            );
                          }}
                        />
                      </div>
                    ) : (
                      <div className="col-12 col-md-5 pt-2 d-flex flex-column justify-content-start align-items-center"></div>
                    )}
                    <div className="col-12 col-md-4 pt-2 pl-0 pr-0 d-flex flex-column justify-content-start align-items-center">
                      {/* <small
                        className="text-black-50"
                        style={{ marginLeft: "0.5em" }}
                      >
                        <b style={{ fontSize: "14px" }}>Primary Certificate</b>
                      </small> */}
                      <ComponentCtaBtn
                        data={{
                          tag: "button",
                          // href: "#",
                          ctastyle: "info",
                          text: "Primary Certificate",
                          title: "Primary Certificate",
                          size: "sm",
                          isoutline: "true",
                          primaryicon: "get_app",
                        }}
                        onClick={() => {
                          dispatch(
                            downloadCertificate(
                              item?.userSlug,
                              item?.cohortDisplayId,
                              1
                            )
                          );
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {/* d-flex flex-column justify-content-start align-items-center */}
                    {/* <div className="d-none p-2">
                      <small
                        className="text-black-50"
                        style={{ marginLeft: "0.6em" }}
                      >
                        <b style={{ fontSize: "14px" }}>Print Certificate </b>
                      </small>
                      <ComponentCtaBtn
                        data={{
                          tag: "button",
                          // href: "#",
                          ctastyle: "primary",
                          text: "Print Certificate",
                          title: "Print Certificate",
                          size: "sm",
                        }}
                        onClick={() => {
                          dispatch(
                            downloadCertificate(
                              item?.userSlug,
                              item?.cohortDisplayId,
                              1,
                              null,
                              true
                            )
                          );
                        }}
                      />
                    </div> */}
                    <div className="col-12 col-md-8 pr-2 pl-2 d-flex justify-content-end align-items-center">
                      <ComponentCtaBtn
                        data={{
                          tag: "button",
                          // href: "#",
                          ctastyle: "info",
                          text: "Participant Guide",
                          title: "Participant Guide",
                          size: "sm",
                          isoutline: "true",
                          primaryicon: "get_app",
                        }}
                        onClick={() => {
                          dispatch(
                            downloadCertificate(
                              item?.userSlug,
                              item?.cohortDisplayId,
                              0,
                              !isTranscripts
                            )
                          );
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <hr />
        </React.Fragment>
      ))
    );
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-12 justify-content-space-between d-flex">
          <div className="tkl_transcripts">
            <div
              className={`dash-t-move position-absolute ${
                !isTranscripts && "top-0"
              } left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center`}
              style={{ top: "0.5em" }}
            >
              <i className="material-icons">open_with</i>
            </div>
            <h2
              className="h4 text-uppercase"
              style={{
                marginLeft: "2em",
                position: isTranscripts && "relative",
                top: isTranscripts && "0.5em",
              }}
            >
              <strong>{title}</strong>
            </h2>
          </div>
          {isTranscripts && (
            <ComponentCtaBtn
              className="mt-2"
              onClick={() => {
                dispatch(printTranscripts());
              }}
              data={{
                tag: "button",
                type: "submit",
                ctastyle: "info",
                text: "Print List",
                title: "Print List",
                size: "sm",
                primaryicon: "print",
              }}
            />
          )}
        </div>
        {/* <div className="dash-t-h-right py-2 px-3 flex-md-grow-1 d-none d-md-flex justify-content-md-end align-items-md-center">
          <ComponentCtaBtn
            data={{
              tag: "button",
              type: "submit",
              ctastyle: "info",
              text: "Print List",
              title: "Print List",
              size: "sm",
              primaryicon: "print",
            }}
          />
        </div> */}
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container
          id="mapl_tabs"
          defaultActiveKey={defaultKey === "comprehensive" ? defaultKey : undefined}
            activeKey={defaultKey !== "comprehensive" ? defaultKey : undefined}
        >
          <Row>
            <Col xs="12" className="mb-3">
              <Nav
                variant="pills"
                className="custom-tabs-link flex-row tile_p_nav"
              >
                <div className="tile_p_nav_group">
                  {
                  // loggeduserRole ?
                  ((loggedInUserRoles.includes(CONTENT_ADMIN)) ||
                    (loggedInUserRoles.includes(CONTENT_AUTHOR)) ||
                    (loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER)) ||
                    (loggedInUserRoles.includes(SYSTEM_ADMIN)) ||
                    (loggedInUserRoles.includes(TKL_FACILITATOR))) ?
                    
                    <>
                    <Nav.Item>
                    <Nav.Link
                      ref={comprehensiveRef}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="comprehensive"
                      onClick={() => {
                        setSelectedNav("comprehensive");
                      }}
                    >
                      <strong>Comprehensive</strong>
                    </Nav.Link>
                  </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="gplc"
                        onClick={() => {
                          setSelectedNav("gplc");
                        }}
                      >
                        <strong>GPLC</strong>
                      </Nav.Link>
                    </Nav.Item>
                  
                    <Nav.Item>
                      <Nav.Link
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="micro"
                        onClick={() => {
                          setSelectedNav("micro");
                        }}
                      >
                        <strong>Micro Courses</strong>
                      </Nav.Link>
                    </Nav.Item>
                  
                    <Nav.Item>
                      <Nav.Link
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="kidvision"
                        onClick={() => {
                          setSelectedNav("kidvision");
                        }}
                      >
                        <strong>KidVision</strong>
                      </Nav.Link>
                    </Nav.Item>
                    </>
                    :
                    <>
                    { ((loggedInUserRoles.includes(COMPREHENSIVE_PROGRAM_MEMBER_NAME)) || (loggedInUserRoles.includes(COMPREHENSIVE_PROGRAM_MEMBER))) && (
                      <Nav.Item>
                    <Nav.Link
                      ref={navLinkComprehensive}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="comprehensive"
                      onClick={() => {
                        setSelectedNav("comprehensive");
                      }}
                    >
                      <strong>Comprehensive</strong>
                    </Nav.Link>
                  </Nav.Item>
                  )}
                  {((loggedInUserRoles.includes(GPLC_PROGRAM_MEMBER)) || ((loggedInUserRoles.includes(GPLC_PROGRAM_MEMBER_NAME)))) && (
                    <Nav.Item>
                      <Nav.Link
                        ref={navLinkGplc}
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="gplc"
                        onClick={() => {
                          setSelectedNav("gplc");
                        }}
                      >
                        <strong>GPLC</strong>
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {(loggedInUserRoles.includes(MICRO_PROGRAM_MEMBER)) && (
                    <Nav.Item>
                      <Nav.Link
                        ref={navLinkMicro}
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="micro"
                        onClick={() => {
                          setSelectedNav("micro");
                        }}
                      >
                        <strong>Micro Courses</strong>
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  {(loggedInUserRoles.includes(KIDVISION_PROGRAM_MEMBER)) &&(
                    <Nav.Item>
                      <Nav.Link
                        ref={navLinkKidvision}
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="kidvision"
                        onClick={() => {
                          setSelectedNav("kidvision");
                        }}
                      >
                        <strong>KidVision</strong>
                      </Nav.Link>
                    </Nav.Item>
                  )}
                    </> 
                    
                    }
                </div>
                <div className="tile_b_controls">
                  <Dropdown
                    options={["Recent Activity", "Course Name"]}
                    placeholder={"Recent Activity"}
                    className="sort-by-dropdown-styles_facilitator tile_b_sortBy"
                    onChange={(e) => {
                      console.log(e.value);
                      setSortBy(e.value);
                    }}
                    style={{ width: "100%" }}
                    // menuPlacement="auto"
                    arrowClosed={
                      <span className="material-icons">arrow_drop_down</span>
                    }
                    arrowOpen={
                      <span className="material-icons">arrow_drop_up</span>
                    }
                  />
                </div>
              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="comprehensive">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {isLoading ? (
                      <div className="tkl-community-spinner tkl-spinner-master-data">
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="lr"
                        />
                      </div>
                    ) : (
                      renderData("comprehensive")
                    )}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="gplc">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {isLoading ? (
                      <div className="tkl-community-spinner tkl-spinner-master-data">
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="lr"
                        />
                      </div>
                    ) : (
                      renderData("gplc")
                    )}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="micro">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {isLoading ? (
                      <div className="tkl-community-spinner tkl-spinner-master-data">
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="lr"
                        />
                      </div>
                    ) : (
                      renderData("micro")
                    )}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="kidvision">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {isLoading ? (
                      <div className="tkl-community-spinner tkl-spinner-master-data">
                        <Spinner
                          animation="border"
                          variant="primary"
                          size="lr"
                        />
                      </div>
                    ) : (
                      renderData("kidvision")
                    )}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}
