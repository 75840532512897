import { types } from "./types";
const initialState = {
  cartData: [],
  isReload: false,
  catalogOptionalValues: {},
  assignedCourses: [],
  getCoursesForCode: {},
  codeData: {},
  errorMessageForCart: {},
  cartCodesApplied: [],
  paymentIntent: {},
  orderDetails: {},
  catalogueDescription: "",
};
export const catalogue = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_CART_DATA:
      return {
        ...state,
        cartData: action.payload,
      };
    case types.GET_ASSIGNED_COURSES:
      return {
        ...state,
        assignedCourses: action.payload,
      };

    case types.CART_RELOAD:
      return {
        ...state,
        isReload: action.payload,
      };

    case types.PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: action.payload,
      };

    case types.CONFIRM_ORDER:
      return {
        ...state,
        orderDetails: action.payload,
      };

    case types.REMOVE_FROM_CART:
      return {
        ...state,
        cartData: [
          ...state.cartData.slice(0, action.itemIndex),
          ...state.cartData.slice(action.itemIndex + 1),
        ],
      };

    case types.CATALOG_OPTIONAL_VALUES:
      let type = action.payload.key;

      return {
        ...state,
        catalogOptionalValues: {
          ...state.catalogOptionalValues,
          [type]: action.payload.payload,
        },
      };
    case types.RESUABLE_GET_DATAINPUT_BY_LABEL_CART_REDUCER:
      return {
        ...state,
        [action.label]: action.payload,
      };
    case types.RESUABLE_GET_DATAINPUT_FOR_ONE_LEVEL_CART_REDUCER:
      return {
        ...state,
        [action.label]: {
          ...state[action.label],
          [action.innerLabel]: [
            ...state[action.label][action.innerLabel].slice(
              0,
              action.itemIndex
            ),
            action.newDataAtThatIndex,
            ...state[action.label][action.innerLabel].slice(
              action.itemIndex + 1
            ),
          ],
        },
      };

    case types.RESUABLE_INNER_LABEL_VALUE_CHANGE:
      console.log("...action", action);
      return {
        ...state,
        [action.label]: {
          ...state[action.label],
          [action.innerLabel]: action.payload,
        },
      };
    default:
      return state;
  }
};
