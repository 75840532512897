import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import "./_index.scss";
import "react-circular-progressbar/dist/styles.css";

const ComponentProgressBar = React.memo(function ComponentProgressBar(props) {
  const type = props.type || "";
  const data = props.data || {};
  const genCircular = (data) => {
    try {
      const current = parseInt((data.value || {}).current || 0, 10);
      const total = parseInt((data.value || {}).total || 0, 10);
      const perc = ((current / total) * 100).toFixed(2);
      return (
        <div>
          <CircularProgressbar
            className={`progress-${data.theme || "primary"}`}
            value={(perc === "NaN") ? 0 : perc}
            text={
              data?.showDirectText
                ? data.showValue || "0"
                : [true, "true"].includes(data.showPercent)
                ? (perc === "NaN") ? "0" : `${Math.floor(perc)}%`
                : Math.floor(current) || 0
            }
            role="progressbar"
            aria-valuenow={current}
            aria-valuemin="0"
            aria-valuemax={total}
          />
        </div>
      );
    } catch (e) {
      return <></>;
    }
  };

  let style = () => {
    let bgColors = {
      Default: "#18998e",
      Blue: "#00B1E1",
      Cyan: "#37BC9B",
      Green: "#8CC152",
      Yellow: "#F6BB42",
      Purple: "purple",
      Royalblue: "royalblue",
      Cornflowerblue: "cornflowerblue",
      Darkblue: "darkblue",
    };
    if (data.theme == "randomColors") {
      return {
        backgroundColor:
          bgColors[
            Object.keys(bgColors)[
              Math.floor(Math.random() * Object.keys(bgColors).length)
            ]
          ],
      };
    } else {
      {
      }
    }
  };
  return (
    <>
      {type === "linear" ? (
        <div className="progress prgrs-bar-comp">
          <div
            className={`progress-bar bg-${data.theme || "primary"}`}
            role="progressbar"
            style={{
              width: (data.progress || 0) + "%",
              ...style(),
            }}
            aria-valuenow={data.progress}
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
      ) : (
        <></>
      )}
      {type === "circular" ? genCircular(data) : <></>}
    </>
  );
});

export default ComponentProgressBar;
