import React, { useState } from "react";
import ComponentPageBanner from "./../../shared-components/page-banner/index";
import ComponentAvatar from "./../../shared-components/avatar/index";
import ComponentProgressBar from "./../../shared-components/progress-bar/index";
import "./_index.scss";
import {
  getInDateFormat,
  BannerImageHandlingForAllDevices,
  getFullName,
  EditProfileJSX,
} from "../utility/ReUsableFunctions";
import { getMyConnections } from "actions/CommunityActions/CommunityAction";
import { useDispatch, useSelector } from "react-redux";
const ComponentPaidUserTopcard = React.memo(function ComponentPaidUserTopcard(
  props
) {
  const dispatch = useDispatch();
  const [infoToggle, setInfoToggle] = useState(false);
  const defaultImageURL = `${
    process.env.PUBLIC_URL + "/Banner_defaut_image.png"
  }`;

  const { myConnections, isColleaguesReload } = useSelector(
    (state) => state.community
  );
  React.useEffect(() => {
    dispatch(getMyConnections());
  }, [isColleaguesReload]);

  return (
    <>
      <section className="tc-comp mb-3">
        <div>
          <ComponentPageBanner
            data={{
              type: "image",
              // bannerImage: BannerImageHandlingForAllDevices(
              //   props.userData?.bannerImg?.url,
              //   "course",
              //   props.userData?.bannerImg?.slug
              // ),
            }}
            bannerImage={
              props.userData?.bannerImg?.slug
                ? props.userData?.bannerImg?.url
                : defaultImageURL
            }
            iseditable={false}
          />
          <div className="tc-main">
            <div className="d-md-flex">
              <div className="tc-name-wrap p-2 p-md-3 p-xxl-4">
                <article className="tc-name position-relative d-flex h-100">
                  <div className="tc-img position-absolute left-0">
                    <ComponentAvatar
                      type={"user"}
                      data={{
                        // firstname:
                        //   props.userData?.avatarImg?.slug === "" &&
                        //   props.userData?.firstName,
                        // lastname:
                        //   props.userData?.avatarImg?.slug === "" &&
                        //   props.userData?.lastName,
                        // img: userData?.avatarImg?.url,
                        img: BannerImageHandlingForAllDevices(
                          props.userData?.avatarImg?.url,
                          "user",
                          props.userData?.avatarImg?.slug
                        ),
                      }}
                    />
                  </div>
                  <div className="tc-name-text d-flex flex-column justify-content-center user_data_overflow_wrap">
                    <p className="text-brandyellow text-uppercase m-0">
                      <small>
                        <strong>TKL MEMBER</strong>
                      </small>
                    </p>
                    <h1 className="h3 text-uppercase m-0">
                      {getFullName(
                        props.userData?.firstName,
                        props.userData?.middleName,
                        props.userData?.lastName
                      )}
                      {EditProfileJSX()}
                    </h1>
                    <p
                      className="text-black-50 mb-2"
                      style={{ marginTop: "10px" }}
                    >
                      <strong>
                        {props.userData?.subArea.map((item, index) => {
                          return (
                            <>
                              {index !== 0 && <>&middot;</>} {item?.name}{" "}
                            </>
                          );
                        })}
                      </strong>
                    </p>

                    <ComponentProgressBar
                      type={"linear"}
                      data={{
                        theme: "warning",
                        progress: `${props.userData?.points}`,
                      }}
                    />
                    <small className="text-black-50">
                      <strong>
                        Profile Completion: {props.userData?.points}%
                      </strong>
                    </small>
                  </div>
                </article>
              </div>

              <div className="tc-right-one py-2 px-3 pl-xl-2 pr-xl-4 d-md-flex flex-md-column">
                <div className="tc-stat py-2 h-100">
                  <div className="alert alert-brandaqua d-flex h-100 pl-5 py-3 py-md-2 position-relative m-0 align-items-center line-height-1">
                    <div className="position-absolute left-0 h-100 top-0 d-flex justify-content-center align-items-center p-2">
                      <big>
                        <strong>
                          {props.userData?.paidUserData?.activeCourses}
                        </strong>
                      </big>
                    </div>
                    # Active Courses
                  </div>
                </div>
                <div className="tc-stat py-2 h-100">
                  <div className="alert alert-brandred d-flex h-100 pl-5 py-3 py-md-2 position-relative m-0 align-items-center line-height-1">
                    <div className="position-absolute left-0 h-100 top-0 d-flex justify-content-center align-items-center p-2">
                      <big>
                        <strong>
                          {props.userData?.paidUserData?.certificatesEarned}
                        </strong>
                      </big>
                    </div>
                    # Certificates Earned
                  </div>
                </div>
              </div>
            </div>
            <div className="p-1 p-md-0 border-bottom">
              <button
                className="tc-toggle-btn text-uppercase px-3 py-2 rounded d-md-none"
                onClick={() => {
                  setInfoToggle(!infoToggle);
                }}
              >
                More Info
                <div className="d-flex justify-content-center align-items-center p-2">
                  {!infoToggle ? (
                    <i className="material-icons">add_circle_outline</i>
                  ) : (
                    <></>
                  )}
                  {infoToggle ? (
                    <i className="material-icons">remove_circle_outline</i>
                  ) : (
                    <></>
                  )}
                </div>
              </button>
            </div>
            <div
              className={`tc-user-info ${
                infoToggle ? "d-block" : "d-none"
              } d-md-block`}
            >
              <div className="d-md-flex">
                <div className="min-md-25 p-3 flex-md-grow-1">
                  <i className="material-icons">stars</i>
                  {/* <small className="ml-2 text-uppercase"> */}
                  <strong className="btn-text-primary text-uppercase ml-2">
                    TKL Activity Points
                  </strong>
                  {/* </small> */}
                  <br />
                  <strong style={{ marginLeft: "2.5px" }}>COMING SOON!</strong>
                </div>
                {props.userData?.school && !props.userData?.schoolPrivate && (
                  <div className="min-md-25 p-3 flex-md-grow-1">
                    <i className="material-icons">home</i>
                    {/* <small className="ml-2 text-uppercase">School</small> */}
                    <strong className="ml-2 text-uppercase btn-text-primary">
                      School
                    </strong>
                    <br />
                    <strong style={{ marginLeft: "2.5px" }}>
                      {" "}
                      {props?.userData?.school}
                    </strong>
                  </div>
                )}
                {props.userData?.district && !props?.userData?.districtPrivate && (
                  <div className="min-md-25 p-3 flex-md-grow-1">
                    <i className="material-icons">map</i>
                    {/* <small className="ml-2 text-uppercase">District</small> */}
                    <strong className="ml-2 text-uppercase btn-text-primary">
                      District
                    </strong>
                    <br />
                    <strong style={{ marginLeft: "2.5px" }}>
                      {" "}
                      {props?.userData?.district}
                    </strong>
                  </div>
                )}
                {props.userData?.country?.name &&
                  !props?.userData?.countryPrivate && (
                    <div className="min-md-25 p-3 flex-md-grow-1">
                      <i className="material-icons">place</i>
                      {/* <small className="ml-2 text-uppercase">Location</small> */}
                      <strong className="ml-2 text-uppercase btn-text-primary">
                        Location
                      </strong>
                      <br />
                      <strong style={{ marginLeft: "2.5px" }}>
                        {" "}
                        {props?.userData?.state?.name}
                      </strong>
                    </div>
                  )}
              </div>
              <div className="d-md-flex border-top text-uppercase">
                <div className="min-md-25 p-3 flex-md-grow-1">
                  {/* <small> */}
                  <strong className="ml-2 text-uppercase btn-text-primary">
                    TOTAL CONNECTIONS
                    <br />
                  </strong>
                  <strong
                    className="TKL_number_highlight"
                    style={{ marginLeft: "2.5px" }}
                  >
                    {myConnections?.meta?.total}{" "}
                  </strong>
                  MEMBERS
                  {/* </small> */}
                </div>

                <div className="min-md-25 p-3 flex-md-grow-1">
                  {/* <small> */}
                  <strong className="ml-2 text-uppercase btn-text-primary">
                    Joined TKL on
                    <br />
                  </strong>
                  <strong
                    className="TKL_number_highlight"
                    style={{ marginLeft: "4.5px" }}
                  >
                    {getInDateFormat(props?.userData?.joinedTKL)}
                  </strong>
                </div>
                <div className="min-md-25 p-3 flex-md-grow-1`">
                  <strong className="ml-2 text-uppercase btn-text-primary">
                    Last Accessed on
                    <br />
                  </strong>
                  <strong
                    className="TKL_number_highlight"
                    style={{ marginLeft: "4.5px" }}
                  >
                    {getInDateFormat(props?.userData?.lastAccessedAt)}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
});

export default ComponentPaidUserTopcard;
