import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL } from "../../api/tklEnvironments";

export const getNotifications = () => {
  return (dispatch) => {
    if (localStorage.getItem("token")) {
      axios
        .get(`${URL}/notification?page=1`)
        .then(function (response) {
          if (response.data) {
            dispatch({
              type: types.NOTIFICATION_RELOAD,
              payload: false,
            });
            dispatch({
              type: types.GET_NOTIFICATIONS,
              payload: response.data,
            });
          }
        })
        .catch(function (error) {
          dispatch({
            type: types.NOTIFICATION_RELOAD,
            payload: false,
          });
          console.error("..error", error);
        });
    }
  };
};

export const fetchMoreNotifications = (pageNo, notification) => {
  return (dispatch) => {
    if (localStorage.getItem("token")) {
      axios
        .get(`${URL}/notification?page=${pageNo}`)
        .then(function (response) {
          if (response.data) {
            dispatch({
              type: types.NOTIFICATION_RELOAD,
              payload: true,
            });
            dispatch({
              type: types.APPEND_NOTIFICATIONS,
              payload: response.data,
              currentNotification: notification,
            });
          }
        })
        .catch(function (error) {
          console.error("..error", error);
        });
    }
  };
};

export const markReadNotification = (id) => {
  return (dispatch) => {
    if (localStorage.getItem("token")) {
      axios
        .put(`${URL}/markRead/${id}`)
        .then(function (response) {
          if (response.data) {
            dispatch({
              type: types.NOTIFICATION_RELOAD,
              payload: true,
            });
          }
        })
        .catch(function (error) {
          console.error("..error", error);
        });
    }
  };
};
