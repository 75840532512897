import axios from "axios";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import { showFirstValidationErrorFromAPI } from "../../components/utility/ReUsableFunctions";
import { types } from "../../reducers/types";
import {
  hideLoader,
  convertLocalDateToUTCDate,
  convertUTCDateToLocalDate,
} from "../../components/utility/ReUsableFunctions";
import { store } from "../../App";
import _ from "lodash";
import {
  sortBasedonSortOrder,
  sortBasedonSortOrderFinancial,
  sortBasedonSortOrderComprehensive,
} from "./UserAction";
import { getPathRightData } from "./AccountAction";
import printJS from "print-js";
export const getCodeLocations = () => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-market-location?isPaginated=${0}`)
      .then((response) => {
        // console.log(".....response?.data", response?.data);
        if (response?.data)
          dispatch({
            type: types.GET_ALL_LOCATIONS_DISCUOUNTCODE,
            payload: { data: response.data },
          });
      })
      .catch((error) => {});
  };
};
export const getCodeCourses = (
  marketLocationSlug,
  isUpdate,
  isUpdateSecondRender,
  label,
  courseData
) => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-cohort-by-market-location/${marketLocationSlug}`)
      .then((response) => {
        let storeData = store && store.getState()?.tkladmin?.allCourses;
        response?.data?.response.forEach((item) => {
          let payload = {};
          payload["slug"] = item?.cohort;
          payload["name"] = item?.courseName;
          payload["marketLocationSlug"] = marketLocationSlug;
          storeData.push(payload);
        });
        if (
          isUpdate &&
          isUpdateSecondRender === 2 &&
          label === "discountCode"
        ) {
          let newCourseData = storeData.filter((storeDataItem) =>
            courseData.some(({ slug }) => slug === storeDataItem?.slug)
          );
          dispatch({
            type: types.CODE_INPUT_SELECTION,
            label: "courses",
            payload: newCourseData[0] ? newCourseData : [""],
            isArray: false,
          });
        } else if (
          isUpdate &&
          isUpdateSecondRender === 2 &&
          label === "bundles"
        ) {
          let newCourseData = storeData.filter((storeDataItem) =>
            courseData.some(
              (courseDataItem) => courseDataItem?.slug === storeDataItem?.slug
            )
          );
          dispatch({
            type: types.CODE_INPUT_SELECTION_BUNDLE,
            label: "courses",
            payload: newCourseData[0] ? newCourseData : [""],
            isArray: false,
            data: "bundleData",
          });
        }
        dispatch({
          type: types.GET_ALL_COURSES_DISCUOUNTCODE,
          payload: storeData,
        });
      })
      .catch((error) => {});
  };
};

export const saveAddDiscountCode = (data, pageNumber, isUpdate) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    // dispatch({ type: types.SHOW_LOADER });
    let payload = {};
    payload["code"] = data["code"];
    payload["discount"] = +data["discount"];
    payload["discountType"] = data["discountype"];
    payload["status"] = data["status"];
    let newStartDate = convertLocalDateToUTCDate(data["startTime"]); //new Date(data["startTime"]);
    //console.log(".....newStartDate", newStartDate);
    payload["startAt"] = newStartDate;

    // `${newStartDate.getFullYear()}-${
    //   newStartDate.getMonth() + 1
    // }-${newStartDate.getDate()} ${newStartDate.getHours()}:${newStartDate.getMinutes()}`;

    let newEndDate = convertLocalDateToUTCDate(data["endTime"]); //new Date(data["endTime"]);
    payload["endAt"] = newEndDate;

    // `${newEndDate.getFullYear()}-${
    //   newEndDate.getMonth() + 1
    // }-${newEndDate.getDate()} ${newEndDate.getHours()}:${newEndDate.getMinutes()}`;

    payload["cohortsSelected"] = [];
    payload["marketLocationsSelected"] = [];
    data?.courses &&
      data?.courses.forEach((item) => {
        if (item?.slug) payload["cohortsSelected"].push(item?.slug);
        else {
          payload["cohortsSelected"] = [];
        }
      });
    data?.location.forEach((item) => {
      payload["marketLocationsSelected"].push(item?.slug);
    });

    if (isUpdate) {
      await axios
        .put(`${URL_COHORT}/update-discount-code`, payload)
        .then((response) => {
          dispatch(getDiscountCodesData(pageNumber));
          // hideLoader(initialTime);
          return Promise.resolve(true);
        })
        .catch((error) => {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          // hideLoader(initialTime);

          return Promise.reject();
        });
    } else {
      await axios
        .post(`${URL_COHORT}/add-discount-code`, payload)
        .then((response) => {
          dispatch(getDiscountCodesData(pageNumber));
          dispatch({ type: types.INITIAL_DATA_DISCOUNT_CODE });
          // hideLoader(initialTime);
          return Promise.resolve();
        })
        .catch((error) => {
          //  hideLoader(initialTime);
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject();
        });
    }
  };
};

export const getDiscountCodesData = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // !userData?.searchInput
    if (true) {
      dispatch(
        sortBasedonSortOrder(
          "get-discount-code",
          1,
          "discountCode",

          userData?.sortFieldType?.slug || "startAt",
          userData?.sortOrder === "Ascending" ? "ASC" : "DESC" || "DESC",

          userData?.searchFieldType?.slug || "code",
          userData?.searchInput || "",

          true,
          page
        )
      );
    } else {
      axios
        .get(`${URL_COHORT}/get-discount-code?page=${page}`)
        .then(function (response) {
          if (response.data) {
            dispatch({ type: types.IS_LOADING, payload: false });
            response.data["data"] = dispatch(
              prepareDataFromApiToStore(response)
            );
            dispatch({
              type: types.GET_DISCOUNT_CODE_DATA,
              payload: response.data,
            });
          }
        })
        .catch((error) => {
          console.error("..error");
          dispatch({ type: types.IS_LOADING, payload: false });
        });
    }
  };
};

export const prepareDataFromApiToStore = (response) => {
  return (dispatch) => {
    let returnArrayAfterNamingConventionsChange = [];
    response?.data?.data.forEach((data) => {
      let payload = {};
      payload["code"] = data["code"];
      payload["discount"] = +data["discount"];
      payload["discountype"] = data["discountType"];
      payload["status"] = data["status"];
      payload["redemptionCount"] = data["redemptionCount"];

      payload["startTime"] = convertUTCDateToLocalDate(data["startAt"]); //data["startAt"]; //.split(" ")[1]?.slice(0, -3);
      payload["endTime"] = convertUTCDateToLocalDate(data["endAt"]); //data["endAt"]; //.split(" ")[1]?.slice(0, -3);

      payload["courses"] = [];
      payload["location"] = [];
      data?.cohortWithCourses.forEach((item) => {
        item &&
          payload["courses"].push({
            name: item?.cohortCourse?.name,
            // slug: item?.cohortCourse?.courseDisplayId,
            slug: item?.cohortDisplayId,
          });
      });
      data?.marketLocationAndOrganisations.forEach((item) => {
        payload["location"].push({ name: item.name, slug: item?.slug });
      });
      returnArrayAfterNamingConventionsChange.push(payload);
    });

    return returnArrayAfterNamingConventionsChange;
  };
};

export const saveAddBundles = (data, pageNumber, isUpdate) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    //  dispatch({ type: types.SHOW_LOADER });
    let payload = {};
    payload["title"] = data["title"];
    payload["price"] = +data["price"];
    payload["bundleSize"] = data["bundleSize"];
    payload["isPrivate"] = data["Visibility"] === "PRIVATE" ? true : false;
    payload["description"] = data["description"];
    payload["marketLocationSlugs"] = [];
    payload["cohorts"] = [];
    data?.location.forEach((item) => {
      payload["marketLocationSlugs"].push(item?.slug);
    });
    data?.courses.forEach((item) => {
      if (item?.slug) payload["cohorts"].push(item?.slug);
      else {
        payload["cohorts"] = [];
      }
    });
    if (isUpdate) {
      payload["slug"] = data["slug"];
      await axios
        .put(`${URL_COHORT}/update-bundle`, payload)
        .then((response) => {
          dispatch(getBundlesData(pageNumber));
          // dispatch({ type: types.INITIAL_DATA_BUNDLE_CODES });
          // hideLoader(initialTime);
          return Promise.resolve(true);
        })
        .catch((error) => {
          // hideLoader(initialTime);
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
            return Promise.reject();
          }
        });
    } else {
      await axios
        .post(`${URL_COHORT}/add-bundle`, payload)
        .then((response) => {
          dispatch(getBundlesData(pageNumber));
          //hideLoader(initialTime);

          return Promise.resolve();
        })
        .catch((error) => {
          // hideLoader(initialTime);
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject();
        });
    }
  };
};

export const getBundlesData = (
  page,
  isNotPaginated,
  isAppendPreviousData,
  isNotPrivate
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    let url = isNotPaginated
      ? `get-bundle?${isNotPrivate ? "isPrivate=0&" : ""}isPaginated=0`
      : `get-bundle?${isNotPrivate ? "isPrivate=0&" : ""}page=${page}`;
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // _.isEmpty(userData?.searchInput) &&
    if (!isNotPaginated) {
      dispatch(
        sortBasedonSortOrder(
          `get-bundle${isNotPrivate ? "?isPrivate=0" : ""}`,
          1,
          "bundle",

          userData?.sortFieldType?.slug || "title",
          userData?.sortOrder === "Descending" ? "DESC" : "ASC" || "ASC",
          userData?.searchFieldType?.slug || "title",
          "",

          true,
          page,

          null,
          null,

          isAppendPreviousData
        )
      );
    } else {
      axios
        .get(`${URL_COHORT}/${url}`)
        .then(function (response) {
          if (response.data) {
            dispatch({ type: types.IS_LOADING, payload: false });
            if (isNotPaginated) {
              response.data.response = prepareDataFromApiToStoreBundles(
                response,
                isNotPaginated
              );
              dispatch({
                type: types.GET_BUNDLES_DATA,
                payload: response.data?.response,
                label: "getBundleData",
              });
            } else {
              response.data.response["data"] = prepareDataFromApiToStoreBundles(
                response,
                isNotPaginated
              );
              if (isAppendPreviousData) {
                //append previous data for infinite scrolling pagination
                let newArr =
                  store && store.getState()?.tkladmin?.getBundleData?.data;
                response.data?.response?.data?.forEach((item) => {
                  newArr.push(item);
                });
                response.data.response.data = newArr;
              }
              dispatch({
                type: types.GET_BUNDLES_DATA,
                payload: response.data?.response,
                label: "getBundleData",
              });
            }
          }
        })
        .catch((error) => {
          console.error("..error");
          dispatch({ type: types.IS_LOADING, payload: false });
        });
    }
  };
};

export const prepareDataFromApiToStoreBundles = (response, isNotPaginated) => {
  let returnArrayAfterNamingConventionsChange = [];
  if (isNotPaginated) {
    response?.data?.response.forEach((data) => {
      let payload = {};
      payload["title"] = data["title"];
      payload["slug"] = data["slug"];
      payload["price"] = +data["price"];
      payload["bundleSize"] = data["bundleSize"];
      payload["visibility"] = data["isPrivate"] === true ? "PRIVATE" : "PUBLIC";
      payload["description"] = data["description"];
      payload["location"] = [];
      payload["courses"] = [];
      data?.cohorts.forEach((item) => {
        payload["courses"].push({ name: item?.name, slug: item?.slug });
      });
      data?.marketLocations.forEach((item) => {
        payload["location"].push({ name: item.name, slug: item?.slug });
      });
      returnArrayAfterNamingConventionsChange.push(payload);
    });
  } else {
    response?.data?.response?.data.forEach((data) => {
      let payload = {};
      payload["title"] = data["title"];
      payload["slug"] = data["slug"];
      payload["price"] = +data["price"];
      payload["bundleSize"] = data["bundleSize"];
      payload["visibility"] = data["isPrivate"] === true ? "PRIVATE" : "PUBLIC";
      payload["description"] = data["description"];
      payload["location"] = [];
      payload["courses"] = [];
      data?.cohorts.forEach((item) => {
        payload["courses"].push({
          name: item?.cohortDisplayId,
          slug: item?.cohortDisplayId,
        });
      });
      data?.marketLocations.forEach((item) => {
        payload["location"].push({ name: item.name, slug: item?.slug });
      });
      returnArrayAfterNamingConventionsChange.push(payload);
    });
  }

  return returnArrayAfterNamingConventionsChange;
};

export const saveAddBundleCodes = (data, pageNumber, isUpdate) => {
  return async (dispatch) => {
    //let initialTime = performance.now();
    //  dispatch({ type: types.SHOW_LOADER });
    let payload = {};
    payload["code"] = data["code"];
    payload["bundleSlug"] = data?.bundleData?.slug;
    payload["bundleSize"] = +data["bundleSize"];
    payload["isActive"] =
      data["isActive"] === "YES" || data["isActive"] === "Yes" ? true : false;
    payload["redemptionCount"] = +data["redemptionCount"];
    payload["isAutoGenerated"] = false;
    if (isUpdate) {
      await axios
        .put(`${URL_COHORT}/update-bundle-code`, payload)
        .then((response) => {
          dispatch(getBundleCodesData(pageNumber));
          return Promise.resolve();
        })
        .catch((error) => {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject();
        });
    } else {
      await axios
        .post(`${URL_COHORT}/add-bundle-code`, payload)
        .then((response) => {
          dispatch(getBundleCodesData(pageNumber));
          return Promise.resolve();
        })
        .catch((error) => {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject();
        });
    }
  };
};

export const getBundleCodesData = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // !userData?.searchInput
    if (true) {
      dispatch(
        sortBasedonSortOrder(
          "get-bundle-code",
          1,
          "bundleCode",
          userData?.sortFieldType?.slug || "code",
          userData?.sortOrder === "Descending" ? "DESC" : "ASC" || "ASC",
          userData?.searchFieldType?.slug || "code",
          userData?.searchInput || "",
          true,
          page
        )
      );
    } else {
      axios
        .get(`${URL_COHORT}/get-bundle-code?page=${page}`)
        .then(function (response) {
          if (response.data) {
            dispatch({ type: types.IS_LOADING, payload: false });
            response.data.response["data"] =
              prepareDataFromApiToStoreBundleCodes(response);

            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data?.response,
              label: "getBundleCodeData",
            });
          }
        })
        .catch((error) => {
          console.error("..error");
          dispatch({ type: types.IS_LOADING, payload: false });
        });
    }
  };
};

export const prepareDataFromApiToStoreBundleCodes = async (response) => {
  let returnArrayAfterNamingConventionsChange = [];
  // response?.data?.response?.data
  console.log(".....response", response);
  response?.data?.data.forEach((data) => {
    let payload = {};
    payload["code"] = data["code"];
    payload["bundleData"] =
      data?.bundle || data?.bundleDetails || data?.bundleData;
    payload["redemptionCount"] = +data["redemptionCount"];
    payload["bundleSize"] =
      data?.bundle?.["bundleSize"] ||
      data?.bundleDetails?.["bundleSize"] ||
      data?.bundleData?.["bundleSize"];
    payload["isAutoGenerated"] = data["isAutoGenerated"] === 0 ? "NO" : "YES";
    payload["isActive"] = data["isActive"] === 0 ? "NO" : "YES";
    payload["createdAt"] = new Date(data?.createdAt);
    returnArrayAfterNamingConventionsChange.push(payload);
  });
  return returnArrayAfterNamingConventionsChange;
};

export const deleteCode = (data, pageNumber) => {
  return (dispatch) => {
    //let initialTime = performance.now();
    // dispatch({ type: types.SHOW_LOADER });
    let url = "delete-discount-code";
    if (data?.type === "Add Discount Code") {
      url = `delete-discount-code/${data?.code}`;
    } else if (data.type === "Add Bundle") {
      url = `delete-bundle/${data?.slug}`;
    } else if (data.type === "Add Bundle Code") {
      url = `delete-bundle-code/${data?.code}`;
    }
    axios
      .delete(`${URL_COHORT}/${url}`)
      .then((response) => {
        if (data?.type === "Add Discount Code") {
          dispatch(getDiscountCodesData(pageNumber));
        } else if (data.type === "Add Bundle") {
          dispatch(getBundlesData(pageNumber));
        } else if (data.type === "Add Bundle Code") {
          dispatch(getBundleCodesData(pageNumber));
        }
        //    hideLoader(initialTime);
      })
      .catch((error) => {
        // hideLoader(initialTime);
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
      });
  };
};

export const downloadFile = (
  isNotCohort,
  urlItem,
  fileNametodownload,
  downloadAttribute,
  accountType,
  updatedUrlItem
) => {
  let url = "";
  if(accountType){
    url = `${isNotCohort ? URL : URL_COHORT}/${updatedUrlItem}`;
  }else{
    url = `${isNotCohort ? URL : URL_COHORT}/${urlItem}`;
  }
  return (dispatch) => {
    axios
      .get(url)
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let fileName = fileNametodownload;
          link.setAttribute(downloadAttribute, fileName);
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
      });
  };
};

//userManagement
const data = {
  response: {
    current_page: 1,
    data: [
      {
        firstName: "Saige",
        middleName: "Benny Schinner",
        lastName: "Aufderhar",
        userPosition: "Mrs. Everette Feest",
        userRegistrationDate: "2021-07-09 13:36:05",
        programName: "Comprehensive Course Program",
        termEndDate: "2021-08-10 13:51:01",
        courseCompletedAt: null,
        courseFacilitatorFirstName: "Alfreda",
        courseFacilitatorMiddleName: "Herminia Streich",
        courseFacilitatorLastName: "Schulist",
        courseName: "Ms. Olga Gislason",
        courseStatus: "ACTIVE",
        userCountry: "United States",
        userState: "Colorado",
        marketLocation: "Mr. Willard Kuhlman",
        Purpose: "Graduate Credits/Semester Hours",
        cohortType: "Rolling",
      },
      {
        firstName: "Saige",
        middleName: "Benny Schinner",
        lastName: "Aufderhar",
        userPosition: "Mrs. Everette Feest",
        userRegistrationDate: "2021-07-09 13:36:05",
        programName: "Comprehensive Course Program",
        termEndDate: "2021-08-10 13:51:01",
        courseCompletedAt: null,
        courseFacilitatorFirstName: "Alfreda",
        courseFacilitatorMiddleName: "Herminia Streich",
        courseFacilitatorLastName: "Schulist",
        courseName: "Ms. Olga Gislason",
        courseStatus: "ACTIVE",
        userCountry: "United States",
        userState: "Colorado",
        marketLocation: "Mr. Willard Kuhlman",
        Purpose: "Graduate Credits/Semester Hours",
        cohortType: "Rolling",
      },
      {
        firstName: "Saige",
        middleName: "Benny Schinner",
        lastName: "Aufderhar",
        userPosition: "Mrs. Everette Feest",
        userRegistrationDate: "2021-07-09 13:36:05",
        programName: "Comprehensive Course Program",
        termEndDate: "2021-08-10 13:51:01",
        courseCompletedAt: null,
        courseFacilitatorFirstName: "Alfreda",
        courseFacilitatorMiddleName: "Herminia Streich",
        courseFacilitatorLastName: "Schulist",
        courseName: "Ms. Olga Gislason",
        courseStatus: "ACTIVE",
        userCountry: "United States",
        userState: "Colorado",
        marketLocation: "Mr. Willard Kuhlman",
        Purpose: "Graduate Credits/Semester Hours",
        cohortType: "Rolling",
      },
    ],
    first_page_url:
      "http://127.0.0.1:8000/api/v1/get-comprehensive-user-list?page=1",
    from: 1,
    last_page: 1,
    last_page_url:
      "http://127.0.0.1:8000/api/v1/get-comprehensive-user-list?page=1",
    links: [
      {
        url: null,
        label: "&laquo; Previous",
        active: false,
      },
      {
        url: "http://127.0.0.1:8000/api/v1/get-comprehensive-user-list?page=1",
        label: "1",
        active: true,
      },
      {
        url: null,
        label: "Next &raquo;",
        active: false,
      },
    ],
    next_page_url: null,
    path: "http://127.0.0.1:8000/api/v1/get-comprehensive-user-list",
    per_page: 15,
    prev_page_url: null,
    to: 3,
    total: 3,
  },
};
export const getuserManagementDataHandler = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // !userData?.searchInput
    if (true) {
      dispatch(
        sortBasedonSortOrderComprehensive(
          "get-comprehensive-user-list",
          1,
          "facilitator",
          userData?.sortFieldType?.slug || "createdAt",
          userData?.sortOrder === "Ascending" ? "ASC" : "DESC",
          "",
          "",
          true,
          page,
          false,
          true
        )
      );
    } else {
      axios
        .get(`${URL_COHORT}/get-comprehensive-user-list?page=${page}`)
        .then(function (response) {
          if (response.data) {
            dispatch({ type: types.IS_LOADING, payload: false });
            // response.data["data"] = dispatch(prepareDataFromApiToStore(response));

            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data?.response, //data?.response,
              label: "getUserManagementData",
            });
          }
        })
        .catch((error) => {
          console.error("..error");
          dispatch({ type: types.IS_LOADING, payload: false });
        });
    }
  };
};

export const getTKLMembersDataHandler = (page) => {
  return (dispatch) => {
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // !userData?.searchInput
    if (true) {
      dispatch(
        sortBasedonSortOrderComprehensive(
          "get-tkl-member-info",
          1,
          "facilitator",
          userData?.sortFieldType?.slug || "firstName",
          userData?.sortOrder === "Descending" ? "DESC" : "ASC",
          "",
          "",
          true,
          page,
          false,
          true,
          true //isTKLMemebers
        )
      );
    } else {
      axios
        .get(`${URL_COHORT}/get-tkl-member-info?page=${page}`)
        .then(function (response) {
          if (response.data) {
            dispatch({ type: types.IS_RELOAD, payload: false });
            // response.data["data"] = dispatch(prepareDataFromApiToStore(response));
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data?.response, //data?.response,
              label: "getTKLMembersData",
            });
          }
        })
        .catch((error) => {
          console.error("..error");
          dispatch({ type: types.IS_RELOAD, payload: false });
        });
    }
  };
};

export const getuserFinancialDataHandler = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // !userData?.searchInput
    if (true) {
      dispatch(
        sortBasedonSortOrderFinancial(
          "get-financial-data",
          1,
          "facilitator",
          userData?.sortFieldType?.slug || "createdAt",
          userData?.sortOrder === "Ascending" ? "ASC" : "DESC",
          "",
          "",
          true,
          page,
          false,
          true
        )
      );
    } else {
      axios
        .get(`${URL_COHORT}/get-financial-data?page=${page}`)
        .then(function (response) {
          if (response.data) {
            dispatch({ type: types.IS_LOADING, payload: false });
            // response.data["data"] = dispatch(prepareDataFromApiToStore(response));
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data?.response,
              label: "getFinancialTable",
            });
          }
        })
        .catch((error) => {
          console.error("..error");
          dispatch({ type: types.IS_LOADING, payload: false });
        });
    }
  };
};

export const getBundleFinancialsDataHandler = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    axios
      .get(
        `${URL_COHORT}/get-bundle-financial-data?page=${page}&sortBy=createdAt&orderBy=DESC`
      )
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_LOADING, payload: false });
          dispatch({
            type: types.GET_BUNDLES_DATA,
            payload: response.data?.response,
            label: "getBundleFinancials",
          });
        }
      })
      .catch((error) => {
        console.error("..error");
        dispatch({ type: types.IS_LOADING, payload: false });
      });
  };
};

export const searchAndSortBundleFinancials = (pageNumberToSearch = 1) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    let { searchFieldType, searchInput, sortFieldType, sortOrder } = JSON.parse(
      sessionStorage.getItem("userSortingData")
    );

    let searchCriteria = searchInput
      ? `&searchBy=${searchFieldType?.slug}&searchValue=${searchInput || ""}`
      : "";
    let sortCriteria = `&sortBy=${sortFieldType?.slug || ""}&orderBy=${
      sortOrder === "Descending" ? "DESC" : "ASC"
    }`;

    axios
      .get(
        `${URL_COHORT}/get-bundle-financial-data?page=${pageNumberToSearch}${searchCriteria}${sortCriteria}`
      )
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_LOADING, payload: false });
          dispatch({
            type: types.GET_BUNDLES_DATA,
            payload: response.data?.response,
            label: "getBundleFinancials",
          });
        }
      })
      .catch((error) => {
        console.error("..error");
        dispatch({ type: types.IS_LOADING, payload: false });
      });
  };
};

export const getCoursesForMarketLcoations = (marketLocationSlug) => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-cohort-by-market-location/${marketLocationSlug}`)
      .then((response) => {
        if (response?.data) {
          let responseData = response?.data?.response;
          dispatch({
            type: types.GET_ALL_COURSES_DISCUOUNTCODE,
            payload: responseData,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const AddUpdateTKLMembers = (isUpdate, urlItem, payload) => {
  return async (dispatch) => {
    if (!isUpdate) {
      return await axios
        .post(`${URL_COHORT}/${urlItem}`, payload)
        .then((response) => {
          if (response.data) {
            if (isUpdate) {
              dispatch({
                type: types.API_SUCCESS,
                payload: "Details Updated",
              });
            } else {
              dispatch({
                type: types.API_SUCCESS,
                payload: "Member Created",
              });
            }
            dispatch({ type: types.IS_RELOAD, payload: true });

            // dispatch({ type: types.HIDE_LOADER });
            return Promise.resolve(true);
          }
        })
        .catch((error) => {
          console.log(".....error", error);
          // dispatch({ type: types.HIDE_LOADER });
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject(false);
        });
    } else {
      return await axios
        .put(`${URL_COHORT}/${urlItem}`, payload)
        .then((response) => {
          if (response.data) {
            dispatch({ type: types.IS_RELOAD, payload: true });

            // dispatch({ type: types.HIDE_LOADER });
            return Promise.resolve(true);
          }
        })
        .catch((error) => {
          console.log(".....error", error);

          // dispatch({ type: types.HIDE_LOADER });
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject(false);
        });
    }
  };
};

//here
export const resendInformationSheet = (courseSlug, userSlug) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_LOADER });
    return await axios
      .get(
        `${URL_COHORT}/send-information-sheet?courseSlug=${courseSlug}&userSlug=${userSlug}`
      )
      .then((response) => {
        if (response.data) {
          // dispatch({ type: types.IS_RELOAD, payload: true });
          let message = showFirstValidationErrorFromAPI([
            [response?.data?.message],
          ]);
          dispatch({
            type: types.API_SUCCESS,
            payload: message,
          });
          dispatch({ type: types.HIDE_MODAL_LOADER });
          return Promise.resolve(true);
        }
      })
      .catch((error) => {
        dispatch({ type: types.HIDE_MODAL_LOADER });
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject(false);
      });
  };
};

export const resendWelcomeLetter = (courseSlug, userSlug) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_LOADER });
    return await axios
      .get(
        `${URL_COHORT}/send-welcome-letter?courseSlug=${courseSlug}&userSlug=${userSlug}`
      )
      .then((response) => {
        if (response.data) {
          let message = showFirstValidationErrorFromAPI([
            [response?.data?.message],
          ]);
          dispatch({
            type: types.API_SUCCESS,
            payload: message,
          });
          dispatch({ type: types.HIDE_MODAL_LOADER });
          return Promise.resolve(true);
        }
      })
      .catch((error) => {
        dispatch({ type: types.HIDE_MODAL_LOADER });
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject(false);
      });
  };
};

export const generateComplitionCertificate = (courseSlug, userSlug) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_LOADER });
    let payload = { cohortCourseSlug: courseSlug, userSlug: userSlug };
    return await axios
      .put(`${URL_COHORT}/reset-certificate`, payload)
      .then((response) => {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
          let message = showFirstValidationErrorFromAPI([
            [response?.data?.response],
          ]);
          dispatch({
            type: types.API_SUCCESS,
            payload: message,
          });
          dispatch({ type: types.HIDE_MODAL_LOADER });
          return Promise.resolve(true);
        }
      })
      .catch((error) => {
        dispatch({ type: types.HIDE_MODAL_LOADER });
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject(false);
      });
  };
};

export const UpdateCourseRegistrations = (
  isUpdate,
  urlItem,
  payload,
  pageNumber
) => {
  return async (dispatch) => {
    return await axios
      .put(`${URL_COHORT}/${urlItem}`, payload)
      .then((response) => {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
          dispatch(getuserManagementDataHandler(pageNumber));
          dispatch(getuserFinancialDataHandler(pageNumber));
          return Promise.resolve(true);
        }
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject(false);
      });
  };
};

export const assignUserToCourse = (data) => {
  return async (dispatch) => {
    return await axios
      .post(`${URL_COHORT}/add-user-to-existing-course`, data)
      .then(() => {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Course Assigned",
        });
        return Promise.resolve(true);
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
          return Promise.resolve(false);
        }
      });
  };
};

export const assignUserToMicroCourse = (data) => {
  return async (dispatch) => {
    return await axios
      .post(`${URL_COHORT}/add-user-to-existing-course-program`, data)
      .then(() => {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Course Assigned",
          pathName: "/",
        });
        return Promise.resolve(true);
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
          return Promise.resolve(false);
        }
      });
  };
};

export const assignUserToKidVisionCourse = (data) => {
  return async (dispatch) => {
    return await axios
      .post(`${URL_COHORT}/add-user-to-existing-kidvision-program`, data)
      .then(() => {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Course Assigned",
          pathName: "/",
        });
        return Promise.resolve(true);
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
          return Promise.resolve(false);
        }
      });
  };
};

export const updatePathWrightData = (
  isUpdate,
  urlItem,
  payload,
  pageNumber = 1
) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_LOADER });
    return await axios
      .put(`${URL_COHORT}/${urlItem}`, payload)
      .then((response) => {
        if (response.data) {
          // dispatch({ type: types.IS_RELOAD, payload: true });
          dispatch({ type: types.HIDE_MODAL_LOADER });
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.message,
          });
          dispatch(getPathRightData(pageNumber || 1));
          return Promise.resolve(true);
        }
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          dispatch({ type: types.HIDE_MODAL_LOADER });
          dispatch({
            type: types.API_ERROR,
            payload: error.response?.data?.message,
          });
        }
        return Promise.reject(false);
      });
  };
};

export const downloadParticipantGuideInstructor = (
  isGenerate,
  cohortCourseSlug
) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    return await axios
      .get(
        `${URL_COHORT}/download-participant-guide-instructor?cohortDisplayId=${cohortCourseSlug}&isGenerate=${isGenerate}`,
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        if (response.data) {
          dispatch({ type: types.HIDE_LOADER });
          if (!isGenerate) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // printJS(url);
            const link = document.createElement("a");
            link.href = url;
            let fileName = `Blank Participant Report ${cohortCourseSlug}.pdf`;

            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
          }
          if (isGenerate) {
            dispatch({
              type: types.API_SUCCESS,
              payload: "Successfully Generated Blank Participant Guide",
            });
          }

          return Promise.resolve(true);
        }
      })
      .catch((error) => {
        if (error.response?.data?.response) {
          dispatch({ type: types.HIDE_LOADER });
          dispatch({
            type: types.API_ERROR,
            payload: error.response?.data?.response,
          });
        }
        return Promise.reject(false);
      });
  };
};
