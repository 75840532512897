import React, { useState, useEffect } from "react";

const ComponentContentLoader = React.memo(function ComponentContentLoader() {
  const [animation, setAnimation] = useState("");
  useEffect(() => {
    setAnimation("active");
    return () => {
      setAnimation("");
    };
  });
  return (
    <div className={`content-loader-comp ${animation}`}>
      <div
        className="d-inline-block"
        style={{ width: "4rem", height: "4rem", zIndex: "999999" }}
      >
        <div className="tkl-spinner"></div>
      </div>
    </div>
  );
});

export default ComponentContentLoader;
