import { types } from "./types";
import { CourseDetails } from "../components/Models/course-model";
import update from "react-addons-update";
import { current } from "@reduxjs/toolkit";
const initialState = {
  application: new CourseDetails(),
  showText: false,
  resetClicked: false,
  isLoading: false,
  showComments: false,
  showCommentsIndex: {},
  discussionForumComments: [],
  commentSlugUpdate: "",
  userMode: "edit",
  totalQuestionLength: 0,
  activityCompleted: false,
  modulesDataFragments: [],
  cohortContentPublishText: false,
  activitySettingsSaveText: false,
  initialActivity: "",
  lastActivity: "",
  nextActivityLabel: "",
  totalSectionLength: "",
  currentActivityIndex: "",
  updatePointsEarned: "",
};

export const fragments = (state = initialState, action: any) => {
  switch (action.type) {
    case types.UPDATE_POINTS_EARNED:
      return {
        ...state,
        updatePointsEarned: Math.random(),
      };
    case types.RADIO_GROUP_FORM_DATA:
      let newArray = [...state.application.Fragments];
      let currentObject = {};
      newArray?.[action.itemIndex] &&
        Object.keys(newArray?.[action.itemIndex])?.forEach((field) => {
          if (field === action.label) {
            currentObject[field] = action.payload;
          } else {
            currentObject[field] = newArray[action.itemIndex][field];
          }
        });
      return {
        ...state,
        isLoading: action.isLoading ? action.isLoading : false,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            currentObject,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };

    case types.CHECKBOX_FORM_DATA: {
      let fragmentsTemp = [...state.application.Fragments];
      let currentObject = {};
      fragmentsTemp?.[action.itemIndex] &&
        Object.keys(fragmentsTemp[action.itemIndex]).forEach((field) => {
          if (field === action.label) {
            currentObject[field] = action.payload;
          } else {
            currentObject[field] = fragmentsTemp[action.itemIndex][field];
          }
        });

      return {
        ...state,
        isLoading: action.isLoading ? action.isLoading : false,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            currentObject,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };
    }

    case types.ADD_RADIO_OPTIONS:
      let newArrayOptions = [...state.application.Fragments];
      let currentObjectRadioOptions = {};
      newArrayOptions?.[action.itemIndex] &&
        Object.keys(newArrayOptions[action.itemIndex]).forEach((field) => {
          if (field === action.label) {
            currentObjectRadioOptions[field] =
              newArrayOptions[action.itemIndex][field].push("");
          } else {
            currentObjectRadioOptions[field] =
              newArrayOptions[action.itemIndex][field];
          }
        });
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments],
        },
      };

    case types.SHOW_COMMENT_INDEX:
      return {
        ...state,
        showCommentsIndex: action.payload,
      };

    case types.CHANGE_USER_MODE:
      return {
        ...state,
        userMode: action.payload,
      };

    case types.CHANGE_MODE:
      return {
        ...state,
        mode: action.payload,
      };

    case types.UPDATE_FRAGMENT_POSITION:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case types.FRAGMENTS_SUBMIT_BUTTON_CLICKED:
      //  //console.log(".....action", action);
      return {
        ...state,
        showText: action.payload,
      };

    case types.FRAGMENTS_SHOW_COMMENTS:
      return {
        ...state,
        showComments: action.payload,
      };

    case types.FRAMENT_UPDATE:
      return {
        ...state,
        isLoading: action.isLoading,
      };

    case types.FRAGMENTS_RESET_BUTTON_CLICKED:
      //  alert(action.payload);
      return {
        ...state,
        resetClicked: action.payload,
      };
    case types.DELETE_RADIOGROUP_OPTION:
      let newArrayOptionsDelete = [...state.application.Fragments];
      let currentObjectRadioOptionsDelete = {};
      newArrayOptionsDelete?.[action.itemIndex] &&
        Object.keys(newArrayOptionsDelete[action.itemIndex]).forEach(
          (field) => {
            if (field === action.label) {
              currentObjectRadioOptionsDelete[field] = action.payload;
            } else {
              currentObjectRadioOptionsDelete[field] =
                newArrayOptionsDelete[action.itemIndex][field];
            }
          }
        );
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments],
        },
      };

    case types.ADD_NEW_RADIO_GROUP:
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments, ...action.payload],
        },
      };
    case types.ADD_NEW_RICHTEXT_GROUP:
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments, ...action.payload],
        },
      };
    case types.DELETE_SELECTED_FRAGMENT:
      return {
        ...state,
        application: {
          ...state.application,
          radioGroup: action.payload,
        },
      };
    case types.GET_SELECTED_FRAGMENTS:
      return {
        ...state,
        isLoading: false,
        application: {
          ...state.application,
          Fragments: action.payload,
        },
      };
    case types.ADD_QUESTION_BOX:
      //console.log("..action.payload", ...action.payload);
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments, ...action.payload],
        },
      };

    case types.QUESTIONBOX_FORM_DATA:
      let QuestionBoxData = [...state.application.Fragments];
      let QuestionBoxObject = {};
      QuestionBoxData?.[action?.itemIndex] &&
        Object.keys(QuestionBoxData?.[action?.itemIndex]).forEach((field) => {
          if (field === action.label) {
            QuestionBoxObject[field] = action.payload;
          } else {
            QuestionBoxObject[field] = QuestionBoxData[action.itemIndex][field];
          }
        });
      return {
        ...state,
        isLoading: action.isLoading ? action.isLoading : false,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            QuestionBoxObject,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };
    case types.ADD_QUESTION_INPUT:
      let questionboxdataInputadd = [...state.application.Fragments];
      let questioninputdata = {};
      questionboxdataInputadd?.[action.itemIndex] &&
        Object.keys(questionboxdataInputadd[action.itemIndex]).forEach(
          (field) => {
            if (field === action.label) {
              if (action.label !== "answerSubmitted") {
                questioninputdata[field] =
                  questionboxdataInputadd[action.itemIndex][field].push("");
              } else {
                questioninputdata[field] =
                  questionboxdataInputadd[action.itemIndex][field].push("");
              }
            } else {
              questioninputdata[field] =
                questionboxdataInputadd[action.itemIndex][field];
            }
          }
        );
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments],
        },
      };
    case types.DEELTE_QUESTION_INPUT:
      let Questioninputdatadelete = [...state.application.Fragments];
      let questionboxOptionsDelete = {};
      Questioninputdatadelete?.[action.itemIndex] &&
        Object.keys(Questioninputdatadelete[action.itemIndex]).forEach(
          (field) => {
            if (field === action.label) {
              questionboxOptionsDelete[field] = action.payload;
            } else {
              questionboxOptionsDelete[field] =
                Questioninputdatadelete[action.itemIndex][field];
            }
          }
        );
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments],
        },
      };

    case types.ADD_INPUT_BUTTON:
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments, ...action.payload],
        },
      };

    case types.INPUT_BUTTON_FORM_DATA:
      let inputButtonFormData = [...state.application.Fragments];
      let inputButtonObject = {};
      inputButtonFormData?.[action.itemIndex] &&
        Object.keys(inputButtonFormData[action.itemIndex]).forEach((field) => {
          if (field === action.label) {
            inputButtonObject[field] = action.payload;
          } else {
            inputButtonObject[field] =
              inputButtonFormData[action.itemIndex][field];
          }
        });
      return {
        ...state,
        isLoading: action.isLoading ? action.isLoading : false,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            inputButtonObject,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };

    case types.ADD_DISCUSSION_FORM:
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments, ...action.payload],
        },
      };

    case types.ADD_SELECTBOX_CHOICE:
      let exisiting_select_box = [...state.application.Fragments];
      let currentObjectselectOptions = {};
      exisiting_select_box?.[action.itemIndex] &&
        Object.keys(exisiting_select_box[action.itemIndex]).forEach((field) => {
          if (field === action.label) {
            currentObjectselectOptions[field] = exisiting_select_box[
              action.itemIndex
            ][field].push(action.payload);
          } else {
            currentObjectselectOptions[field] =
              exisiting_select_box[action.itemIndex][field];
          }
        });
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments],
        },
      };

    case types.ADD_INPUT_ELEMENT:
      let exisiting_select_box_input_options = [...state.application.Fragments];
      let currentObjectselectInputOptions = {};
      exisiting_select_box_input_options?.[action.itemIndex] &&
        Object.keys(
          exisiting_select_box_input_options[action.itemIndex]
        ).forEach((field) => {
          if (field === action.label) {
            currentObjectselectInputOptions[field] =
              exisiting_select_box_input_options[action.itemIndex][field][
                action.optionIndex
              ]["options"].push(action.payload);
          } else {
            currentObjectselectInputOptions[field] =
              exisiting_select_box_input_options[action.itemIndex][field];
          }
        });
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments],
        },
      };
    case types.SELECT_BOX_OPTIONS_HANDLER:
      let select_box_options_handler = [...state.application.Fragments];
      let currentObjectselectInputOptionsHandler = {};
      select_box_options_handler?.[action.itemIndex] &&
        Object.keys(select_box_options_handler[action.itemIndex]).forEach(
          (field) => {
            if (field === action.label) {
              if (action.conditionLabel === "options") {
                select_box_options_handler[action.itemIndex][field][
                  action.index
                ]["options"][action.optionIndex] = action.payload;
                currentObjectselectInputOptionsHandler[field] =
                  select_box_options_handler[action.itemIndex][field];
              } else {
                select_box_options_handler[action.itemIndex][field][
                  action.index
                ]["choiceText"] = action.payload;
                currentObjectselectInputOptionsHandler[field] =
                  select_box_options_handler[action.itemIndex][field];
              }
            } else {
              currentObjectselectInputOptionsHandler[field] =
                select_box_options_handler[action.itemIndex][field];
            }
          }
        );
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            currentObjectselectInputOptionsHandler,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };

    case types.SELECT_BOX_CORRECT_ANSWER_INDEX:
      let newArray_Fragments = [...state.application.Fragments];
      let objectTobeChanged = {};
      newArray_Fragments?.[action.itemIndex] &&
        Object.keys(newArray_Fragments[action.itemIndex]).forEach(
          (arrayItem, index) => {
            if (action.label === arrayItem) {
              newArray_Fragments[action.itemIndex][arrayItem][action.index][
                "correctAnswerIndex"
              ] = action.payload;
              objectTobeChanged[arrayItem] =
                newArray_Fragments[action.itemIndex][arrayItem];
            } else {
              objectTobeChanged[arrayItem] =
                newArray_Fragments[action.itemIndex][arrayItem];
            }
          }
        );

      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            objectTobeChanged,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };

    case types.SELECT_BOX_OPTION_SELECTED:
      let newArray_Fragments_option_selected = [...state.application.Fragments];
      let objectTobeChanged_option_selected = {};
      newArray_Fragments_option_selected?.[action.itemIndex] &&
        Object.keys(
          newArray_Fragments_option_selected[action.itemIndex]
        ).forEach((arrayItem, index) => {
          if (action.label === arrayItem) {
            newArray_Fragments_option_selected[action.itemIndex][arrayItem][
              action.index
            ]["optionSelected"] = action.payload;
            objectTobeChanged_option_selected[arrayItem] =
              newArray_Fragments_option_selected[action.itemIndex][arrayItem];
          } else {
            objectTobeChanged_option_selected[arrayItem] =
              newArray_Fragments_option_selected[action.itemIndex][arrayItem];
          }
        });

      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            objectTobeChanged_option_selected,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };

    case types.ADD_INITIAL_COMMENTS_TO_DISCUSSION_FORUM:
      let discussionForumData = [...state.application.Fragments];
      let discussionForumInsertData = {};
      discussionForumData?.[action.itemIndex] &&
        Object.keys(discussionForumData[action.itemIndex]).forEach(
          (field, index) => {
            if (field === action.label) {
              if (action.sortData === "Newest first") {
                discussionForumInsertData[field] = discussionForumData[
                  action.itemIndex
                ][field].unshift(action.payload);
              } else {
                discussionForumInsertData[field] = discussionForumData[
                  action.itemIndex
                ][field].push(action.payload);
              }
            } else {
              discussionForumInsertData[field] =
                discussionForumData[action.itemIndex][field];
            }
          }
        );
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments],
        },
      };
    case types.TOTAL_COMMENTS_FOR_FORUM:
      let fragmentDataDiscussionForum = [...state.application.Fragments];
      let forumCommentsData = {};
      fragmentDataDiscussionForum?.[action.itemIndex] &&
        Object.keys(fragmentDataDiscussionForum[action.itemIndex]).forEach(
          (item, index) => {
            if (item === "discussionForumComments") {
              forumCommentsData[item] = action.payload;
            } else {
              forumCommentsData[item] =
                fragmentDataDiscussionForum[action.itemIndex][item];
            }
          }
        );
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            forumCommentsData,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };

    case types.COMMENT_SLUG_UPDATE:
      return {
        ...state,
        commentSlugUpdate: action.payload,
      };

    case types.ADD_FILES_TO_FILEUPLOAD_FRAGMENT:
      let fragmentsDatafileUpload = [...state.application.Fragments];
      let fileUpload = {};
      fragmentsDatafileUpload?.[action.itemIndex] &&
        Object.keys(fragmentsDatafileUpload[action.itemIndex]).forEach(
          (item, index) => {
            if (item === action.label) {
              if (action.nullFileUpload) {
                fileUpload[item] = action.payload;
                fragmentsDatafileUpload[action.itemIndex][item] =
                  action.payload;
              } else {
                fileUpload[item] = fragmentsDatafileUpload[action.itemIndex][
                  item
                ].push(action.payload);
              }
            } else
              fileUpload[item] =
                fragmentsDatafileUpload[action.itemIndex][item];
          }
        );
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments],
        },
      };
    case types.DELETE_UPLOADED_FILE:
      let fragmentsDatafileUpload_Delete = [...state.application.Fragments];
      let fileUploadDelete = {};
      fragmentsDatafileUpload_Delete?.[action.itemIndex] &&
        Object.keys(fragmentsDatafileUpload_Delete[action.itemIndex]).forEach(
          (item, index) => {
            if (item === action.label) {
              fileUploadDelete[item] = action.payload;
            } else
              fileUploadDelete[item] =
                fragmentsDatafileUpload_Delete[action.itemIndex][item];
          }
        );
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            fileUploadDelete,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };
    case types.TOTAL_QUESTION_FRAGMENTS:
      return {
        ...state,
        totalQuestionLength: action.payload,
      };

    case types.IMAGE_SLIDER_IMAGE_UPLOAD:
      let fragmentDataImageSlider = [...state.application.Fragments];
      let dataTobeAdded = {};
      fragmentDataImageSlider?.[action.itemIndex] &&
        Object.keys(fragmentDataImageSlider[action.itemIndex]).forEach(
          (item, index) => {
            if (item === action.label) {
              dataTobeAdded[item] =
                fragmentDataImageSlider[action.itemIndex][item];
              dataTobeAdded[item][action.sliderContentIndex][
                action.sliderData
              ] = action.payload;
            } else {
              dataTobeAdded[item] =
                fragmentDataImageSlider[action.itemIndex][item];
            }
          }
        );
      // //console.log("..dataTobeAdded", dataTobeAdded);
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            dataTobeAdded,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };
    case types.CLONE_FRAGMENT:
      return {
        ...state,
        application: {
          ...state.application,
          Fragments: [...state.application.Fragments, action.payload],
        },
      };

    case types.ACTIVITY_COMPLETED_CLICKED:
      return {
        ...state,
        activityCompleted: action.payload,
      };

    case types.ADD_MODULES_DATA_FRAGMENTS:
      return {
        ...state,
        modulesDataFragments: action.payload,
      };
    case types.COHORT_CONTENT_PUBLISH_TEXT:
      return {
        ...state,
        cohortContentPublishText: action.payload,
      };

    case types.SAVE_SETTINGS_ONSAVE_TEXT:
      return {
        ...state,
        activitySettingsSaveText: action.payload,
      };
    case types.INITIAL_ACTIVITY:
      return {
        ...state,
        initialActivity: action.payload,
      };
    case types.LAST_ACTIVITY:
      return {
        ...state,
        lastActivity: action.payload,
      };
    case types.NEXT_ACTIVITY_LABEL:
      return {
        ...state,
        nextActivityLabel: action.payload,
      };
    case types.TOTAL_SECTION_LENGTH:
      return {
        ...state,
        totalSectionLength: action.payload,
      };
    case types.CURRENT_ACTIVITY_INDEX:
      return {
        ...state,
        currentActivityIndex: action.payload,
      };

    default:
      return state;
  }
};
