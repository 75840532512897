import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../cohorts.scss";
const MultiSelectDropDown = (props) => {
  const [dropdown, setdropdown] = React.useState(false);
  const container = React.useRef(null);

  const useOutsideAlerter = (ref) => {
    React.useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          event.target?.id !== `${props?.id}_${props?.id}`
        ) {
          setdropdown(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(container);

  const onSelectChange = (event, status) => {
    if (event.target.value === 'Others' && event.target.checked) {
      setdropdown(false);

    }
    if (event.target.id !== "selectAll") {
      props.onSelectFieldChange(event, event.target.checked, status);
    }
  };
  const onSelectAllHandler = (event) => {
    props.onSelectFieldChange(event, event.target.checked, "selectAll");
  };

  const handleButtonClick = (event) => {
    if (event.currentTarget.id === props.id) {
      setdropdown(!dropdown);
    }
    event.preventDefault();
  };

  return (
    <>
      <div
        className={`course_programs course_items_flex cohorts-multiselect ${dropdown && "add-padding-to-container"
          }`}
      >
        <label
          style={{ width: `${props.width}` }}
          className="form-label"
          htmlFor="Course_Programs"
        >
          <strong>{props.label}</strong>
        </label>

        <button
          disabled={props.disabledAlways ? true : props.statesboolean ? false : true}
          id={props.statesboolean && props.id}
          className={`input_field input_field_multiSelect ${props?.isLarge ? "form-control" : "form-control-sm"
            } form-control form-control rounded-pill ${!dropdown
              ? `input-element input-element-multiselect-nodropdown ${!props.widthStyle && `input-field-box-style`}`
              : `input-element-multi-select-dropdown`
            } ${props.isProfilePage && 'input_field_profile_page'}`}
          onClick={handleButtonClick}
        >
          <span
            id={`${props.id}_${props.id}`}
            className="input-element-multiselect-nodropdown"
            style={{
              float: "left",
              display: "inline-block",
              width: "calc(90%)",
              textAlign: "initial",
              //marginTop: "0.25em",
              marginTop: "0.15em",

              // //  marginLeft: '1.2em'

            }}
          >
            {props.data && props.data.length > 0 ? (
              <>
                {props.data.map((dataItem, index) => {
                  return (
                    <span key={index}>
                      {index === props.data.length - 1 ? (
                        <>{`${dataItem.name} `}</>
                      ) : (
                        <>{`${dataItem.name}, `}</>
                      )}
                    </span>
                  );
                })}
              </>
            ) : (
              <>Select {props.label}</>
            )}
          </span>
          <span class="material-icons keyboard_arrow_style">
            keyboard_arrow_down
          </span>
        </button>
        <div ref={container} className="input-element-multiSelect">
          {dropdown && (
            <>
              <div className="search-results-div" style={{ top: "4px" }}>
                {!props?.showSelectAll &&
                  <>
                    {props?.options && props?.options.length > 0 ? (
                      <>
                        <div
                          className="py-2 pr-2 options-padding"
                          style={{
                            display: " flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div className="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              onClick={(event) => onSelectAllHandler(event)}
                              className="custom-control-input"
                              checked={
                                props.data &&
                                props.options &&
                                props.data.length === props.options.length &&
                                true
                              }
                              id="selectAll"
                              // value={"select all"}
                              disabled={props.disableOptions ? true : false}
                            />
                            <label
                              className="custom-control-label"
                              style={{
                                padding: "unset",
                                paddinBottom: "1em",
                                cursor: "pointer",
                              }}
                              htmlFor="selectAll"
                            >
                              Select all
                            </label>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="py-2 pr-2 options-padding"
                          style={{
                            display: " flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <p
                            style={{
                              padding: "0.5em 0em",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              fontSize: "14px",
                            }}
                          >
                            No Data Available
                          </p>
                        </div>
                      </>
                    )}
                  </>
                }
                {props.options.map((search, index) => {
                  return (
                    <>
                      <div
                        className="py-2 pr-2 options-padding"
                        key={index}
                        style={{
                          display: " flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div className="custom-control custom-checkbox">
                          <input
                            name={search.name}
                            type="checkbox"
                            id={search.slug}
                            onClick={(event) =>
                              onSelectChange(event, search.status)
                            }
                            className="custom-control-input"
                            checked={
                              props.data.some(
                                (element) => element.name === search.name
                              )
                                ? true
                                : false
                            }
                            value={search.name}

                            disabled={props.disableOptions ? true : false}
                          />
                          <label
                            title={search.name}
                            htmlFor={search.slug}
                            className="custom-control-label cohorts-page-styles-label"
                          >
                            {search.name}
                          </label>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MultiSelectDropDown));
