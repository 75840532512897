import {
  debounce,
  getLocalDateFromUTC,
} from "components/utility/ReUsableFunctions";
import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Row,
  Nav,
  Button,
  ListGroup,
  Modal,
  ListGroupItem,
  Pagination,
} from "react-bootstrap";
import { BiSearch } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import TKLModal from "shared-components/model/TKLModal";
import {
  acceptRejectGroupIntivation,
  deleteGroup,
  deleteResource,
  getResources,
  recommandResource,
} from "../../../actions/CommunityActions/CommunityAction";
import { URL } from "../../../api/tklEnvironments";
import { SYSTEM_ADMIN } from "../../../constants.js/RoleNames";
import ComponentAvatar from "../../../shared-components/avatar";
import AddResource from "./AddResource";

function ListOfResources(props) {
  const dispatch = useDispatch();

  const { resourses, isResourseReload } = useSelector(
    (state) => state.community
  );
  const defaultImageURL = `${process.env.PUBLIC_URL + "/images.png"}`;

  const { apiSuccessMessage, apiErrorMessage, redirectToPath } = useSelector(
    (state) => state.coursepath
  );
  const userData = useSelector((state) => state.users.userData);
  const [eventKey, setEventKey] = useState("groups");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteGrp, setDeleteGroup] = useState({});

  const [currentResourseToEdit, setCurrentResourseToEdit] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [paginationItems, setPaginationItems] = useState([]);
  const recordsPerPage = 8;

  const [searchTitle, setSearchTitle] = useState("");

  const handleCloseEditPopup = () => {
    setShowEditPopup(false);
  };

  const handleShowEditPopup = () => {
    setShowEditPopup(true);
  };

  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
  };
  const handleShowDeletePopup = () => {
    setShowDeletePopup(true);
  };

  useEffect(() => {
    if (isResourseReload) {
      dispatch(getResources(1, false, recordsPerPage));
    }
  }, [isResourseReload]);

  useEffect(() => {
    dispatch(getResources(1, false, recordsPerPage));
  }, []);

  useEffect(() => {
    setPaginationItems(
      [...Array(resourses?.last_page).keys()].map((item, index) => {
        return (
          <Pagination.Item
            active={resourses?.current_page == index + 1}
            onClick={() => {
              dispatch(getResources(index + 1, false, recordsPerPage));
            }}
          >
            {index + 1}
          </Pagination.Item>
        );
      })
    );
  }, [resourses]);

  const getGroups = () => {
    return (resourses?.data || [])?.map((group) => {
      return (
        <div>
          <ListGroup.Item style={{ minWidth: "680px" }}>
            <Row>
              <Col
                md="3"
                lg="2"
                sm="3"
                xs="3"
                xl="2"
                className="p-2 d-flex align-items-center"
              >
                <div className="d-flex align-items-center">
                  <div
                    className="d-inline-block mr-2"
                    style={{ width: "2.5rem" }}
                  >
                    {group.type === "course" ? (
                      <ComponentAvatar
                        type="user"
                        data={{
                          img: {
                            md: defaultImageURL,
                            md2: defaultImageURL,
                            xs: defaultImageURL,
                            xs2: defaultImageURL,
                          },
                        }}
                      />
                    ) : (
                      <ComponentAvatar
                        type="resource"
                        data={{
                          type: group.type,
                        }}
                      />
                    )}
                  </div>
                  <b>{group.type}</b>
                </div>
              </Col>
              <Col
                md={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "4" : "5"}`}
                xs={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "4" : "5"}`}
                lg={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "5" : "6"}`}
                sm={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "4" : "5"}`}
                xl={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "5" : "6"}`}
                className="p-2 d-flex align-items-center justify-content-flex-start"
                title={group?.title}
              >
                <a
                  href={group?.redirectionUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    maxWidth: "40em",
                  }}
                >
                  {group?.title}
                </a>
              </Col>
              <Col
                md={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                lg={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                sm={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                xs={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                xl={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                className="p-2 d-flex align-items-center"
              >
                {getLocalDateFromUTC(group?.createdAt)}
              </Col>

              {userData?.roles?.includes(SYSTEM_ADMIN) && (
                <Col
                  md="3"
                  lg="3"
                  sm="3"
                  xs="3"
                  xl="3"
                  className="p-2 d-flex align-items-center  flex-direction-row justify-content-center"
                >
                  {/* <Button
                    disabled={group?.isRecommended}
                    className="btn btn-primary rounded-pill btn-width m-1"
                    size={"sm"}
                    style={{
                      backgroundColor: "#ab4bc6",
                      border: "none",
                      minWidth: "110px",
                    }}
                    onClick={() => {
                      dispatch(recommandResource(group?.communityResourceId));

                      setTimeout(() => {
                        props?.handleShowGroup();
                      }, 500);
                    }}
                  >
                    {group?.isRecommended ? "Recommended" : "Recommend"}
                  </Button> */}
                  <Button
                    className="btn btn-primary rounded-pill btn-width m-1"
                    size={"sm"}
                    onClick={() => {
                      handleShowEditPopup();
                      // props?.setGroupView("resource");
                      setCurrentResourseToEdit(group);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    className="btn btn-danger rounded-pill btn-width m-1"
                    size={"sm"}
                    onClick={() => {
                      setDeleteGroup(group);
                      handleShowDeletePopup();
                    }}
                  >
                    Delete
                  </Button>
                </Col>
              )}
            </Row>
          </ListGroup.Item>
        </div>
      );
    });
  };

  const getuuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };

  const handleDelete = (group) => {
    dispatch(deleteResource(group?.communityResourceId));
    handleCloseDeletePopup();
  };

  const handleSearchTitleChange = (e) => {
    if (e.target.value == "") {
      dispatch(getResources(1, false, 8, "", ""));
    }
    setSearchTitle(e.target.value);
  };

  return (
    <div className="">
      <div
        className="bg-light d-flex  container_padding p-1 pb-6"
        style={{
          flexDirection: "column",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <h1 className=" pl-4 pt-4 pb-4">
          <em>TKL Recommended Resources</em>
        </h1>
        <div className="p-2 d-flex align-items-center justify-content-center">
          {" "}
          <div
            className="d-flex align-items-center justify-content-center m-2"
            style={{
              borderRadius: "25px",
              maxWidth: "350px",
              outline: "1px solid #18998e",
            }}
          >
            <input
              type="text"
              placeholder="Search Title"
              className=""
              style={{
                padding: "10px",

                border: "none",
                marginRight: "0px !important",
                minWidth: "300px",
                outline: "none",
                paddingRight: "5px !important",
              }}
              value={searchTitle}
              onChange={handleSearchTitleChange}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  dispatch(getResources(1, false, 8, "", searchTitle));
                }
              }}
            />
            <span
              className="material-icons pr-2"
              style={{
                fontSize: "26px",
                color: "#18998e",
                cursor: "pointer",
              }}
              onClick={() => {
                console.log("search clicked");
                dispatch(getResources(1, false, 8, "", searchTitle));
              }}
            >
              search
            </span>
          </div>
        </div>
        {/* <hr></hr> */}
        <ListGroup style={{ overflow: "auto" }}>
          <div>
            <ListGroup.Item variant="primary" style={{ minWidth: "680px" }}>
              <Row>
                <Col md="3" lg="2" sm="3" xs="3" xl="2">
                  <strong className="d-flex align-items-center justify-content-center">
                    Resource Type
                  </strong>
                </Col>
                <Col
                  md={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "4" : "5"}`}
                  xs={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "4" : "5"}`}
                  lg={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "5" : "6"}`}
                  sm={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "4" : "5"}`}
                  xl={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "5" : "6"}`}
                >
                  <strong className="d-flex align-items-center justify-content-center">
                    Title
                  </strong>
                </Col>
                <Col
                  md={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                  lg={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                  sm={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                  xs={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                  xl={`${userData?.roles?.includes(SYSTEM_ADMIN) ? "2" : "3"}`}
                >
                  <strong className="d-flex align-items-center justify-content-center">
                    Added On
                  </strong>
                </Col>
                <Col></Col>
              </Row>
            </ListGroup.Item>
          </div>

          {getGroups()}
        </ListGroup>
        <div className="d-flex align-items-center justify-content-center pt-5 pr-4">
          <Pagination style={{ maxWidth: "350px", overflow: "auto" }} size="sm">
            {paginationItems}
          </Pagination>
        </div>
      </div>
      {/* edit dialoge */}
      <Modal
        size="lg"
        show={showEditPopup && currentResourseToEdit}
        onHide={handleCloseEditPopup}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Resource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddResource
            handleCloseGroup={handleCloseEditPopup}
            editMode={true}
            currentResourse={currentResourseToEdit}
          />
        </Modal.Body>
        {/* <Modal.Footer>
          <Button
            className="btn rounded-pill btn-width"
            size={"sm"}
            style={{ backgroundColor: "#40403f" }}
            onClick={handleCloseEditPopup}
          >
            Close
          </Button>
          <Button
            className="btn btn-primary rounded-pill btn-width"
            size={"sm"}
            onClick={() => handleCloseEditPopup()}
          >
            Done
          </Button>
        </Modal.Footer> */}
      </Modal>

      {/* delete dialoge */}
      <Modal show={showDeletePopup} onHide={handleCloseDeletePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete Resource ?</Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary rounded-pill btn-width"
            size={"sm"}
            onClick={handleCloseDeletePopup}
          >
            Close
          </Button>
          <Button
            className="btn btn-danger rounded-pill btn-width"
            size={"sm"}
            onClick={() => handleDelete(deleteGrp)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {apiSuccessMessage && (
        <TKLModal
          showDialog={true}
          msgType={"success"}
          message={apiSuccessMessage}
          type="success"
          redirectToPath={redirectToPath}
          primaryBtnColor={"#0088CC"}
        />
      )}
      {apiErrorMessage && (
        <TKLModal
          showDialog={true}
          msgType={"warning"}
          message={apiErrorMessage}
          type="error"
        />
      )}
    </div>
  );
}

export default ListOfResources;
