import React from 'react';

import './_index.scss';

const ComponentOffcanvas = React.memo(function ComponentOffcanvas(props) {
  const isToggled = props.isToggled || false;
  const octitle = props.title || '';
  const toggleOffcanvas = props.toggleOffcanvas;
  const closeOffcanvas = () => {
    if (toggleOffcanvas) {
      toggleOffcanvas(false);
    }
  };
  return (<>
    <aside className={`offcanvas-comp ${isToggled ? 'active' : ''}`}>
      <div className="oc-header p-2 p-md-3 pr-5 pr-md-5">
        {octitle ? <h4 className="m-0">{octitle}</h4> : <></>}
        <button type="button" onClick={closeOffcanvas} className="offcanvas-close">
          <i className="material-icons">close</i>
        </button>
      </div>
      <div className="oc-content">
        {props.children}
      </div>
    </aside>
    <div className={`offcanvas-mask ${isToggled ? 'active' : ''}`} onClick={closeOffcanvas}></div>
  </>)
});

export default ComponentOffcanvas;