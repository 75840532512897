/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";
import ComponentInstructionalCoachTopcard from "./../../components/topcard/index-instructionalcoach";
import ComponentDashboardTile from "./../../components/dashboard-tile/index";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getProfileData } from "../../actions/UserActions/ProfileActions";
function ContentAuthor({ props, userData, getProfileData }) {
  const [list, setList] = useState([
    <ComponentDashboardTile
      type={"authorcourses"}
      title={"My Courses"}
      role="contentAuthor"
    />,
    <ComponentDashboardTile
      type={"approvedCourses"}
      title={"Developed Courses"}
      role="contentAuthor"
    />,

    // <ComponentDashboardTile type={'d'} />,
    // <ComponentDashboardTile type={'c'} />,
    // <ComponentDashboardTile type={'g'} />
  ]);
  useEffect(() => {
    window.scrollTo(0, 0);
    getProfileData();
  }, []);
  return (
    <>
      <main className="pg-main pb-3 pb-md-5">
        <div className="px-2 px-md-4 profile-banner-styles">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <ComponentInstructionalCoachTopcard
                  userData={userData}
                  user={"CONTENT AUTHOR"}
                />
              </div>
            </div>
            <div className="row">
              <div className="col p-0">
                <section className="dashboard-container">
                  <ReactSortable
                    // list={list}
                    list={list?.map((x) => ({ ...x, chosen: true }))}
                    setList={setList}
                    handle=".dash-t-move"
                    ghostClass="sortable-ghost" // Class name for the drop placeholder
                    chosenClass="sortable-chosen" // Class name for the chosen item
                    dragClass="sortable-drag" // Class name for the dragging item
                  >
                    {/* className="col-xxl-6 col-xl-12 mb-3" */}
                    {list.map((item, index) => (
                      <div
                        className="col-xl-6 mb-3"
                        key={`ic_sortable_${index}`}
                      >
                        {item}
                      </div>
                    ))}
                  </ReactSortable>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    userData: state.users.userData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfileData: () => dispatch(getProfileData()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContentAuthor));
