import axios from "axios";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import { types } from "../../reducers/types";
import { store } from "../../App";
import {
  prepareErrorMsg,
  showFirstValidationErrorFromAPI,
  hideLoader,
} from "../../components/utility/ReUsableFunctions";
import {
  prepareDataFromApiToStore,
  prepareDataFromApiToStoreBundles,
  prepareDataFromApiToStoreBundleCodes,
} from "./CodeActions";
import { updateTilePosition } from "../DashboardActions/InstructionalCoachActions";

export const getAllUsers = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    // !userData?.searchInput
    if (true) {
      dispatch(
        sortBasedonSortOrder(
          "get-all-users",
          1,
          "user",
          userData?.sortFieldType?.slug || "createdAt",
          userData?.sortOrder === "Ascending" ? "ASC" : "DESC",
          "",
          "",
          false,
          page || 1
        )
      );
    } else {
      axios
        .get(`${URL}/get-all-users?page=${page}`)
        .then(function (response) {
          if (response?.data?.response?.data) {
            dispatch({ type: types.IS_LOADING, payload: false });
            dispatch({ type: types.IS_RELOAD, payload: false });
            dispatch({
              type: types.GET_ALL_USERS_MASTER_DATA,
              payload: response?.data?.response,
            });
          }
        })
        .catch(function (error) {
          console.error("..error", error);
        });
    }
  };
};

export const updateUserRoleStatus = (payload, page, isFromMasterData) => {
  return async (dispatch) => {
    await axios
      .put(`${URL}/update-user-by-admin`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch(updateTilePosition(null, payload?.slug));
          dispatch({
            type: types.API_SUCCESS,
            payload: "User Updated Successfully",
          });
          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: true,
          });
          dispatch({ type: types.IS_RELOAD, payload: true });
          page > 0 && dispatch(getAllUsers(page));
          if (isFromMasterData) {
            return Promise.resolve();
          }
        }
      })
      .catch(function (error) {
        if (isFromMasterData) {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject();
        } else {
          dispatch({
            type: types.API_ERROR,
            payload: "Error while updating User Roles",
            // payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const updateUserRole = (payload, page, isFromMasterData) => {
  return async (dispatch) => {
    await axios
      .put(`${URL}/update-user-by-adminrole`, payload)
      .then(function (response) {
        if (response.data) {
          // dispatch(updateTilePosition(null, payload?.slug));
          dispatch({
            type: types.API_SUCCESS,
            payload: "User Updated Successfully",
          });
          dispatch({
            type: types.IS_RELOAD_TKL,
            payload: true,
          });
          dispatch({ type: types.IS_RELOAD, payload: true });
          page > 0 && dispatch(getAllUsers(page));
          if (isFromMasterData) {
            return Promise.resolve();
          }
        }
      })
      .catch(function (error) {
        if (isFromMasterData) {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject();
        } else {
          dispatch({
            type: types.API_ERROR,
            payload: "Error while updating User Roles",
            // payload: prepareErrorMsg(error.response),
          });
        }
      });
  };
};

export const addUser = (payload, isFromMasterData) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let urlItem = isFromMasterData ? "add-user-manually" : "add-user-to-school";
    await axios
      .post(`${URL}/${urlItem}`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
          hideLoader(initialTime);
        }
        if (isFromMasterData) {
          return Promise.resolve();
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        hideLoader(initialTime);
        if (isFromMasterData) {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject();
        }
      });
  };
};

export const createTempPassword = (payload, isFromMasterData) => {
  return async (dispatch) => {
    await axios
      .post(`${URL}/create-temp-password/${payload}`)
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.GENERATE_PASSWORD,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        if (isFromMasterData) {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject();
        }
      });
  };
};
export const searchBasedOnFieldTypeAndValue = (
  URLPoint,
  isPaginated,
  searchType,

  searchField,
  searchValue,

  isCohort,
  pageNumberToSearch,

  isdropdownSearch,
  searchBy, //instead of searchType

  isAppendPreviousData,
  sortByItem,
  setRowData,
  getPaginationData,
  setSearchPagination
) => {
  // &searchType=${searchType}
  let permissionUrl= `&permissions[]=${localStorage.getItem("masterRadioValue")}`;
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    axios
      .get(
        `${isCohort ? URL_COHORT : URL}/${URLPoint}?isPaginated=${isPaginated}&page=${pageNumberToSearch ? pageNumberToSearch : 1}&
        ${isdropdownSearch ? `${searchField}${searchValue}` : `searchKey=${searchField}&searchValue=${searchValue}`
        }&sortBy=${userData?.sortFieldType?.slug || sortByItem || searchField || ""
        }&orderBy=${userData?.sortOrder === "Descending" ? "DESC" : "ASC"
        }${localStorage.getItem("masterRadioValue") ? permissionUrl : ""}`
      )
      .then((response) => {
        switch (searchType) {
          case "user":
            if(getPaginationData){
              getPaginationData(response?.data?.response?.last_page);
              setRowData(response?.data?.response);
            }
            dispatch({
              type: types.GET_ALL_USERS_MASTER_DATA, 
              payload: response?.data?.response,
            });
            break;
          case "frameworkDomain":
            dispatch({
              type: types.GET_ALL_FRAMEWORKS,
              payload: response?.data,
            });
            break;
          case "frameworkSubdomain":
            dispatch({
              type: types.GET_ALL_SUBDOMAINS,
              payload: response.data,
            });
            break;

          case "gradeLevel":
            dispatch({
              type: types.GET_ALL_GRADELEVELS,
              payload: response?.data,
            });
            break;

          case "tag":
            dispatch({
              type: types.GET_ALL_TAGS,
              payload: response.data,
            });

            break;
          case "contentArea":
            dispatch({
              type: types.GET_ALL_CONTENTAREA,
              payload: response.data,
            });
            break;

          case "position":
            dispatch({
              type: types.GET_POSITION_TITLES_MASTER_DATA,
              payload: response.data,
            });
            break;

          case "discountCode":
            response.data["data"] = dispatch(
              prepareDataFromApiToStore(response)
            );
            dispatch({
              type: types.GET_DISCOUNT_CODE_DATA,
              payload: response.data,
            });
            break;
          case "marketLocationAndOrganisation":
            dispatch({
              type: types.GET_ALL_MARKET_LOCATION,
              payload: response?.data,
            });
            break;
          case "bundle":
            response.data.response["data"] =
              prepareDataFromApiToStoreBundles(response);
            if (isAppendPreviousData) {
              //append previous data for infinite scrolling pagination
              let newArr =
                store && store.getState()?.tkladmin?.getBundleData?.data;
              response.data?.response?.data?.forEach((item) => {
                newArr.push(item);
              });
              response.data.response.data = newArr;
            }
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response?.data?.response,
              label: "getBundleData",
            });
            break;
          case "bundleCode":
            // response.data["data"] =
            //   prepareDataFromApiToStoreBundleCodes(response);
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data,
              label: "getBundleCodeData",
            });
            break;
          case "userManagement":
            response.data["data"] = prepareDataFromApiToStore(response);
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data,
              label: "getUserManagementData",
            });
            break;

          case "facilitator":
            // response.data["data"] = prepareDataFromApiToStore(response);
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data?.response,
              label: "getFinancialTable",
            });
            break;

          case "catalogueInformationData":
            let obj=[];
            response?.data?.data?.map((item, index) => {
              let pageNo;
              // if (pageNumber) {
              //   pageNo = ((15 * (pageNumber - 1)) + index + 1)
              // } else {
              //   pageNo = index + 1;
              // }
              item["serial"] = index+1;
              obj.push(item);
            })
            setRowData(obj);
            if(getPaginationData && !setSearchPagination){
              getPaginationData(response?.data?.last_page, response?.data?.current_page);
            }
            if(setSearchPagination){
              getPaginationData(response?.data?.last_page, response?.data?.current_page);
            }
            //  response.data["data"] = prepareDataFromApiToStore(response);
            dispatch({
              type: types.ADD_DATA_TO_STATE,
              payload: response.data,
              label: "catalogueDescription",
            });
            break;
          default:
            break;
        }
      })
      .catch((error) => {});

    dispatch({ type: types.IS_LOADING, payload: false });
    dispatch({ type: types.IS_RELOAD, payload: false });
  };
};

export const sortBasedonSortOrder = (
  URLPoint,
  isPaginated,
  searchType,

  sortBy,
  sortOrder,
  searchFieldType,
  searchInput,

  isCohort,
  pageNumber,

  isdropdownSearch,
  searchBy, //instead of searchType

  isAppendPreviousData,
  getPaginationData
) => {
  let permissionUrl= `&permissions[]=${localStorage.getItem("masterRadioValue")}`;
  return async (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    let apiUrl = "";
    if (searchInput) {
      apiUrl = `${isCohort ? URL_COHORT : URL}/${URLPoint}${
        URLPoint.includes("?") ? "&" : "?"
      }isPaginated=${isPaginated}
      &page=${pageNumber}
      &${
        isdropdownSearch
          ? `${searchFieldType}${searchInput}
        `
          : `searchKey=${searchFieldType}&searchValue=${searchInput}
      `
      }
      &sortBy=${sortBy}
      &orderBy=${sortOrder}`;
    } else {
      apiUrl = `${isCohort ? URL_COHORT : URL}/${URLPoint}${
        URLPoint.includes("?") ? "&" : "?"
      }isPaginated=${isPaginated}&page=${pageNumber}&sortBy=${sortBy}&orderBy=${sortOrder}
      ${localStorage.getItem("masterRadioValue") ? permissionUrl : ""}`;
    }
    await axios
      .get(apiUrl)
      .then((response) => {
        switch (searchType) {
          case "user":
            if(getPaginationData){
              getPaginationData(response?.data?.response?.last_page);
            } 
            dispatch({
              type: types.GET_ALL_USERS_MASTER_DATA,
              payload: response?.data?.response,
            });
            break;
          case "frameworkDomain":
            dispatch({
              type: types.GET_ALL_FRAMEWORKS,
              payload: response.data,
            });
            break;
          case "frameworkSubdomain":
            dispatch({
              type: types.GET_ALL_SUBDOMAINS,
              payload: response.data,
            });
            break;

          case "gradeLevel":
            dispatch({
              type: types.GET_ALL_GRADELEVELS,
              payload: response.data,
            });
            break;

          case "tag":
            dispatch({
              type: types.GET_ALL_TAGS,
              payload: response.data,
            });
            break;
          case "contentArea":
            dispatch({
              type: types.GET_ALL_CONTENTAREA,
              payload: response.data,
            });
            break;

          case "position":
            dispatch({
              type: types.GET_POSITION_TITLES_MASTER_DATA,
              payload: response.data,
            });
            break;

          case "discountCode":
            response.data["data"] = dispatch(
              prepareDataFromApiToStore(response)
            );
            dispatch({
              type: types.GET_DISCOUNT_CODE_DATA,
              payload: response.data,
            });
            break;
          case "marketLocationAndOrganisation":
            dispatch({
              type: types.GET_ALL_MARKET_LOCATION,
              payload: response?.data,
            });
            break;
          case "bundle":
            response.data.response.data = prepareDataFromApiToStoreBundles(
              response,
              !isPaginated
            );
            if (isAppendPreviousData) {
              //append previous data for infinite scrolling pagination
              let newArr =
                store && store.getState()?.tkladmin?.getBundleData?.data;
              response.data?.response?.data?.forEach((item) => {
                newArr.push(item);
              });
              response.data.response.data = newArr;

              // console.log(".....abcd2");
            }
            // console.log(".....abcd", response.data?.response);
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response?.data?.response,
              label: "getBundleData",
            });
            break;
          case "bundleCode":
            // response.data["data"] =
            //   prepareDataFromApiToStoreBundleCodes(response);
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data, //response.data?.response,
              label: "getBundleCodeData",
            });
            break;
          case "userManagement":
            //    response.data["data"] = prepareDataFromApiToStore(response);
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data,
              label: "getUserManagementData",
            });
            break;
          case "facilitator":
            //  response.data["data"] = prepareDataFromApiToStore(response);
            dispatch({
              type: types.GET_BUNDLES_DATA,
              payload: response.data?.response,
              label: "getFinancialTable",
            });
            break;

          case "catalogueInformationData":
            //  response.data["data"] = prepareDataFromApiToStore(response);
            dispatch({
              type: types.ADD_DATA_TO_STATE,
              payload: response.data,
              label: "catalogueDescription",
            });
            break;
          default:
            break;
        }
      })
      .catch((error) => {});

    dispatch({ type: types.IS_LOADING, payload: false });
    dispatch({ type: types.IS_RELOAD, payload: false });
  };
};

export const saveDiscussionFAQAction = (key, value) => {
  return async (dispatch) => {
    let dataPayload = {};
    dataPayload["key"] = key;
    dataPayload["value"] = value;
    await axios
      .put(`${URL}/update-configurations`, dataPayload)
      .then(() => {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Discussion FAQ Updated",
        });
      })
      .catch((error) => {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Discussion FAQ Not Updated, Please Try again",
        });
      });
  };
};
export const getDiscussionFAQ = () => {
  return async (disaptch) => {
    await axios
      .get(`${URL}/get-discussion-faq`)
      .then((response) => {
        disaptch({
          type: types.GET_DISCUSSION_FAQ,
          payload: response?.data,
        });
      })
      .catch((error) => {
        alert("Error in getting Discussion FAQ, Please try again");
      });
  };
};

//usecomprehenisve
export const searchBasedOnFieldTypeAndValueComprehensive = (
  URLPoint,
  isPaginated,
  searchType,
  searchField,
  searchValue,
  isCohort,
  pageNumberToSearch,
  isdropdownSearch,
  searchBy,
  isTKLMembers //instead of searchType
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));

    axios
      .get(
        `${isCohort ? URL_COHORT : URL}/${URLPoint}?&page=${
          pageNumberToSearch ? pageNumberToSearch : 1
        }
      &${
        isdropdownSearch
          ? `${searchField}${searchValue}`
          : `searchBy=${searchField}&searchValue=${searchValue}`
      }&sortBy=${userData?.sortFieldType?.slug || searchField || ""}&orderBy=${
          userData?.sortOrder === "Descending" ? "DESC" : "ASC"
        }`
      )
      .then((response) => {
        dispatch({
          type: types.GET_BUNDLES_DATA,
          payload: response.data?.response,
          // label: "getUserManagementData",
          label: `${
            isTKLMembers ? "getTKLMembersData" : "getUserManagementData"
          }`,
        });
      })
      .catch((error) => {});

    dispatch({ type: types.IS_LOADING, payload: false });
    dispatch({ type: types.IS_RELOAD, payload: false });
  };
};

export const sortBasedonSortOrderComprehensive = (
  URLPoint,
  isPaginated,
  searchType,
  sortBy,
  sortOrder,
  searchFieldType,
  searchInput,
  isCohort,
  pageNumber,
  isdropdownSearch,
  searchBy, //instead of searchType
  isTKLMembers
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    let apiUrl = "";
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    if (searchInput) {
      apiUrl = `${isCohort ? URL_COHORT : URL}/${URLPoint}?&page=${
        pageNumber ? pageNumber : 1
      }
      &${
        isdropdownSearch
          ? `${searchFieldType}${searchInput}`
          : `searchBy=${searchFieldType}&searchValue=${searchInput}`
      }&sortBy=${
        userData?.sortFieldType?.slug || searchFieldType || ""
      }&orderBy=${userData?.sortOrder === "Descending" ? "DESC" : "ASC"}`;
    } else {
      // &searchType=${searchType}
      apiUrl = `${
        isCohort ? URL_COHORT : URL
      }/${URLPoint}?isPaginated=${isPaginated}&page=${pageNumber}&sortBy=${sortBy}&orderBy=${sortOrder}`;
    }
    axios
      .get(apiUrl)
      .then((response) => {
        // console.log(
        //   "....response.data?.response2",
        //   response.data?.response,
        //   isTKLMembers
        // );

        dispatch({
          type: types.GET_BUNDLES_DATA,
          payload: response.data?.response,
          label: `${
            isTKLMembers ? "getTKLMembersData" : "getUserManagementData"
          }`,
        });
      })
      .catch((error) => {});

    dispatch({ type: types.IS_LOADING, payload: false });
    dispatch({ type: types.IS_RELOAD, payload: false });
  };
};

//financialData

export const searchBasedOnFieldTypeAndValueFinancial = (
  URLPoint,
  isPaginated,
  searchType,
  searchField,
  searchValue,
  isCohort,
  pageNumberToSearch,
  isdropdownSearch,
  searchBy //instead of searchType
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));

    axios
      .get(
        `${isCohort ? URL_COHORT : URL}/${URLPoint}?page=${
          pageNumberToSearch ? pageNumberToSearch : 1
        }&${
          isdropdownSearch
            ? `${searchField}${searchValue}`
            : `searchBy=${searchField}&searchValue=${searchValue}`
        }&sortBy=${
          userData?.sortFieldType?.slug || searchField || ""
        }&orderBy=${userData?.sortOrder === "Descending" ? "DESC" : "ASC"}`
      )
      .then((response) => {
        dispatch({
          type: types.GET_BUNDLES_DATA,
          payload: response.data?.response,
          label: "getFinancialTable",
        });
      })
      .catch((error) => {});

    dispatch({ type: types.IS_LOADING, payload: false });
    dispatch({ type: types.IS_RELOAD, payload: false });
  };
};

export const sortBasedonSortOrderFinancial = (
  URLPoint,
  isPaginated,
  searchType,
  sortBy,
  sortOrder,
  searchFieldType,
  searchInput,
  isCohort,
  pageNumber,
  isdropdownSearch,
  searchBy //instead of searchType
) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    let apiUrl = "";
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    if (searchInput) {
      apiUrl = `${isCohort ? URL_COHORT : URL}/${URLPoint}?&page=${
        pageNumber ? pageNumber : 1
      }
      &${
        isdropdownSearch
          ? `${searchFieldType}${searchInput}`
          : `searchBy=${searchFieldType}&searchValue=${searchInput}`
      }&sortBy=${
        userData?.sortFieldType?.slug || searchFieldType || ""
      }&orderBy=${userData?.sortOrder === "Descending" ? "DESC" : "ASC"}`;
    } else {
      apiUrl = `${
        isCohort ? URL_COHORT : URL
      }/${URLPoint}?page=${pageNumber}&sortBy=${sortBy}&orderBy=${sortOrder}`;
    }
    axios
      .get(apiUrl)
      .then((response) => {
        // dispatch({
        //   type: types.GET_BUNDLES_DATA,
        //   payload: response.data,
        //   label: "getUserManagementData",
        // });
        dispatch({
          type: types.GET_BUNDLES_DATA,
          payload: response.data?.response,
          label: "getFinancialTable",
        });
      })
      .catch((error) => {});

    dispatch({ type: types.IS_LOADING, payload: false });
    dispatch({ type: types.IS_RELOAD, payload: false });
  };
};
