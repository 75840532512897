import axios from "axios";
import { URL } from "../../api/tklEnvironments";
import { types } from "../../reducers/types";
import { sortBasedonSortOrder } from "./UserAction";
export const getFrameworkDetails = (page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    if (!userData?.searchInput) {
      dispatch(
        sortBasedonSortOrder(
          "framework-domain",
          1,
          "frameworkDomain",
          userData?.sortFieldType?.slug || "index",
          userData?.sortOrder === "Descending" ? "DESC" : "ASC" || "ASC",
          "",
          "",
          false,
          page
        )
      );
    } else {
      axios
        .get(`${URL}/framework-domain?page=${page}`)
        .then(function (response) {
          if (response.data) {
            dispatch({
              type: types.GET_ALL_FRAMEWORKS,
              payload: response.data,
            });
          }
        })
        .catch(function (error) {
          console.error("..error", error);
        });
    }
    dispatch({ type: types.IS_LOADING, payload: false });
    dispatch({ type: types.IS_RELOAD, payload: false });
  };
};

export const updateFrameWork = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/update-framework-domain`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        let errorMessage = error.response?.data?.errors?.name;
        console.error("..error", errorMessage);
        if (
          errorMessage &&
          errorMessage[0] === "The name has already been taken."
        ) {
          dispatch({
            type: types.API_ERROR,
            payload: "The name has already been taken.",
          });
        }
      });
  };
};

export const updateSubDommainFrameWork = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/update-framework-subdomain`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        let errorMessage = error.response?.data?.errors?.name;
        console.error("..error", errorMessage);
        if (
          errorMessage &&
          errorMessage[0] === "The name has already been taken."
        ) {
          dispatch({
            type: types.API_ERROR,
            payload: "The name has already been taken.",
          });
        }
      });
  };
};

export const addFrameWork = (payload) => {
  return (dispatch) => {
    axios
      .post(`${URL}/add-framework-domain`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
        }
      })
      .catch(function (error) {
        let errorMessage = error.response?.data?.errors?.name;
        console.error("..error", errorMessage);
        if (
          errorMessage &&
          errorMessage[0] === "The name has already been taken."
        ) {
          dispatch({
            type: types.API_ERROR,
            payload: "The name has already been taken.",
          });
        }
      });
  };
};

export const addSubDomainFrameWork = (payload) => {
  return (dispatch) => {
    axios
      .post(`${URL}/add-framework-subdomain`, payload)
      .then(function (response) {
        if (response.data) {
          dispatch({ type: types.IS_RELOAD, payload: true });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        let errorMessage = error.response?.data?.errors?.name;
        console.error("..error", errorMessage);
        if (
          errorMessage &&
          errorMessage[0] === "The name has already been taken."
        ) {
          dispatch({
            type: types.API_ERROR,
            payload: "The name has already been taken.",
          });
        }
      });
  };
};

export const getSubDomain = (domainSlug, page) => {
  return (dispatch) => {
    dispatch({ type: types.IS_LOADING, payload: true });
    dispatch({ type: types.IS_RELOAD, payload: true });
    let userData = JSON.parse(sessionStorage.getItem("userSortingData"));
    //console.log(".....domainSlug", domainSlug);
    if (!userData?.searchInput) {
      dispatch(
        sortBasedonSortOrder(
          `get-subdomain/${domainSlug}`,
          1,
          "frameworkSubdomain",
          userData?.sortFieldType?.slug || "index",
          userData?.sortOrder === "Descending" ? "DESC" : "ASC" || "ASC",
          "",
          "",
          false,
          page
        )
      );
    } else {
      axios
        .get(`${URL}/get-subdomain/${domainSlug}?page=${page}`)
        .then((response) => {
          dispatch({ type: types.GET_ALL_SUBDOMAINS, payload: response?.data });
        })
        .catch((error) => {
          console.log("...error", error);
        });
    }
    dispatch({ type: types.IS_LOADING, payload: false });
    dispatch({ type: types.IS_RELOAD, payload: false });
  };
};
