import React, { useState } from "react";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import {
  Form,
  Tab,
  Row,
  Col,
  Nav,
  InputGroup,
  Dropdown,
  Button,
  Modal,
  Spinner,
} from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import {
  CustomDropdownToggle,
  CustomDropdownMenu,
} from "./../../../shared-components/custom-dropdown/index";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import {
  getCountries,
  getStates,
} from "../../../actions/CohortsActions/cohortactions";
import {
  getProfessionlColleagues,
  followUnfollowUser,
  getFollowedUsers,
  getGroupList,
  getMyConnections,
  getAddMoreContacts,
  sendConnectionRequest,
} from "../../../actions/CommunityActions/CommunityAction";
import { URL } from "../../../api/tklEnvironments";

import { TKLModal } from "../../../shared-components/model/TKLModal";
import ComponentPageBanner from "../../../shared-components/page-banner";
import { BiSearch } from "react-icons/bi";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  showFirstValidationErrorFromAPI,
  BannerImageHandlingForAllDevices,
} from "../../utility/ReUsableFunctions";
//import { store } from "../../../App";
import { types } from "../../../reducers/types";
import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter, Link } from "react-router-dom";
import "./../../../components/community/ProfessionalColleagues/ProfessionalColleagues.scss";

const tilejson = require("./../../../data/dashboard/tile-c.json");
const arr1 = (tilejson || []).filter((item) => item.type === "members");
const arr2 = (tilejson || []).filter((item) => item.type === "groups");

export default function TileC(props) {
  const dispatch = useDispatch();
  const [searchName, setSearchName] = useState("");

  const [invitePopup, setInvitePopup] = useState(false);

  const userData = useSelector((state) => state.users.userData);
  const { addMoreContacts, listOfGroups, isColleaguesReload } = useSelector(
    (state) => state.community
  );
  const { apiSuccessMessage, apiErrorMessage } = useSelector(
    (state) => state.coursepath
  );
  const {
    getValues,
    setValue,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  React.useEffect(() => {
    dispatch(getAddMoreContacts(searchName, 1, 10));
    dispatch(getGroupList());
  }, [JSON.stringify(userData)]);

  React.useEffect(() => {
    isColleaguesReload && dispatch(getAddMoreContacts(searchName, 1, 10));
  }, [isColleaguesReload]);

  const sendInvitePopUp = () => {
    setInvitePopup(true);
  };

  const handleCloseInvitePopUp = () => {
    setInvitePopup(false);
    setValue("email", "");
  };

  const onSubmit = async (data) => {
    await axios
      .post(`${URL}/send-invite`, { email: data?.email })
      .then((response) => {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Invitation Sent",
        });
        handleCloseInvitePopUp();
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
      });
  };

  const nextPageData = () => {
    // console.log("nextPageData");
    // console.log(myContacts?.links?.next);
    // dispatch(getAddMoreContacts(searchName));
  };
  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "members") {
      thisArr = addMoreContacts?.data || [];
      char = "members";
      return thisArr?.length > 0 ? (
        // <InfiniteScroll
        //   dataLength={thisArr?.length}
        //   next={nextPageData(myConnections?.meta?.current_page + 1)}
        //   hasMore={myConnections?.links?.next != null}
        //   loader={
        //     <div className="tkl-community-spinner">
        //       <Spinner animation="border" variant="primary" size="lr" />
        //     </div>
        //   }
        // >
        <div>
          {thisArr.map((item, index) => (
            <React.Fragment key={`t_c_${char}_${index}`}>
              <div className="dash-row-type-2 container rounded mb-2">
                <div className="row">
                  <div className="t-row-name col-12 col-md-8 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
                    <div className="t-avtr-wrap position-absolute">
                      <a
                        href={`/OtherProfile/${item?.userSlug}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <ComponentAvatar
                          type="user"
                          data={{
                            firstname: item.firstname,
                            lastname: item.lastname,
                            img: item.avatarImage?.slug
                              ? {
                                  md: `${URL}/stream-media/${item.avatarImage?.slug}`,
                                  md2: `${URL}/stream-media/${item.avatarImage?.slug}`,
                                  xs: `${URL}/stream-media/${item.avatarImage?.slug}`,
                                  xs2: `${URL}/stream-media/${item.avatarImage?.slug}`,
                                }
                              : BannerImageHandlingForAllDevices(
                                  null,
                                  "user",
                                  null
                                ),
                          }}
                        />
                      </a>
                    </div>
                    <a
                      href={`/OtherProfile/${item?.userSlug}`}
                      target="_blank"
                      rel="noreferrer"
                      className="t-row-name-link"
                    >
                      {item.firstName} {item.lastName}
                    </a>
                    <small>
                      {item.subjectAreas?.[0]?.name && "Prof. "}
                      {item.subjectAreas?.[0]?.name || ""}
                    </small>
                  </div>
                  <div className="col-6 col-md-2 p-2 d-flex flex-column justify-content-start align-items-center">
                    <Button
                      className="btn m-2 pl-2 pr-2  send_invitation_btn"
                      size="sm"
                      style={{
                        maxHeight: "32px",
                        minWidth: "130px",
                      }}
                      onClick={() => {
                        dispatch(sendConnectionRequest(item?.userSlug));
                      }}
                    >
                      {"Send Invitation"}
                    </Button>
                  </div>
                </div>
                {index === thisArr?.length - 1 && (
                  <a
                    href={`/colleagues`}
                    className="t-row-name-link text-primary"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      marginLeft: "2em",
                      marginTop: "1em",
                      display: "inline-block",
                    }}
                  >
                    View All Colleagues
                  </a>
                )}
              </div>

              {/* <hr /> */}
            </React.Fragment>
          ))}
        </div>
      ) : (
        // </InfiniteScroll>
        <p className="item-not-selected">No Members Available</p>
      );
    }
    if (str === "groups") {
      thisArr = listOfGroups || [] || [];
      char = "groups";

      return thisArr?.length > 0 ? (
        thisArr.map((item, index) => (
          <React.Fragment key={`t_c_${char}_${index}`}>
            <div className="dash-row-type-2 container rounded mb-2">
              <div className="row">
                <div className="t-row-name col-12 col-md-8 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
                  <div className="t-avtr-wrap position-absolute">
                    <a href={item.link}>
                      <ComponentAvatar
                        type="user"
                        data={{
                          firstname: item.firstname,
                          lastname: item.lastname,
                          img: item.groupIcon?.slug
                            ? {
                                md: `${URL}/stream-media/${item.groupIcon?.slug}`,
                                md2: `${URL}/stream-media/${item.groupIcon?.slug}`,
                                xs: `${URL}/stream-media/${item.groupIcon?.slug}`,
                                xs2: `${URL}/stream-media/${item.groupIcon?.slug}`,
                              }
                            : BannerImageHandlingForAllDevices(
                                null,
                                "course",
                                null
                              ),
                        }}
                      />
                    </a>
                  </div>
                  <a
                    href={`/community/${item.slug}`}
                    target="_blank"
                    rel="noreferrer"
                    className="t-row-name-link"
                  >
                    {item.groupName}
                  </a>
                  <div>
                    <small className="text-black-50">{item.description}</small>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            {apiSuccessMessage && (
              <TKLModal
                showDialog={true}
                msgType={"success"}
                message={apiSuccessMessage}
                type="success"
              />
            )}
            {apiErrorMessage && (
              <TKLModal
                showDialog={true}
                msgType={"warning"}
                message={apiErrorMessage}
                type="error"
              />
            )}
          </React.Fragment>
        ))
      ) : (
        <p className="item-not-selected">No Groups Available</p>
      );
    }
  };
  return (
    <>
      <article className="dash-t-comp d-flex flex-column">
        <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
          <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
            <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
              <i className="material-icons">open_with</i>
            </div>
            <h2 className="h4 text-uppercase m-0">
              <strong>My Professional Colleagues</strong>
            </h2>
            {/* <small className="d-md-none text-uppercase">
              <strong>{userData?.followedByCount}</strong> Connections
            </small> */}
            <ComponentCtaBtn
              className="d-md-none ml-2"
              data={{
                tag: "button",
                type: "submit",
                ctastyle: "info",
                text: "Invite",
                title: "Invite",
                size: "sm",
                primaryicon: "person_add",
              }}
            />
          </div>
          <div className="dash-t-h-right py-2 px-3 flex-md-grow-1 d-none d-md-flex justify-content-md-end align-items-md-center">
            {/* <small className="text-uppercase">
              <strong>{userData?.followedByCount}</strong> Connections
            </small> */}
            <ComponentCtaBtn
              className="ml-2"
              onClick={sendInvitePopUp}
              data={{
                tag: "button",
                type: "submit",
                ctastyle: "info",
                text: "Invite",
                title: "Invite",
                size: "sm",
                primaryicon: "person_add",
              }}
            />
          </div>
        </div>
        {/* <div className="dash-t-search p-2 px-md-3 d-flex flex-column">
        <Form>
          <Row>
            <Col xs="6" md="8">
              <InputGroup size="sm">
                <Form.Control
                  type="search"
                  placeholder="Search for Members / Groups"
                  aria-label="Search for Members / Groups"
                  aria-describedby="basic-addon2"
                  className="rounded-pill"
                />
                <InputGroup.Append>
                  <ComponentCtaBtn
                    data={{
                      tag: "button",
                      type: "submit",
                      ctastyle: "secondary",
                      text: "",
                      title: "Search",
                      size: "sm",
                      primaryicon: "search",
                    }}
                  />
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col xs="6" md="4">
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomDropdownToggle}
                  id="dropdown-custom-components"
                >
                  All
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomDropdownMenu}>
                  <Dropdown.Item eventKey="1">All</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Members</Dropdown.Item>
                  <Dropdown.Item eventKey="3">Groups</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row>
        </Form>
      </div> */}
        <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
          <Tab.Container id="mapl_tabs" defaultActiveKey="members">
            <Row>
              <Col xs="12" className="mb-3">
                <Nav variant="pills" className="custom-tabs-link flex-row">
                  <Nav.Item>
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="members"
                    >
                      <strong>Potential New Contacts</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="groups"
                    >
                      <strong>Groups</strong>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row className="flex-grow-1">
              <Col xs="12" className="d-flex">
                <Tab.Content className="custom-tabs-pane">
                  <Tab.Pane eventKey="members">
                    <ComponentCustomScrollbar
                      className="scroll-comp"
                      style={{ height: "100%" }}
                    >
                      {renderData("members")}

                      <br />
                    </ComponentCustomScrollbar>
                  </Tab.Pane>
                  <Tab.Pane eventKey="groups">
                    <ComponentCustomScrollbar
                      className="scroll-comp"
                      style={{ height: "100%" }}
                    >
                      {renderData("groups")}
                      <br />
                    </ComponentCustomScrollbar>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </article>
      {invitePopup && (
        <Modal
          size="lg"
          centered
          show={invitePopup}
          onHide={handleCloseInvitePopUp}
        >
          <Modal.Header closeButton>
            <Modal.Title className="font-weight-500">
              <span className="font-weight-500">Invite new members</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group-create">
              <label className="form-label">
                <strong>Email Address</strong>
              </label>
              <div className="mb-3 input-group">
                <input
                  placeholder={`Enter Email Address`}
                  type="text"
                  className={`input-caret rounded-pill form-control form-control-sm input-width-style`}
                  {...register("email", {
                    required: true,
                  })}
                  name="email"
                />
              </div>
              {errors.email && !getValues("email") && (
                <p className="show-txt-error">Email required</p>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="p-1 m-1 btn rounded-pill btn-outline btn-secondary btn-sm icon-lr btn-background-style-save footer-padding btn-width"
              title="Close"
              onClick={handleCloseInvitePopUp}
            >
              Close
            </Button>
            <Button
              type="submit"
              className="p-1 m-1 btn rounded-pill btn-outline btn-blue btn-sm icon-lr btn-background-style-save footer-padding btn-width"
              title="Send Invite"
              onClick={handleSubmit(onSubmit)}
            >
              Send Invite
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {apiSuccessMessage && (
        <TKLModal
          showDialog={true}
          msgType={"success"}
          message={apiSuccessMessage}
          primaryBtnColor={"#0088CC"}
        />
      )}

      {apiErrorMessage && (
        <TKLModal
          showDialog={true}
          msgType={"warning"}
          message={apiErrorMessage}
          type="error"
        />
      )}
    </>
  );
}
