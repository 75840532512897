import React, { useEffect, useRef, useState } from "react";
import "./FileStorageRoot.scss";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";

import Form from "react-bootstrap/Form";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ProgressBar, Spinner } from "react-bootstrap";
import {
  getAwsToken,
  listFolders,
  mapFileUpload,
} from "actions/FileStorageActions/FileStorageAction";

// tkl-dev
// tkl-prod
// tkl-qa
// tkl-uat

// TKL_DRIVE

function UploadFileToS3() {
  const getEnvironment = () => {
    let selectedEnvConfig = "qa";
    let environment = process.env.REACT_APP_ENVIRONMENT || "dev";
    if (environment.trim() === "dev") {
      selectedEnvConfig = "dev";
    } else if (environment.trim() === "staging") {
      selectedEnvConfig = "qa";
    } else if (environment.trim() === "uat") {
      selectedEnvConfig = "uat";
    } else if (environment.trim() === "prod") {
      selectedEnvConfig = "prod";
    } else if (environment.trim() === "local") {
      selectedEnvConfig = "local";
    }
    return selectedEnvConfig;
  };
  const { folders, currentFolderPath, secretAccessKey, accessKeyId, token } =
    useSelector((state) => state.filestorage);
  const dispatch = useDispatch();

  const [uploadStatus, setUploadStatus] = useState({});

  const [showUploadPopup, setShowUploadPopup] = useState(false);

  const [uploadError, setUploadError] = useState(false);

  const [fileUploadErrorMessage, setFileUploadErrorMessage] =
    useState("File upload failed");

  useEffect(() => {
    dispatch(getAwsToken());
  }, []);

  const fileUploadInput = useRef(null);

  const safariStream = (blob) => {
    let position = 0;

    return new ReadableStream({
      async pull(controller) {
        const chunk = blob.slice(
          position,
          Math.min(
            blob.size,
            position + Math.max(controller.desiredSize, 512 * 1024)
          )
        );
        const buffer = await new Response(chunk).arrayBuffer();
        const uint8array = new Uint8Array(buffer);
        const bytesRead = uint8array.byteLength;

        position += bytesRead;
        controller.enqueue(uint8array);

        if (position >= blob.size) controller.close();
      },
    });
  };

  const uploadFile = async (e) => {
    e.persist();
    setUploadError(false);
    setShowUploadPopup(true);
    setUploadStatus({});
    let partSize = 1024 * 1024 * 5;
    console.log(e.target.files[0]);
    let file = e.target.files[0];
    if (file?.size) {
      console.log(file.size);
      let fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB >= 1000) {
        partSize = 1024 * 1024 * 100;
        console.log("if", partSize);
      } else {
        partSize = (fileSizeInMB / 10) * 1024 * 1024;
        if (partSize <= 1024 * 1024 * 5) {
          partSize = 1024 * 1024 * 5;
        }
        console.log("else", partSize);
      }
      try {
        let fileNameKey = file.name;
        if (currentFolderPath[currentFolderPath.length - 1].name != "/") {
          fileNameKey = currentFolderPath
            .map((item, index) => {
              if (item.slug != null) return "/" + item.slug;
            })
            .join("");
          fileNameKey =
            "TKL-DRIVE/" +
            fileNameKey.slice(1, fileNameKey.length) +
            "/" +
            file.name;
          //  +
          // Math.random().toString();
        }

        const parallelUploads3 = new Upload({
          client:
            new S3({
              region: "us-east-1",
              credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey,
                sessionToken: token,
              },
            }) ||
            new S3Client({
              region: "us-east-1",
              credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey,
                sessionToken: token,
              },
            }).send(),
          params: {
            Bucket: `tkl-${getEnvironment()}`,
            Key: fileNameKey,
            Body: Blob.prototype.stream ? file : safariStream(file),
          },
          tags: [],
          queueSize: 4, // optional concurrency configuration
          partSize: partSize, // optional size of each part, in bytes, at least 5MB
          leavePartsOnError: false, // optional manually handle dropped parts
        });

        parallelUploads3.on("httpUploadProgress", (progress) => {
          setUploadStatus(progress);
          console.log(progress);
        });

        await parallelUploads3.done();

        dispatch(
          mapFileUpload({
            folderSlug: currentFolderPath[currentFolderPath.length - 1].slug,
            fileName: file.name,
            fileUploadLocation: fileNameKey,
          })
        );

        //file reset
        fileUploadInput.current.value = null;
      } catch (e) {
        setUploadError(true);
        console.log(e);

        //file reset
        fileUploadInput.current.value = null;
      }
    } else {
      setFileUploadErrorMessage(
        "Empty File provided, Please try again with a Non Empty file"
      );
      setUploadError(true);
      fileUploadInput.current.value = null;
    }
  };

  const UploadPopup = ({ handleClose, handleSubmit, show }) => {
    return (
      <Modal
        enforceFocus={true}
        size="lg"
        className="modal-styles"
        show={show}
        onHide={handleClose}
      >
        {/* <Modal.Header closeButton> */}
        <Modal.Header>
          <Modal.Title>{"File Upload"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="m-2 p-2">Uploading File to {uploadStatus.Key}</div>
          <ProgressBar
            animated
            variant={`${uploadError ? "danger" : "success"}`}
            now={(uploadStatus.loaded / uploadStatus.total) * 100}
            label={`${(uploadStatus.loaded / uploadStatus.total) * 100}%`}
          />
          {uploadError ? (
            <p className="m-2" style={{ color: "red", fontWeight: "bold" }}>
              {fileUploadErrorMessage}
            </p>
          ) : (
            <p className="m-2" style={{ color: "green", fontWeight: "bold" }}>
              File uploaded Successfully
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            className="btn-outline btn-secondary rounded-pill "
            title={"Cancel"}
            onClick={handleClose}
          >
            {"Cancel"}
          </Button> */}
          {(uploadError ||
            (uploadStatus.loaded === uploadStatus.total &&
              uploadStatus.total)) && (
            <Button
              className=" btn-outline rounded-pill btn"
              title={"Ok"}
              onClick={(e) => {
                dispatch(
                  listFolders(
                    currentFolderPath[currentFolderPath.length - 1].slug
                  )
                );
                handleSubmit(e);
              }}
            >
              {"Ok"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };
  return (
    <div className="d-flex flex-row justify-content-space-between align-items-center p-1 m-2">
      <UploadPopup
        show={
          showUploadPopup &&
          (uploadError ||
            (uploadStatus.loaded === uploadStatus.total && uploadStatus.total))
        }
        handleClose={() => {
          setShowUploadPopup(false);
        }}
        handleSubmit={() => {
          setShowUploadPopup(false);
        }}
        error={uploadError}
      ></UploadPopup>
      {showUploadPopup && (
        <div>
          {uploadStatus.loaded ? (
            <div>
              <div className="m-2 p-2">
                Uploading File to {uploadStatus.Key}
              </div>
              <ProgressBar
                animated
                variant={`${uploadError ? "danger" : "success"}`}
                now={(uploadStatus.loaded / uploadStatus.total) * 100}
                label={`${(uploadStatus.loaded / uploadStatus.total) * 100}%`}
              />
              {uploadError && (
                <p className="m-2" style={{ color: "red", fontWeight: "bold" }}>
                  File upload failed
                </p>
              )}
            </div>
          ) : (
            <Spinner animation="border" variant="primary" size="lr" />
          )}
        </div>
      )}
      <span class="material-icons FileStorageRoot__toolbar__folder__icon">
        upload
      </span>
      <Form.Group controlId="formFile" className="mb-3 align-items-center">
        <Form.Label>Upload File</Form.Label>
        <Form.Control
          ref={fileUploadInput}
          type="file"
          onChange={(e) => {
            uploadFile(e);
          }}
        />
      </Form.Group>
    </div>
  );
}

export default UploadFileToS3;
