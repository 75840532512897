import React from "react";
import ComponentNotificationBulb from "./../notification-bulb/index";
import "./_index.scss";

const ComponentCtaBtn = React.memo(function ComponentCtaBtn(props) {
  const data = props.data;
  const parentClassName = props.className || "";
  const clickfn = props.onClick;
  const notifications = props.notifications;
  const disabled = props.disabled;
  const isBorder = props.isBorder ? props.isBorder : false;
  const genCtaClass = (props) => {
    let clsStr = parentClassName + " btn rounded-pill cta-comp";

    if (props.ctastyle && [true, "true"].includes(props.isoutline)) {
      clsStr += " btn-outline-" + props.ctastyle;
    }
    if (props.ctastyle && ![true, "true"].includes(props.isoutline)) {
      clsStr += " btn-" + props.ctastyle;
    }
    if (props.size) {
      clsStr += " btn-" + props.size;
    }

    if (props.primaryicon && props.ctaicon) {
      clsStr += " icon-lr";
    }
    if (!props.primaryicon && props.ctaicon) {
      clsStr += " icon-r";
    }
    if (props.primaryicon && !props.ctaicon) {
      clsStr += " icon-l";
    }

    if (!props.text) {
      clsStr += " no-text";
    }

    if (disabled) {
      clsStr += " disabled";
    }

    return clsStr;
  };
  const generateNotif = (notif) => {
    if (notif) {
      try {
        let thisNotif = parseInt(notif);
        return (
          <div
            className="position-absolute"
            style={{
              width: "1rem",
              height: "1rem",
              top: "-0.5rem",
              right: "-0.5rem",
            }}
          >
            <ComponentNotificationBulb count={notif} />
          </div>
        );
      } catch (e) {
        return <></>;
      }
    }
    return <></>;
  };
  const genIcon = (icon, str) => {
    return <i className={`${str} material-icons`}>{icon}</i>;
  };
  return (
    <>
      {data.tag === "button" ? (
        isBorder ? (
          <button
            style={{
              "background-color": "darken(#1DBAAD, 7.5%)",
              color: "white",
            }}
            type={data.type || "button"}
            disabled={disabled}
            className={genCtaClass(data)}
            title={data.title || data.text || ""}
            onClick={clickfn}
          >
            {data.primaryicon ? genIcon(data.primaryicon, "_pi") : <></>}
            {data.text}
            {data.ctaicon ? genIcon(data.ctaicon, "_ci") : <></>}
            {generateNotif(notifications)}
          </button>
        ) : (
          <button
            type={data.type || "button"}
            disabled={disabled}
            className={genCtaClass(data)}
            title={data.title || data.text || ""}
            onClick={clickfn}
          >
            {data.primaryicon ? genIcon(data.primaryicon, "_pi") : <></>}
            {data.text}
            {data.ctaicon ? genIcon(data.ctaicon, "_ci") : <></>}
            {generateNotif(notifications)}
          </button>
        )
      ) : (
        <></>
      )}
      {data.tag === "a" ? (
        <a
          href={data.href || "#"}
          className={genCtaClass(data)}
          title={data.title || data.text || ""}
          onClick={clickfn}
        >
          {data.primaryicon ? genIcon(data.primaryicon, "_pi") : <></>}
          {data.text}
          {data.ctaicon ? genIcon(data.ctaicon, "_ci") : <></>}
          {generateNotif(notifications)}
        </a>
      ) : (
        <></>
      )}
    </>
  );
});
export default ComponentCtaBtn;
