import { types } from "./types";
import { UserProfileData } from "../components/Models/userModels";
import { UserCoursesModel } from "../components/Models/userModels";
const initialState = {
  users: [],
  userData: new UserProfileData(),
  allPositionTitles: [],
  contentDeveloperCourses: new UserCoursesModel(),
  otherUserData: new UserProfileData(),
};

const returnMutiselectedItemArray = (action, originalData, allOptions) => {
  let cohortData = {};
  Object.keys(originalData).forEach((item, index) => {
    if (item === action.label) {
      if (action.payload === "selectAll") {
        originalData[item]?.splice(0, originalData[item].length + 1);
        if (action.checked && action.checked) {
          let dropDownOptionsData = allOptions;
          dropDownOptionsData.forEach((option, index) => {
            originalData[item].push(option);
          });
        }
      } else {
        if (action.payload["isChecked"] === true) {
          cohortData[item] = originalData[item].push(action.payload);
        } else {
          let indexFind = originalData[item]
            .map((element) => element.slug)
            .indexOf(`${action.payload["slug"]}`);
          ~indexFind && originalData[item].splice(indexFind, 1);
        }
      }
    } else cohortData[item] = originalData[item];
  });
  return originalData[`${action.label}`];
};

const returnSingleSelectedItem = (action, originalData) => {
  let datatoBeReplaced = {};
  Object.keys(originalData).forEach((item, index) => {
    if (item === action.label) {
      datatoBeReplaced[item] = action.payload;
    }
  });
  return datatoBeReplaced[action.label];
};
export const users = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ALL_USERS:
      return {
        ...state,
        users: action.payload,
      };
    case types.POSITION_TITLES:
      return {
        ...state,
        allPositionTitles: action.payload,
      };
    case types.MULTISELECT_FORM_DATA:
      let returnedMultiSelectUserData = returnMutiselectedItemArray(
        action,
        state.userData,
        action.allOptions
      );
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.label]: returnedMultiSelectUserData,
        },
      };
    case types.SINGLE_SELECTION_DRPDOWN:
      let returnedSelectedItem = returnSingleSelectedItem(
        action,
        state.userData
      );
      return {
        ...state,
        userData: {
          ...state.userData,
          [action.label]: returnedSelectedItem,
        },
      };
    case types.GET_PROFILE_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case types.GET_OTHER_PROFILE_DATA:
      return {
        ...state,
        [action.label]: action.payload,
      };
    case types.USER_CONTENT_DEVELOPER_COURSES:
      return {
        ...state,
        contentDeveloperCourses: action.payload,
      };
    default:
      return state;
  }
};
