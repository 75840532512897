import React, { useState } from "react";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Tab, Button, Row, Col, Nav, Modal, Form } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, useParams, Link } from "react-router-dom";
import {
  getAllPosts,
  getResources,
  getBookmarkedPosts,
  getGroupList,
  shareResourseToGroup,
  shareResourseToUser,
} from "../../../actions/CommunityActions/CommunityAction";
import parse from "html-react-parser";
import {
  BannerImageHandlingForAllDevices,
  getInDateAnotherFormat,
} from "../../utility/ReUsableFunctions";
import history from "../../../Routes/history";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { SYSTEM_ADMIN } from "constants.js/RoleNames";
import "./tiles.scss";
import { getAllUsers } from "actions/MasterDataActions/UserAction";
import InputField from "components/Cohorts/CohortFields/InputField";
import ComponentPageBanner from "shared-components/page-banner";

const tilejson = require("./../../../data/dashboard/tile-e.json");
// const arr1 = (tilejson || []).filter((item) => item.type === "bookmarked");
// const arr2 = (tilejson || []).filter((item) => item.type === "recommended");

export default function TileE(props) {
  const dispatch = useDispatch();
  const defaultImageURL = `${process.env.PUBLIC_URL + "/images.png"}`;
  const { posts, listOfGroups, resourses, bookmarkedPosts } = useSelector(
    (state) => state.community
  );

  const userData = useSelector((state) => state.users.userData);
  const users = useSelector((state) => state.masterdata.users.data);

  React.useEffect(() => {
    // dispatch(getAllPosts());

    // dashboard
    dispatch(getResources(1, false, 5, 1));
    dispatch(getBookmarkedPosts(1));
    dispatch(getGroupList());
    dispatch(getAllUsers(1));
  }, []);

  // React.useEffect(() => {
  //   // console.log("posts ::: ", posts);
  // }, [posts]);

  const bookmarkItemClickHandler = (item) => {
    if (item?.postId) {
      window.open(`/community/PostItem/${item?.postId}`);
    }
  };

  const nextPageData = (e, str) => {
    if (str === "bookmarked") {
      dispatch(
        getBookmarkedPosts(bookmarkedPosts?.current_page + 1, true, 5, 1)
      );
    }
    if (str === "recommended") {
      //dashboard My resources tab
      dispatch(getResources(resourses?.current_page + 1, true, 5, 1));
    }
  };
  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    // console.log(".....bookmarkedPosts?.data", bookmarkedPosts?.data);
    if (str === "bookmarked") {
      thisArr = bookmarkedPosts?.data || []; //(posts?.data || []).filter((post) => post?.isBookmarked);
      char = "bookmarked";
    }
    if (str === "recommended") {
      thisArr = resourses?.data || [];
      char = "recommended";
    }

    const [showSharePopup, setShowSharePopup] = useState(false);
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedResource, setSelectedResource] = useState("");
    const [selectedUser, setSelectedUser] = useState("");

    const handleCloseSharePopup = () => {
      setShowSharePopup(false);
    };
    const handleShareWithGroup = () => {
      if (selectedGroup && selectedResource) {
        dispatch(
          shareResourseToGroup({
            groupSlug: selectedGroup,
            resourceId: selectedResource?.communityResourceId,
          })
        );
      }
    };
    const handleShareWithUser = () => {
      if (selectedUser && selectedResource) {
        dispatch(
          shareResourseToUser({
            mail: selectedUser,
            resourceId: selectedResource?.communityResourceId,
          })
        );
      }
    };
    const handleSubmitSharePopup = () => {
      setShowSharePopup(false);
    };

    return (
      <InfiniteScroll
        dataLength={1 || thisArr?.data?.length || 15}
        next={(e) => nextPageData(e, str)}
        hasMore={
          thisArr?.next_page_url ||
          thisArr?.meta?.current_page !== thisArr?.meta?.last_page
        }
        loader={
          <div className="tkl-spinner-master-data tkl-community-spinner">
            <Spinner animation="border" variant="primary" size="lr" />
          </div>
        }
        endMessage={
          <p className="font-weight-600 p-2" style={{ textAlign: "center" }}>
            You're All Caught Up
          </p>
        }
      >
        {thisArr?.map((item, index) => (
          <React.Fragment key={`t_d_${char}_${index}`}>
            <div className="dash-row-type-2 container rounded mb-2">
              <div className="row">
                <div className="t-row-name col-12 col-md-9 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
                  <div className="t-avtr-wrap position-absolute">
                    <a href={item.link}>
                      {item.type === "course" ? (
                        <ComponentAvatar
                          type={"user"}
                          data={{
                            img: {
                              md: defaultImageURL,
                              md2: defaultImageURL,
                              xs: defaultImageURL,
                              xs2: defaultImageURL,
                            },
                          }}
                        />
                      ) : (
                        <ComponentAvatar
                          type="resource"
                          data={{
                            type: item.type,
                          }}
                        />
                      )}
                    </a>
                  </div>
                  <small>
                    <strong>
                      {item?.type?.charAt(0).toUpperCase() +
                        item?.type?.slice(1)}
                    </strong>
                  </small>
                  <a
                    className="d-block"
                    variant="link"
                    href={item?.redirectionUrl || "#"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item?.title || ""}
                  </a>

                  <span
                    className="t-row-name-link title_underline_hover cursor-pointer"
                    onClick={() => bookmarkItemClickHandler(item)}
                  >
                    {/* {item?.type === "text" ? "Text Content" : item?.content?.text} */}

                    {item?.type === "text"
                      ? item?.content?.data && parse(item?.content?.data)
                      : item?.content?.text && parse(item?.content?.text)}
                  </span>
                  <small className="text-muted">
                    <p>
                      {/* {dateformat(new Date(item?.updatedAt) || new Date(), "P")} */}
                      {moment(item?.updatedAt).format("MM-DD-YYYY")}
                    </p>
                  </small>
                </div>{" "}
                <Col>
                  {str === "recommended" && (
                    <div className="d-flex flex-column align-items-end justify-content-center">
                      <Button
                        size="sm"
                        className="rounded-pill"
                        title="Share"
                        onClick={() => {
                          setSelectedResource(item);
                          setTimeout(() => {
                            setShowSharePopup(true);
                          }, 100);
                          console.log("listOfGroups", listOfGroups);
                        }}
                      >
                        Share
                      </Button>
                      {/* <Button
                      className="rounded-pill"
                      title="copy to clipboard"
                      onClick={(e) => {
                        navigator.clipboard.writeText(item?.redirectionUrl);
                      }}
                    >
                      Copy url
                    </Button> */}
                    </div>
                  )}
                </Col>
              </div>
            </div>
            <hr />
            <Modal
              size="lg"
              className="modal-styles"
              show={showSharePopup}
              onHide={handleCloseSharePopup}
            >
              <Modal.Header closeButton>
                <Modal.Title
                  className="tile_e_group_tile_name"
                  style={{ maxWidth: "90%" }}
                >{`Share resource "${selectedResource?.title}"`}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row className="p-2">
                  <Col xl="3" lg="3" md="3" sm="12" xs="12">
                    <label>
                      <strong>Email :</strong>
                    </label>
                  </Col>
                  <Col>
                    <Form.Control
                      as="input"
                      size="md"
                      className="rounded-pill "
                      onChange={(e) => {
                        console.log(e.target.value);
                        setSelectedUser(e.target.value);
                      }}
                      value={selectedUser}
                      placeholder={"email"}
                    />
                  </Col>
                  <Button
                    className="btn-outline btn-secondary rounded-pill "
                    title={"Share with User"}
                    style={{ backgroundColor: "#0088CC" }}
                    onClick={handleShareWithUser}
                  >
                    {"Share"}
                  </Button>
                </Row>

                {/* <Row className="p-2">
                  <Col xl="3" lg="3" md="3" sm="12" xs="12">
                    <label>
                      <strong>Select Group: </strong>
                    </label>
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      size="md"
                      className="rounded-pill "
                      onChange={(e) => {
                        setSelectedGroup(e.target.value);
                      }}
                      value={selectedGroup}
                      placeholder={"Select Group"}
                    >
                      <option value="">Select Group</option>
                      {listOfGroups?.map((item, index) => {
                        return (
                          <option value={item?.slug}>{item?.groupName}</option>
                        );
                      })}
                    </Form.Control>
                  </Col>
                  <Button
                    className="btn-outline btn-secondary rounded-pill "
                    title={"Share with Group"}
                    style={{ backgroundColor: "#0088CC" }}
                    onClick={handleShareWithGroup}
                  >
                    {"Share"}
                  </Button>
                </Row> */}
                <Row className="mt-5 mb-1">
                  <strong className="pl-3">Share with Group</strong>
                </Row>
                <div className="tile_e_group_tile_wrapper">
                  {listOfGroups.map((item, index) => {
                    return (
                      <div className={"tile_e_group_tile"}>
                        <p className="tile_e_group_tile_name">
                          {item?.groupName}
                        </p>
                        <Button
                          className="btn-outline btn-secondary rounded-pill "
                          title={"Share with Group"}
                          size="sm"
                          style={{ backgroundColor: "#0088CC" }}
                          onClick={(e) => {
                            setSelectedGroup(item?.slug);
                            handleShareWithGroup(e);
                          }}
                        >
                          {"Share"}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-outline btn-secondary rounded-pill "
                  title={"Cancel"}
                  onClick={handleCloseSharePopup}
                >
                  {"Cancel"}
                </Button>

                <Button
                  className=" btn-outline rounded-pill btn"
                  title={"Done"}
                  onClick={handleSubmitSharePopup}
                >
                  {"Done"}
                </Button>
              </Modal.Footer>
            </Modal>
          </React.Fragment>
        ))}
      </InfiniteScroll>
    );
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>My Resources</strong>
          </h2>
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container id="mapl_tabs" defaultActiveKey="recommended">
          <Row>
            <Col xs="12" className="mb-3">
              <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="recommended"
                  >
                    <strong>Recommended</strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="bookmarked"
                  >
                    <strong>Bookmarked</strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="recommended">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("recommended")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>{" "}
                <Tab.Pane eventKey="bookmarked">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("bookmarked")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}
