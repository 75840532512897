import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { constants } from "../api/constants";

// import ComponentPageLoader from '../shared-components/page-loader/index';

import LogoLoader from "../shared-components/page-loader/logoLoader";
import { connect, useDispatch, useSelector } from "react-redux";

import { getProfileData } from "../actions/UserActions/ProfileActions";
import _ from "lodash";
import { getRoles } from "../actions/MasterDataActions/AccountAction";
import ContentAdmin from "../components/Dashboards/ContentAdmin";
import ContentAuthor from "../components/Dashboards/ContentAuthor";
import {
  CONTENT_ADMIN,
  CONTENT_AUTHOR,
  SYSTEM_ADMIN,
  TKL_ACCOUNT_MANAGER,
  TKL_FACILITATOR,
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
  DISTRICT_FACILITATOR,
  SCHOOL_FACILITATOR,
} from "../constants.js/RoleNames";
import NotFoundPage from "../components/OtherPages/NotFoundPage";
import { userRoles, isFreeUser } from "../components/utility/ReUsableFunctions";
import CoursePathReport from "../components/Instructor/coursePathReport/CoursePathReport";
import FileStorageRoot from "components/FileStorage/FileStorageRoot";
import UnderMaintenance from "../components/UnderMaintenance/UnderMaintenance";
import ListOfResources from "components/community/Resources/ListOfResources";
import ListOfColleages from "components/community/ProfessionalColleagues/ListOfColleages";
import UpdateCohortMembers from "components/Cohorts/ManageCohortForAccount/UpdateMicroCohortMembers"

//import CreateCourse from '../components/CourseCreation/CreateCourse';
//import CoursePath from "../components/CoursePath/CoursePath";

const TKLReports = React.lazy(
  () => import("../components/TKLReports/TKLReports")
);
const PageDefault = React.lazy(() => import("../pages/default/index"));
// const PageLogin = React.lazy(() => import('../pages/login/index'));
const PageLogin = React.lazy(() => import("../components/UserLogin/login"));

const PageDashboardDistrictAdmin = React.lazy(
  () => import("../pages/dashboards/index-districtadmin")
);

const PageChangePassword = React.lazy(
  () => import("../components/UserProfile/ChangePassword")
);

//const PageCreateCourse = React.lazy(() => import('../pages/create-course/index'));
const PageCourseReview = React.lazy(
  () => import("../components/Instructor/IntructorDashboard")
);
const PageCourseReviewGPLCMICRO = React.lazy(
  () => import("../components/Instructor/IntructorDashboardMicroGPLC")
);
const PageCourseReviewMICRO = React.lazy(
  () => import("../components/Instructor/InstructorDashboardMicro")
);

const PageCourseReviewKidvision = React.lazy(
  () => import("../components/Instructor/InsructorDashboardKidvision")
);

const PageCourseReviewOld = React.lazy(
  () => import("../pages/course-review/index")
);
const PageCoursePath = React.lazy(() => import("../pages/course-path/index"));
const PageManageCohorts = React.lazy(
  () =>
    import(
      "../components/Cohorts/ManageCohortForAccount/ManageCohortForAccount"
    )
);
const PageManageMembers = React.lazy(
  () => import("../pages/manage-members/index")
);
const CatalogComprehensive = React.lazy(
  () => import("../components/Catalog/Comprehensive/CatalogComprehensive")
);
const CatalogMicro = React.lazy(()=> import("../components/Catalog/Micro/CatalogMicro" ));

const CatalogKidVision = React.lazy(()=> import("../components/Catalog/KidVision/CatalogKidVision" ));

const PageCart = React.lazy(() => import("../pages/cart/index"));
const PageCatalogGPLC = React.lazy(
  () => import("../components/Catalog/GPLCCatalog/GPLCCatalog")
);
const PageCatalogMicro = React.lazy(
  () => import("../pages/catalog/index-micro")
);
const PageCommunity = React.lazy(() => import("../components/community/index"));
const PageCommunityOld = React.lazy(() => import("../pages/community/index"));
const PageColleagues = React.lazy(
  () =>
    import(
      "../components/community/ProfessionalColleagues/ProfessionalColleagues"
    )
);

const PageActivitypathOld = React.lazy(
  () => import("../pages/activity-path/index")
);
const PageActivitypath = React.lazy(
  () => import("../components/ActivityPath/ActivityEdit")
);
const PageCreateCourse = React.lazy(
  () => import("../components/CourseCreation/CreateCourse")
);
const CourseHomePage = React.lazy(
  () => import("../components/CourseCreation/CourseHomePage")
);
const LearnerHome = React.lazy(
  () => import("../components/Leaner/LeanderHome")
);
const CoursePath = React.lazy(
  () => import("../components/CoursePath/CoursePath")
);
const MasterData = React.lazy(
  () => import("../components/MasterData/MasterData")
);
const TKLAdmin = React.lazy(() => import("../components/TKLAdmin/TKLAdmin"));

const CourseDevelopmentStatus = React.lazy(
  () => import("../components/CourseDevelopmentStatus/CourseDevelopmentStatus")
);
const ManageCohorts = React.lazy(
  () => import("../components/Cohorts/ManageCohorts/ManageCohorts")
);
const Cohorts = React.lazy(() => import("../components/Cohorts/Cohorts"));
const DraftRichText = React.lazy(
  () => import("../components/DraftRichTextEditor/DraftRichTextEditor")
);

const CatalogWebsite = React.lazy(
  () => import("../components/Catalog/Website/CatalogWebsite")
);

const BundleCodes = React.lazy(
  () => import("../components/Catalog/Comprehensive/BundleCodes")
);

const ExternalBundleCodes = React.lazy(
  () => import("../components/Catalog/Website/ExternalBundleCodes")
);
const Register = React.lazy(
  () => import("../components/UserRegister/Register")
);
const ProfileCreate = React.lazy(
  () => import("../components/UserProfile/CreateProfile/CreateProfileRootPage")
);
const ProfileData = React.lazy(
  () => import("../components/UserProfile/ProfileBio/ProfileData")
);
const MainCart = React.lazy(
  () => import("../components/Catalog/Cart/MainCart")
);
const MainCartWebsite = React.lazy(
  () => import("../components/Catalog/CartWebsite/MainCartWebsite")
);
const TKLAccountManager = React.lazy(
  () => import("../components/Dashboards/TklAccountManager")
);
const InstructionalCoach = React.lazy(
  () => import("../components/Dashboards/InstructionalCoach")
);

const DashboardPaidUser = React.lazy(
  () => import("../components/Dashboards/PaidUser")
);
const DistrictFacilitator = React.lazy(
  () => import("../components/Dashboards/DistrictFacilitator")
);
const SchoolFacilitator = React.lazy(
  () => import("../components/Dashboards/SchoolFacilitator")
);
const SystemAdmin = React.lazy(
  () => import("../components/Dashboards/SystemAdmin")
);

const InformationSheets = React.lazy(
  () => import("../components/InformationSheets/InformationSheets")
);

const PreviousPolls = React.lazy(
  () => import("../components/community/MontlyPolls/AllMonthPolls")
);
const AllDiscussionForums = React.lazy(
  () =>
    import(
      "../components/dashboard-tile/tiles/AllDisucssionForums/AllDiscussionForums"
    )
);

const MainRoutes = (props) => {
  const dispatch = useDispatch();
  const { roles } = props.userData;

  useEffect(() => {
    dispatch(getProfileData(false, false, true));
  }, []);

  // const getComponentBasedOnRole = () => {
  //     // if(roles.length>0){
  //     //     if(roles?.includes("CONTENT ADMIN")){
  //     //         return <ContentAdmin />;
  //     //     }else if(roles?.includes("CONTENT AUTHOR")){
  //     //         return <ContentAuthor />;
  //     //     }else{
  //     //         return <Suspense fallback={<LogoLoader />}><PageDefault /></Suspense>;
  //     //     }
  //     // }else{
  //     //     return <Suspense fallback={<LogoLoader />}><PageDefault /></Suspense>;
  //     // }
  //     return <ContentAdmin />;
  // }

  //  console.log("currentRoles:::",roles);
  const PrivateRoute = ({ children, currentRoles, ...rest }) => {
    //    console.log("currentRoles:::",currentRoles,roles,roles?.some(r=> currentRoles.includes(r)));
    return (
      <Route
        {...rest}
        render={({ location }) => {
          return roles?.some((r) => currentRoles.includes(r)) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }}
      />
    );
  };
  const authenticatedUser = () => {
    return (
      // <HashRouter>
      <Switch>
        <Route exact path="/see_all">
          <PageDefault />
        </Route>
        <Route exact path="/informationSheets">
          <InformationSheets />
        </Route>
        <Route exact path="/register">
          <Register />
        </Route>
        <Route exact path="/forgotPassword">
          <PageLogin />
        </Route>
        <Route exact path="/changePassword">
          <PageLogin />
        </Route>
        <Route exact path="/courseHomePage">
          <CourseHomePage />
        </Route>
        <Route exact path="/toCart">
          <MainCart />
        </Route>
        <Route exact path="/toCartWebsite">
          <MainCartWebsite />
        </Route>
        <Route exact path="/tklAdmin/account/:accountId">
          <TKLAdmin />
        </Route>
        <Route exact path="/tklAdmin">
          <TKLAdmin />
        </Route>
        <Route exact path="/tklReports">
          <Suspense fallback={<LogoLoader />}>
            <TKLReports />
          </Suspense>
        </Route>
        <Route exact path="/instructorHomePage">
          <LearnerHome />
        </Route>
        <Route exact path="/learnerHomePage">
          <LearnerHome />
        </Route>
        <Route exact path="/createProfile">
          <ProfileCreate />
        </Route>
        <Route exact path="/profileData">
          <ProfileData />
        </Route>
        <Route exact path="/OtherProfile/:userSlugId">
          <ProfileData />
        </Route>
        <Route exact path="/external-catalog">
          <CatalogWebsite />
        </Route>
        <Route exact path="/manageCohorts">
          <Suspense fallback={<LogoLoader />}>
            <ManageCohorts />
          </Suspense>
        </Route>
        <Route exact path="/manageCohorts/:programType">
          <Suspense fallback={<LogoLoader />}>
            <ManageCohorts />
          </Suspense>
        </Route>
        <Route exact path="/changeTempPassword">
          <Suspense fallback={<LogoLoader />}>
            <PageChangePassword />
          </Suspense>
        </Route>
        <Route exact path="/courseDevelopmentStatus">
          <Suspense fallback={<LogoLoader />}>
            <CourseDevelopmentStatus />
          </Suspense>
        </Route>
        <Route exact path="/CoursePath">
          <Suspense fallback={<LogoLoader />}>
            <CoursePath />
          </Suspense>
        </Route>
        <Route exact path="/:id/CoursePath">
          <Suspense fallback={<LogoLoader />}>
            <CoursePath />
          </Suspense>
        </Route>
        <Route exact path="/masterData">
          <Suspense fallback={<LogoLoader />}>
            <MasterData />
          </Suspense>
        </Route>
        <Route exact path="/DraftRichText">
          <Suspense fallback={<LogoLoader />}>
            <DraftRichText />
          </Suspense>
        </Route>
        <Route
          exact
          path="/introductionActivity/:courseSlugId/CoursePath/:activitySlugId/:introId/activity-path/:mode"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        <Route
          exact
          path="/fragmentsActivity/:courseSlugId/CoursePath/:activitySlugId/activity-path/:mode"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        <Route
          exact
          path="/cohortIntroduction/:cohortActivity/:cohortId/introduction/:introId/:activityIndex/:activitySlugId"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        <Route
          exact
          path="/cohortIntroduction/:cohortActivity/:cohortId/introduction/:introId/:activityIndex/:activitySlugId/cohortUserLearner/:cohortUserLearner"
        >
          <Suspense fallback={<LogoLoader />}>
            <PageActivitypath />
          </Suspense>
        </Route>
        <Route
          exact
          path="/cohortIntroduction/:cohortActivity/:cohortId/introduction/:introId/:activityIndex/:activitySlugId/cohortInstructor/:cohortInstructor/:userSlug"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        <Route
          exact
          path="/cohortIntroduction/:cohortActivity/:cohortId/introduction/:introId/:activityIndex/:activitySlugId/cohortInstructor/:cohortInstructor/:userSlug/:cohortInstructorReadOnly"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        {/* for participantReport */}
        <Route
          exact
          path="/cohortIntroduction/:cohortActivity/:cohortId/introduction/:introId/:activityIndex/:activitySlugId/cohortInstructor/:cohortInstructor/:userSlug/:cohortInstructorReadOnly/:participantReport"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        <Route
          exact
          path="/:cohortActivity/:cohortId/:moduleIndex/:sectionIndex/:activityIndex/:activitySlugId/cohortUserLearner/:cohortUserLearner"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        <Route
          exact
          path="/:cohortActivity/:cohortId/:moduleIndex/:sectionIndex/:activityIndex/:activitySlugId"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        <Route
          exact
          path="/:cohortActivity/:cohortId/:moduleIndex/:sectionIndex/:activityIndex/:activitySlugId/cohortInstructor/:cohortInstructor/:userSlug"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        <Route
          exact
          path="/:cohortActivity/:cohortId/:moduleIndex/:sectionIndex/:activityIndex/:activitySlugId/cohortInstructor/:cohortInstructor/:userSlug/:cohortInstructorReadOnly"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        {/* for participantReport */}
        <Route
          exact
          path="/:cohortActivity/:cohortId/:moduleIndex/:sectionIndex/:activityIndex/:activitySlugId/cohortInstructor/:cohortInstructor/:userSlug/:cohortInstructorReadOnly/:participantReport"
        >
          <Suspense fallback={<LogoLoader />}>
            {" "}
            <PageActivitypath />
          </Suspense>
        </Route>
        {/* fot cohort user direct quiz*/}
        <Route exact path="/CoursePathForCohort/:id/:isLearner">
          <Suspense fallback={<LogoLoader />}>
            <CoursePath />
          </Suspense>
        </Route>
        <Route exact path="/CoursePathForCohort/:id">
          <Suspense fallback={<LogoLoader />}>
            <CoursePath />
          </Suspense>
        </Route>
        <Route exact path="/CoursePathReport/:courseSlug/:cohortId/:userSlug">
          <Suspense fallback={<LogoLoader />}>
            <CoursePathReport />
          </Suspense>
        </Route>
        {/* <Route path="/redirect/:courseSlugId/CoursePath/:activitySlugId/activity-path/:mode">
            <PageActivitypath />
        </Route> */}
        <Route exact path="/:id/create-course">
          <Suspense fallback={<LogoLoader />}>
            <PageCreateCourse />
          </Suspense>
        </Route>
        <Route exact path="/loginPage">
          <Suspense fallback={<LogoLoader />}>
            <PageLogin />
          </Suspense>
        </Route>
        <Route exact path="/dashboard-paid-user">
          <Suspense fallback={<LogoLoader />}>
            <DashboardPaidUser />
          </Suspense>
        </Route>
        <Route exact path="/dashboard-district-admin">
          <Suspense fallback={<LogoLoader />}>
            <PageDashboardDistrictAdmin />
          </Suspense>
        </Route>
        <Route exact path="/dashboard-account-manager">
          <Suspense fallback={<LogoLoader />}>
            <TKLAccountManager />
          </Suspense>
        </Route>
        <Route exact path="/dashboard-course-facilitator">
          <Suspense fallback={<LogoLoader />}>
            <DistrictFacilitator />
          </Suspense>
        </Route>
        <Route exact path="/dashboard-district-facilitator">
          <Suspense fallback={<LogoLoader />}>
            <DistrictFacilitator />
          </Suspense>
        </Route>
        <Route exact path="/dashboard-school-facilitator">
          <Suspense fallback={<LogoLoader />}>
            <SchoolFacilitator />
          </Suspense>
        </Route>
        <Route exact path="/dashboard-instructional-coach">
          <Suspense fallback={<LogoLoader />}>
            <InstructionalCoach />
          </Suspense>
        </Route>
        <Route exact path="/content-admin">
          <ContentAdmin />
        </Route>
        <Route exact path="/content-author">
          <ContentAuthor />
        </Route>
        {/* <Route path="/editmyprofile">
            <Suspense fallback={<LogoLoader />}><PageMyProfile /></Suspense>
        </Route> */}
        <Route exact path={`/${constants.pages.CREATE_COURSE}`}>
          <Suspense fallback={<LogoLoader />}>
            <PageCreateCourse />
          </Suspense>
        </Route>
        <Route exact path={`/${constants.pages.UPDATE_COURSE}`}>
          <Suspense fallback={<LogoLoader />}>
            <PageCreateCourse />
          </Suspense>
        </Route>
        <Route exact path="/course-review">
          <Suspense fallback={<LogoLoader />}>
            <PageCourseReview />
          </Suspense>
        </Route>
        <Route exact path="/course-review/:isDirectCourseReviewPage">
          <Suspense fallback={<LogoLoader />}>
            <PageCourseReview />
          </Suspense>
        </Route>
        <Route exact path="/course-review-gplc">
          <Suspense fallback={<LogoLoader />}>
            <PageCourseReviewGPLCMICRO />
          </Suspense>
        </Route>
        <Route exact path="/course-review-micro">
          <Suspense fallback={<LogoLoader />}>
            <PageCourseReviewMICRO />
          </Suspense>
        </Route>
        <Route exact path="/course-review-kidvision">
          <Suspense fallback={<LogoLoader />}>
            <PageCourseReviewKidvision />
          </Suspense>
        </Route>
        <Route exact path="/course-review-old">
          <Suspense fallback={<LogoLoader />}>
            <PageCourseReviewOld />
          </Suspense>
        </Route>
        <Route exact path="/course-path/:mode">
          <Suspense fallback={<LogoLoader />}>
            <PageCoursePath />
          </Suspense>
        </Route>
        <Route exact path="/activity-path/:mode">
          <PageActivitypath />
        </Route>
        
        <Route exact path="/activity-path-old/:mode">
          <PageActivitypathOld />
        </Route>

        <Route exact path="/updateMicroCohortMember/:accountId/:schoolId">
          <UpdateCohortMembers/> 
        </Route>

        <Route exact path="/updateKidVisionCohortMember/:accountId/:schoolId">
          <UpdateCohortMembers/> 
        </Route>


        <PrivateRoute
          currentRoles={[CONTENT_ADMIN, SYSTEM_ADMIN, TKL_ACCOUNT_MANAGER]}
          exact
          path="/manage-cohorts/"
        >
          <Suspense fallback={<LogoLoader />}>
            <PageManageCohorts />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute
          currentRoles={[
            CONTENT_ADMIN,
            SYSTEM_ADMIN,
            TKL_FACILITATOR,
            CONTENT_AUTHOR,
          ]}
          exact
          path="/fileStorage"
        >
          <Suspense fallback={false}>
            <FileStorageRoot />
          </Suspense>
        </PrivateRoute>
        <PrivateRoute
          currentRoles={[CONTENT_ADMIN, SYSTEM_ADMIN, TKL_ACCOUNT_MANAGER, DISTRICT_ADMIN, SCHOOL_ADMIN, DISTRICT_FACILITATOR, SCHOOL_FACILITATOR,]}
          exact
          path="/manage-cohorts/:mode"
        >
          <Suspense fallback={<LogoLoader />}>
            <PageManageCohorts />
          </Suspense>
        </PrivateRoute>
        <Route exact path="/manage-members">
          <Suspense fallback={<LogoLoader />}>
            <PageManageMembers />
          </Suspense>
        </Route>
        <Route exact path="/catalog-comprehensive">
          <Suspense fallback={<LogoLoader />}>
            <CatalogComprehensive />
          </Suspense>
        </Route>
        <Route exact path ="/catalog-micro">
          <Suspense fallback="loading">
            <CatalogMicro />
          </Suspense>
        </Route>
        <Route exact path ="/catalog-KidVision">
          <Suspense fallback="loading">
            <CatalogKidVision />
          </Suspense>
        </Route>
        <Route exact path="/bundle-codes">
          <Suspense fallback={<LogoLoader />}>
            <BundleCodes />
          </Suspense>
        </Route>
        <Route exact path="/cart-comprehensive/:codeapplied">
          <Suspense fallback={<LogoLoader />}>
            <PageCart />
          </Suspense>
        </Route>
        <Route exact path="/catalog/:mode/:accountId/:schoolId/:cohortGroupId">
          <Suspense fallback={<LogoLoader />}>
            <PageCatalogGPLC />
          </Suspense>
        </Route>
        <Route exact path="/dashboard/catalog/:mode/">
          <Suspense fallback={<LogoLoader />}>
            <PageCatalogGPLC />
          </Suspense>
        </Route>
        <Route exact path="/catalog">
          <Suspense fallback={<LogoLoader />}>
            <PageCatalogGPLC />
          </Suspense>
        </Route>
        <Route exact path="/colleagues">
          <Suspense fallback={<LogoLoader />}>
            <PageColleagues />
          </Suspense>
        </Route>
        <Route exact path="/catalog-micro/:role">
          <Suspense fallback={<LogoLoader />}>
            <PageCatalogMicro />
          </Suspense>
        </Route>
        <Route exact path="/community">
          <Suspense fallback={<LogoLoader />}>
            <PageCommunity />
          </Suspense>
        </Route>
        <Route exact path="/community/:groupId">
          <Suspense fallback={<LogoLoader />}>
            <PageCommunity />
          </Suspense>
        </Route>
        <Route exact path="/community/:groupId/add-colleagues">
          <Suspense fallback={<LogoLoader />}>
            <ListOfColleages />
          </Suspense>
        </Route>
        <Route exact path="/community/resources/all-tkl-recommended-resouces">
          <Suspense fallback={<LogoLoader />}>
            <ListOfResources />
          </Suspense>
        </Route>
        <Route exact path="/community/forum/:forumId">
          <Suspense fallback={<LogoLoader />}>
            <PageCommunity />
          </Suspense>
        </Route>
        <Route exact path="/community/PostItem/:bookmarkPostId">
          <Suspense fallback={<LogoLoader />}>
            <PageCommunity />
          </Suspense>
        </Route>
        <Route exact path="/all_polls">
          <Suspense fallback={<LogoLoader />}>
            <PreviousPolls />
          </Suspense>
        </Route>
        <Route exact path="/community-old">
          <Suspense fallback={<LogoLoader />}>
            <PageCommunityOld />
          </Suspense>
        </Route>
        PageCommunity
        <Route exact path="/default">
          <Redirect to="/" />
        </Route>
        <Route exact path="/:courseSlug/:programSelected/cohorts">
          <Cohorts />
        </Route>
        <Route exact path="/dashboard-system-admin"></Route>
        <Route exact path="/discussionForums/:cohortId">
          <Suspense fallback={<LogoLoader />}>
            <AllDiscussionForums />
          </Suspense>
        </Route>
        <Route exact path="/">
          {isFreeUser(roles) ? (
            // <PageCommunity />
            <ContentAdmin />
          ) : (
            <ContentAdmin />
          )}
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
        
      </Switch>
    );
  };
  const unauthenticatedUser = () => {
    return (
      <Switch>
        <Route path="/see_all">
          <PageDefault />
        </Route>

        <Route path="/register">
          <Register />
        </Route>

        <Route path="/forgotPassword">
          <PageLogin />
        </Route>

        <Route exact path="/changeTempPassword">
          <Suspense fallback={<LogoLoader />}>
            <PageChangePassword />
          </Suspense>
        </Route>

        <Route path="/changePassword">
          <PageLogin />
        </Route>

        <Route path="/external-catalog">
          <CatalogWebsite />
        </Route>

        <Route path="/external-bundlecodes">
          <ExternalBundleCodes />
        </Route>
        <Route path="/toCartWebsite">
          <MainCartWebsite />
        </Route>
        <Route path="*">
          <PageLogin />
        </Route>
      </Switch>
    );
  };
  return (
    <React.Fragment>
      <Router>
        <React.Fragment>
          {/* {props.isLoading && <LogoLoader />} */}
          {props.isLoading && <LogoLoader />}
          {!props.isUnderMaintenance ? (
            localStorage.getItem("token") &&
            localStorage.getItem("isTemporaryPassword") === "false" ? (
              <>{!_.isEmpty(roles) && authenticatedUser()}</>
            ) : (
              <>{unauthenticatedUser()}</>
            )
          ) : (
            <Switch>
              <Route path="*">
                <UnderMaintenance />
              </Route>
            </Switch>
          )}
        </React.Fragment>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.coursehome.isLoading,
    userData: state.users.userData,
    userRoles: state.tkladmin.roles,
  };
};
const mapDispatchToProps = () => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(MainRoutes);
