import {
  ConfirmationPopup,
  InfoPopup,
} from "components/utility/ReUsableFunctions";
import React, { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { FileIcon, defaultStyles } from "react-file-icon";
import FileViewer from "react-file-viewer";
import "../../components/ActivityPath/activity-edit.scss";

function File({ item }) {

  let updatedHttpsUrl = item?.url.replace("http", "https")
  let extension = item.name.split(".");
  extension = extension[extension.length - 1];
  let isFiles = ["pdf", "docx", "xlsx", "csv", "mov", "txt", "pages"].includes(
    extension.toLowerCase()
  );
  const [showFileViewer, setShowFileViewer] = useState(false);

  const handleFileOpen = () => {
    setShowFileViewer(true);
  };

  return (
    <div
      style={{
        backgroundColor: "aliceblue",
        maxHeight: "180px",
        maxWidth: "220px",
        height: "180px",
        width: "220px",
        borderRadius: "0.5em",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
      className={"m-2 p-2"}
    >
      <div style={{ width: "68px", marginBottom: "5px" }} title={item.name}>
        {
          <FileIcon
            extension={`${extension}`}
            {...defaultStyles[extension]}
            key={Math.random()}
          />
        }
      </div>
      <label
        className="FileStorageRoot__Activity__folder__container__label"
        title={item.name}
        style={{ marginTop: "15px" }}
      >
        {item.name}
      </label>
      <DropdownButton
        className="fileStorage__folder__dropdown"
        title=""
        size="sm"
        variant="primary"
        direction="top"
        style={{ backgroundColor: "lavender" }}
      >
        <Dropdown.Item onClick={handleFileOpen}>Open</Dropdown.Item>
        <a style={{color: "black", paddingLeft: "27px"}}href={updatedHttpsUrl} download>Download</a>
        {/* <Dropdown.Divider /> */}
      </DropdownButton>

      {
        <InfoPopup
          //   size="xl"
          className={` ${
            isFiles
              ? "preview_files_container modal-learner-Popup-DiscussionForm-box"
              : "preview_files_container isNotFiles modal-learner Popup-DiscussionForm-box "
          } `}
          title={item.name}
          body={
            <>
              {isFiles ? (
                <FileViewer
                  fileType={extension}
                  filePath={item.url}
                  onError={() => {
                    setShowFileViewer(false);
                  }}
                  key={Math.random()}
                />
              ) : ["png", "jpg", "PNG", "JPG", "jpeg", "JPEG"].includes(
                  extension
                ) ? (
                <img
                  src={item?.url || ""}
                  className="embed_media"
                  alt={item?.url}
                />
              ) : (
                <embed src={item?.url || ""} className="embed_media" />
              )}
            </>
          }
          primaryBtn={"Ok"}
          secondaryBtn="Cancel"
          handleClose={() => {
            setShowFileViewer(false);
          }}
          handleSubmit={() => {
            setShowFileViewer(false);
          }}
          show={showFileViewer}
        />
      }
    </div>
  );
}

export default File;
