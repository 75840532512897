export class UserArrayFieldsData {
  public name?: "";
  public slug?: "";
}
export class CourseCreationModel {
  constructor(
    name,
    slug,
    description,
    status,
    courseDisplayId,
    updatedAt,
    programsAvailable
  ) {
    this.name = name || "";
    this.slug = slug || "";
    this.description = description || "";
    this.status = status || "";
    this.courseDisplayId = courseDisplayId || "";
    this.updatedAt = updatedAt || "";
    this.programsAvailable = programsAvailable || { name: "", slug: "" };
  }
  public name: "";
  public slug: "";
  public description: "";
  public status: "";
  public courseDisplayId: "";
  public updatedAt: "";
  public programsAvailable: Array<UserArrayFieldsData> = [];
  public bannerImage = {};
  public avatarImage = {};
}

export class UserProfileData {
  public avatarImg = {};
  public bannerImg = {};
  firstName = "";
  middleName = "";
  lastName = "";
  email = "";
  roles = [];
  positionTitlesOther = "";
  school = "";
  schoolPrivate = false;
  district = "";
  districtPrivate = false;
  country = new UserArrayFieldsData();
  countryPrivate = false;
  statesPrivate = false;
  aboutUser = "";
  hobbies = "";
  positionTitles: Array<UserArrayFieldsData> = [];
  subArea: Array<UserArrayFieldsData> = [];
  gradeLevel: Array<UserArrayFieldsData> = [];
  state = new UserArrayFieldsData();
  joinedTKL = "";
  points = null;
  lastAccessedAt = "";
  userId = null;
}
export class UserCoursesModel {
  coursesCreated: Array<CourseCreationModel> = [];
}
