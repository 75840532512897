import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MultiSelectDropdown from "../../Cohorts/CohortFields/MultiSelectDropdown";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  addUser,
  updateUserRoleStatus, updateUserRole
} from "../../../actions/MasterDataActions/UserAction";
import { useForm } from "react-hook-form";
import {
  GPLC_PROGRAM_MEMBER,
  TKL_MEMBER,
  MICRO_PROGRAM_MEMBER,
  KIDVISION_PROGRAM_MEMBER,
  GPLC_PROGRAM_MEMBER_NAME,
  SCHOOL_ADMIN,
  SCHOOL_FACILITATOR,
} from "../../../constants.js/RoleNames";

function UserForm(props) {
  const dispatch = useDispatch();
  const { updateData, isCreate, handleClose, type } = props;
  const [roleObject, setRoleObject] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userStatus, setUserStatus] = useState(
    updateData.status ? updateData.status : "INACTIVE"
  );
  const { roles, accountType } = useSelector((state) => state.tkladmin);
  const [firstName, setFirstName] = useState(props?.updateData?.firstName);
  const [middleName, setMiddleName] = useState(props?.updateData?.middleName);
  const [lastName, setLastName] = useState(props?.updateData?.lastName);
  const[selectedUserSlug, setSelectedUserSlug] = useState(updateData?.slug);
  
  const updateName = (event, nameType) => {
    if(nameType === "fname"){
      setFirstName(event?.target?.value);
    }else if(nameType === "mname"){
      setMiddleName(event?.target?.value);
    }else if(nameType === "lname"){
      setLastName(event?.target?.value);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let selectedRoles = [];
    //selectedRoles.push({name:"TKL MEMBER (FREE USER)", slug: "tkl-member (free user)"})
    updateData?.role?.forEach((role) => {
      if (userRoles.find((findrole) => findrole.name === role)) {
        selectedRoles.push({ name: role, slug: role, isChecked: true });
      }
    });

    setRoleObject(selectedRoles);
  }, [updateData, userRoles]);

  useEffect(() => {
    let userRoles = [];
    roles?.[0].forEach((role) => {
      if (type === "accountDistrictData") {
        if (
          [
            TKL_MEMBER,
            "DISTRICT ADMIN",
            "DISTRICT FACILITATOR",
            "SCHOOL FACILITATOR",
            "SCHOOL ADMIN",
          ].includes(role)
        ) {
          userRoles.push({
            name: role,
            slug: role?.replace(" ", "-")?.toLowerCase(),
          });
        }
      } else if (type === "accountSchoolData") {
        if (
          [
            TKL_MEMBER,
            accountType === 1 && GPLC_PROGRAM_MEMBER,
            (accountType === 2 || accountType === "macro") && MICRO_PROGRAM_MEMBER,
            accountType === 4 && KIDVISION_PROGRAM_MEMBER,
            "PAID USER",
            "SCHOOL ADMIN",
            accountType === 1 ? "SCHOOL FACILITATOR" : "SCHOOL FACILITATOR",
          ].includes(role)
        ) {
          // if( role!==TKL_MEMBER )
          //    {
          userRoles.push({
            name: role,
            slug: role?.replace(" ", "-")?.toLowerCase(),
          });
        // }
        }
      } else {
        if (role === "USER") {
          setRoleObject([
            {
              name: role,
              slug: role?.replace(" ", "-")?.toLowerCase(),
            },
          ]);
        }
       
        userRoles.push({
          name: role,
          slug: role?.replace(" ", "-")?.toLowerCase(),
        });
      
      }
    });
    setUserRoles(userRoles);
  }, [roles]);

  const stateHandler = (event, checked, status, selectall) => {
    if (status !== "selectAll") {
      let newData = {};
      newData["name"] = event.target.value;
      newData["slug"] = event.target.id;
      checked ? (newData["isChecked"] = true) : (newData["isChecked"] = false);

      let updatedRoleObject = [...roleObject];

      let index = _.findIndex(updatedRoleObject, { name: newData.name });

      if (index === -1) {
        updatedRoleObject.push(newData);
      } else {
        updatedRoleObject.splice(index, 1);
      }

      let tklMemberExist = _.findIndex(updatedRoleObject, { name: "TKL MEMBER (FREE USER)" });
      if(tklMemberExist === -1)
      {
      updatedRoleObject.unshift({name:"TKL MEMBER (FREE USER)", slug: "tkl-member (free user)"});
      }
      let kidVisionMemberExist = _.findIndex(updatedRoleObject, { name: "KIDVISION PROGRAM MEMBER (PAID USER)" });
      if(kidVisionMemberExist === -1 && accountType === 4)
      {
      updatedRoleObject.unshift({name:"KIDVISION PROGRAM MEMBER (PAID USER)", slug: "kidvision"});
      }

      let microMemberExist = _.findIndex(updatedRoleObject, { name: "MICRO COURSE PROGRAM MEMBER (PAID USER)" });
      if(microMemberExist === -1 && (accountType === 2 || accountType === "macro"))
      {
      updatedRoleObject.unshift({name:"MICRO COURSE PROGRAM MEMBER (PAID USER)", slug: "micro-course-program"});
      }

      if (
        newData["name"] === GPLC_PROGRAM_MEMBER
      ) {
        updatedRoleObject = updatedRoleObject.filter((obj) =>
          [GPLC_PROGRAM_MEMBER, MICRO_PROGRAM_MEMBER, TKL_MEMBER]?.includes(
            obj?.name
          )
        );
      }

      if (newData["name"] === SCHOOL_ADMIN) {
        updatedRoleObject = updatedRoleObject.filter((obj) =>
          [SCHOOL_ADMIN, SCHOOL_FACILITATOR, TKL_MEMBER, MICRO_PROGRAM_MEMBER, KIDVISION_PROGRAM_MEMBER]?.includes(obj?.name)
        );
      }

      if (newData["name"] === SCHOOL_FACILITATOR) {
        updatedRoleObject = updatedRoleObject.filter((obj) =>
          [SCHOOL_ADMIN, SCHOOL_FACILITATOR, TKL_MEMBER, MICRO_PROGRAM_MEMBER, KIDVISION_PROGRAM_MEMBER]?.includes(obj?.name)
        );
      }

      setRoleObject(updatedRoleObject);
    } else {
      if(checked)
      {
        let newUserRoles = [...userRoles];
        let index = _.findIndex(newUserRoles, { name:TKL_MEMBER});
        if(index == -1)
        {
          newUserRoles.unshift({name:"TKL MEMBER (FREE USER)", slug: "tkl-member (free user)"});
          setUserRoles(newUserRoles);
        }
      }
     
      checked ? setRoleObject(userRoles) : setRoleObject([{name:"TKL MEMBER (FREE USER)", slug: "tkl-member (free user)"}, {name:"MICRO COURSE PROGRAM MEMBER (PAID USER)", slug: "micro-course-program"}, {name:"KIDVISION PROGRAM MEMBER (PAID USER)", slug: "kidvision"}]);

    }
  };

  const changeStatus = (e) => {
    setIsSubmitted(true);
    setUserStatus(e.target.value);
  };

  const onSubmit = (e) => {
    // e.preventDefault();
    // if (!isCreate) {
    // if (roleObject.length > 0) {
    //   dispatch(
    //     updateUserRoleStatus({
    //       slug: updateData.slug,
    //       roles: roleObject.map((obj) => obj.name),
    //       status: userStatus,
    //     })
    //   );

    //   handleClose();
    // }
    // }

    // setIsSubmitted(true);

    if (isCreate) {
      console.log("....4 e", e);
      dispatch(
        addUser({
          firstName: e.firstName,
          middleName: e.middleName,
          lastName: e.lastName,
          email: e.email,
          status: userStatus,
          roles: roleObject.map((obj) => obj.name),
          slug: updateData.slug,
        })
      );

      handleClose();
    } else {
      if (roleObject.length > 0) {
        dispatch(
          updateUserRole({ 
            slug: updateData.slug,
            roles: roleObject.map((obj) => obj.name),
            email: e.email,
            status: e.status,
            firstName, 
            middleName, 
            lastName
          })
        );
        handleClose();
      }
    }
    setIsSubmitted(true);
  };
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row} controlId="firstName">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            First Name
          </Form.Label>
          <Col sm="9">
            <Form.Control
              // readOnly={!isCreate}
              type="text"
              size="sm"
              defaultValue={updateData.firstName ? updateData.firstName : ""}
              placeholder="Enter First Name"
              className="rounded-pill"
              required
              {...register("firstName",{onChange: (e) => { updateName(e, 'fname') }})}
            />
            {errors.firstName && (
              <p className="show-txt-error p-1 m-2">First Name required</p>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="middleName">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            Middle Name
          </Form.Label>
          <Col sm="9">
            <Form.Control
              size="sm"
              // readOnly={!isCreate}
              type="text"
              placeholder="Enter Middle Name"
              className="rounded-pill"
              defaultValue={updateData.middleName ? updateData.middleName : ""}
              {...register("middleName",{onChange: (e) => { updateName(e, 'mname') }})}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="lastName">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            Last Name
          </Form.Label>
          <Col sm="9">
            <Form.Control
              // readOnly={!isCreate}
              type="text"
              size="sm"
              placeholder="Enter Last Name"
              className="rounded-pill"
              defaultValue={updateData.lastName ? updateData.lastName : ""}
              required
              {...register("lastName",{onChange: (e) => { updateName(e, 'lname') }})}
            />
            {errors.lastName && (
              <p className="show-txt-error p-1 m-2">Last Name required</p>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="email">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            Email
          </Form.Label>
          <Col sm="9">
            <Form.Control
              readOnly={!isCreate}
              type="email"
              size="sm"
              placeholder="Enter Email"
              className="rounded-pill"
              defaultValue={updateData.email ? updateData.email : ""}
              {...register("email")}
            />
            {errors.email && (
              <p className="show-txt-error p-1 m-2">Email is required</p>
            )}
          </Col>
        </Form.Group>
        
        {
          updateData?.schoolName &&
          <Form.Group as={Row} controlId="">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              School/Location
            </Form.Label>
            <Col sm="9">
              <Form.Control
                disabled
                type="email"
                size="sm"
                className="rounded-pill"
                defaultValue={updateData.schoolName ? updateData.schoolName : ""}
              />
            </Col>
          </Form.Group>
        }

        <Form.Group as={Row} controlId="email">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            Role  <span className="text-danger">*</span>
          </Form.Label>
          <Col sm="9">
            <div className="multiple-select">
              <MultiSelectDropdown
                width={"100%"}
                options={userRoles}
                id={"roles"}
                data={roleObject}
                statesboolean={true}
                onSelectFieldChange={stateHandler}
              />
            </div>
            {roleObject.length === 0 && isSubmitted && (
              <p className="show-txt-error p-1 m-2">Please select Role</p>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="status">
          <Form.Label column sm="3" className="font-semi-bold-TKL">
            Status
          </Form.Label>
          <Col sm="9" className="select-container">
            <span className="select-arrow-icon material-icons">
              expand_more
            </span>
            <Form.Control
              onChange={changeStatus}
              as="select"
              size="sm"
              className="rounded-pill select-styles"
              defaultValue={
                updateData?.status ? updateData?.status : "INACTIVE"
              }
              {...register("status")}
            >
              <option name="INACTIVE">INACTIVE</option>
              <option name="ACTIVE">ACTIVE</option>
            </Form.Control>
          </Col>
          <Row className="mt-3 mr-2 ml-auto mr-3s">
            <Button
              className="btn btn-outline btn-secondary m-2 rounded-pill"
              onClick={handleClose}
            >
              Close
            </Button>
            <Button type="submit" className="m-2 btn btn-primary rounded-pill">
              {isCreate ? "Create" : "Update"}
            </Button>
          </Row>
        </Form.Group>
      </form>
    </Container>
  );
}

export default UserForm;
