import { store } from "../App";
import { types } from "./types";
const initialState = {
  notification: {},
  notification_reload:false,
};
export const notifications = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_NOTIFICATIONS:
      return {
        ...state,
        notification: action.payload,
      };

      case types.NOTIFICATION_RELOAD:
      return {
        ...state,
        notification_reload: action.payload,
      };

      case types.APPEND_NOTIFICATIONS:
        let currentState={...state};
        let currentArrayData= action?.currentNotification?.data;
        let newArrayData=action.payload?.data;
        let newArray=[...currentArrayData,...newArrayData];
        let newPayload={...action.payload,data:newArray}

        

      console.log("Current::Notification::Data",newPayload);
      return {
        ...state,
        notification: JSON.parse(JSON.stringify(newPayload)),
      };
    default:
      return state;
  }
};
