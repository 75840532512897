import { types } from "./types";
const initialState = {
  users: {},
  frameworkdomain: {},
  grades: {},
  tags: {},
  last_page: 0,
  isLoading: false,
  isReload: false,
  isAccountCreation: 1,

  error: {},
  last_page_Frameworks: 0,
  last_page_GradeLevels: 0,
  last_page_Tags: 0,
  contentArea: {},
  last_page_ContentArea: 0,
  subDomain: {},
  last_page_subDomain: 0,
  discussionFAQ: "",
  marketLocation: [],
  secondaryCertificates: [],
  secondaryCertificatesMap: {},
  positionTitles: {},
  generatedPassword: {},
  catalogueDescription: {},
};
export const masterdata = (state = initialState, action: any) => {
  switch (action.type) {
    case types.GET_ALL_USERS_MASTER_DATA:
      return {
        ...state,
        users: action.payload,
        last_page: action.payload?.last_page,
      };
    case types.IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case types.IS_RELOAD:
      return {
        ...state,
        isReload: action.payload,
      };

      case "setApiData":
        return {
          ...state,
          setapiData: action.payload,
        };

        case "getApiData":
          return {
            ...state,
            getapiData: action.payload,
          };


    case types.GENERATE_PASSWORD:
      return {
        ...state,
        generatedPassword: action.payload,
      };

    case types.GET_ALL_FRAMEWORKS:
      return {
        ...state,
        frameworkdomain: action.payload,
        last_page_Frameworks: action.payload?.last_page,
      };

    case types.GET_ALL_GRADELEVELS:
      return {
        ...state,
        grades: action.payload,
        last_page_GradeLevels: action.payload?.last_page,
      };

    case types.IS_ACCOUNT_CREATION:
      return {
        ...state,
        isAccountCreation: action.payload,
      };

    case types.GET_ALL_TAGS:
      return {
        ...state,
        tags: action.payload,
        last_page_Tags: action.payload?.last_page,
      };

    case types.GET_ALL_CONTENTAREA:
      return {
        ...state,
        contentArea: action.payload,
        last_page_ContentArea: action.payload?.last_page,
      };

    case types.GET_ALL_SUBDOMAINS:
      return {
        ...state,
        subDomain: action.payload,
        last_page_subDomain: action.payload?.last_page,
      };
    default:
      return state;
    case types.GET_DISCUSSION_FAQ:
      return {
        ...state,
        discussionFAQ: action.payload,
      };
    case types.GET_ALL_MARKET_LOCATION:
      return {
        ...state,
        marketLocation: action.payload,
      };
    case types.ADD_DATA_TO_STATE:
      return {
        ...state,
        [action.label]: action.payload,
      };
    case types.GET_POSITION_TITLES_MASTER_DATA:
      return {
        ...state,
        positionTitles: action.payload,
      };
  }
};
