/* eslint-disable @typescript-eslint/no-array-constructor */
import { settings, activity } from "./activity_settings";
export class commonArea {
  public title?: string;
  public slug?: string;
}
export class CourseDetails {
  public slug: string = "";
  public courseName: string = "";
  public courseId: number = 0;
  public courseObjective = new Array([""]);
  public courseDescription: string = "";
  public bannerImage: string = "";
  public avatarImage: string = "";
  public programsAvailable: Array<commonArea> = new Array();
  public authors: Array<commonArea> = new Array();
  public gradeLevel: Array<commonArea> = new Array();
  public contentArea: Array<commonArea> = new Array();
  public tags: Array<commonArea> = new Array();
  public frameworkDomain: Array<commonArea> = new Array();
  public frameworkSubDomain: Array<commonArea> = new Array();
  public saveAs: string = "";
  public status: string = "";
  public activityHeader: activity = new activity();
  public settings: settings = new settings();
  public Fragments = new Array();
  public cohorts = [
    {
      courseUrlLocation: "",
      courseSlug: "",
      assignedProgram: { name: "", slug: "" },
      surveyUrl: "",
      CountryComprehensive: { name: "", slug: "" },
      SelectMarketLocationComprehensive: { name: "", slug: "" },
      AssignFacilitatorComprehensive: { name: "", slug: "" },
      CohortPurposeComprehensive: { name: "", slug: "" },
      CohortTypeComprehensive: { name: "", slug: "" },

      RegistrationStartDateComprehensiveSeasonal: "",
      RegistrationEndDateComprehensiveSeasonal: "",
      TermOpenDateComprehensiveSeasonal: "",
      TermCloseDateComprehensiveSeasonal: "",
      StatusComprehensiveSeasonal: { name: "", slug: "" },

      termDaysComprehensiveRolling: "",
      externalUrlComprehensiveRolling: "",
      StatusComprehensiveRolling: { name: "", slug: "" },

      CountryGuidedPlc: { name: "", slug: "" },
      accountOrganizationGuidedPlc: { name: "", slug: "" },
      assignFacilitatorGuidedPlc: { name: "", slug: "" },
      assignTklAccountManagerGuidedPlc: { name: "", slug: "" },
      StatusGuidedPlc: { name: "", slug: "" },

      StateProvidence: [],
      Price: "",
      gradCredits: "",
      clockHours: "",
      salaryPoints: "",
      WelcomeLetter: { name: "", slug: "" },
      InformationSheet: { name: "", slug: "" },
    },
  ];
}

//registeration Datamodel
export class RegistrationApplicationData {
  public RegistrationFormData = {};
}
