import React from 'react';
import ComponentCtaBtn from './../../../shared-components/cta-btn/index';
import { Tab, Row, Col, Nav, Form } from 'react-bootstrap';

export default function TileL() {
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0"><strong>Assign Micro Courses</strong></h2>
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div style={{minWidth: '22rem', maxWidth: '24rem'}}>
          <Form>
            <Form.Group controlId="tilel_district">
              <Form.Label><strong>District / Account</strong></Form.Label>
              <Form.Control as="select" className="rounded-pill">
                <option>District / Account</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="tilel_school">
              <Form.Label><strong>Location / School</strong></Form.Label>
              <Form.Control as="select" className="rounded-pill">
                <option>District / Account</option>
              </Form.Control>
            </Form.Group>
            <div className="text-center">
              <ComponentCtaBtn data={{
                tag: "button",
                type: "submit",
                ctastyle: "primary",
                text: "View Catalog",
                title: "View Catalog",
                primaryicon: "local_mall",
                ctaicon: "chevron_right"
              }}/>
            </div>
          </Form>
        </div>
      </div>
    </article>
  );
}