import React from "react";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import ComponentNotificationBulb from "./../../../shared-components/notification-bulb/index";
import moment from "moment";

const tilejson = require("./../../../data/dashboard/tile-j.json");
const arr1 = (tilejson || []).filter((item) => item.type === "notifications");

export default function TileJ() {
  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "notifications") {
      thisArr = (arr1 || [])[0].data || [];
      char = "notifications";
    }

    return thisArr.map((item, index) => (
      <React.Fragment key={`t_j_${char}_${index}`}>
        <div className="dash-row-type-2 container rounded mb-2">
          <div className="row">
            <div className="t-row-name col-12 col-md-8 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
              <div className="t-avtr-wrap position-absolute">
                <a href={item.link}>
                  <ComponentAvatar
                    type="user"
                    data={{
                      firstname: item.firstname,
                      lastname: item.lastname,
                      img: item.img || {},
                    }}
                  />
                </a>
              </div>
              <a href={item.link} className="t-row-name-link">
                {item.name}
              </a>
              <small>
                <strong>{item.role}</strong>
              </small>
              <small className="text-muted">
                {moment(item?.createddate).format("MM-DD-YYYY")}
              </small>
            </div>
            <div className="col-6 col-md-2 p-2 d-flex flex-column justify-content-start align-items-center">
              <ComponentCtaBtn
                data={{
                  tag: "a",
                  href: "#",
                  ctastyle: "primary",
                  text: "View",
                  title: "View",
                  size: "sm",
                }}
              />
            </div>
            <div className="col-6 col-md-2 p-2 d-flex flex-column justify-content-start align-items-center">
              <ComponentCtaBtn
                data={{
                  tag: "a",
                  href: "#",
                  ctastyle: "secondary",
                  text: "",
                  title: "Print",
                  size: "sm",
                  isoutline: "true",
                  primaryicon: "more_vert",
                }}
              />
            </div>
          </div>
        </div>
        <hr />
      </React.Fragment>
    ));
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-flex flex-row position-relative">
        <div className="dash-t-h-left flex-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>Internal Notifications</strong>
          </h2>
        </div>
        <div className="dash-t-h-right py-2 px-3 flex-md-grow-1 d-flex justify-content-end align-items-center">
          <div
            className="ml-md-2 d-inline-block"
            style={{ width: "1.5rem", height: "1.5rem" }}
          >
            <ComponentNotificationBulb count={"123"} />
          </div>
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <ComponentCustomScrollbar
          className="scroll-comp"
          style={{ height: "100%" }}
        >
          {renderData("notifications")}
          <br />
        </ComponentCustomScrollbar>
      </div>
    </article>
  );
}
