import { RegistrationApplicationData } from "../components/Models/course-model";
import { types } from "./types";
const initialState = {
  RegistrationApplicationData: new RegistrationApplicationData(),
};
export const register = (state = initialState, action: any) => {
  switch (action.type) {
    case types.REGISTRATION_APPLICATION_INITIAL_DATA:
      return {
        ...state,
        RegistrationApplicationData: new RegistrationApplicationData(),
      };
    case types.REGISTRATION_FORM_DATA:
      return {
        ...state,
        RegistrationApplicationData: {
          ...state.RegistrationApplicationData,
          RegistrationFormData: {
            ...state.RegistrationApplicationData.RegistrationFormData,
            ...action.payload,
          },
        },
      };
    default:
      return state;
  }
};
