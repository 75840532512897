import React, { useState, useMemo, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getSchoolsByAccountId } from "../../../actions/MasterDataActions/AccountAction";
import { getcatalogueList } from "../../../actions/DashboardActions/TklaccountManagerActions";
import { Form, Spinner } from "react-bootstrap";
import { types } from "../../../reducers/types";
import ContextUser from "./../../../contexts/user-context";
import { getDisctrictsAccountManager } from "../../../actions/DashboardActions/TklaccountManagerActions";
import { getProgramDetailsApi } from "../../../actions/UserActions/ProfileActions";
// import "./tiles.scss";
const ActiveAccounts = ({ Program, role, history }) => {
  const [selectedItem, setSelectedItem] = useState({
    name: "Active Cohort",
    slug: "Active-Cohort",
  });

  const { districts, schools, cohortList, cohortDatagrouped } = useSelector(
    (state) => state.dashboards
  );
  const { isLoading } = useSelector((state) => state.masterdata);

  const [districtData, setDistrictData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);

  const [districtSelected, setDistrictSelected] = useState({});
  const [schoolSelected, setSchoolSelected] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProgramDetailsApi());
  }, []);
  const [gplcProgramIdFromApi, setGplcProgramIdFromApi] = useState("");
  const programDataFromStore = useSelector((state) => state.dashboards.programData);
  useEffect(() => {
    setGplcProgramIdFromApi(programDataFromStore[1]?.programId || 1)
  },[programDataFromStore])
  useEffect(() => {
    // ApiCall for gpcl homepage api gplc
    // dispatch(getDisctrictsAccountManager(Program.toLowerCase(), 1)); //isGplc
    dispatch(getDisctrictsAccountManager(Program.toLowerCase(), gplcProgramIdFromApi));
  }, [gplcProgramIdFromApi]);  

  useEffect(() => {
    dispatch(
      getcatalogueList(
        null,
        null,
        null, //schoolSelected?.schoolId,
        true //ISGPLC
      )
    );
  }, []);

  const activeHandler = (event) => {
    let value = event?.target?.innerText;
    setSelectedItem({ name: value, slug: value });
  };

  useEffect(() => {
    setDistrictData(districts);
  }, [districts]);

  useEffect(() => {
    setSchoolData(schools);
  }, [schools]);
  useEffect(() => {
    sessionStorage.setItem(
      "districtSelected",
      JSON.stringify(districtSelected)
    );
  }, [districtSelected]);

  useEffect(() => {
    sessionStorage.setItem("schoolSelected", JSON.stringify(schoolSelected));
  }, [schoolSelected]);

  // useEffect(() => {
  //   if (
  //     selectedItem?.name !== "Active Cohort" &&
  //     districtSelected?.accountId &&
  //     schoolSelected?.schoolId &&
  //     cohortList
  //   ) {
  //     dispatch(
  //       getcatalogueList(
  //         districtSelected?.stateSlug,
  //         districtSelected?.accountId,
  //         schoolSelected?.schoolId,
  //         true //ISGPLC
  //       )
  //     );
  //   }
  // }, [selectedItem]);

  const selectOnChangeHandler = (event, isSchool) => {
    if (isSchool) {
      setSchoolSelected({
        name: event.target?.value,
        slug: event.target[event.target.selectedIndex]?.dataset?.slug,
        schoolId: event.target[event.target.selectedIndex]?.dataset?.schoolid,
      });
      dispatch(
        getcatalogueList(
          districtSelected?.stateSlug,
          districtSelected?.accountId,
          event.target[event.target.selectedIndex]?.dataset?.schoolid, //schoolSelected?.schoolId,
          true //ISGPLC
        )
      );
      if (
        selectedItem?.name !== "Active Cohort" &&
        districtSelected?.accountId &&
        schoolSelected?.schoolId &&
        cohortList
      ) {
        // dispatch({
        //   type: types.RESUABLE_GET_ACTION,
        //   payload: [],
        //   label: "cohortDatagrouped",
        // });
      } else {
        // dispatch(
        //   getCohortList(
        //     districtSelected?.accountId,
        //     event.target[event.target.selectedIndex]?.dataset?.schoolid,
        //     true //isGPLC
        //   )
        // );
      }
    } else {
      dispatch({
        type: types.RESUABLE_GET_ACTION,
        payload: [],
        label: "cohortList",
      });
      dispatch({
        type: types.RESUABLE_GET_ACTION,
        payload: [],
        label: "cohortDatagrouped",
      });
      setDistrictSelected({
        name: event.target?.value,
        slug: event.target[event.target.selectedIndex]?.dataset?.slug,
        accountId: event.target[event.target.selectedIndex]?.dataset?.accountid,
        stateSlug: event.target[event.target.selectedIndex]?.dataset?.stateslug,
      });
      dispatch(
        getSchoolsByAccountId(
          event.target[event.target.selectedIndex]?.dataset.accountid,
          true,
          true //isGPLC
        )
      );
      setSchoolSelected({
        name: "",
        slug: "",
        schoolId: "",
      });
      // dispatch(
      //   getcatalogueList(
      //     districtSelected?.stateSlug,
      //     districtSelected?.accountId,
      //     schoolSelected?.schoolId
      //   )
      // );
    }
  };

  // useEffect(() => {
  //   console.log("...cohortList", cohortList, cohortDatagrouped);
  // }, [cohortList, cohortDatagrouped]);

  const renderActiveCohort = () => {
    return (
      <>
        {isLoading && (
          <div className="tkl-spinner-master-data tkl-dashboard-tile-spinner">
            <Spinner animation="border" variant="primary" size="lr" />
          </div>
        )}
        {!isLoading && (
          <Col xs="12" className="d-flex">
            <ComponentCustomScrollbar
              className="scroll-comp"
              style={{ height: "100%", width: "100%" }}
            >
              {cohortList && cohortList.length ? (
                <>
                  {cohortList &&
                    cohortList.map((item) => {
                      return (
                        <>
                          <div className="dash-row-type-2 container rounded mb-2 active_accounts_style">
                            <div className="row w-100 row_bottom_border">
                              <Row className="t-row-name col-12 col-md-9 col-xl-8 col-xxl-9 position-relative p-2 d-flex flex-column justify-content-center">
                                <Col
                                  className="font-semi-bold-TKL"
                                  style={{
                                    //  marginBottom: "0.3em",
                                    fontSize: "18px",
                                  }}
                                  sm="12"
                                  md="8"
                                >
                                  {selectedItem?.name !== "Active Topics" ? (
                                    <Button
                                      variant="link"
                                      onClick={() => {
                                        history.push("/manage-cohorts");
                                      }}
                                    >
                                      {item?.cohortName}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                                <Row className="members-count-account">
                                  <Col
                                    style={{
                                      //   marginBottom: "0.3em",
                                      fontSize: "16px",
                                    }}
                                    sm="12"
                                    md="6"
                                  >
                                    {selectedItem?.name !== "Active Topics" ? (
                                      <>
                                        <p className="font-semi-bold-TKL">
                                          Active Members
                                        </p>
                                        <p className="TKL_number_highlight">
                                          {/* {item?.cohorts?.length} Members */}
                                          {item?.cohorts?.reduce(
                                            (a, b) =>
                                              a +
                                              +(
                                                b["usersData"]["totalUsers"] ||
                                                0
                                              ),
                                            0
                                          ) -
                                            item?.cohorts?.reduce(
                                              (a, b) =>
                                                a +
                                                +(
                                                  b["usersData"][
                                                    "completedUsers"
                                                  ] || 0
                                                ),
                                              0
                                            )}{" "}
                                          Members
                                        </p>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                  <Col
                                    sm="12"
                                    md="6"
                                    style={{
                                      //  marginBottom: "0.3em",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {selectedItem?.name !== "Active Topics" ? (
                                      <>
                                        <p className="font-semi-bold-TKL">
                                          Total Members
                                        </p>
                                        <p className="TKL_number_highlight">
                                          {/* {item?.cohorts?.usersData?.totalUsers}{" "} */}
                                          {item?.cohorts?.reduce(
                                            (a, b) =>
                                              a +
                                              (b["usersData"]["totalUsers"] ||
                                                0),
                                            0
                                          )}{" "}
                                          Members
                                        </p>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                              </Row>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </>
              ) : (
                <>
                  <p className="item-not-selected">No Cohort is Active</p>
                </>
              )}
              <br />
            </ComponentCustomScrollbar>
          </Col>
        )}
      </>
    );
  };

  const renderActiveTopic = () => {
    return (
      <>
        {isLoading && (
          <div className="tkl-spinner-master-data tkl-dashboard-tile-spinner">
            <Spinner animation="border" variant="primary" size="lr" />
          </div>
        )}
        {!isLoading && (
          <Col xs="12" className="d-flex">
            <ComponentCustomScrollbar
              className="scroll-comp"
              style={{ height: "100%" }}
            >
              {cohortDatagrouped && Object.keys(cohortDatagrouped).length ? (
                <>
                  {cohortDatagrouped &&
                    Object.keys(cohortDatagrouped).map((item) => {
                      return (
                        <>
                          <div className="dash-row-type-2 container rounded mb-2 active_accounts_style">
                            <div className="row w-100 row_bottom_border">
                              <div className="t-row-name col-12 col-md-9 col-xl-8 col-xxl-9 position-relative p-2 d-flex flex-column justify-content-center">
                                <p
                                  className="font-semi-bold-TKL"
                                  style={{
                                    marginBottom: "0.3em",
                                    fontSize: "18px",
                                  }}
                                >
                                  {
                                    <>
                                      <Button
                                        variant="link"
                                        onClick={() => {
                                          history.push("/manage-cohorts");
                                        }}
                                      >
                                        {
                                          cohortDatagrouped[item][0]
                                            ?.cohortCourseName?.name
                                        }
                                      </Button>
                                    </>
                                  }
                                </p>
                                <p
                                  className="TKL_number_highlight"
                                  style={{
                                    marginBottom: "0.3em",
                                    fontSize: "16px",
                                  }}
                                >
                                  {
                                    <>
                                      {cohortDatagrouped[item]?.length}{" "}
                                      Cohort(s) Assigned
                                    </>
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </>
              ) : (
                <>
                  <p className="item-not-selected">No Active Topics</p>
                </>
              )}
              <br />
            </ComponentCustomScrollbar>
          </Col>
        )}
      </>
    );
  };

  return (
    <article
      className="dash-t-comp d-flex flex-column"
      // style={{ overflowx: "auto" }}
    >
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2
            className="h4 text-uppercase m-0"
            style={{ display: "inline-block" }}
          >
            <strong>Active {Program} Accounts</strong>
          </h2>
          {(role === "systemAdmin" || role === "accountManager") && (
            <ComponentCtaBtn
              className="account-button-style"
              onClick={() => {
                history.push("/tklAdmin");
                history.go(0);
              }}
              data={{
                tag: "button",
                type: "button",
                size: "sm",
                ctastyle: "info",
                text: "Add Account",
                title: "Add Account",
                ctaicon: "chevron_right",
              }}
            />
          )}
        </div>
      </div>
      <div style={{ marginLeft: "1em" }}>
        {(role === "systemAdmin" || role === "accountManager") && (
          <Row
            className="d-flex flex-row"
            style={{
              flexWrap: "nowrap",
              marginTop: "1em",
              width: "calc(100%)",
            }}
          >
            <Col
              // sm="12"
              md="3"
              lg="2"
              xxl="2"
              xl="4"
              style={{ marginRight: "1em" }}
            >
              <ComponentCtaBtn
                onClick={() => {
                  Program === "GPLC"
                    ? history.push("/manage-cohorts")
                    : history.push("/manage-cohorts/micro");
                  history.go(0);
                }}
                data={{
                  tag: "button",
                  type: "button",
                  size: "sm",
                  ctastyle: "info",
                  text: "Manage Cohort",
                  title: "Manage Cohort",
                  ctaicon: "chevron_right",
                }}
              />
            </Col>
            <Col
              // sm="12"
              md="3"
              lg="4"
              xxl="3"
              xl="4"
            >
              <ComponentCtaBtn
                onClick={() => {
                  Program === "GPLC"
                    ? history.push("/manage-cohorts")
                    : history.push("/manage-cohorts/micro");
                  history.go(0);
                  sessionStorage.setItem("manageCohortsPopup", true);
                }}
                data={{
                  tag: "button",
                  type: "button",
                  size: "sm",
                  ctastyle: "primary",
                  text: "Create Cohort",
                  title: "Create Cohort",
                  ctaicon: "add",
                }}
              />
            </Col>
            <Col
              // sm="12"
              md="3"
              lg="4"
              xxl="3"
              xl="4"
            >
              <ComponentCtaBtn
                onClick={() => {
                  history?.push(
                    `/catalog/gplc/${districtSelected?.accountId}/${schoolSelected?.schoolId}/null`
                  );
                }}
                data={{
                  tag: "button",
                  type: "button",
                  size: "sm",
                  ctastyle: "secondary",
                  text: " Assign Focus Topic",
                  title: "Assign Focus Topic",
                }}
              />
            </Col>
          </Row>
        )}
        <div className="dash-t-form py-2" style={{ marginTop: "1em" }}>
          <Form>
            <Form.Row>
              <Form.Group
                as={Col}
                // controlId={`tilehdistrict_${Program}`}
                className="m-0"
              >
                <Form.Label>
                  <strong>District</strong>
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  className="rounded-pill"
                  onChange={(event) => selectOnChangeHandler(event, false)}
                  id={`districtValue_${Program}`}
                >
                  <option value="">Select Disctict / Account</option>
                  {districtData &&
                    districtData.map((item) => {
                      return (
                        <option
                          value={item?.districtName}
                          data-slug={item?.slug}
                          data-accountid={item?.accountId}
                          data-stateslug={item?.state?.slug}
                          selected={
                            districtSelected?.name === item?.districtName
                          }
                        >
                          {item?.districtName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>

              <Form.Group
                as={Col}
                // controlId={`tilehdischool_${Program}`}
                className="m-0"
              >
                <Form.Label>
                  <strong>School</strong>
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  className="rounded-pill"
                  onChange={(event) => selectOnChangeHandler(event, true)}
                  id={`districtValue_${Program}`}
                >
                  <option value="" selected={schoolSelected?.name === ""}>
                    Select Location / School
                  </option>
                  {schoolData &&
                    schoolData.map((item) => {
                      return (
                        <option
                          value={item?.schoolName}
                          data-slug={item?.slug}
                          data-schoolid={item?.schoolId}
                          selected={schoolSelected?.name === item?.schoolName}
                        >
                          {item?.schoolName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
        <Row
          style={{ flexWrap: "nowrap", marginTop: "1em", marginLeft: "0.1em" }}
        >
          <Col sm="12" md="5">
            <button
              className="sm btn btn-rounded rounded-pill"
              onClick={activeHandler}
              id={`ActiveCohort_${Program}`}
              style={{
                backgroundColor:
                  selectedItem?.name !== "Active Topics" ? "#ede8e8" : "#fff",
                border: "1px solid #dedede",
                boxShadow: "none",
              }}
            >
              Active Cohort
            </button>
          </Col>
          <Col sm="12" md="5">
            <button
              className="sm btn btn-rounded rounded-pill"
              id={`ActiveTopics_${Program}`}
              onClick={activeHandler}
              style={{
                backgroundColor:
                  selectedItem?.name === "Active Topics" ? "#ede8e8" : "#fff",
                border: "1px solid #dedede",
                boxShadow: "none",
              }}
            >
              Active Topics
            </button>
          </Col>
        </Row>
      </div>

      <Row
        className="flex-grow-1"
        style={{ marginTop: "1.2em", marginLeft: "1em" }}
      >
        {districtSelected?.accountId && schoolSelected?.schoolId ? (
          <>
            {selectedItem?.name === "Active Cohort" ? (
              <>{renderActiveCohort()}</>
            ) : (
              <>{renderActiveTopic()}</>
            )}
          </>
        ) : (
          <>
            <p className="item-not-selected">Please Select School</p>
          </>
        )}
      </Row>
    </article>
  );
};
export default React.memo(withRouter(ActiveAccounts));

/* <Row
style={{ flexWrap: "nowrap", marginTop: "1em", marginLeft: "0.1em" }}
>
<Col sm="12" md="5">
  <div>
    <label className="font-semi-bold-TKL">Account/District</label>
  </div>
  <select
    size="sm"
    className="rounded-pill"
    style={{ width: "100%", outline: "none" }}
    onChange={(event) => selectOnChangeHandler(event, false)}
  >
    <option>Select Account/District</option>

    {districtData &&
      districtData.map((item) => {
        return (
          <option
            value={item?.districtName}
            data-slug={item?.slug}
            data-accountid={item?.accountId}
          >
            {item?.districtName}
          </option>
        );
      })}
  </select>
</Col>

<Col sm="12" md="5">
  <div>
    <label className="font-semi-bold-TKL">School</label>
  </div>

  <select
    size="sm"
    className="rounded-pill"
    style={{ width: "100%", outline: "none" }}
    onChange={(event) => selectOnChangeHandler(event, true)}
  >
    <option>Select School</option>
    {schoolData &&
      schoolData.map((item) => {
        return (
          <option
            value={item?.schoolName}
            data-slug={item?.slug}
            data-schoolid={item?.schoolId}
          >
            {item?.schoolName}
          </option>
        );
      })}
  </select>
</Col>
</Row> */
