import { types } from "./types";
import { CourseDetails } from "../components/Models/course-model";

const initialState = {
  application: new CourseDetails(),
  allCountries: [],
  allStates: [],
  manageCohortData: [],
  allCohortTypes: [],
  allCohortPurposes: [],
  allCohortComprehensiveMarkets: [],
  allCohortFacilitators: [],
  allCohortGuidedAccounts: [],
  updateCohortData: {},
  isRefresh: false,
  courseIdCohorts: "",
  courseTitleCohorts: "",
  activeCohorts: [],
  courseCohortData: [],
  activeUserProgress: {},
  cohortReviewActivity: [],
  cohortStatus: "inactive",
  cohortFlag: false,
  totalPoints: 0,
  quizFinished: false,
  instructorReadOnly: false,
  courseCatalog: [],
  featuredCourseCatalog: [],
  numberItemsinCard: [],
  welcomeLetter: [],
  informationSheets: [],
  userPoints: {},
  userCompletionData: [],
  instructorReviewCourses: {}
};
export const cohorts = (state = initialState, action: any) => {
  switch (action.type) {
    case types.COHORTS_FORM_DATA:
      let cohortsData = [...state.application.cohorts];
      let cohortsDatatoBeReplaced = {};
      Object.keys(cohortsData[action.itemIndex]).forEach((item, index) => {
        if (item === action.label) {
          cohortsDatatoBeReplaced[item] = action.payload;
        } else
          cohortsDatatoBeReplaced[item] = cohortsData[action.itemIndex][item];
      });
      return {
        ...state,
        application: {
          ...state.application,
          cohorts: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            cohortsDatatoBeReplaced,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };

    case types.MANAGE_COHORT_DATA:
      return {
        ...state,
        manageCohortData: action.payload,
        isRefresh: false,
      };
    case types.ACTIVE_COHORTS:
      return {
        ...state,
        activeCohorts: action.payload,
      };

    case types.UPDATE_COHORT:
      return {
        ...state,
        isRefresh: true,
        manageCohortData: [],
      };

    case types.COURSE_CATALOG:
      return {
        ...state,
        isRefresh: true,
        courseCatalog: action.payload,
      };
    case types.FEATURE_COURSE_CATALOG:
      return {
        ...state,
        isRefresh: true,
        featuredCourseCatalog: action.payload,
      };

    case types.GET_COHORT_CONTENT_DATA:
      return {
        ...state,
        courseCohortData: action.payload,
      };
      case types.GET_COURSES_INSTRUCTOR_REVIEW:
        return {
          ...state,
          instructorReviewCourses: action.payload,
        };
  
    case types.USER_POINTS:
      return {
        ...state,
        userPoints: action.payload,
      };

    case types.ACTIVE_USER_PROGRES:
      return {
        ...state,
        activeUserProgress: action.payload,
      };

    case types.GET_ACTIVITY_REVIEW_DATA:
      return {
        ...state,
        cohortReviewActivity: action.payload,
      };

    case types.UPDATE_COHORT_DATA:
      return {
        ...state,
        updateCohortData: action.payload,
      };

    case types.WELCOME_LETTER:
      return {
        ...state,
        welcomeLetter: action.payload,
      };
    case types.INFORMATION_SHEETS:
      return {
        ...state,
        informationSheets: action.payload,
      };

    case types.ADD_COHORT_FIELDS:
      let cohortsFieldData = [...state.application.cohorts];
      let cohortsFieldDatatoBeAdded = {};
      for (let key in action.payload) {
        cohortsFieldData[action.itemIndex][key] = action.payload[key];
      }
      return {
        ...state,
        application: {
          ...state.application,
          cohorts: [
            ...state.application.Fragments.slice(0, action.itemIndex),
            cohortsFieldDatatoBeAdded,
            ...state.application.Fragments.slice(action.itemIndex + 1),
          ],
        },
      };
    case types.GET_COHORT_DATA:
      if (action.label === "countries") {
        return {
          ...state,
          allCountries: action.payload,
        };
      } else if (action.label === "states") {
        return {
          ...state,
          allStates: action.payload,
        };
      } else if (action.label === "cohortType") {
        return {
          ...state,
          allCohortTypes: action.payload,
        };
      } else if (action.label === "cohortPurpose") {
        return {
          ...state,
          allCohortPurposes: action.payload,
        };
      } else if (action.label === "SelectMarketLocationComprehensive") {
        return {
          ...state,
          allCohortComprehensiveMarkets: action.payload,
        };
      } else if (
        action.label === "AssignFacilitatorComprehensive" ||
        action.label === "assignFacilitatorGuidedPlc"
      ) {
        return {
          ...state,
          allCohortFacilitators: action.payload,
        };
      } else if (action.label === "accountOrganizationGuidedPlc") {
        return {
          ...state,
          allCohortGuidedAccounts: action.payload,
        };
      } else {
        return {
          ...state,
        };
      }

    case types.STATES_FORM_DATA:
      let cohortsExistingData = [...state.application.cohorts];
      let cohortData = {};
      Object.keys(cohortsExistingData[action.itemIndex]).forEach(
        (item, index) => {
          if (item === action.label) {
            if (action.payload === "initialValue") {
              cohortsExistingData[action.itemIndex][item] = [];
            }
            if (action.payload === "selectAll") {
              cohortsExistingData[action.itemIndex][item].splice(
                0,
                cohortsExistingData[action.itemIndex][item].length + 1
              );
              if (action.checked && action.checked) {
                let providence = state.allStates;
                providence.forEach((providenceState, index) => {
                  // providenceState["isChecked"]=true
                  cohortsExistingData[action.itemIndex][item].push(
                    providenceState
                  );
                });
              }
            } else {
              if (action.payload["isChecked"] === true) {
                cohortData[item] = cohortsExistingData[action.itemIndex][
                  item
                ].push(action.payload);
              } else {
                let indexFind = cohortsExistingData[action.itemIndex][item]
                  .map((element) => element.slug)
                  .indexOf(`${action.payload["slug"]}`);
                ~indexFind &&
                  cohortsExistingData[action.itemIndex][item].splice(
                    indexFind,
                    1
                  );
              }
            }
          } else cohortData[item] = cohortsExistingData[action.itemIndex][item];
        }
      );
      return {
        ...state,
        application: {
          ...state.application,
          cohorts: [...state.application.cohorts],
        },
      };
    case types.COHORT_CONTENT_PUBLISH_TEXT:
      return {
        ...state,
      };
    case types.INITIAL_COHORT_DATA:
      return {
        ...state,
        application: new CourseDetails(),
      };
    case types.COHORT_STATUS_ACTIVITY_PAGE:
      return {
        ...state,
        cohortStatus: action.payload,
      };
    case types.TOTAL_POINTS_OBTAINED:
      console.log("......totalPoints", action.payload);
      return {
        ...state,
        totalPoints: action.payload,
      };
    case types.QUIZ_COMPLETED_COHORT:
      return {
        ...state,
        quizFinished: action.payload,
      };
    case types.INSTRUCTOR_FEEDBACK_READONLY:
      return {
        ...state,
        instructorReadOnly: action.payload,
      };
    case types.COHORTS_FIELDS_ADD:
      return {
        ...state,
        application: {
          ...state.application,
          cohorts: [...action.payload],
        },
      };
    case types.COHORTS_FIELDS_REMOVE:
      return {
        ...state,
        application: {
          ...state.application,
          cohorts: [...action.applicationFields],
        },
      };
    case types.USER_CERTIFICATION_DATA: //can be reused this
      //  console.log("....action", action);
      return {
        ...state,
        [action.label]: action.payload,
      };

    default:
      return state;
  }
};
