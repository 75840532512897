import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL_COHORT, URL } from "../../api/tklEnvironments";
import {
  convertUTCDateToLocalDate,
  convertLocalDateToUTCDate,
  hideLoader,
} from "../../components/utility/ReUsableFunctions";
export const getCohortData = (payload, stepTypes, userSlug = "") => {
  let isContinue = { isContinue: false };
  if (userSlug && userSlug != null) {
    userSlug = "/" + userSlug;
  } else {
    userSlug = "";
  }
  return (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    axios
      .get(`${URL_COHORT}/get-cohort-content/${payload}${userSlug}`)
      .then(function (response) {
        dispatch({ type: types.HIDE_LOADER });
        dispatch({
          type: types.GET_COHORT_CONTENT_DATA,
          payload: response?.data,
        });
        let coursePathData = [];
        if (response.data) {
          let responseData = response.data;
          let appData = {};
          let applicationdata = response.data;
          let isCompletedSection = false;
          // console.log(".....responseData", responseData);
          appData["courseName"] = applicationdata.name;
          appData["courseObjective"] = applicationdata.objective;
          appData["courseDescription"] = applicationdata.courseDescription;
          appData["authors"] = applicationdata?.authors;
          appData["programsSelected"] = [];
          appData["gradeLevel"] = [];
          appData["contentArea"] = [];
          appData["tags"] = [];
          appData["frameworkDomains"] = [];
          appData["frameworkSubDomains"] = [];
          appData["slug"] = applicationdata.slug;
          appData["avatarImage"] = applicationdata.avatarImage;
          appData["bannerImage"] = applicationdata.bannerImage;
          appData["status"] = applicationdata.cohort.status;
          appData["createdBy"] = applicationdata.createdBy;
          dispatch({ type: types.GET_COURSE_DETAILS, payload: appData });
          let introSection =
            responseData?.cohortCourseWithCohortIntroductionSection?.[0];
          let introSecObj = {
            type: "subheader",
            name: introSection.name,
            title: "Introduction: " + introSection.name,
            desc: introSection.description,
            courseSlug: applicationdata.slug,
            slug: introSection.slug,
            activities: getListOfIntoActivities(introSection, isContinue),
            isIntro: true,
          };
          introSecObj.activities.every(function (element, index) {
            if (element.isSubmitted) {
              isCompletedSection = true;
              return true;
            } else {
              isCompletedSection = false;
              return false;
            }
          });
          introSecObj["isSubmitted"] = isCompletedSection;
          dispatch({ type: types.GET_INTRO_SECTION, introSecObj });
          responseData?.cohortCourseWithCohortModules?.forEach(
            (object, index) => {
              // //console.log("cohort responseData", object);
              let moduleObj = {
                type: "header",
                id: "header_" + (index + 1),
                name: object.name,
                title: "Module " + (index + 1) + ": " + object.name,
                courseObjective: object.courseObjectiveIndex,
                moduleObjective: object.objective,
                link: "#",
                desc: object.description,
                module_index: index + 1,
                sectionCount: object?.CohortModuleWithCohortSections?.length,
                moduleSlug: object.slug,
                courseSlug: applicationdata.slug,
                //isSubmitted: true,
              };
              coursePathData.push(moduleObj);
              getSectionData(
                object.CohortModuleWithCohortSections,
                coursePathData,
                moduleObj,
                stepTypes,
                isContinue
              );
            }
          );
          dispatch({ type: types.GET_MODULE, coursePathData });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.HIDE_LOADER });
        // dispatch({ type: types.HIDE_LOADER, payload: false });
      });
  };
};

export const getCohortAndNavigatetoLearn = (
  cohortId,
  props,
  progress,
  daysLeft
) => {
  return (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    axios
      .get(`${URL_COHORT}/get-cohort-content/${cohortId}`)
      .then(function (response) {
        if (response?.data) {
          console.log(".....progress", progress, cohortId);
          localStorage.setItem("facilitatorManager",JSON.stringify(response?.data?.cohort?.accountManager ? response?.data?.cohort?.accountManager : response?.data?.cohort?.facilitator))
          if (progress === 100 || !daysLeft) {
            sessionStorage.setItem("mode", "learn");
            props.history.push(`CoursePathForCohort/${cohortId}/true`);
            props.history.go(0);
          } else {
            const responseData = response?.data;
            let isIntro = null;
            let sectionIndex = 0;
            let activityIndex = 0;
            let moduleIndex = 0;
            let activitySlug = "";
            // responseData?.cohortCourseWithCohortIntroductionSection[0]?.slug;
            // ?.cohortIntroductionSectionWithCohortIntroductionSteps[0];
            loop2: for (
              let i = 0;
              i <
              responseData?.cohortCourseWithCohortIntroductionSection.length;
              i++
            ) {
              for (
                let j = 0;
                j <
                responseData?.cohortCourseWithCohortIntroductionSection[i]
                  ?.cohortIntroductionSectionWithCohortIntroductionSteps
                  ?.length;
                j++
              ) {
                if (
                  !responseData?.cohortCourseWithCohortIntroductionSection[i]
                    ?.cohortIntroductionSectionWithCohortIntroductionSteps[j]
                    ?.userIntroductionActivity?.cohortUserIntroductionActivity
                    ?.stepCompletedAt
                ) {
                  isIntro = true;
                  sectionIndex = i;
                  activityIndex = j;
                  activitySlug =
                    responseData?.cohortCourseWithCohortIntroductionSection[i]
                      ?.cohortIntroductionSectionWithCohortIntroductionSteps[j]
                      ?.slug;
                  break loop2;
                }
              }
            }

            if (!isIntro) {
              loop1: for (
                let i = 0;
                i < responseData?.cohortCourseWithCohortModules.length;
                i++
              ) {
                for (
                  let j = 0;
                  j <
                  responseData?.cohortCourseWithCohortModules[i]
                    ?.CohortModuleWithCohortSections?.length;
                  j++
                ) {
                  for (
                    let p = 0;
                    p <
                    responseData?.cohortCourseWithCohortModules[i]
                      ?.CohortModuleWithCohortSections[j]?.sectionWithActivities
                      ?.length;
                    p++
                  )
                    if (
                      !responseData?.cohortCourseWithCohortModules[i]
                        ?.CohortModuleWithCohortSections[j]
                        ?.sectionWithActivities[p]?.userActivity
                        ?.cohortUserActivity?.stepCompletedAt
                    ) {
                      isIntro = false;
                      moduleIndex = i;
                      sectionIndex = j;
                      activityIndex = p;
                      activitySlug =
                        responseData?.cohortCourseWithCohortModules[i]
                          ?.CohortModuleWithCohortSections[j]
                          ?.sectionWithActivities[p]?.slug;
                      break loop1;
                    }
                }
              }
            }

            // if (
            //   introData?.userIntroductionActivity?.cohortUserIntroductionActivity
            //     ?.stepCompletedAt
            // ) {
            //   dispatch({
            //     type: types.ACTIVITY_COMPLETED_CLICKED,
            //     payload: true,
            //   });
            // }
            if (!activitySlug) {
              sessionStorage.setItem("mode", "learn");
              props.history.push(`CoursePathForCohort/${cohortId}/true`);
              props.history.go(0);
            } else {
              sessionStorage.setItem("mode", "learn");
              props.history.push("/");
              setTimeout(() => {
                if (isIntro) {
                  props.history.push(
                    `/cohortIntroduction/cohortActivity/${cohortId}/introduction/${
                      sectionIndex + 1
                    }/${
                      activityIndex + 1
                    }/${activitySlug}/cohortUserLearner/true`
                  );
                } else {
                  //    console.log("........activitySlug", activitySlug);
                  props.history.push(
                    `/cohortActivity/${cohortId}/${moduleIndex + 1}/${
                      sectionIndex + 1
                    }/${
                      activityIndex + 1
                    }/${activitySlug}/cohortUserLearner/true`
                  );
                }
              }, 0);
            }
          }
        }
      })
      .catch(function (error) {});

    dispatch({ type: types.HIDE_LOADER, payload: false });
  };
};

const getSectionData = (
  sectionObject,
  coursePathData,
  moduleObj,
  stepTypes,
  isContinue
) => {
  if (sectionObject?.length > 0) {
    let isCompletedSection = false;
    (sectionObject || []).forEach((section, secIndex) => {
      let sectionObj = {
        type: "subheader",
        headerid: "header_" + moduleObj.module_index,
        id: "subheader_" + moduleObj.module_index + "." + (secIndex + 1),
        name: section?.name,
        title:
          "Section " +
          moduleObj.module_index +
          "." +
          (secIndex + 1) +
          ": " +
          section?.name,
        courseObjective: section?.courseObjectiveIndex,
        moduleObj: section.moduleObjectiveIndex,
        link: "#",
        desc: section.description,
        sectionIndex: secIndex + 1,
        activities: getListOfActivities(
          section,
          moduleObj.module_index,
          secIndex,
          stepTypes,
          isContinue
        ),
        slug: section.slug,
        moduleSlug: moduleObj.moduleSlug,
      };

      sectionObj.activities.every(function (element, index) {
        if (element.isSubmitted) {
          isCompletedSection = true;
          return true;
        } else {
          isCompletedSection = false;
          return false;
        }
      });

      sectionObj["isSubmitted"] = isCompletedSection;

      // //console.log("cohort responseData", section, sectionObj);
      coursePathData.push(sectionObj);
    });
  }
};

export const getListOfActivities = (
  section,
  modIndex,
  secIntex,
  stepTypes,
  isContinue
) => {
  let listOfActivities = [];
  if (section.sectionWithActivities.length > 0) {
    (section.sectionWithActivities || []).forEach((step, stepIndex) => {
      let stepType = (stepTypes || []).find(
        (obj) => obj.slug === step?.stepType?.slug
      );
      let activity = {
        name: step.name,
        title:
          "Step " +
          modIndex +
          "." +
          (secIntex + 1) +
          "." +
          (stepIndex + 1) +
          ": " +
          step.name,
        link: "#",
        id: "activity_1_" + (stepIndex + 1),
        headerid: section.headerid,
        sectionid: section.id,
        activity: {
          title: step?.stepType?.title,
          slug: step?.stepType?.slug,
          icon: step?.stepType?.icon,
        },
        index: step.index,
        moduleIndex: modIndex,
        sectionIndex: secIntex + 1,
        stepIndex: stepIndex + 1,
        points: step.points,
        fragmentCount: step.fragmentCount,
        type: "activity",
        releaseOn: step?.releaseOn,
        iscovered: true,
        moduleObjective: step.moduleObjectiveIndex,
        slug: step.slug,
        cohortLevelData: step.cohortLevelData,
        sectionSlug: section.slug,
        isSubmitted: step?.userActivity?.cohortUserActivity?.stepCompletedAt
          ? true
          : false,
        userEarnedPoints:
          step?.userActivity?.cohortUserActivity?.userEarnedPoints,
        stepCompletedAt:
          step?.userActivity?.cohortUserActivity?.stepCompletedAt,
      };
      activity["isCompleted"] =
        activity.isSubmitted === false &&
        isContinue.isContinue === false &&
        !checkFutureRelease(activity)
          ? true
          : false;
      listOfActivities.push(activity);
      if (activity.isSubmitted === false && isContinue.isContinue === false) {
        if (checkFutureRelease(activity)) {
          isContinue.isContinue = false;
        } else {
          isContinue.isContinue = true;
        }
      }
    });
  }

  return listOfActivities;
};
const checkFutureRelease = (releaseOn) => {
  let isFutureRelease = false;

  if (releaseOn.releaseOn !== null) {
    let releaseDate = releaseOn.releaseOn; //new Date(releaseOn.releaseOn);

    // releaseDate.setHours(releaseDate.getHours() + 5);
    // releaseDate.setMinutes(releaseDate.getMinutes() + 30);
    releaseDate = convertUTCDateToLocalDate(releaseDate);

    let currentDate = new Date();

    if (releaseDate > currentDate) {
      isFutureRelease = true;
    } else {
      isFutureRelease = false;
    }
  } else {
    isFutureRelease = false;
  }

  return isFutureRelease;
};
export const getListOfIntoActivities = (section, isContinue) => {
  let listOfActivities = [];

  (section.cohortIntroductionSectionWithCohortIntroductionSteps || []).forEach(
    (step, stepIndex) => {
      let activity = {
        name: step.name,
        // title: "Step " + 1 + "." + (stepIndex + 1) + " : " + step.name,
        title: "Intro Step " + (stepIndex + 1) + ": " + step.name,
        link: "#",
        id: "activity_1_" + (stepIndex + 1),
        activity: {
          title: step?.stepType?.title,
          slug: step?.stepType?.slug,
          icon: step?.stepType?.icon,
        },
        index: step.index,
        points: step.points,
        type: "activity",
        sectionIndex: 1,
        fragmentCount: step.fragmentCount,
        activityIndex: stepIndex + 1,
        iscovered: true,
        isIntroStep: true,
        slug: step.slug,
        releaseOn: step?.releaseOn,
        cohortLevelData: step.cohortLevelData,
        sectionSlug: section.slug,
        isSubmitted: step?.userIntroductionActivity
          ?.cohortUserIntroductionActivity?.stepCompletedAt
          ? true
          : false,
        userEarnedPoints:
          step?.userIntroductionActivity?.cohortUserIntroductionActivity
            ?.userEarnedPoints,
        stepCompletedAt:
          step?.userIntroductionActivity?.cohortUserIntroductionActivity
            ?.stepCompletedAt,
      };

      activity["isCompleted"] =
        activity.isSubmitted === false &&
        isContinue.isContinue === false &&
        !checkFutureRelease(activity)
          ? true
          : false;
      if (activity.isSubmitted === false && isContinue.isContinue === false) {
        if (checkFutureRelease(activity)) {
          isContinue.isContinue = false;
        } else {
          isContinue.isContinue = true;
        }
      }

      listOfActivities.push(activity);
    }
  );

  return listOfActivities;
};

export const addIntroStepForCohort = (payload, scrollID) => {
  return (dispatch) => {
    axios
      .post(`${URL_COHORT}/create-cohort-introduction-step`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: scrollID },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const putIntroStepForCohort = (payload, scrollID, props) => {
  return (dispatch) => {
    axios
      .put(`${URL_COHORT}/update-cohort-introduction-step`, payload)
      .then(function (response) {
        let responseData = response?.data?.response;
        responseData?.cohortIntroductionStep?.slug;
        let pathURL = props?.history?.location?.pathname?.split("/");
        console.log(pathURL);

        // pathURL[pathURL.length - 1] = responseData?.activity?.slug;
        pathURL[pathURL.length - 1] =
          responseData?.cohortIntroductionStep?.slug;
        console.log(pathURL);
        console.log(responseData?.cohortIntroductionStep?.slug, payload);
        if (response?.status === 200) {
          dispatch({
            type: types.ADD_MODULE,
            payload: { isLoading: true, scrollID: scrollID },
          });

          let newPath = pathURL?.join("/");
          console.log(".....newPath", newPath, pathURL);
          props?.history.push(newPath);
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.API_ERROR,
          payload: error?.response?.data?.errors?.name?.[0],
        });
        console.error("..error", error);
      });
  };
};

export const deleteIntroStepForCohort = (activityslug) => {
  return (dispatch) => {
    axios
      .delete(`${URL_COHORT}/delete-cohort-introduction-step/${activityslug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_STEP, isLoading: true });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addModulePostForCohort = (payload) => {
  return (dispatch) => {
    axios
      .post(`${URL_COHORT}/create-cohort-module`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "", successMessage: "Success" },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
      });
  };
};

export const updateModulePostForCohort = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL_COHORT}/update-cohort-module`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const deleteModuleForCohort = (moduleSlug) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-module/${moduleSlug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_MODULE, isLoading: true });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const pasteModuleForCohort = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/copy-module`, payload)
      .then(function (response) {
        ////console.log("...response", response);
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};
export const pasteSection = (payload) => {
  return (dispatch) => {
    axios
      .put(`${URL}/copy-section`, payload)
      .then(function (response) {
        ////console.log("...response", response);
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "" },
        });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const deleteSection = (sectionSlug) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-section/${sectionSlug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_SECTION, isLoading: true });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updateStepPosition = (slug, newPosition) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-section/`)
      .then(function (response) {
        dispatch({ type: types.DELETE_SECTION, isLoading: true });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addSectionPostForCohort = (payload, secCount) => {
  return (dispatch) => {
    ////console.log("addSectionPost : ", payload);
    axios
      .post(`${URL_COHORT}/create-cohort-section`, payload)
      .then(function (response) {
        const res = response.data.response;
        ////console.log("ADD_MODULE", response, res.sectionSlug);
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: "subheader_" + secCount },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
      });
  };
};

export const updateSectionPostForCohort = (payload, scrollID) => {
  return (dispatch) => {
    axios
      .put(`${URL_COHORT}/update-cohort-section`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: scrollID },
        });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addStepForCohort = (payload, scrollID) => {
  return (dispatch) => {
    axios
      .post(`${URL_COHORT}/create-cohort-activity`, payload)
      .then(function (response) {
        dispatch({
          type: types.ADD_MODULE,
          payload: { isLoading: true, scrollID: scrollID },
        });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        //console.error("..error", error);
      });
  };
};

export const updateStepForCohort = (payload, scrollID, props) => {
  return (dispatch) => {
    axios
      .put(`${URL_COHORT}/update-cohort-activity`, payload)
      .then(function (response) {
        let responseData = response?.data?.response;
        // //console.log(".....responsedata", responseData, payload);
        let pathURL = props?.history?.location?.pathname?.split("/");
        // pathURL[pathURL.length - 1] = responseData?.activity?.slug;
        pathURL[pathURL.length - 1] = responseData?.activity.slug;

        if (response?.status === 200) {
          dispatch({
            type: types.ADD_MODULE,
            payload: { isLoading: true, scrollID: scrollID },
          });
          let newPath = pathURL?.join("/");
          // //console.log(".....newPath", newPath, pathURL);
          props?.history.push(newPath);
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        //console.error("..error", error);
      });
  };
};

export const deleteCohortActivity = (activityslug) => {
  return (dispatch) => {
    axios
      .delete(`${URL_COHORT}/delete-cohort-activity/${activityslug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_STEP, isLoading: true });
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};

export const deleteCohortIntroActivity = (activityslug) => {
  return (dispatch) => {
    axios
      .delete(`${URL_COHORT}/delete-cohort-introduction-step/${activityslug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_STEP, isLoading: true });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const deleteModule = (moduleSlug) => {
  return (dispatch) => {
    axios
      .delete(`${URL}/delete-module/${moduleSlug}`)
      .then(function (response) {
        dispatch({ type: types.DELETE_MODULE, isLoading: true });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getActiveCohorts = () => {
  return (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-cohort-active-course`)
      .then(function (response) {
        if (response.status === 200) {
          dispatch({ type: types.ACTIVE_COHORTS, payload: response?.data });
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.errors?.name) {
          dispatch({
            type: types.API_ERROR,
            payload: error?.response?.data?.errors?.name?.[0],
          });
        }
        console.error("..error", error);
      });
  };
};
