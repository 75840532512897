import React from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addTag, updateTags } from "../../../actions/MasterDataActions/Tags";

function TagsForm(props) {
  const { updateData, isCreate, handleClose } = props;
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (isCreate) {
      dispatch(addTag({ name: data.name, status: data?.status }));
    } else {
      // if (data.name !== updateData.name) {
      dispatch(
        updateTags({
          name: data.name,
          slug: updateData.slug,
          status: data?.status,
        })
      );
      // }
    }
    handleClose();
  };

  return (
    <div>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Tag Name <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="name"
                type="text"
                size="sm"
                defaultValue={updateData.name ? updateData.name : ""}
                placeholder="Enter Name"
                className="rounded-pill"
                {...register("name", { required: true })}
              />

              {errors.name && (
                <p className="show-txt-error p-1 m-2">Tag name is required</p>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="status">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Status
            </Form.Label>
            <Col sm="9" className="select-container">
              <span className="select-arrow-icon material-icons">
                expand_more
              </span>

              <Form.Control
                {...register("status", { required: true })}
                as="select"
                size="sm"
                className="rounded-pill select-styles"
                defaultValue={
                  updateData?.status ? updateData?.status : "INACTIVE"
                }
              >
                <option name="INACTIVE">INACTIVE</option>
                <option name="ACTIVE">ACTIVE</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="button">
            <Row className="mt-3 mr-2 ml-auto mr-3s">
              <Button
                className="m-2 btn btn-secondary rounded-pill"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                className="m-2 btn btn-primary rounded-pill"
              >
                {isCreate ? "Create" : "Update"}
              </Button>
            </Row>
          </Form.Group>
        </form>
      </Container>
    </div>
  );
}

export default TagsForm;
