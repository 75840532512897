import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import MultiSelectDropdown from "../../Cohorts/CohortFields/MultiSelectDropdown";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { addUserToSchool, getSchoolsByAccountId } from "../../../actions/MasterDataActions/AccountAction";
import "../tklAdmin.scss";
import {
  TKL_MEMBER,
  GPLC_PROGRAM_MEMBER,
  MICRO_PROGRAM_MEMBER,
  SCHOOL_ADMIN,
  SCHOOL_FACILITATOR,
  KIDVISION_PROGRAM_MEMBER,
} from "../../../constants.js/RoleNames";
import { useParams } from "react-router-dom";

function UserData(props) {
  const { isDistrict } = props;
  let dispatch = useDispatch();
  const [roleData, setRolesData] = useState([]);
  const [roleObject, setRoleObject] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [schools, setSchools] = useState([]);
  const [schoolObject, setSchoolObject] = useState([]);
  const [lastNameError, setLastNameError] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const { roles, accountType, schoolsByAccountID } = useSelector(
    (state) => state.tkladmin
  );
  const { accountId, schoolId:selectedSchoolId} = useParams();
  useEffect(() => {
    dispatch(getSchoolsByAccountId(accountId));
  }, [accountId]);


  useEffect(() => {
    let userRoles = [];
    let defaultSelectedRoles = [];
    roles &&
      roles[0].forEach((role) => {
       
        if (isDistrict) {
          if (
            [
              TKL_MEMBER,
              "DISTRICT ADMIN",
              "DISTRICT FACILITATOR",
              accountType === 1 ? "DISTRICT FACILITATOR" : "",
              (accountType === 2 || accountType === "macro") && MICRO_PROGRAM_MEMBER,
              accountType === 4 && KIDVISION_PROGRAM_MEMBER,
            ].includes(role)
          ) {
            if (role === "DISTRICT ADMIN") {
              setRoleObject([
                {
                  name: role,
                  slug: role?.replace(" ", "-")?.toLowerCase(),
                },
              ]);
            }
            // if( role!==TKL_MEMBER )
            //  {
            userRoles.push({
              name: role,
              slug: role?.replace(" ", "-")?.toLowerCase(),
            });
         // }
          }
          let defaultRoles = [TKL_MEMBER];
          if(accountType === 2 || accountType === "macro"){
            defaultRoles.push(MICRO_PROGRAM_MEMBER);
          }else if(accountType === 4){
            defaultRoles.push("KIDVISION PROGRAM MEMBER (PAID USER)");
          }
          if ( defaultRoles.includes( role ) ) {
            defaultSelectedRoles.push({
              name: role,
              slug: role?.replace(" ", "-")?.toLowerCase(),
            });
          }
        } else {
          if (
            [
              TKL_MEMBER,
              accountType === 1 && GPLC_PROGRAM_MEMBER, "SCHOOL ADMIN",
              accountType === 1 ? "SCHOOL FACILITATOR" : "SCHOOL FACILITATOR",
              (accountType === 2 || accountType === "macro") && MICRO_PROGRAM_MEMBER,
              accountType === 4 && KIDVISION_PROGRAM_MEMBER
            ].includes(role)
          ) {
            if (
              [TKL_MEMBER, GPLC_PROGRAM_MEMBER, MICRO_PROGRAM_MEMBER, "KIDVISION PROGRAM MEMBER (PAID USER)"].includes(
                role
              )
            ) {
              defaultSelectedRoles.push({
                name: role,
                slug: role?.replace(" ", "-")?.toLowerCase(),
              });
            }
            // if( role!==TKL_MEMBER )
            //  {
              userRoles.push({
                name: role,
                slug: role?.replace(" ", "-")?.toLowerCase(),
              });
          //}
          }
        }
      });
    setRoleObject(defaultSelectedRoles);
    setRolesData(userRoles);
  }, [roles]);

  useEffect(() => {
    let schoolObjects = schoolsByAccountID.map((item) => {
      return {
        name: item.schoolName,
        slug: item.slug,
        id: item.schoolId,
      };
    });
    setSchools(schoolObjects);
    setSchoolObject(schoolObjects);
  }, [schoolsByAccountID]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log("data", data);
    let userRolesMap = [];
    if(data?.email.trim() === ""){
      setMailError(true);
    }else{
      setMailError(false);
    }
    if(data?.firstName.trim() === ""){ 
      setFirstNameError(true);
    }else{
      setFirstNameError(false);
    }
    if(data?.lastName.trim() === ""){
      setLastNameError(true);
    }else{
      setLastNameError(false);
    }
    let tempValue = 0;
    roleObject?.map((item) => {
      if (item?.name === "DISTRICT ADMIN" || item?.name === "DISTRICT FACILITATOR" || item?.name === "SCHOOL FACILITATOR" || item?.name === "SCHOOL ADMIN" || props?.isMemberCreate) {
        tempValue = 1;
      }
    })
    if(tempValue === 0){
      setRoleError(true);
    }else{
      setRoleError(false); 
    }

    roleObject.forEach((role) => userRolesMap.push(role?.name));
    data["roles"] = userRolesMap;
    if (isDistrict) {
      let schoolMap = [];
      console.log("schoolObject :", schoolObject);
      schoolObject.forEach((school) => schoolMap.push(school?.id * 1));
      data["schoolId"] = schoolMap;
    }
    if(!(data?.email.trim() === "") && !(data?.lastName.trim() === "") && !mailError && roleObject.length > 0 && tempValue){
        dispatch(addUserToSchool(data, accountId));
        props.handleClose();
    }
  };
  const stateHandler = (event, checked, status, selectall) => {
    if (status !== "selectAll") {
      let newData = {};
      newData["name"] = event.target.value;
      newData["slug"] = event.target.id;
      checked ? (newData["isChecked"] = true) : (newData["isChecked"] = false);

      let updatedRoleObject = [...roleObject];

      let index = _.findIndex(updatedRoleObject, { name: newData.name });

      if (index === -1) {
        updatedRoleObject.push(newData);
      } else {
      updatedRoleObject.splice(index, 1);
      }

      let tklMemberExist = _.findIndex(updatedRoleObject, { name: "TKL MEMBER (FREE USER)" });
      if(tklMemberExist === -1)
      {
      updatedRoleObject.unshift({name:"TKL MEMBER (FREE USER)", slug: "tkl-member (free user)"});
      }

      let kidVisionMemberExist = _.findIndex(updatedRoleObject, { name: "KIDVISION PROGRAM MEMBER (PAID USER)" });
      if(kidVisionMemberExist === -1 && accountType === 4)
      {
      updatedRoleObject.unshift({name:"KIDVISION PROGRAM MEMBER (PAID USER)", slug: "kidvision"});
      }

      let microMemberExist = _.findIndex(updatedRoleObject, { name: "MICRO COURSE PROGRAM MEMBER (PAID USER)" });
      if(microMemberExist === -1 && (accountType === 2 || accountType === "macro"))
      {
      updatedRoleObject.unshift({name:"MICRO COURSE PROGRAM MEMBER (PAID USER)", slug: "micro-course-program"});
      }

      if (newData["name"] === GPLC_PROGRAM_MEMBER) {
        updatedRoleObject = updatedRoleObject.filter((obj) =>
          [GPLC_PROGRAM_MEMBER, MICRO_PROGRAM_MEMBER, KIDVISION_PROGRAM_MEMBER , TKL_MEMBER,]?.includes(
            obj?.name
          )
        );
      }

      if (newData["name"] === SCHOOL_ADMIN) {
        updatedRoleObject = updatedRoleObject.filter((obj) =>
          [SCHOOL_ADMIN, SCHOOL_FACILITATOR, TKL_MEMBER, MICRO_PROGRAM_MEMBER, KIDVISION_PROGRAM_MEMBER]?.includes(obj?.name)
        );
      }

      if (newData["name"] === SCHOOL_FACILITATOR) {
        updatedRoleObject = updatedRoleObject.filter((obj) =>
          [SCHOOL_ADMIN, SCHOOL_FACILITATOR, TKL_MEMBER, MICRO_PROGRAM_MEMBER, KIDVISION_PROGRAM_MEMBER]?.includes(obj?.name)
        );
      }

      setRoleObject(updatedRoleObject);
    } else {
      if(checked)
      {
        let newRoleData = [...roleData];
        let index = _.findIndex(newRoleData, { name:TKL_MEMBER});
        if(index == -1)
        {
          newRoleData.unshift({name:"TKL MEMBER (FREE USER)", slug: "tkl-member (free user)"});
          setRolesData(newRoleData);
        }
      }
     checked ? setRoleObject(roleData) : setRoleObject([{name:"TKL MEMBER (FREE USER)", slug: "tkl-member (free user)"}, ]);
    }
  };

  const stateHandlerForSchools = (event, checked, status, selectall) => {
    if (status !== "selectAll") {
      let newData = {};
      newData["name"] = event.target.value;
      newData["slug"] = event.target.id;
      checked ? (newData["isChecked"] = true) : (newData["isChecked"] = false);

      let updatedRoleObject = [...schoolObject];

      let index = _.findIndex(updatedRoleObject, { name: newData.name });

      if (index === -1) {
        updatedRoleObject.push(newData);
      } else {
        updatedRoleObject.splice(index, 1);
      }

      setSchoolObject(updatedRoleObject);
    } else {
      checked ? setSchoolObject(schools) : setSchoolObject([]);
    }
  };
  return (
    <Container fluid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row} controlId="name">
          <Form.Label column sm={3} className="font-weight-bold">
            First Name
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              name="fname"
              type="text"
              placeholder="Enter First Name"
              className="rounded-pill"
              {...register("firstName")}
            />
            {errors["firstName"] && (
              <p className="show-txt-error p-1 m-2">First Name is required</p>
            )}
            {firstNameError && (
              <p className="show-txt-error p-1 m-2">First Name field cannot be empty</p>
            )}
          </Col>
        </Form.Group>

        <Form.Group as={Row} controlId="name">
          <Form.Label column sm={3} className="font-weight-bold">
            Middle Name
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              name="fname"
              type="text"
              placeholder="Enter Middle Name"
              className="rounded-pill"
              {...register("middleName")}
            />
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="lname">
          <Form.Label column sm={3} className="font-weight-bold">
            Last Name
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              name="lname"
              type="text"
              placeholder="Enter Last Name"
              className="rounded-pill"
              {...register("lastName")}
            />
            {errors["lastName"] && (
              <p className="show-txt-error p-1 m-2">Last Name is required</p>
            )}
            {lastNameError && (
              <p className="show-txt-error p-1 m-2">Last Name field cannot be empty</p>
            )}
          </Col>
        </Form.Group>
        <Form.Group as={Row} controlId="phone">
          <Form.Label column sm={3} className="font-weight-bold">
            Email
          </Form.Label>
          <Col sm={9}>
            <Form.Control
              name="phone"
              type="text"
              placeholder="Enter Email"
              className="rounded-pill"
              {...register("email", { required: true })}
            />
            {errors["email"] && (
              <p className="show-txt-error p-1 m-2">Email is required</p>
            )}
            {mailError && (
              <p className="show-txt-error p-1 m-2">Email field cannot be empty</p>
            )}
          </Col>
        </Form.Group>
        <Form.Group
          as={Row}
          controlId="role"
          className="role-styles rolestyle-arrow"
        >
          <Form.Label column sm={3} className="font-weight-bold">
            Role 
          </Form.Label>
          <Col sm={9}>
            <div className="multiple-select">
              <MultiSelectDropdown
                width={"100%"}
                options={roleData}
                id={"roles"}
                data={roleObject}
                statesboolean={true}
                onSelectFieldChange={stateHandler}
                isLarge={true}
              />
            </div>
            {roleObject.length === 0 && (
              <p className="show-txt-error p-1 m-2">Please select role !!</p>
            )}
            {
              (roleError && !props?.isMemberCreate) &&
              <p className="show-txt-error p-1 m-2">Please select at least one from DISTRICT FACILITATOR, SCHOOL FACILITATOR, DISTRICT ADMIN or SCHOOL ADMIN role !!</p>
            }
          </Col>
        </Form.Group>

        {isDistrict ? (
          <Form.Group as={Row} controlId="role">
            <Form.Label column sm={3} className="font-weight-bold">
              School/Location
            </Form.Label>
            <Col sm={9} className="lo_styles">
              <div className="multiple-select">
                <MultiSelectDropdown
                  width={"100%"}
                  options={schools}
                  id={"roles"}
                  data={schoolObject}
                  statesboolean={true}
                  onSelectFieldChange={stateHandlerForSchools}
                  isLarge={true}
                />
              </div>
              {roleObject.length === 0 && isSubmitted && (
                <p className="show-txt-error p-1 m-2">
                  Please Select School !!
                </p>
              )}
            </Col>
          </Form.Group>
        ) : (
          <Form.Group as={Row} controlId="formHorizontalPassword">
            <Form.Label column sm={3} className="font-weight-bold">
              School/Location <br /> <span style={{ color: 'red', fontSize: '12px', fontWeight: 'normal' }}>(Total No. of seats/seats filled)</span>
            </Form.Label>
            <Col
              sm={9}
              className="select-container school_location_conditional_arrow"
            >
              <span className="select-arrow-icon material-icons">
                expand_more
              </span>

              <Form.Control
                as="select"
                type="country"
                className="rounded-pill select-styles"
                readOnly = {selectedSchoolId}
                value = {selectedSchoolId}
                {...register("schoolId", { required: true })}
              >
                <option value="">-- Select School --</option>
                {schoolsByAccountID.map((school) => (
                  <option value={school?.schoolId} disabled={school?.numberOfUsers == school?.seatsFilled ? true : false } >
                  {school?.schoolName} ({school?.numberOfUsers +'/'+ school?.seatsFilled})
                </option>
                ))}
              </Form.Control>
              {errors["schoolId"] && (
                <p className="show-txt-error p-1 m-2">School is required</p>
              )}
            </Col>
          </Form.Group>
        )}

        <Form.Group
          as={Row}
          controlId="status"
          style={{ display: props.isMemberCreate && "none" }}
        >
          <Form.Label column sm={3} className="font-weight-bold">
            Status
          </Form.Label>
          <Col sm={9} className="select-container">
            <span className="select-arrow-icon material-icons">
              expand_more
            </span>

            <Form.Control
              as="select"
              className="rounded-pill select-styles"
              {...register("status")}
            >
              <option value="ACTIVE">PENDING</option>
              <option value="INACTIVE">INACTIVE</option>
              <option value="ACTIVE">ACTIVE</option>
            </Form.Control>
          </Col>
        </Form.Group>

        <Row className="d-flex justify-content-center">
          <Button
            className="btn-outline btn-danger  m-2  pl-4 pr-4   rounded-pill "
            onClick={props.handleClose}
            size="sm"
          >
            {" Cancel "}
          </Button>
          <Button
            type="submit"
            className="btn-outline btn-blue  m-2  pl-4 pr-4   rounded-pill "
          >
            {"Create"}
          </Button>
        </Row>
      </form>
    </Container>
  );
}

UserData.propTypes = {};

export default UserData;
