import React from "react";

const ComponentResponsiveImg = React.memo(function ComponentResponsiveImg(
  props
) {
  // React.useEffect(() => {
  //   if (props.type === "user") console.log("....", props.imgdata);
  // });
  const generateSrcset = (normal, retina) => {
    let str = "";
    if (normal && retina) {
      str +=
        normal +
        " 1x, " +
        // + retina + ' 1.5x, '
        // + retina + ' 1.75x, '
        retina +
        " 2x, ";
      // + retina + ' 2.5x, '
      // + retina + ' 3x';
    }
    if (normal && !retina) {
      str += normal + " 1x";
    }
    if (!normal && retina) {
      str += retina + " 1x";
    }
    return str;
  };
  const imgdata = (props || {}).imgdata || {};
  return (
    <>
      <picture>
        {imgdata.xxl || imgdata.xxl2 ? (
          <source
            media="(min-width: 1600px)"
            data-srcset={generateSrcset(imgdata.xxl, imgdata.xxl2)}
          />
        ) : (
          <></>
        )}
        {imgdata.xl || imgdata.xl2 ? (
          <source
            media="(min-width: 1200px)"
            data-srcset={generateSrcset(imgdata.xl, imgdata.xl2)}
          />
        ) : (
          <></>
        )}
        {imgdata.lg || imgdata.lg2 ? (
          <source
            media="(min-width: 1024px)"
            data-srcset={generateSrcset(imgdata.lg, imgdata.lg2)}
          />
        ) : (
          <></>
        )}
        {imgdata.sm || imgdata.sm2 ? (
          <source
            media="(min-width: 768px)"
            data-srcset={generateSrcset(imgdata.sm, imgdata.sm2)}
          />
        ) : (
          <></>
        )}
        {imgdata.xs || imgdata.xs2 ? (
          <source
            media="(min-width: 300px)"
            data-srcset={generateSrcset(imgdata.xs, imgdata.xs2)}
          />
        ) : (
          <></>
        )}
        <img
          className="lazyload"
          src={imgdata.xs}
          data-srcset={generateSrcset(imgdata.xs, imgdata.xs2)}
          alt=""
          style={{ height: "100%" }}
        />
      </picture>
    </>
  );
});

export default ComponentResponsiveImg;
