import { types } from "../../../reducers/types";
import axios from "axios";
import { store } from "../../../App";
import { URL_COHORT } from "../../../api/tklEnvironments";

export const postInstructorFeedback = (
  fragments,
  commenfeedback,
  activitySlugId,
  introId,
  userSlug
) => {
  return (dispatch) => {
    let path ="";
    let pageType = sessionStorage.getItem("pageType");
    if(pageType === "micro"){
      path = "/course-review-micro";
    }else if(pageType === "comprehensive"){
      path = "/course-review";
    }else if(pageType === "kidVision"){
      path = "/course-review-kidvision";
    }
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    // let userSlugData = store && store.getState()?.users?.userData?.userSlug;

    let URL_item = introId
      ? "add-introduction-instructor-feedback"
      : "add-instructor-feedback";
    let dataToSend = {};
    let fragmentPoints = [];
    dataToSend[introId ? "cohortIntroductionStepSlug" : "cohortActivitySlug"] =
      activitySlugId;
    dataToSend["feedback"] = commenfeedback;
    dataToSend["userSlug"] = userSlug || "";
    let points = 0;
    fragments.forEach((fragmentItem) => {
      if (
        fragmentItem.type === "radio" ||
        fragmentItem.type === "checkbox" ||
        fragmentItem.type === "selectBox" ||
        fragmentItem.type === "questionbox" ||
        fragmentItem.type === "fileUpload"
      ) {
        points += fragmentItem?.pointsEarned;
        let assignPointsTOFragments = {};
        assignPointsTOFragments["slug"] = fragmentItem.fragmentSlug;
        assignPointsTOFragments["point"] = !fragmentItem.pointsEarned
          ? 0
          : fragmentItem.pointsEarned;
        fragmentPoints.push(assignPointsTOFragments);
      } else if (fragmentItem.fragmentSlug) {
        let assignPointsTOFragments = {};
        assignPointsTOFragments["slug"] = fragmentItem.fragmentSlug;
        assignPointsTOFragments["point"] = !fragmentItem.pointsEarned
          ? 0
          : fragmentItem.pointsEarned;
        fragmentPoints.push(assignPointsTOFragments);
      }
    });
    dataToSend["points"] = !points ? 0 : points;
    dataToSend["pointBreakOut"] = fragmentPoints;

    axios
      .post(`${URL_COHORT}/${URL_item}`, dataToSend)
      .then(function (response) {
        if (response.data) {
          let appData = {};
          appData["instructorReview"] = {};
          appData["instructorReview"]["feedbackId"] =
            response.data.response.savedFeedback.feedbackId;
          appData["instructorReview"]["feedback"] = commenfeedback;
          dispatch({
            type: types.ADD_ACTIVITY_HEADER_DATA,
            payload: appData,
          });
          // dispatch({
          //   type: types.TOTAL_POINTS_OBTAINED,
          //   payload: points,
          // });
          // alert("Your feedback has been submitted");
          dispatch({
            type: types.API_SUCCESS,
            payload: "Your feedback has been submitted",
            pathName: path, 
          });
          dispatch({
            type: types.PROVIDE_FEEDBACK_IN_PROGRESS,
            payload: false,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        if (
          error.response?.data?.errors?.feedback[0] ===
          "The feedback field is required."
        ) {
          dispatch({
            type: types.API_ERROR,
            payload: "Please fill out the feedback",
          });
        } else
          dispatch({
            type: types.API_ERROR,
            payload: "Your feedback has not been submitted, please try again",
          });

        dispatch({
          type: types.PROVIDE_FEEDBACK_IN_PROGRESS,
          payload: false,
        });
      });
    let finalTime = performance.now();
    if (finalTime - initialTime >= 600) {
      dispatch({ type: types.HIDE_LOADER });
    } else {
      setTimeout(() => {
        dispatch({ type: types.HIDE_LOADER });
      }, 600 - (finalTime - initialTime));
    }
  };
};

export const updateInstructorFeedback = (
  fragments,
  commenfeedback,
  feedbackId,
  activitySlugId,
  introId,
  userSlug
) => {
  return (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });

    let URL_item = introId
      ? "update-introduction-instructor-feedback"
      : "update-instructor-feedback";
    let dataToSend = {};
    let fragmentPoints = [];

    dataToSend[introId ? "cohortIntroductionStepSlug" : "cohortActivitySlug"] =
      activitySlugId;
    dataToSend["feedback"] = commenfeedback;
    dataToSend["userSlug"] = userSlug || "";

    let points = 0;
    fragments.forEach((fragmentItem) => {
      if (
        fragmentItem.type === "radio" ||
        fragmentItem.type === "checkbox" ||
        fragmentItem.type === "selectBox" ||
        fragmentItem.type === "questionbox"
      ) {
        points += fragmentItem.pointsEarned;
        let assignPointsTOFragments = {};
        assignPointsTOFragments["slug"] = fragmentItem.fragmentSlug;
        assignPointsTOFragments["point"] = !fragmentItem.pointsEarned
          ? 0
          : fragmentItem.pointsEarned;
        fragmentPoints.push(assignPointsTOFragments);
      }
    });
    dataToSend["points"] = !points ? 0 : points;
    dataToSend["feedbackId"] = feedbackId;
    dataToSend["pointBreakOut"] = fragmentPoints;

    axios
      .put(`${URL_COHORT}/${URL_item}`, dataToSend)
      .then(function (response) {
        if (response.data) {
          let appData = {};
          appData["instructorReview"] = {};
          appData["instructorReview"]["feedbackId"] =
            response.data.response.savedFeedback.feedbackId;
          appData["instructorReview"]["feedback"] = commenfeedback;
          dispatch({
            type: types.ADD_ACTIVITY_HEADER_DATA,
            payload: appData,
          });
          dispatch({
            type: types.TOTAL_POINTS_OBTAINED,
            payload: points,
          });

          // alert("Your feedback has been Updated");
          dispatch({
            type: types.API_SUCCESS,
            payload: "Your feedback has been Updated",
            pathName: "/course-review",
          });
          dispatch({
            type: types.PROVIDE_FEEDBACK_IN_PROGRESS,
            payload: false,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        dispatch({
          type: types.PROVIDE_FEEDBACK_IN_PROGRESS,
          payload: false,
        });
      });
    let finalTime = performance.now();
    if (finalTime - initialTime >= 600) {
      dispatch({ type: types.HIDE_LOADER });
    } else {
      setTimeout(() => {
        dispatch({ type: types.HIDE_LOADER });
      }, 600 - (finalTime - initialTime));
    }
  };
};

export const getInstructorFeedBack = (
  activitySlugId,
  introId,
  fragments,
  userSlug
) => {
  return (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    fragments = store && store.getState().fragments.application.Fragments;
    // let reviewFeedback =
    //   store && store.getState().coursehome?.application?.activityHeader;
    let URL_item = introId
      ? `get-introduction-instructor-feedback`
      : `get-instructor-feedback`;
    console.log(
      ".....userSlug12345",
      userSlug,
      store && store.getState()?.users?.userData?.userSlug
    );

    axios
      .get(
        `${URL_COHORT}/${URL_item}/${activitySlugId}/${
          ![null, undefined, "null", "undefined", "NaN"].includes(userSlug)
            ? userSlug
            : ""
        }`
      )
      .then(function (response) {
        if (response.data) {
          let appData = {};
          if (response.data?.length === 0) {
            appData["instructorReview"] = {};
            appData["instructorReview"]["feedbackId"] = "";
            appData["instructorReview"]["feedback"] = "";
            dispatch({
              type: types.TOTAL_POINTS_OBTAINED,
              payload: 0,
            });

            store.dispatch({
              type: types.UPDATE_POINTS_EARNED,
            });

            setTimeout(() => {
              //start
              let gradingMethod =
                store &&
                store.getState().coursehome.application.settings.grading;
              let stepCompletedAt =
                store &&
                store.getState().coursehome.application?.activityHeader
                  ?.userActivity?.stepCompletedAt;
              console.log(
                gradingMethod,
                store &&
                  store.getState().coursehome.application?.activityHeader
                    ?.userActivity?.stepCompletedAt
              );
              fragments =
                store && store.getState().fragments.application.Fragments;

              if (gradingMethod === "AUTO GRADING" && stepCompletedAt) {
                let userSlugData = userSlug
                  ? userSlug
                  : store && store.getState()?.users?.userData?.userSlug;
                let URL_item = introId
                  ? "add-introduction-instructor-feedback"
                  : "add-instructor-feedback";
                let dataToSend = {};
                let fragmentPoints = [];
                dataToSend[
                  introId ? "cohortIntroductionStepSlug" : "cohortActivitySlug"
                ] = activitySlugId;
                dataToSend["feedback"] = "Auto Graded";
                dataToSend["userSlug"] = userSlugData;
                let points = 0;
                fragments.forEach((fragmentItem) => {
                  if (
                    fragmentItem.type === "radio" ||
                    fragmentItem.type === "checkbox" ||
                    fragmentItem.type === "selectBox" ||
                    fragmentItem.type === "questionbox" ||
                    fragmentItem.type === "fileUpload"
                  ) {
                    points += fragmentItem?.pointsEarned;
                    let assignPointsTOFragments = {};
                    assignPointsTOFragments["slug"] = fragmentItem.fragmentSlug;
                    assignPointsTOFragments["point"] =
                      !fragmentItem.pointsEarned
                        ? 0
                        : fragmentItem.pointsEarned;

                    fragmentPoints.push(assignPointsTOFragments);
                  } else if (fragmentItem.fragmentSlug) {
                    points += 0;
                    let assignPointsTOFragments = {};
                    assignPointsTOFragments["slug"] = fragmentItem.fragmentSlug;
                    assignPointsTOFragments["point"] = 0;
                    fragmentPoints.push(assignPointsTOFragments);
                  }
                });
                dataToSend["points"] = !points ? 0 : points;
                dataToSend["pointBreakOut"] = fragmentPoints;
                axios
                  .post(`${URL_COHORT}/${URL_item}`, dataToSend)
                  .then(function (response) {
                    if (response.data) {
                      dispatch({
                        type: types.TOTAL_POINTS_OBTAINED,
                        payload: points,
                      });
                    }
                  })
                  .catch(function (error) {
                    console.log("....error", error);
                  });
              }

              //end
            }, 3000);

            // fragments?.forEach((fragmentItem, fragmentIndex) => {
            //   if (
            //     fragmentItem.type === "radio" ||
            //     fragmentItem.type === "checkbox" ||
            //     fragmentItem.type === "selectBox" ||
            //     fragmentItem.type === "questionbox"
            //   ) {
            //     if (fragmentItem.type === "radio") {
            //       dispatch({
            //         type: types.RADIO_GROUP_FORM_DATA,
            //         payload: 0,
            //         itemIndex: fragmentIndex,
            //         label: "pointsEarned",
            //       });
            //     } else if (fragmentItem.type === "checkbox") {
            //       dispatch({
            //         type: types.CHECKBOX_FORM_DATA,
            //         payload: 0,
            //         itemIndex: fragmentIndex,
            //         label: "pointsEarned",
            //       });
            //     } else if (fragmentItem.type === "selectBox") {
            //       dispatch({
            //         type: types.RADIO_GROUP_FORM_DATA,
            //         payload: 0,
            //         itemIndex: fragmentIndex,
            //         label: "pointsEarned",
            //       });
            //     } else if (fragmentItem.type === "questionbox") {
            //       dispatch({
            //         type: types.QUESTIONBOX_FORM_DATA,
            //         payload: 0,
            //         itemIndex: fragmentIndex,
            //         label: "pointsEarned",
            //       });
            //     }
            //   }
            // });
            // setTimeout(() => {
            //   console.log("running second time");
            //   fragments?.forEach((fragmentItem, fragmentIndex) => {
            //     if (
            //       fragmentItem.type === "radio" ||
            //       fragmentItem.type === "checkbox" ||
            //       fragmentItem.type === "selectBox" ||
            //       fragmentItem.type === "questionbox"
            //     ) {
            //       // console.log("fragmentItem", fragmentItem);
            //       if (fragmentItem.type === "radio") {
            //         dispatch({
            //           type: types.RADIO_GROUP_FORM_DATA,
            //           payload: 0,
            //           itemIndex: fragmentIndex,
            //           label: "pointsEarned",
            //         });
            //       } else if (fragmentItem.type === "checkbox") {
            //         dispatch({
            //           type: types.CHECKBOX_FORM_DATA,
            //           payload: 0,
            //           itemIndex: fragmentIndex,
            //           label: "pointsEarned",
            //         });
            //       } else if (fragmentItem.type === "selectBox") {
            //         dispatch({
            //           type: types.RADIO_GROUP_FORM_DATA,
            //           payload: 0,
            //           itemIndex: fragmentIndex,
            //           label: "pointsEarned",
            //         });
            //       } else if (fragmentItem.type === "questionbox") {
            //         dispatch({
            //           type: types.QUESTIONBOX_FORM_DATA,
            //           payload: 0,
            //           itemIndex: fragmentIndex,
            //           label: "pointsEarned",
            //         });
            //       }
            //     }
            //   });
            // }, 3000);
          } else {
            appData["instructorReview"] = {};
            appData["instructorReview"]["feedbackId"] =
              response.data[0].feedbackId;
            appData["instructorReview"]["feedback"] =
              response.data[0]?.feedback;
            appData["instructorReview"]["points"] = response.data[0]?.points;
            dispatch({
              type: types.TOTAL_POINTS_OBTAINED,
              payload: response.data[0]?.points,
            });

            fragments?.forEach((fragmentItem, fragmentIndex) => {
              if (
                fragmentItem.type === "radio" ||
                fragmentItem.type === "checkbox" ||
                fragmentItem.type === "selectBox" ||
                fragmentItem.type === "questionbox" ||
                fragmentItem.type === "fileUpload"
              ) {
                response.data[0]?.pointBreakOut.forEach((pointForFragment) => {
                  if (fragmentItem.fragmentSlug == pointForFragment.slug) {
                    if (fragmentItem.type === "radio") {
                      dispatch({
                        type: types.RADIO_GROUP_FORM_DATA,
                        payload: pointForFragment.point,
                        itemIndex: fragmentIndex,
                        label: "pointsEarned",
                      });
                    } else if (fragmentItem.type === "checkbox") {
                      dispatch({
                        type: types.CHECKBOX_FORM_DATA,
                        payload: pointForFragment.point,
                        itemIndex: fragmentIndex,
                        label: "pointsEarned",
                      });
                    } else if (fragmentItem.type === "selectBox") {
                      dispatch({
                        type: types.RADIO_GROUP_FORM_DATA,
                        payload: pointForFragment.point,
                        itemIndex: fragmentIndex,
                        label: "pointsEarned",
                      });
                    } else if (fragmentItem.type === "questionbox") {
                      dispatch({
                        type: types.QUESTIONBOX_FORM_DATA,
                        payload: pointForFragment.point,
                        itemIndex: fragmentIndex,
                        label: "pointsEarned",
                      });
                    } else if (fragmentItem.type === "fileUpload") {
                      dispatch({
                        type: types.QUESTIONBOX_FORM_DATA,
                        payload: pointForFragment.point,
                        itemIndex: fragmentIndex,
                        label: "pointsEarned",
                      });
                    }
                  }
                });
              }
            });
          }
          dispatch({
            type: types.ADD_ACTIVITY_HEADER_DATA,
            payload: appData,
          });

          // dispatch({
          //   type: types.TOTAL_POINTS_OBTAINED,
          //   payload: response.data[0]?.points,
          // });
          dispatch({
            type: types.ADD_INSTRUCTOR_FEEDBACK_IN_PROGRESS,
            payload: false,
          });
          return Promise.resolve(true);
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
    let finalTime = performance.now();
    if (finalTime - initialTime >= 600) {
      dispatch({ type: types.HIDE_LOADER });
    } else {
      setTimeout(() => {
        dispatch({ type: types.HIDE_LOADER });
      }, 600 - (finalTime - initialTime));
    }
  };
};

export const sendEmailToAllUsers = (emailContent, courseSlug) => {
  return (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let URL_item = `notify-course-users/${courseSlug}`;

    axios
      .post(`${URL_COHORT}/${URL_item}`, {
        messageBody: `<p>${emailContent}</p>`,
      })
      .then(function (response) {
        if (response.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response,
          });
        }
        dispatch({
          type: types.SEND_EMAIL_IN_PROGRESS,
          payload: false,
        });
      })
      .catch(function (error) {
        console.error("..error", error);

        dispatch({
          type: types.API_ERROR,
          payload: "Failed to send Emails",
        });
        dispatch({
          type: types.SEND_EMAIL_IN_PROGRESS,
          payload: false,
        });
      });
    let finalTime = performance.now();
    if (finalTime - initialTime >= 600) {
      dispatch({ type: types.HIDE_LOADER });
    } else {
      setTimeout(() => {
        dispatch({ type: types.HIDE_LOADER });
      }, 600 - (finalTime - initialTime));
    }
  };
};
