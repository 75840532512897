import React, { useEffect, useRef, useMemo, useState, useContext } from "react";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getFacilitatorCourses } from "../../../actions/DashboardActions/InstructionalCoachActions";
import { BannerImageHandlingForAllDevices } from "../../utility/ReUsableFunctions";
import Dropdown from "react-dropdown";
import ContextUser from "../../../contexts/user-context";
import moment from "moment";

function TileM(props) {
  const dispatch = useDispatch();
  // var arr2 = ["Course Name", "Recent Activity", "Market Location"];
  // const arrGplcMicro = ["Course Name", "Recent Activity", "Micro"];
  const arrComp = ["Course Name", "Recent Activity", "Market Location"];
  const arrGplc = ["Course Name", "Recent Activity", "Account"];

  const { history } = useContext(ContextUser);

  const [eventKey, setEventKey] = useState("comp");

  const facilitatorCourses = useSelector(
    (state) => state.dashboards.facilitatorCourses
  );
  useEffect(() => {
    // courseName
    dispatch(getFacilitatorCourses("submittedForReviewAt"));
  }, []);

  const compArr = useRef([]);
  const gplcArr = useRef([]);
  const microArr = useRef([]);

  useEffect(() => {
    if (props.role === "instructionalCoach") {
      compArr.current = (facilitatorCourses || []).filter(
        (item) => item?.program?.slug === "comprehensive-course-program"
      );
      gplcArr.current = (facilitatorCourses || []).filter(
        (item) => item?.program?.slug === "guided-plc-program"
      );
      microArr.current = (facilitatorCourses || []).filter(
        (item) => item?.program?.slug === "micro-course-program"
      );
    } else if (props.role === "districtFacilitator") {
      compArr.current = (facilitatorCourses || []).filter(
        (item) => item?.program?.slug === "comprehensive-course-program"
      );
      gplcArr.current = (facilitatorCourses || []).filter(
        (item) => item?.program?.slug === "guided-plc-program"
      );
      microArr.current = (facilitatorCourses || []).filter(
        (item) => item?.program?.slug === "micro-course-program"
      );
    } else if (props.role === "schoolFacilitator") {
      compArr.current = (facilitatorCourses || []).filter(
        (item) => item?.program?.slug === "comprehensive-course-program"
      );
      gplcArr.current = (facilitatorCourses || []).filter(
        (item) => item?.program?.slug === "guided-plc-program"
      );
      microArr.current = (facilitatorCourses || []).filter(
        (item) => item?.program?.slug === "micro-course-program"
      );
    }
  }, [facilitatorCourses]);

  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "comprehensive") {
      thisArr = compArr?.current || []; // //(compArr || [])[0]?.data || [];
      char = "comp";
      // let newArr = ["Course Name", "Recent Activity", "Market Location"];
      // setArr([...newArr]);
      // arr.current = ["Course Name", "Recent Activity", "Market Location"];
    }
    if (str === "gplc") {
      thisArr = gplcArr?.current || []; //(gplcArr || [])[0].data || [];
      char = "gplc";
    }
    if (str === "micro") {
      thisArr = microArr?.current || []; //(microArr || [])[0].data || [];
      char = "micro";
      // setArr(["Course Name", "Recent Activity", "Account Name"]);
    }
    const handleNavigateForInstruction = (item) => {
      //console.log("handleNavigateForInstruction : ", item);

      sessionStorage.setItem("mode", "Instructor");
      sessionStorage.setItem("role", "Instructor");
      props.history.push(
        "/CoursePathForCohort/" + item?.cohortDisplayId + "/" + false
      );
      props.history.go(0);
    };

    return thisArr?.length === 0 ? (
      <p className="item-not-selected">No Courses Available</p>
    ) : (
      thisArr.map((item, index) => (
        <React.Fragment key={`t_m_${char}_${index}`}>
          <div className="dash-row-type-2 container rounded mb-2">
            <div className="row">
              <div className="t-row-name col-12 col-md-12 position-relative pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
                <div className="t-avtr-wrap position-absolute">
                  <a href="#">
                    <ComponentAvatar
                      type="course"
                      data={{
                        img: BannerImageHandlingForAllDevices(
                          item?.media?.url,
                          "course",
                          item?.media?.slug
                        ),
                      }}
                    />
                  </a>
                </div>
                <div>
                  <div
                    className="text-info mr-1"
                    onClick={() => handleNavigateForInstruction(item)}
                  >
                    <span>
                      {/* <a
                      name="EditCohort"
                      className="stretched-link curser-pointer mr-1"
                    >
                      {item?.course?.name}
                    </a> */}
                    </span>
                  </div>
                  <Link
                    to={`/CoursePathForCohort/${item?.cohortDisplayId}/false`}
                    className="t-row-name-link"
                    onClick={() => {
                      sessionStorage.setItem("mode", "Instructor");
                    }}
                  >
                    {item?.course?.name}
                  </Link>
                </div>
              </div>
              <div className="my_assigned_courses_style">
                <div
                  className="col-12 col-md-6 d-flex flex-column"
                  style={{ alignItems: "flex-start" }}
                >
                  <div>
                    <small className="text-muted" style={{ marginTop: "5px" }}>
                      {moment(item?.createdAt).format("MM-DD-YYYY")}
                    </small>
                    <br />
                    <small className="text-muted" style={{ marginTop: "5px" }}>
                      <b>Last Reviewed:&nbsp;</b>

                      {moment(item?.course?.lastSubmittedForReview).format(
                        "MM-DD-YYYY"
                      )}
                    </small>
                  </div>
                  <div>
                    <div style={{ marginTop: "5px" }}>
                      <small>
                        <i
                          style={{ marginTop: "5.5px" }}
                          className="material-icons text-primary"
                        >
                          place
                        </i>
                      </small>

                      <small className="text-black-50">
                        {item?.accountLocation?.name}
                      </small>
                    </div>
                  </div>
                </div>
                <div className="pt-2 col-6 col-md-3 d-flex flex-column justify-content-center align-items-start">
                  <a
                    href={item?.activeuserslink}
                    className="d-inline-block text-center"
                    onClick={() => {
                      sessionStorage.setItem("courseSlug", item?.course?.slug);
                      sessionStorage.setItem("courseName", item?.course?.name);
                      sessionStorage.setItem(
                        "marketLocationSlug",
                        item?.accountLocation?.slug
                      );
                      sessionStorage.setItem(
                        "cohortCourseID",
                        item?.cohortDisplayId
                      );
                      sessionStorage.setItem("activityType", "aup");
                      history.push("/course-review");
                      history.go(0);
                    }}
                  >
                    <strong>
                      {item?.ActiveUsers === 1
                        ? "1 Active"
                        : item?.ActiveUsers + " Active"}
                    </strong>
                    <br />

                    {item?.ActiveUsers === 1 ? "User" : "Users"}
                  </a>
                </div>
                <div className="pt-2 col-6 col-md-3 d-flex flex-column justify-content-center align-items-end">
                  <p
                    // href={item?.completeduserslink}
                    className="d-inline-block text-center text-dark"
                    style={{ margin: "0em" }}
                  >
                    <strong>
                      {item?.usersCompletedCourse === 1
                        ? "1 User"
                        : item?.usersCompletedCourse + " Users"}
                    </strong>
                    <br />
                    Completed Course
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr />
        </React.Fragment>
      ))
    );
  };
  const sortingFields = (data) => {
    if (data.value === "Course Name") {
      dispatch(getFacilitatorCourses("courseName"));
    } else if (data.value === "Recent Activity") {
      dispatch(getFacilitatorCourses("submittedForReviewAt"));
    } else if (data.value === "Market Location") {
      dispatch(getFacilitatorCourses("marketLocation"));
    } else {
      dispatch(getFacilitatorCourses("account"));
    }
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 m-0 text-uppercase">
            <strong>{props?.title}</strong>
          </h2>
        </div>
        <div className="dash-t-h-right py-2 px-3 flex-md-grow-1 d-none d-md-flex justify-content-md-end align-items-md-center"></div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container
          id="mapl_tabs"
          defaultActiveKey={
            props.role === "districtFacilitator" ||
            props.role === "schoolFacilitator"
              ? "gplc"
              : "comprehensive"
          }
        >
          <Row>
            <Col xs="12" className="mb-3">
              {/* <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="comprehensive"
                  >
                    <strong>Comprehensive</strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav> */}
              <Nav
                variant="pills"
                className="custom-tabs-link flex-row"
                style={{ position: "relative" }}
              >
                {compArr?.current?.length > 0 && (
                  <Nav.Item>
                    {!(
                      props.role === "districtFacilitator" ||
                      props.role === "schoolFacilitator"
                    ) && (
                      <Nav.Link
                        className="p-2 line-height-1 mr-2 border"
                        eventKey="comprehensive"
                        onClick={() => {
                          setEventKey("comp");
                        }}
                      >
                        <strong>Comprehensive</strong>
                      </Nav.Link>
                    )}
                  </Nav.Item>
                )}
                {gplcArr.current?.length > 0 && (
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setEventKey("gplc");
                      }}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="gplc"
                    >
                      <strong>GPLC</strong>
                    </Nav.Link>
                  </Nav.Item>
                )}
                {microArr.current?.length > 0 && (
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => {
                        setEventKey("micro");
                      }}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="micro"
                    >
                      <strong>Micro Courses</strong>
                    </Nav.Link>
                  </Nav.Item>
                )}
                <Dropdown
                  options={eventKey === "comp" ? arrComp : arrGplc}
                  placeholder="Sort By"
                  className="sort-by-dropdown-styles_facilitator"
                  onChange={sortingFields}
                  //    menuPlacement="auto"
                  value={"Recent Activity"}
                  arrowClosed={
                    <span className="material-icons">arrow_drop_down</span>
                  }
                  arrowOpen={
                    <span className="material-icons">arrow_drop_up</span>
                  }
                />
              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="comprehensive">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("comprehensive")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="gplc">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("gplc")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="micro">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("micro")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>{" "}
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}

export default withRouter(TileM);
