import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import "lazysizes";

import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.scss";
import "./framework.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider, connect } from "react-redux";
import configureStore from "./store/index";
import { BrowserRouter } from "react-router-dom";
export const store = configureStore({});

// ReactDOM.render(
// <BrowserRouter>
//   <Provider store={store}>
//     <App />,
//   </Provider>
//   ,
// </BrowserRouter>,
//   document.getElementById("root")
// );

const root = createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />,
    </Provider>
    ,
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
