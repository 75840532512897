export class settings {
  public ScheduleRelease: boolean = false;
  public CompletionRequired: boolean = true;
  public AllowRetries: boolean = true;
  public ShowAnswers: boolean = false;
  public Points = null;
  public grading: string = "SELECT GRADING";
  public releaseOn: string = "";
  public toComplete: string = "";
}

export class activity {
  public courseName: string = "";
  public moduleIndex = null;
  public moduleName: string = "";
  // public moduleObjective = new Array();
  public sectionIndex = null;
  public sectionName: string = "";
  public stepIndex = null;
  public stepName: string = "";
  public stepType: string = "";
}
