import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL_COHORT, URL } from "../../api/tklEnvironments";
import {
  avtrBannerImagesDataPrepare,
  hideLoader,
  showFirstValidationErrorFromAPI,
  prepareErrorMsg,
  streamMediaURL,
} from "../../components/utility/ReUsableFunctions";

export const getCartDataForCatalog = (isAPI) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    if (isAPI) {
      axios
        .get(`${URL_COHORT}/get-cart-data`)
        .then(function (response) {
          dispatch({
            type: types.GET_CART_DATA,
            payload: response?.data,
          });

          dispatch({
            type: types.CART_RELOAD,
            payload: false,
          });

          hideLoader(initialTime);
        })
        .catch(function (error) {
          hideLoader(initialTime);
          console.error("..error", error);
        });
    }
  };
};

export const getCartData = (isAPI, cartData, dontShowLoader) => {
  return async (dispatch) => {
    if (localStorage.getItem("token") || !isAPI) {
      let initialTime = performance.now();
      !dontShowLoader && dispatch({ type: types.SHOW_LOADER });
      if (isAPI) {
        axios
          .get(`${URL_COHORT}/get-cart-data`)
          .then(function (response) {
            let responseData = response.data; //cartDataFromApi; //response.data?.response;
            let cartDataAfterFormatting = [];
            responseData["course"].forEach((item) => {
              let avtrImg = {};
              let media_URL_avtr = streamMediaURL(item?.avatar?.slug); // `${URL}/stream-media/${item?.avatar?.slug}`;
              item["avatar"] =
                avtrBannerImagesDataPrepare(
                  avtrImg,
                  item?.avatar,
                  media_URL_avtr
                ) || {};
              let bannerImg = {};
              let media_URL_banner = streamMediaURL(item?.banner?.slug); //`${URL}/stream-media/${item?.banner?.slug}`;
              item["banner"] =
                avtrBannerImagesDataPrepare(
                  bannerImg,
                  item?.banner,
                  media_URL_banner
                ) || {};

              item["code"] = {};
              item["courseObjective"] = item?.cohortPurpose;
              item.type = "Course";
              if (item?.codeApplied) {
                item.codeApplied = calculatePriceAfterCodeAplying(
                  item?.codeApplied,
                  item?.price
                );
                item["code"] = {
                  codeType: item?.codeApplied?.discountType
                    ? "Discount"
                    : "Bundle",
                  code: item?.codeApplied?.code,
                };
              }
              cartDataAfterFormatting.push(item);
            });
            responseData["bundle"].forEach((item) => {
              item.type = "bundle";
              cartDataAfterFormatting.push(item);
            });

            dispatch({
              type: types.GET_CART_DATA,
              payload: cartDataAfterFormatting, //response?.data,
            });
            dispatch({
              type: types.CART_RELOAD,
              payload: false,
            });
            sessionStorage.setItem(
              "cartDataAfterCodeApply",
              JSON.stringify(cartDataAfterFormatting)
            );
            !dontShowLoader && hideLoader(initialTime);
          })
          .catch(function (error) {
            !dontShowLoader && hideLoader(initialTime);
            console.error("..error", error);
          });
      } else {
        let responseData = cartData;
        let cartDataAfterFormatting = [];
        responseData?.forEach((item) => {
          let avtrImg = {};
          let media_URL_avtr = streamMediaURL(item?.avatar?.slug); //`${URL}/stream-media/${item?.avatar?.slug}`;
          item["avatar"] =
            avtrBannerImagesDataPrepare(
              avtrImg,
              item?.avatar,
              media_URL_avtr
            ) || {};
          let bannerImg = {};
          let media_URL_banner = streamMediaURL(item?.banner?.slug); //`${URL}/stream-media/${item?.banner?.slug}`;
          item["banner"] =
            avtrBannerImagesDataPrepare(
              bannerImg,
              item?.banner,
              media_URL_banner
            ) || {};
          // item["code"] = {};
          item["courseObjective"] = item?.cohortPurpose;

          if (item?.type === "bundle") {
            item.type = "bundle";
            cartDataAfterFormatting.push(item);
          } else {
            item.type = "Course";
            if (item?.codeApplied) {
              item.codeApplied = calculatePriceAfterCodeAplying(
                item?.codeApplied,
                item?.price
              );
            }
            cartDataAfterFormatting.push(item);
          }
        });
        localStorage.setItem("cartData", JSON.stringify(cartData) || []);
        // console.log(".....cartData", cartData);
        dispatch({
          type: types.GET_CART_DATA,
          payload: cartData,
        });

        dispatch({
          type: types.CART_RELOAD,
          payload: false,
        });
        !dontShowLoader && hideLoader(initialTime);
      }
    }
  };
};

export const removeFromCart = (index, cohortId, type) => {
  return (dispatch) => {
    let urlItem =
      type === "Course"
        ? `type=course&cohortDisplayId=${cohortId}`
        : `type=bundle&bundleSlug=${cohortId}`;
    axios.delete(`${URL_COHORT}/remove-course-from-cart?${urlItem}`);
    dispatch({ type: types.REMOVE_FROM_CART, itemIndex: index });
  };
};

export const removeFromCartWebsite = (index, cohortId) => {
  return (dispatch) => {
    dispatch({ type: types.REMOVE_FROM_CART, itemIndex: index });
    let cartData = JSON.parse(localStorage.getItem("cartData"));
    // if (cartData?.length === 1) {
    localStorage.setItem(
      "cartData",
      JSON.stringify([
        ...cartData?.slice(0, index),
        ...cartData?.slice(index + 1),
      ])
    );
    // }
    // else {
    //   localStorage.setItem(
    //     "cartData",
    //     JSON.stringify(cartData.splice(index, 1))
    //   );
    // }
  };
};
export const getCodeData = (data, courses) => {
  return (dispatch) => {
    let urlItem =
      data?.codeType === "Bundle"
        ? "fetch-bundle-code-info"
        : "fetch-discount-code-info";
    axios
      .get(`${URL_COHORT}/${urlItem}/${data?.code}`)
      .then((response) => {
        let codeApiData = {};
        let responseData = response?.data?.response;
        codeApiData["courses"] = [];
        codeApiData["codeType"] = data?.codeType;
        if (data?.codeType === "Bundle") {
          codeApiData["redemptionCount"] = responseData?.redemptionCount;
          codeApiData["bundleSize"] = responseData?.bundleSize;
        } else {
          codeApiData["discountType"] = responseData?.discountType;
          codeApiData["discount"] = responseData?.discount;
        }
        let coursesForGivenCodeAvailable = false;
        //console.log(".....responseData", responseData, courses);
        responseData?.course &&
          responseData?.course.forEach((courseItemFromApi) => {
            courses?.forEach((courseItemFromCart) => {
              if (
                courseItemFromCart?.cohortDisplayId ===
                courseItemFromApi?.cohortDisplayId
              ) {
                codeApiData["courses"].push(courseItemFromCart);
                coursesForGivenCodeAvailable = true;
              }
            });
          });
        if (coursesForGivenCodeAvailable) {
          dispatch({
            type: types.RESUABLE_GET_DATAINPUT_BY_LABEL_CART_REDUCER,
            payload: { codeType: data?.codeType, code: data?.code },
            label: "codeData",
          });
          dispatch({
            type: types.RESUABLE_GET_DATAINPUT_BY_LABEL_CART_REDUCER,
            payload: codeApiData, //response from get
            label: "getCoursesForCode",
          });
        } else {
          dispatch({
            type: types.RESUABLE_GET_DATAINPUT_BY_LABEL_CART_REDUCER,
            payload: {
              codeType: data?.codeType,
              code: data?.code,
              message: `code cannot be applied to any of your cart Items`,
            },
            label: "errorMessageForCart",
          });
        }
      })
      .catch((error) => {
        // if (error.response?.data?.errors) {
        //   let message = showFirstValidationErrorFromAPI(
        //     error.response?.data?.errors
        //   );
        // dispatch({
        //   type: types.API_ERROR,
        //   payload: message,
        // });
        // console.log("....error?.response?.data", error?.response?.data);
        // if (error?.response?.data?.response?.message === "Not Found!") {
        dispatch({
          type: types.RESUABLE_GET_DATAINPUT_BY_LABEL_CART_REDUCER,
          payload: {
            codeType: data?.codeType,
            code: data?.code,
            message: "is an Invalid Code",
          },
          label: "errorMessageForCart",
        });
        // }
        // }
      });

    //show courses to code which can be applied
  };
};

const calculatePriceAfterCodeAplying = (codeApplied, beforePrice) => {
  // console.log(".....codeApplied,beforePrice", codeApplied, beforePrice);
  let newCodeData = codeApplied;
  if (newCodeData?.discountType) {
    if (newCodeData?.discountType === "PERCENTAGE") {
      newCodeData["newPrice"] = !newCodeData?.code
        ? beforePrice
        : beforePrice - (beforePrice * newCodeData?.discount) / 100;
      //  newCodeData["price"] = beforePrice;
    } else {
      newCodeData["newPrice"] = !newCodeData?.code
        ? beforePrice
        : beforePrice - newCodeData?.discount; //newCodeData["price"] =
    }
  } else {
    newCodeData["newPrice"] = !newCodeData?.code ? beforePrice : "0";
  }

  newCodeData["price"] = beforePrice;
  return newCodeData;
};

export const removeCodeAppliedToCourse = (
  cohortDisplayId,
  isApi,
  cartData,
  history
) => {
  return (dispatch) => {
    if (isApi) {
      axios
        .delete(`${URL_COHORT}/remove-code-for-course/${cohortDisplayId}`)
        .then(() => {
          dispatch(getCartData(isApi, cartData));
        })
        .catch((error) => {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
        });
    } else {
      cartData?.forEach((cartItem) => {
        if (
          cartItem.type === "Course" &&
          cartItem?.cohortDisplayId === cohortDisplayId
        ) {
          delete cartItem?.code;
          delete cartItem?.codeApplied;
        }
      });
      localStorage.setItem("cartData", JSON.stringify(cartData) || []);
      dispatch({
        type: types.GET_CART_DATA,
        payload: cartData,
      });
    }
  };
};

export const ApplyCodeToCourse = (
  requestData,
  newCartData,
  isAPI,
  getCoursesForCode
) => {
  return async (dispatch) => {
    if (isAPI) {
      await axios
        .post(`${URL_COHORT}/apply-bundle-or-discount-code`, requestData)
        .then((response) => {
          dispatch(getCartData(true, newCartData));
          return Promise.resolve();
        })
        .catch((error) => {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          }
          return Promise.reject();
        });
    } else {
      // console.log("......getCoursesForCode", getCoursesForCode);
      let sessionData = JSON.parse(
        sessionStorage.getItem("cartDataAfterCodeApply")
      );
      getCoursesForCode?.courses.forEach((courseItem) => {
        sessionData.forEach((sessionCartItem) => {
          if (
            courseItem?.cohortDisplayId === sessionCartItem?.cohortDisplayId
          ) {
            // console.log(".....sessionCartItem", sessionCartItem, courseItem);
            if (sessionCartItem?.codeApplied?.code) {
            } else {
              sessionCartItem["code"] = {
                codeType: courseItem?.code?.code
                  ? getCoursesForCode?.discountType
                    ? "Discount"
                    : "Bundle"
                  : null,
                code: courseItem?.code?.code,
              };
              sessionCartItem["codeApplied"] = {
                code: courseItem?.code?.code,
                discountType: getCoursesForCode?.discountType || "",
                discount: getCoursesForCode?.discount,
              };
            }
          }
        });
      });
      // console.log("......sessionData", sessionData);
      dispatch(getCartData(false, sessionData));
      return Promise.resolve();
    }
  };
};
