import { types } from "./types";
import { CourseDetails } from "../components/Models/course-model";
import { cloneDeep as cloneDeep } from "lodash";
//import { StepTypes } from '../components/models/course-path-models'

const initialState = {
  courseSlug: "",
  coursePathData: [],
  cohortData: [],
  moduleCount: 0,
  courseDetails: new CourseDetails(),
  fromActivityPage: false,
  moduleIndex: null,
  sectionIndex: null,
  activityIndex: null,
  stepTypes: [],
  isLoading: true,
  scrollID: "",
  isInitialState: true,
  showReviewComments: false,
  copiedItem: {},
  coursePathIntro: {},
  cohortIntro: {},
  apiErrorMessage: "",
  apiSuccessMessage: "",
  redirectToPath: "",
  isContinue: false,
  selectedObjectivesFromStore: [],
  isReload: false,
};

export const coursepath = (state = initialState, action: any) => {
  switch (action.type) {
    case types.INITIAL_STATE:
      return { ...state, coursePathData: [] };
    case types.ADD_MODULE:
      return {
        ...state,
        isLoading: action.payload.isLoading,
        scrollID: action.payload.scrollID,
        successMessage: action.payload.successMessage,
      };
    case types.EMPTY_COURSEPATH_DATA:
      return {
        ...state,
        coursePathData: [],
        coursePathIntro: {},
        isLoading: !state.isLoading,
      };
    case types.SELECTED_OBJECTIVES:
      return { ...state, selectedObjectivesFromStore: action.payload };
    case types.LEANER_IS_CONTINUE:
      return { ...state, isContinue: action.payload };
    case types.API_ERROR:
      return {
        ...state,
        apiErrorMessage: action.payload,
        isReload: action.isReload,
      };
    case types.API_SUCCESS:
      return {
        ...state,
        apiSuccessMessage: action.payload,
        redirectToPath: action?.pathName,
      };
    case types.UPDATE_MODULE:
      return { ...state, isLoading: action.isLoading };
    case types.DELETE_STEP:
      return { ...state, isLoading: action.isLoading };
    case types.DELETE_MODULE:
      return { ...state, isLoading: action.isLoading };
    case types.DELETE_SECTION:
      return { ...state, isLoading: action.isLoading };
    case types.DELETE_SECTION_INTRO:
      return { ...state, isLoading: action.isLoading, coursePathIntro: {} };
    case types.ADD_STEP:
      return { ...state, isLoading: action.isLoading };
    case types.UPDATE_STEP:
      return { ...state, isLoading: action.isLoading };
    case types.LOAD_COURSE_PATH_DATA:
      return { ...state, isLoading: action.payload };
    case types.UPDATE_SECTION_COUNT:
      return { ...state, coursePathData: action.payload };
    case types.UPDATE_MODULE_COUNT:
      return { ...state, moduleCount: action.payload };
    case types.ADD_SECTION:
      return { ...state };
    case types.GET_COURSE_DETAILS:
      return { ...state, courseDetails: action.payload };
    case types.GET_COHORT_COURSE_DETAILS:
      return { ...state, courseDetails: action.payload };
    case types.GET_INTRO_SECTION:
      return { ...state, coursePathIntro: action.introSecObj };
    case types.SHOW_REVIEW_COMMENTS:
      return { ...state, showReviewComments: action.payload };
    case types.COPY_ITEM:
      return { ...state, copiedItem: action.item };
    case types.GET_COHORT_CONTENT_DATA:
      return { ...state, cohortData: action.cohortData };
    case types.GET_MODULE:
      return {
        ...state,
        coursePathData: action.coursePathData,
        isLoading: false,
      };
    case types.GET_SECTIONS:
      return { ...state, coursePathData: action.sectionObjs };
    case types.GET_STEP_TYPES:
      return { ...state, stepTypes: action.payload };
    case types.SET_SCROLL:
      return { ...state, scrollID: action.payload };
    case types.ENABLE_FROM_ACTIVITY:
      return {
        ...state,
        fromActivityPage: action.payload,
      };
    case types.ADD_ACTIVITY_INDEXES:
      //console.log("...action.payload", action.payload);
      return {
        ...state,
        moduleIndex: action.payload[0],
        sectionIndex: action.payload[1],
        activityIndex: action.payload[2],
      };
    default:
      return state;
  }
};
