import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

export default function TileG() {
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0"><strong>My Activity Calendar</strong></h2>
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
      <FullCalendar
        plugins={[ dayGridPlugin ]}
        initialView="dayGridMonth"
      />
      </div>
    </article>
  );
}