import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import {
  hideLoader,
  avtrBannerImagesDataPrepare,
  streamMediaURL,
} from "../../components/utility/ReUsableFunctions";
import ControlSelectDropdown from "components/activity-controls/controls/select-dropdown";

export const getFacilitatorCourses = (sortBy) => {
  let OrderBy = sortBy === "submittedForReviewAt" ? "DESC" : "ASC";
  return async (dispatch) => {
    await axios
      .get(
        `${URL_COHORT}/get-facilitator-cohort?sortBy=${sortBy}&orderBy=${OrderBy}`
      )
      .then((response) => {
        let responseData = response.data?.response;
        responseData?.forEach((item) => {
          let avtrImg = {};
          let media_URL = streamMediaURL(item?.media?.slug); //`${URL}/stream-media/${item?.media?.slug}`;
          item["media"] =
            avtrBannerImagesDataPrepare(avtrImg, item?.media, media_URL) || {};
          item["createdAt"] = new Date(item?.createdAt);
        });
        dispatch({
          type: types.MY_COURSES_FACILITATOR,
          payload: responseData,
        });
      })
      .catch((error) => {});
  };
};

export const getActivityReviewTasks = (
  programSlug,
  stepTypeSlug,
  returnPromiseData,
  sortBy
) => {
  return async (dispatch) => {
    dispatch({
      type: types.RESUABLE_GET_ACTION,
      payload: true,
      label: "isLoading",
    });
    let responseData = null;
    let order = "ASC";
    if (sortBy === "total") {
      order = "DESC";
    }
    await axios
      .get(
        `${URL_COHORT}/get-step-type-activity-to-review?programType=${programSlug}&stepTypeSlug=${stepTypeSlug}&sortBy=${sortBy}&orderBy=${order}`
      )
      .then((response) => {
        if (response.data) {
          responseData = response.data?.response;
          // let courseNames = {};
          // courseNames[stepTypeSlug] = response.data?.courseNames;
          dispatch({
            type: types.SET_DASHBOARD_COURSE_NAMES,
            payload: {
              [stepTypeSlug]: response.data?.courseNames,
            },
            label: "courseNames",
          });

          // console.log("responseData....", stepTypeSlug, responseData);
          Object.keys(responseData)?.forEach((item) => {
            if (responseData[item]?.avatarSlug) {
              let media_URL = streamMediaURL(responseData[item]?.avatarSlug); // `${URL}/stream-media/${responseData[item]?.avatarSlug}`;
              let mediaData = {
                avatarSlug: responseData[item]?.avatarSlug,
                url: media_URL,
              };
              responseData[item]["mediaData"] = mediaData;
              responseData[item]["numberOfActivitiesToBeReviewed"] =
                (responseData[item]?.numberOfActivitiesToBeReviewed || 0) +
                (responseData[item]?.numberOfIntroStepToBeReviewed || 0);
            }
          });
          dispatch({
            type: types.RESUABLE_GET_ACTION,
            payload: responseData,
            label: "facilitatorFeedbacksCourses",
          });
          dispatch({
            type: types.RESUABLE_GET_ACTION,
            payload: false,
            label: "isLoading",
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: types.RESUABLE_GET_ACTION,
          payload: false,
          label: "isLoading",
        });
        console.log("....error", error);
      });
    if (returnPromiseData) {
      return Promise.resolve(responseData);
    }
  };
};

export const getFacilitatorMonitoringData = (
  activityType,
  programSlug,
  isCertificateCount = 1
) => {
  return async (dispatch) => {
    await axios
      .get(
        `${URL_COHORT}/get-dashboard-monitoring-progress?programName=${programSlug}&isCertificateCount=${isCertificateCount}`
      )
      .then(function (response) {
        let responseData = response?.data.response;

        if (isCertificateCount == 0) {
          dispatch({
            type: types.RESUABLE_GET_ACTION,
            payload: responseData,
            label: "aupCourses",
          });
        } else {
          dispatch({
            type: types.RESUABLE_GET_ACTION,
            payload: responseData,
            label: "uceCourses",
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getMonitoringData = (
  URLitem,
  courseSlug,
  marketLocationSlug,
  programType
) => {
  return async (dispatch) => {
    let courseSlugWithRoute = ["Gplc", "Micro"].includes(programType)
      ? `courseSlug=${courseSlug}`
      : `cohortCourseSlug=${courseSlug}`;
    let totalDispatch = 0;
    await axios
      .get(
        `${URL_COHORT}/${URLitem}?${courseSlugWithRoute}&&marketLocation=${marketLocationSlug}&&programType=${programType}`
      )
      .then((response) => {
        if (response.data) {
          let responseData = response.data?.response;
          totalDispatch = responseData?.total;
        }
      })
      .catch((error) => {
        console.log("...error", error);
        return Promise.reject(50);
      });
    return Promise.resolve(totalDispatch);
  };
};

//common Actions For Dashboards
export const updateTilePosition = (data, userSlug) => {
  let positionsData = {
    positions: data,
  };
  return (dispatch) => {
    axios
      .put(
        `${URL}/update-tile-position${userSlug ? `?userSlug=${userSlug}` : ""}`,
        positionsData
      )
      .then((response) => {
        dispatch({
          type: types.RESUABLE_GET_ACTION,
          payload: data,
          label: "positions",
        });
      });
  };
};

export const getTilePositionData = () => {
  return (dispatch) => {
    axios.get(`${URL}/get-tile-position`).then((response) => {
      if (response?.data) {
        let responseData = response?.data;
        let positionsData = store && store.getState()?.dashboards?.positions;
        let data = [];
        data = responseData?.positions;
        dispatch({
          type: types.RESUABLE_GET_ACTION,
          payload: data || [],
          label: "positions",
        });
        // if (!Array.isArray(data) || data?.length < 1 || !data || data === {}) {
        //   dispatch(updateTilePosition(positionsData));
        // }
      }
    });
  };
};
