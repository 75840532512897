import React from "react";



const UnderMaintenance = (props) => {

    const [timer, setTimer] = React.useState(null);

    React.useEffect(()=>{
       setInterval(()=>{
        let months  =['Jan','Feb', 'Mar', 'Apr','May', 'Jun', "Jul","Aug",'Sep','Oct', 'Nov', 'Dec'];
        let d = new Date();
        let month = months[d.getMonth()];
        let date = d.getDate();
        let year = d.getFullYear();
        let now = new Date().getTime();
        let countdownTime = new Date(`${month} ${date} ${year} 4:00:00`).getTime();
        let distance = countdownTime - now;

        if (distance < 0) {
            setTimer("sometime.");
        } else {
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            let  time = `${Math.abs(hours)}:${Math.abs(minutes)}:${Math.abs(seconds)}`
            setTimer(time);
        }
       }, 1000);        
    },[])
 
  return (
    <main className="pg-main p-0">
      <div
        className="login-wrap d-flex justify-content-center align-items-center"
        style={{ backgroundImage: 'url("login-bg-1.jpg")' }}
      >
        <div className="p-3 p-md-4 p-xl-5 maintenance-content">
          <div className="text-center pb-3">
            <div
              className="d-inline-block logo-wrap"
              style={{ height: "3rem" }}
            >
              <svg
                version="1.2"
                baseProfile="tiny"
                id="Layer_2"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 796 202"
              >
                <path
                  fill="#1DBAAD"
                  d="M92,92H22C9.9,92,0,82.1,0,70L0,0l24,0c37.4,0,68,30.6,68,68V92z"
                />
                <path
                  fill="#FCB127"
                  d="M180,92h-70V68c0-37.6,30.4-68,68-68h24v70C202,82.2,192.2,92,180,92z"
                />
                <path
                  fill="#DF1C45"
                  d="M47,202h-2c-24.8,0-45-20.3-45-45v-2c0-24.9,20.1-45,45-45h47v47C92,181.9,71.9,202,47,202z"
                />
                <path
                  fill="#3F749C"
                  d="M157,202h-2c-24.8,0-45-20.3-45-45v-47h47c24.9,0,45,20.1,45,45v2C202,181.9,181.9,202,157,202z"
                />
                <g>
                  <path
                    fill="#171717"
                    d="M282.9,6.6h4.6v67.9c0,7.8-4.3,11-9.2,11v1.8H311v-1.8c-4.9,0-9.2-3.2-9.2-11V6.6h4.6
		c0.8,0,23.1,0.4,23.8,20.4h1.8V4.8h-74.3V27h1.8C260,6.9,281.2,6.6,282.9,6.6z"
                  />
                  <path
                    fill="#171717"
                    d="M373.5,72.4c-2.6,5.6-11.3,8.9-18.1,7.8c-8.4-1.3-14.9-7.5-15.6-21.1H377c0-18.4-8.5-27-23.9-27
		s-26.4,11.6-26.4,28.4c0,14.6,10.5,27.7,25.4,27.7c14.7,0,21.5-9.4,23-15.1L373.5,72.4z M352.7,33.9c6,0,12.2,7.6,12.2,23.5h-25
		C339.8,44.4,345.9,33.9,352.7,33.9z"
                  />
                  <path
                    fill="#171717"
                    d="M434.8,79.5c-0.7,2.5-3.3,2.5-3.3,2.5c-5.5,0-5.5-7.7-5.5-7.7V55.5c0-13.8-3.7-23.4-17.8-23.4
		c0,0-6-0.4-11.1,2.3c-6.3,3.4-8.1,6.7-9.1,8.5c-1.1,1.9-0.9,4.4,0.5,6.4c1.9,2.7,5.7,3.3,8.4,1.4c2.7-2,3.4-5.7,1.4-8.4
		c-1.1-1.4-2.3-2.1-4-2.3c-0.7-0.1-0.1-2.2,4-4.3c8.8-4.2,15.1,2.2,15.1,14.3v7c-20.2,5.1-30.1,7-30.1,19.3
		c0,11.9,13.5,11.9,13.5,11.9c8.2,0,15.1-4.3,16.7-9.7c1.1,9.8,10.8,9.7,10.8,9.7c6.8,0,11.5-4.4,12.3-8.1L434.8,79.5z M413.4,75.6
		c-1.1,2.8-3.9,6.4-8.9,6.4c-7.4,0-8.4-6.8-8.4-8.4c0-6.6,4.8-11.8,17.3-14.6C413.4,59,413.4,75.4,413.4,75.6z"
                  />
                  <path
                    fill="#171717"
                    d="M468.7,79.8c-8.2-1.3-15.9-7.3-15.9-21.4c0-20.9,12.1-24.6,16.5-24.6c6.2,0,9.1,2.6,8.1,2.9
		c-1.5,0.6-2.8,1.9-3.4,3.6c-1.3,3.3,0.2,7.1,3.6,8.4c3.4,1.3,7.3-0.6,8.5-3.9c1.1-2.2,0.2-5-1.2-6.8c-1.5-1.8-5.7-6.1-15.7-6.1
		c-19,0-29.4,14.2-29.4,28c0,15.4,11.5,28.2,25.6,28.2c14.5,0,20.9-9.4,22.5-15.2l-1.4-0.8C484.2,76.4,476,80.9,468.7,79.8z"
                  />
                  <path
                    fill="#171717"
                    d="M506.9,74.6V47.7c2.2-4.3,6.4-8.2,10.9-8.2c7.5,0,11,4,11,11.6v23.5c0,6-0.2,9.6-4.7,10.9v1.8h21.9v-1.8
		c-4.1-1.1-4.8-4.4-4.8-10.9v-24c0-14-6-18.5-15.4-18.5c-7.5,0-16.7,6.6-18.8,13V0.4l-18.3,4.2v1.8c4.8,1.3,5.9,2.6,5.9,6.7v61.5
		c0,6-0.2,9.5-4.7,10.8v1.9h21.9v-1.9C507.5,84.3,506.9,81,506.9,74.6z"
                  />
                  <path
                    fill="#171717"
                    d="M626.7,72.6l6.6,9.6c4.7,6.9,16.3,5.9,16.3,5.9c6.4-0.7,13.5-5.1,13.5-14.6h-1.8c-0.9,4.3-5,5.6-9.1,5.6
		c0,0-6.1,0-9.7-5.4l-9.2-13.3c2.9-7.6,9.2-9.5,9.2-7c0.1,1.9,0.8,3.3,2.2,4.6c2.9,2.5,7.3,2,9.6-0.8c2.7-2.9,2.1-7.3-0.7-9.5
		c-2.1-1.8-5.4-2-7.8-1.4c-3,0.7-9,2.9-13.5,12.8L617.4,37c17.8-12.6,12.6-26.4,8.1-31.8c-3.6-4.1-9.1-6.2-17-4.8
		c-13.1,2.2-20.5,16.8-9.5,32.1l6.2,9.4l-5.9,3.5c-9.2,5.4-24.6,18.3-12.9,34.3C598.3,95.8,622.2,85.9,626.7,72.6z M611.4,3.1
		c4.2-1.5,9.1,0,12.5,3.4c5.1,6.4,7.8,18.8-7.5,28.9L608,22.9C602.4,14.6,605.3,5.2,611.4,3.1z M600.5,47.1l5.9-3.7l19.1,27.5
		c-5.7,12.3-20.6,11.7-26.9,3.9C589.4,63.3,593.9,51.7,600.5,47.1z"
                  />
                  <path
                    fill="#171717"
                    d="M277.5,187.4v-57.4c0-8.5,4.4-9.8,9.6-9.8v-1.8h-31.9v1.8c5,0,7.8,2.6,7.8,9.8v57.4c0,8-2.9,11.6-7.8,11.6
		v1.8h30.7v-1.8C280.7,198.9,277.5,195.3,277.5,187.4z"
                  />
                  <path
                    fill="#171717"
                    d="M323.3,191.9c0,0-7.7-10.4-28.3-37.9c25.3-32.4,32.6-33.9,40.8-33.9v-1.8h-35.5v1.8
		c19.2,0.4,2.3,19.8-1.1,23.6l-15.4,18.7c0,0,5.7,7.5,12.4,17.1c12.4,17.7,15.1,21.2,27.5,21.2h15.4v-1.8
		C336.9,198.9,328.8,199.2,323.3,191.9z"
                  />
                  <path
                    fill="#171717"
                    d="M359.6,129.1c0-3.9-3.3-7-7.1-7c-3.9,0-6.9,3.2-6.9,7c0,3.9,3,6.9,6.9,6.9
		C356.3,136,359.6,132.9,359.6,129.1z"
                  />
                  <path
                    fill="#171717"
                    d="M359.8,188.2v-42.6l-18.3,4v1.8c4.8,2,6,3.6,6,7.6v29.3c0,6-0.2,9.4-4.7,10.8v1.8h21.8v-1.8
		C360.4,197.8,359.8,194.6,359.8,188.2z"
                  />
                  <path
                    fill="#171717"
                    d="M425.2,190.4v-76.5l-18.3,3.7v1.8c4.8,1.3,6,2.7,6,6.8v23.3c-3.7-2.3-8.7-3.9-14.3-3.9
		c-15.9,0-28.1,8.8-28.1,27.4c0,17.3,10.3,28.8,24.8,28.8c5.6,0,13.8-2.6,17.6-9v6.8c0,0,0,2.6,3.3,2.1l15-2.3v-1.8
		C426.4,197.2,425.2,196.1,425.2,190.4z M413,184.1c0,8.2-5.4,13.9-13.2,13.9c-9.8,0-14.7-7.7-14.7-26.4c0-18.3,5.7-24.2,13.9-24.2
		c6.9,0,14,5.3,14,18.3V184.1z"
                  />
                  <path
                    fill="#171717"
                    d="M464.1,171.1l-11.8-7.4c-10.2-6.4-8.1-16.4,1.3-16.4c12.8,0,16.3,10.1,17.4,15.6h1.8v-13.7
		c0,0-5.7-3.6-19.2-3.6c-11.8,0-15.8,6.3-15.8,6.3c-5.6,8.2-2.8,16.4,8.5,23.6l11.8,7.1c8.9,5.8,8.9,17.3-1.4,17.3
		c-14.5,0-17.8-12.4-19.1-18.6h-1.8v15.3c0,0,6.7,5,20.8,5c11.3,0,15.6-6.1,15.6-6.1C478,187.5,474.6,177.6,464.1,171.1z"
                  />
                  <path
                    fill="#171717"
                    d="M542.7,199h-4.2v-68.4c0-8.4,4.7-10.4,9.7-10.4v-1.8h-32.4v1.8c5,0,8.3,3,8.3,10.2v55.9
		c0,8-2.3,12.8-7.4,12.8v1.8h62.4v-25.4h-1.6C574.4,199,549,199,542.7,199z"
                  />
                  <path
                    fill="#171717"
                    d="M613.5,145.6c-15.3,0-26.4,11.6-26.4,28.4c0,14.6,10.5,27.7,25.4,27.7c14.7,0,21.5-9.4,23-15.1l-1.6-0.8
		c-2.6,5.6-11.3,8.9-18.1,7.8c-8.4-1.3-14.9-7.5-15.6-21.1h37.2C637.4,154.3,628.8,145.6,613.5,145.6z M600.2,170.9
		c0-13,6.1-23.5,12.9-23.5c6,0,12.2,7.6,12.2,23.5H600.2z"
                  />
                  <path
                    fill="#171717"
                    d="M691.7,195.5c-5.5,0-5.5-7.7-5.5-7.7V169c0-13.8-3.7-23.4-17.8-23.4c0,0-6-0.4-11.1,2.3
		c-6.3,3.4-8.1,6.7-9.1,8.5s-0.9,4.4,0.5,6.4c1.9,2.7,5.7,3.3,8.4,1.4c2.7-2,3.4-5.7,1.4-8.4c-1.1-1.4-2.3-2.1-4-2.3
		c-0.7-0.1-0.1-2.2,4-4.3c8.8-4.2,15.1,2.2,15.1,14.3v7c-20.2,5.1-30.1,7-30.1,19.3c0,11.9,13.5,11.9,13.5,11.9
		c8.2,0,15.1-4.3,16.7-9.7c1.1,9.8,10.8,9.7,10.8,9.7c6.8,0,11.5-4.4,12.3-8.1l-1.8-0.7C694.2,195.5,691.7,195.5,691.7,195.5z
		 M673.5,189.2c-1.1,2.8-3.9,6.4-8.9,6.4c-7.4,0-8.4-6.8-8.4-8.4c0-6.6,4.8-11.8,17.3-14.6C673.5,172.5,673.5,188.9,673.5,189.2z"
                  />
                  <path
                    fill="#171717"
                    d="M791.1,188.2v-24.3c0-14.2-6.2-18.3-15.4-18.3c-7.4,0-16.1,5.4-18.7,12.4v-12.4l-18.3,4v0.1
		c-0.3-0.6-0.8-1.2-1.3-1.7c-2.1-2-5.7-2.5-8.1-1.9c-3.7,0.9-9.5,3.4-14.7,12.2v-12.6l-18.4,4v1.8c4.8,1.8,5.9,3.5,5.9,7.5v29.3
		c0,6.1-0.2,9.5-4.7,10.9v1.8h22V199c-4.2-1.2-4.8-4.3-4.8-10.9v-26.8c4-7.4,10.3-9.4,10.1-7.7c-0.1,1.8,0.6,3.6,2,5.1
		c3.2,2.9,7.8,2.8,10.8-0.1c1.9-1.9,2.5-4.5,2-6.9c4.2,1.8,5.1,3.3,5.1,7.2v29.5c0,6-0.1,9.4-4.6,10.6v1.8h21.8V199
		c-4.1-1.2-4.8-4.2-4.8-10.6v-25.7c0.5-4.4,5.7-9.8,11-9.8c6.8,0,10.8,4.7,10.8,11.5v24c0,6-0.2,9.5-4.7,10.8v1.8h21.9V199
		C791.7,197.9,791.1,194.7,791.1,188.2z"
                  />
                </g>
              </svg>
            </div>
            <h4 className="text-uppercase m-0 mt-3">
              Online Educational Services
            </h4>
          </div>
            <div>
            <h4 className=" m-0 mt-3 text-center" style={{fontWeight:500}}   >
            We are currently performing a server maintenance upgrade.
            </h4>
            <h4 className=" m-0 mt-3 text-center" >
            We will be back in <span style={{fontWeight:500}}>{timer}</span>
            </h4>
            </div>
        </div>
      </div>
    </main>
  );
};



export default UnderMaintenance;
