import { types } from "./types";
const initialState = {
  folders: [],
  currentFolderPath: [{ name: "/", slug: null }],
  fileContents: [],
  secretAccessKey: "",
  accessKeyId: "",
  token: "",
  uploadedFileSlug: "",
};
export const filestorage = (state = initialState, action: any) => {
  switch (action.type) {
    case types.UPADTE_FOLDERS:
      return { ...state, folders: action.payload };

    case types.UPDATE_FILECONTENTS:
      return { ...state, fileContents: action.payload };
    case types.UPDATE_CURRENT_FOLDER_PATH:
      return { ...state, currentFolderPath: action.payload };

    case types.UPDATE_AWS_CREDENTIALS:
      return {
        ...state,
        accessKeyId: action.payload.accessKeyId,
        secretAccessKey: action.payload.secretAccessKey,
        token: action.payload.token,
      };

    case types.UPDATE_UPLOADED_FILE_SLUG:
      return {
        ...state,
        uploadedFileSlug: action.payload,
      };

    case types.CREATE_COMMON_FOLDER:
      return {
        ...state,
        commonFolder: {},
      };
    case types.DELETE_COMMON_FOLDER:
      return {
        ...state,
        roles: action.payload,
      };
    case types.RENAME_COMMON_FOLDER:
      return {
        ...state,
        token: action.payload,
      };

    case types.CREATE_USER_FOLDER:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case types.DELETE_USER_FOLDER:
      return {
        ...state,
        roles: action.payload,
      };
    case types.RENAME_USER_FOLDER:
      return {
        ...state,
        token: action.payload,
      };

    case types.CREATE_USER_SUB_FOLDER:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case types.DELETE_USER_SUB_FOLDER:
      return {
        ...state,
        roles: action.payload,
      };
    case types.RENAME_USER_SUB_FOLDER:
      return {
        ...state,
        token: action.payload,
      };

    default:
      return state;
  }
};
