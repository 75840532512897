import React, { useEffect, useMemo, useRef } from "react";
import { Tabs, Tab, Row, Col, Nav } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import ComponentNotificationBulb from "./../../../shared-components/notification-bulb/index";
import ComponentProgressBar from "./../../../shared-components/progress-bar/index";
import {
  BannerImageHandlingForAllDevices,
  convertUTCDateToLocalDate, userRoles
} from "../../utility/ReUsableFunctions";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getPaidUserCourses } from "../../../actions/DashboardActions/PaidUserActions";
import { getCohortAndNavigatetoLearn } from "../../../actions/CohortsActions/coursePathForCohortAction";
import moment from "moment";
import "./tiles.scss";
import {
  COMPREHENSIVE_PROGRAM_MEMBER,
  COMPREHENSIVE_PROGRAM_MEMBER_NAME,
  CONTENT_ADMIN,
  CONTENT_AUTHOR,
  DISTRICT_FACILITATOR,
  GPLC_PROGRAM_MEMBER,
  GPLC_PROGRAM_MEMBER_NAME,
  MICRO_PROGRAM_MEMBER,
  KIDVISION_PROGRAM_MEMBER,
  MICRO_PROGRAM_MEMBER_NAME,
  SCHOOL_FACILITATOR,
  SYSTEM_ADMIN,
  TKL_ACCOUNT_MANAGER,
  TKL_FACILITATOR,
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
} from "../../../../src/constants.js/RoleNames";
const tilejson = require("./../../../data/dashboard/tile-a.json");

// const compArr = (tilejson || []).filter(
//   (item) => item.type === "comprehensive"
// );
// const gplcArr = (tilejson || []).filter((item) => item.type === "gplc");
// const microArr = (tilejson || []).filter((item) => item.type === "micro");

function TileA(props) {
  const dispatch = useDispatch();

  const [defaultKey, setDefaultKey] = React.useState("comprehensive");
  const [selectedValue, setSelectedValue] = React.useState("")
  const [loggeduserRole, setUserRole] = React.useState(""); 
  const facilitatorCourses = useSelector(
    (state) => state.dashboards.facilitatorCourses
  );
  const userData = useSelector((state) => state.users.userData);
  const loggedInUserRoles = useSelector((state) => state?.users?.userData?.roles);

  useEffect(() => {
    dispatch(getPaidUserCourses());
  }, []);

  const compArr = useRef([]); 
  const gplcArr = useRef([]); 
  const microArr = useRef([]); 
  const kidVisionArr = useRef([]);

  useMemo(() => {
    compArr.current = (facilitatorCourses || []).filter(
      (item) => item?.ProgramType?.slug === "comprehensive-course-program"
    );
    gplcArr.current = (facilitatorCourses || []).filter(
      (item) => item?.ProgramType?.slug === "guided-plc-program"
    );
    microArr.current = (facilitatorCourses || []).filter(
      (item) => item?.ProgramType?.slug === "micro-course-program"
    );
    kidVisionArr.current = (facilitatorCourses || []).filter(
      (item) => item?.ProgramType?.slug === "kidvision"
    );
  }, [facilitatorCourses]);

  useEffect(() => {
    if (compArr.current?.length > 0) {
      setSelectedValue("comprehensive");
    } else if (gplcArr.current?.length > 0) {
      setSelectedValue("gplc");
    } else if (microArr.current?.length > 0) {
      setSelectedValue("micro");
    } else if (kidVisionArr.current?.length > 0) {
      setSelectedValue("kidvision")
    }
  }, [
    compArr.current?.length,
    gplcArr.current?.length,
    microArr.current?.length,
    kidVisionArr.current?.length,
  ]);
  
  useEffect(() => {
      isOnlyNormalUser(userData?.roles);
  }, []);

  const isOnlyNormalUser = (roles) => {
    if(roles?.length === 2){
      if(roles.includes(userRoles?.KIDVISION_PROGRAM_MEMBER)){
        setUserRole("kidvision");
        setDefaultKey("kidvision");
        setSelectedValue("kidvision");
      }else if(roles.includes(userRoles?.GPLC_PROGRAM_MEMBER_PAID_USER)){
        setUserRole("gplc");
        setDefaultKey("gplc");
        setSelectedValue("gplc");
      }else if (roles.includes(userRoles?.MICRO_COURSE_PROGRAM_MEMBER_PAID_USER)){
        setUserRole("micro");
        setDefaultKey("micro");
        setSelectedValue("micro");
      }else if(roles.includes(userRoles?.COMPREHENSIVE_PROGRAM_MEMBER_PAID_USER) && roles.includes(!userRoles?.SYSTEM_ADMIN)){
        setUserRole("comprehensive");
        setDefaultKey("comprehensive");
        setSelectedValue("comprehensive");
      }
    }
  }
  const navLinkKidvision = useRef();
  const navLinkGplc = useRef();
  const navLinkMicro = useRef();
  const navLinkComprehensive = useRef();
  
  useEffect(() => {
    if (loggedInUserRoles.includes(userRoles?.COMPREHENSIVE_PROGRAM_MEMBER_PAID_USER)) {
      setTimeout(() => {
          navLinkComprehensive?.current?.click()
      }, 2000)
    } else if (loggedInUserRoles.includes(userRoles?.GPLC_PROGRAM_MEMBER_PAID_USER)) {
      setTimeout(() => {
        navLinkGplc?.current?.click()
      }, 2000)
    } else if (loggedInUserRoles.includes(userRoles?.MICRO_COURSE_PROGRAM_MEMBER_PAID_USER)) {
      setTimeout(() => {
        navLinkMicro?.current?.click()
      }, 2000)
    }else if (loggedInUserRoles.includes(userRoles?.KIDVISION_PROGRAM_MEMBER)) {
      setTimeout(() => {
        navLinkKidvision?.current?.click()
      }, 2000)}
  }, [loggedInUserRoles]);

  const handleNavigateToFirstStep = (item) => {
    // item?.daysLeft > 0 &&
    dispatch(
      getCohortAndNavigatetoLearn(
        item?.cohortDisplayId,
        props,
        item?.scores?.userProgress,
        item?.daysLeft
      )
    );
  };

  const courseNotReleased = (startDate) => {
    let value = convertUTCDateToLocalDate(startDate);
    return moment(value).format() > moment(new Date()).format();
  };
  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    // if (str === "comprehensive") {
    //   thisArr =compArr?.current || [];// (compArr || [])[0].data || [];
    //   char = "comp";
    // }
    // if (str === "gplc") {
    //   thisArr = (gplcArr || [])[0].data || [];
    //   char = "gplc";
    // }
    // if (str === "micro") {
    //   thisArr = (microArr || [])[0].data || [];
    //   char = "micro";
    // }
    if (str === "comprehensive") {
      thisArr = compArr?.current || []; // //(compArr || [])[0]?.data || [];
      char = "comp";
    }
    if (str === "gplc") {
      thisArr = gplcArr?.current || []; //(gplcArr || [])[0].data || [];
      char = "gplc";
    }
    if (str === "micro") {
      thisArr = microArr?.current || []; //(microArr || [])[0].data || [];
      char = "micro";
    }
    if (str === "kidvision") {
      thisArr = kidVisionArr?.current || []; //(kidVisionArr || [])[0].data || [];
      char = "kidvision";
    }
    return thisArr?.length === 0 ? (
      <p className="item-not-selected">No Courses Available</p>
    ) : (
      thisArr.map((item, index) => (
        <div>
          {item?.daysLeft < 0 && (
            <p
              style={{
                position: "absolute",
                marginTop: "75px",
                display: "block",
                color: "white",
                zIndex: 1,
                fontSize: "28px",
                fontStyle: "bold",
                width: "100%",
                height: "100%",
                textAlign: "center",
              }}
            >
              Term Ended
            </p>
          )}
          {courseNotReleased(item?.startDate) && (
            <p
              style={{
                position: "relative",
                top: "125px",
                left: "260px",
                display: "inline",
                color: "white",
                zIndex: 1,
                fontSize: "28px",
                fontStyle: "bold",
              }}
            >
              Term Not Started
            </p>
          )}
          <div
            className={`${
              item?.daysLeft < 0 ? " disabled_termEnded" : ""
            } dash-row-type-1 bg-light-gray container rounded mb-2 ${
              courseNotReleased(item?.startDate)
                ? "pointer_events_none disabled_termNotStarted"
                : ""
            }`}
            key={`t_a_${char}_${index}`}
          >
            <div className="row border-bottom">
              <div className="t-row-name col-12 position-relative py-2 pl-8 pl-md-10 pr-5 d-flex flex-column justify-content-center">
                <div className="t-avtr-wrap position-absolute ">
                  <a href={item.daysLeft >= 0 ? item.link : ""}>
                    <ComponentAvatar
                      type="course"
                      data={{
                        img: BannerImageHandlingForAllDevices(
                          item?.media?.url,
                          "course",
                          item?.media?.slug
                        ),
                      }}
                    />
                  </a>
                </div>

                <div
                  className="text-info mr-1"
                  onClick={() => {
                    !courseNotReleased(item?.startDate) &&
                      item?.daysLeft >= 0 &&
                      handleNavigateToFirstStep(item);
                  }}
                >
                  <span>
                    <span
                      name="EditCohort"
                      className="stretched-link curser-pointer mr-1 onHover_underline"
                      // style={
                      //   {
                      //     // fontSize: "20px",
                      //     // fontWeight: "600",
                      //   }
                      // }
                    >
                      {item?.cohortCourseName?.name}
                    </span>
                  </span>
                </div>

                <div className="t-notif-wrap position-absolute">
                  {/* <ComponentNotificationBulb count={item?.courseNotifications} /> */}
                </div>
              </div>
            </div>
            {courseNotReleased(item?.startDate) && (
              <p className="text-gray course_release mt-2">
                Course releases on{" "}
                {moment(convertUTCDateToLocalDate(item?.startDate)).format(
                  "LLL"
                )}
              </p>
            )}
            <div
              className={`row ${
                courseNotReleased(item?.startDate) ? "blur_content" : ""
              }`}
            >
              <div className="col-12 col-md-6 p-2 d-flex flex-column justify-content-center">
                <span className="font-semi-bold-TKL">Step Progress Bar</span>
                <div style={{ marginTop: "0.5em" }}>
                  <ComponentProgressBar
                    type={"linear"}
                    data={{
                      theme:
                        item?.scores?.userProgress >= 100
                          ? "primary"
                          : "warning",
                      progress: item?.scores?.userProgress,
                    }}
                  />
                  <strong>{item?.scores?.userProgress}%</strong>
                </div>
                <div style={{ marginTop: "0.2em" }}>
                  <Link
                    to={{
                      pathname: `/discussionForums/${item?.cohortDisplayId}`,
                      state: {
                        title: "abcd",
                        descriptionText: "abcddd",
                        discussionForumComments: [],
                        userData: userData,
                      },
                    }}
                  >
                    View Discussion Forums
                  </Link>
                </div>
              </div>
              <div className="col-6 col-md-3 p-2 text-center d-flex flex-column justify-content-center align-items-center">
                <div className="d-inline-block dash-t-cpgrs">
                  <ComponentProgressBar
                    type={"circular"}
                    data={{
                      theme:
                        (item?.scores?.userScore / item?.scores?.maxScore) *
                          100 >=
                        85
                          ? "primary"
                          : "warning",
                      value: {
                        current: item.scores?.userScore,
                        total: item.scores?.maxScore,
                      },
                      showPercent: "true",
                    }}
                  />
                </div>
                <span
                  className="font-semi-bold-TKL"
                  style={{ paddingBottom: "0.2em" }}
                >
                  My Score
                </span>

                <strong>
                  {item.scores?.userScore}/{item.scores?.maxScore}
                </strong>
              </div>
              <div className="col-6 col-md-3 p-2 text-center d-flex flex-column justify-content-center align-items-center">
                <div className="d-inline-block dash-t-cpgrs days_left_tile_a">
                  <ComponentProgressBar
                    type={"circular"}
                    data={{
                      theme: item?.daysLeft <= 14 ? "danger" : "blue",
                      value: {
                        current: item?.totalDays - item?.daysLeft,
                        total: item?.totalDays,
                      },
                      showDirectText: true,
                      showValue: item?.daysLeft >= 0 ? item?.daysLeft : 0,
                      showPercent: "false",
                      // (Math.floor(item?.daysLeft / item?.totalDays) + 1) * 100,
                    }}
                  />
                </div>
                <span className="font-semi-bold-TKL">Days Left</span>
                {/* <br />
            <strong>
              {item?.totalDays - item?.daysLeft}/{item?.totalDays}
            </strong> */}
              </div>
            </div>
          </div>
        </div>
      ))
    );
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-flex flex-row position-relative">
        <div className="dash-t-h-left flex-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>My Active Professional Learning</strong>
          </h2>
        </div>
        <div className="dash-t-h-left flex-grow-1">
          {/* Header Right Section */}
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container id="mapl_tabs" defaultActiveKey={defaultKey === "comprehensive" ? defaultKey : undefined}
            activeKey={defaultKey !== "comprehensive" ? defaultKey : undefined}> 
          <Row>
            <Col xs="12" className="mb-3 d-flex justify-content-space-between">
              {
                // loggeduserRole ?
                ((loggedInUserRoles.includes(CONTENT_ADMIN)) ||
                    (loggedInUserRoles.includes(CONTENT_AUTHOR)) ||
                    (loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER)) ||
                    (loggedInUserRoles.includes(SYSTEM_ADMIN)) ||
                    (loggedInUserRoles.includes(TKL_FACILITATOR))) ?
                <>
                  <Nav variant="pills" className="custom-tabs-link flex-row">
                  <Nav.Item>
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="comprehensive"
                      onClick={ () => setSelectedValue("comprehensive")}
                    >
                      <strong>Comprehensive</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="gplc"
                      onClick={ () => setSelectedValue("gplc")}
                    >
                      <strong>GPLC</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="micro"
                      onClick={ () => setSelectedValue("micro")}
                    >
                      <strong>Micro Courses</strong>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="kidvision"
                      onClick={ () => setSelectedValue("kidvision")}
                    >
                      <strong>KidVision</strong>
                    </Nav.Link>
                  </Nav.Item>
              </Nav>
                  <Link
                  to={
                    selectedValue === "kidvision" ? "/catalog-KidVision" 
                    :
                    selectedValue === "micro" ? "/catalog-micro"
                    :
                    selectedValue === "gplc" ? "/dashboard/catalog/gplc"
                    :
                    "/catalog-comprehensive"
                  }
                  >

                <button className="btn rounded-pill btn-outline btn-primary">
                  {selectedValue === "kidvision" ?  "Select Field Trip" : "Select New Courses"}
                </button>
              </Link>
                </>
                :
                <>
                  <Nav variant="pills" className="custom-tabs-link flex-row">
                {((loggedInUserRoles.includes(COMPREHENSIVE_PROGRAM_MEMBER_NAME)) || ((loggedInUserRoles.includes(COMPREHENSIVE_PROGRAM_MEMBER)))) && 
                    <Nav.Item>
                    <Nav.Link
                      ref={navLinkComprehensive}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="comprehensive"
                      onClick={ () => setSelectedValue("comprehensive")}
                    >
                      <strong>Comprehensive</strong>
                    </Nav.Link>
                  </Nav.Item>
                }
                {((loggedInUserRoles.includes(GPLC_PROGRAM_MEMBER)) || ((loggedInUserRoles.includes(GPLC_PROGRAM_MEMBER_NAME)))) && (
                  <Nav.Item>
                    <Nav.Link
                      ref={navLinkGplc}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="gplc"
                      onClick={ () => setSelectedValue("gplc")}
                    >
                      <strong>GPLC</strong>
                    </Nav.Link>
                  </Nav.Item>
                )} 
                {(loggedInUserRoles.includes(MICRO_PROGRAM_MEMBER)) && (
                  <Nav.Item>
                    <Nav.Link
                      ref={navLinkMicro}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="micro"
                      onClick={ () => setSelectedValue("micro")}
                    >
                      <strong>Micro Courses</strong>
                    </Nav.Link>
                  </Nav.Item>
                )}
                {(loggedInUserRoles.includes(KIDVISION_PROGRAM_MEMBER)) && (
                  <Nav.Item>
                    <Nav.Link
                      ref={navLinkKidvision}
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="kidvision"
                      onClick={ () => setSelectedValue("kidvision")}
                    >
                      <strong>KidVision</strong>
                    </Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
                  <Link
                  to={
                    selectedValue === "kidvision" ? "/catalog-KidVision" 
                    :
                    selectedValue === "micro" ? "/catalog-micro"
                    :
                    selectedValue === "gplc" ? "/dashboard/catalog/gplc"
                    :
                    "/catalog-comprehensive"
                  }
                  >

                <button className="btn rounded-pill btn-outline btn-primary">
                  {loggeduserRole === "kidvision" ? "Select Field Trip" : "Select New Courses"}
                </button>
              </Link>
                </>
              }
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="comprehensive">
                  <ComponentCustomScrollbar className="scroll-comp">
                    {renderData("comprehensive")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="gplc">
                  <ComponentCustomScrollbar className="scroll-comp">
                    {renderData("gplc")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="micro">
                  <ComponentCustomScrollbar className="scroll-comp">
                    {renderData("micro")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="kidvision">
                  <ComponentCustomScrollbar className="scroll-comp">
                    {renderData("kidvision")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}

export default withRouter(TileA);
