import React from 'react';
import ComponentCtaBtn from './../../../shared-components/cta-btn/index';

const PanelDragger = React.memo(function PanelDragger(props) {
  const { item } = props;
  return (<div className="act-ctrl rounded-lg">
    <div className="ctrl-dragger pl-1 py-2 pr-4 pr-xl-5 pl-xl-2">
      <div className="ctrl-icn mb-2" dangerouslySetInnerHTML={{ __html: item.icon }}>
      </div>
      <small className="text-uppercase">{item.name}</small>
    </div>
    <ComponentCtaBtn className="mt-2 mr-1" data={{
      tag: "button",
      type: "button",
      ctastyle: "link",
      text: "",
      title: "Add " + item.name,
      primaryicon: "add",
      isoutline: "true",
      size: "sm"
    }} />
  </div>);
});

export default PanelDragger;