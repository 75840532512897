import React, { useState, useMemo } from "react";
import TileA from "./tiles/tile-a";
import TileB from "./tiles/tile-b";
import TileC from "./tiles/tile-c";
import TileD from "./tiles/tile-d";
import TileE from "./tiles/tile-e";
import TileF from "./tiles/tile-f";
import TileG from "./tiles/tile-g";
import TileH from "./tiles/tile-h";
import TileI from "./tiles/tile-i";
import TileJ from "./tiles/tile-j";
import TileK from "./tiles/tile-k";
import TileL from "./tiles/tile-l";
import TileM from "./tiles/tile-m";
import TileN from "./tiles/tile-n";
import TileO from "./tiles/tile-o";
import TileP from "./tiles/tile-p";
import TileQ from "./tiles/tile-q";
import ActiveAccounts from "./tiles/ActiveAccountsTile";
import MicroActiveAccounts from "./tiles/MicroActiveAccount";
import KidVisionActiveAccounts from "./tiles/KidVisionActiveAccount"
import "./_index.scss";
import CourseDevelopmentStatus from "./tiles/CourseDevelopmentStatus";
import ApprovedCoursesTitle from "./tiles/ApprovedCoursesTitle";
import "./tiles/tiles.scss";
import ManageCohort from "./tiles/ManageCohortTile";
import ReviewPage from "./tiles/ReviewPage";
export default function ComponentDashboardTile(props) {
  const type = props.type || "";
  const role = props.role || "";
  const title = props.title || "";
  const Program = props.Program || "";
  const userData = props.userData || {};
  const activityType = props.activityType || "";
  const isTranscripts = props?.isTranscripts || false;

  return (
    <>
      {type === "a" ? <TileA /> : <></>}
      {type === "b" ? (
        <TileB title={title} isTranscripts={isTranscripts} />
      ) : (
        <></>
      )}
      {type === "q" ? (
        <TileQ title={title} isTranscripts={isTranscripts} />
      ) : (
        <></>
      )}
      {type === "c" ? <TileC /> : <></>}
      {type === "d" ? <TileD /> : <></>}
      {type === "e" ? <TileE /> : <></>}
      {type === "f" ? <TileF /> : <></>}
      {type === "g" ? <TileG /> : <></>}
      {type === "h" ? <TileH role={role} /> : <></>}
      {type === "i" ? <TileI /> : <></>}
      {type === "j" ? <TileJ /> : <></>}
      {type === "k" ? <TileK /> : <></>}
      {type === "l" ? <TileL /> : <></>}
      {type === "m" ? <TileM title={title} role={role} /> : <></>}
      {type === "n" ? <TileN /> : <></>}
      {type === "o" ? <TileO /> : <></>}
      {type === "p" ? (
        <TileP title={title} activityType={activityType} />
      ) : (
        <></>
      )}

      {type === "resources" && <TileE title={title} />}
      {type === "colleages" && <TileC title={title} />}

      {type === "courseDevelopmentStatus" ? (
        <CourseDevelopmentStatus
          title={title}
          role={role}
          userData={userData}
        />
      ) : (
        <></>
      )}

      {type === "authorcourses" ? (
        <CourseDevelopmentStatus
          title={title}
          role={role}
          userData={userData}
        />
      ) : (
        <></>
      )}

      {type === "approvedCourses" ? (
        <ApprovedCoursesTitle title={title} role={role} />
      ) : (
        <></>
      )}

      {type === "Active Accounts GPLC" && Program === "GPLC" && (
        <ActiveAccounts Program={"GPLC"} role={role} />
      )}
      {type === "Active Accounts MICRO" && Program === "MICRO" && (
        <MicroActiveAccounts Program={"MICRO"} role={role} />
      )}
      {type === "Active Accounts KIDVISION" && Program === "KIDVISION" && (
        <KidVisionActiveAccounts Program={"KIDVISION"} role={role} />
      )}
      {type === "manageCohorts" && <ManageCohort title={title} role={role} />}

      {type === "ReviewPage" && (
        <ReviewPage title={title} activityType={activityType} />
      )}
    </>
  );
}
