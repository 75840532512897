import React, { useState, useMemo, useEffect, useContext } from "react";
import { withRouter } from "react-router";
import ComponentCtaBtn from "../../../shared-components/cta-btn/index";
import ComponentCustomScrollbar from "../../../shared-components/custom-scrollbar/index";
import { Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getSchoolsByAccountId,getMemberMicroCourse } from "../../../actions/MasterDataActions/AccountAction";
import { getcatalogueList } from "../../../actions/DashboardActions/TklaccountManagerActions";
import { Form, Spinner } from "react-bootstrap";
import { types } from "../../../reducers/types";
import ContextUser from "../../../contexts/user-context";
import { store } from "../../../App";
import { getDisctrictsAccountManager } from "../../../actions/DashboardActions/TklaccountManagerActions";
// import { Button } from "react-bootstrap";
import { getProgramDetailsApi } from "../../../actions/UserActions/ProfileActions";
import {
  COMPREHENSIVE_PROGRAM_MEMBER,
  CONTENT_ADMIN,
  CONTENT_AUTHOR,
  DISTRICT_FACILITATOR,
  GPLC_PROGRAM_MEMBER,
  GPLC_PROGRAM_MEMBER_NAME,
  MICRO_PROGRAM_MEMBER,
  KIDVISION_PROGRAM_MEMBER,
  MICRO_PROGRAM_MEMBER_NAME,
  SCHOOL_FACILITATOR,
  SYSTEM_ADMIN,
  TKL_ACCOUNT_MANAGER,
  TKL_FACILITATOR,
  DISTRICT_ADMIN,
  SCHOOL_ADMIN,
} from "../../../../src/constants.js/RoleNames";

const KidVisionActiveAccounts = ({ Program, role }) => {
  const [selectedItem, setSelectedItem] = useState({
    name: "Active Cohort",
    slug: "Active-Cohort",
  });
  const { history } = useContext(ContextUser);

  const loggedInUserRoles = useSelector((state) => state?.users?.userData?.roles);

  const {
    kidVision,
    districtsMicro,
    schoolsMicro,
    cohortListMicro,
    cohortDatagroupedMicro,
  } = useSelector((state) => state.dashboards);

  const { isLoading } = useSelector((state) => state.masterdata);
  const { microCourseDetails } = useSelector((state) => state.tkladmin);


  const [listMicroCourse, setListMicroCourse] = useState([]);
  const [showInstructionalTopic,setShowInstructionalTopic] = useState(false);

  const [districtData, setDistrictData] = useState([]);
  const [schoolData, setSchoolData] = useState([]);

  const [districtSelected, setDistrictSelected] = useState({});
  const [schoolSelected, setSchoolSelected] = useState({});

  const dispatch = useDispatch();
  const [buttonName, setButtonName] = useState((loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER)) ? "Manage Accounts" : "Add Account");

  useEffect(() => {
    dispatch(getProgramDetailsApi());
  }, []);

  const [kidVisionProgramIdFromApi, setKidVisionProgramIdFromApi] = useState("");

  const programDataFromStore = useSelector((state) => state.dashboards.programData);
  useEffect(() => {
    setKidVisionProgramIdFromApi(programDataFromStore[4]?.programId || 4)
  },[programDataFromStore])

  useEffect(() => {
    // ApiCall for gpcl homepage api micro
    // dispatch(getDisctrictsAccountManager(Program.toLowerCase(), 0));
    dispatch(getDisctrictsAccountManager(Program.toLowerCase(), kidVisionProgramIdFromApi));
  }, [kidVisionProgramIdFromApi]);

  useEffect(() => {
    sessionStorage.setItem(
      "districtSelected",
      JSON.stringify(districtSelected)
    );
  }, [districtSelected]);

  useEffect(() => {
    sessionStorage.setItem("schoolSelected", JSON.stringify(schoolSelected));
  }, [schoolSelected]);

  const activeHandler = (event) => {
    let value = event?.target?.innerText;
    setSelectedItem({ name: value, slug: value });
  };

  useEffect(() => {
    setDistrictData(kidVision);
  }, [kidVision]);

  useEffect(() => {
    setSchoolData(schoolsMicro);
  }, [schoolsMicro]);

  // useEffect(() => {
  //   if (
  //     selectedItem?.name !== "Active Cohort" &&
  //     districtSelected?.accountId &&
  //     schoolSelected?.schoolId &&
  //     cohortListMicro
  //   ) {
  //     dispatch(
  //       getcatalogueList(
  //         districtSelected?.stateSlug,
  //         districtSelected?.accountId,
  //         schoolSelected?.schoolId,
  //         false //isGPLC
  //       )
  //     );
  //   }
  // }, [selectedItem]);

  const selectOnChangeHandler = (event, isSchool) => {
    if (isSchool) {
      setSchoolSelected({
        name: event.target?.value,
        slug: event.target[event.target.selectedIndex]?.dataset?.slug,
        schoolId: event.target[event.target.selectedIndex]?.dataset?.schoolid,
      });
      // dispatch(
      //   getcatalogueList(
      //     districtSelected?.stateSlug,
      //     districtSelected?.accountId,
      //     event.target[event.target.selectedIndex]?.dataset?.schoolid, //schoolSelected?.schoolId,
      //     //   schoolSelected?.schoolId,
      //     false //isGPLC
      //   )
      // );

      let payload = {
        accountId: districtSelected?.accountId,
        schoolId: event.target[event.target.selectedIndex]?.dataset?.schoolid,
      };

      dispatch(getMemberMicroCourse(payload));
      setListMicroCourse(microCourseDetails);
      setShowInstructionalTopic(true);

      if (
        selectedItem?.name !== "Active Cohort" &&
        districtSelected?.accountId &&
        schoolSelected?.schoolId &&
        cohortListMicro
      ) {
        // dispatch({
        //   type: types.RESUABLE_GET_ACTION,
        //   payload: [],
        //   label: "cohortDatagrouped",
        // });
      } else {
        // dispatch(
        //   getCohortList(
        //     districtSelected?.accountId,
        //     event.target[event.target.selectedIndex]?.dataset?.schoolid,
        //     false //ISGPLC
        //   )
        // );
      }
    } else {
      dispatch({
        type: types.RESUABLE_GET_ACTION,
        payload: [],
        label: "cohortListMicro",
      });
      dispatch({
        type: types.RESUABLE_GET_ACTION,
        payload: [],
        label: "cohortDatagroupedMicro",
      });
      setDistrictSelected({
        name: event.target?.value,
        slug: event.target[event.target.selectedIndex]?.dataset?.slug,
        accountId: event.target[event.target.selectedIndex]?.dataset?.accountid,
        stateSlug: event.target[event.target.selectedIndex]?.dataset?.stateslug,
      });
      if((loggedInUserRoles.includes(SCHOOL_FACILITATOR)|| loggedInUserRoles.includes(SCHOOL_ADMIN))){
        dispatch(
          getSchoolsByAccountId( 
            event.target[event.target.selectedIndex]?.dataset.accountid,
            true,
            false, //isGPLC
            true,
          )
        );
      }
      else{
        dispatch(
          getSchoolsByAccountId(
            event.target[event.target.selectedIndex]?.dataset.accountid,
            true,
            false, //isGPLC
          )
        );
      }
      setSchoolSelected({
        name: "",
        slug: "",
        schoolId: "",
      });
      // dispatch(
      //   getcatalogueList(
      //     districtSelected?.stateSlug,
      //     districtSelected?.accountId,
      //     schoolSelected?.schoolId
      //   )
      // );
    }
  };



  const renderActiveCohort = () => {
    return (
      <>
        {isLoading && (
          <div className="tkl-spinner-master-data tkl-dashboard-tile-spinner">
            <Spinner animation="border" variant="primary" size="lr" />
          </div>
        )}
        {!isLoading && (
          <Col xs="12" className="d-flex">
            <ComponentCustomScrollbar
              className="scroll-comp"
              style={{ height: "100%" }}
            >
              {showInstructionalTopic ? (
                <>
                  {microCourseDetails &&
                    microCourseDetails.map((item) => {
                      return (
                        <>
                          <div className="dash-row-type-2 container rounded mb-2 active_accounts_style">
                            <div className="row w-100 row_bottom_border">
                              <Row className="t-row-name col-12 col-md-9 col-xl-8 col-xxl-9 position-relative p-2 d-flex flex-column">
                                <Col
                                  className="font-semi-bold-TKL"
                                  style={{
                                    //  marginBottom: "0.3em",
                                    fontSize: "16px",
                                  }}
                                  sm="12"
                                  md="8"
                                >
                                    
                                  {selectedItem?.name !== "Active Topics" ? (
                                    <>
                                    <p className="font-semi-bold-TKL">
                                      Field Trips
                                   </p>
                                    <Button
                                    style={{color : role === "systemAdmin" ? "black" : "", display : role === "systemAdmin" ? "contents" : ""}}
                                      variant="link"
                                      disabled= {(role === "systemAdmin" || "accountManager") ? true :  (loggedInUserRoles.includes(DISTRICT_FACILITATOR)|| loggedInUserRoles.includes(SCHOOL_FACILITATOR)|| loggedInUserRoles.includes(DISTRICT_ADMIN)|| loggedInUserRoles.includes(SCHOOL_ADMIN)) ? true : false} 
                                      onClick={() => {
                                        history.push("/manageCohorts");
                                        history.go(0);
                                        sessionStorage.setItem("manageCohortsPopup", true);
                                        localStorage.setItem("kidVisionSelected", true);
                                        localStorage.setItem("microSelectedCourse", item?.courseName) 
                                      }}
                                    >
                                      {item?.courseName}
                                    </Button>
                                     </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                                <Row className="members-count-account">
                                  <Col
                                    style={{
                                      //   marginBottom: "0.3em",
                                      fontSize: "16px",
                                    }}
                                    sm="12"
                                    md="6"
                                  >
                                    {selectedItem?.name !== "Active Topics" ? (
                                      <>
                                        <p className="font-semi-bold-TKL">
                                          Active Members
                                        </p>
                                        <p className="TKL_number_highlight">
                                          {item.users.filter(user => user.status === 'ACTIVE').length} Members
                                        </p>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                  <Col
                                    sm="12"
                                    md="6"
                                    style={{
                                      //  marginBottom: "0.3em",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {selectedItem?.name !== "Active Topics" ? (
                                      <>
                                        <p className="font-semi-bold-TKL">
                                          Total Members
                                        </p>
                                        <p className="TKL_number_highlight">
                                        {item.users.length} Members
                                        </p>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </Col>
                                </Row>
                              </Row>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </>
              ) : (
                <>
                  <p className="item-not-selected">No Cohort is Active</p>
                </>
              )}
              <br />
            </ComponentCustomScrollbar>
          </Col>
        )}
      </>
    );
  };

  function hrefFunction(){
    localStorage.setItem("kidVisionSelect",true);
    localStorage.removeItem("microRadioSelect");
    location.href = "/masterData";
}

const genCtaClass = (props) => {
  let clsStr = " btn rounded-pill cta-comp";

  if (props.ctastyle && [true, "true"].includes(props.isoutline)) {
    clsStr += " btn-outline-" + props.ctastyle;
  }
  if (props.ctastyle && ![true, "true"].includes(props.isoutline)) {
    clsStr += " btn-" + props.ctastyle;
  }
  if (props.size) {
    clsStr += " btn-" + props.size;
  }

  if (props.primaryicon && props.ctaicon) {
    clsStr += " icon-lr";
  }
  if (!props.primaryicon && props.ctaicon) {
    clsStr += " icon-r";
  }
  if (props.primaryicon && !props.ctaicon) {
    clsStr += " icon-l";
  }

  if (!props.text) {
    clsStr += " no-text";
  }

  return clsStr;
};

  const renderActiveTopic = () => {
    return (
      <>
        {isLoading && (
          <div className="tkl-spinner-master-data tkl-dashboard-tile-spinner">
            <Spinner animation="border" variant="primary" size="lr" />
          </div>
        )}
        {!isLoading && (
          <Col xs="12" className="d-flex">
            <ComponentCustomScrollbar
              className="scroll-comp"
              style={{ height: "100%" }}
            >
              {cohortDatagroupedMicro &&
              Object.keys(cohortDatagroupedMicro).length ? (
                <>
                  {cohortDatagroupedMicro &&
                    Object.keys(cohortDatagroupedMicro).map((item) => {
                      return (
                        <>
                          <div className="dash-row-type-2 container rounded mb-2 active_accounts_style">
                            <div className="row w-100 row_bottom_border">
                              <div className="t-row-name col-12 col-md-9 col-xl-8 col-xxl-9 position-relative p-2 d-flex flex-column justify-content-center">
                                <p
                                  className="font-semi-bold-TKL"
                                  style={{
                                    marginBottom: "0.3em",
                                    fontSize: "18px",
                                  }}
                                >
                                  {
                                    <>
                                      {
                                        <Button
                                          variant="link"
                                          onClick={() => {
                                            history.push("/manage-cohorts");
                                          }}
                                        >
                                          {
                                            cohortDatagroupedMicro[item][0]
                                              ?.cohortCourseName?.name
                                          }
                                        </Button>
                                      }
                                    </>
                                  }
                                </p>
                                <p
                                  className="TKL_number_highlight"
                                  style={{
                                    marginBottom: "0.3em",
                                    fontSize: "16px",
                                  }}
                                >
                                  {
                                    <>
                                      {cohortDatagroupedMicro[item]?.length}{" "}
                                      Cohort(s) Assigned
                                    </>
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </>
              ) : (
                <>
                  <p className="item-not-selected">No Active Topics</p>
                </>
              )}
              <br />
            </ComponentCustomScrollbar>
          </Col>
        )}
      </>
    );
  };

  // useEffect(() => {
  //   console.log("....role", role);
  // }, [role]);
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2
            className="h4 text-uppercase m-0"
            style={{ display: "inline-block" }}
          >
            <strong>Active {Program} Accounts</strong>
          </h2>
          {((loggedInUserRoles.includes(CONTENT_ADMIN)) ||
            (loggedInUserRoles.includes(CONTENT_AUTHOR)) ||
            (loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER)) ||
            (loggedInUserRoles.includes(SYSTEM_ADMIN)) ||
            (loggedInUserRoles.includes(TKL_FACILITATOR))) && (
            <ComponentCtaBtn
              className="account-button-style"
              onClick={() => {
                store.dispatch({
                  type: types.ACCOUNT_TYPE,
                  payload: 4,
                });
                sessionStorage.setItem("tklAdminPageType", "kidVision"); 
                history.push("/tklAdmin");
                history.go(0);
              }}
              data={{
                tag: "button",
                type: "button",
                size: "sm",
                ctastyle: "info",
                text: buttonName,
                title: "Add Account",
                ctaicon: "chevron_right",
              }}
              
            />
          )}
        </div>
      </div>
      <div style={{ marginLeft: "1em" }}>
        {(role === "systemAdmin" || role === "accountManager") && (
          <Row
            className="d-flex flex-row"
            style={{ flexWrap: "nowrap", marginTop: "1em" }}
          >
            {
              ((!loggedInUserRoles.includes(SCHOOL_FACILITATOR)) && (!loggedInUserRoles.includes(SCHOOL_ADMIN))) &&
              <Col
              // sm="12"
              md="3"
              lg="2"
              xxl="2"
              xl="4"
              style={{ marginRight: "1em" }}
            >
              <ComponentCtaBtn
                onClick={() => {
                  store.dispatch({
                    type: types.ACCOUNT_TYPE,
                    payload: "kidvision",
                  });
                  Program === "GPLC"
                    ? history.push("/manage-cohorts")
                    : history.push("/manage-cohorts/kidVision");
                  history.go(0);
                }}
                data={{
                  tag: "button",
                  type: "button",
                  size: "sm",
                  ctastyle: "info",
                  text: "Manage Members",
                  title: "Manage Members",
                  //ctaicon: "chevron_right",
                }}
              />
            </Col>
            }
            {
              ((loggedInUserRoles.includes(CONTENT_ADMIN)) ||
                (loggedInUserRoles.includes(CONTENT_AUTHOR)) ||
                (loggedInUserRoles.includes(TKL_ACCOUNT_MANAGER)) ||
                (loggedInUserRoles.includes(SYSTEM_ADMIN)) ||
                (loggedInUserRoles.includes(TKL_FACILITATOR))) &&
              <>
                <Col
                  // sm="12"
                  md="3"
                  lg="4"
                  xxl="3"
                  xl="4"
                >
                  <ComponentCtaBtn
                    onClick={() => {
                      Program === "GPLC"
                        ? history.push("/manage-cohorts")
                        : history.push("/manageCohorts");
                      history.go(0);
                      sessionStorage.setItem("manageCohortsPopup", true);
                      localStorage.setItem("kidVisionSelected", true);
                      localStorage.removeItem("microSelected");
                      store.dispatch({
                        type: types.ACCOUNT_TYPE,
                        payload: "kidvision",
                      });
                    }}
                    data={{
                      tag: "button",
                      type: "button",
                      size: "sm",
                      ctastyle: "primary",
                      text: "Manage Cohort",
                      title: "Manage Cohort",
                      //ctaicon: "add",
                    }}
                  />
                </Col>
                <Col
                  // sm="12"
                  md="3"
                  lg="4"
                  xxl="3"
                  xl="4"
                >
                  <button
                    style={{
                      "background-color": "darken(#1DBAAD, 7.5%)",
                      color: "white",
                    }}
                    type={"button"}
                    className={genCtaClass({
                      tag: "button",
                      type: "button",
                      size: "sm",
                      ctastyle: "warning",
                      text: " Assign Focus Topic",
                      title: "Assign Focus Topic",
                    })}
                    onClick={() => { hrefFunction() }}
                  >
                    Assign Focus Topic
                  </button>
                </Col>
              </>
            }
            
          </Row>
        )}
        {(loggedInUserRoles.includes(SCHOOL_FACILITATOR)|| loggedInUserRoles.includes(SCHOOL_ADMIN)) && (
          <Row
            className="d-flex flex-row"
            style={{ flexWrap: "nowrap", marginTop: "1em" }}
          >
            <Col
              // sm="12"
              md="3"
              lg="2"
              xxl="2"
              xl="4"
              style={{ marginRight: "1em" }}
            >
              <ComponentCtaBtn
                onClick={() => {
                  store.dispatch({
                    type: types.ACCOUNT_TYPE,
                    payload: "kidvision",
                  });
                  Program === "GPLC"
                    ? history.push("/manage-cohorts")
                    : history.push("/manage-cohorts/kidVision");
                  history.go(0);
                }}
                data={{
                  tag: "button",
                  type: "button",
                  size: "sm",
                  ctastyle: "info",
                  text: "Manage Members",
                  title: "Manage Members", 
                }}
              />
            </Col>
          </Row>
        )}
        <div className="dash-t-form py-2" style={{ marginTop: "1em" }}>
          <Form>
            <Form.Row>
              <Form.Group
                as={Col}
                controlId={`tilehdistrict_${Program}`}
                className="m-0"
              >
                <Form.Label>
                  <strong>District</strong>
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  className="rounded-pill"
                  onChange={(event) => selectOnChangeHandler(event, false)}
                  id={`districtValue_${Program}`}
                >
                  <option value="">Select Disctict / Account</option>
                  {districtData &&
                    districtData.map((item) => {
                      return (
                        <option
                          value={item?.districtName}
                          data-slug={item?.slug}
                          data-accountid={item?.accountId}
                          data-stateslug={item?.state?.slug}
                          selected={
                            districtSelected?.name === item?.districtName
                          }
                        >
                          {item?.districtName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>

              <Form.Group
                as={Col}
                controlId={`tilehdischool_${Program}`}
                className="m-0"
              >
                <Form.Label>
                  <strong>School</strong>
                </Form.Label>
                <Form.Control
                  as="select"
                  size="sm"
                  className="rounded-pill"
                  onChange={(event) => selectOnChangeHandler(event, true)}
                  id={`schoolValue_${Program}`}
                >
                  <option value="" selected={schoolSelected?.name === ""}>
                    Select Location / School
                  </option>
                  {schoolData &&
                    schoolData.map((item) => {
                      return (
                        <option
                          value={item?.schoolName}
                          data-slug={item?.slug}
                          data-schoolid={item?.schoolId}
                          selected={schoolSelected?.name === item?.schoolName}
                        >
                          {item?.schoolName}
                        </option>
                      );
                    })}
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </Form>
        </div>
        <Row
          style={{ flexWrap: "nowrap", marginTop: "1em", marginLeft: "0.1em" }}
        >
          <Col sm="12" md="5">
            {/* <button
              className="sm btn btn-rounded rounded-pill"
              onClick={activeHandler}
              id={`ActiveCohort_${Program}`}
              style={{
                backgroundColor:
                  selectedItem?.name !== "Active Topics" ? "#ede8e8" : "#fff",
                border: "1px solid #dedede",
                boxShadow: "none",
              }}
            >
              Active Cohort
            </button> */}
          </Col>
          <Col sm="12" md="5">
            {/* <button
              className="sm btn btn-rounded rounded-pill"
              id={`ActiveTopics_${Program}`}
              onClick={activeHandler}
              style={{
                backgroundColor:
                  selectedItem?.name === "Active Topics" ? "#ede8e8" : "#fff",
                border: "1px solid #dedede",
                boxShadow: "none",
              }}
            >
              Active Topics
            </button> */}
          </Col>
        </Row>
      </div>

      <Row
        className="flex-grow-1"
        style={{ marginTop: "1.2em", marginLeft: "1em" }}
      >
        {districtSelected?.accountId && schoolSelected?.schoolId ? (
          <>
            {selectedItem?.name === "Active Cohort" ? (
              <>{renderActiveCohort()}</>
            ) : (
              <>{renderActiveTopic()}</>
            )}
          </>
        ) : (
          <>
            <p className="item-not-selected">Please Select School</p>
          </>
        )}
      </Row>
    </article>
  );
};
export default React.memo(withRouter(KidVisionActiveAccounts));