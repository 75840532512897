import React from "react";
import "./_index.scss";

const ComponentNotificationBulb = React.memo(function ComponentNotificationBulb(
  props
) {
  const thisCount = (num) => {
    try {
      let c = parseInt(num);
      return {
        count: c > 99 ? "99" : num + "",
        plus: c > 99 ? "+" : "",
      };
    } catch (e) {
      return {
        count: "0",
        plus: "",
      };
    }
  };
  const count = thisCount(props.count).count;
  const plus = thisCount(props.count).plus;
  return (
    <>
      {parseInt(count) >= 0 ? (
        <div className="nb-c">
          <div className="nb-i">
            <svg
              version="1.2"
              baseProfile="tiny"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 92 92"
            >
              <path d="M47,92h-2C20.2,92,0,71.7,0,47v-2C0,20.1,20.1,0,45,0h47v47C92,71.9,71.9,92,47,92z" />
            </svg>
          </div>
          <div className="nb-t d-flex justify-content-center align-items-center">
            <svg viewBox="0 0 92 92" xmlns="http://www.w3.org/2000/svg">
              <text fontSize="70" textAnchor="middle" x="46" y="68">
                {count}
              </text>
            </svg>
          </div>
          {plus === "+" ? <div className="nb-p">+</div> : <></>}
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default ComponentNotificationBulb;
