import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  addGradeLevel,
  updateGradeLevels,
} from "../../../actions/MasterDataActions/GradeLevelAction";

function GradeLevelForm(props) {
  const { updateData, isCreate, handleClose, gradeLevelIndex } = props;
  console.log("updateData : ", updateData);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    if (isCreate) {
      dispatch(
        addGradeLevel({
          name: data.name,
          status: data?.status,
          index: props.gradeLevelIndex,
        })
      );
    } else {
      // if (data.name !== updateData.name) {
      dispatch(
        updateGradeLevels({
          name: data.name,
          slug: updateData.slug,
          status: data?.status,
          index: data?.serial,
        })
      );
      // }
    }
    handleClose();
  };

  console.log("errors : ", errors);

  return (
    <div>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Grade Level <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="name"
                type="text"
                size="sm"
                defaultValue={updateData.name ? updateData.name : ""}
                placeholder="Enter Name"
                className="rounded-pill"
                {...register("name", { required: true })}
              />

              {errors.name && (
                <p className="show-txt-error">Grade Level is required</p>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Index Number <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="name"
                type="number"
                min="0"
                size="sm"
                defaultValue={
                  updateData.serial ? updateData.serial : gradeLevelIndex
                }
                placeholder="Enter Index Number"
                className="rounded-pill"
                {...register("serial", { required: true })}
              />

              {errors.name && (
                <p className="show-txt-error">Grade Level is required</p>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="status">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Status
            </Form.Label>
            <Col sm="9" className="select-container">
              <span className="select-arrow-icon material-icons">
                expand_more
              </span>

              <Form.Control
                {...register("status", { required: true })}
                as="select"
                size="sm"
                className="rounded-pill select-styles"
                defaultValue={
                  updateData?.status ? updateData?.status : "INACTIVE"
                }
              >
                <option name="INACTIVE">INACTIVE</option>
                <option name="ACTIVE">ACTIVE</option>
              </Form.Control>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="button">
            <Row className="mt-3 mr-2 ml-auto mr-3s">
              <Button
                className="m-2 btn btn-secondary rounded-pill"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                className="m-2 btn btn-primary rounded-pill"
              >
                {isCreate ? "Create" : "Update"}
              </Button>
            </Row>
          </Form.Group>
        </form>
      </Container>
    </div>
  );
}

export default GradeLevelForm;
