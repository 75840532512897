import { types } from "./types";
import { CourseDetails } from "../components/Models/course-model";
const initialState = {
  application: new CourseDetails(),
  //courseObjective: new Array(),
  allAuthors: [],
  allGradeLevels: [],
  allContentArea: [],
  allTags: [],
  allFrameworks: [],
  allFrameworkSubDomain: [],
  validationCheck: false,
  checkSubdomainValue: false,
  allPrograms: [],
  toCoursePathEdit: false,
  saveDraftdata: [],
  draftData: [],
  coursePathVisited: false,
  showContinue: false,
  alreadyExists: null,
  isLoading: false,
  provideFeebackInProgress: false,
  sendEmailInProgress: false,

  addInstructorFeedbackInProgress: false,

};
export const coursehome = (state = initialState, action: any) => {
  switch (action.type) {
    case types.INITIAL_STATE:
      return {
        ...state,
        application: new CourseDetails(),
        allFrameworkSubDomain: [],
      };
    case types.HOMELOGIN:
      return { ...state, displayValue2: action.payload };

    case types.CREATE_COURSE_FORM_DATA:
      return {
        ...state,
        application: { ...state.application, ...action.payload },
      };

    case types.CREATE_COURSE_FORM_DATA_OBJECTIVE:
      return {
        ...state,
        application: {
          ...state.application,
          courseObjective: [
            ...state.application.courseObjective,
            action.payload,
          ],
        },
      };

    case types.GET_PROGRAM_DETAILS:
      return {
        ...state,
        allPrograms: action.payload,
      };
    case types.CREATE_COURSE_FORM_PROGRAMS_ADD:
      return {
        ...state,
        application: {
          ...state.application,
          programsAvailable: [
            ...state.application.programsAvailable,
            action.payload,
          ],
        },
      };

    case types.AVATAR_IMAGE_UPLOAD_ADD:
      return {
        ...state,
        application: {
          ...state.application,
          avatarImage: action.payload,
        },
      };
    case types.BANNER_IMAGE_UPLOAD_ADD:
      return {
        ...state,
        application: {
          ...state.application,
          bannerImage: action.payload,
        },
      };
    case types.CREATE_COURSE_FORM_PROGRAMS_DELETE:
      return {
        ...state,
        application: {
          ...state.application,
          programsAvailable: [
            ...state.application.programsAvailable.slice(0, action.payload),
            ...state.application.programsAvailable.slice(action.payload + 1),
          ],
        },
      };

    case types.GET_ALL_AUTHORS:
      return {
        ...state,
        allAuthors: action.payload,
      };
    case types.ADD_AUTHORS:
      return {
        ...state,
        application: {
          ...state.application,
          authors: [...state.application.authors, action.payload],
        },
      };
    case types.DELETE_AUTHORS:
      return {
        ...state,
        application: {
          ...state.application,
          authors: [
            ...state.application.authors.slice(0, action.payload),
            ...state.application.authors.slice(action.payload + 1),
          ],
        },
      };

    case types.GET_ALL_GRADELEVELS:
      return {
        ...state,
        allGradeLevels: action.payload,
      };
    case types.ADD_GRADELEVEL:
      return {
        ...state,
        application: {
          ...state.application,
          gradeLevel: [...state.application.gradeLevel, action.payload],
        },
      };
    case types.DELETE_GRADELEVEL:
      return {
        ...state,
        application: {
          ...state.application,
          gradeLevel: [
            ...state.application.gradeLevel.slice(0, action.payload),
            ...state.application.gradeLevel.slice(action.payload + 1),
          ],
        },
      };

    case types.GET_ALL_CONTENTAREA:
      return {
        ...state,
        allContentArea: action.payload,
      };
    case types.ADD_CONTENTAREA:
      return {
        ...state,
        application: {
          ...state.application,
          contentArea: [...state.application.contentArea, action.payload],
        },
      };
    case types.DELETE_CONTENTAREA:
      return {
        ...state,
        application: {
          ...state.application,
          contentArea: [
            ...state.application.contentArea.slice(0, action.payload),
            ...state.application.contentArea.slice(action.payload + 1),
          ],
        },
      };

    case types.GET_ALL_TAGS:
      return {
        ...state,
        allTags: action.payload,
      };
    case types.ADD_TAGS:
      return {
        ...state,
        application: {
          ...state.application,
          tags: [...state.application.tags, action.payload],
        },
      };
    case types.DELETE_TAGS:
      return {
        ...state,
        application: {
          ...state.application,
          tags: [
            ...state.application.tags.slice(0, action.payload),
            ...state.application.tags.slice(action.payload + 1),
          ],
        },
      };

    case types.GET_ALL_FRAMEWORKS:
      return {
        ...state,
        allFrameworks: action.payload,
      };
    case types.ADD_FRAMEWORKS:
      return {
        ...state,
        application: {
          ...state.application,
          frameworkDomain: [
            ...state.application.frameworkDomain,
            action.payload,
          ],
        },
      };
    case types.DELETE_FRAMEWORKS:
      return {
        ...state,
        application: {
          ...state.application,
          frameworkDomain: [
            ...state.application.frameworkDomain.slice(0, action.payload),
            ...state.application.frameworkDomain.slice(action.payload + 1),
          ],
        },
      };
    case types.ADD_FRAMEWORKS_SUB_DOMAIN:
      return {
        ...state,
        application: {
          ...state.application,
          frameworkSubDomain: [
            ...state.application.frameworkSubDomain,
            action.payload,
          ],
        },
      };
    case types.DELETE_FRAMEWORKS_SUB_DOMAIN:
      return {
        ...state,
        application: {
          ...state.application,
          frameworkSubDomain: [
            ...state.application.frameworkSubDomain.slice(0, action.payload),
            ...state.application.frameworkSubDomain.slice(action.payload + 1),
          ],
        },
      };
    case types.DELETE_COMPONENT_DOMAIN_FROM_DOMAIN:
      return {
        ...state,
        // application: {
        //     ...state.application,
        allFrameworkSubDomain: [
          ...state.allFrameworkSubDomain.slice(0, action.payload),
          ...state.allFrameworkSubDomain.slice(action.payload + 1),
        ],
        // }
      };

    case types.ADD_DATA_TO_SUBDOMAIN:
      return {
        ...state,
        allFrameworkSubDomain: [...state.allFrameworkSubDomain, action.payload],
      };
    case types.ADD_DATA_TO_SUBDOMAIN_FROM_GET_DATA_BY_SLUG:
      let addDataTosubDomain = [...state.allFrameworkSubDomain];
      let addData = [];
      addDataTosubDomain.forEach((subdomain) => {
        if (subdomain["slug"] !== action.payload["slug"]) {
          addData.push(subdomain);
        }
      });
      return {
        ...state,
        allFrameworkSubDomain: addData,
      };
    case types.REMOVE_DATA_FROM_SUBDOMAIN:
      // //console.log("...subdomains", action.subDomains);
      // let updatedallFrameworkSubDomain = [...state.allFrameworkSubDomain];
      // let reupdateallDomains = [];
      // updatedallFrameworkSubDomain.forEach((allsubd, index) => {
      //   let itemdomainIndex = action.subDomains.findIndex(
      //     (subdomain) => subdomain.name === allsubd["name"]
      //   );
      //   if (itemdomainIndex === -1) {
      //     reupdateallDomains.push(allsubd);
      //   }
      // });
      return {
        ...state,
        allFrameworkSubDomain: [
          ...state.allFrameworkSubDomain.slice(0, action.startIndex),
          ...state.allFrameworkSubDomain.slice(
            action.startIndex + action.len - 1 + 1
          ),
        ],
        //allFrameworkSubDomain: reupdateallDomains,
      };
    case types.VALIDATION_CHECK:
      return {
        ...state,
        validationCheck: action.payload,
      };

    case types.CHECK_SUBDOMAIN_VALUE:
      return {
        checkSubdomainValue: action.payload,
      };

    case types.GET_DATA_BY_SLUG:
      return {
        ...state,
        application: action.payload,
      };
    case types.NAVIGATE_TO_COURSE_PATH_EDIT:
      return {
        ...state,
        toCoursePathEdit: action.payload,
      };

    case types.GET_DATA_FOR_SAVE_DRAFT:
      return {
        saveDraftdata: action.payload,
      };

    case types.ACTIVITY_SETTINGS_CHECKBOXES:
      return {
        ...state,
        application: {
          ...state.application,
          settings: { ...state.application.settings, ...action.payload },
        },
      };
    case types.GET_DRAFT_DATA:
      return {
        ...state,
        draftData: action.payload,
      };
    case types.VISITED_TO_COURSE_PATH:
      return {
        ...state,
        coursePathVisited: action.payload,
      };

    case types.ADD_ACTIVITY_HEADER_DATA:
      return {
        ...state,
        application: {
          ...state.application,
          activityHeader: {
            ...state.application.activityHeader,
            ...action.payload,
          },
        },
      };
    case types.UPDATE_CONTINUE_WITH_COURSE_PATH:
      return {
        ...state,
        showContinue: action.payload,
      };

    case types.CHECK_COURSE_NAME:
      // alert(action.payload);
      return {
        ...state,
        alreadyExists: action.payload,
      };

    case types.DELETE_COURSE:
      return {
        ...state,
        draftData: [],
      };
    case types.SHOW_LOADER:
      return {
        ...state,
        isLoading: true,
      };

    case types.HIDE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    case types.PROVIDE_FEEDBACK_IN_PROGRESS:
      return {
        ...state,
        provideFeebackInProgress: action.payload,
      };

    case types.SEND_EMAIL_IN_PROGRESS:
      return {
        ...state,
        sendEmailInProgress: action.payload,
      };

    case types.ADD_INSTRUCTOR_FEEDBACK_IN_PROGRESS:
      return {
        ...state,
        addInstructorFeedbackInProgress: action.payload,
      };
    case types.CREATE_COURSE_RESUABLE_FIELD_VALUE:
      return {
        ...state,
        [action.parentLabel]: {
          ...state[action.parentLabel],
          [action.label]: action.payload,
        },
      };

    default:
      return state;
  }
};
