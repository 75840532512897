import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import {
  hideLoader,
  avtrBannerImagesDataPrepare,
} from "../../components/utility/ReUsableFunctions";
import { showFirstValidationErrorFromAPI } from "../../components/utility/ApiErrorHandler";
import _ from "lodash";
export const getDisctrictsAccountManager = (accountType, programId) => {
  return (dispatch) => {
    let initialTime = performance.now();
    let labelValue = "";
    if(programId === 1) {
      labelValue = "districts";
    }else if(programId === 2) {
      labelValue = "districtsMicro";
    }else if(programId === 4) {
      labelValue = "kidVision";
    }
    //dispatch({ type: types.SHOW_LOADER });
    // ApiCall for gpcl homepage api call
    if(programId === 1 || programId === 2 || programId === 3 || programId === 4 ){
      axios
      .get(
        `${URL}/get-all-accounts/${
          programId
        }?isPaginated=${0}`
      )
      .then(function (response) {
        //hideLoader(initialTime);
        if (response?.data) {
          // if (isGplc) { 
          //   // isGplc will be programID
          //   dispatch({
          //     type: types.RESUABLE_GET_ACTION,
          //     payload: response?.data?.response,
          //     label: "districts",
          //   });
          // } else {
          //   dispatch({
          //     type: types.RESUABLE_GET_ACTION,
          //     payload: response?.data?.response,
          //     label: "districtsMicro",
          //   });
          // }
          dispatch({
              type: types.RESUABLE_GET_ACTION,
              payload: response?.data?.response,
              label: labelValue,
              });
        }
      })
      .catch(function (error) {
        if (error.response) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
      });
    }
  };
};
export const getCohortList = (accountId, schoolId, isGPLC) => {
  return async (dispatch) => {
    if (accountId && schoolId) {
      await axios
        .get(`${URL_COHORT}/get-cohort-list/${accountId}/${schoolId}`)
        .then((response) => {
          if (response.data)
            if (isGPLC) {
              dispatch({
                type: types.RESUABLE_GET_ACTION, //reusable
                payload: response.data?.response,
                label: "cohortList",
              });
            } else {
              dispatch({
                type: types.RESUABLE_GET_ACTION, //reusable
                payload: response.data?.response,
                label: "cohortListMicro",
              });
            }
        })
        .catch((error) => {
          if (error.response?.data?.errors) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          } else {
            dispatch({
              type: types.API_ERROR,
              payload: "There's some error, Please try again",
            });
          }
        });
    }
    return Promise.resolve();
  };
};
export const getcatalogueList = (stateSlug, accountId, schoolId, isGPLC) => {
  return (dispatch) => {
    let stateParam = stateSlug !== null ? `stateSlug=${stateSlug}` : "";
    let accountIdParam = accountId !== null ? `&accountId=${accountId}` : "";
    dispatch({ type: types.IS_LOADING, payload: true });
    if (stateParam && accountIdParam) {
      axios
        .get(
          `${URL_COHORT}/get-account-catalogue-course?${stateParam}&isFeature=0${accountIdParam}`
        )
        .then((response) => {
          //   console.log(".....accountId, schoolId", accountId, schoolId);
          let cohortData = [];
          accountId &&
            schoolId &&
            dispatch(getCohortList(accountId, schoolId, isGPLC)).then(() => {
              let cohortList = store && store.getState().dashboards.cohortList;
              response.data?.response.forEach((cohortCourseItem) => {
                let cohortGroupCountForID = 0;
                cohortList.forEach((cohortListItem) => {
                  cohortListItem?.cohorts.forEach((cohortId) => {
                    if (
                      cohortCourseItem?.cohortDisplayId ===
                      cohortId?.cohortDisplayId
                    ) {
                      let courseData = {
                        cohortDisplayId: cohortId?.cohortDisplayId,
                        cohortGroupCountForID: ++cohortGroupCountForID,
                        cohortCourseName: cohortCourseItem?.course,
                      };
                      cohortGroupCountForID = 0;
                      cohortData.push(courseData);
                    }
                  });
                });
              });
              var cohortDatagrouped = _.groupBy(cohortData, function (item) {
                return item.cohortDisplayId;
              });
              // console.log("......cohortDatagrouped", cohortDatagrouped);
              if (isGPLC) {
                dispatch({
                  type: types.RESUABLE_GET_ACTION,
                  payload: cohortDatagrouped,
                  label: "cohortDatagrouped",
                });
              } else {
                dispatch({
                  type: types.RESUABLE_GET_ACTION,
                  payload: cohortDatagrouped,
                  label: "cohortDatagroupedMicro",
                });
              }
            });
          dispatch({ type: types.IS_LOADING, payload: false });
        })
        .catch((error) => {
          if (error.response) {
            let message = showFirstValidationErrorFromAPI(
              error.response?.data?.errors
            );
            dispatch({
              type: types.API_ERROR,
              payload: message,
            });
          } else {
            dispatch({
              type: types.API_ERROR,
              payload: "There's some error, Please try again",
            });
          }
          dispatch({ type: types.IS_LOADING, payload: false });
        });
    }
  };
};
