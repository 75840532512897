import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  updateFrameWork,
  addFrameWork,
} from "../../../actions/MasterDataActions/FrameworkAction";

function FrameworkDomainForm(props) {
  const { updateData, isCreate, handleClose } = props;
  const [DomainNamingError, showError] = React.useState(false);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // console.log(
    //   "....validation",
    //   data.name.includes(": "),
    //   data?.name.substr(0, 6),
    //   data?.name.substr(0, 7) === "Domain ",
    //   data?.name.length > 10
    // );
    if (
      !(
        data.name.includes(": ") &&
        data?.name.substr(0, 7) === "Domain " &&
        data?.name.length > 10
      )
    ) {
      showError(true);
    } else {
      showError(false);
      if (isCreate) {
        dispatch(
          addFrameWork({
            index: parseInt(data?.serialNumber),
            name: data.name,
            status: data.status,
          })
        );
      } else {
        // if (data.name !== updateData.name) {
        dispatch(
          updateFrameWork({
            name: data.name,
            index: parseInt(data?.serialNumber),
            slug: updateData.slug,
            status: data.status,
          })
        );
        // }
      }
      handleClose();
    }
  };

  function auto_grow(element) {
    element.style.height = "1px";
    element.style.height = 25 + element.scrollHeight + "px";
  }
  return (
    <div>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Framework Domain <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="name"
                type="text"
                size="sm"
                defaultValue={updateData.name ? updateData.name : ""}
                placeholder="Enter Name"
                className="rounded-pill"
                {...register("name", { required: true })}
              />

              {errors.name && (
                <p className="show-txt-error">Framework Domain is Required</p>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Index Number <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="serialNumber"
                type="number"
                min="0"
                size="sm"
                defaultValue={updateData.index ? updateData.index : 0}
                placeholder="Enter Index Number"
                className="rounded-pill"
                {...register("serialNumber", { required: true })}
              />

              {errors.serialNumber && (
                <p className="show-txt-error">Serial Number is Required</p>
              )}
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="status">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Status
            </Form.Label>
            <Col sm="9" className="select-container">
              <span className="select-arrow-icon material-icons">
                expand_more
              </span>

              <Form.Control
                {...register("status", { required: true })}
                // onChange={changeStatus}
                as="select"
                className="rounded-pill select-styles"
                size="sm"
                defaultValue={
                  updateData?.status ? updateData?.status : "INACTIVE"
                }
              >
                <option name="INACTIVE">INACTIVE</option>
                <option name="ACTIVE">ACTIVE</option>
              </Form.Control>
            </Col>
          </Form.Group>
          {!isCreate && (
            <Form.Group as={Row} controlId="subDomain">
              <Form.Label column sm="3" className="font-semi-bold-TKL">
                Sub Domain
              </Form.Label>
              <Col sm="9" className="select-container">
                <Form.Control
                  as="textarea"
                  className="select-styles"
                  size="sm"
                  rows={3}
                  cols={4}
                  defaultValue={updateData?.subdomain || ""}
                  disabled={true}
                ></Form.Control>
              </Col>
            </Form.Group>
          )}
          <div>
            {DomainNamingError && (
              <>
                <p
                  className="font-semi-bold-TKL"
                  style={{
                    fontSize: "16px",
                    marginBottom: "0.5em",
                    marginTop: "2em",
                  }}
                >
                  Domain naming must follow below convention
                </p>
                <div>
                  <p
                    className="validation-color-description font-semi-bold-TKL"
                    style={{ fontSize: "16px" }}
                  >
                    <i>Domain Domain_Number: Domain Name</i>
                  </p>
                  <p
                    className="font-semi-bold-TKL"
                    style={{
                      fontSize: "15px",
                      marginBottom: "0.5em",
                      marginTop: "1em",
                    }}
                  >
                    <i>(Example) Domain 2: Instruction</i>
                  </p>
                </div>
              </>
            )}
          </div>
          <Form.Group as={Row} controlId="name">
            <Row className="mt-3 mr-2 ml-auto mr-3s">
              <Button
                className="m-2 btn btn-secondary rounded-pill"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                className="m-2 btn btn-primary rounded-pill"
              >
                {isCreate ? "Create" : "Update"}
              </Button>
            </Row>
          </Form.Group>
        </form>
      </Container>
    </div>
  );
}

export default FrameworkDomainForm;
