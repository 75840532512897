export const CONTENT_ADMIN = "CONTENT ADMIN";
export const CONTENT_AUTHOR = "CONTENT AUTHOR";
export const TKL_FACILITATOR = "TKL FACILITATOR";
export const COMPREHENSIVE_PROGRAM_MEMBER =
  "COMPREHENSIVE PROGRAM MEMBER (PAID USER)";
export const COMPREHENSIVE_PROGRAM_MEMBER_NAME = "COMPREHENSIVE PROGRAM MEMBER";
export const GPLC_PROGRAM_MEMBER_NAME = "GPLC PROGRAM MEMBER";
export const MICRO_PROGRAM_MEMBER_NAME = "MICRO PROGRAM MEMBER";

export const GPLC_PROGRAM_MEMBER = "GPLC PROGRAM MEMBER (PAID USER)";
export const MICRO_PROGRAM_MEMBER = "MICRO COURSE PROGRAM MEMBER (PAID USER)";
export const KIDVISION_PROGRAM_MEMBER = "KIDVISION PROGRAM MEMBER (PAID USER)";

export const TKL_ACCOUNT_MANAGER = "TKL ACCOUNT MANAGER";
export const DISTRICT_FACILITATOR = "DISTRICT FACILITATOR";
export const SCHOOL_FACILITATOR = "SCHOOL FACILITATOR";
export const DISTRICT_ADMIN = "DISTRICT ADMIN";
export const SCHOOL_ADMIN = "SCHOOL ADMIN";
export const SYSTEM_ADMIN = "SYSTEM ADMIN";
export const TKL_MEMBER = "TKL MEMBER (FREE USER)";
