import { notifications } from "./notifications";
import { masterdata } from "./masterdata";
import { combineReducers } from "redux";
import { coursehome } from "./coursehome";
import { coursepath } from "./coursepath";
import { fragments } from "./fragments";
import { cohorts } from "./cohorts";
import { register } from "./register";
import { users } from "./users";
import { auth } from "./auth";
import { tkladmin } from "./tkladmin";
import { dashboards } from "./dashboards";
import { catalogue } from "./catalogue";
import { community } from "./community";
import { filestorage } from "./filestorage";

export default combineReducers({
  coursehome: coursehome,
  coursepath: coursepath,
  fragments: fragments,
  cohorts: cohorts,
  register: register,
  auth: auth,
  masterdata: masterdata,
  users: users,
  tkladmin: tkladmin,
  dashboards: dashboards,
  catalogue: catalogue,
  notifications: notifications,
  community: community,
  filestorage: filestorage,
});
