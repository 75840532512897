import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import _ from "lodash";
import {
  prepareErrorMsg,
  showFirstValidationErrorFromAPI,
  hideLoader,
} from "../../components/utility/ReUsableFunctions";
import { getProfileData } from "../UserActions/ProfileActions";
export const createPost = (payload) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    axios
      .post(`${URL}/add-post`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: true,
          });
          dispatch({ type: types.HIDE_LOADER });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        dispatch({ type: types.HIDE_LOADER });
      });
  };
};

export const createGroup = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/create-group`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.IS_GROUP_RELOAD,
            payload: true,
          });

          dispatch({
            type: types.API_SUCCESS,
            payload: "Group Created Successfully",
          });
          return response?.data;
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updateGroup = (payload) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/update-group`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.IS_GROUP_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const deleteGroup = (payload) => {
  return async (dispatch) => {
    axios
      .delete(`${URL}/delete-group/${payload}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Group Deleted Successfully",
          });
          dispatch({
            type: types.IS_GROUP_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const deleteResource = (payload) => {
  return async (dispatch) => {
    axios
      .delete(`${URL}/delete-community-resource/${payload}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Resource  Deleted Successfully",
          });
          dispatch({
            type: types.RESOURCE_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const deleteForumComment = (payload) => {
  return async (dispatch) => {
    axios
      .delete(`${URL}/delete-forum-comment/${payload}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.FORUM_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};
export const deleteForum = (payload) => {
  return async (dispatch) => {
    axios
      .delete(`${URL}/delete-forum/${payload}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Forum  Deleted Successfully",
            pathName: "closeWindow",
          });
          dispatch({
            type: types.FORUM_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const recommandResource = (payload) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/recommend-resource/${payload}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response,
          });

          dispatch({
            type: types.RESOURCE_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addRemoveBookmark = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/add-or-remove-bookmark/${payload}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response,
          });
          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: true,
          });
          dispatch({
            type: types.BOOKMARKED_POST_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.BOOKMARKED_POST_RELOAD,
          payload: true,
        });
        console.error("..error", error);
      });
  };
};

export const updatePost = (payload) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    axios
      .put(`${URL}/update-post`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: true,
          });
          dispatch({ type: types.HIDE_LOADER });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        dispatch({ type: types.HIDE_LOADER });
      });
  };
};

export const addBannerImage = (payload) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    return await axios
      .post(`${URL}/update-banner-images`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.BANNER_RELOAD,
            payload: true,
          });
          dispatch({ type: types.HIDE_LOADER });
          return Promise.resolve();
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        dispatch({ type: types.HIDE_LOADER });
        return Promise.reject(error);
      });
  };
};

export const blockUser = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/block-unblock-user`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: true,
          });
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response?.message,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updatePostLike = (payload) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/update-post-like/${payload}`)
      .then(function (response) {
        if (response?.data) {
          // dispatch({
          //   type: types.API_SUCCESS,
          //   payload: response?.data?.response?.message,
          // });

          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updateCommentLike = (commentId) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/update-comment-like/${commentId}`)
      .then(function (response) {
        if (response?.data) {
          // dispatch({
          //   type: types.API_SUCCESS,
          //   payload: response?.data?.response?.message,
          // });

          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addPostComment = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/add-post-comment`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addForum = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/add-forum`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.FORUM_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addForumComment = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/add-forum-comment`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.FORUM_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updateForumCommentLike = (payload) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/update-forum-comment-like/${payload}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.FORUM_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updateForumComment = (commentId, payload) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/update-forum-comment/${commentId}`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.FORUM_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updateForumLike = (payload) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/update-forum-like/${payload}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.FORUM_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updateForum = (payload) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/update-forum`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.FORUM_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getAllForums = (payload) => {
  return async (dispatch) => {
    axios
      .get(`${URL}/get-all-forum`)
      .then(function (response) {
        dispatch({
          type: types.GET_FORUMS,
          payload: response?.data,
        });

        if (response?.data) {
          dispatch({
            type: types.FORUM_RELOAD,
            payload: false,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const addResource = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/add-resource`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Resources Added Successfully",
          });
          dispatch({
            type: types.RESOURCE_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updateResource = (payload) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/update-resource`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.RESOURCE_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getResources = (
  page,
  isappendData,
  perPage = 5,
  isRecommended = "",
  searchTitleValue = ""
) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    let isRecommendedParam =
      isRecommended == 1 || isRecommended == 0
        ? `&isRecommended=${isRecommended}`
        : "";

    let searchTitle = searchTitleValue ? `&title=${searchTitleValue}` : "";
    axios
      .get(
        `${URL}/get-community-resources?page=${
          page || 1
        }&perPage=${perPage}${isRecommendedParam}${searchTitle}`
      )
      .then(function (response) {
        if (response?.data) {
          let responseData = response?.data?.response;
          if (isappendData) {
            let resourcesData =
              (store && store.getState()?.community?.resourses) || [];
            responseData?.data?.forEach((item) => {
              resourcesData.push(item);
            });
            responseData["data"] = resourcesData;
          }
          dispatch({
            type: types.GET_RESOURCE,
            payload: responseData,
          });

          // dispatch({type: types.SHOW_LOADER})
          dispatch({
            type: types.RESOURCE_RELOAD,
            payload: false,
          });
          dispatch({ type: types.HIDE_LOADER });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.HIDE_LOADER });
        console.error("..error", error);
        dispatch({
          type: types.RESOURCE_RELOAD,
          payload: false,
        });
      });
  };
};

export const getBookmarkedPosts = (page, isappendData, perPage = 10) => {
  return async (dispatch) => {
    axios
      .get(`${URL}/get-user-bookmark-post?page=${page}&perPage=${perPage}`)
      .then(function (response) {
        if (response?.data) {
          let responseData = response?.data;
          if (isappendData) {
            let bookmarkedPostsData =
              (store && store.getState()?.community?.bookmarkedPosts) || [];
            responseData?.data?.forEach((item) => {
              bookmarkedPostsData.push(item);
            });
            responseData["data"] = bookmarkedPostsData;
          }
          dispatch({
            type: types.BOOKMARKED_POST_RELOAD,
            payload: false,
          });
          dispatch({
            type: types.RESUABLE_COMMUNITIY_REDUCER,
            payload: responseData,
            label: "bookmarkedPosts",
          });
          dispatch({
            type: types.RESOURCE_RELOAD,
            payload: false,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        dispatch({
          type: types.RESOURCE_RELOAD,
          payload: false,
        });
        dispatch({
          type: types.BOOKMARKED_POST_RELOAD,
          payload: false,
        });
      });
  };
};

export const getPostComment = (payload) => {
  return async (dispatch) => {
    axios
      .get(`${URL}/get-post-comment`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.LIST_OF_COMMENTS_FOR_POST,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const updatePostComment = (commentdID, payload) => {
  return async (dispatch) => {
    // get-post-comment
    axios
      .put(`${URL}/update-post-comment/${commentdID}`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Reply Updated",
          });

          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};
export const deleteUserPost = (postId) => {
  return (dispatch) => {
    axios.delete(`${URL}/delete-post/${postId}`).then(function (response) {
      if (response?.data) {
        // dispatch({
        //   type: types.API_SUCCESS,
        //   payload: "Post Deleted",
        // });
        dispatch({
          type: types.COMMUNITY_RELOAD,
          payload: true,
        });
      }
    });
  };
};
export const deletePost = (payload) => {
  return async (dispatch) => {
    axios
      .delete(`${URL}/delete-post-comment/${payload}`)
      .then(function (response) {
        if (response?.data) {
          // dispatch({
          //   type: types.API_SUCCESS,
          //   payload: "Post or Comment Deleted",
          // });
          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const followUnfollowUser = (payload, otherUserSlugId) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/follow-unfollow-user`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: true,
          });

          if (otherUserSlugId) dispatch(getProfileData(otherUserSlugId));
          else dispatch(getProfileData());
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response?.message,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getFollowingUsers = (country, state, name, page, showLoader) => {
  const searchName = name?.length > 0 ? `&name=${name}` : "";
  const countryParam = country?.length > 0 ? `&countrySlug=${country}` : "";
  const stateParam = state?.length > 0 ? `&stateSlug=${state}` : "";

  return async (dispatch) => {
    let initialTime = performance.now();

    !showLoader && dispatch({ type: types.SHOW_LOADER });
    return await axios
      .get(
        `${URL}/get-following-users?isPaginated=${
          page ? `1&page=${page || 1}&` : "0&"
        }${countryParam}${stateParam}${searchName}`
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: false,
          });

          dispatch({
            type: types.FOLLOWING_USERS,
            payload: response?.data,
          });

          hideLoader(initialTime);

          if (page > 0) return Promise.resolve(response?.data?.meta?.last_page);
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });
        hideLoader(initialTime);
        console.error("..error", error);
      });
  };
};

export const getBlockedUSers = (country, state, name, page, showLoader) => {
  const searchName = name?.length > 0 ? `&name=${name}` : "";
  const countryParam = country?.length > 0 ? `&countrySlug=${country}` : "";
  const stateParam = state?.length > 0 ? `&stateSlug=${state}` : "";

  return async (dispatch) => {
    let initialTime = performance.now();

    !showLoader && dispatch({ type: types.SHOW_LOADER });
    return await axios
      .get(
        `${URL}/get-blocked-users?isPaginated=${
          page ? `1&page=${page || 1}&` : "0&"
        }${countryParam}${stateParam}${searchName}`
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: false,
          });

          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES,
            payload: response?.data,
          });

          hideLoader(initialTime);

          if (page > 0) return Promise.resolve(response?.data?.meta?.last_page);
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });
        hideLoader(initialTime);
        console.error("..error", error);
      });
  };
};
export const getGroupList = () => {
  return async (dispatch) => {
    axios
      .get(`${URL}/get-group-list?isPending=0`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.IS_GROUP_RELOAD,
            payload: false,
          });

          dispatch({
            type: types.LIST_OF_GROUPS,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.IS_GROUP_RELOAD,
          payload: false,
        });
        console.error("..error", error);
      });
  };
};

const formatGroupDataAsPostData = (responseData) => {
  let formattedData = {
    isBookmarked: responseData?.post?.isBookmarked,
    isLiked: responseData?.post?.isLiked,
    // isSystemAdmin:
    likesCount: responseData?.post?.likesCount,
    // position:
    postId: responseData?.post?.postId,
    type: responseData?.post?.type,
    updatedAt: responseData?.post?.updatedAt,
    comments: [],
    content: responseData?.post?.content,
  };
  return formattedData;
};
export const getResoursesOfGroup = (groupId) => {
  return async (dispatch) => {
    dispatch({
      type: types.COMMUNITY_RELOAD,
      payload: false,
    });
    axios
      .get(`${URL}/get-resources-for-group/${groupId}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.IS_GROUP_RELOAD,
            payload: false,
          });
          let responseData = response?.data?.response;
          dispatch({
            type: types.LIST_OF_POSTS_BY_GROUP,
            payload: responseData, //formatGroupDataAsPostData(responseData),
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.IS_GROUP_RELOAD,
          payload: false,
        });
        console.error("..error", error);
      });
  };
};

export const getPendingGroupList = () => {
  return async (dispatch) => {
    axios
      .get(`${URL}/get-group-list?isPending=1`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.IS_GROUP_RELOAD,
            payload: false,
          });

          dispatch({
            type: types.LIST_OF_INVITED_GROUPS,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.IS_GROUP_RELOAD,
          payload: false,
        });
        console.error("..error", error);
      });
  };
};

export const acceptRejectGroupIntivation = (payload) => {
  return async (dispatch) => {
    axios
      .put(`${URL}/accept-decline-group-invitation`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response,
          });

          dispatch({
            type: types.IS_GROUP_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};
export const addResourseToGroup = (payload) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/add-post-to-group`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Post added to group Successfully",
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const sharePostToUser = (email, postId) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    axios
      .post(`${URL}/share-post`, { mail: email, postId: postId })
      .then(function (response) {
        if (response?.data) {
          dispatch({ type: types.HIDE_LOADER });
          dispatch({
            type: types.API_SUCCESS,
            payload: "Post shared with user Successfully",
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.HIDE_LOADER });
        dispatch({
          type: types.API_ERROR,
          payload: "Failed to share post with email",
        });
        console.error("..error", error);
      });
  };
};

export const shareResourseToUser = (payload) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    axios
      .post(`${URL}/send-community-resource`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({ type: types.HIDE_LOADER });
          dispatch({
            type: types.API_SUCCESS,
            payload: "Resource shared with user Successfully",
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.HIDE_LOADER });
        dispatch({
          type: types.API_ERROR,
          payload: "Failed to share post with email",
        });
        console.error("..error", error);
      });
  };
};

export const shareResourseToGroup = (payload) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    axios
      .post(`${URL}/add-resource-to-group`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({ type: types.HIDE_LOADER });
          dispatch({
            type: types.API_SUCCESS,
            payload: "Resource shared to group Successfully",
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.HIDE_LOADER });
        dispatch({
          type: types.API_ERROR,
          payload: "Failed to share post with group",
        });
        console.error("..error", error);
      });
  };
};

export const groupInvitation = (payload, addColleague) => {
  return async (dispatch) => {
    axios
      .post(`${URL}/group-invitation`, payload)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: addColleague
              ? "Colleague added to group"
              : "Colleague removed from group",
          });
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: true,
        });
        console.error("..error", error);
      });
  };
};

export const getGroupInviteUserList = (searchValue, groupId, page = 1) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    let perPageParam = `&perPage=${25}`;
    let pageParam = `?page=${page || 1}`;
    dispatch({ type: types.SHOW_LOADER });
    return await axios
      .get(
        `${URL}/get-group-invite-user-list/${groupId}` +
          pageParam +
          `${searchValue ? `&name=${searchValue}` : ""}` +
          perPageParam
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: false,
          });
          // page
          if (false) {
            let newData = {};
            let professionalColleaguesData =
              (store && store.getState()?.community?.colleagues?.data) || [];
            newData["links"] = response?.data?.links;
            newData["meta"] = response?.data?.meta;
            response?.data?.data?.forEach((item) => {
              professionalColleaguesData.push(item);
            });
            newData["data"] = professionalColleaguesData;
            console.log("....newData", newData);
            dispatch({
              type: types.PROFESSIONAL_COLLEAGUES,
              payload: newData,
            });
          } else {
            console.log("......response?.data", response?.data);
            dispatch({
              type: types.GROUP_INVITE_USER_LIST,
              payload: response?.data,
            });
          }

          hideLoader(initialTime);
          if (page > 0) return Promise.resolve(response?.data?.meta?.last_page);
          // dispatch({ type: types.HIDE_LOADER });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });

        hideLoader(initialTime);
        console.error("..error", error);
      });
  };
};

export const getMyConnections = (searchValue, page = 1) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    let perPageParam = `&perPage=${25}`;
    let pageParam = `?page=${page || 1}`;
    dispatch({ type: types.SHOW_LOADER });
    return await axios
      .get(
        `${URL}/community/my-connections` +
          pageParam +
          `${
            searchValue ? `&searchBy=${"name"}&searchValue=${searchValue}` : ""
          }` +
          perPageParam
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: false,
          });
          // page
          if (false) {
            let newData = {};
            let professionalColleaguesData =
              (store && store.getState()?.community?.colleagues?.data) || [];
            newData["links"] = response?.data?.links;
            newData["meta"] = response?.data?.meta;
            response?.data?.data?.forEach((item) => {
              professionalColleaguesData.push(item);
            });
            newData["data"] = professionalColleaguesData;
            console.log("....newData", newData);
            dispatch({
              type: types.PROFESSIONAL_COLLEAGUES,
              payload: newData,
            });
          } else {
            console.log("......response?.data", response?.data);
            dispatch({
              type: types.MY_CONNECTIONS,
              payload: response?.data,
            });
          }

          hideLoader(initialTime);
          if (page > 0) return Promise.resolve(response?.data?.meta?.last_page);
          // dispatch({ type: types.HIDE_LOADER });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });

        hideLoader(initialTime);
        console.error("..error", error);
      });
  };
};

export const getAddMoreContacts = (searchValue, page = 1, perPage = 25) => {
  return async (dispatch) => {
    let initialTime = performance.now();

    dispatch({ type: types.SHOW_LOADER });
    let pageParam = `?page=${page || 1}`;
    let perPageParam = `&perPage=${perPage}`;
    return await axios
      .get(
        `${URL}/community/get-add-more-contacts` +
          pageParam +
          perPageParam +
          `${
            searchValue ? `&searchBy=${"name"}&searchValue=${searchValue}` : ""
          }`
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: false,
          });
          // page
          if (false) {
            let newData = {};
            let professionalColleaguesData =
              (store && store.getState()?.community?.colleagues?.data) || [];
            newData["links"] = response?.data?.links;
            newData["meta"] = response?.data?.meta;
            response?.data?.data?.forEach((item) => {
              professionalColleaguesData.push(item);
            });
            newData["data"] = professionalColleaguesData;
            console.log("....newData", newData);
            dispatch({
              type: types.PROFESSIONAL_COLLEAGUES,
              payload: newData,
            });
          } else {
            console.log("......response?.data", response?.data);
            dispatch({
              type: types.ADD_MORE_CONTACTS,
              payload: response?.data,
            });
          }

          hideLoader(initialTime);
          if (page > 0) return Promise.resolve(response?.data?.meta?.last_page);
          // dispatch({ type: types.HIDE_LOADER });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });

        hideLoader(initialTime);
        console.error("..error", error);
      });
  };
};

export const getMemberInvitations = (searchValue, page = 1) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let perPageParam = `&perPage=${25}`;
    let pageParam = `?page=${page || 1}`;
    return await axios
      .get(
        `${URL}/community/invitations` +
          pageParam +
          `${
            searchValue ? `&searchBy=${"name"}&searchValue=${searchValue}` : ""
          }` +
          perPageParam
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: false,
          });
          // page
          if (false) {
            let newData = {};
            let professionalColleaguesData =
              (store && store.getState()?.community?.colleagues?.data) || [];
            newData["links"] = response?.data?.links;
            newData["meta"] = response?.data?.meta;
            response?.data?.data?.forEach((item) => {
              professionalColleaguesData.push(item);
            });
            newData["data"] = professionalColleaguesData;
            console.log("....newData", newData);
            dispatch({
              type: types.PROFESSIONAL_COLLEAGUES,
              payload: newData,
            });
          } else {
            console.log("......response?.data", response?.data);
            dispatch({
              type: types.MEMBER_INVITATIONS,
              payload: response?.data,
            });
          }

          hideLoader(initialTime);
          if (page > 0) return Promise.resolve(response?.data?.meta?.last_page);
          // dispatch({ type: types.HIDE_LOADER });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });

        hideLoader(initialTime);
        console.error("..error", error);
      });
  };
};

export const getPendingInvitations = (searchValue, page = 1) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let perPageParam = `&perPage=${25}`;
    let pageParam = `?page=${page || 1}`;
    return await axios
      .get(
        `${URL}/community/pending-invitations` +
          pageParam +
          `${
            searchValue ? `&searchBy=${"name"}&searchValue=${searchValue}` : ""
          }` +
          perPageParam
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: false,
          });
          // page
          if (false) {
            let newData = {};
            let professionalColleaguesData =
              (store && store.getState()?.community?.colleagues?.data) || [];
            newData["links"] = response?.data?.links;
            newData["meta"] = response?.data?.meta;
            response?.data?.data?.forEach((item) => {
              professionalColleaguesData.push(item);
            });
            newData["data"] = professionalColleaguesData;
            console.log("....newData", newData);
            dispatch({
              type: types.PROFESSIONAL_COLLEAGUES,
              payload: newData,
            });
          } else {
            console.log("......response?.data", response?.data);
            dispatch({
              type: types.PENDING_INVITATIONS,
              payload: response?.data,
            });
          }

          hideLoader(initialTime);
          if (page > 0) return Promise.resolve(response?.data?.meta?.last_page);
          // dispatch({ type: types.HIDE_LOADER });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });

        hideLoader(initialTime);
        console.error("..error", error);
      });
  };
};

export const sendConnectionRequest = (userSlug) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    return await axios
      .post(`${URL}/community/connection-request`, { userSlug: userSlug })
      .then(function (response) {
        if (response?.data) {
          console.log("......response?.data", response?.data);
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response,
          });

          hideLoader(initialTime);
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });
        hideLoader(initialTime);

        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }

        return Promise.reject();

        console.error("..error", error);
      });
  };
};

export const acceptConnectionRequest = (userSlug) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    return await axios
      .put(`${URL}/community/accept-connection-request`, {
        userSlug: userSlug,
      })
      .then(function (response) {
        if (response?.data) {
          console.log("......response?.data", response?.data);
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response,
          });

          hideLoader(initialTime);
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });
        hideLoader(initialTime);

        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject();
        console.error("..error", error);
      });
  };
};

export const declineConnectionRequest = (userSlug) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    return await axios
      .put(`${URL}/community/decline-connection-request`, {
        userSlug: userSlug,
      })
      .then(function (response) {
        if (response?.data) {
          console.log("......response?.data", response?.data);
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response,
          });

          hideLoader(initialTime);
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });
        hideLoader(initialTime);

        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject();
        console.error("..error", error);
      });
  };
};

export const removeConnection = (userSlug) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    return await axios
      .delete(`${URL}/community/remove-connection/${userSlug}`)
      .then(function (response) {
        if (response?.data) {
          console.log("......response?.data", response?.data);
          dispatch({
            type: types.API_SUCCESS,
            payload: response?.data?.response,
          });

          hideLoader(initialTime);
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: true,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });
        hideLoader(initialTime);

        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        return Promise.reject();
        console.error("..error", error);
      });
  };
};

export const getProfessionlColleagues = (
  country,
  state,
  name,
  page,
  showLoader
) => {
  // console.log(country, state, name);
  const countryParam = country?.length > 0 ? `countrySlug=${country}` : "";
  const stateParam = state?.length > 0 ? `&stateSlug=${state}` : "";
  const searchName = name?.length > 0 ? `&name=${name}` : "";
  return async (dispatch) => {
    if (countryParam) {
      let initialTime = performance.now();

      !showLoader && dispatch({ type: types.SHOW_LOADER });
      return await axios
        .get(
          `${URL}/get-professional-colleagues?isPaginated=${
            page ? `1&page=${page || 1}&` : "0&"
          }${countryParam}${stateParam}${searchName}`
        )
        .then(function (response) {
          if (response?.data) {
            dispatch({
              type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
              payload: false,
            });
            // page
            if (false) {
              let newData = {};
              let professionalColleaguesData =
                (store && store.getState()?.community?.colleagues?.data) || [];
              newData["links"] = response?.data?.links;
              newData["meta"] = response?.data?.meta;
              response?.data?.data?.forEach((item) => {
                professionalColleaguesData.push(item);
              });
              newData["data"] = professionalColleaguesData;
              console.log("....newData", newData);
              dispatch({
                type: types.PROFESSIONAL_COLLEAGUES,
                payload: newData,
              });
            } else {
              console.log("......response?.data", response?.data);
              dispatch({
                type: types.PROFESSIONAL_COLLEAGUES,
                payload: response?.data,
              });
            }

            hideLoader(initialTime);
            if (page > 0)
              return Promise.resolve(response?.data?.meta?.last_page);
            // dispatch({ type: types.HIDE_LOADER });
          }
        })
        .catch(function (error) {
          dispatch({
            type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
            payload: false,
          });

          hideLoader(initialTime);
          console.error("..error", error);
        });
    }
  };
};

export const getFollowedUsers = (country, state, name, page, showLoader) => {
  const searchName = name?.length > 0 ? `&name=${name}` : "";
  const countryParam = country?.length > 0 ? `&countrySlug=${country}` : "";
  const stateParam = state?.length > 0 ? `&stateSlug=${state}` : "";
  return async (dispatch) => {
    // if (country || state || name) {

    let initialTime = performance.now();

    !showLoader && dispatch({ type: types.SHOW_LOADER });
    dispatch({
      type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
      payload: true,
    });
    return await axios
      .get(
        `${URL}/get-followed-users?isPaginated=${
          page ? `1&page=${page || 1}&` : "0&"
        }${countryParam}${stateParam}${searchName}`
      )
      .then(function (response) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });
        if (response?.data) {
          let responseData = response?.data;
          //    console.log("......responseData", responseData);
          dispatch({
            type: types.FOLLOWED_USERS,
            payload: responseData,
          });

          hideLoader(initialTime);

          if (page > 0) return Promise.resolve(response?.data?.meta?.last_page);
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.PROFESSIONAL_COLLEAGUES_RELOAD,
          payload: false,
        });

        hideLoader(initialTime);
        console.error("..error", error);
      });
    // }
  };
};

export const getBannerImages = (payload) => {
  return async (dispatch) => {
    axios
      .get(`${URL}/get-banner-images`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.BANNER_RELOAD,
            payload: false,
          });
          dispatch({
            type: types.GET_BANNERS,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.BANNER_RELOAD,
          payload: false,
        });
        console.error("..error", error);
      });
  };
};

export const getTrendingCourse = () => {
  return async (dispatch) => {
    axios
      .get(`${URL_COHORT}/get-trending-courses`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.GET_TRENDING_COURSES,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const gettAllPostsTillCurrentPage = (arrResponse, bookmarkPostId) => {
  return async (dispatch) => {
    dispatch({
      type: types.COMMUNITY_RELOAD,
      payload: false,
    });
    let newArr = {};
    console.log(".......arrResponse", arrResponse);
    if (!bookmarkPostId) {
      newArr = {
        data: [],
        links: {},
        meta: {},
      };
      let len = arrResponse?.length;
      if (len > 0) {
        newArr["meta"] = arrResponse[len - 1]["data"]["meta"];
        newArr["links"] = arrResponse[len - 1]["data"]["links"];
        for (let index = 0; index < len; index++) {
          arrResponse[index]?.data?.data.forEach((item) => {
            newArr["data"].push(item);
          });
        }
        dispatch({
          type: types.GET_POSTS,
          payload: newArr,
        });
      }
    }
  };
};
export const getPostById = (bookmarkPostId) => {
  return async (dispatch) => {
    await axios
      .get(`${URL}/get-posts?postId=${bookmarkPostId}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: false,
          });
          let responseData = response?.data;
          // console.log("......responseData", responseData);
          let newData = {
            data: responseData?.data,
            links: {},
            meta: {},
          };
          dispatch({
            type: types.GET_POSTS,
            payload: newData,
          });
        }
      })
      .catch((error) => {
        console.log("......error", error);
      });
  };
};
export const getAllPosts = (bookmarkPostId, pageNumber) => {
  return async (dispatch) => {
    dispatch({ type: types.SHOW_MODAL_LOADER });
    await axios
      .get(`${URL}/get-posts?page=${pageNumber || 1}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: false,
          });
          dispatch({ type: types.HIDE_MODAL_LOADER });
          if (!bookmarkPostId) {
            let newArr = (store && store.getState()?.community?.posts) || {};
            if (Array.isArray(newArr?.data)) {
              response?.data?.data?.forEach((item) => {
                newArr?.["data"]?.push(item);
              });
            } else {
              newArr["data"] = [];
              newArr["data"] = response?.data?.data;
            }
            response.data.data = newArr["data"] || [];
            dispatch({
              type: types.GET_POSTS,
              payload: response?.data,
            });
          }
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.COMMUNITY_RELOAD,
          payload: false,
        });
        dispatch({ type: types.HIDE_MODAL_LOADER });
        console.error("..error", error);
      });
  };
};

{
  /*
  export const getAllPosts = (bookmarkPostId, pageNumber) => {
  return async (dispatch) => {
    axios
      .get(`${URL}/get-posts?page=${pageNumber || 1}`)
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.COMMUNITY_RELOAD,
            payload: false,
          });
          // let bookmarkdItemPost = localStorage.getItem("bookmarkdItemPost");
          if (bookmarkPostId) {
            let responseData = response?.data;
            let filteredPostItem = {
              data:
                Array.isArray(responseData?.data) &&
                responseData?.data?.filter(
                  (postItem) => postItem?.postId === +bookmarkPostId
                ),
              links: responseData?.links,
              meta: responseData?.meta,
            };
            dispatch({
              type: types.GET_POSTS,
              payload:
                filteredPostItem?.data && filteredPostItem?.data?.length
                  ? filteredPostItem
                  : responseData,
            });
            // localStorage.removeItem("bookmarkdItemPost");
          } else {
            let newArr = (store && store.getState()?.community?.posts) || {};
            if (Array.isArray(newArr?.data)) {
              response?.data?.data?.forEach((item) => {
                newArr?.["data"]?.push(item);
              });
            } else {
              newArr["data"] = [];
              newArr["data"] = response?.data?.data;
            }
            response.data.data = newArr["data"] || [];
            dispatch({
              type: types.GET_POSTS,
              payload: response?.data,
            });
          }
        }
      })
      .catch(function (error) {
        dispatch({
          type: types.COMMUNITY_RELOAD,
          payload: false,
        });
        console.error("..error", error);
      });
  };
};
*/
}
export const CreateNewPoll = (updateMode, month, year) => {
  return async (dispatch) => {
    let data = store && store.getState()?.community?.pollCreation;
    await getFormattedData(data?.options, updateMode).then(
      async (formattedData) => {
        // let startD = new Date(data?.pollOpenDate).setHours(
        //   new Date().getHours(),
        //   new Date().getMinutes() + 5
        // );
        // console.log("....startD", startD, data?.pollOpenDate);
        let payloadData = {
          pollStartDate:
            data?.pollOpenDate < new Date() ? new Date() : data?.pollOpenDate,
          pollEndDate:
            data?.pollCloseDate < new Date() ? new Date() : data?.pollCloseDate,
          isSingleOptionSelection: data?.isOnlyOption,
          pollQuestion: data?.pollQuestion,
          options: formattedData,
          pollId: updateMode ? data?.pollId : null,
        };

        if (updateMode) {
          await axios
            .put(`${URL}/update-poll`, payloadData)
            .then((response) => {
              dispatch(getPollDataByMonthYear(month, year));
              return Promise.resolve();
            })
            .catch((error) => {
              if (error.response?.data?.errors) {
                let message = showFirstValidationErrorFromAPI(
                  error.response?.data?.errors
                );
                dispatch({
                  type: types.API_ERROR,
                  payload: message,
                });
              }
              return Promise.reject();
            });
        } else {
          await axios
            .post(`${URL}/create-poll`, payloadData)
            .then((response) => {
              dispatch(getRecentPollData());
              return Promise.resolve();
            })
            .catch((error) => {
              if (error.response?.data?.errors) {
                let message = showFirstValidationErrorFromAPI(
                  error.response?.data?.errors
                );
                dispatch({
                  type: types.API_ERROR,
                  payload: message,
                });
              }
              return Promise.reject();
            });
        }
      }
    );
  };
};

const getFormattedData = async (optionsData, updateMode) => {
  let formattedData = [];
  let payload = {};
  await optionsData.forEach((item, index) => {
    payload = {
      optionName: item?.name,
      isCorrectAnswer: index === 0 ? true : false,
    };
    formattedData.push(payload);
  });
  return Promise.resolve(formattedData);
  // return formattedData;
};

export const pollOptionChange = (
  event,
  radioGroup,
  optionIndex,
  arrData, //ViewMonthlyPoll
  parentLabel, //options
  innerData
) => {
  return (dispatch) => {
    if (radioGroup) {
      var changedData = [];
      let dataPayload = {};
      Array.isArray(arrData) &&
        arrData?.forEach((item, index) => {
          if (index === optionIndex) {
            dataPayload = {
              name: item?.name,
              isCorrectAnswer: item?.isCorrectAnswer,
              optionSelected: event.target.checked,
              optionTimes: event.target.checked
                ? (arrData[optionIndex]?.optionTimes || 0) + 1
                : (arrData[optionIndex]?.optionTimes || 1) - 1,
            };
          } else {
            dataPayload = {
              name: item?.name,
              isCorrectAnswer: item?.isCorrectAnswer,
              optionSelected: false,
              optionTimes: arrData[optionIndex]?.optionTimes || 0,
            };
          }
          changedData.push(dataPayload);
        });
      dispatch({
        type: types.CREATE_POLL_OPTIONS_HANDLER,
        parentLabel: parentLabel,
        label: innerData,
        payload: changedData,
      });
    } else {
      dispatch({
        type: types.CREATE_POLL_EDIT_OPTION,
        parentLabel: parentLabel,
        label: innerData,
        optionIndex: optionIndex,
        makeResuableAction: true,
        data: {
          name: arrData[optionIndex]?.name,
          isCorrectAnswer: arrData[optionIndex]?.isCorrectAnswer,
          optionSelected: event.target.checked || false,
          optionTimes: event.target.checked
            ? (arrData[optionIndex]?.optionTimes || 0) + 1
            : (arrData[optionIndex]?.optionTimes || 1) - 1,
        },
      });
    }
  };
};

export const getRecentPollData = () => {
  return async (dispatch) => {
    dispatch({ type: types.IS_RELOAD_TKL, payload: true });

    await axios
      .get(`${URL}/get-poll`)
      .then((response) => {
        if (response?.data) {
          let responseData = response?.data?.response;
          let payloadData = {
            pollOpenDate: new Date(responseData?.poll?.pollStartDate),
            pollCloseDate: new Date(responseData?.poll?.pollEndDate),
            answerSubmitted: responseData?.isResponseSubmitted,
            pollQuestion: responseData?.poll?.pollQuestion,
            isOnlyOption: responseData?.poll?.isSingleOptionSelection,
            totalResponses: responseData?.PollData?.totalResponses,
            pollId: responseData?.poll?.pollId,
            totalUsersVoted: responseData?.PollData?.totalUsersAttempted,

            options: pollOptionsFormattedData(
              responseData?.poll?.options,
              responseData?.PollData?.pollStats,
              responseData?.userResponse
            ),
          };
          dispatch({
            type: types.RESUABLE_COMMUNITIY_REDUCER,
            label: "ViewMonthlyPoll",
            payload: payloadData,
          });
          dispatch({ type: types.IS_RELOAD_TKL, payload: false });
        }
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }

        dispatch({ type: types.IS_RELOAD_TKL, payload: false });
      });
  };
};

const pollOptionsFormattedData = (optionsData, optionStats, userResponses) => {
  let formattedData = [];
  let payload = {};
  optionsData.forEach((item, index) => {
    payload = {
      name: item?.optionName,
      isCorrectAnswer: item?.isCorrectAnswer,
      optionSelected: userResponses?.includes(index) || false, //item?.isCorrectAnswer,
      optionTimes: optionStats[index + ""] || 0,
    };
    formattedData.push(payload);
  });
  return formattedData;
};

export const submitPoll = (pollData, isAllPolls, month, year) => {
  return async (dispatch) => {
    let indexData = [];
    pollData?.options.forEach((item, index) => {
      if (item?.optionSelected) {
        indexData.push(+index);
      }
    });
    let payloadData = {
      pollId: pollData?.pollId,
      optionsSelected: indexData,
    };
    await axios
      .post(`${URL}/attempt-poll`, payloadData)
      .then((response) => {
        if (response?.data) {
          if (isAllPolls) {
            dispatch(getPollDataByMonthYear(month, year));
          } else {
            dispatch(getRecentPollData());
          }
        }
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
      });
  };
};

export const deletePoll = (pollId, month, year) => {
  return async (dispatch) => {
    await axios
      .delete(`${URL}/delete-poll/${pollId}`)
      .then((response) => {
        dispatch(getPollDataByMonthYear(month, year));
        return Promise.resolve();
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
      });
  };
};

export const getPollDataByMonthYear = (month, year) => {
  return async (dispatch) => {
    dispatch({ type: types.IS_RELOAD_TKL, payload: true });
    await axios
      .get(`${URL}/get-all-monthly-polls?month=${month}&year=${year}`)
      .then((response) => {
        if (response?.data) {
          //response?.data?.response;
          let responseData = response?.data?.response;
          // [
          //   {
          //     poll: {
          //       pollId: 305565,
          //       pollQuestion: "Modi reiciendis quidem autem.",
          //       isSingleOptionSelection: true,
          //       options: [
          //         {
          //           optionName: "Gerard Light",
          //           isCorrectAnswer: true,
          //         },
          //         {
          //           optionName: "Pfeffer Points",
          //           isCorrectAnswer: false,
          //         },
          //         {
          //           optionName: "Satterfield Ridge",
          //           isCorrectAnswer: false,
          //         },
          //       ],
          //       pollStartDate: "09/29/2021 00:00:00",
          //       pollEndDate: "09/30/2021 00:00:00",
          //     },
          //     PollData: {
          //       totalUsersAttempted: 0,
          //       pollStats: [],
          //       totalResponses: 0,
          //     },
          //     isResponseSubmitted: false,
          //   },
          //   {
          //     poll: {
          //       pollId: 943404,
          //       pollQuestion: "Et occaecati veritatis.",
          //       isSingleOptionSelection: true,
          //       options: [
          //         {
          //           optionName: "Edgardo Ford",
          //           isCorrectAnswer: false,
          //         },
          //         {
          //           optionName: "Robel Summit",
          //           isCorrectAnswer: false,
          //         },
          //         {
          //           optionName: "Viola Cape",
          //           isCorrectAnswer: true,
          //         },
          //       ],
          //       pollStartDate: "09/29/2021 00:00:00",
          //       pollEndDate: "09/30/2021 00:00:00",
          //     },
          //     PollData: {
          //       totalUsersAttempted: 0,
          //       pollStats: [],
          //       totalResponses: 0,
          //     },
          //     isResponseSubmitted: false,
          //   },
          //   {
          //     poll: {
          //       pollId: 159425,
          //       pollQuestion:
          //         "Dignissimos sunt animi aliquid culpa veniam natus.",
          //       isSingleOptionSelection: true,
          //       options: [
          //         {
          //           optionName: "Earline Knoll",
          //           isCorrectAnswer: false,
          //         },
          //         {
          //           optionName: "Rhoda Station",
          //           isCorrectAnswer: false,
          //         },
          //         {
          //           optionName: "Yundt Pike",
          //           isCorrectAnswer: true,
          //         },
          //       ],
          //       pollStartDate: "09/29/2021 00:00:00",
          //       pollEndDate: "09/30/2021 00:00:00",
          //     },
          //     PollData: {
          //       totalUsersAttempted: 4,
          //       pollStats: {
          //         2: 3,
          //         0: 4,
          //         1: 1,
          //       },
          //       totalResponses: 8,
          //     },
          //     isResponseSubmitted: true,
          //     userResponse: [0, 2],
          //   },
          // ];
          let pollData = [];
          let payloadData = {};
          responseData?.forEach((item, index) => {
            payloadData = {
              pollOpenDate: new Date(item?.poll?.pollStartDate),
              pollCloseDate: new Date(item?.poll?.pollEndDate),
              answerSubmitted: item?.isResponseSubmitted,
              pollQuestion: item?.poll?.pollQuestion,
              isOnlyOption: item?.poll?.isSingleOptionSelection,
              totalResponses: item?.PollData?.totalResponses,
              pollId: item?.poll?.pollId,
              totalUsersVoted: item?.PollData?.totalUsersAttempted,
              options: pollOptionsFormattedData(
                item?.poll?.options,
                item?.PollData?.pollStats,
                item?.userResponse
              ),
            };
            pollData.push(payloadData);
          });
          dispatch({
            type: types.RESUABLE_COMMUNITIY_REDUCER,
            label: "allPollData",
            payload: pollData,
          });
          dispatch({ type: types.IS_RELOAD_TKL, payload: false });
        }
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }

        dispatch({ type: types.IS_RELOAD_TKL, payload: false });
      });
  };
};
