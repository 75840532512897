import React, { useContext, useState, useEffect, useRef } from "react";
import ComponentNotificationBulb from "../../shared-components/notification-bulb/index";
import ComponentAvatar from "../../shared-components/avatar/index";
import ContextOffcanvas from "../../contexts/offcanvas-context";
import "./_index.scss";
import {
  BannerImageHandlingForAllDevices,
  isFreeUser,
} from "../../components/utility/ReUsableFunctions";
import InfiniteScroll from "react-infinite-scroll-component";
import ContextUser from "./../../contexts/user-context";

import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Container,
  Dropdown,
  FormControl,
  ListGroup,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import Notification from "../../components/Notification/Notification";
import {
  fetchMoreNotifications,
  getNotifications,
  markReadNotification,
} from "../../actions/Notification/NotificationActions";
import axios from "axios";
import { URL } from "../../api/tklEnvironments";
import ComponentAvtar from "../../components/CourseCreation/modals/ComponentAvatar";
import history from "../../Routes/history";
function useOutsideAlerter(ref, dropDownShow, setDropDownShow) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // let tempDropShow = dropDownShow;
        // setDropDownShow(!tempDropShow);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
const ComponentSiteheader = React.memo(function ComponentSiteheader(props) {
  const dispatch = useDispatch();

  const wrapperRef = useRef(null);

  const { notification, notification_reload } = useSelector(
    (state) => state.notifications
  );
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [currentNotification, setCurrentNotification] = useState([]);
  const [dropDownShow, setDropDownShow] = useState(false);
  useOutsideAlerter(wrapperRef, dropDownShow, setDropDownShow);
  const [pageCount, setPageCount] = useState(1);
  const [hasMoreNotfications, setHasMoreNotfications] = useState(true);
  const [notificationCount, setNotificationCount] = useState(true);
  const userData = useSelector((state) => state.users.userData);

  const toggle = () => setPopoverOpen(!popoverOpen);
  const toggleOffcanvas = useContext(ContextOffcanvas);
  const toggleSiteNav = props.toggleSiteNav;

  // const { userProfileData } = useContext(ContextUser);

  const openSiteNav = () => {
    if (toggleSiteNav) {
      toggleSiteNav(true);
    }
  };
  const openUserNav = () => {
    if (toggleOffcanvas) {
      toggleOffcanvas(true, "OffcanvasUserAccount", "");
    }
  };

  React.useMemo(() => {
    if (localStorage.getItem("token") && !userData?.firstName) {
      setInterval(() => {
        // dispatch(getNotifications());
      }, 20000);
      dispatch(getNotifications());
    }
  }, []);

  // React.useMemo(() => {
  //   //console.log("notification", notification);
  // }, [notification]);

  React.useEffect(() => {
    setCurrentNotification(notification?.data);
  }, [JSON.stringify(notification)]);

  React.useEffect(() => {
    dispatch(getNotifications());
  }, [notification_reload]);

  React.useEffect(() => {
    //console.log("currentNotification ", currentNotification);
  }, [currentNotification]);

  const fetchMoreNotfctn = () => {
    let tempPageCount = pageCount;
    axios
      .get(`${URL}/notification?page=${tempPageCount + 1}`)
      .then(function (response) {
        if (response.data) {
          const oldArray = notification?.data;
          const newArray = response?.data?.data;
          const combinedArray = [...oldArray, ...newArray];

          setCurrentNotification(combinedArray);

          setTimeout(() => {
            if (response.data?.current_page === response.data?.last_page) {
              setHasMoreNotfications(false);
            }
          }, 1000);
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };

  const getCurrentNotificationLength = () => {
    let notificationLength = currentNotification?.filter(
      (notObject) => notObject?.read_at === null
    )?.length;
    //console.log("notificationLength", notificationLength);
    return notificationLength;
  };

  const handleOnClickNotification = (item) => {
    const prevValue = dropDownShow;
    console.log("...item", item);

    if (item?.data?.cohortDiscussionData) {
      let {
        cohortDisplayId,
        commentIdentifier,
        fragmentSlug,
        parentCommentIdentifier,
      } = item?.data?.cohortDiscussionData;
      sessionStorage.setItem("cohortDisplayId", cohortDisplayId);
      sessionStorage.setItem(
        "commentIdentifier",
        parentCommentIdentifier || commentIdentifier
      );
      sessionStorage.setItem("cohortFragmentSlug", fragmentSlug);
      history?.push(`/discussionForums/${cohortDisplayId}`);
      setTimeout(() => {
        history?.go(0);
      }, 0);
    }
    if (item?.data?.feedbackData) {
      let {
        cohortId,
        isIntroductionStep,
        cohortModuleIndex,
        cohortSectionIndex,
        stepIndex,
        activitySlug,
        stepSlug,
      } = item?.data?.feedbackData;
      if (isIntroductionStep) {
        window.open(
          `/cohortIntroduction/cohortActivity/${cohortId}/introduction/1/${stepIndex}/${stepSlug}/cohortUserLearner/true`
        );
      } else {
        window.open(
          `/cohortActivity/${cohortId}/${cohortModuleIndex}/${cohortSectionIndex}/${stepIndex}/${activitySlug}/cohortUserLearner/true`
        );
      }
    }

    if (item?.data?.groupData?.redirectionLink) {
      window.open(item?.data?.groupData?.redirectionLink);
    }
  };

  return (
    <>
      <div className="siteheader-comp notifications position-fixed w-100 top-0 left-0">
        <header
          className="d-flex flex-row justify-content-between"
          style={{ maxHeight: "3em" }}
        >
          <div className="d-flex flex-row justify-content-center">
            <a
              href={isFreeUser(userData?.roles) ? "/community" : "/"}
              className="site-logo"
            >
              <svg
                version="1.2"
                baseProfile="tiny"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 202 202"
              >
                <path
                  fill="#1DBAAD"
                  d="M92,92H22C9.9,92,0,82.1,0,70L0,0l24,0c37.4,0,68,30.6,68,68V92z"
                />
                <path
                  fill="#FCB127"
                  d="M180,92h-70V68c0-37.6,30.4-68,68-68h24v70C202,82.2,192.2,92,180,92z"
                />
                <path
                  fill="#DF1C45"
                  d="M47,202h-2c-24.8,0-45-20.3-45-45v-2c0-24.9,20.1-45,45-45h47v47C92,181.9,71.9,202,47,202z"
                />
                <path
                  fill="#3F749C"
                  d="M157,202h-2c-24.8,0-45-20.3-45-45v-47h47c24.9,0,45,20.1,45,45v2C202,181.9,181.9,202,157,202z"
                />
              </svg>
            </a>
            <button
              type="button"
              className="sitenav-toggle border-0 p-0"
              onClick={openSiteNav}
            >
              <i className="material-icons">menu</i>
            </button>
          </div>
          <div className="d-flex flex-row justify-content-start">
            <Dropdown className="header-notification-dropdown">
              <Dropdown.Toggle
                as={"span"}
                style={{ maxHeight: "10px", maxWidth: "10px" }}
              >
                <div
                  className="cursor-pointer"
                  style={{ height: "25px", width: "25px", marginTop: "10px" }}
                >
                  <ComponentNotificationBulb
                    onClick={() => {
                      const prevValue = dropDownShow;
                      setDropDownShow(!prevValue);
                    }}
                    count={getCurrentNotificationLength()}
                  />
                </div>
              </Dropdown.Toggle>

              <Card>
                {/* <div
                  className="arrow-top"
                  style={{
                    display: dropDownShow ? "block" : "none",
                    height: "0px",
                  }}
                ></div> */}
                <Dropdown.Menu
                  className="card-box-notify"
                  style={{ heigth: "60vh", overflow: "auto" }}
                >
                  <Container className="d-flex justify-content-center p-1 pb-2 font-weight-500 border-bottom">
                    Notifications
                  </Container>
                  {currentNotification?.length > 0 ? (
                    <InfiniteScroll
                      dataLength={currentNotification?.length}
                      next={fetchMoreNotfctn}
                      hasMore={hasMoreNotfications}
                      loader={<h4>Loading...</h4>}
                      height={400}
                      endMessage={
                        <p
                          className="font-weight-600 p-2"
                          style={{ textAlign: "center" }}
                        >
                          You're All Caught Up
                        </p>
                      }
                    >
                      {currentNotification?.map((notify, index) => {
                        if (notify?.data.message) {
                          return (
                            <Dropdown.Item
                              key={Math.random()}
                              eventKey={index}
                              onClick={() => handleOnClickNotification(notify)}
                            >
                              <Notification
                                key={Math.random()}
                                nData={notify}
                                setDropDownShow={setDropDownShow}
                                dropDownShow={dropDownShow}
                              />
                            </Dropdown.Item>
                          );
                        }
                      })}
                    </InfiniteScroll>
                  ) : (
                    <Dropdown.Item eventKey={0}>
                      No New Notifications
                    </Dropdown.Item>
                  )}
                  <p
                    className="d-flex justify-content-center font-semi-bold-TKL"
                    style={{ marginTop: "0.8em" }}
                  >
                    All read notifications will be cleared after 3 days
                  </p>
                </Dropdown.Menu>
              </Card>
            </Dropdown>
            <button
              type="button"
              onClick={openUserNav}
              className={`user-toggle border-0 ${
                !userData?.avatarImg?.slug && "p-2"
              }`}
              style={{
                marginRight: userData?.avatarImg?.slug && "0.5em",
                marginTop: userData?.avatarImg?.slug && "-0.8em",
              }}
            >
              <div>
                {userData?.avatarImg?.slug ? (
                  <ComponentAvtar
                    avtrImg={userData?.avatarImg?.url}
                    avtrImgSlug={userData?.avatarImg?.slug}
                    type="avatar"
                    show={false}
                    isCreateCourse={true}
                    isRound={true}
                  />
                ) : (
                  <ComponentAvatar
                    type={"user"}
                    data={{
                      img: BannerImageHandlingForAllDevices(
                        userData?.avatarImg?.url,
                        "user",
                        userData?.avatarImg?.slug
                      ),
                    }}
                  />
                )}
              </div>
            </button>
          </div>
        </header>
      </div>
      <div className="siteheader-buffer"></div>
    </>
  );
});

export default ComponentSiteheader;
