import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import {
  hideLoader,
  avtrBannerImagesDataPrepare,
  sortingByProperties,
  showFirstValidationErrorFromAPI,
  streamMediaURL,
} from "../../components/utility/ReUsableFunctions";
import _ from "lodash";
import printJS from "print-js";
export const getPaidUserCourses = () => {
  return async (dispatch) => {
    await axios
      .get(`${URL_COHORT}/get-user-progress-details`)
      .then((response) => {
        let responseData = response.data?.response;
        let newList = [];
        responseData?.forEach((item) => {
          let avtrImg = {};
          let media_URL = streamMediaURL(item?.cohortCourseName?.media?.slug); // `${URL}/stream-media/${item?.cohortCourseName?.media?.slug}`;
          item["media"] =
            avtrBannerImagesDataPrepare(
              avtrImg,
              item?.cohortCourseName?.media,
              media_URL
            ) || {};
          item["createdAt"] = new Date(item?.createdAt);
          // item["daysLeft"] =
          //   item?.scores?.userProgress >= 100 ? 0 : item?.daysLeft;
          // if (item?.daysLeft >= 0) {
          newList.push(item);
          // }
        });
        // let arrayToSort = [...responseData];
        // arrayToSort.sort(sortingByProperties(false, "cohortCourseName?.name"));

        // eslint-disable-next-line no-undef
        // responseData = _.sortBy(
        //   responseData,
        //   (item) => item.cohortCourseName.name.toLowerCase(),
        //   ["asc"]
        // );
        newList = _.sortBy(newList, (item) => item?.daysLeft, ["asc"]);
        dispatch({
          type: types.MY_COURSES_FACILITATOR,
          payload: newList,
        });
      })
      .catch((error) => {});
  };
};

export const getCompletedCourseData = (programType, sortBy) => {
  return async (dispatch) => {
    dispatch({
      type: types.RESUABLE_GET_ACTION,
      payload: true,
      label: "isLoading",
    });
    try {
      const response = await axios.get(
        `${URL_COHORT}/get-course-completed-users?sortBy=${sortBy}&programType=${programType}&isPaginated=0`
      );
      if (response.data) {
        let responseData = response.data.response;
        let dataPayload = [];
        responseData.forEach((item) => {
          if (item?.resultStatus === "PASSED") {
            item["program"] = {
              slug: item?.programName,
            };
            item["media"] = streamMediaURL(item?.avatarSlug);
            dataPayload.push(item);
          }
        });
        dispatch({
          type: types.RESUABLE_GET_ACTION,
          label: "completedCourses",
          payload: dataPayload,
        });
        dispatch({
          type: types.RESUABLE_GET_ACTION,
          payload: false,
          label: "isLoading",
        });
      }
    } catch (error) {
      console.log("...error", error);
      dispatch({
        type: types.RESUABLE_GET_ACTION,
        payload: false,
        label: "isLoading",
      });
    }
  };
 };
 export const getCourseData = (programType, sortBy) => {
  return async (dispatch) => {
    dispatch({
      type: types.USEABLE_GET_ACTION,
      payload: true,
      label: "isLoading",
    });
    try {
      const response = await axios.get(
        `${URL_COHORT}/get-course-completed-users?sortBy=${sortBy}&programType=${programType}&isPaginated=0`
      );
      if (response.data) {
        let responseData = response.data.response;
        let dataPayload = [];
        responseData.forEach((item) => {
          if (item?.resultStatus === "PASSED") {
            item["program"] = {
              slug: item?.programName,
            };
            item["media"] = streamMediaURL(item?.avatarSlug);
            dataPayload.push(item);
          }
        });
        dispatch({
          type: types.USEABLE_GET_ACTION,
          label: "coursesCompleted",
          payload: dataPayload,
        });
        dispatch({
          type: types.USEABLE_GET_ACTION,
          payload: false,
          label: "isLoading",
        });
      }
    } catch (error) {
      console.log("...error", error);
      dispatch({
        type: types.USEABLE_GET_ACTION,
        payload: false,
        label: "isLoading",
      });
    }
  };
 };

export const downloadCertificate = (
  userSlug,
  cohortDisplayId,
  isPrimary,
  isNotTranscripts,
  isPDF
) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let URL_Item = `${
      isNotTranscripts
        ? `download-course-participation-guide?userSlug=${userSlug}&cohortDisplayId=${cohortDisplayId}`
        : `download-certificate?userSlug=${userSlug}&cohortDisplayId=${cohortDisplayId}&isPrimary=${isPrimary}`
    }`;
    await axios
      .get(`${URL_COHORT}/${URL_Item}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          if (isPDF) {
            printJS(url);
          } else {
            const link = document.createElement("a");
            link.href = url;
            let fileName = isNotTranscripts
              ? "TKLParticipantGuide.pdf"
              : "Course Completion Certificate.pdf";
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
          }
        }

        hideLoader(initialTime);
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }

        hideLoader(initialTime);
      });
  };
};

export const printTranscripts = () => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let URL_Item = `transcript/download`;
    return await axios
      .get(`${URL}/${URL_Item}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        if (response.data) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          printJS(url);
        }
        hideLoader(initialTime);
      })
      .catch((error) => {
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        hideLoader(initialTime);
      });
  };
};
