import { types } from "./types";
import {
  DiscountCode,
  Bundles,
  BundleCodes,
} from "../components/Models/CodeTypes";
import { cloneDeep as cloneDeep } from "lodash";

const initialState = {
  acount: "",
  accountDetails: {},
  accountManagers: [],
  listOfAccounts: [],
  roles: [],
  schoolsByAccountID: [],
  districtAdmins: [],
  accountType: "gplc",
  apiSuccessMessage: "",
  apiErrorMessage: "",
  isLoading: false,
  isReload: false,
  members: [],
  unassigedMembers: [],
  cohortMembers: [],
  selectedCohortGroup: {},

  allLocations: [],
  allCourses: [],
  discountCodeData: new DiscountCode(),
  bundleData: new Bundles(),
  bundleCodeData: new BundleCodes(),
  getDiscountData: {},
  last_page_discount_code: 0,
  getBundleData: {},
  getBundleCodeData: {},
  getUserManagementData: {},
  getTKLMembersData: {},
  getFinancialTable: {},
  getBundleFinancials: {},

  contentDeveloperSummaryReportTotals: {},
  getCourseRegistrationsForContentDeveloper: {},

  getCourseFacilitatorSummaryReports: {},
  getCourseFacilitatorIndividualCourses: {},
  getPathRightUploadStatus: {},
  getPathRightData: {},
  modalLoader: false,
};

const ReduxInputFormHandler = (formData, label, payload, isArray, index) => {
  let dataTobeReplaced = {};
  if (!isArray) {
    Object.keys(formData).forEach((formItem) => {
      if (formItem === label) {
        dataTobeReplaced[formItem] = payload;
      }
    });
  } else {
    Object.keys(formData).forEach((formItem) => {
      if (formItem === label) {
        dataTobeReplaced[label] = formData[label];
        dataTobeReplaced[formItem][index] = payload;
      }
    });
  }
  return dataTobeReplaced[label];
};
export const tkladmin = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_ACCOUNT:
      return {
        ...state,
        acount: action.payload,
      };
    case types.SHOW_MODAL_LOADER:
      return {
        ...state,
        modalLoader: true,
      };
    case types.HIDE_MODAL_LOADER:
      return {
        ...state,
        modalLoader: false,
      };

    case types.GET_ACCCOUNT_MANAGERS:
      return {
        ...state,
        accountManagers: action.payload,
      };

    case types.ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.payload,
      };

    case types.IS_LOADING_TKLADMIN:
      return {
        ...state,
        isLoading: action.payload,
      };

    case types.SELECTED_COHORT_GROUP:
      return {
        ...state,
        selectedCohortGroup: action.payload,
      };

    case types.LIST_OF_COHORT_MEMBERS:
      return {
        ...state,
        cohortMembers: action.payload,
      };

    case types.LIST_OF_DISTRICT_ADMINS:
      return {
        ...state,
        districtAdmins: action.payload,
      };

    case types.IS_RELOAD_TKL:
      return {
        ...state,
        isReload: action.payload,
      };

    case types.LIST_OF_MEMBERS:
      return {
        ...state,
        members: action.payload,
      };

    case types.GET_SCHOOLS_BY_ID:
      return {
        ...state,
        schoolsByAccountID: action.payload,
      };

    case types.GET_UNASSIGNED_MEMBERS:
      return {
        ...state,
        unassigedMembers: action.payload,
      };

    case types.GET_MICRO_COUSRSE_DETAILS:
      return {
        ...state,
        microCourseDetails: action.payload,
      };  

    case types.LIST_OF_ACCOUNTS:
      return {
        ...state,
        listOfAccounts: action.payload,
      };

    case types.GET_PATH_RIGHT_DATA:
      return {
        ...state,
        getPathRightData: action.payload,
      };

    case types.GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case types.GET_ACCOUNT:
      return {
        ...state,
        accountDetails: action.payload,
      };

    case types.GET_ALL_LOCATIONS_DISCUOUNTCODE:
      return {
        ...state,
        allLocations: action.payload?.data,
      };

    case types.GET_ALL_COURSES_DISCUOUNTCODE:
      return {
        ...state,
        allCourses: action.payload,
      };
    case types.INITIAL_DATA_DISCOUNT_CODE:
      return {
        ...state,
        discountCodeData: new DiscountCode(),
      };

    case types.INITIAL_DATA_BUNDLE:
      return {
        ...state,
        bundleData: new Bundles(),
      };

    case types.INITIAL_DATA_BUNDLE_CODES:
      return {
        ...state,
        bundleCodeData: new BundleCodes(),
      };

    case types.ADD_LOCATION_TO_DISCUOUNTCODE:
      let newLocationData = [...state?.discountCodeData?.[action.label]];
      newLocationData.push("");
      return {
        ...state,
        discountCodeData: {
          ...state.discountCodeData,
          [action.label]: newLocationData,
        },
      };
    case types.REMOVE_LOCATION_FROM_DISCOUNT_CODE:
      return {
        ...state,
        discountCodeData: {
          ...state.discountCodeData,
          [action.label]: [
            ...state.discountCodeData[action.label].slice(0, action.payload),
            ...state.discountCodeData[action.label].slice(action.payload + 1),
          ],
        },
      };

    case types.CODE_INPUT_SELECTION:
      return {
        ...state,
        discountCodeData: {
          ...state.discountCodeData,
          [action.label]: ReduxInputFormHandler(
            state.discountCodeData,
            action.label,
            action.payload,
            action.isArray,
            action.index
          ),
        },
      };

    case types.ADD_LOCATION_TO_BUNDLE:
      let newLocationDatabundle = [...state?.bundleData?.[action.label]];
      newLocationDatabundle.push("");
      return {
        ...state,
        [action.data]: {
          ...state?.[action.data],
          [action.label]: newLocationDatabundle,
        },
      };
    case types.REMOVE_LOCATION_FROM_BUNDLE:
      return {
        ...state,
        [action.data]: {
          ...state?.[action.data],
          [action.label]: [
            ...state?.[action.data][action.label].slice(0, action.payload),
            ...state?.[action.data][action.label].slice(action.payload + 1),
          ],
        },
      };

    case types.CODE_INPUT_SELECTION_BUNDLE:
      return {
        ...state,
        [action.data]: {
          ...state?.[action.data],
          [action.label]: ReduxInputFormHandler(
            state?.[action.data],
            action.label,
            action.payload,
            action.isArray,
            action.index
          ),
        },
      };

    case types.GET_DISCOUNT_CODE_DATA:
      return {
        ...state,
        getDiscountData: action.payload,
        last_page_discount_code: action.payload?.last_page,
      };
    case types.GET_BUNDLES_DATA:
      return {
        ...state,
        [action.label]: action.payload,
      };
    case types.GET_CONTENT_DEVELOPER_SUMMARY_REPORT_TOTALS:
      return {
        ...state,
        [action.label]: action.payload,
      };
    case types.GET_COURSE_REGISTRATIONS_FOR_CONTENT_DEVELOPER:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.COMPANY_REGISTRATIONS_SUMMARY_REPORTS:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.COMPANY_REGISTRATIONS_PARENT_COURSE:
      return {
        ...state,
        [action.label]: action.payload,
      };
    case types.COMPANY_REGISTRATIONS_INDIVIDUAL_COURSES:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.KID_VISION_SUMMARY_REPORTS:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.KID_VISION_PARENT_COURSE:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.KID_VISION_INDIVIDUAL_COURSES:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.MICRO_COURSE_SUMMARY_REPORTS:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.MICRO_COURSE_PARENT_COURSE:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.MICRO_COURSE_INDIVIDUAL_COURSES:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.COURSE_FACILITATOR_SUMMARY_REPORTS:
      return {
        ...state,
        [action.label]: action.payload,
      };
    case types.COURSE_FACILITATOR_INDIVIDUAL_COURSES:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.CF_PAYMENTS:
      return {
        ...state,
        [action.label]: action.payload,
      };

    case types.GET_PATH_RIGHT_UPLOAD_STATUS:
      return {
        ...state,
        getPathRightUploadStatus: action.payload,
      };

    default:
      return state;
  }
};
