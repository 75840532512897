import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Modal } from "react-bootstrap";
import { createFolder } from "actions/FileStorageActions/FileStorageAction";

function SelectContentDeveloper({ handleClose, handleSubmit, show }) {
  const dispatch = useDispatch();
  const [authorsList, setAuthorsList] = useState([]);
  const { allAuthors } = useSelector((state) => state.coursehome);
  const [contentDevSlug, setContentDevSlug] = useState({ name: "", slug: "" });

  useEffect(() => {
    setAuthorsList(allAuthors);
    setContentDevSlug(allAuthors[0]);
  }, [allAuthors]);

  const createUserFolder = () => {
    dispatch(
      createFolder({
        name: contentDevSlug?.slug,
        userSlug: contentDevSlug?.slug,
      })
    );
  };

  return (
    <Modal className="modal-styles" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{"Select the Content Developer"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          style={{ width: "300px" }}
          className="content_dev_registration_report_control"
        >
          <label style={{ width: "100%", marginTop: "25px" }}>
            Content Developer Name:{" "}
          </label>
          <Form.Control
            as="select"
            className="form-control rounded-pill icon-hide"
            onChange={(e) => {
              let selectedAuthorList = authorsList.filter((value) => {
                if (value.name === e.target.value) return value;
              });
              setContentDevSlug(selectedAuthorList[0]);
            }}
            value={contentDevSlug?.name}
          >
            {authorsList?.map((item, index) => {
              return <option value={item.name}>{item.name}</option>;
            })}
          </Form.Control>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-outline btn-secondary rounded-pill "
          title={"Cancel"}
          onClick={handleClose}
        >
          {"Cancel"}
        </Button>

        <Button
          className=" btn-outline rounded-pill btn"
          title={"Create"}
          onClick={() => {
            createUserFolder();
            handleSubmit();
          }}
        >
          {"Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SelectContentDeveloper;
