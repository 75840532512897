import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import axios from "axios";
import thunk from "redux-thunk";
import { ACCESS_TOKEN } from "../constants.js/authToken";
import combineReducers from "../reducers/index";
import { store } from "../App";
import ContextUser from "./../contexts/user-context";

import history from "../Routes/history";

axios.interceptors.request.use(
  (config) => {
    // const token = ACCESS_TOKEN;
    const token =
      (store && store.getState().auth.token) || localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
axios.interceptors.response.use(
  (response) => {
    sessionStorage.removeItem("showUserErrorHandling");
    return response;
  },
  (error) => {
    if (
      error?.response?.status === 403 &&
      !sessionStorage.getItem("showUserErrorHandling")
    ) {
      sessionStorage.setItem("showUserErrorHandling", "true");

      let routePath = history?.location?.pathname;
      if (
        !(
          // routePath.includes("/default") ||
          (
            routePath.includes("/see_all") ||
            routePath.includes("/register") ||
            routePath.includes("/forgotPassword") ||
            routePath.includes("/changePassword") ||
            routePath.includes("/changeTempPassword") ||
            routePath.includes("/external-catalog") ||
            routePath.includes("/external-bundlecodes") ||
            routePath.includes("/toCartWebsite") ||
            routePath.includes("/loginPage")
          )
        )
      ) {
        if (!localStorage.getItem("token")) {
          history.push("/loginPage");
          history.go(0);
        }
      }
    } else if (!sessionStorage.getItem("showUserErrorHandling")) {
      // alert("For testing purpose, check network tab and report to dev");
    }
    setTimeout(() => {
      sessionStorage.removeItem("showUserErrorHandling");
    }, 1200);
    return Promise.reject(error);
  }
);
export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: combineReducers,
    middleware: [thunk],
    preloadedState,
  });
  return store;
}
