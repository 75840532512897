import React from "react";
import ComponentCtaBtn from "./../../../shared-components/cta-btn/index";
import { Tab, Row, Col, Nav } from "react-bootstrap";
import ComponentCustomScrollbar from "./../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "./../../../shared-components/avatar/index";
import moment from "moment";

const tilejson = require("./../../../data/dashboard/tile-f.json");
const arr1 = (tilejson || []).filter((item) => item.type === "monthly");
const arr2 = (tilejson || []).filter((item) => item.type === "annually");
const arr3 = (tilejson || []).filter((item) => item.type === "reviewlist");

export default function TileF() {
  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "monthly") {
      thisArr = (arr1 || [])[0].data || [];
      char = "monthly";
    }
    if (str === "annually") {
      thisArr = (arr2 || [])[0].data || [];
      char = "annually";
    }
    if (str === "reviewlist") {
      thisArr = (arr2 || [])[0].data || [];
      char = "reviewlist";
    }

    return thisArr.map((item, index) => (
      <React.Fragment key={`t_f_${char}_${index}`}>
        <div className="dash-row-type-2 container rounded mb-2">
          <div className="row">
            <div className="t-row-name col-12 col-md-8 col-xl-7 position-relative py-2 pl-8 pl-md-10 pr-2 d-flex flex-column justify-content-center">
              <div className="t-avtr-wrap position-absolute">
                <a href={item.link}>
                  <ComponentAvatar
                    type="user"
                    data={{
                      firstname: item.firstname,
                      lastname: item.lastname,
                      img: item.img || {},
                    }}
                  />
                </a>
              </div>
              <a href={item.link} className="t-row-name-link">
                {item.name}
              </a>
              <small className="text-muted">
                {moment(item?.createddate).format("MM-DD-YYYY")}
              </small>
            </div>
            <div className="col-6 col-md-2 p-2 d-flex flex-column justify-content-start align-items-center">
              <small>
                <strong>Rank: {item.rank}</strong>
              </small>
            </div>
            <div className="col-6 col-md-2 col-xl-3 p-2 d-flex flex-column justify-content-start align-items-center">
              <small>
                <strong className="text-warning">{item.points} Points</strong>
              </small>
            </div>
          </div>
        </div>
        <hr />
      </React.Fragment>
    ));
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 text-uppercase m-0">
            <strong>User Leaderboard</strong>
          </h2>
          <div className="d-flex d-md-none align-items-center justify-content-start">
            <div
              className="d-inline-block mr-2"
              style={{ width: "2rem", height: "2rem" }}
            >
              <ComponentAvatar
                type="user"
                data={{
                  firstname: "firstname",
                  lastname: "lastname",
                }}
              />
            </div>
            <small>
              <strong>Rank: 5</strong> &middot;{" "}
              <strong className="text-warning">110 Points</strong>
            </small>
          </div>
        </div>
        <div className="dash-t-h-right py-2 px-3 flex-md-grow-1 d-none d-md-flex justify-content-md-end align-items-md-center">
          <div
            className="d-inline-block mr-2"
            style={{ width: "2rem", height: "2rem" }}
          >
            <ComponentAvatar
              type="user"
              data={{
                firstname: "firstname",
                lastname: "lastname",
              }}
            />
          </div>
          <small>
            <strong>Rank: 5</strong> &middot;{" "}
            <strong className="text-warning">110 Points</strong>
          </small>
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container id="mapl_tabs" defaultActiveKey="monthly">
          <Row>
            <Col xs="12" className="mb-3">
              <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="monthly"
                  >
                    <strong>Monthly</strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="annually"
                  >
                    <strong>Annually</strong>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="reviewlist"
                  >
                    <strong>Review List</strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="monthly">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("monthly")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="annually">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("annually")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="reviewlist">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("reviewlist")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </article>
  );
}
