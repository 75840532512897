import React, { useEffect, useRef, useMemo, useState, useContext } from "react";
import ComponentCtaBtn from "../../../shared-components/cta-btn/index";
import {
  Tabs,
  Tab,
  Row,
  Col,
  Nav,
  Container,
  Button,
  Modal,
} from "react-bootstrap";
import ComponentCustomScrollbar from "../../../shared-components/custom-scrollbar/index";
import ComponentAvatar from "../../../shared-components/avatar/index";
import { connect, useSelector, useDispatch } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { getFacilitatorCourses } from "../../../actions/DashboardActions/InstructionalCoachActions";
import {
  BannerImageHandlingForAllDevices,
  userRoles,
} from "../../utility/ReUsableFunctions";
import {
  getallDraftData,
  getCoursesForAuthor,
} from "../../../actions/CreateCourse/CreatecourseActions";
import { store } from "../../../App";
import { types } from "../../../reducers/types";
import ComponentNotificationBulb from "../../../shared-components/notification-bulb";
import { deleteCourse } from "../../../actions/CoursePath/coursePathAction";
import ContextUser from "../../../contexts/user-context";
import moment from "moment";

function CourseDevelopmentStatus(props) {
  const { history } = useContext(ContextUser);

  const [show, setShow] = useState(false);
  const [currentCourse, setCurrentCourse] = useState("");
  const dispatch = useDispatch();
  const { userData } = props;
  const facilitatorCourses = useSelector(
    (state) => state.dashboards.facilitatorCourses
  );
  //console.log("props : ", props);

  const draftData = useSelector((state) => state.coursehome.draftData);

  useEffect(() => {
    if (props.role === "instructionalCoach") {
      dispatch(getFacilitatorCourses());
    } else if (props.role === "contentDeveloper") {
      dispatch(getallDraftData());
    } else if (props.role === "contentAuthor") {
      dispatch(getCoursesForAuthor());
    }
  }, []);

  const handleShow = (slug) => {
    setShow(true);
    setCurrentCourse(slug);
  };

  const handleClose = () => setShow(false);

  const compArr = useRef([]);
  const gplcArr = useRef([]);
  const microArr = useRef([]);
  const kidVisionArr = useRef([]);

  const navigateToCoursePath = (event, slug) => {
    sessionStorage.setItem("courseSlug", slug);
    props.history.push(`/${slug}/CoursePath/`);
  };

  const handleDelete = () => {
    dispatch(deleteCourse(currentCourse));
    store.dispatch({
      type: types.DELETE_COURSE,
      payload: "",
    });
    store.dispatch({
      type: types.SHOW_LOADER,
      payload: "",
    });
    handleClose();
    setTimeout(() => {
      dispatch(getallDraftData());
      store.dispatch({
        type: types.HIDE_LOADER,
        payload: "",
      });
    }, 1000);
  };

  useMemo(() => {
    // console.log("......", props.role, draftData);
    // console.log(draftData);
    if (Array.isArray(draftData)) {
      if (props.role === "contentAuthor") {
        compArr.current = (draftData || []).filter(
          (item) =>
            // item?.programsAvailable?.some(
            //   (e) =>
            //     e?.slug === "comprehensive-course-program" &&
            [
              "DRAFT",
              "UNPUBLISHED",
              "UNDER REVIEW",
              "FEEDBACK SUBMITTED",
              "APPROVED",
              "DENIED",
            ]?.find((e2) => e2 === item.status)
          // )
        );
        gplcArr.current = (draftData || []).filter((item) =>
          item.programsAvailable.some(
            (e) =>
              e.slug === "guided-plc-program" &&
              [
                "DRAFT",
                "UNPUBLISHED",
                "UNDER REVIEW",
                "APPROVED",
                "DENIED",
                "FEEDBACK SUBMITTED",
              ].find((e) => e === item.status)
          )
        );
        microArr.current = (draftData || []).filter((item) =>
          item.programsAvailable.some(
            (e) =>
              e.slug === "micro-course-program" &&
              [
                "DRAFT",
                "UNPUBLISHED",
                "UNDER REVIEW",
                "APPROVED",
                "DENIED",
                "FEEDBACK SUBMITTED",
              ].find((e) => e === item.status)
          )
        );
        kidVisionArr.current = (draftData || []).filter((item) =>
          item.programsAvailable.some(
            (e) =>
              e.slug === "kidvision" &&
              [
                "DRAFT",
                "UNPUBLISHED",
                "UNDER REVIEW",
                "APPROVED",
                "DENIED",
                "FEEDBACK SUBMITTED",
              ].find((e) => e === item.status)
          )
        );
      } else if (props.role === "contentDeveloper") {
        compArr.current = (draftData || []).filter((item) =>
          item.programsAvailable.some(
            (e) =>
              e.slug === "comprehensive-course-program" &&
              [
                "DRAFT",
                "UNPUBLISHED",
                "UNDER REVIEW",
                "APPROVED",
                "FEEDBACK SUBMITTED",
              ].find((e) => e === item.status)
          )
        );
        gplcArr.current = (draftData || []).filter(
          (item) =>
            item.programsAvailable.some(
              (e) => e.slug === "guided-plc-program"
            ) &&
            [
              "DRAFT",
              "UNPUBLISHED",
              "UNDER REVIEW",
              "APPROVED",
              "FEEDBACK SUBMITTED",
            ].find((e) => e === item.status)
        );
        microArr.current = (draftData || []).filter(
          (item) =>
            item.programsAvailable.some(
              (e) => e.slug === "micro-course-program"
            ) &&
            [
              "DRAFT",
              "UNPUBLISHED",
              "UNDER REVIEW",
              "APPROVED",
              "FEEDBACK SUBMITTED",
            ].find((e) => e === item.status)
        );
        kidVisionArr.current = (draftData || []).filter(
          (item) =>
            item.programsAvailable.some(
              (e) => e.slug === "kidvision"
            ) &&
            [
              "DRAFT",
              "UNPUBLISHED",
              "UNDER REVIEW",
              "APPROVED",
              "FEEDBACK SUBMITTED",
            ].find((e) => e === item.status)
        );
      }
    }
  }, [draftData]);

  function getStatus(item) {
    if (item.status === "DRAFT" || item.status === "UNPUBLISHED") {
      return "DRAFT";
    } else if (item.status === "UNDER REVIEW") {
      return props.role === "contentDeveloper"
        ? "REVIEW AND FEEDBACK"
        : "UNDER REVIEW";
    } else if (item.status === "FEEDBACK SUBMITTED") {
      return props.role === "contentDeveloper"
        ? "FEEDBACK SUBMITTED"
        : "FEEDBACK RECEIVED";
    } else {
      return item.status;
    }
  }

  const getProgramType = (char) => {
    if (char === "comp") return "Comprehensive Course Program";
    else if (char === "gplc") return "Guided PLC Program";
    else if (char === "micro") return "Micro Course Program";
    else if (char === "kidVision") return "KidVision Course Program";
    else return "";
  };

  const checkifCanEdit = (item) => {
    let isEditable = false;

    let authorsSlug = item?.authors.map((author) => author?.slug);

    if (
      authorsSlug?.find((aSlug) => aSlug === userData?.userSlug) ||
      userData?.userSlug === item?.createdBy?.slug
    ) {
      isEditable = true;
    }
    // console.log("checkifCanEditcheckifCanEdit", item, authorsSlug, userData);
    return isEditable;
  };

  const renderData = (str) => {
    let thisArr = [];
    let char = "";
    if (str === "comprehensive") {
      thisArr = compArr?.current || []; // //(compArr || [])[0]?.data || [];
      char = "comp";
    }
    if (str === "gplc") {
      thisArr = gplcArr?.current || []; //(gplcArr || [])[0].data || [];
      char = "gplc";
    }
    if (str === "micro") {
      thisArr = microArr?.current || []; //(microArr || [])[0].data || [];
      char = "micro";
    }
    if (str === "kidVision") {
      thisArr = kidVisionArr?.current || []; //(kidVisionArr || [])[0].data || [];
      char = "kidVision";
    }

    return thisArr?.map((item, index) => (
      <React.Fragment key={`t_m_${char}_${index}`}>
        <div className="dash-row-type-2 container rounded mb-2">
          <div className="row">
            <Container fluid>
              <Row>
                <Col xs={2} sm={1}>
                  <div className="t-avtr-wrap position-absolute">
                    <a href="#">
                      <ComponentAvatar
                        type="course"
                        data={{
                          img: BannerImageHandlingForAllDevices(
                            item?.avatarImage?.url,
                            "course",
                            item?.avatarImage?.slug
                          ),
                        }}
                      />
                    </a>
                  </div>
                </Col>
                <Col xs={10} sm={11}>
                  <Container fluid>
                    <Row>
                      <Col className="ml-2">
                        <div>
                          <Link
                            to={`/${item?.slug}/coursePath`}
                            onClick={() => {
                              sessionStorage.setItem("isReadOnly", "true");
                              sessionStorage.setItem("courseSlug", item?.slug);
                              sessionStorage.setItem("mode", "edit");
                            }}
                            className="t-row-name-link"
                          >
                            {item?.courseName ? item?.courseName : item?.name}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {/* xs={4} sm={4} md={3} lg={5} xl={5} xxl={5} */}
                      <Col xs={5} sm={5} md={5} lg={6} xl={6} xxl={6}>
                        <div className="ml-2">
                          <div>
                            {/* {console.log(
                              "....item?.approvedAt",
                              item?.updatedAt,
                              moment(item?.updatedAt).format("MM-DD-YYYY")
                            )} */}
                            <small
                              className="text-muted font-weight-600"
                              style={{ marginTop: "5px" }}
                            >
                              Created :{" "}
                              {item?.updatedAt
                                ? moment(item?.updatedAt).format("MM-DD-YYYY")
                                : moment(new Date()).format("MM-DD-YYYY")}
                              {/* {dateformat(new Date(), "P")} */}
                            </small>
                          </div>
                          {getStatus(item) === "FEEDBACK SUBMITTED" ? (
                            <div>
                              <div style={{ marginTop: "5px" }}>
                                <small className="font-weight-600">
                                  Status : FEEDBACK SENT
                                </small>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div style={{ marginTop: "5px" }}>
                                <small className="font-weight-600">
                                  Status : {getStatus(item)}
                                </small>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                      {/* <Col xs={1} sm={1} md={2} lg={1} xl={1} xxl={2}></Col> */}
                      <Col
                        xs={7}
                        sm={7}
                        md={7}
                        lg={6}
                        xl={6}
                        xxl={6}
                        style={{ margin: "auto" }}
                      >
                        {item.status === "UNDER REVIEW" && (
                          <>
                            {props.role === "contentDeveloper" ||
                            userData?.roles.includes(
                              userRoles?.SYSTEM_ADMIN
                            ) ? (
                              <Container fluid>
                                <Row
                                  className="p-1"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  <Button
                                    size="sm"
                                    disabled={item.status === "APPROVED"}
                                    className="btn btn-outline btn-green rounded-pill btn-sm btn-width-10"
                                    onClick={(event) => {
                                      navigateToCoursePath(event, item.slug);
                                      sessionStorage.setItem("mode", "review");
                                    }}
                                  >
                                    Provide Feedback
                                  </Button>
                                </Row>
                              </Container>
                            ) : (
                              <Container fluid>
                                <Row
                                  className="p-1"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  <Button
                                    disabled={true}
                                    className="btn btn-outline btn-rounded btn-purple-light m-2 btn-sm rounded-pill btn-width"
                                  >
                                    Under Review
                                  </Button>
                                  <span className="font-weight-500"></span>
                                </Row>
                              </Container>
                            )}
                          </>
                        )}
                        {item.status === "APPROVED" && (
                          <>
                            {props.role === "contentDeveloper" ? (
                              <Container fluid>
                                <Row
                                  className="p-1"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  {/* <Button
                          className="btn btn-outline btn-rounded btn-primary btn-width btn-sm rounded-pill"
                          disabled={item?.status !== "APPROVED"}
                          onClick={(event) => {
                            props.history.push(
                              `\/${item?.slug}\/${sessionStorage.getItem(
                                "programSelected"
                              )}/cohorts`
                            );
                          }}
                        >
                          Publish
                        </Button> */}
                                </Row>
                              </Container>
                            ) : (
                              <Container fluid>
                                <Row
                                  className="p-1"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  <Button
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "courseSlug",
                                        item.slug
                                      );
                                      sessionStorage.setItem("mode", "edit");
                                      props.history.push(
                                        `/${item.slug}/CoursePath/`
                                      );
                                    }}
                                    className="btn btn-outline btn-rounded btn-primary m-2 btn-sm rounded-pill btn-width"
                                  >
                                    View
                                  </Button>
                                </Row>
                              </Container>
                            )}
                          </>
                        )}
                        {item.status === "FEEDBACK SUBMITTED" && (
                          <Container fluid>
                            <Row className="p-1" style={{ flexWrap: "nowrap" }}>
                              {props.role === "contentAuthor" ||
                              userData?.roles?.includes(
                                userRoles?.CONTENT_ADMIN
                              ) ? (
                                <Button
                                  onClick={() => {
                                    sessionStorage.setItem(
                                      "courseSlug",
                                      item.slug
                                    );
                                    sessionStorage.setItem("mode", "edit");
                                    props.history.push(
                                      `/${item.slug}/CoursePath/`
                                    );
                                  }}
                                  className="btn btn-outline btn-rounded btn-primary btn-width btn-sm rounded-pill "
                                >
                                  {"Edit"}
                                </Button>
                              ) : (
                                <p
                                  style={{ fontWeight: 600, fontSize: "18px" }}
                                >
                                  {checkifCanEdit(item) ||
                                  userData?.roles?.includes(
                                    userRoles?.SYSTEM_ADMIN
                                  ) ? (
                                    <Button
                                      onClick={() => {
                                        sessionStorage.setItem(
                                          "courseSlug",
                                          item.slug
                                        );
                                        sessionStorage.setItem("mode", "edit");
                                        props.history.push(
                                          `/${item.slug}/CoursePath/`
                                        );
                                      }}
                                      className="btn btn-outline btn-rounded btn-primary btn-width btn-sm rounded-pill "
                                    >
                                      {"Edit"}
                                    </Button>
                                  ) : (
                                    "Feedback Sent"
                                  )}
                                </p>
                              )}
                            </Row>
                          </Container>
                        )}{" "}
                        {[
                          "DRAFT",
                          "UNPUBLISHED",
                          "DENIED",
                          "APPROVED",
                        ].includes(item.status) && (
                          <Container fluid>
                            {props.role === "contentDeveloper" &&
                              (item.status === "APPROVED" ? (
                                <Row
                                  className="p-1"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  <Button
                                    onClick={(event) => {
                                      let programType = getProgramType(char);
                                      props.history.push(
                                        `\/${item?.slug}\/${programType}/cohorts`
                                      );
                                    }}
                                    className="btn btn-purple rounded-pill btn-width btn-sm"
                                  >
                                    Publish
                                  </Button>
                                </Row>
                              ) : (
                                <>
                                  {checkifCanEdit(item) ? (
                                    <>
                                      <Row
                                        className="p-1"
                                        style={{ flexWrap: "nowrap" }}
                                      >
                                        <Button
                                          onClick={() => {
                                            sessionStorage.setItem(
                                              "courseSlug",
                                              item.slug
                                            );
                                            sessionStorage.setItem(
                                              "mode",
                                              "edit"
                                            );
                                            // props.history.push(`/${item.slug}/CoursePath/`);
                                            props.history.push(
                                              `/create-course`
                                            );
                                          }}
                                          size="sm"
                                          // className="btn btn-outline btn-primary btn-width btn-sm rounded-pill m-2"
                                          className="btn btn-outline btn-rounded btn-primary btn-width btn-sm rounded-pill"
                                        >
                                          {"Edit" + "   "}
                                        </Button>

                                        <Button
                                          onClick={() => handleShow(item.slug)}
                                          // className="btn btn-outline btn-danger rounded-pill btn-width btn-sm m-2"
                                          className="btn btn-outline btn-rounded btn-danger btn-width btn-sm rounded-pill ml-2"
                                        >
                                          Delete
                                        </Button>
                                      </Row>
                                    </>
                                  ) : (
                                    <>
                                      <Row
                                        className="p-1"
                                        style={{ flexWrap: "nowrap" }}
                                      >
                                        <Button
                                          onClick={() => {
                                            sessionStorage.setItem(
                                              "courseSlug",
                                              item.slug
                                            );
                                            sessionStorage.setItem(
                                              "mode",
                                              "learn"
                                            );
                                            props.history.push(
                                              `/create-course`
                                            );
                                          }}
                                          // mb-2
                                          className="btn btn-purple btn-rounded btn-width btn-sm rounded-pill m-2"
                                        >
                                          {"Review" + "   "}
                                        </Button>
                                        <Button
                                          onClick={() => handleShow(item.slug)}
                                          className="btn btn-outline btn-danger rounded-pill btn-width btn-sm m-2"
                                        >
                                          Delete
                                        </Button>
                                      </Row>
                                    </>
                                  )}
                                </>
                              ))}

                            {props.role === "contentAuthor" &&
                              item.status !== "APPROVED" && (
                                <Row
                                  className="p-1"
                                  style={{ flexWrap: "nowrap" }}
                                >
                                  <Button
                                    onClick={() => {
                                      sessionStorage.setItem(
                                        "courseSlug",
                                        item.slug
                                      );
                                      sessionStorage.setItem("mode", "edit");
                                      // props.history.push(`/${item.slug}/CoursePath/`);
                                      props.history.push(
                                        `/${item.slug}/create-course`
                                      );
                                    }}
                                    // className="btn btn-outline btn-rounded btn-primary btn-width btn-sm rounded-pill"
                                    className="btn btn-outline btn-rounded btn-primary btn-width btn-sm rounded-pill"

                                    // style={{ marginBottom: "0.5em" }}
                                  >
                                    {"Edit" + "   "}
                                  </Button>

                                  <Button
                                    onClick={() => handleShow(item.slug)}
                                    // className="btn btn-outline btn-danger rounded-pill btn-width btn-sm"
                                    className="btn btn-outline btn-rounded btn-danger btn-width btn-sm rounded-pill ml-2"
                                  >
                                    Delete
                                  </Button>
                                </Row>
                              )}
                          </Container>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <hr />
      </React.Fragment>
    ));
  };
  return (
    <article className="dash-t-comp d-flex flex-column">
      <div className="dash-t-head border-bottom d-md-flex flex-md-row position-relative">
        <div className="dash-t-h-left flex-md-grow-1 p-3 pl-md-6">
          <div className="dash-t-move position-absolute top-0 left-0 px-3 py-2 d-none d-md-flex justify-content-center align-items-center">
            <i className="material-icons">open_with</i>
          </div>
          <h2 className="h4 m-0 text-uppercase">
            <strong>{props?.title}</strong>
          </h2>
        </div>
        <div className="py-2 px-3 flex-md-grow-1 d-none d-md-flex justify-content-md-end align-items-md-center">
          <ComponentCtaBtn
            onClick={() => {
              sessionStorage.removeItem("courseSlug");
              history.push("create-course");
              history.go(0);
            }}
            data={{
              tag: "button",
              type: "button",
              ctastyle: "primary",
              size: "sm",
              text: "Create Course",
              title: "Create Course",
              ctaicon: "add",
            }}
          />
        </div>
      </div>
      <div className="dash-t-body p-2 p-md-3 flex-grow-1 d-flex flex-column">
        <Tab.Container
          id="mapl_tabs"
          defaultActiveKey={
            props.role === "districtFacilitator" ||
            props.role === "schoolFacilitator"
              ? "gplc"
              : "comprehensive"
          }
        >
          <Row>
            <Col xs="12" className="mb-3">
              {/* <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="comprehensive"
                  >
                    <strong>Comprehensive</strong>
                  </Nav.Link>
                </Nav.Item>
              </Nav> */}
              <Nav variant="pills" className="custom-tabs-link flex-row">
                <Nav.Item>
                  {!(
                    props.role === "districtFacilitator" ||
                    props.role === "schoolFacilitator"
                  ) && (
                    <Nav.Link
                      className="p-2 line-height-1 mr-2 border"
                      eventKey="comprehensive"
                    >
                      <strong>Comprehensive</strong>

                      <div
                        className="ml-3 md-5 d-inline-block"
                        style={{ width: "1.2rem", height: "1.2rem" }}
                      >
                        <ComponentNotificationBulb
                          count={compArr.current?.length}
                        />
                      </div>
                    </Nav.Link>
                  )}
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="gplc"
                  >
                    <strong>GPLC</strong>
                    <div
                      className="ml-3 md-5 d-inline-block"
                      style={{ width: "1.2rem", height: "1.2rem" }}
                    >
                      <ComponentNotificationBulb
                        count={gplcArr.current?.length}
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="micro"
                  >
                    <strong>Micro Courses</strong>
                    <div
                      className="ml-3 md-5 d-inline-block"
                      style={{ width: "1.2rem", height: "1.2rem" }}
                    >
                      <ComponentNotificationBulb
                        count={microArr.current?.length}
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="p-2 line-height-1 mr-2 border"
                    eventKey="kidVision"
                  >
                    <strong>KidVision</strong>
                    <div
                      className="ml-3 md-5 d-inline-block"
                      style={{ width: "1.2rem", height: "1.2rem" }}
                    >
                      <ComponentNotificationBulb
                        count={kidVisionArr.current?.length}
                      />
                    </div>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
          </Row>
          <Row className="flex-grow-1">
            <Col xs="12" className="d-flex">
              <Tab.Content className="custom-tabs-pane">
                <Tab.Pane eventKey="comprehensive">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("comprehensive")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="gplc">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("gplc")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="micro">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("micro")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
                <Tab.Pane eventKey="kidVision">
                  <ComponentCustomScrollbar
                    className="scroll-comp"
                    style={{ height: "100%" }}
                  >
                    {renderData("kidVision")}
                    <br />
                  </ComponentCustomScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>Do you want to delete the Course ?</Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-outline  rounded-pill btn-sm ml-2"
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button
            className="btn btn-outline btn-danger rounded-pill btn-sm ml-2"
            variant="primary"
            onClick={handleDelete}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </article>
  );
}

export default withRouter(CourseDevelopmentStatus);
