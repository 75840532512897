const trianglify = window.trianglify || require('trianglify');
const GeoPattern = require('geopattern');

const _HexToRgbA = (hex, alpha) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};
const _TrimFn = (str) => {
  return str && (typeof str === 'string' || typeof str === 'number') ? str.toString().replace(/^\s+|\s+$/gm, '') : '';
};
const _HasClass = (el, classStr) => {
  if (el && classStr && typeof className === 'string') {
    if (el.classList) {
      return el.classList.contains(classStr);
    } else {
      return !!el.className.match(new RegExp('(\\s|^)' + classStr + '(\\s|$)'));
    }
  } else {
    return false;
  }
};
const _AddClass = (el, classStr) => {
  if (el && classStr && typeof classStr === 'string') {
    if (_TrimFn(classStr).length > 0) {
      classStr = classStr.split(' ');
      for (let name in classStr) {
        if (el.classList) {
          el.classList.add(classStr[name]);
        } else if (!_HasClass(el, classStr[name])) {
          el.className += ' ' + classStr[name];
        }
      }
    }
  }
};
const _RemoveClass = (el, classStr) => {
  if (el && classStr && typeof classStr === 'string') {
    if (_TrimFn(classStr).length > 0) {
      classStr = classStr.split(' ');
      for (let name in classStr) {
        if (el.classList) {
          el.classList.remove(classStr[name]);
        } else if (_HasClass(el, classStr)) {
          const reg = new RegExp('(\\s|^)' + classStr[name] + '(\\s|$)');
          el.className = el.className.replace(reg, ' ');
        }
      }
    }
  }
};
const _GetElemDimensions = (elem) => {
  try {
    let docElem, win, box = { top: 0, left: 0 }, doc = elem && elem.ownerDocument;

    let isWindow = (obj) => {
      return obj != null && obj === obj.window;
    };

    let getWindow = (elem) => {
      return isWindow(elem) ? elem : elem.nodeType === 9 && elem.defaultView;
    };

    docElem = doc.documentElement;
    if (typeof elem.getBoundingClientRect !== typeof undefined) {
      box = elem.getBoundingClientRect();
    }
    win = getWindow(doc);
    return {
      top: box.top + win.pageYOffset - docElem.clientTop,
      left: box.left + win.pageXOffset - docElem.clientLeft,
      width: box.width ? box.width : 0,
      height: box.height ? box.height : 0
    };
  } catch (e) {
    return {
      top: 0,
      left: 0,
      width: 0,
      height: 0
    };
  }
};

const _PageScrollTo = (pos) => {
  return new Promise(resolve => {
    const scrollListener = e => {
      if ('undefined' === typeof e) {
        return;
      }
      const target = e.currentTarget;
      if (target.scrollTop === pos) {
        target.removeEventListener('scroll', scrollListener);
        resolve();
      }
    };
    window.addEventListener('scroll', scrollListener);
    window.scrollTo({
      top: pos,
      left: 0,
      behavior: 'smooth',
    });
  });
};

const _RandomNum = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
const _RandomColor = () => {
  return (Math.floor(Math.random() * 16777215).toString(16) + '000000').substr(0, 6);
};

const _TrianglifyColorFunctions = () => {
  return [
    // {
    //   type: 'custom',
    //   def: ((radius) => ({ centroid, xScale }) => {
    //     const distanceFromCenter = Math.sqrt(
    //       Math.pow(200 - centroid.x, 2) + Math.pow(150 - centroid.y, 2)
    //     )
    //     return (xScale(distanceFromCenter / radius))
    //   })(Math.sqrt(Math.pow(_RandomNum(100, 999) / 2, 2) + Math.pow(_RandomNum(100, 999) / 2, 2)))
    // },
    {
      type: 'sparkle',
      def: trianglify.colorFunctions.sparkle(Math.random())
    },
    {
      type: 'interpolateLinear',
      def: trianglify.colorFunctions.interpolateLinear(Math.random())
    },
    {
      type: 'shadows',
      def: trianglify.colorFunctions.shadows()
    },
    {
      type: 'null',
      def: null
    }
  ]
};

const _TrianglifyImg = (options) => {
  try {
    const num = _RandomNum(1, 2);
    const len = _RandomNum(3, 6);
    let arr = [];
    const cfnArr = _TrianglifyColorFunctions();
    const colorFn = cfnArr[Math.floor(Math.random() * cfnArr.length)];

    for (let i = 0; i < len; i++) {
      arr.push('#' + _RandomColor());
    }

    let toptions = {
      cellSize: 64,
      width: options.height || 240,
      height: options.width || 240,
      xColors: num < 2 ? 'random' : arr,
      yColors: 'match',
      variance: (Math.random()).toFixed(1)
    };

    if (colorFn.type !== 'null') {
      toptions.colorFunction = colorFn.def
    }

    const pattern = trianglify(toptions);
    return `data:image/svg+xml;base64,${window.btoa((new XMLSerializer()).serializeToString(pattern.toSVG()))}`;
  } catch (e) {
    return '#';
  }
};

const _GeopatternImg = (str) => {
  const options = {
    color: '#' + _RandomColor(),
    baseColor: '#' + _RandomColor()
  };
  return (GeoPattern.generate(str || '', options)).toDataUri();
};

export {
  _AddClass,
  _GeopatternImg,
  _GetElemDimensions,
  _HasClass,
  _HexToRgbA,
  _PageScrollTo,
  _RandomColor,
  _RandomNum,
  _RemoveClass,
  _TrianglifyImg,
  _TrimFn,
};