import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addMarketLocation,
  updateMarketLocation,
  getSecondaryCertificateList,
} from "../../../actions/MasterDataActions/marketLocation";

function MarketLocationForm(props) {
  const { updateData, isCreate, handleClose } = props;

  const { secondaryCertificates, secondaryCertificatesMap } = useSelector(
    (state) => state.masterdata
  );
  const dispatch = useDispatch();

  const [secondaryCertificate, setSecondaryCertificate] = useState({
    name: "",
    slug: "",
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (isCreate) {
      if (secondaryCertificate?.name) {
        //  console.log("....secondaryCertificate", secondaryCertificate);
        dispatch(
          addMarketLocation({
            name: data.name,
            // status: data?.status,
            ...(secondaryCertificate?.slug !== "No Certificate" && {
              secondaryCertificate: secondaryCertificate?.slug,
            }),
          })
        ).then(() => {
          handleClose();
        });
      } else {
        let element = document.getElementById("marketLcoationCertificate");
        element.classList.add("show-border-error");
      }
    } else {
      if (secondaryCertificate?.name) {
        dispatch(
          updateMarketLocation({
            name: data.name,
            slug: updateData.slug,
            ...(secondaryCertificate?.slug !== "No Certificate" && {
              secondaryCertificate: secondaryCertificate?.slug,
            }),
          })
        ).then(() => {
          handleClose();
        });
      } else {
        let element = document.getElementById("marketLcoationCertificate");
        element.classList.add("show-border-error");
      }
    }
  };

  const secondaryCertificateHandler = (event) => {
    let element = document.getElementById("marketLcoationCertificate");
    if (element.classList.contains("show-border-error")) {
      element.classList.remove("show-border-error");
    }
    let data = {
      name: event.target[event.target.selectedIndex].text,
      slug: event.target[event.target.selectedIndex].value,
    };
    setSecondaryCertificate(data);
  };

  useEffect(() => {
    let slug = "";
    secondaryCertificates.forEach((item) => {
      if (item?.name === updateData?.secondaryCertificate) slug = item?.slug;
    });
    setSecondaryCertificate({
      name: updateData?.secondaryCertificate,
      slug: slug,
    });
  }, [updateData?.secondaryCertificate]);
  return (
    <div>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row} controlId="name">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Market Location Name <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9">
              <Form.Control
                name="name"
                type="text"
                size="sm"
                defaultValue={updateData.name ? updateData.name : ""}
                placeholder="Enter Name"
                className="rounded-pill"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <p className="show-txt-error p-1 m-2">
                  Location name is required
                </p>
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="secondaryCertificate">
            <Form.Label column sm="3" className="font-semi-bold-TKL">
              Secondary Certificate <span className="text-danger">*</span>
            </Form.Label>
            <Col sm="9" className="select-container">
              <span className="select-arrow-icon material-icons">
                expand_more
              </span>

              <Form.Control
                // {...register("secondaryCertificate", { required: true })}
                as="select"
                size="sm"
                className="rounded-pill select-styles"
                defaultValue={
                  updateData
                    ? updateData?.secondaryCertificate
                    : secondaryCertificates[0]
                }
                id="marketLcoationCertificate"
                onChange={secondaryCertificateHandler}
              >
                {isCreate && (
                  <option value="" selected={secondaryCertificate?.name === ""}>
                    Select Certificate
                  </option>
                )}
                {secondaryCertificates?.map((certificate) => {
                  return (
                    <>
                      <option
                        value={certificate?.slug}
                        selected={
                          secondaryCertificate?.name === certificate?.name
                            ? true
                            : false
                        }
                      >
                        {certificate?.name}
                      </option>
                    </>
                  );
                })}
              </Form.Control>
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="button">
            <Row className="mt-3 mr-2 ml-auto mr-3s">
              <Button
                className="m-2 btn btn-secondary rounded-pill"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                type="submit"
                className="m-2 btn btn-primary rounded-pill"
              >
                {isCreate ? "Create" : "Update"}
              </Button>
            </Row>
          </Form.Group>
        </form>
      </Container>
    </div>
  );
}

export default MarketLocationForm;
