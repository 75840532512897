import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL, URL_COHORT } from "../../api/tklEnvironments";
import { getInstructorFeedBack } from "../CohortsActions/InstructorActions/instructoractions";
import {
  hideLoader,
  streamMediaURL,
} from "../../components/utility/ReUsableFunctions";

export const saveUserResponses = (introId, activitySlugId, isDraft = 0) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let gradingMethod =
      store && store.getState().coursehome.application.settings.grading;
    let URL_Item = introId
      ? "add-introduction-fragment-response"
      : "add-fragment-response";
    let fragmentsData =
      store && store.getState().fragments.application.Fragments;

    let FragemntsApiData = [];
    fragmentsData.forEach((fragmentItem) => {
      // if (fragmentItem.fragmentSlug) {
      let fragmentDataTosend = {};
      fragmentDataTosend["response"] = [];
      if (fragmentItem.type === "radio") {
        fragmentDataTosend["response"][0] = Array.isArray(
          fragmentItem.optionSelected
        )
          ? fragmentItem.optionSelected.sort()
          : fragmentItem.optionSelected;
      } else if (fragmentItem.type === "questionbox") {
        fragmentDataTosend["response"] = fragmentItem.answerSubmitted;
      } else if (fragmentItem.type === "selectBox") {
        fragmentItem.selectBoxChoices.forEach((selectBoxChoice) => {
          if (selectBoxChoice.optionSelected >= 0) {
            fragmentDataTosend["response"].push(selectBoxChoice.optionSelected);
          } else {
            fragmentDataTosend["response"].push(null);
          }
        });
      } else if (fragmentItem.type === "checkbox") {
        fragmentDataTosend["response"] = fragmentItem.optionSelected;
      } else if (fragmentItem.type === "fileUpload") {
        fragmentItem.fileUploads &&
          fragmentItem.fileUploads.forEach((fileUploadItem) => {
            let fileUploadData = {};
            fileUploadData["name"] = fileUploadItem?.name;
            fileUploadData["slug"] = fileUploadItem?.slug;
            fragmentDataTosend["response"].push(fileUploadData);
          });
      } else {
        fragmentDataTosend["response"].push(null);
      }
      fragmentDataTosend[
        introId
          ? "cohortIntroductionStepFragmentSlug"
          : "cohortStepFragmentSlug"
      ] = fragmentItem.fragmentSlug;

      if (
        fragmentDataTosend.cohortIntroductionStepFragmentSlug ||
        fragmentDataTosend.cohortStepFragmentSlug
      ) {
        FragemntsApiData.push(fragmentDataTosend);
      }
      // }
    });
    let requestData = {};
    requestData["userResponse"] = FragemntsApiData;
    requestData["isDraft"] = isDraft;

    await axios
      .post(`${URL_COHORT}/${URL_Item}`, requestData)
      .then(function (response) {
        if (gradingMethod === "AUTO GRADING") {
          let userSlugData =
            store && store.getState()?.users?.userData?.userSlug;
          let URL_item = introId
            ? "add-introduction-instructor-feedback"
            : "add-instructor-feedback";
          let dataToSend = {};
          let fragmentPoints = [];
          dataToSend[
            introId ? "cohortIntroductionStepSlug" : "cohortActivitySlug"
          ] = activitySlugId;
          dataToSend["feedback"] = "Auto Graded";
          dataToSend["userSlug"] = userSlugData;
          let points = 0;
          fragmentsData.forEach((fragmentItem) => {
            if (
              fragmentItem.type === "radio" ||
              fragmentItem.type === "checkbox" ||
              fragmentItem.type === "selectBox" ||
              fragmentItem.type === "questionbox" ||
              fragmentItem.type === "fileUpload"
            ) {
              points += fragmentItem?.pointsEarned;
              let assignPointsTOFragments = {};
              assignPointsTOFragments["slug"] = fragmentItem.fragmentSlug;
              assignPointsTOFragments["point"] = !fragmentItem.pointsEarned
                ? 0
                : fragmentItem.pointsEarned;
              fragmentPoints.push(assignPointsTOFragments);
            } else if (fragmentItem.fragmentSlug) {
              points += 0;
              let assignPointsTOFragments = {};
              assignPointsTOFragments["slug"] = fragmentItem.fragmentSlug;
              assignPointsTOFragments["point"] = 0;
              fragmentPoints.push(assignPointsTOFragments);
            }
          });
          dataToSend["points"] = !points ? 0 : points;
          dataToSend["pointBreakOut"] = fragmentPoints;
          if (isDraft == 0) {
            axios
              .post(`${URL_COHORT}/${URL_item}`, dataToSend)
              .then(function (response) {
                if (response.data) {
                  dispatch({
                    type: types.TOTAL_POINTS_OBTAINED,
                    payload: points,
                  });
                  dispatch({
                    type: types.ADD_INSTRUCTOR_FEEDBACK_IN_PROGRESS,
                    payload: true,
                  });
                }
              })
              .catch(function (error) {
                console.log("....error", error);
              });
          }
        }
        let finalTime = performance.now();
        if (finalTime - initialTime >= 600) {
          dispatch({ type: types.HIDE_LOADER });
        } else {
          setTimeout(() => {
            dispatch({ type: types.HIDE_LOADER });
          }, 600 - (finalTime - initialTime));
        }
        return Promise.resolve();
      })
      .catch(function (error) {
        let finalTime = performance.now();
        if (finalTime - initialTime >= 600) {
          dispatch({ type: types.HIDE_LOADER });
        } else {
          setTimeout(() => {
            dispatch({ type: types.HIDE_LOADER });
          }, 600 - (finalTime - initialTime));
        }
        dispatch({
          type: types.API_ERROR,
          payload: `Oops, something went wrong!

                    Please refresh your page to see if the connection is regained. If access is not regained please contact PD@TeachnKidsLearn.com`,
          isReload: false,
        });
        console.error("..error", error);
        return Promise.reject();
      });
  };
};

export const getUserResponses = (activitySlugId, introId, userSlug) => {
  return async (dispatch) => {
    console.log("in getUserResponses");
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });

    let URL_Item = "";
    if (userSlug && userSlug != "null" && userSlug != null) {
      URL_Item = introId
        ? `get-introduction-fragment-response/${activitySlugId}/${userSlug}`
        : `get-fragment-response/${activitySlugId}/${userSlug}`;
    } else {
      URL_Item = introId
        ? `get-introduction-fragment-response/${activitySlugId}`
        : `get-fragment-response/${activitySlugId}`;
    }
    console.log("getInstructorFeedBack");
    dispatch(getInstructorFeedBack(activitySlugId, introId, null, userSlug));

    let fragmentsData =
      store && store.getState().fragments.application.Fragments;
    let activitySlug = store && store.getState().fragments.application;

    await axios
      .get(`${URL_COHORT}/${URL_Item}`)
      .then(function (response) {
        let itemIndex = 0;
        fragmentsData?.forEach((fragmentItem, fragmentItemIndex) => {
          // console.log(
          //   "fragmentItem.type",
          //   fragmentItem.type,
          //   "fragmentItemIndex",
          //   fragmentItemIndex,
          //   "response.data[fragmentItemIndex].fragmentResponses[0]",
          //   response.data[fragmentItemIndex].fragmentResponses[0]
          // );
          if (
            fragmentItem.type === "radio" ||
            fragmentItem.type === "checkbox" ||
            fragmentItem.type === "selectBox" ||
            fragmentItem.type === "questionbox" ||
            fragmentItem.type === "fileUpload"
          ) {
            if (fragmentItem.type === "radio") {
              dispatch({
                type: types.RADIO_GROUP_FORM_DATA,
                payload: introId
                  ? response.data[fragmentItemIndex]
                      ?.introductionFragmentResponse[0].response[0]
                  : response.data[fragmentItemIndex]?.fragmentResponses[0]
                      .response[0],
                itemIndex: fragmentItemIndex,
                label: "optionSelected",
              });
              // dispatch({
              //   type: types.TOTAL_POINTS_OBTAINED,
              //   payload: introId
              //     ? response.data[fragmentItemIndex]
              //         ?.introductionFragmentResponse[0]
              //         ?.introductionInstructorFeedback[0]?.points
              //     : response.data[fragmentItemIndex]?.fragmentResponses[0]
              //         ?.instructorFeedback[0]?.points,
              // });
            } else if (fragmentItem.type === "questionbox") {
              dispatch({
                type: types.QUESTIONBOX_FORM_DATA,
                payload: introId
                  ? response.data[fragmentItemIndex]
                      ?.introductionFragmentResponse[0].response
                  : response.data[fragmentItemIndex]?.fragmentResponses[0]
                      .response,
                itemIndex: fragmentItemIndex,
                label: "answerSubmitted",
              });
              // dispatch({
              //   type: types.TOTAL_POINTS_OBTAINED,
              //   payload: introId
              //     ? response.data[fragmentItemIndex]
              //         ?.introductionFragmentResponse[0]
              //         ?.introductionInstructorFeedback[0]?.points
              //     : response.data[fragmentItemIndex]?.fragmentResponses[0]
              //         ?.instructorFeedback[0]?.points,
              // });
            } else if (fragmentItem.type === "selectBox") {
              fragmentItem.selectBoxChoices.forEach(
                (selectBoxChoice, SelectedBoxChoiceindex) => {
                  dispatch({
                    type: types.SELECT_BOX_OPTION_SELECTED,
                    payload: introId
                      ? response.data[fragmentItemIndex]
                          .introductionFragmentResponse[0].response[
                          SelectedBoxChoiceindex
                        ]
                      : response.data[fragmentItemIndex]?.fragmentResponses[0]
                          .response[SelectedBoxChoiceindex],
                    itemIndex: fragmentItemIndex,
                    label: "selectBoxChoices",
                    index: SelectedBoxChoiceindex,
                  });
                }
              );
              // dispatch({
              //   type: types.TOTAL_POINTS_OBTAINED,
              //   // payload:
              //   //   response.data[fragmentItemIndex]?.fragmentResponses[0]
              //   //     ?.instructorFeedback[0]?.points,
              //   payload: introId
              //     ? response.data[fragmentItemIndex]
              //         ?.introductionFragmentResponse[0]
              //         ?.introductionInstructorFeedback[0]?.points
              //     : response.data[fragmentItemIndex]?.fragmentResponses[0]
              //         ?.instructorFeedback[0]?.points,
              // });
            } else if (fragmentItem.type === "checkbox") {
              dispatch({
                type: types.CHECKBOX_FORM_DATA,
                payload: introId
                  ? response.data[fragmentItemIndex]
                      .introductionFragmentResponse[0].response
                  : response.data[fragmentItemIndex]?.fragmentResponses[0]
                      .response,
                itemIndex: fragmentItemIndex,
                label: "optionSelected",
              });
              // dispatch({
              //   type: types.TOTAL_POINTS_OBTAINED,
              //   // payload:
              //   //   response.data[fragmentItemIndex]?.fragmentResponses[0]
              //   //     ?.instructorFeedback[0]?.points,
              //   payload: introId
              //     ? response.data[fragmentItemIndex]
              //         ?.introductionFragmentResponse[0]
              //         ?.introductionInstructorFeedback[0]?.points
              //     : response.data[fragmentItemIndex]?.fragmentResponses[0]
              //         ?.instructorFeedback[0]?.points,
              // });
            } else if (fragmentItem.type === "fileUpload") {
              let fileUplaodItemData = {};
              let responseData = introId
                ? response.data[fragmentItemIndex]
                    ?.introductionFragmentResponse[0].response
                : response.data[fragmentItemIndex]?.fragmentResponses[0]
                    .response;
              dispatch({
                type: types.ADD_FILES_TO_FILEUPLOAD_FRAGMENT,
                payload: [],
                itemIndex: fragmentItemIndex,
                label: "fileUploads",
                nullFileUpload: true,
              });
              responseData.forEach((fileUploadData) => {
                let mediaDataObject = {};
                // getMedia(fileUploadSlug, fragmentItemIndex, mediaDataObject)
                //   .then((bool) => {
                //     if (bool)
                // dispatch({
                //   type: types.ADD_FILES_TO_FILEUPLOAD_FRAGMENT,
                //   payload: mediaDataObject,
                //   itemIndex: fragmentItemIndex,
                //   label: "fileUploads",
                // });
                //   })
                //   .catch((bool) => {
                //     dispatch({
                //       type: types.API_ERROR,
                //       payload: "File not uploaded, Please try again",
                //     });
                //     if (!bool) console.log("...error");
                //   });
                let media_URL = streamMediaURL(fileUploadData?.slug); //`${URL}/stream-media/${fileUploadData?.slug}`;
                mediaDataObject["name"] = fileUploadData?.name;
                mediaDataObject["slug"] = fileUploadData?.slug;
                mediaDataObject["url"] = media_URL;
                dispatch({
                  type: types.ADD_FILES_TO_FILEUPLOAD_FRAGMENT,
                  payload: mediaDataObject,
                  itemIndex: fragmentItemIndex,
                  label: "fileUploads",
                });
              });
            }
            itemIndex++;
          }
        });
        // dispatch({
        //   type: types.FRAGMENTS_SUBMIT_BUTTON_CLICKED,
        //   payload: true,
        // });
        // setTimeout(() => {
        //   dispatch({
        //     type: types.FRAGMENTS_SUBMIT_BUTTON_CLICKED,
        //     payload: true,
        //   });
        // }, 1000);

        // dispatch({
        //   type: types.FRAGMENTS_RESET_BUTTON_CLICKED,
        //   payload: false,
        // });
        // dispatch({
        //   type: types.ACTIVITY_COMPLETED_CLICKED,
        //   payload: true,
        // });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
    let finalTime = performance.now();
    if (finalTime - initialTime >= 2000) {
      dispatch({ type: types.HIDE_LOADER });
    } else {
      setTimeout(() => {
        dispatch({ type: types.HIDE_LOADER });
      }, 2000 - (finalTime - initialTime));
    }
  };
};

export const getMedia = async (
  mediaSlug,
  fragmentItemIndex,
  mediaDataObject
) => {
  await axios
    .get(`${URL}/get-media/${mediaSlug}`)
    .then(function (response) {
      if (response.data) {
        let imageData = response.data;
        mediaDataObject["name"] = imageData.name;
        mediaDataObject["slug"] = imageData.slug;
        mediaDataObject["url"] = imageData.url;
      }
    })
    .catch(function (error) {
      console.log("...error", error);
      return Promise.reject(false);
    });
  return Promise.resolve(true);
};

export const saveUserActivity = (activitySlugId, introId) => {
  return (dispatch) => {
    let URL_item = introId
      ? "save-cohort-introduction-activity"
      : "save-cohort-activity";

    let savecohortActivity = {};
    savecohortActivity[
      introId ? "cohortIntroductionStepSlug" : "cohortActivitySlug"
    ] = activitySlugId;
    axios
      .post(`${URL_COHORT}/${URL_item}`, savecohortActivity)
      .then(function (response) {
        let appData = {};
        appData["userActivity"] = {};
        appData["userActivity"]["createdAt"] = introId
          ? response.data.response?.cohortUserIntroductionActivity?.createdAt
          : response.data.response?.cohortUserActivity?.createdAt;

        dispatch({
          type: types.ACTIVITY_COMPLETED_CLICKED,
          payload: false,
        });
        dispatch({
          type: types.FRAGMENTS_SUBMIT_BUTTON_CLICKED,
          payload: false,
        });
        dispatch({
          type: types.FRAGMENTS_RESET_BUTTON_CLICKED,
          payload: true,
        });
        dispatch({
          type: types.ADD_ACTIVITY_HEADER_DATA,
          payload: appData,
        });
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const resetActivityByLearner = (isIntro, activitySlug, userSlug) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    console.log("resetActivityByLearner");
    let apiUrl = `step-reset?isIntroduction=${isIntro ? 1 : 0}&${
      isIntro ? "introStepSlug=" : "stepSlug="
    }${activitySlug}&userSlug=${userSlug}`;
    return await axios
      .put(`${URL_COHORT}/${apiUrl}`)
      .then(function (response) {
        if (response?.data) {
          hideLoader(initialTime);

          return Promise.resolve(true);
        } else {
          hideLoader(initialTime);
          return Promise.reject(false);
        }
      })
      .catch(function (error) {
        console.error("..error", error);

        hideLoader(initialTime);
        dispatch({
          type: types.API_ERROR,
          payload: `Oops, something went wrong!
                    
                    Please refresh your page to see if the connection is regained. If access is not regained please contact PD@TeachnKidsLearn.com`,
          isReload: false,
        });
        return Promise.reject(false);
      });
  };
};
