import { RegistrationApplicationData } from "../components/Models/course-model";
import { types } from "./types";
const initialState = {
  roles: "",
  isLoggedIn: true,
  token: "",
};
export const auth = (state = initialState, action: any) => {
  switch (action.type) {
    case types.EXTERNAL_USER:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case types.ASSIGN_USER_ROLE:
      return {
        ...state,
        roles: action.payload,
      };
    case types.ASSIGN_TOKEN:
      return {
        ...state,
        token: action.payload,
      };

    default:
      return state;
  }
};
