import React from 'react';
import ComponentCtaBtn from './../../../shared-components/cta-btn/index';

const CustomizeCTAs = React.memo(function CustomizeCTAs(props) {
  const { widthtoggle } = props;
  return (<div className="text-right mb-2 mb-xl-3">
    <ComponentCtaBtn data={{
      tag: "button",
      type: "button",
      ctastyle: "secondary",
      text: "Customize",
      title: "Customize",
      size: "sm",
      primaryicon: "build",
      isoutline: "true"
    }} />
    <ComponentCtaBtn onClick={widthtoggle} className="d-none d-xl-inline-block ml-2" data={{
      tag: "button",
      type: "button",
      ctastyle: "secondary",
      text: "",
      title: "Toggle Size",
      size: "sm",
      primaryicon: "flip",
      isoutline: "true"
    }} />
    <ComponentCtaBtn className="path-dragger ml-2" data={{
      tag: "button",
      type: "button",
      ctastyle: "secondary",
      text: "",
      title: "Reorder",
      size: "sm",
      primaryicon: "open_with",
      isoutline: "true"
    }} />
    <ComponentCtaBtn className="ml-2" data={{
      tag: "button",
      type: "button",
      ctastyle: "danger",
      text: "",
      title: "Delete",
      size: "sm",
      primaryicon: "close",
      isoutline: "true"
    }} />
  </div>);
});

export default CustomizeCTAs;